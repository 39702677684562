import moment from "moment";
const TatReport = ({ tatReportData }) => {
	const statusArray = ["Completed", "Rejected", "CNI", "Disbursed"];
	return (   
		<div className="row w-100" style={{ overflowX: "scroll" }}>
			<h3 className="mb-4">Turn Around Time Calculator</h3>
			<table className="table">
				<thead>
					<tr>
						<th>Status</th>
						<th>Start Date</th>
						<th>End Date</th>
						<th>Duration</th>
					</tr>
				</thead>
				<tbody>
					{tatReportData ? tatReportData.map((data, index) => {
						return(
							<tr key={index}>
								
								<td>{data.status}</td>
								<td>{data.start_date? moment(data.start_date).format("DD MMM YYYY"):"-"}</td>
								<td>{data.end_date? moment(data.end_date).format("DD MMM YYYY"):
									(statusArray.includes(data.status)?(data.status):"In progress")
						             }
								</td>
								<td>{data.time_difference? data.time_difference:
									(statusArray.includes(data.status)?(data.status):"In progress")
						             }
									 </td>
							</tr>
						);
					}):"No Data Found"
					}
					
				</tbody>
			</table>
		</div>
	);
};

export default TatReport;
