import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "react-bootstrap";
import { useState } from "react";
import BranchSalesTable from "../components/dashboard/BranchSalesTable";
import { OrganizationUsersProvider } from "../context/OrganizationUsersState";

const BranchReport = () => {
	const [active, setActive] = useState("1");
	const [loadSecond, setLoadSecond] = useState(false);

	const toggle = tab => {
		if (active !== tab) {
			setActive(tab);
		}
	};

	return (
		<div>
			<Helmet>
				<title>Branches Report | {process.env.REACT_APP_NAME}</title>
			</Helmet>
			<Navbar />
			<OrganizationUsersProvider>
				<div className="branch-report-main py-5">
					<div className="container-fluid">
						<div className="shadow-lg rounded-3 py-4 px-6 bg-white">
							<div className={"d-flex justify-content-between mb-3 "}>
								<h4 className="m-0 fs-3">Branches Reports</h4>
							</div>
							<Nav className="mb-4 users-nav">
								<NavItem>
									<NavLink
										active={active === "1"}
										onClick={() => {
											toggle("1");
										}}
									>
										By Amount
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										active={active === "2"}
										onClick={() => {
											toggle("2");
											setLoadSecond(true);
										}}
									>
										By Number of Cases
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent className="py-50" activetab={active}>
								<TabPane tabid="1" className={active === "1" ? "active" : ""}>
									<BranchSalesTable type="by_amount" />
								</TabPane>
								<TabPane tabid="2" className={active === "2" ? "active" : ""}>
									<BranchSalesTable
										type="by_cases"
										loadDefault={loadSecond}
									/>
								</TabPane>
							</TabContent>
						</div>
					</div>
				</div>
			</OrganizationUsersProvider>
		</div>
	);
};
export default BranchReport;
