import { Badge } from "react-bootstrap";
import moment from "moment";

const statusObj = {
	pending: "warning",
	active: "warning",
	failed: "danger",
	captured: "success",
	paid: "success"
};
const LoanPaymentLogs = ({ loanPayments }) => {
	return (
		<>
			<div className="row mb-5 w-100" style={{ overflowX: "scroll" }}>
				<table className="table">
					<thead>
						<tr>
							<th>Fee Structure</th>
							<th>Total Fee</th>
							<th>Payment Mode</th>
							<th>Payment Status</th>
							<th>Short Url</th>
							<th>Reference Number</th>
							<th>Payment Receipt</th>
							<th>Remarks</th>
							<th>Created By</th>
							<th>Created On</th>
							<th>Updated On</th>
						</tr>
					</thead>
					<tbody>
						{loanPayments.map((value, index) => {
							return (
								<tr key={`loan-payments-${index}`} className="emi-table">
									<td>
										{value.loanPaymentsDetails.length &&
											value.loanPaymentsDetails.map((data, index) => {
												return (
													<span key={`loanPaymentLogsItem-${index}`}>
														{data.no_dues_name} - {""}
														{data.no_dues_amount}
														<br />
													</span>
												);
											})}
									</td>
									<td> {value.payment_amount? <> ₹ {parseFloat(value.payment_amount).toLocaleString("en-IN")} </>: "0"}</td>
									<td>
										{value.payment_mode}
										{value.mode ? `(${value.mode})` : " "}
									</td>
									<td>
										<Badge
											className="text-capitalize mx-2 fs-7 px-3"
											bg={statusObj[value.payment_status]}
											pill
										>
											{value.payment_status === "captured"
												? "Paid"
												: value.payment_status === "active"
													? "Pending"
													: value.payment_status}
										</Badge>
									</td>
									<td>
										{value.payment_short_url ? (
											<a
												href={value.payment_short_url}
												target="_blank"
												rel="noreferrer"
											>
												View
											</a>
										) : (
											"-"
										)}
									</td>
									<td>{value.reference_number ? value.reference_number : "-"}</td>
									<td>
										{value.receipt ? (
											<a
												href={value.receipt}
												target="_blank"
												rel="noreferrer"
											>
												View
											</a>
										) : (
											"-"
										)}
									</td>
									<td>{value.remarks ? value.remarks : "-"}</td>
									<td>{value.created_by ? value.created_by : "-"}</td>
									<td>
										{value.created_on ? (
											<>
												{moment(value.created_on).format(
													"DD MMM YYYY h:mm a"
												)}
											</>
										) : (
											"-"
										)}
									</td>
									<td>
										{value.updated_on ? (
											<>
												{moment(value.updated_on).format(
													"DD MMM YYYY h:mm a"
												)}
											</>
										) : (
											"-"
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};
export default LoanPaymentLogs;
