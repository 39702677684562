import QRCode from "react-qr-code";

const IdCard = ({ user }) => {
	const copyLink = window.location.origin + "/employee/" + user.username;
	return (
		<>
			<div className="idCard-main">
				<div className="idCard">
					<div className="id-details">
						<div className="cmp-logo">
							<img
								src="/images/pages/dashboard/phfleasing-logo.png"
								alt="Phf-leasing"
							/>
						</div>
						<div className="emp-details"></div>
						<div className="emo-img">
							<img src={user.image} alt={user.name} />
						</div>

						<div className="emp-name">
							<h3>
								{user.first_name} {user.last_name}
							</h3>
							<p>{user.designation}</p>
						</div>
						<div className="empDetls">
							<div className="emp-details">
								<p>Code:</p>
								<p className="emp-subDetails">{user.employee_code}</p>
							</div>
							<div className="emp-details">
								<p>Branch:</p>
								<p className="emp-subDetails">{user.branch_name}</p>
							</div>
							{user.email ? (
								<div className="emp-details">
									<p>Email:</p>
									<p className="emp-subDetails">{user.email}</p>
								</div>
							) : (
								""
							)}

							<div className="emp-details">
								<p>Phone:</p>
								<p className="emp-subDetails">{user.phone}</p>
							</div>
						</div>
						<div className="qr-code mb-1">
							<QRCode
								size={45}
								value={copyLink}
								viewBox={"0 0 0 0"}
								className="mb-1"
							/>
						</div>
						<div className="sharing-card-borders"></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default IdCard;
