import React, { useEffect, useState } from "react";
import AutoComplete from "./forms/AutoComplete";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { ErrorMessage } from "@hookform/error-message";

const AddBranches = () => {
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors }
	} = useForm();
	const [branches, setBranches] = useState([]);
	const [updateBranches, setUpdateBranches] = useState("");
	const cityCode = watch("city_code");

	const onSubmit = values => {
		if (updateBranches) {
			let data = { ...values, location_id: updateBranches.location_enc_id };
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-branch`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						toast.success("Branch Updated Successfully");
						reset();
						setUpdateBranches("");
						setValue("city_id", "");
						setValue("city_code", "");
						getBranches();
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		} else {
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/add-branch`,
					values
				)
				.then(response => {
					if (response.data.response.status === 200) {
						toast.success("Branch Added Successfully");
						reset();
						getBranches();
					} else {
						toast.error(
							response.data.response
								? response.data.response.message
								: "An error has occurred, Please try again"
						);
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	};

	const getBranches = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-branches`, {type: "settings"}
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setBranches(response.data.response.branches);
				} else {
					setBranches([]);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const editBranches = location_id => {
		let branch = branches.filter(branch => branch.location_enc_id === location_id);
		setUpdateBranches(branch[0]);
		setValue("address", branch[0]["address"]);
		setValue("location_name", branch[0]["location_name"]);
		setValue("organization_code", branch[0]["organization_code"]);
		setValue("city_id", branch[0]["city_enc_id"]);
		setValue("city_code", branch[0]["city_code"]);
	};

	const deleteBranch = async location_id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/remove-branch`,
				{
					location_id
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Branch Delete Successfully");
					getBranches();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	useEffect(() => {
		register("city_id");
		register("city_code");
		getBranches();
	}, []);
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row justify-content-center">
					<div className="col-md-6">
						<div className="row">
							<div className="mb-4 col-md-12">
								<label className="form-label" htmlFor="org_name">
									Branch Name
								</label>
								<input
									placeholder="Enter Branch Name"
									className="form-control"
									{...register("location_name", {
										required: "This is Required"
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="location_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-12">
								<label className="form-label" htmlFor="org_name">
									Address
								</label>
								<input
									placeholder="Enter Address"
									className="form-control"
									{...register("address", {
										required: "This is Required"
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="address"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="cities">
									City
								</label>
								<AutoComplete
									setFieldValue={setValue}
									link={"utilities/search-cities?keyword="}
									name={"city_id"}
									method={"GET"}
									placeholder={"Enter City"}
									val={updateBranches ? updateBranches.city : ""}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="org_name">
									Branch Number
								</label>
								<div className="input-group mb-3">
									<span
										className="input-group-text"
										id="inputGroup-branches-default"
									>
										{cityCode}
									</span>
									<input
										placeholder="Enter Branch Number"
										className="form-control"
										{...register("organization_code", {
											required: cityCode ? false : "This is Required"
										})}
									/>
								</div>
								<ErrorMessage
									errors={errors}
									name="organization_code"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 text-center">
								<button className="btn btn-primary">Save</button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div className="row">
				<div className="branches-name">
					<table className="table">
						<thead>
							<tr>
								<th>Code</th>
								<th>Name</th>
								<th className="text-center">Edit</th>
								<th className="text-center">Delete</th>
							</tr>
						</thead>
						<tbody>
							{branches && branches.length ? (
								branches.map((value, index) => {
									return (
										<tr key={`branch_location${index}`}>
											<td>
												<div className="branch-info">
													<p className="mb-0">
														{value.city_code ? value.city_code : ""}
														{value.organization_code
															? value.organization_code
															: ""}
													</p>
												</div>
											</td>
											<td>
												<div className="branch-info">
													<p className="mb-0">
														{value.location_name}, {value.address},{" "}
														{value.city}
													</p>
												</div>
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn btn-primary btn-xs"
													onClick={() =>
														editBranches(value.location_enc_id)
													}
												>
													<FaEdit fontSize={15} />
												</button>
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn btn-primary btn-xs"
													onClick={() =>
														deleteBranch(value.location_enc_id)
													}
												>
													<MdDelete fontSize={15} />
												</button>
											</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan="3">
										<div className="not-branch text-center">
											No Branches Found
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};
export default AddBranches;
