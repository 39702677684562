import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import LoanApplicationsTable from "../../components/dashboard/LoanApplicationsTable";

const LegalApplications = () => {
	return (
		<div>
			<Helmet>
				<title>Legal Initiated Applications</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 bg-white">
						<LoanApplicationsTable type={"legal"} />
					</div>
				</div>
			</div>
		</div>
	);
};
export default LegalApplications;
