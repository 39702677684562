import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { FiUsers } from "react-icons/fi";
import PaymentRequestForm from "./PaymentRequestForm";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { readOnlyUsers } from "../../helpers/authServices";

const InitiateReleasePayment = ({ loanDetails, reload }) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const { handlePageLoaded, user } = useContext(MainContext);
	const [releasePaymentStatus, setReleasePaymentStatus] = useState({
		id: "",
		status: ""
	});
	const [formShow, setFormShow] = useState(false);
	const [showUtrModal, setShowUtrModal] = useState(false);
	const formHide = () => {
		setFormShow(false);
	};

	const showForm = () => {
		setFormShow(true);
	};

	const hideUtrModal = () => {
		setShowUtrModal(false);
	};
	const initiateReleasePayment = values => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		// if (!window.confirm("Are you sure you want to change the status?")) {
		//   return false;
		// }
		handlePageLoaded(true);
		let data = {
			loan_app_enc_id: loanDetails.loan_app_enc_id,
			utr_number: values.utr_number
		};
		if (releasePaymentStatus.id) {
			data["loan_application_release_payment_enc_id"] = releasePaymentStatus.id;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-release-payment`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Payment Released Successfully");
					reload(loanDetails.loan_app_enc_id);
					hideUtrModal();
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "Payment Released Error"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	const utrReg = "^[A-Z0-9]{11}(?!.*[.:_-]{2})[A-Z0-9.:_-]{0,30}[A-Z0-9]$";

	useEffect(() => {
		if (
			loanDetails &&
			loanDetails.loanApplicationReleasePayments &&
			loanDetails.loanApplicationReleasePayments.length
		) {
			const tempStatus = loanDetails.loanApplicationReleasePayments;
			if (tempStatus.length) {
				setReleasePaymentStatus({
					id: tempStatus[0].loan_application_release_payment_enc_id,
					status: tempStatus[0].status
				});
			}
		}
	}, [loanDetails]);
	return (
		<>
			{loanDetails.loan_status === "26" || loanDetails.loan_status === "27" ? (
				<>
					{releasePaymentStatus.status === "1" ? (
						<button type="button" className="btn btn-success">
							<FiUsers /> Payment Released
						</button>
					) : (
						<>
							{releasePaymentStatus.status === "0" ? (
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => setShowUtrModal(true)}
								>
									<FiUsers /> Release Payment Initiated
								</button>
							) : (
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => showForm()}
								>
									<FiUsers /> Release Payment
								</button>
							)}
						</>
					)}
				</>
			) : (
				<button type="button" className="btn btn-primary" disabled={true}>
					<FiUsers /> Release Payment
				</button>
			)}

			<PaymentRequestForm
				show={formShow}
				onHide={formHide}
				loanDetails={loanDetails}
				reload={reload}
			/>

			<Modal show={showUtrModal} onHide={hideUtrModal}>
				<ModalHeader>UTR Number</ModalHeader>
				<ModalBody>
					<form onSubmit={handleSubmit(initiateReleasePayment)}>
						<label className="form-label mb-0" htmlFor="organization_name">
							Enter UTR Number <span style={{ color: "red" }}>*</span>
						</label>
						<input
							placeholder=""
							className="form-control mb-3"
							{...register("utr_number", {
								required: "This is required.",
								pattern: {
									value: utrReg,
									message: "Invalid UTR Number"
								}
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="utr_number"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>

						<div className="text-center">
							<button type="submit" className="btn btn-primary">
								Submit
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</>
	);
};

export default InitiateReleasePayment;
