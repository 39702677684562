import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import "../assets/css/paymentDetails.css";
import { MainContext } from "../context/MainState";
import CollectLoginFeeManually from "./forms/CollectLoginFeeManually";
import moment from "moment";
import { IoCloseCircle } from "react-icons/io5";

const CollectNow = ({ show, loanDetails, onHide, reload, applicationBorrower }) => {
	const { user, loan_products, handlePageLoaded } = useContext(MainContext);
	const { register, handleSubmit, setValue, reset } = useForm();
	const [link, setLink] = useState(false);
	const [showLink, setShowLink] = useState("");
	const [qr, setQr] = useState("");
	const [feeStructures, setFeeStructures] = useState([]);
	const [paymentDetails, setPaymentDetails] = useState(false);
	const [totalAmount, setTotalAmount] = useState(0);

	const handleSumTotalAmount = (e, feeAmount, feeIndex) => {
		const isChecked = e.target.checked;
		const fee = parseFloat(feeAmount);

		if (isChecked) {
			setValue(`amount[${feeIndex}]`, e.target.value);
			setTotalAmount(totalAmount + fee);
		} else {
			setValue(`amount[${feeIndex}]`, "");
			setTotalAmount(totalAmount - fee);
		}
	};

	const inputHandler = event => {
		setShowLink(event.target.value);
	};

	const handleClick = () => {
		onHide();
		setPaymentDetails(true);
	};

	const handleClose = () => {
		setPaymentDetails(false);
	};

	useEffect(() => {
		if (loan_products && loan_products.length) {
			const tempFee = loan_products.filter(
				product => product.name === loanDetails.loan_product
			);
			const finalFee = [];
			if (tempFee.length && tempFee[0].financerLoanProductLoginFeeStructures && tempFee[0].financerLoanProductLoginFeeStructures.length) {
				tempFee[0].financerLoanProductLoginFeeStructures.forEach(function (item) {
					if (item.name === "Diwali Scheme" || item.name === "Diwali Construction Scheme") {
						if (moment(loanDetails.apply_date).isAfter("2023-11-01 00:00:00")) {
							finalFee.push(item);
						}
					} else {
						finalFee.push(item);
					}
				});
			}
			setFeeStructures(finalFee);
		}
	}, [loan_products, loanDetails]);

	const onSubmit = async data => {
		data.loan_app_id = loanDetails.loan_app_enc_id;
		data.name = applicationBorrower?.name;
		data.phone = applicationBorrower?.phone;
		const brand = user.organization_username;
		data.brand = brand;
		data.amount = data.amount ? data.amount.filter(el => el) : [];
		if (!data.amount.length) {
			toast.error("Please select amount");
			return false;
		}
		handlePageLoaded(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}payments/get-payment-link`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Payment link generated successfully");
					setShowLink(response.data.response.data.link);
					setQr(response.data.response.data.qr);
					onHide();
					setLink(true);
					reset();
				} else {
					toast.error(
						response.data.response.message
							? response.data.response.message.toString()
							: "An Error occurred"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occurred: " + error.message);
			});
	};

	const hideLink = () => {
		reload(loanDetails.loan_app_enc_id);
		setLink(false);
	};
	const copy = async () => {
		await navigator.clipboard.writeText(showLink);
		toast.success("Link copied");
	};

	return (
		<div>
			<Modal show={show} onHide={onHide} size={"lg"} centered>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)} className="form">
						<fieldset>
							<div className="container">
								<div className="row">
									<div className="col-12 text-center mb-3">
										<h2>Collect Now <span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span></h2>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<label className="form-label d-block titles">Name</label>
										<input
											placeholder="Full Name"
											value={applicationBorrower?.name}
											className="form-control"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-label d-block titles">
											Phone No.
										</label>
										<input
											placeholder="Phone No."
											value={applicationBorrower?.phone}
											className="form-control"
										/>
									</div>
									<div className="row mt-3">
										<div className="col-lg-12">
											<label className="form-label mb-0" htmlFor="">
												Fee Structure
											</label>
										</div>
										<div className="radio-wrapper">
											{feeStructures.length
												? feeStructures.map((fee, feeIndex) => (
													<div
														className="radio-item mb-2"
														key={`limit-${feeIndex}`}
													>
														<input
															type="checkbox"
															value={
																fee.financer_loan_product_login_fee_structure_enc_id
															}
															className="radioInput"
															name="payment_method"
															id={`payment-id-${feeIndex}`}
															{...register(`amount[${feeIndex}]`)}
															onChange={e => {
																handleSumTotalAmount(
																	e,
																	fee.amount,
																	feeIndex
																);
															}}
														/>
														<label
															htmlFor={`payment-id-${feeIndex}`}
															className="option option-1"
														>
															<span>
																{fee.name} ₹{" "}
																{fee.amount
																	? parseFloat(
																		fee.amount
																		  ).toLocaleString("en-IN")
																	: ""}
															</span>
														</label>
													</div>
												  ))
												: "No fee Structure Available"}
										</div>
										<p className="text-center m-0">
											Total Amount: ₹ {totalAmount.toLocaleString("en-IN")}
										</p>
									</div>
								</div>
								<div className="text-center mt-3">
									<button type="submit" className="btn btn-primary">
										Collect Now
									</button>
								</div>
								{user.user_type === "Financer" ||
								user.designation === "Operations Manager" ? (
										<p className="text-center mt-3">
										If fee already paid,{" "}
											<span
												onClick={handleClick}
												style={{ color: "#00a0e3", cursor: "pointer" }}
											>
											click here
											</span>{" "}
										to update.
										</p>
									) : (
										""
									)}
							</div>
						</fieldset>
					</form>
				</Modal.Body>
			</Modal>

			{/* --------------------------------------------------------------------------------- */}

			<Modal show={link} onHide={hideLink} size={"lg"} centered>
				<Modal.Body>
					<fieldset>
						<div className="container">
							<div className="row align-items-center">
								<div className="col-12 text-center">
									<h3>Payment QR Code</h3>
									<img src={qr} alt="Qr Code" className="qrImage" />
								</div>
								<div className="col-12">
									<label className="form-label d-block titles">
										Payment Link
									</label>
									<input
										type="text"
										className="form-control"
										value={showLink}
										disabled={true}
										onChange={inputHandler}
									/>
									<button
										type="button"
										className="btn btn-primary m-auto d-block mt-3"
										onClick={copy}
									>
										Copy
									</button>
								</div>
							</div>
						</div>
					</fieldset>
				</Modal.Body>
			</Modal>

			{user.user_type === "Financer" || user.designation === "Operations Manager" ? (
				<Modal show={paymentDetails} onHide={handleClose} size={"lg"} centered>
					<Modal.Header>
						<Modal.Title>Payment Detail</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="container">
							<CollectLoginFeeManually
								feeStructures={feeStructures}
								loanDetails={loanDetails}
								reload={reload}
								callback={handleClose}
							/>
						</div>
					</Modal.Body>
				</Modal>
			) : (
				""
			)}
		</div>
	);
};
export default CollectNow;
