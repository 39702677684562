import { useEffect, useState } from "react";
import axios from "axios";
import SkeletonCard from "./dashboard/SkeletonCard";


const SalesReportStats = ({filters}) => {
	const [salesData, setSalesData] = useState("");
	const[loading,setLoading] = useState(false);
	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			setLoading(true);
			getSalesReportStats({
				start_date: filters.start_date + " 00:00:00",
				end_date: filters.end_date + " 23:59:59",
				product_id: filters.loan_product,
				loan_type_enc_id: filters.loan_product_portfolio,
			});
		}
	}, [filters]);

	const getSalesReportStats = filters => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/sales-report-stats`,
				filters
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setSalesData(response.data.response.data);
					setLoading(false);
				} else {
					setSalesData([]);
				}
			})
			.catch(error => {
				console.error("An Error Occurred", error);
			});
	};
	return (
		<div>
         	{loading ? 
				<div className="row">
					{[...Array(6)].map((index) => (
						<>
							<SkeletonCard key={index} type={"sm"}/>
						</>
					))}
				</div>
				:
				(<div className="dsa-stats">
					<div className="row">
						<div className="col-lg-2 col-sm-6 col-6">
							<div className="card-body">
								<img
									src="/images/pages/dashboard/total-applications.png"
									className="card-right-img"
									alt="ds"
								/>
								<p className="card-stat mb-0">
									{salesData.total_cases ? salesData.total_cases : "0"}
								</p>
								<p className="card-head mb-1">
									<span>Total Cases</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										₹ {salesData.total_cases_amount ? parseFloat(salesData.total_cases_amount).toLocaleString("en-IN") : "0"}
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-sm-6 col-6">
							<div className="card-body">
								<img
									src="/images/pages/dashboard/new-leads.png"
									className="card-right-img"
									alt="ds"
								/>
								<p className="card-stat mb-0">
									{salesData.new_lead ? salesData.new_lead : "0"}
								</p>
								<p className="card-head mb-1">
									<span>New Leads</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										₹{" "} 
										{salesData.new_lead_amount
											? parseFloat(salesData.new_lead_amount).toLocaleString(
												"en-IN"
											)
											: "0"}
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-sm-6 col-6">
							<div className="card-body">
								<img
									src="/images/pages/dashboard/log-in.png"
									className="card-right-img"
									alt="ds"
								/>
								<p className="card-stat mb-0">
									{salesData.login ? salesData.login : "0"}
								</p>
								<p className="card-head mb-1">
									<span>Login</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										₹{" "} 
										{salesData.login_amount ? parseFloat(salesData.login_amount).toLocaleString("en-IN")
											: "0"}
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-sm-6 col-6">
							<div className="card-body">
								<img
									src="/images/pages/dashboard/under-process.png"
									className="card-right-img"
									alt="ds"
								/>
								<p className="card-stat mb-0">
									{salesData.under_process ? salesData.under_process : "0"}											
								</p>
								<p className="card-head mb-1">
									<span>Under Process</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										₹{" "}
										{salesData.under_process_amount
											? parseFloat(salesData.under_process_amount).toLocaleString(
												"en-IN"
											)
											: "0"}
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-sm-6 col-6">
							<div className="card-body">
								<img
									src="/images/pages/dashboard/disbursed.png"
									className="card-right-img"
									alt="ds"
								/>
								<p className="card-stat mb-0">
									{salesData.disbursed ? salesData.disbursed : "0"}
								</p>
								<p className="card-head mb-1">
									<span>Disbursed</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										₹{" "}
										{salesData.disbursed_amount
											? parseFloat(salesData.disbursed_amount).toLocaleString("en-IN")
											: "0"}
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-sm-6 col-6">
							<div className="card-body">
								<img
									src="/images/pages/dashboard/rejected.png"
									className="card-right-img"
									alt="ds"
								/>
								<p className="card-stat mb-0">
									{salesData.rejected ? salesData.rejected : "0"}
								</p>
								<p className="card-head mb-1">
									<span>Rejected/CNI</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										₹{" "}
										{salesData.rejected_amount
											? parseFloat(salesData.rejected_amount).toLocaleString(
												"en-IN"
											)
											: "0"}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				)
				 }
		</div>
	);
};

export default SalesReportStats;
