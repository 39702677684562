import moment from "moment";
import React, { useState, useEffect, useContext, useRef } from "react";
import { AiFillCloseCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReportIssueModal from "./ReportIssueModal";
import { FaCheck, FaClock, FaEdit, FaEye, FaPlus, FaTimes, FaUserCheck, FaWhatsapp } from "react-icons/fa";
import RepossessionModal from "./RepossessionModal";
import axios from "axios";
import { toast } from "react-toastify";
import { Badge, Tab, Tabs } from "react-bootstrap";
import AuditTrail from "../AuditTrail";
import EmiCollectionForm from "./EmiCollectionForm";
import { BsPersonFillAdd, BsTelephoneOutbound } from "react-icons/bs";
import LoanAccountRemarks from "./LoanAccountRemarks";
import { MainContext } from "../../context/MainState";
import { Link, useParams } from "react-router-dom";
import CollectionManagerModal from "./CollectionManagerModal";
import TargetPriorityDates from "./TargetPriorityDates";
import PhoneListModal from "./PhoneListModal";
import { MdOpenInNew, MdOutlineContentCopy } from "react-icons/md";
import LoanAccountCaseDetails from "./LoanAccountCaseDetails";
import { IoOpenOutline } from "react-icons/io5";
import QRCode from "react-qr-code";
import Select from "react-select";
import AssignBdoModal from "./AssignBdoModal";
import NachRegisterModal from "./NachRegisterModal";
import EditSelectInput from "../forms/EditSelectInput";
import { readOnlyUsers } from "../../helpers/authServices";
import PickCashModal from "./PickCashModal";
import ActionRemarks from "../../pages/account/ActionRemarks";
import EnachLogs from "../EnachLogs";
import { isLocationEnabled } from "../../helpers/customFunctions";

const EmiLoanAccountSidebar = ({ handleCloseSideBar, loanAccountId, reloadDetails, reloadSidebar = null, type=null}) => {
	const {user, loanAccountTypes, handlePageLoaded} = useContext(MainContext);
	const [selectedRow, setSelectedRow] = useState({});
	const [showRepoModal, setShowRepoModal] = useState(false);
	const [loanAccountNumber, setLoanAccountNmber] = useState("");
	const [loading, setLoading] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	const [emiAccountDetails, setEmiAccountDetails] = useState("");
	const [emiAccountTableDetails, setEmiAccountTableDetails] = useState("");
	const [showReportIssues, setShowReportIssues] = useState();
	const [auditTrails, setAuditTrails] = useState([]);
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [key, setKey] = useState("basic_details");
	const [loanAccountComments, setLoanAccountComments] = useState([]);
	const [sharedModal, setSharedModal] = useState(false);
	const [phoneModal, setPhoneModal] = useState({status: false, type: ""});
	const [issueType, setIssueType] = useState("");
	const [loanDetailsList, setLoanDetailsList] = useState(null);
	const [isHidden, setIsHidden] = useState(false);
	const [paidThisMonthAmount, setPaidThisMonthAmount] = useState(null);
	const [bdoModal, setBdoModal] = useState({type: "", id: ""});
	const params = useParams();
	const [nachApproved, setNachApproved] = useState(false);
	const [pickCash, setPickCash] = useState(false);
	const [cancelNach, setCancelNach] = useState(false);
	const [enachLogsData, setEnachLogsData] = useState(null);
	const [dataLoading, setDataLoading] = useState(true);

	const handleShowEmiModal = async (val) => {
		if (!await isLocationEnabled()) { // Check if location is enabled
			toast.error("Please allow location to continue");
			return;
		}
		setShowEmiModal(val);
	};

	const getReportIssues = elem => {
		let data = {
			loan_account_enc_id: elem
		};

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-payment-issues`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setShowReportIssues(response.data.response.data);
				} else {
					setShowReportIssues([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting report issues, Please try again");
			});
	};

	const openSideBar = () => {
		setShowSidebar(true);
		setLoading("main");

		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			if(type !== "fullwidth"){
				document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
			}
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/emi-account-details`,
				{ loan_account_enc_id: loanAccountId.loan_account_enc_id, loan_account_number: loanAccountId.loan_account_number }
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					setEmiAccountDetails(response.data.response.data);
					setEmiAccountTableDetails(response.data.response.display_data);
					getReportIssues(loanAccountId);
				} else {
					setEmiAccountDetails([]);
					setEmiAccountTableDetails([]);
					setShowReportIssues([]);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error("An error has occurred while getting emi account details, Please try again");
				setLoading(false);
				// Also reset the reported issues on error
				setShowReportIssues([]);
		  });
	};

	const getAuditTrail = () => {
		let data = {loan_id: loanAccountId.loan_account_enc_id};
		setLoading("audit_trail");
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/audit-trail-list`,
				data
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					setAuditTrails(response.data.response.audit_list);
				} else {
					setAuditTrails([]);
				}
			})
			.catch(error => {
				toast.error(error.message);
				setLoading(false);
			});
	};

	const approveEmiPipeline = async elem => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm("Are you sure you want to update Status")) {
			return false;
		}
		const data = {
			emi_collection_enc_id: elem.id,
			status: elem.type
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/emi-collection`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success(`EMI ${elem.type === "paid" ? "Approved" : "Rejected"} Successfully`);
					openSideBar({ loan_account_enc_id: loanAccountId });
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const openModal = (row, type) => {
		setSelectedRow(row);
		setIssueType(type);
	};

	const hideRepoModal = () => {
		setShowRepoModal(false);
	};
	
	const onHide = () => {
		setSelectedRow({});
	};

	const statusColorObj = {
		paid: "success",
		pending: "warning",
		rejected: "danger",
		failed: "danger",
		pipeline: "secondary",
		"not paid": "danger",
		collected: "secondary",
		Active: "success",
		Issued: "warning",
		Pending: "secondary",
		Inactive: "danger",
		Failed: "danger",
		Cancelled: "danger",
		"Not Registered": "primary"
	};

	const handleClose = () => {
		handleCloseSideBar();
		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
		setEmiAccountDetails([]);
		setEmiAccountTableDetails([]);
		setShowReportIssues([]);
		setLoanAccountComments([]);
		setAuditTrails([]);
		setLoanDetailsList("");
		setEnachLogsData("");
		setPaidThisMonthAmount(0);
		activeComments.current = false;
		setShowSidebar(false);
	};

	const handleCloseEmi = () => {
		setLoanAccountNmber("");
		setShowEmiModal(false);
	};

	const reloadData = () => {
		openSideBar();
		reloadDetails();
	};

	const reloadBasicDetails = (reload) => {
		if(reload){
			reloadDetails();
		}else{
			getOtherDetailsList();
		}
	};

	const handleSidebarModal = (val) =>{
		setBdoModal({type: val.type, id: val.id});
	};

	const copyLink =
		window.location.origin + "/account/loan-account/" + loanAccountId.loan_account_enc_id;
	const copy = async () => {
		await navigator.clipboard.writeText(copyLink);
		toast.success("Link copied");
	};

	const getLoanAccountComments = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-comments`,
				{ loan_account_enc_id: emiAccountDetails.loan_account_enc_id }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setLoanAccountComments(response.data.response.data);
				}
			})
			.catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};

	const handleChangeData = (val, name) => {
		setEmiAccountDetails({
			...emiAccountDetails,
			[name]:val
		});
	};

	const handleChangePriority = async (val, id) => {
		if (!window.confirm("Are you sure you want to Change Priority")) {
			return false;
		}
		handlePageLoaded(true);
		let data = {};
		data[id] = val;
		data["loan_account_enc_id"] = [emiAccountDetails.loan_account_enc_id];
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-priority`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					handleChangeData(val, id);
					toast.success("Updated Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
				handlePageLoaded(false);
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};
	const getOtherDetailsList = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-case-detail`,
				{
					loan_account_enc_id: emiAccountDetails.loan_account_enc_id
				}
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setLoanDetailsList(response.data.response.data);
				} else {
					setLoanDetailsList("");
					toast.error("An error has occured while getting other details list, Please try again");
				}
			})
			.catch((error) => {
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};
	const getEnachLogsDetails = () => {
		setDataLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/lead-nach-logs`,
				{
					loan_account_enc_id: emiAccountDetails.loan_account_enc_id
				}
			)
			.then(response => {
				setDataLoading(false);
				if (response.data.response.status === 200) {
					setEnachLogsData(response.data.response.data);
				} else {
					setEnachLogsData("");
				}
			})
			.catch((error) => {
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};


	const hardRecovery = (type) => {
		if (!window.confirm("Are you sure you want to Change Status")) {
			return false;
		}
		const data = {};
		data["loan_accounts"] = [ loanAccountId.loan_account_enc_id];
		data["value"] = type;
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/hard-recovery`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Hard Recovery Marked Successfully");
					reloadDetails();
					setShowSidebar(false);
					handleClose();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
				console.log(error);
			});
	};

	const activeComments = useRef(false);

	const getSelectedTab = k => {
		if (k === "comments" && !activeComments.current) {
			activeComments.current = true; 
			getLoanAccountComments();
		}
		if(k === "case_details" && !loanDetailsList && emiAccountDetails.loan_account_enc_id){
			getOtherDetailsList();
		}
		if(k === "enach_logs" && !enachLogsData ){
			getEnachLogsDetails();
		}
		if(k === "audit_trail" && !auditTrails.length){
			getAuditTrail();
		}

	};

	const hidePhoneModal = () => {
		setPhoneModal({status:false, type:""});
	};

	const searchParams = useParams();
	useEffect(() => {	
		if (searchParams.id) {
			setIsHidden(true);
		}
	}, []);

	useEffect(() => {
		const { loan_account_enc_id, loan_account_number } = loanAccountId || {};
		if ((loan_account_enc_id && loan_account_enc_id !== "") || (loan_account_number && loan_account_number !== "")) {
			openSideBar();
		}else{
			handleClose();
		}
	}, [loanAccountId.loan_account_enc_id, loanAccountId.loan_account_number, reloadSidebar]);
	
	useEffect(() => {
		if (emiAccountTableDetails && emiAccountTableDetails.length) {
			const currentDate = moment().format("YYYY-MM-DD");
			const [currentYear, currentMonth] = currentDate.split("-");
			const totalAmount = emiAccountTableDetails
				.filter(item => item.collection_date && moment(item.collection_date).format("YYYY-MM") === `${currentYear}-${currentMonth}` && item.emi_payment_status === "paid")
				.map(item => parseFloat(item.amount.replace(/^0+/, "")))
				.reduce((acc, curr) => acc + curr, 0);
			setPaidThisMonthAmount(totalAmount);
		}
	}, [emiAccountTableDetails]);

	const priorityList = [
		{ value: "1", label: "1" },
		{ value: "2", label: "2" },
		{ value: "3", label: "3" },
		{ value: "4", label: "4" },
		{ value: "5", label: "5" },
	  ];
	
	  const NachstatusOptions = [
		{ value: "Active", label: "Active" },
		{ value: "Inactive", label: "Inactive" },
	  ];

	// const CustomStyle = {
	// 	option: (data, state) => ({
	// 		...data,
	// 		backgroundColor: state.isSelected && state.value === "Inactive" ? "#198754" : state.isSelected && state.value === "Active" ? "#dc3545"  : "",
	// 		color: state.isSelected ? "#fff" : "#000",
	// 		fontWeight: state.isSelected ? "bold" : "",
	// 		cursor: "pointer",
	// 		tetxAlign: "center"
	// 	})
	// };

	const changeNachStatus = val => {
		handlePageLoaded(true);
		const data = { loan_account_enc_id: loanAccountId.loan_account_enc_id ,  nach_approved: val };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-nach-status`,
				data
			)
			.then((response) => {
				handlePageLoaded(false);
				if(response.data.response?.status === 200){
					toast.success("Nach Status Updated");
					reloadData();
					setNachApproved(false);
				}else{
					toast.error(response.data.response?.error ? response.data.response?.error : "An error has occurred");
				}
			}).catch(error => {
				handlePageLoaded(false);
				toast.error("An Error Occurred: " + error.message);
			});
	};

	return (
		<>
		   {type!=="fullwidth" ?
				<div className="backdrop-blur "></div>
				:""
		   }
			<div className={`${type !== "fullwidth" ? "expend-detail" : "bg-white p-3 vh-100"} pt-3 sidebar-sm ${!showSidebar ? "expend-detail-close" : ""}`}>
				{type!=="fullwidth" ?
					<div
						className="closeIcon"
						onClick={() => {
							handleClose();
						}}
					>
						<AiFillCloseCircle />
					</div>
					:""}
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					{loading === "main" ? (
						<div className="cus-loader">
							<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
						</div>
					) : (
						<Tabs onSelect={k => (setKey(k), getSelectedTab(k))}>
							<Tab eventKey="basic_details" title="Basic Details" className="">
								<div className="emis-details-tab p-3" style={{ overflowX: "scroll" }}>
									<div className="row justify-content-end">
										<div className="col-md-8">
											<div className="text-center d-flex align-items-center justify-content-end ht35">
												<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												 Upcoming EMI Date:{" "}
													{emiAccountDetails.emi_date ?
														<span className="fw-medium me-3 ms-1 text-capitalize Blinking-text" style={{color:"#00a0e3"}}>
															{moment(emiAccountDetails.emi_date).format(
																"DD"
															)}
														</span>
														: "--"}
												</p>
											
												{emiAccountDetails.nach_approved ? (
													<>
													Nach Status: {" "}
														{!nachApproved && (
															<>
																<Badge
																	className="text-capitalize mx-2 py-1"
																	bg={statusColorObj[emiAccountDetails.nach_approved || "Not Registered"]}
																	pill
																>
																	{emiAccountDetails.nach_approved || "Not Registered"}
																</Badge>
																{emiAccountDetails.nach_approved === "Issued" && (["nisha123", "Satparkash"].includes(user.username) || user.user_type === "Financer") ? (
																	<span className="text-red pointer-cursor" onClick={() =>  setCancelNach({loan_account_enc_id:emiAccountDetails.loan_account_enc_id, payment_registration_enc_id: emiAccountDetails.payment_registration_enc_id})}><AiOutlineCloseCircle size={18}/></span>
																) : ""}
															</>
														)}
														{(user.user_type === "Financer" || ["pushapduggal", "nisha123", "Satparkash"].includes(user.username)) && !nachApproved && (
															<span onClick={() => setNachApproved(true)} className="pointer-cursor mx-2">
																<FaEdit color="#00a0e3" fontSize="16px" />
															</span>
														)}
														{nachApproved && (
															<>
																<Select
																	options={NachstatusOptions}
																	className="smSelect smOption statuses-css fw-medium mx-2"
																	value={NachstatusOptions.find(option => option.label === emiAccountDetails.nach_approved)}
																	name="Nach Status"
																	isSearchable={false}
																	placeholder="Select Status"
																	onChange={e => changeNachStatus(e.value)}
																	noOptionsMessage={() => "No Status Available"}
																/>
																<span onClick={() => setNachApproved(false)} className="mx-2 pointer-cursor">
																	<FaTimes color="red" fontSize="16px" />
																</span>
															</>
														)}
													</>
												): ""}
												{(!emiAccountDetails.nach_approved || ["Inactive", "Failed", "Cancelled"].includes(emiAccountDetails.nach_approved)) && emiAccountDetails?.loan_account_enc_id ? (
													<div className="text-end mx-3">
														<NachRegisterModal loanDetails={emiAccountDetails} reload={openSideBar} applicantName={emiAccountDetails.name} type="loan_account"/>
													</div>
												): ""}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Loan Account Number:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.loan_account_number}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Branch:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.branch_name ? emiAccountDetails.branch_name : "-"}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className=" d-flex flex-wrap align-items-center ht-48 m-0">
												Name:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.name}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Loan Type:{" "}
												{user.user_type === "Financer" ? (
													<span className="fw-medium text-gray-700 mx-2 text-capitalize d-flex align-items-center">
														<EditSelectInput
															optionList={loanAccountTypes}
															getData={openSideBar}
															defaultValue={emiAccountDetails.loan_type ? emiAccountDetails.loan_type : ""}
															defaultLabel={emiAccountDetails.loan_type ? emiAccountDetails.loan_type : ""}
															api={"loan-accounts/update"}
															id={emiAccountDetails.loan_account_enc_id}
															selectPlaceholder="Choose Loan Type"
															parentId={user.organization_enc_id}
															required={true}
															type={"loan_type"}
														/>
													</span>
												) : (
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{emiAccountDetails.loan_type}
													</span>
												)}
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Mobile Number:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													<a
														href={`tel:${emiAccountDetails.phone && emiAccountDetails.phone.length ? emiAccountDetails.phone[0] : ""}`}
														className="text-center w-100"
													>
														<BsTelephoneOutbound size={17} />{" "}
														{emiAccountDetails.phone && emiAccountDetails.phone.length ? emiAccountDetails.phone[0] : "-"}
														
													</a>
													{emiAccountDetails.phone && emiAccountDetails.phone.length > 0 ? (
														<button type="button"
															className="btn btn-primary btn-sm mb-0 mx-2 py-1 px-2"
															style={{ fontSize: "3px" }}
															onClick={() => {
																setPhoneModal({ status: true, type: "" });
															}}><FaEye style={{ fontSize: "13px" }} /></button>
													) : ""}
													<button type="button"
														className="btn btn-primary btn-sm mb-0 py-1 px-2"
														style={{ fontSize: "3px" }}
														onClick={() => {
															setPhoneModal({ status: true, type: "add_new" });
														}}><FaPlus style={{ fontSize: "13px" }} /></button>
												</span>
											</p>
										</div>
										<div className="col-md-6 txt-btn-sm-w">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Bucket:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize ">
													{emiAccountDetails.sub_bucket ? emiAccountDetails.sub_bucket === "0" ? "X" : emiAccountDetails.sub_bucket : "-"}
												</span>
											</p>
										</div>
										{/* {emiAccountDetails.loan_app_enc_id &&
											<div className="col-md-6 txt-btn-sm-w">
												<p className="d-flex flex-wrap align-items-center ht-48 m-0">
													Has Smartphone:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize ">
														{loanAccountId.has_smartphone ? hasSmartphone[loanAccountId.has_smartphone] : "-"}
													</span>
												</p>
											</div>
										} */}
										<div className="col-md-6 ">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Paid This Month:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													<BiRupee size={16} />
													{paidThisMonthAmount ? parseFloat(paidThisMonthAmount).toLocaleString("en-IN") : "0"}
												</span>
											</p>
										</div>
										<div className="col-md-6 ">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												EMI Amount:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													<BiRupee size={16} />
													{parseFloat(
														emiAccountDetails.emi_amount
													).toLocaleString("en-IN")}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Overdue EMI:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.overdue_amount ? (
														<>
															<BiRupee size={16} className="me-n1" />{" "}
															{parseFloat(emiAccountDetails.overdue_amount) > 0 ? parseFloat(emiAccountDetails.overdue_amount).toLocaleString("en-IN") : 0}
														</>
													) : "--"}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Ledger Amount:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.ledger_amount ? (
														<>
															<BiRupee size={16} className="me-n1" />{" "}
															{parseFloat(
																emiAccountDetails.ledger_amount
															).toLocaleString("en-IN")}
														</>
													) : (
														"--"
													)}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Collection Charges:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.other_charges ? (
														<>
															<BiRupee size={16} className="me-n1" />{" "}
															{parseFloat(
																emiAccountDetails.other_charges
															).toLocaleString("en-IN")}
														</>
													) : (
														"--"
													)}
												</span>
											</p>
										</div>
										<div className="col-md-6 ">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Target Collection Amount:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.target_collection_amount ? (
														<>
															<BiRupee size={16} className="me-n1" />{" "}
															{parseFloat(
																emiAccountDetails.target_collection_amount
															).toLocaleString("en-IN")}
														</>
													) : (
														"--"
													)}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Net Outstanding Amount:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.total_pending_amount ? (
														<>
															<BiRupee size={16} className="me-n1" />{" "}
															{parseFloat(
																emiAccountDetails.total_pending_amount
															).toLocaleString("en-IN")}
														</>
													) : (
														"--"
													)}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Last Collection Received:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.last_emi_received_amount ? (
														<>
															<BiRupee size={16} className="me-n1" />{" "}
															{parseFloat(
																emiAccountDetails.last_emi_received_amount
															).toLocaleString("en-IN")}
														</>
													) : (
														"--"
													)}
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Last Collection Date:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.last_emi_received_date
														? moment(
															emiAccountDetails.last_emi_received_date
														).format("DD MMM YYYY")
														: "--"}
												</span>
											</p>
										</div>
										{/* <div className="col-md-6 ht35">
											<p className="d-flex flex-wrap ht35">
												Total EMI&#39;s Collected:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.total_emis}
												</span>
											</p>
										</div> */}
										{/* <div className="col-md-6 ht35">
											<p className="d-flex flex-wrap ht35">
												Nach Status:{" "}
												<div>
													<Badge className="text-capitalize mx-2 py-1" bg={statusColorObj[emiAccountDetails.nach_approved ? emiAccountDetails.nach_approved : "Not Registered"]} pill>
														{emiAccountDetails.nach_approved ? emiAccountDetails.nach_approved : "Not Registered"}
													</Badge>
												</div>
												{/* <span className="ms-2 mt-n2">
													<NachRegisterModal loanDetails={emiAccountDetails} reload={openSideBar} applicantName={emiAccountDetails.name} type="loan_account"/>
												</span> */}
										{/* </p> */}
										{/* </div>  */}
										{params["*"] !== "emi-collection" ? (
											<>
												<div className="col-md-6">
													<p className=" reports-issue d-flex flex-wrap align-items-center ht-48 m-0">
														Report Issue:{" "}
														<button
															className="btn btn-danger btn-xs my-1 mx-2"
															onClick={() => openModal(emiAccountDetails, "1")}
														>
															Initiate Legal
														</button>
														<button
															className="btn btn-danger btn-xs my-1 mx-0"
															onClick={() => openModal(emiAccountDetails, "2")}
														>
															Accident
														</button>
														<button
															className="btn btn-danger btn-xs my-1 mx-2"
															onClick={() => openModal(emiAccountDetails, "3")}
														>
															Health Issues
														</button>
													</p>
												</div>
												<div className="col-md-6">
													<p className="d-flex flex-wrap align-items-center btnn-sm ht-48 m-0">
														Repossession:{" "}
														<span className="mx-2">
															<button
																type="button"
																className="btn btn-primary btn-sm mb-0 py-1 px-2"
																onClick={() => setShowRepoModal(true)}
															>
															Initiate Repo
															</button>
														</span>
													</p>
												</div>
											</>
										) : ""}
										{(process.env.REACT_APP_TYPE === "DEV" && emiAccountDetails.loan_account_enc_id) && (
											<div className="col-md-6">
												<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Pick Cash:{" "}
													<span className="mx-2">
														<button
															type="button"
															className="btn btn-primary btn-sm mb-0 py-1 px-2"
															onClick={() => {
																setPickCash({loan_account_enc_id: emiAccountDetails.loan_account_enc_id,loan_account_number: emiAccountDetails.loan_account_number});
															}}
														>
														Raise Pickup Request
														</button>
													</span>
												</p>
											</div>
										)}
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												Collect EMI:{" "}
												<span className="mx-2">
													<button
														type="button"
														className="btn btn-primary btn-sm mb-0 py-1 px-2"
														onClick={() => {
															handleShowEmiModal("emi");
															setLoanAccountNmber(
																{
																	loan_account_enc_id: emiAccountDetails.loan_account_enc_id,
																	loan_account_number: emiAccountDetails.loan_account_number
																}
															);
														}}
													>
														Collect EMI
													</button>
												</span>
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												<span className="me-1">Sales Priority :</span>
												{user.user_type === "Financer" ? (
													<Select
														value={priorityList.filter(option => option.value === emiAccountDetails.sales_priority)}
														onChange={e => handleChangePriority(e.value, "sales_priority")}
														options={priorityList}
														placeholder="Choose Priority"
														className="priority-select smOption"
														isSearchable={false}
													/>
												) :
													<span
														className="fw-medium text-gray-700 mx-2 text-capitalize">{emiAccountDetails.sales_priority ? emiAccountDetails.sales_priority : "-"}</span>
												}
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												<span className="me-1">Telecaller Priority :</span>
												{user.user_type === "Financer" ? (
													<Select
														value={priorityList.filter(option => option.value === emiAccountDetails.telecaller_priority)}
														onChange={e => handleChangePriority(e.value, "telecaller_priority")}
														options={priorityList}
														placeholder="Choose Priority"
														className="priority-select smOption"
														isSearchable={false}
													/>
												) : <span
													className="fw-medium text-gray-700 mx-2 text-capitalize">{emiAccountDetails.telecaller_priority ? emiAccountDetails.telecaller_priority : "-"}</span>}
											</p>
										</div>

										<div className="col-md-6">
											<p className="d-flex flex-wrap align-items-center ht-48 m-0">
												<span className="me-1">Collection Priority :</span>
												{user.user_type === "Financer" ? (
													<Select
														value={priorityList.filter(option => option.value === emiAccountDetails.collection_priority)}
														onChange={e => handleChangePriority(e.value, "collection_priority")}
														options={priorityList}
														placeholder="Choose Priority"
														className="priority-select smOption"
														isSearchable={false}
													/>
												) : <span
													className="fw-medium text-gray-700 mx-2 text-capitalize">{emiAccountDetails.collection_priority ? emiAccountDetails.collection_priority : "-"}</span>}
											</p>
										</div>
										<div className="col-md-6">
											<p className="d-flex align-items-center ht-48 m-0">
												Assigned Telecaller:{" "}
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails.assigned_caller ? emiAccountDetails.assigned_caller : "-"}
												</span>
											</p>
										</div>

										<TargetPriorityDates reload={handleChangeData} loanId={emiAccountDetails} handlePageLoaded={handlePageLoaded} user={user} />


										{loanAccountId.loan_account_enc_id !== null ? (<>
											<div className="col-md-6">
												<div className="shared-with d-flex align-items-center ht-48 m-0">
													<p className="mb-0">Assigned BDO: </p>
													{emiAccountDetails?.assignedLoanViews?.length > 0 ? (
														<div
															className="register-count">
															<div className="reg-img" onClick={() => setSharedModal({
																...emiAccountDetails,
																hide: "CM"
															})}>
																{emiAccountDetails.assignedLoanViews.filter(name => name.user_type === "bdo").map((value, index) => {
																	if (index <= 1) {
																		return (
																			<span
																				className={`reg${index + 1} reg`}
																				key={`accessList-${index}`}
																			>
																				<img src={value.image} />
																			</span>
																		);
																	}
																})}
															</div>
															{emiAccountDetails.assignedLoanViews.filter(name => name.user_type === "bdo").length > 2 ? (
																<span style={{ color: "#00a0e3", fontSize: "13px" }}>
																	{" "}
																	+{emiAccountDetails.assignedLoanViews.filter(name => name.user_type === "bdo").length - 2}{" "}
																</span>
															) : (
																""
															)}
														</div>
													) : ("")}
													<span className="cont cursor-point"
														  onClick={() => handleSidebarModal({
															  type: "bdo",
															  id: loanAccountId.loan_account_enc_id
														  })}>
														<BsPersonFillAdd color="#00a0e3" size={22} />
													</span>
												</div>
											</div>

											<div className="col-md-6">
												<div className="shared-with d-flex align-items-center ht-48 m-0">
													<p className="mb-0">Collection Manager: </p>
													{emiAccountDetails?.assignedLoanViews?.length > 0 ? (
														<div
															className="register-count">
															<div className="reg-img" onClick={() => setSharedModal({
																...emiAccountDetails,
																hide: "BDO"
															})}>
																{emiAccountDetails.assignedLoanViews.filter(name => name.user_type === "collection_manager").map((value, index) => {
																	if (index <= 1) {
																		return (
																			<span
																				className={`reg${index + 1} reg`}
																				key={`accessList-${index}`}
																			>
																				<img src={value.image} />
																			</span>
																		);
																	}
																})}
															</div>
															{emiAccountDetails.assignedLoanViews.filter(name => name.user_type === "collection_manager").length > 2 ? (
																<span style={{ color: "#00a0e3", fontSize: "13px" }}>
																	{" "}
																	+{emiAccountDetails.assignedLoanViews.filter(name => name.user_type === "collection_manager").length - 2}{" "}
																</span>
															) : (
																""
															)}
														</div>
													) : ("-")}
													<span className="cont cursor-point"
														  onClick={() => handleSidebarModal({
															  type: "collection_manager",
															  id: loanAccountId.loan_account_enc_id
														  })}>
														<BsPersonFillAdd color="#00a0e3" size={22} />
													</span>
												</div>
											</div>
										</>) : ""}
										{loanAccountId?.loan_account_enc_id ? (
											<div className="col-md-6">
												<div className="d-flex mt-2">
													Application Link:
													<div className="d-flex ms-2">
														<div className="d-flex flex-column ">
															<QRCode
																size={115}
																value={copyLink}
																viewBox={"0 0 0 0"}
																className="mb-1"
															/>
															{/* <button type="button" className="btn btn-primary btn-sm" onClick={downloadQr}>
														QR <FaDownload style={{ margin: "0px 2px" }} size={18}/>
													</button> */}
														</div>

														<div className="d-flex flex-column">
															<button
																type="button"
																className="btn btn-primary btn-sm mb-1"
																onClick={copy}
																style={{ marginLeft: "4px", padding: "6px 8px" }}
															>
																<MdOutlineContentCopy style={{ margin: "0px 2px" }}
																					  size={18} />
															</button>
															{!isHidden ? (
																<a
																	href={copyLink}
																	target="_blank"
																	className="btn btn-primary btn-sm mb-1"
																	style={{
																		marginLeft: "4px",
																		padding: "6px 8px"
																	}}
																	rel="noreferrer"
																>
																	<IoOpenOutline style={{ margin: "0px 2px" }}
																				   size={18} />
																</a>
															) : (
																""
															)}
															<a
																href={`https://wa.me/?text=${copyLink}`}
																target="_blank"
																className="btn btn-success btn-sm mb-0"
																style={{ marginLeft: "4px", padding: "6px 8px" }}
																rel="noreferrer"
															>
																<FaWhatsapp size={18} />
															</a>
														</div>
													</div>
												</div>
											</div>
										) : ""}
										{params["*"] !== "emi-collection" && loanAccountId?.loan_account_enc_id ? (
											<div className="col-md-6 mt-2">
												{(user.user_type === "Financer" || user.username === "tandon") && (user.organization_username === "phfleasing" || process.env.REACT_APP_TYPE === "DEV") ? (
													<div>
														<button type="button"
															className="btn btn-primary  btn-sm mb-0 py-1 px-2 ht35"
															onClick={() => hardRecovery("1")}>Mark Hard Recovery
														</button>
													</div>
												) : ""}
											</div>
										) : ""}
									</div>

									<div
										className="row my-5 w-100 pb-3 dashboard-dsa"
										style={{ overflowX: "scroll" }}
									>
										<table className="table">
											<thead>
												<tr>
													<th>Actions</th>
													<th>Created On</th>
													<th>Collected By</th>
													<th>Amount</th>
													<th>Reference Number</th>
													<th>Payment Method</th>
													<th>Payment Status</th>
													<th>Payment Link</th>
													<th>Remarks</th>
													<th>Location</th>
													<th>Delay Reason</th>
													<th>Customer Visit</th>
													<th>Customer Interaction</th>
													<th>PR Receipt</th>
													<th>PTP Amount</th>
													<th>PTP Date</th>
												</tr>
											</thead>
											<tbody>
												{emiAccountTableDetails &&
												emiAccountTableDetails.length ? (
														emiAccountTableDetails.map(
															(value, index) => {
																return (
																	<tr
																		key={`emi-table-data${index}`}
																	>
																		<td>
																			{(value.emi_payment_status === "pipeline" || value.emi_payment_status === "pending") && !["QR", "Link"].includes(value.emi_payment_method) && !(value.emi_payment_mode === "Manual Collection" && value.emi_payment_method === "Cash" && moment(value.created_on).isAfter("2023-11-07")) && (user.user_type === "Financer" || ["KKB", "phf604", "phf403"].includes(user.username)) ? (
																				<>
																					<button
																						className="btn btn-success btn-xs mx-1"
																						title="Approve"
																						onClick={() => {
																							approveEmiPipeline(
																								{
																									id: value.emi_collection_enc_id,
																									type: "paid"
																								}
																							);
																						}}
																					>
																						<FaCheck />
																					</button>
																					<button
																						className="btn btn-danger btn-xs"
																						title="Reject"
																						onClick={() => {
																							approveEmiPipeline(
																								{
																									id: value.emi_collection_enc_id,
																									type: "rejected"
																								}
																							);
																						}}
																					>
																						<FaTimes />
																					</button>
																				</>
																			) : (
																				""
																			)}
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{moment(
																					value.created_on
																				).format(
																					"DD MMM YYYY h:mm a"
																				)}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{
																					value.collected_by
																				}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				<BiRupee
																					size={16}
																				/>
																				{parseFloat(
																					value.amount
																				).toLocaleString(
																					"en-IN"
																				)}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{
																					value.reference_number ? value.reference_number : "-"
																				}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{
																					value.emi_payment_method
																				}
																			</p>
																		</td>
																		<td>
																			<p
																				className={`bg-${
																					statusColorObj[
																						value
																							.emi_payment_status
																					]
																				} text-capitalize m-0 badge rounded-pill`}
																			>
																				{
																					value.emi_payment_status
																				}
																			</p>
																		</td>
																		<td>
																			{value.payment_short_url ? (
																				<Link
																					to={value.payment_short_url}
																					target="_blank"
																					className="link-button"
																				>
																		Preview
																				</Link>	
																			) : ("-")}
																		</td>
																		<td>
																			<div className="mb-0 text-gray-900 fw-medium d-block" style={{width:"250px"}}>
																				{value.remarks
																					? (
																						<ul>
																							{value.remarks.split("_").map((name, index) => (
																						  <li key={index}>{name}</li>
																							))}
																					  </ul>
																					):"-"}
																			</div>
																		</td>
																		<td>
																			{value.link ? (
																				<Link
																					to={value.link}
																					target="_blank"
																					className="link-button"
																				>
																		View
																				</Link>	
																			) : ("-")}
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{value.delay_reason
																					? value.delay_reason
																					: "-"}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{value.customer_visit
																					? value.customer_visit
																					: "-"}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{value.customer_interaction
																					? value.customer_interaction
																					: "-"}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{value.receipt_number ? (
																					<>
																						<a href={`/pr-receipt/${value.emi_collection_enc_id}`} target="_blank" rel="noreferrer">
																							{value.receipt_number}
																						</a>
																					</>
																				) : (
																					"-"
																				)}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{value.ptp_amount ? (
																					<>
																						<BiRupee
																							size={
																								16
																							}
																						/>
																						{parseFloat(
																							value.ptp_amount
																						).toLocaleString(
																							"en-IN"
																						)}
																					</>
																				) : (
																					"-"
																				)}
																			</p>
																		</td>
																		<td>
																			<p className="mb-0 text-gray-900 fw-medium">
																				{value.ptp_date
																					? moment(
																						value.ptp_date
																						  ).format(
																						"DD MMM YYYY"
																						  )
																					: "-"}
																			</p>
																		</td>
																	</tr>
																);
															}
														)
													) : (
														<tr>
															<td colSpan="3">
																<div className="not-branch text-center">
																		No Data Found
																</div>
															</td>
														</tr>
													)}
											</tbody>
										</table>
									</div>

									<hr />

									{emiAccountTableDetails &&
												emiAccountTableDetails.length ? (
											<div className="row mb-3">
												<h3 className="mb-2">Attachments</h3>
												<div className="row">
													{emiAccountTableDetails.map((value, index) => {
														return(
															<>
																{value.borrower_image || value.pr_receipt_image || value.other_doc_image ? (
																	<div className=" col-lg-3 col-md-4 " key={`allImages-${index}`}>
																		<div className="loan-attachments res-attachments">
																			<div className="loan-att-image">
																				{value.borrower_image ? (
																					<a href={value.borrower_image}	target="_blank" rel="noreferrer">
																						<img src={value.borrower_image} alt={value.collected_by} className="img-fluid"/>
																					</a>
																				) : ""}
																				{value.pr_receipt_image ? (
																					<a href={value.pr_receipt_image} target="_blank" rel="noreferrer">
																						<img src={value.pr_receipt_image } alt={value.collected_by} className="img-fluid"/> 
																					</a>
																				) : ""}
																				{value.other_doc_image? (
																					<a href={value.other_doc_image} target="_blank" rel="noreferrer">
																						<img src={value.other_doc_image} alt={value.collected_by} className="img-fluid"/>
																					</a>
																				) : ""}
																			</div>
																			<hr className="w-100 my-2" />
																			<div className="uploaded-by text-center">
																				<p className={"mb-2 m-0"}>
																					<FaUserCheck fontSize={18} color={"#00a0e3"}/>{" "}
																					{value.collected_by	}
																				</p>
																				<p className={"m-0"} >
																					<FaClock fontSize={14} color={"#00a0e3"} />{" "}
																					{moment(value.created_on).format("DD MMM YYYY h:mm a")}
																				</p>
																			</div>
																		</div>
																	</div>
																): ""}
															</>
														);
													})}
													{/* {emiAccountTableDetails &&
													emiAccountTableDetails.length
														? emiAccountTableDetails.map(
															(value, index) => {
																return (
																	<>
																		{value.borrower_image ? (
																			<div
																				className="col-md-3"
																				key={`all-Images-${index}`}
																			>
																				<a
																					href={
																						value.borrower_image
																							? value.borrower_image
																							: ""
																					}
																					target="_blank"
																					rel="noreferrer"
																					className="documentView imagesView"
																				>
																					<img
																						src={
																							value.borrower_image
																						}
																						alt={
																							value.collected_by
																						}
																					/>
																					<hr className="w-100 my-2" />
																					<div className="uploaded-by">
																						<p
																							className={
																								"mb-2 m-0"
																							}
																						>
																							<FaUserCheck
																								fontSize={
																									18
																								}
																								color={
																									"#00a0e3"
																								}
																							/>{" "}
																							{
																								value.collected_by
																							}
																						</p>
																						<p
																							className={
																								"m-0"
																							}
																						>
																							<FaClock
																								fontSize={
																									14
																								}
																								color={
																									"#00a0e3"
																								}
																							/>{" "}
																							{moment(
																								value.created_on
																							).format(
																								"DD MMM YYYY h:mm a"
																							)}
																						</p>
																					</div>
																				</a>
																			</div>
																		) : (
																			""
																		)}
																		{value.pr_receipt_image ? (
																			<div
																				className="col-md-3"
																				key={`all-Images-${index}`}
																			>
																				<a
																					href={
																						value.pr_receipt_image
																							? value.pr_receipt_image
																							: ""
																					}
																					target="_blank"
																					rel="noreferrer"
																					className="documentView imagesView"
																				>
																					<img
																						src={
																							value.pr_receipt_image
																						}
																						alt={
																							value.collected_by
																						}
																					/>
																					<hr className="w-100 my-2" />
																					<div className="uploaded-by">
																						<p
																							className={
																								"mb-2 m-0"
																							}
																						>
																							<FaUserCheck
																								fontSize={
																									18
																								}
																								color={
																									"#00a0e3"
																								}
																							/>{" "}
																							{
																								value.collected_by
																							}
																						</p>
																						<p
																							className={
																								"m-0"
																							}
																						>
																							<FaClock
																								fontSize={
																									14
																								}
																								color={
																									"#00a0e3"
																								}
																							/>{" "}
																							{moment(
																								value.created_on
																							).format(
																								"DD MMM YYYY h:mm a"
																							)}
																						</p>
																					</div>
																				</a>
																			</div>
																		) : (
																			""
																		)}
																		{value.other_doc_image ? (
																			<div
																				className="col-md-3"
																				key={`allImages-${index}`}
																			>
																				<a
																					href={
																						value.other_doc_image
																							? value.other_doc_image
																							: ""
																					}
																					target="_blank"
																					rel="noreferrer"
																					className="documentView imagesView"
																				>
																					<img
																						src={
																							value.other_doc_image
																						}
																						alt={
																							value.collected_by
																						}
																					/>
																					<hr className="w-100 my-2" />
																					<div className="uploaded-by">
																						<p
																							className={
																								"mb-2 m-0"
																							}
																						>
																							<FaUserCheck
																								fontSize={
																									18
																								}
																								color={
																									"#00a0e3"
																								}
																							/>{" "}
																							{
																								value.collected_by
																							}
																						</p>
																						<p
																							className={
																								"m-0"
																							}
																						>
																							<FaClock
																								fontSize={
																									14
																								}
																								color={
																									"#00a0e3"
																								}
																							/>{" "}
																							{moment(
																								value.created_on
																							).format(
																								"DD MMM YYYY h:mm a"
																							)}
																						</p>
																					</div>
																				</a>
																			</div>
																		) : (
																			""
																		)}
																	</>
																);
															}
														  )
														: null} */}
												</div>
											</div>
										) : (
											" "
										)}
									
									<div className="row mb-3">
										{showReportIssues && showReportIssues.length ? (
											<h3 className="mb-2">Issues Reported</h3>
										) : (
											" "
										)}
										{showReportIssues && showReportIssues.length
											? showReportIssues.map((val, ind) => {
												return (
													<div
														className="media g-mb-30 media-comment mb20"
														key={`showReport-${ind}`}
													>
														<div className="media-body pt-2">
															<div className="g-mb-15 report-issue">
																<h4 className="text-capitalize mx-2">
																	{val.reasons}
																</h4>
																<p className="mb-0 mx-2">
																	{val.remarks}
																</p>
															</div>

															<div className="d-flex align-items-center justify-content-between pt-3">
																<p className="h5 g-color-gray-dark-v1 mb-0">
																	<span>	
																		<img
																			src={val.user_image}
																			alt={val.created_by}
																			className="creator-img"
																		/>
																	</span>
																	{val.created_by}
																</p>
																<p className="mb-0 issue-date">
																	{moment(
																		val.created_on
																	).format(
																		"DD MMM YYYY h:mm a"
																	)}
																</p>
															</div>
														</div>
													</div>
												);
											  })
											: ""}
									</div>

									<div className="row mb-3">
										{showReportIssues &&
										showReportIssues.length &&
										showReportIssues.some(val => val.image) ? (
												<h3>Reported Attachments</h3>
											) : (
												""
											)}
										<div className="row">
											{showReportIssues && showReportIssues.length
												? showReportIssues.map((val, ind) => {
													return (
														<>
															{val.image ? (
																<div
																	className="col-md-3"
																	key={`report-Images-${ind}`}
																>
																	<a
																		href={
																			val.image
																				? val.image
																				: ""
																		}
																		target="_blank"
																		rel="noreferrer"
																		className="documentView imagesView"
																	>
																		<img
																			src={val.image}
																			alt={val.created_by}
																		/>
																		<hr className="w-100 my-2" />
																		<div className="uploaded-by">
																			<p
																				className={
																					"mb-2 m-0"
																				}
																			>
																				<FaUserCheck
																					fontSize={
																						18
																					}
																					color={
																						"#00a0e3"
																					}
																				/>{" "}
																				{val.created_by}
																			</p>
																			<p
																				className={
																					"m-0"
																				}
																			>
																				<FaClock
																					fontSize={
																						14
																					}
																					color={
																						"#00a0e3"
																					}
																				/>{" "}
																				{moment(
																					val.created_on
																				).format(
																					"DD MMM YYYY h:mm a"
																				)}
																			</p>
																		</div>
																	</a>
																</div>
															) : (
																""
															)}
														</>
													);
												  })
												: ""}
										</div>
									</div>
								</div>

								<ReportIssueModal
									selectedRow={selectedRow}
									onHide={onHide}
									reload={() => getReportIssues(loanAccountId)}
									issueType={issueType}
								/>
								
								<PickCashModal
									loanId={pickCash}
									onHide={() => setPickCash(false)}
									reload={openSideBar}
								/>

								<RepossessionModal
									show={showRepoModal}
									onHide={hideRepoModal}
									loan_account_id={emiAccountDetails.loan_account_enc_id}
									loanType= {emiAccountDetails.loan_type}
								/>

								<EmiCollectionForm
									show={showEmiModal}
									hide={handleCloseEmi}
									loan_number={loanAccountNumber}
								/>

								<CollectionManagerModal
									sharedModal={sharedModal}
									setSharedModal={setSharedModal}
									reloadDetails={reloadData}
								/>

								<PhoneListModal 
									show={phoneModal.status} 
									hide={hidePhoneModal} 
									phoneData={emiAccountDetails.phone}
									type={phoneModal.type}
									loanAccNumber={emiAccountDetails.loan_account_enc_id}
									reload={reloadData}	
								/>

								<AssignBdoModal 
									onHide={() => setBdoModal(false)}
									bdoModal={bdoModal} 
									reloadData={reloadData}
								/>

							</Tab>
							<Tab eventKey="case_details" title="Case Details">
								<LoanAccountCaseDetails loanDetailsList={loanDetailsList} reloadDetails={reloadBasicDetails} emiAccountDetails={emiAccountDetails} user={user} handleCloseSidebar={handleClose}/>
							</Tab>
							<Tab eventKey="comments" title="Remarks">
								{key === "comments" ? (
									<LoanAccountRemarks
										loanAccountComments={loanAccountComments}
										getLoanAccountComments={getLoanAccountComments}
										loanAccountEncId={emiAccountDetails.loan_account_enc_id}
										showReportIssues={showReportIssues}
									/>
								) : (
									""
								)}
							</Tab>
							{emiAccountDetails?.location && Object.entries(emiAccountDetails?.location).length > 0 ? 
								<Tab eventKey="locations" title="Locations">
									<div className="emis-details-tab p-3 pt-3" style={{ overflowX: "auto" }}>
										<div className="row mb-1 mb-3 p-2">
											<div className="col-md-12">
												{Object.keys(emiAccountDetails?.location).map((key, index) =>{
													if(emiAccountDetails?.location[key]?.length){
														return(
															<div key={key}>
																<h4 className={`${index !== 0 ? "mt-3" : ""} fs-3 bold`}>
																	{key.charAt(0).toUpperCase() + key.slice(1)}
																</h4>
																<table className="table">
																	<thead>
																		<tr>
																			<th>Address</th>
																			<th>Created By</th>
																			<th>Created On</th>
																		</tr>
																	</thead>
																	<tbody>
																		{emiAccountDetails?.location[key]?.map((value, index) => {
																			return (
																				<>
																					<tr key={`location-${index}`}>
																						<td>
																							{value.address ? value.address + " - " : ""} {" "}
																							<a target="_blank" href={`https://maps.google.com/?q=${value.latitude},${value.longitude}`}
																								className="btn btn-primary btn-sm m-0" style={{ fontSize: "11px", padding: "6px 12px", borderRadius: "21px" }} rel="noreferrer">
																								<MdOpenInNew /> Open on Map
																							</a>
																						</td>
																						<td>{value.created_by ? value.created_by : "-"}</td>
																						<td>{value.created_on ? moment(value.created_on).format("DD MMM YYYY  h:mm A") : "-"}</td>
																					</tr>
																				</>
																			);
																		})}
																	</tbody>
																</table>	
															</div>
														);
													}
												})  
												}
											</div>
										</div>
									</div>
								</Tab>
								: ""}
							<Tab
								eventKey="audit_trail"
								title="Audit Trail"
								className="pt-4"
							>
								{loading === "audit_trail" ? (
									<div className="cus-loader">
										<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
									</div>
								) : (
									auditTrails?.length ? (
										<div style={{ overflowX: "scroll" }}>
											{Object.values(auditTrails).map((value, index) => {
												return (
													<div
														key={`audit-trail-${index}`}
														className="auditTrail-sec"
													>
														{/* <div className="audit-trail-title">*/}
														{/* <h3>{key}</h3>*/}
														{/* </div>*/}
														<AuditTrail
															auditKey={value}
															auditIndex={index}
														/>
													</div>
												);
											})}
										</div>
									) : <h5 className="text-center">No Data Found</h5>
								)}
							</Tab>

							{emiAccountDetails?.emiPaymentRecords && emiAccountDetails?.emiPaymentRecords.length ? (
								<Tab
									eventKey="bounced_nach"
									title="Bounced Nach"
									className="pt-4"
								>
									<div className="row my-5 w-100 pb-3 dashboard-dsa px-3" style={{ overflowX: "scroll" }}>
										<table className="table">
											<thead>
												<tr>
													<th>Amount</th>
													<th>Nach Date</th>
													<th>Type</th>
													<th>Status</th>
												</tr>
											</thead>
											<tbody>
												{emiAccountDetails.emiPaymentRecords  ? (
													emiAccountDetails.emiPaymentRecords.map(
														(value2, index) => {
															return (
																<tr key={`emi-table-data${index}`}>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			<BiRupee size={16} />
																			{parseFloat(
																				value2.amount
																			).toLocaleString(
																				"en-IN"
																			)}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{moment(
																				value2.nach_date
																			).format(
																				"DD MMM YYYY h:mm a"
																			)}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value2.type}
																		</p>
																	</td>
																	<td>
																		<p className={"bg-danger text-capitalize m-0 badge rounded-pill"}>
																			Failed
																		</p>
																	</td>
																</tr>
															);
														}
													)
												) : (
													<tr>
														<td colSpan="3">
															<div className="not-branch text-center">
																			No Data Found
															</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</Tab>
							) : (
								""
							)}
							<Tab eventKey="enach_logs" title="eNACH Logs">
								<div className="p-4">
									{ dataLoading ?
										<div className="cus-loader">
											<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
										</div>
										:
								      <EnachLogs enachLogsData={enachLogsData}/>
									}
								</div>
							</Tab>
						</Tabs>
					)}
				</PerfectScrollbar>
				<ActionRemarks
					selectedId={cancelNach}
					onHide={() => setCancelNach(false)}
					title="Change Nach Status"
					confirmMessage="Are you sure you want to cancel nach request status"
					callback={() => reloadData()}
					api="payments/cancel-enach-links"
				/>
		 </div>

		</>
	);
};
export default EmiLoanAccountSidebar;
