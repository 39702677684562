import React, { createContext, useReducer, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import OrganizationOperationsReducer from "../reducers/OrganizationOperationsReducer";
// import { MainContext } from "./MainState";

// Create context
export const OrganizationOperationsContext = createContext({});


// Provider component
export const OrganizationOperationsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(OrganizationOperationsReducer, {});
	const [isLoading, setIsLoading] = useState(false);

	const getEmiPaymentLinks = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/links-payment-list`,filter)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_EMI_LINKS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_EMI_LINKS",
						payload: {}
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while getting EMI Payment Links, Please try again");
			});
	};

	const getNachRecords = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}payments/nach-records`,filter)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_NACH_RECORDS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_NACH_RECORDS",
						payload: {}
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while getting E-Mandate Records, Please try again");
			});
	};

	return (
        
		<OrganizationOperationsContext.Provider
			value={{
				emiPaymentLinks: state.emiPaymentLinks,
				nachRecords: state.nachRecords,
				isLoading,
				getEmiPaymentLinks,
				getNachRecords
			}}
		>
			{children}
		</OrganizationOperationsContext.Provider>
	);
};