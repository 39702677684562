import { Modal } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import { MainContext } from "../context/MainState";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MultiStepFormContainer from "./forms/MultiStepApplyForm/MultiStepFormContainer";
import DealerStep1 from "./forms/DealerMultistepApplyForm/DealerStep1";
import DealerStep2 from "./forms/DealerMultistepApplyForm/DealerStep2";
import DealerStep3 from "./dashboard/DealerStep3";
import { useForm } from "react-hook-form";

const DealerSignUpMultiStep = ({ show, onHide }) => {
	const { reset } = useForm();
	const [formIsLoading, setFormIsLoading] = useState(false);
	const history = useNavigate();
	const { updateUser, user } = useContext(MainContext);
	const [allFormData, setAllFormData] = useState({});
	const [formStep, setFormStep] = useState(0);
	const [preventTab, setPreventTab] = useState(false);
	const isUser = !user || !user.access_token;
	const nextFormStep = () => setFormStep(currentStep => currentStep + 1);
	const prevFormStep = () => setFormStep(currentStep => currentStep - 1);

	const setFormData = data => {
		setAllFormData({ ...allFormData, ...data });
		console.log(data);
	};

	const onSubmit = async values => {
		Object.entries(allFormData).map(([key, value]) => {
			values.append(key, value ? value : "");
		});
		values.append("user_type", "Dealer");
		values.append("organization_email", allFormData.email);
		values.append("organization_phone", allFormData.phone);

		setFormIsLoading(true);

		if (user && user.organization_ref_code) {
			values.append("ref_id", user.organization_ref_code);
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/signup`,
				values
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 201) {
					toast.success("Dealer Created Successfully");
					if (isUser) {
						if (response.data.response.data) {
							localStorage.setItem(
								"user",
								JSON.stringify(response.data.response.data)
							);
							updateUser(response.data.response.data);
							history("/account/dashboard");
						}
						if (response.data.response.message) {
							history("/");
							toast.warn(response.data.response.message);
						}
					}
					onHide();
					reset();
					setFormStep(1);
					prevFormStep();
					return false;
				} else if (response.data.response.status === 409) {
					const errors = response.data.response.error;
					const messages = [];
					for (var propName in errors) {
						if (Object.prototype.hasOwnProperty.call(errors, propName)) {
							messages.push(errors[propName]);
						}
					}
					console.log(messages);
					toast.error(messages.toString());
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error occurred: " + error.message);
				setFormIsLoading(false);
			});
	};
	const handleClose = () => {
		onHide();
		setFormStep(0);
		setAllFormData({});
	};

	useEffect(() => {
		if (isUser) {
			setPreventTab(true);
		}
	}, []);
	return (
		<Modal show={show} onHide={handleClose} size={`${isUser ? "xl" : "lg"}`} centered>
			<div className="sign-page">
				<div className={`sign-box ${isUser ? "" : "d-block"}`}>
					{isUser ? (
						<div className="login-benifit">
							<div className="benifit-list">
								<ul>
									<li>
										<div className="ben-icon">
											<img src="/images/pages/home/team-icon.png" alt="" />
										</div>
										<div className="ben-text">
											<h2>Tech-Driven Platform</h2>
											<p>
												We value change and innovation in order to provide
												innovative solutions for all of your requirements.
											</p>
										</div>
									</li>
									<li>
										<div className="ben-icon">
											<img src="/images/pages/home/credit.png" alt="" />
										</div>
										<div className="ben-text">
											<h2>A large pool of loan products</h2>
											<p>
												We&#39;ve gathered everything under one roof, from
												education and personal loans to house loans and
												financial advice.
											</p>
										</div>
									</li>
									<li>
										<div className="ben-icon">
											<img src="/images/pages/home/secure.png" alt="" />
										</div>
										<div className="ben-text">
											<h2>Personalized Dashboard</h2>
											<p>
												With a customizable dashboard, you may tailor it to
												your needs and expectations.
											</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
					) : (
						""
					)}
					<div className="signup-form">
						<h1 className="mb-3">Sign Up</h1>
						<MultiStepFormContainer
							maxStep={3}
							currentStep={formStep}
							prevFormStep={prevFormStep}
							preventTabs={preventTab}
						>
							{formStep >= 0 && (
								<DealerStep1
									formStep={formStep}
									nextFormStep={nextFormStep}
									setFormData={setFormData}
									onSubmitForm={onSubmit}
									isUser={isUser}
									formIsLoading={formIsLoading}
								/>
							)}
							{formStep >= 1 && (
								<DealerStep2
									formStep={formStep}
									nextFormStep={nextFormStep}
									setFormData={setFormData}
									formIsLoading={formIsLoading}
								/>
							)}
							{formStep >= 2 && (
								<DealerStep3
									formStep={formStep}
									nextFormStep={nextFormStep}
									setFormData={onSubmit}
									formIsLoading={formIsLoading}
								/>
							)}
						</MultiStepFormContainer>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default DealerSignUpMultiStep;
