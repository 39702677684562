import { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import NameComp from "../../components/NameComp";
import RepoSidebar from "./RepoSidebar";
import axios from "axios";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { toast } from "react-toastify";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
import MinMaxFilter from "../../components/MinMaxFilter";
import { FaRegEdit ,FaTimes } from "react-icons/fa";
import Select from "react-select";
import { MainContext } from "../../context/MainState";
import SearchDealerModal from "../../components/SearchDealerModal";

const RepossessionTable = () => {
	const { handlePageLoaded} = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [showSidebar, setShowsidebar] = useState(false);
	const [repoDetails, setRepoDetails] = useState("");
	const [repoList, setRepoList] = useState([]);
	const [searchArray, setSearchArray] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [sidebarLoading, setSidebarLoading] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [editRepoStatus, setEditRepoStatus] = useState(false);
	const [soldToModal,setSoldToModal] =useState(false);
	const[requestEncId,setRequestEncId] =useState("");

	const RepoStatusOptions = [
		{ value: 1, label: "Initiated" },
		{ value: 2, label: "Completed" },
	  ];
	const hideSoldToModal = () => {
		setRequestEncId("");
		setSoldToModal(false);
	};
	const assignDealer = (request_enc_id,val) => {
		handlePageLoaded(true);
		const data = { request_enc_id, sold_to: val };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-repo-status`,
				data
			)
			.then((response) => {
				handlePageLoaded(false);
				if(response.data.response?.status === 200){
					toast.success("Dealer Assigned Successfully");
					getRepoList();
				}else{
					toast.error(response.data.response?.error ? response.data.response?.error : "An error has occurred");
				}
			}).catch(error => {
				handlePageLoaded(false);
				toast.error("An Error Occurred: " + error.message);
			});
	};

	const handleClose = () => {
		setShowsidebar(false);
		setRepoDetails("");
		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};
	const handlePerPage = e => {
		getRepoList({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
		});
		setRowsPerPage(parseInt(e.target.value));
	};
	const changeRepoStatus = (request_enc_id,val) => {
		handlePageLoaded(true);
		const data = { request_enc_id, repo_status: val };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-repo-status`,
				data
			)
			.then((response) => {
				handlePageLoaded(false);
				if(response.data.response?.status === 200){
					toast.success("Repossession Status Updated");
					getRepoList();
					setEditRepoStatus(false);
				}else{
					toast.error(response.data.response?.error ? response.data.response?.error : "An error has occurred");
				}
			}).catch(error => {
				handlePageLoaded(false);
				toast.error("An Error Occurred: " + error.message);
			});
	};
 
	const getRepoList = async data => {
		setIsLoading(true);
		const params = { ...data };
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-repo-list`, params)
			.then(response => {
				setIsLoading(false);
				if(response.data.response.status === 200){
					setRepoList(response.data.response);
					setRepoDetails("");
				}else{
					setRepoList([]);
				}
			}).catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(error.message);
			});
	};

	const openSideBar = (elem) => {
		setShowsidebar(true);
		setSidebarLoading(true);
		// addEvent();
		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
		}

		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/repo-details`, 
			{"request_enc_id": elem}
		)
			.then(response => {
				setSidebarLoading(false);
				if(response.data.response.status === 200){
					setRepoDetails(response.data.response.data);
				}else{
					setRepoDetails("");
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};
	
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = repoList ? parseInt(repoList.count) / rowsPerPage : 1;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};
	const handlePagination = page => {
		getRepoList({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
		});
		setCurrentPage(page.selected + 1);
	};
	const reloadDetails = () => {
		getRepoList({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
		});
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2 text-link" onClick={() => (openSideBar(`${row.request_enc_id}`))}>{row.loan_account_number ? row.loan_account_number : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"Vehicle Model"}
					id={"vehicle_model"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.vehicle_model ? row.vehicle_model : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2 text-link text-capitalize" onClick={() => (openSideBar(`${row.request_enc_id}`))}>{row.vehicle_model ? row.vehicle_model : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"Vehicle Brand"}
					id={"brand_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.brand_name ? row.brand_name : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2 text-capitalize">{row.brand_name ? row.brand_name : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name: (
				<MinMaxFilter
					title={"Current Market Value"}
					id={"current_market_value"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.current_market_value ? row.current_market_value : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{parseFloat(row.current_market_value).toLocaleString("en-IN")}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Insurance"}
					id={"insurance"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.insurance ? row.insurance : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2 text-capitalize">{row.insurance ? row.insurance : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"RC"}
					id={"rc"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.rc ? row.rc : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2 text-capitalize">{row.rc ? row.rc : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name: (
				<MinMaxFilter
					title={"KM Driven"}
					id={"km_driven"}
					placeholder="KM"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.km_driven ? row.km_driven : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2">{row.km_driven ? parseFloat(row.km_driven).toLocaleString("en-IN") : "-"}</span>
					</>
				);
			},
		},
		{
			name: (
				<MinMaxFilter
					title={"Repossession Date"}
					id={"repossession_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.repossession_date ? row.repossession_date : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2">{ row.repossession_date ? moment(row.repossession_date).format("DD MMM YYYY") : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"Repossession Status"}
					id={"repo_status"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.repo_status ? row.repo_status:"-"),
			cell: row => {
				return(
					<>
					   <div className="d-flex">
							{editRepoStatus === row.request_enc_id ? (
								<>
									<Select
										options={RepoStatusOptions}
										className="smSelect smOption editRepo-css fw-medium z-2"
										value={RepoStatusOptions.find(option => option.label === row.repo_status)}
										name="Repo Status"
										isSearchable={false}
										placeholder="Select Status"
										onChange={e => changeRepoStatus(row.request_enc_id, e.value)}
										noOptionsMessage={() => "No Status Available"}
									/>
									<span onClick={() => setEditRepoStatus(false)} className="mx-2 pointer-cursor">
										<FaTimes color="red" fontSize="16px" />
										
									</span>
								</>
							) : 
								<>
									<span className="me-2">{row.repo_status ? row.repo_status : "-"}</span>
									<span onClick={() => setEditRepoStatus(row.request_enc_id)} className="pointer-cursor">
										{row.repo_status !== "Completed" ?
											<FaRegEdit
												style={{
													color: "rgb(41, 121, 255)",
													cursor: "pointer"
												}}
												size={14}	
											/>
											:""}
									</span>
								</>}

						</div>
					</>
				);
			},
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"Sold To"}
					id={"sold_to"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.sold_to ? row.sold_to : "-"),
			cell: row => {
				 return(
					<>
						{row.sold_to ? (
							<span className="me-2 text-capitalize">{row.sold_to}</span>
						) : (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer"
								}}
								size={14}	
								onClick={() => {
									setRequestEncId(row.request_enc_id),
									setSoldToModal(requestEncId);
								}}
							/>
						)}
					</>
				);
			},
			sortable: false,
		}
		
	];

	useEffect(() => {
		if (searchArray) {
			const timerId = setTimeout(() => {
				setCurrentPage(1);
				getRepoList({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
				});
			}, 1000);
			
			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}

			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArray]);

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});	
	};
	
	return(
		<>
			<Row className="mx-0 mt-1 mb-50">
				<Col sm="6">
					<div className="d-flex align-items-center">
						<label htmlFor="sort-select">Show</label>
						<select
							className="dataTable-select form-select loan_acc_select"
							style={{
								width: "65px",
								padding: "2px 8px",
								margin: "0px 10px"
							}}
							id="sort-select"
							value={rowsPerPage}
							onChange={e => handlePerPage(e)}
						>
							<option value={10}>10</option>
							<option value={25}>25</option>
							<option value={50}>50</option>
							<option value={75}>75</option>
							<option value={100}>100</option>
						</select>
						<label htmlFor="sort-select">Results</label>
					</div>
				</Col>
				<Col sm="6" className="text-end">
					{showClearBtn ? (
						<button className="btn btn-primary btn-sm mx-1" onClick={(e) => clearFilter(e)}>Reset</button>
					): ""}
				</Col>
			</Row>
			<hr />
			<DataTable
				columns={columns}
				data={repoList.data ? repoList.data : []}
				pagination
				paginationServer
				paginationComponent={CustomPagination}
				highlightOnHover="true"
				persistTableHead={true}
				progressPending={isLoading}
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
				reloadData={reloadDetails}
				className="loan-account-table-container fix-overflow"
			/>
			<RepoSidebar
				showSidebar={showSidebar}
				handleClose={handleClose}
				repoDetails={repoDetails}
				openSideBar={openSideBar}
				sidebarLoading={sidebarLoading}
				getRepoList={getRepoList}
			/>
			<SearchDealerModal 
			  assignDealer={assignDealer}
			  hideSoldToModal={hideSoldToModal}
			  soldToModal={soldToModal}
			  requestEncId={requestEncId}
			/>
		</>
	);
};


export default RepossessionTable;