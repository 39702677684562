import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { RiCloseFill } from "react-icons/ri";
import AutoComplete from "./forms/AutoComplete";
import { MainContext } from "../context/MainState";
import Select from "react-select";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import { MdOutlineModeEdit } from "react-icons/md";
import { toast } from "react-toastify";

const DesignationsSettings = () => {
	const {getOrganizationDepartments, departmentList} = useContext(OrganizationDashboardContext);
	const [allStatusList, setAllStatusList] = useState([]);
	const [currentVal, setCurrentVal] = useState("");
	const [selectedOption, setSelectedOption] = useState("");
	const [showErrorMessage, setShowErrorMessage] = useState({department: false, designation: false}); 
	const { user, handlePageLoaded } = useContext(MainContext);
	const [editingDesignation, setEditingDesignation] = useState();

	const getLoanStatusList = async () => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/financer-designation-list`
			);
			if (response.data.response.status === 200) {
				setAllStatusList(response.data.response.data);
			} else {
				setAllStatusList([]);
			}
		} catch (error) {
			console.log(error);
			toast.error(
				"An error has occurred while getting department lists, Please try again"
			);
		}
	};

	const addNewDesignation = async () => {
		if (!selectedOption) {
			setShowErrorMessage({...showErrorMessage, department: "This field is required"}); 
			return false; 
		}
		if (!currentVal) {
			setShowErrorMessage({...showErrorMessage, designation: "This field is required"});
			return false;
		}

		handlePageLoaded(true);
		let data = {department: selectedOption.value, designation: currentVal, organization_id: user.organization_enc_id, 
			designation_id: editingDesignation && editingDesignation.designation_id ? editingDesignation.designation_id : "", };
		
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/financer-designations`,
				data
			);
			handlePageLoaded(false);
			if (response.data.response.status === 200) {
				setCurrentVal("empty");
				setSelectedOption("");
				getLoanStatusList();
				setEditingDesignation("");
			} else {
				toast.error(response.data.response.message);
				// console.error("An error occurred while adding designation");
			}
		} catch (error) {
			console.log(error);
			toast.error(error.message);
			handlePageLoaded(false);
		}
	};

	const removeDesignation = async (id) => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		handlePageLoaded(true);
		const data = { assigned_designation_enc_id: id };
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/designation-remove`,
				data
			);
			handlePageLoaded(false);
			if (response.data.response.status === 200) {
				getLoanStatusList();
			} else {
				console.error("An error occurred while removing designation");
			}
		} catch (error) {
			console.log(error);
			toast.error(error.message);
			handlePageLoaded(false);
		}
	};

	const handleChange = (selectedOption) => {
		setSelectedOption(selectedOption);
		setShowErrorMessage(false); 
	};

	const editDesignation = (selected, key) => {
		console.log(selected);
		setCurrentVal(selected.value);
		let selectedDepartment = [];
		if(key != "Unassigned"){
			selectedDepartment = departmentList.filter((item) => item.label === key);
		}
		setSelectedOption(selectedDepartment.length ? selectedDepartment[0] : "" );

		setEditingDesignation({
			designation_id: selected.id, 
			department: selectedDepartment.length ? selectedDepartment[0].value : "" ,
		});
	};
	const cancelEditing = () => {
		setCurrentVal("empty");
		setSelectedOption("");
		setEditingDesignation("");
	};
	useEffect(() => {
		getLoanStatusList();
		if(!departmentList){
			getOrganizationDepartments();
		}
	}, []);

	

	return (
		<>
			<h2>Designations</h2>
			<div className="my-4">
				<div className="row">
					<div className="col-md-4"><h4>Select Function</h4></div>
					<div className="col-md-4"><h4>Add New Designation</h4></div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<Select
							value={selectedOption}
							onChange={handleChange}
							options={departmentList}
							placeholder="Select a Function"
						/>
						{showErrorMessage && showErrorMessage.department ? <p className="error">{showErrorMessage.department}</p> : ""} 
					</div>
					<div className="col-md-4">
						<AutoComplete
							link="utilities/designations?keyword="
							setFieldValue={(event, value) => setCurrentVal(value)}
							name={"designation"}
							method={"GET"}
							placeholder="Enter Designation"
							labelAsVal={true}
							val={currentVal}
						/>
						{showErrorMessage && showErrorMessage.designation ? <p className="error">{showErrorMessage.designation}</p> : ""} 
					
					</div>
					<div className="col-md-4">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => addNewDesignation()}
						>
                            Save
						</button>
						{editingDesignation ? (
							<button
								type="button"
								className="btn btn-primary ms-2"
								onClick={() => cancelEditing()}
							>
								Cancel
							</button>
						) : ""}
					</div>
				</div>
			</div>
			<hr />
			<div className="mt-5">
				{allStatusList ? (
					Object.entries(allStatusList).map(([key, value], index) => {
						return (
							<div style={{ border: "3px solid #eee", borderRadius: "10px", padding: "15px", marginBottom:"20px" }} key={index}>
								<h4>{key}</h4>
								{value.length ? value.map((val, index) => {
									return(
										<div className="chip-list" key={`designation-${index}`}>
											<RiCloseFill
												style={{ marginRight: "6px", cursor: "pointer", color: "#bb2124"}}
												size={19}
												onClick={() => removeDesignation(val.id)}
											/>
											{val.value}
											<span className="editIcon">
												<MdOutlineModeEdit 
													style={{ marginLeft: "10px", cursor: "pointer"}}
													size={17}
													onClick={() => editDesignation(val, key)}	
												/>
											</span>
										</div>
									);
								}) : ""}
							</div>
						);
					})
					
				) : (
					"No Designations Found!"
				)}
			</div>
		</>
	);
};

export default DesignationsSettings;
