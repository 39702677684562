import React, { useContext, useEffect, useRef, useState } from "react";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import axios from "axios";
import { loanStatusData } from "../data/loanStatusData";
import moment from "moment";
import EditInput from "./forms/EditInput";
import { MainContext } from "../context/MainState";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { FaRegEdit, FaTimes } from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { Col, Modal } from "react-bootstrap";
import AutoComplete from "./forms/AutoComplete";
import { useForm } from "react-hook-form";
import LoanApplicationDetails from "./dashboard/LoanApplicationDetails";
import LoanColSearch from "./LoanColSearch";
import SortTableColumn from "./dashboard/SortTableColumn";
import EditSelectInput from "./forms/EditSelectInput";
import PerfectScrollbar from "react-perfect-scrollbar";
import { OrganizationSidebarProvider } from "../context/OrganizationSidebarState";
import { IoCloseCircle } from "react-icons/io5";
import UpdateLoanStatus from "./dashboard/UpdateLoanStatus";
// import Datepicker from "react-datepicker";
// import { RiFilter2Fill } from "react-icons/ri";

const LoanApplicationByStatus = ({ loan_product, product_portfolio }) => {
	const { getLoanApplications, getBranches, branches_list, getStates, states_list } = useContext(
		OrganizationDashboardContext
	);
	const [loanApplications, setLoanApplications] = useState({});
	const [loanAppStatus, setLoanAppStatus] = useState([]);
	const [statusToggle, setStatusToggle] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const { user, financer_assigned_loan_types } = useContext(MainContext);
	const [selectedApp, setSelectedApp] = useState({});
	const [appsLoading, setAppsLoading] = useState(true);
	const filterPopupRef = useRef(null);
	const { register, handleSubmit, watch, setValue, reset } = useForm();
	const toBeShared = watch("shared_to");
	const [createrName, setCreaterName] = useState("");
	const [loanDetails, setLoanDetails] = useState("");
	const [searchArr, setSearchArr] = useState("");
	const [allStatusArr, setAllStatusArr] = useState([]);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [sortOrder, setSortOrder] = useState({
		name: "",
		sort: 0,
		sort_val: ""
	});
	const [savedCols, setSavedCols] = useState([]);
	const [initialSavedCols, setInitialSavedCols] = useState([]);
	const [loanEncId, setLoanEncId] = useState("");
	const [loanProductsName, setLoanProductsName] = useState("");
	const [isFilterVisible, setIsFilterVisible] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);	
	const [cancelTokenSource, setCancelTokenSource] = useState(null);
	const [totalCounts, setTotalCounts] = useState({ count: 0, amount: 0 });
	const { getDashboardStatsAmount, stats_amounts } = useContext(
		OrganizationDashboardContext
	);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
	});
	// const [dateFilter, setDateFilter] = useState({
	// 	startDate: null,
	// 	endDate: null
	// });


	const defaultCols = {
		application_number: {
			name: "Application Number",
			checked: true,
			isVisible: true
		},
		tat: { name: "TAT", checked: true, isVisible: true },
		branch: { name: "Branch", checked: true, isVisible: true },
		state: { name: "State", checked: true, isVisible: true },
		applicant_name: { name: "Applicant Name", checked: true, isVisible: true },
		product: { name: "Loan Product", checked: true, isVisible: product_portfolio ? true : false },
		priority: { name: "EOB", checked: true, isVisible: true},
		purpose: { name: "Loan Purpose", checked: true, isVisible: true},
		amount: { name: "Loan Applied", checked: true, isVisible: true },
		status: { name: "Status", checked: true, isVisible: true },
		login: { name: "Login Date", checked: true, isVisible: true },
		bdo_approved_amount: {
			name: "BDO Approved Amount",
			checked: true,
			isVisible: true
		},
		tl_approved_amount: {
			name: "TL Approved Amount",
			checked: true,
			isVisible: true
		},
		last_updated_on:{ name:"Last Updated On" , checked: true, isVisible: true },
		apply_date: { name: "Application Date", checked: true, isVisible: true },
		share: { name: "Share", checked: true, isVisible: true },
		actions: {
			name: "Actions",
			checked: true,
			isVisible: user.user_type === "Financer" ? true : false
		}
	};

	const onHide = () => {
		setSelectedApp({});
	};

	// const toggleFilter = () => {
	// 	setIsFilterVisible(!isFilterVisible);
	// };

	const onSubmit = async data => {
		data["loan_id"] = selectedApp.loan_app_enc_id;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/share-application`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reset();
					getLoanApplications();
					onHide();
					toast.success("Submit Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};

	const handleAccessChange = async (val, id) => {
		let data = {};
		data["shared_loan_app_id"] = id;
		if (val === "delete") {
			data["delete"] = true;
		} else {
			data["access"] = val;
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-shared-application`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reset();
					getLoanApplications();
					onHide();
					toast.success("Submit Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};

	const ref = useRef(null);

	const useOutsideAlerter = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			// handleClose();
			// removeEvent();
		}
	};
	// const removeEvent = () => {
	// 	document.removeEventListener("click", useOutsideAlerter, true);
	// };

	const addEvent = () => {
		document.addEventListener("click", useOutsideAlerter, true);
	};

	// const columnsAccess = [{id: 'loanApplied', name: 'Loan Applied', value: 'loan_applied'}, {}]

	
	const loanStatus = async () => {
		if (cancelTokenSource && cancelTokenSource["statusStats"]) {
			cancelTokenSource["statusStats"].cancel("Previous request canceled");	
		};
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, "statusStats": newCancelTokenSource});

		let data = {fields_search: searchArr};
		if(product_portfolio){
			data["product_portfolio"] = loan_product;
		}else{
			data["loan_product"]= loan_product; 
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/status-stats`,
				data, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setLoanAppStatus(response.data.response.stats);
					statusArr(response.data.response.stats);
					setLoanProductsName(response.data.response.product_name);
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					toast.error(
						"An error has occurred while getting loan applications cases, Please try again"
					);
				}
			});
	};

	const statusArr = stats => {
		let statsArr = [];
		stats.forEach(function (item) {
			statsArr.push(item.value);
		});
		setAllStatusArr(statsArr);
		getLoanApps(statsArr);
	};

	const getLoanApps = async statsArr => {
		if (cancelTokenSource && cancelTokenSource["statusApps"]) {
			cancelTokenSource["statusApps"].cancel("Previous request canceled");
		}
		
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, "statusApps": newCancelTokenSource});

		// setAppsLoading(true);

		let data = {
			status: statsArr,
			provider_id: user.organization_enc_id,
			fields_search: searchArr,
		};
		if(product_portfolio){
			data["product_portfolio"] = loan_product;
		}else{
			data["loan_product"]= loan_product; 
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/status-applications`,
				data, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setAppsLoading(false);
				setTableLoading(false);

				if (response.data.response.status === 200) {
					setLoanApplications(response.data.response.loans);
					setLoanEncId(response.data.response.loan_id);
					getCheckedColumns(response.data.response.loan_id);
				} else {
					setLoanApplications({});
					setTableCols(defaultCols);
				}
			})
			.catch(error => {
				setAppsLoading(false);
				setTableLoading(false);
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					toast.error(
						"An error has occurred while getting loan applications cases, Please try again"
					);
				}
			});
	};

	const reloadData = (loan_app_id, status_key, fieldName, value) => {
		let statusData = loanApplications[status_key];
		let loanApps = statusData.data;
		let itemIndex = loanApps.findIndex(loanApps => {
			return loanApps.loan_app_enc_id === loan_app_id;
		});
		loanApps[itemIndex][fieldName] = value;
		statusData.data = loanApps;
		setLoanApplications({ ...loanApplications, [status_key]: statusData });
	};

	const handleApplicationDelete = id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/remove-loan-application`,
				{ loan_id: id }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					statusArr(loanAppStatus);
					toast.success("Application Successfully Deleted");
				} else {
					toast.error("An error has occurred, Please try again later");
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const handleStatusField = (reload = false) => {
		setShowCurrent("1");
		setStatusToggle(false);
		if (reload) {
			statusArr(loanAppStatus);
		}
	};

	const getStatus = key => {
		const selectedStatus = loanStatusData.loanStatus.filter(
			value => value.value === parseInt(key)
		);
		return selectedStatus && selectedStatus.length > 0 ? selectedStatus[0].name : "--";
	};

	const getStatusColor = key => {
		const selectedStatus = loanAppStatus.filter(value => value.value === key);
		return selectedStatus && selectedStatus.length > 0 ? selectedStatus[0].status_color : "--";
	};

	// const getStatusCount = key => {
	// 	const selectedCount = loanAppStatus.filter(value => value.value === key);
	// 	return selectedCount && selectedCount.length > 0 ? selectedCount[0].count : "--";
	// };

	const handleShareAccess = row => {
		setSelectedApp(row);
		setCreaterName(row.creator_name);
	};

	const getMoreApps = (status_key, loan_product, page, limit) => {
		let data = {
			filter: [status_key],
			fields_search: searchArr,
			limit,
			page,
			provider_id: user.organization_enc_id
		};
		if(product_portfolio){
			data["product_portfolio"] = loan_product;
		}else{
			data["loan_product"]= loan_product; 
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-applications`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					let ua = loanApplications[status_key];
					ua.data = [
						...loanApplications[status_key].data,
						...response.data.response.loans
					];
					ua.page = page;
					setLoanApplications({ ...loanApplications, [status_key]: ua });
				}
			})
			.catch(error => {
				console.log(error.message);
				setTableLoading(false);
			});
	};

	const sortValue = { 0: "", 1: "ASC", 2: "DESC" };
	const handleSorting = column => {
		let data = { field_sort: {} };
		if (sortOrder && sortOrder.name === column) {
			if (sortOrder.sort >= 2) {
				data.field_sort[column] = 0;
			} else {
				data.field_sort[column] = sortOrder.sort + 1;
			}
		} else {
			data.field_sort[column] = 1;
		}
		setSortOrder({
			...sortOrder,
			name: column,
			sort: data.field_sort[column],
			sort_val: sortValue[data.field_sort[column]]
		});
		data.status = allStatusArr;
		data.field_sort[column] = sortValue[data.field_sort[column]];
		if(product_portfolio){
			data["product_portfolio"] = loan_product;
		}else{
			data["loan_product"]= loan_product; 
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/status-applications`,
				data
			)
			.then(response => {
				setLoanApplications(response.data.response.loans);
			});
	};
	// const filterLoanApps = () => {
	// 	let data = [];
	// 	data["start_date"] = dateFilter.startDate
	// 		? moment(dateFilter.startDate).format("YYYY-MM-DD") + " 00:00:00"
	// 		: "";
	// 	data["end_date"] = dateFilter.endDate
	// 		? moment(dateFilter.endDate).format("YYYY-MM-DD") + " 23:59:59"
	// 		: "";
	// 	setSearchArr({ ...searchArr, ...data });
	// 	setIsFilterVisible(false);
	// };
	
	const getSearchResults = () => {
		if (cancelTokenSource && cancelTokenSource["statusApps"]) {
			cancelTokenSource["statusApps"].cancel("Previous request canceled");
		}

		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, "statusApps": newCancelTokenSource});

		let data = {
			fields_search: Object.fromEntries(Object.entries(searchArr).filter(v => v !== "")),
			provider_id: user.organization_enc_id,
			status: allStatusArr,
		};
		if(product_portfolio){
			data["product_portfolio"] = loan_product;
		}else{
			data["loan_product"]= loan_product;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/status-applications`,
				data, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setTableLoading(false);
				if (response.data.response.status === 200) {
					setLoanApplications(response.data.response.loans);
				}
			}).catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setTableLoading(false);
					toast.error(
						"An error has occurred while getting loan applications cases, Please try again"
					);
				}
			});

	};

	const [tableCols, setTableCols] = useState({
		application_number: { name: "Application Number", checked: true, isVisible: true},
		tat: { name: "TAT", checked: true, isVisible: true},
		branch: { name: "Branch", checked: true, isVisible: true },
		state: { name: "State", checked: true, isVisible: true },
		applicant_name: { name: "Applicant Name", checked: true, isVisible: true },
		product: { name: "Loan Product", checked: true, isVisible: true },
		priority: { name: "EOB", checked: true, isVisible: true },
		purpose: { name: "Loan Purpose", checked: true, isVisible: true},
		amount: { name: "Loan Applied", checked: true, isVisible: true },
		status: { name: "Status", checked: true, isVisible: true },
		login: { name: "Login Date", checked: true, isVisible: true },
		bdo_approved_amount: { name: "BDO Approved Amount", checked: true, isVisible: true },
		tl_approved_amount: { name: "TL Approved Amount", checked: true, isVisible: true},
		last_updated_on:{ name:"Last Updated On" , checked: true, isVisible: true },
		apply_date: { name: "Application Date", checked: true, isVisible: true },
		share: { name: "Share", checked: true, isVisible: true },
		actions: { name: "Actions", checked: true, isVisible: user.user_type === "Financer" ? true : false}
	});

	const changeTableCol = id => {
		let newTableCols = { ...tableCols };
		newTableCols[id] = {
			...newTableCols[id],
			checked: !newTableCols[id].checked
		};
		setTableCols(newTableCols);

		let newSavedCols = [];
		if (initialSavedCols && initialSavedCols.length) {
			newSavedCols = [...initialSavedCols];
			setInitialSavedCols([]);
		} else {
			newSavedCols = [...savedCols];
		}

		if (newSavedCols.length) {
			let itemExists = newSavedCols.findIndex(items => {
				return items === id;
			});
			itemExists >= 0 ? newSavedCols.splice(itemExists, 1) : newSavedCols.push(id);
		} else {
			newSavedCols.push(id);
		}

		setSavedCols(newSavedCols);
	};

	const getCheckedColumns = loan_id => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-column-preference`,
				{ loan_product_enc_id: loan_id }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					let cols = response.data.response.columns;
					if (cols.length) {
						setInitialSavedCols(response.data.response.columns);
					} else {
						setTableCols(defaultCols);
					}
				} else {
					setTableCols(defaultCols);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};
	const getCurrentMonthData = () => {
		const tempDate = new Date();
		const fromDate = moment(tempDate.getFullYear() + (tempDate.getMonth() < 9 ? "-0" : "-") + (tempDate.getMonth() + 1) + "-01").format("YYYY-MM-DD");
		const toDate = moment(tempDate).format("YYYY-MM-DD");
		setFilters({start_date: fromDate, end_date: toDate });
	};

	const getDataApi = () => {
		let productType = product_portfolio ? "product_portfolio" : "loan_product";
		getDashboardStatsAmount({
			start_date: filters.start_date,
			end_date: filters.end_date,
			[productType]: loan_product,
		});
	};

	let priorityList = [
		{
			label: "High",
			value: "2"
		},
		{
			label: "Medium",
			value: "3"
		},
		{
			label: "Low",
			value: "4"
		}
	];

	useEffect(() => {
		getCurrentMonthData();
		setLoanEncId("");
		setSavedCols([]);
		setSearchArr("");
		loanStatus();
	}, [window.location.pathname]);

	useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("bg-white");
		return () => {
			document.getElementsByTagName("body")[0].classList.remove("bg-white");
		};
	}, []);

	useEffect(() => {
		if(filters.start_date && filters.end_date){
			getDataApi();
		}
	}, [filters]);
	
	useEffect(() => {
		if (initialSavedCols && initialSavedCols.length) {
			let newTableCols = { ...defaultCols };
			initialSavedCols.forEach(function (item) {
				newTableCols[item] = { ...newTableCols[item], checked: false };
			});
			setTableCols(newTableCols);
		}
	}, [initialSavedCols]);

	useEffect(() => {
		if (stats_amounts && Object.keys(stats_amounts).length) {
			let totalCount = 0;
			let totalAmounts = 0;
			Object.keys(stats_amounts)
				.filter(val => val.match(/_count/))
				.forEach(val => {
					totalCount += stats_amounts[val] ? parseInt(stats_amounts[val]) : 0;
				});
			Object.keys(stats_amounts)
				.filter(val => val.match(/_amount/))
				.forEach(val => {
					totalAmounts += stats_amounts[val] ? parseFloat(stats_amounts[val]) : 0;
				});
			setTotalCounts({ count: totalCount, amount: totalAmounts });
		}
	}, [stats_amounts]);

	useEffect(() => {
		if (loanApplications && loanEncId) {
			let data = {
				disabled_fields: JSON.stringify(savedCols),
				loan_product_enc_id: loanEncId
			};
			axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/add-column-preference`,
				data
			);
		}
	}, [savedCols]);

	useEffect(() => {
		if (searchArr) {
			setTableLoading(true);
			const timerId = setTimeout(() => {
				getSearchResults();
				// loanStatus();
				setClearFilters(false);
			}, 1000);
			
			const valLength = Object.values(searchArr);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setShowClearBtn(true);
			}

			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArr]);
	
	// useEffect(() => {
	// 	// Cleanup function to cancel the request if component unmounts
	// 	return () => {
	// 		if (cancelTokenSource) {
	// 			cancelTokenSource.cancel("Component unmounted");
	// 		}
	// 	};
	// }, [cancelTokenSource]);

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArr("");
	};

	useEffect(() => {
		if (!branches_list) {
			getBranches();
		}
		getStates();
	}, []);

	useEffect(() => {
		const handleClickOutside = event => {
			if (filterPopupRef.current && !filterPopupRef.current.contains(event.target)) {
				setIsFilterVisible(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSearch = data => {
		setSearchArr(data);
	};

	const scrollToSec = elem => {
		const element = document.getElementById(elem);
		window.scroll({ top: element.offsetTop - 300 });
	};
	// const loanstatus = useRef();

	return (
		<>	
			
			<div className="">
				<div className="loan-filters-main container-fluid my-5">
					<div className="dashboard-dsa" style={{marginTop: "150px"}}>						
						<div className="dsa-stats row">
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">{totalCounts.count}</p>
									<p className="card-head mb-1">
										<span>Total Leads</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹ {parseFloat(totalCounts.amount).toLocaleString("en-IN")}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/new-leads.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.new_lead_count
											? stats_amounts.new_lead_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>New Leads</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.new_lead_amount ? parseFloat(stats_amounts.new_lead_amount).toLocaleString("en-IN") : "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/log-in.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.login_count
											? stats_amounts.login_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>Login</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.login_amount
												? parseFloat(stats_amounts.login_amount).toLocaleString(
													"en-IN"
												)
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/under-process.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.under_process_count
											? stats_amounts.under_process_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>Under Process</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.under_process_amount
												? parseFloat(
													stats_amounts.under_process_amount
												).toLocaleString("en-IN")
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/soft-approvals.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.soft_approval_count
											? stats_amounts.soft_approval_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>TVR / Soft Approval</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.soft_approval_amount
												? parseFloat(
													stats_amounts.soft_approval_amount
												).toLocaleString("en-IN")
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/soft-sanctioned.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.soft_sanction_count
											? stats_amounts.soft_sanction_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>Conditional / Soft Sanction</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.soft_sanctioned_amount
												? parseFloat(
													stats_amounts.soft_sanctioned_amount
												).toLocaleString("en-IN")
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/sanctioned.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.sanctioned_count
											? stats_amounts.sanctioned_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>Sanctioned</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.sanctioned_amount
												? parseFloat(
													stats_amounts.sanctioned_amount
												).toLocaleString("en-IN")
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/disbursed-approval.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.disbursement_approval_count
											? stats_amounts.disbursement_approval_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>Disbursement Approval</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.disbursed_approval_amount
												? parseFloat(
													stats_amounts.disbursed_approval_amount
												).toLocaleString("en-IN")
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/disbursed.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.disbursed_count
											? stats_amounts.disbursed_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>Disbursed</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.disbursed_amount
												? parseFloat(
													stats_amounts.insurance_charges_amount
														? parseFloat(
															stats_amounts.insurance_charges_amount
														) + parseFloat(stats_amounts.disbursed_amount)
														: stats_amounts.disbursed_amount
												).toLocaleString("en-IN")
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/rejected.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.rejected_count
											? stats_amounts.rejected_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>Rejected</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.rejected_amount
												? parseFloat(stats_amounts.rejected_amount).toLocaleString(
													"en-IN"
												)
												: "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/cni.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.cni_count
											? stats_amounts.cni_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>CNI</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.cni_amount
												? parseFloat(stats_amounts.cni_amount).toLocaleString(
													"en-IN"
												)
												: "0"}
										</p>
									</div>
								</div>
							</div>
							{/* <div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/insurancecharges.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{stats_amounts && stats_amounts.insurance_charges_count
											? stats_amounts.insurance_charges_count
											: "0"}
									</p>
									<p className="card-head mb-1">
										<span>
										Insurance & <br />
										Incident Charges
										</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
										₹{" "}
											{stats_amounts && stats_amounts.insurance_charges_amount
												? parseFloat(
													stats_amounts.insurance_charges_amount
												).toLocaleString("en-IN")
												: "0"}
										</p>
									</div>
								</div>
							</div> */}
						</div>
					</div>
					<div className="row z-2 c-box-shadow mb-5 position-fixed top-positn lf-flex">
						<div className="col-md-9">
							<h3 style={{ marginTop:"15px"}}>{loanProductsName} Applications</h3>
							<PerfectScrollbar>
								<div className="loansts-flex">
									{loanAppStatus
										? loanAppStatus.map((value, index) => {
											return (
												<button
													className="newlead-btn"
													style={{ background: value.status_color }}
													onClick={() =>
														scrollToSec(`${value.loan_status}-list`)
													}
													key={`loan-status-${index}`}
												>
													{value.loan_status}
												</button>
											);
										  })
										: ""}
								</div>
							</PerfectScrollbar>
						</div>
						<div className="col-md-3 text-end filter-main">
							{/* <div style={{ marginRight: "10px", position: "relative" }}>
								<button
									className="btn filter-btn"
									type="button"
									onClick={toggleFilter}
								>
									<span>
										<RiFilter2Fill />
									</span>
								</button>
								{isFilterVisible && (
									<div className="filter-popup" ref={filterPopupRef}>
										<div className="fil-content">
											<label
												className="form-label"
												style={{ marginRight: "6px", marginBottom: "2px" }}
											>
												From
											</label>
											<Datepicker
												showIcon
												selected={dateFilter.startDate}
												onChange={date => {
													setDateFilter({
														...dateFilter,
														startDate: date
													});
												}}
												showMonthDropdown
												showYearDropdown
												className={"form-control f-date"}
												maxDate={new Date()}
												dropdownMode="select"
												isClearable={true}
												dateFormat="dd MMM yyyy"
											/>
										</div>
										<div className="fil-content">
											<label
												className="form-label"
												style={{ marginRight: "6px", marginBottom: "2px" }}
											>
												To
											</label>
											<Datepicker
												showIcon
												selected={dateFilter.endDate}
												onChange={date => {
													setDateFilter({ ...dateFilter, endDate: date });
												}}
												showMonthDropdown
												showYearDropdown
												className={"form-control f-date"}
												maxDate={new Date()}
												dropdownMode="select"
												isClearable={true}
												dateFormat="dd MMM yyyy"
											/>
										</div>
										<div style={{ margin: "10px", textAlign: "center" }}>
											<button
												className="btn show-results"
												type="button"
												onClick={filterLoanApps}
											>
												<span>Filter Applications</span>
											</button>
										</div>
									</div>
								)}
							</div> */}
							{showClearBtn ? (
								<button className="btn btn-primary btn-sm mx-1" onClick={(e) => clearFilter(e)}>Reset</button>
							): ""}
							<div className="dropdown">
								<button
									className="dropdown-toggle btn btn-sm btn-primary"
									type="button"
									data-bs-toggle="dropdown"
								>
									<span>Show/Hide</span>
									<b className="caret mx-1"></b>
								</button>
								<div className="dropdown-menu" role="menu">
									<ul className="box-content p-3 m-0" style={{width: "230px"}}>
										{tableCols
											? Object.entries(tableCols).map(
												([key, value], index) => {
													return value.isVisible === true ? (
														<>
															<li
																className={"form-check"}
																key={`tc-${index}`}
															>
																<input
																	className="form-check-input"
																	type="checkbox"
																	id={key}
																	value={key}
																	checked={value.checked}
																	onChange={() =>
																		changeTableCol(key)
																	}
																/>
																<label
																	className="form-check-label"
																	htmlFor={key}
																>
																	{value.name}
																</label>
															</li>
														</>
													) : null;
												}
											  )
											: ""}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="customize-scrollbar">
						<div className="row">
							<div className="col-md-12">
								<div className={`panel panel-default mb-5 position-sticky top-set c-box-shadow ${isFilterVisible ? "z-1" : ""}`}>
									<div className="panel-body">
										<table className="table filter-table text-wrap c-bg-white z-2">
											<thead>
												<tr>
													{Object.entries(tableCols).map(
														(value, index) => {
															return value.checked &&
																value.isVisible ? (
																	<th
																		scope="col"
																		key={`tc-${index}`}
																		className="width200"
																	>
																		{value.name}
																	</th>
																) : (
																	""
																);
														}
													)}
												</tr>
											</thead>
											<tbody>
												<tr>
													{Object.entries(tableCols).map(
														([key, value], index) => {
															return value.checked &&
																value.isVisible ? (
																	<td
																		key={`tcd-${index}`}
																		className="width200"
																	>
																		<LoanColSearch
																			colName={key}
																			setSearchArray={
																				handleSearch
																			}
																			branches={branches_list}
																			loan_types={financer_assigned_loan_types}
																			loan_product_id={loan_product}
																			status={loanAppStatus}
																			states_list={states_list}
																			searchArray={searchArr}
																			clearFilters={clearFilters}
																		/>
																	</td>
																) : (
																	""
																);
														}
													)}
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div
									className="panel-group"
									// style={{ marginTop: "100px" }}
									id="accordion"
									role="tablist"
									aria-multiselectable="true"
								>
									<div className="inner-scroll">
										{appsLoading ? (
											<h2 className="cus-loader w-100 m-5">
												Loading...
											</h2>
										) : ( <>
											{Object.keys(loanApplications).length
												? Object.entries(loanApplications).map(
													([key, value], index) => {
														let statusColor = getStatusColor(key);
														let statusName = getStatus(key);
														return (
															<>
																<div
																	id={`${statusName}-list`}
																	className="panel panel-default mb-5"
																	key={`loanStat-${statusName}-${index}`}
																>
																	<div
																		className="panel-heading"
																		role="tab"
																		id={`headingOne-${index}`}
																	>
																		<h4 className="panel-title m-0 d-flex align-items-center">
																			<button
																				className="border-0"
																				style={{
																					background:
																						statusColor,
																					zIndex: "-1"
																				}}
																				data-bs-toggle="collapse"
																				data-parent="#accordion"
																				data-bs-target={`#collapseOne-${index}`}
																				aria-expanded="true"
																				aria-controls={`collapseOne-${index}`}
																			>
																				{getStatus(key)}
																			</button>
																			<span className="mx-2 fw-medium fs-6 text-gray-500">
																				{value.count
																					? `${value.count} Application${parseInt(value.count) > 1 ? "s" : ""}`
																					: ""}
																			</span>
																		</h4>
																	</div>
																	<div
																		id={`collapseOne-${index}`}
																		className="panel-collapse collapse in show"
																		role="tabpanel"
																		aria-labelledby={`headingOne-${index}`}
																	>
																		<div className="panel-body">
																			<table
																				className="table filter-table2 text-nowrap"
																				// style={{
																				// 	background:
																				// 		statusColor +
																				// 		"4d"
																				// }}
																			>
																				<thead>
																					<tr>
																						{Object.entries(
																							tableCols
																						).map(
																							(
																								[
																									key,
																									value
																								],
																								index
																							) => {
																								return value.checked &&
																									value.isVisible ? (
																										<th
																											key={`table_status_data_${index}`}
																											scope="col"
																											className="width200"
																										>
																											{
																												value.name
																											}
																											<SortTableColumn
																												loanStatus={key}
																												column={key}
																												sortColumn={sortOrder.name}
																												currentSort={sortOrder.sort_val}
																												callback={handleSorting}
																											/>
																										</th>
																									) : (
																										""
																									);
																							}
																						)}
																					</tr>
																				</thead>
																				<tbody>
																			
																					{tableLoading ?
																						<h2 className="w-100 m-5 text-start">Loading...</h2> : (
																							<>
																								{value.data.length
																									? value.data.map(
																										(
																											item,
																											index
																										) => {
																											return (
																												<tr
																													key={`tablerow-${statusName}-${index}`}
																												>
																													{tableCols[
																														"application_number"
																													]
																														.checked && (
																														<td className="width200">
																															
																															{item.application_number ? (
																																<h6 className="mb-0 cursor-point text-wrap" onClick={() =>
																																{setLoanDetails(item.loan_app_enc_id), addEvent();}
																																}>
																																	{item.application_number}
																																</h6>
																															) : (
																																<div className="editInputDiv">
																																	<EditInput
																																		getData={(
																																			id,
																																			value
																																		) =>
																																			reloadData(
																																				item.loan_app_enc_id,
																																				key,
																																				"application_number",
																																				value
																																			)
																																		}
																																		defaultValue={
																																			item.application_number
																																				? item.application_number
																																				: ""
																																		}
																																		api={
																																			"loans/update-application-number"
																																		}
																																		parentId={
																																			item.loan_app_enc_id
																																		}
																																		placeholder={
																																			"Enter Application Number"
																																		}
																																		provider={
																																			user.organization_enc_id
																																		}
																																		id={
																																			item.loan_app_enc_id
																																		}
																																		type={"users-table"}
																																	/>
																																</div>
																															)}
																															
																														</td>
																													)}
																													{tableCols[
																														"tat"
																													].checked && (
																														<td className="width200">
																															{item.tat ? (
																																<h6 className="mb-0 cursor-point text-wrap" onClick={() =>
																																{setLoanDetails(item.loan_app_enc_id), addEvent();}
																																}>
																																	{item.tat}
																																</h6>
																															) : "--"}
																														</td>
																													)}
																													{tableCols[
																														"branch"
																													]
																														.checked && (
																														<td className="text-breaks">
																															
																															{item.branch ? (
																																<h6 className="mb-0 cursor-point" onClick={() =>
																																{setLoanDetails(item.loan_app_enc_id), addEvent();}
																																}>
																																	{item.branch}
																																</h6>
																															) : (
																																<div className="editInputDiv">
																																	<EditSelectInput
																																		optionList={
																																			branches_list
																																		}
																																		// getData={
																																		// 	openSideBar
																																		// }
																																		defaultValue={
																																			item.branch_id
																																				? item.branch_id
																																				: ""
																																		}
																																		defaultLabel={
																																			item.branch
																																				? item.branch
																																				: ""
																																		}
																																		api={
																																			"loans/add-loan-branch"
																																		}
																																		id={
																																			item.loan_app_enc_id
																																		}
																																		selectPlaceholder="Choose Branch"
																																		parentId={
																																			user.organization_enc_id
																																		}
																																		required={
																																			true
																																		}
																																	/>
																																</div>
																															)}
																														</td>
																													)}
																													{tableCols[
																														"state"
																													]
																														.checked && (
																														<td className="width200 text-breaks">
																															<h6 className="mb-0 cursor-point">
																																{item.state_name
																																	? item.state_name
																																	: "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"applicant_name"
																													]
																														.checked && (
																														<td className="width200 text-breaks">
																															<h6
																																className="mb-0 cursor-point"
																																onClick={() =>
																																{setLoanDetails(item.loan_app_enc_id), addEvent();}
																																}
																															>
																																{item.applicant_name
																																	? item.applicant_name
																																	: "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols["product"].checked &&
																													tableCols[
																														"product"
																													]
																														.isVisible &&(
																														<td>
																															<h6 className="mb-0 list-data text-wrap">
																																{item.loan_product ? item.loan_product : "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols["priority"].checked &&
																													tableCols[
																														"priority"
																													]
																														.isVisible &&(
																														<td>
																															<div className="d-flex align-items-center">
																																<EditSelectInput
																																	optionList={priorityList}
																																	getData={loanStatus}
																																	defaultValue={item.priority}
																																	defaultLabel={item.priority}
																																	api={"loans/loan-update"}
																																	id={item.loan_app_enc_id}
																																	selectPlaceholder="Choose EOB"
																																	parentId={user.organization_enc_id}
																																	required={true}
																																	type="priority"
																																	loanDetailStatus={parseInt(item.loan_status) || ""}
																																/>
																															</div>
																														</td>
																													)}
																													{tableCols[
																														"purpose"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0 list-data text-wrap">
																																{item.loanPurposes && item.loanPurposes.length
																																	? item.loanPurposes.map((value, index) => {
																																		return (
																																			<span
																																				key={`purposes-${item.loan_app_enc_id}${index}`}
																																			>
																																				{value.purpose}
																																			</span>
																																		);
																																	})
																																	: "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"amount"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0">
																																{item.amount
																																	? (
																																		<>
																																		₹ {parseFloat(item.amount).toLocaleString("en-IN")}
																																		</>
																																	)
																																	: "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"status"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0">
																																<div className="editInputDiv">
																																	{item.assignedLoanProviders &&
																																item
																																	.assignedLoanProviders
																																	.length
																																		? item.assignedLoanProviders.map(
																																			(
																																				value,
																																				index
																																			) => {
																																				return (
																																					<>
																																						{statusToggle &&
																																						showCurrent ===
																																							value.assigned_loan_provider_enc_id ? (
																																								<div className="d-flex align-items-center">
																																									<UpdateLoanStatus
																																										key={`ap-${statusName}-${index}`}
																																										allStatus={loanStatusData.loanStatus}
																																										loanType={item.loan_product ? item.loan_product : ""}
																																										defaultStatus={value.status}
																																										loanId={item.loan_app_enc_id}
																																										label={false}
																																										callback={handleStatusField}
																																										completeLoanDetails={item}
																																									/>
																																									<FaTimes
																																										style={{
																																											color: "red",
																																											cursor: "pointer",
																																											margin: "0 5px"
																																										}}
																																										size={14}
																																										onClick={() => {
																																											setShowCurrent("");
																																											setStatusToggle(false);
																																										}}
																																									/>
																																								</div>
																																							) : (
																																								<h6
																																									key={`apc-${index}`}
																																									onDoubleClick={() => {
																																										setShowCurrent(
																																											value.assigned_loan_provider_enc_id
																																										);
																																										setStatusToggle(
																																											true
																																										);
																																									}}
																																								>
																																									{user.user_type ===
																																								"DSA"
																																										? value
																																											.providerEnc
																																											.name
																																										: ""}
																																									<span
																																										className={`status-color status-color-${value.status}`}
																																									>
																																										{user.user_type ===
																																									"DSA"
																																											? `(${getStatus(
																																												value.status
																																											)})`
																																											: `${getStatus(
																																												value.status
																																											)}`}
																																									</span>
																																									<FaRegEdit
																																										style={{
																																											color: "rgb(41, 121, 255)",
																																											cursor: "pointer"
																																										}}
																																										size={
																																											14
																																										}
																																										onClick={() => {
																																											setShowCurrent(
																																												value.assigned_loan_provider_enc_id
																																											);
																																											setStatusToggle(
																																												true
																																											);
																																										}}
																																									/>
																																								</h6>
																																							)}
																																					</>
																																				);
																																			}
																																		)
																																		: "N/A"}
																																</div>
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"login"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0">
																																{item.login_date
																																	? moment(
																																		item.login_date
																																	).format(
																																		"DD MMM YYYY h:mm a"
																																	)
																																	: "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"bdo_approved_amount"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0 cursor-point">
																																<span className="editInputDiv">
																																	<EditInput
																																		getData={(
																																			id,
																																			value
																																		) =>
																																			reloadData(
																																				item.loan_app_enc_id,
																																				key,
																																				id,
																																				value
																																			)
																																		}
																																		defaultValue={
																																			item.bdo_approved_amount
																																				? item.bdo_approved_amount
																																				: ""
																																		}
																																		api={
																																			"loans/update-loan-amounts"
																																		}
																																		parentId={
																																			item.loan_app_enc_id
																																		}
																																		placeholder={
																																			"BDO Amount"
																																		}
																																		provider={
																																			user.organization_enc_id
																																		}
																																		id={
																																			"bdo_approved_amount"
																																		}
																																		asAmount={
																																			true
																																		}
																																		validateAsNumber={
																																			true
																																		}
																																		type={"onlyAmount"}
																																	/>
																																</span>
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"tl_approved_amount"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0">
																																<div className="editInputDiv">
																																	<EditInput
																																		getData={(
																																			id,
																																			value
																																		) =>
																																			reloadData(
																																				item.loan_app_enc_id,
																																				key,
																																				id,
																																				value
																																			)
																																		}
																																		defaultValue={
																																			item.tl_approved_amount
																																				? item.tl_approved_amount
																																				: ""
																																		}
																																		api={"loans/update-loan-amounts"}
																																		parentId={item.loan_app_enc_id}
																																		placeholder={"TL Amount"}
																																		provider={user.organization_enc_id}
																																		id={"tl_approved_amount"}
																																		asAmount={
																																			true
																																		}
																																		validateAsNumber={
																																			true
																																		}
																																		type={"onlyAmount"}
																																	/>
																																</div>
																															</h6>
																														</td>
																													)}
																													{/* {tableCols['loan_type'].checked && (*/}
																													{/*    <td>*/}
																													{/*    <h6 className="mb-0">{item.loan_type ? item.loan_type : '-'}</h6>*/}
																													{/* </td>*/}
																													{/* )}*/}
																													{tableCols[
																														"last_updated_on"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0">
																																{item.last_updated_on
																																	? moment(
																																		item.last_updated_on
																																	).format(
																																		"DD MMM YYYY h:mm a"
																																	)
																																	: "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"apply_date"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0">
																																{item.apply_date
																																	? moment(
																																		item.apply_date
																																	).format(
																																		"DD MMM YYYY h:mm a"
																																	)
																																	: "-"}
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"share"
																													]
																														.checked && (
																														<td>
																															<h6 className="mb-0">
																																{" "}
																																{user.user_type ===
																																"Financer" ||
																															item.access !==
																																"Read Only" ? (
																																		<>
																																			{item.sharedTo &&
																																	item
																																		.sharedTo
																																		.length >
																																		0 ? (
																																					<div
																																						className="register-count"
																																						onClick={() =>
																																							handleShareAccess(
																																								item
																																							)
																																						}
																																					>
																																						<div className="reg-img">
																																							{item.sharedTo.map(
																																								(
																																									value,
																																									index
																																								) => {
																																									if (index <= 6) {
																																										return (
																																											<span
																																												key={`share-${statusName}-${index}`}
																																												className={`reg${
																																													index +
																																													1
																																												} reg`}
																																											>
																																												<img
																																													src={
																																														value.image
																																													}
																																													alt=""
																																												/>
																																											</span>
																																										);
																																									}
																																								}
																																							)}
																																						</div>
																																						{item.sharedTo && item.sharedTo.length > 7 ? (
																																							<span
																																								style={{
																																									color: "#00a0e3",
																																									fontSize: "13px"
																																								}}
																																							>
																																								{" "}
																																								+{item.sharedTo.length - 7}{" "}
																																							</span>
																																						) : (
																																							""
																																						)}
																																						<span className="cont">
																																							<AiOutlineUsergroupAdd
																																								color="#00a0e3"
																																								size={
																																									20
																																								}
																																							/>
																																						</span>
																																					</div>
																																				) : (
																																					<button
																																						onClick={() =>
																																							handleShareAccess(
																																								item
																																							)
																																						}
																																						className="btn btn-primary btn-sm m-0"
																																					>
																																			Share
																																					</button>
																																				)}
																																		</>
																																	) : (
																																		"-"
																																	)}
																															</h6>
																														</td>
																													)}
																													{tableCols[
																														"actions"
																													]
																														.checked &&
																												tableCols[
																													"actions"
																												]
																													.isVisible ? (
																															<td>
																																<h6 className="mb-0">
																																	<div className="d-flexr w-100">
																																		<button
																																			type="button"
																																			className="link-button"
																																			onClick={() =>
																																				handleApplicationDelete(
																																					item.loan_app_enc_id
																																				)
																																			}
																																		>
																																			<BiTrash
																																				size={
																																					18
																																				}
																																				color="red"
																																			/>
																																		</button>
																																	</div>
																																</h6>
																															</td>
																														) : null}
																												</tr>
																											);
																										}
																									)
																									: "No application to show"}
																								{value.count && parseInt(value.count) > value.page * value.limit ? (
																									<tr className="not-highlight">
																										<td
																											colSpan={
																												15
																											}
																											className="loadMoreTd"
																										>
																											<p
																												className="tableLoadMoreBtn"
																												onClick={() =>
																													getMoreApps(
																														key,
																														loan_product,
																														value.page +
																													1,
																														value.limit
																													)
																												}
																											>
																												Load
																												More
																											</p>
																										</td>
																									</tr>
																								) : (
																									"" 
																								)}
																							</>
																						)}
																				</tbody>
																			</table>
																		</div>
																	</div>
																</div>
															</>
														);
													}
											  )
												: "No applications found"}
										</>)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div ref={ref}>
				<OrganizationSidebarProvider>
					<LoanApplicationDetails
						handleCloseSideBar={() => setLoanDetails("")}
						loanId={loanDetails}
					/>
				</OrganizationSidebarProvider>
			</div>
			<Modal
				show={selectedApp && selectedApp.loan_app_enc_id}
				onHide={onHide}
				size={"md"}
				centered
			>
				<Modal.Header>
					<Modal.Title>Update Application Access</Modal.Title><span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="col-sm-12 col-12">
							<div className="mb-3 d-flex">
								{/* <label className="form-label">Enter Username</label>*/}
								<div className="w-100">
									<AutoComplete
										setFieldValue={setValue}
										link={`company-dashboard/employee-search?type=employees&loan_id=${selectedApp.loan_app_enc_id}&employee_search=`}
										name={"shared_to"}
										method={"GET"}
										placeholder={"Enter Username"}
									/>
								</div>
								{toBeShared ? (
									<select
										style={{ maxWidth: "160px", marginLeft: "10px" }}
										className="form-select"
										name="access"
										id="shared_access"
										{...register("access", { required: "This is required." })}
									>
										<option value="Full Access">Full Access</option>
										<option value="Read Only">Read Only</option>
									</select>
								) : (
									""
								)}
							</div>
						</div>
						{toBeShared ? (
							<div className="col-sm-12 col-12">
								<Col size={12} className="text-center mt-2">
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</Col>
							</div>
						) : (
							""
						)}
					</form>
					{selectedApp.sharedTo && !toBeShared && selectedApp.sharedTo.length > 0 ? (
						<>
							<h3>People with Access</h3>
							<div className="col-sm-12 col-12">
								<div className="mb-3">
									<ul className="usersAccessList p-0">
										{selectedApp.sharedTo.map((value, index) => {
											return (
												<li
													key={`usersList-${index}`}
													className="d-flex align-items-center mb-3"
												>
													<div className="userAvatar">
														<img src={value.image} />
													</div>
													<div className="userDetails">
														<h4 className="m-0">{value.name}</h4>
														<p className="m-0">{value.designation}</p>
													</div>
													<div className="userActions">
														<select
															style={{
																width: "160px",
																marginLeft: "10px"
															}}
															defaultValue={value.access}
															className="form-select"
															id={`shared_app_access${index}`}
															onChange={e =>
																handleAccessChange(
																	e.target.value,
																	value.shared_loan_app_enc_id
																)
															}
														>
															<option value="Read Only">
																Read Only
															</option>
															<option value="Full Access">
																Full Access
															</option>
															<option disabled>_________</option>
															<option value="delete">
																Remove Access
															</option>
														</select>
													</div>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
							<p className=" text-end m-0 fs-5">Created By: <span style={{color: "#000"}}>{loanDetails && loanDetails.created_by ? loanDetails.created_by : createrName}</span></p>
						</>
					) : (
						""
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default LoanApplicationByStatus;
