import React, { useContext, useEffect, useRef, useState } from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
// import { toast } from "react-toastify";
import { FaSort, FaSortAlphaDown, FaSortAlphaDownAlt, FaSortNumericDown, FaSortNumericDownAlt } from "react-icons/fa";
import { MainContext } from "../context/MainState";
import { useParams } from "react-router-dom";

const NameComp = ({
	title,
	id,
	searchArray,
	setSearchArray,
	sortArr,
	setSortArr,
	sortType = null,
	loanTypes = null,
	loanStatus = null,
	branches = null,
	loanProducts = null,
	designations = null,
	grades = null,
	clearFilters = false,
	states= null,
	departmentList = null,
	loanPortfolios= null,
}) => {
	const { user} = useContext(MainContext);
	const [selectedVal, setSelectedVal] = useState("~");
	const [selectedCount, setSelectedCount] = useState("0");
	const [multiSelectValues, setMultiSelectValues] = useState("~");
	const [dateRange, setDateRange] = useState(["~", "~"]);
	const [startDate, endDate] = dateRange;
	const [showMinMaxBox, setShowMinMaxBox] = useState(false);
	const ref = useRef(null);
	const [minMaxAmount, setMinMaxAmount] = useState({});
	const [compareAmount, setCompareAmount] = useState(false);
	const [amountErrorMsg, setAmountErrorMsg] = useState({});
	const [options, setOptions] = useState([]);
	const params = useParams();

	const minmaxarray = 
		["total_collected_cases_sum", "OnTime_collected_cases_sum", "SMA0_collected_cases_sum", "SMA1_collected_cases_sum", "SMA2_collected_cases_sum", "NPA_collected_cases_sum", 
			"total_collected_verified_amount", "OnTime_collected_verified_amount", "SMA0_collected_verified_amount", "SMA1_collected_verified_amount", "SMA2_collected_verified_amount", "NPA_collected_verified_amount", 
			"total_collected_unverified_amount", "OnTime_collected_unverified_amount", "SMA0_collected_unverified_amount", "SMA1_collected_unverified_amount", "SMA2_collected_unverified_amount", "NPA_collected_unverified_amount", 
			"total_interaction_sum", "OnTime_total_interaction_sum", "SMA0_total_interaction_sum", "SMA1_total_interaction_sum", "SMA2_total_interaction_sum", "NPA_total_interaction_sum",
			"total_target_amount", "OnTime_target_amount", "SMA0_target_amount", "SMA1_target_amount", "SMA2_target_amount", "NPA_target_amount",
			"collected_cash", "transfered_cash", "unverified_cash"
		];

	const bolderLabelsIds = ["target_collection_amount", "sales_priority", "collection_priority", "telecaller_priority", "priority", "sales_target_date", "telecaller_target_date", "collection_target_date", "target_date", "sub_bucket", "current_sub_bucket"];

	const branches_list = branches ? [{
		label: "All", value: "all"
	  },{
		label: "Unassigned", value: "unassigned"
	  },  ...branches.map(data => ({
		  label: data.value, value: data.id, enc: data.state_enc_id
	}))]: [];

	const states_list = states ? [{
		label: "All", value: "all"
	},{
		label: "Unassigned", value: "unassigned"
	}, ... states.map(data => ({
		label: data.name, value: data.state_enc_id
	}))] : [];

	const loanportfolioList = loanPortfolios 
		? [{
			label: "All", value: "all"
		}, ...loanPortfolios.map(data => ({
			label: data.name,
			value: data.loan_type_enc_id
		}))] : [];

	const loanProduct_list = loanProducts
		? [{
			label: "All", value: "all"
		}, ...loanProducts.map(data => ({
			label: data.name,
			value: data.financer_loan_product_enc_id
		  }))]
		: [];

	const loanAccountsType = loanTypes
		? [{
			label: "All", value: "all"
		  }, ...loanTypes.map(data => ({
			label: data.loan_type,
			value: data.loan_type
		  }))] 
		  : [];

	const loan_status_list = loanStatus
		? [{
			label: "All", value: "all"
		}, ...loanStatus.map(data => ({
			label: data.loan_status,
			value: data.value
		  }))]
		: [];

	const departmentOptions = departmentList
		? departmentList.map(data => ({
			label: data.label,
			value: data.value
		  }))
		: [];

	const paymentMethods = [
		{
			value: 1,
			text: "QR"
		},
		{
			value: 2,
			text: "Link"
		},
		{
			value: 3,
			text: "POS"
		},
		{
			value: 4,
			text: "Cash"
		},
		{
			value: 5,
			text: "Cheque"
		},
		{
			value: 6,
			text: "Nach"
		},
		{
			value: 7,
			text: "ENach"
		},
		{
			value: 9,
			text: "Scanned QR"
		},
		{
			value: 10,
			text: "Digital Transfer"
		},
		{
			value: 11,
			text: "Paid To Dealer"
		},
		{
			value: 83,
			text: "Net Banking"
		},
		{
			value: 84,
			text: "RTGS/NEFT"
		}
	];

	let bucketListData = [
		{
			label: "All", 
			value: "all"
		},
		{
			label: "Unassigned", 
			value: "unassigned"
		},
		{
			label: "X",
			value: "X"
		},
		{
			label: "1",
			value: "1"
		},
		{
			label: "2",
			value: "2"
		},
		{
			label: "3",
			value: "3"
		},
		{
			label: "4",
			value: "4"
		},
		{
			label: "5",
			value: "5"
		},
		{
			label: "6",
			value: "6"
		},
		{
			label: "7",
			value: "7"
		},
		{
			label: "8",
			value: "8"
		},
	];

	let priorityList = [
		
		{
			label: "High",
			value: "2"
		},
		{
			label: "Medium",
			value: "3"
		},
		{
			label: "Low",
			value: "4"
		}
	];

	const paymentMethods_list = paymentMethods
		? paymentMethods.map(data => ({
			label: data.text,
			value: data.value
		  }))
		: [];

	const paymentStatus = [
		{
			value: "paid",
			text: "Paid"
		},
		{
			value: "pending",
			text: "Pending"
		},
		{
			value: "pipeline",
			text: "Pipeline"
		},
		{
			value: "collected",
			text: "Collected"
		},
		{
			value: "rejected",
			text: "Rejected"
		},
		{
			value: "failed",
			text: "Failed"
		},
		{
			value: "not paid",
			text: "Not Paid"
		}
	];

	const paymentStatus_list = paymentStatus
		? paymentStatus.map(data => ({
			label: data.text,
			value: data.value
		  }))
		: [];

	const paymentDelayReasons = [
		{
			value: "Insufficient Balance",
			text: "Insufficient Balance"
		},
		{
			value: "Loss of Job",
			text: "Loss of Job"
		},
		{
			value: "Business Loss",
			text: "Business Loss"
		},
		{
			value: "Medical Emergency",
			text: "Medical Emergency"
		},
		{
			value: "Forgot to Pay",
			text: "Forgot to Pay"
		},
		{
			value: "Others",
			text: "Others"
		}
	];
	
	const paymentDelayReasons_list = paymentDelayReasons
		? paymentDelayReasons.map(data => ({
			label: data.text,
			value: data.value
		  }))
		: [];

	const paymentMode = [
		{
			value: 1,
			text: "Pay Now"
		},
		{
			value: 2,
			text: "Manual Collection"
		},
		{
			value: 3,
			text: "Pay By EOD"
		},
		{
			value: 4,
			text: "Online/Off Transaction System"
		}
	];

	const paymentMode_list = paymentMode
		? paymentMode.map(data => ({
			label: data.text,
			value: data.value
		  }))
		: [];

	const dealerCategory = [
		{
			value: "Distributor",
			text: "Distributor"
		},
		{
			value: "Main Dealer",
			text: "Main Dealer"
		},
		{
			value: "Sub Dealer",
			text: "Sub Dealer"
		},
		{
			value: "Channel Dealer",
			text: "Channel Dealer"
		}
	];

	const dealerCategory_list = dealerCategory
		? dealerCategory.map(data => ({
			label: data.text,
			value: data.value
		  }))
		: [];

	const priorityListOptions = [
		{ label: "All", value: "all"},
		{ label: "Unassigned", value: "unassigned" },
		{ label: "1", value: "1" },
		{ label: "2", value: "2" },
		{ label: "3", value: "3" },
		{ label: "4", value: "4" },
		{ label: "5", value: "5" }
	];
			
	const Emidatelist =	 [
		{ label: "All", value: "all"},
		{ label: "Unassigned", value: "unassigned" },
		{ label: "01", value: "01" },
		{ label: "05", value: "05" },
		{ label: "11", value: "11" },
		{ label: "15", value: "15" },
		{ label: "21", value: "21" }
	];
	
	const eNachStatus = [
		{
			value: "Active",
			text: "Active"
		},
		{
			value: "Inactive",
			text: "Inactive"
		},
		{
			value: "Pending",
			text: "Pending"
		},
		{
			value: "Issued",
			text: "Issued"
		},
		{
			value: "Failed",
			text: "Failed"
		},
		{
			value: "Cancelled",
			text: "Cancelled"
		}
	];

	const eNachStatusList = eNachStatus
		? eNachStatus.map(data => ({
			label: data.text,
			value: data.value
		  }))
		  : [];

	const setSearchedArray = (value, id) => {
		let data = [];
		if (
			(id === "apply_date" ||
				id === "employee_joining_date" ||
				id === "platform_joining_date" ||
				id === "collection_date" ||
				id === "ptp_date" ||
				id === "created_on" ||
				id === "repossession_date" ||
				id === "disbursement_date" ||
				id === "dealership_date") &&
			value != null
		) {
			value = moment(value).format("YYYY-MM-DD");
		}
		if (id === "loan_product") {
			data["loan_products_enc_id"] = value;
		} else {
			data[id] = value;
		}
		setSearchArray({...searchArray, ...data });
	};

	const [selectAllArr, setSelectAllArr] = useState({});
	const [previousAllArr, setpreviousAllArr] = useState({});
	const selectedCustomData = (selected, list = null) => {
		const duplicateId =
			id == "multi_branches" ? "branch" :
				id == "multi_states" ? "state_enc_id" : 
					id == "loan_product_list" ? "loan_products_enc_id" : 
						id == "status_list" ? "status" : 
							id == "loan_accounts" ? "loan_type" : 
								id == "loan_type_list" ? "loan_type" : 
									id == "emi_payment_method_list" ? "emi_payment_method" : 
										id == "emi_payment_status_list" ? "emi_payment_status" : 
											id == "delay_reason_list" ? "delay_reason" : 
												id == "designations_list" ? "designation_id" : 
													id == "department" ? "department_id" : 
														id == "dealer_category" ? "category" : id;

		let selectAll = selected.filter(item => item.value === "all");
		selectAll = selectAll.length ? selectAll[0] : "";
		let prevSelectAll = previousAllArr && previousAllArr[duplicateId] ? previousAllArr[duplicateId].filter(item => item.value === "all") : ""; 
		let flag = false;

		if(list && selected.length === list.length - 1 && !selectAll && prevSelectAll.length){
			flag = false;
			selected = [];
		}else if((selected.length && selectAll && !selectAllArr[duplicateId]) || (list && selected.length === list.length - 1 && !selectAll)){
			flag = true;
			selected = list;
		}else{
			flag = false;
			selected = selected.filter(item => item.value != "all");
		}
						
		setpreviousAllArr({...previousAllArr, [duplicateId]: selected});		
		setSelectAllArr({...selectAllArr, [duplicateId]: flag});
		setSearchedArray(
			selected.map(option => option.value),
			duplicateId
		);
		setMultiSelectValues(selected);
		setSelectedCount(selected.length);
	};
	
	const setSortedArray = (id, val) => {
		setSortArr({[id]: val});
		
	};
	const useOutsideAlerter = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			setShowMinMaxBox(false);
			removeEvent();
		}
	};
	const removeEvent = () => {
		document.removeEventListener("click", useOutsideAlerter, true);
	};
	const addEvent = () => {
		document.addEventListener("click", useOutsideAlerter, true);
	};
	
	const settingMinMaxArray = (type, id, amount) => {
		let flag = true;
		let erMsg = "";
		if(type === "min_" && minMaxAmount["max_"+id] && !isNaN(amount)){
			if(parseInt(amount) > minMaxAmount["max_"+id]){
				flag = false;
				erMsg = "Min amount is greater";
			};
		}
		if(type === "max_" && minMaxAmount["min_"+id] && !isNaN(amount)){
			if(parseInt(amount) < minMaxAmount["min_"+id]){
				flag = false;
				erMsg = "Max amount is less";
			};
		}
		setAmountErrorMsg({...amountErrorMsg, [type+id]:erMsg});
		setCompareAmount({[type+id]: amount});
	
		if(flag){
			setMinMaxAmount({...minMaxAmount, [type+id]:amount});
			setCompareAmount(false);
			setAmountErrorMsg({...amountErrorMsg, [type+id]:erMsg});
		}
	};
	
	useEffect(() => {
		if (clearFilters) {
			setSelectedVal("~");
			setSelectedCount("0");
			setDateRange(["~", "~"]);
			setMultiSelectValues("~");
			setMinMaxAmount({});
		}
	}, [clearFilters]);
	
	useEffect(() => {
		if (designations && typeof designations === "object") {
			const groupedOptions = Object.entries(designations).map(([group, optionsList]) => ({
			  options: optionsList.map(option => ({ label: option.value, value: option.id, cat: group}))
			}));
	  
			const flattenedOptions = groupedOptions.flatMap(group => group.options);
	  
			setOptions(flattenedOptions);
		  }
	  }, [designations]);
	  

	useEffect(() => {
		if (startDate !== "~" && endDate !== "~") {
			let data = {};
			const prefix =
				id === "sales_target_date" ? "sales_target_"
					: id === "telecaller_target_date" ? "telecaller_target_"
						: id === "collection_target_date" ? "collection_target_"
							: id === "proposed_date" ? "proposed_"
								: id === "last_emi_date" ? "last_emi_date_"
									: id === "target_date" ? "target_"
										: id === "last_emi_received_date" ? "last_emi_received_"
											: id === "collection_date_range" ? "collection_"
												: id === "disbursement_date_range" ? "disbursement_"
													: id === "login_date_range" ? "login_"
														: id === "updated_on" ? "updated_on_"
															: id === "transaction" ? "transaction_"
																: id === "nach_date" ? "nach_"
																	: "";
																
			data[prefix + "start_date"] = startDate
				? moment(startDate).format("YYYY-MM-DD") + " 00:00:00"
				: "";
			data[prefix + "end_date"] = endDate
				? moment(endDate).format("YYYY-MM-DD") + " 23:59:59"
				: "";
			if ((startDate && endDate) || (!startDate && !endDate)) {
				setSearchArray({...searchArray, ...data });
			}
		}
	}, [dateRange, startDate, endDate, id]);

	useEffect(() => {
		if(Object.keys(minMaxAmount).length){
			setSearchArray({...searchArray, ...minMaxAmount});
		};
	}, [minMaxAmount]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (selectedVal !== "~") {
				setSearchedArray(selectedVal, id);
			}
		}, 800);

		return () => clearTimeout(delayDebounceFn);
	}, [selectedVal]);

	useEffect(() => {
		const tempdata = [];
		let idArr = [
			{id: "loan_accounts", list: loanAccountsType, name:"loan_type" }, 
			{id: "multi_branches", list: branches_list, name: "branch"},
			{id: "multi_states", list: states_list, name: "state_enc_id"},
			{id: "product_portfolio_name", list: loanportfolioList, name: "product_portfolio_name"},
		];
		const selected_id = idArr.find(obj => obj.id === id);
		if (searchArray && selected_id) {
			if (searchArray[selected_id.name] && searchArray[selected_id.name].length) {
				searchArray[selected_id.name].forEach(val => {
					let sb = selected_id.list.filter(item => item.value === val);
					tempdata.push({ label: sb[0].label, value: sb[0].value });
				});
			}
			setSelectedCount(searchArray[selected_id.name] ? searchArray[selected_id.name].length : "0");
			setMultiSelectValues(tempdata);
		}	
	}, [searchArray]);

	const renderOptions = (list, value = []) =>{
		return value.length ? list.filter(branch => 
			value.includes(branch.enc)
		) : list;
	};

	const getSubBucketData = () => {
		if (user.user_type === "Financer" || ["phf1148", "phf110", "ghuman", "wishey", "phf1164"].includes(user.username) || params["*"] === "emi-collection") {
			return bucketListData;
		}
		if (user.department === "Call Center") {
			return bucketListData.filter(item => !["X", "5", "6", "7", "8"].includes(item.value));
		}
		return bucketListData.filter(item => item.value !== "X");
	};

	return (
		<div className="w-100 height-set">
			<div className={bolderLabelsIds.includes(id) ? "bold" : ""}>
				{title}
			</div>
			{id === "loan_type" ? (
				<div>
					<select
						className="form-control name-input-field"
						onChange={e => setSelectedVal(e.target.value)}
						style={{ padding: "5px 3px", fontSize: "12px" }}
					>
						<option value="">Select Loan Type</option>
						{loanTypes
							? loanTypes.map((value, index) => {
								return (
									<option
										key={index}
										value={value.loan_type ? value.loan_type : value.name}
									>
										{value.loan_type ? value.loan_type : value.name}
									</option>
								);
							  })
							: ""}
					</select>
				</div>
			)	: id === "loan_accounts" ? (
				<div className="d-flex align-items-center">
					<div className="w90">
						<Multiselect
							className="custom-select-container"
							avoidHighlightFirstOption
							emptyRecordMsg="No Loan Type Found"
							placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
							displayValue="label"
							selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
							options={loanAccountsType}
							showCheckbox
							hideSelectedList
							onRemove={e => selectedCustomData(e, loanAccountsType)}
							onSelect={e => selectedCustomData(e, loanAccountsType)}
						/>
					</div>

					{sortArr ?
						<div className="sortIcon">
							{sortArr.loan_accounts === "1" ? (
								<FaSortAlphaDown size={16} onClick={() => setSortedArray(id, "2")}/>) : 
								sortArr.loan_accounts === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray(id, "")} />) : 
									<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
						</div>
						: ""}
				</div>
			) : id === "product_portfolio_name" ? (
				<div className="d-flex align-items-center">
					<div className="w90">
						<Multiselect
							className="custom-select-container"
							avoidHighlightFirstOption
							emptyRecordMsg="No Loan Type Found"
							placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
							displayValue="label"
							selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
							options={loanportfolioList}
							showCheckbox
							hideSelectedList
							onRemove={e => selectedCustomData(e, loanportfolioList)}
							onSelect={e => selectedCustomData(e, loanportfolioList)}
						/>
					</div>

					{sortArr ?
						<div className="sortIcon">
							{sortArr.product_portfolio_name === "1" ? (
								<FaSortAlphaDown size={16} onClick={() => setSortedArray(id, "2")}/>) : 
								sortArr.product_portfolio_name === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray(id, "")} />) : 
									<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
						</div>
						: ""}
				</div>
			) : id === "loan_product" ? (
				<div>
					<select
						className="form-control name-input-field"
						onChange={e => setSelectedVal(e.target.value)}
						style={{ padding: "5px 3px", fontSize: "12px" }}
					>
						<option value="">Select Loan Products</option>
						{loanProducts
							? loanProducts.map((value, index) => {
								return (
									<option
										key={index}
										value={value.financer_loan_product_enc_id}
									>
										{value.name}
									</option>
								);
							  })
							: ""}
					</select>
				</div>
			) : id === "loan_product_list" ? (
				<div className="d-flex align-items-center">
					<div className="w90">
						<Multiselect
							className="custom-select-container"
							avoidHighlightFirstOption
							emptyRecordMsg="No Product Found"
							placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
							displayValue="label"
							selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
							options={loanProduct_list}
							showCheckbox
							hideSelectedList
							onRemove={e => selectedCustomData(e, loanProduct_list)}
							onSelect={e => selectedCustomData(e, loanProduct_list)}
						/>
					</div>
					
					{sortArr ?
						<div className="sortIcon">
							{sortArr.loan_product_list === "1" ? (
								<FaSortAlphaDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
								sortArr.loan_product_list === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
									<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
						</div>
						: ""}
				</div>
			) : id === "multi_branches" ? (
				<div className="d-flex align-items-center">
					<div className="w-90">
						<Multiselect
							className="custom-select-container"
							avoidHighlightFirstOption
							emptyRecordMsg="No Branches Found"
							placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
							displayValue="label"
							selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
							options={renderOptions(branches_list, searchArray?.state_enc_id)}
							showCheckbox
							hideSelectedList
							closeIcon="circle2"
							onRemove={e => selectedCustomData(e, branches_list)}
							onSelect={e => selectedCustomData(e, branches_list)}
						/>
					</div>
					
					{sortArr ?
						<div className="sortIcon">
							{sortArr.branch === "1" ? (
								<FaSortAlphaDown size={16} onClick={() => setSortedArray("branch", "2")}/>) :
								sortArr.branch === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray("branch", "")} />) :
									<FaSort size={16} onClick={() => setSortedArray("branch", "1")}/>}
							
						</div>
						: ""}
				</div>
			) : id === "branch" ? (
				<div>
					<select
						className="form-control name-input-field"
						onChange={e => setSelectedVal(e.target.value)}
						style={{ padding: "5px 3px", fontSize: "12px" }}
					>
						<option value="">Choose Branch</option>
						{branches
							? branches.map((value, index) => {
								return (
									<option value={value.id} key={`branch-${index}`}>
										{value.value}
									</option>
								);
							  })
							: ""}
					</select>
				</div>
			) : id === "multi_states" ? (
				<div className="d-flex align-items-center">
					<div className="w-90">
						<Multiselect
							className="custom-select-container"
							avoidHighlightFirstOption
							emptyRecordMsg="No States Found"
							placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
							displayValue="label"
							selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
							options={states_list}
							showCheckbox
							hideSelectedList
							closeIcon="circle2"
							onRemove={e => selectedCustomData(e, states_list)}
							onSelect={e => selectedCustomData(e, states_list)}
						/>
					</div>
					{sortArr ?
						<div className="sortIcon">
							{sortArr.state_enc_id === "1" ? (
								<FaSortAlphaDown size={16} onClick={() => setSortedArray("state_enc_id", "2")}/>) :
								sortArr.state_enc_id === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray("state_enc_id", "")} />) :
									<FaSort size={16} onClick={() => setSortedArray("state_enc_id", "1")}/>}
							
						</div>
						: ""}
				</div>
			): id === "nach_approved" ? (
				<div>
					<select
						className="form-control name-input-field"
						onChange={e => setSelectedVal(e.target.value)}
						style={{ padding: "5px 3px", fontSize: "12px" }}
					>
						<option value="">All</option>
						<option value="Inactive">Inactive</option>
						<option value="Active">Active</option>
					</select>
				</div>
			): id === "has_smartphone" ? (
				<div>
					<select
						className="form-control name-input-field"
						onChange={e => setSelectedVal(e.target.value)}
						style={{ padding: "5px 3px", fontSize: "12px" }}
					>
						<option value="">All</option>
						<option value={true}>Yes</option>
						<option value={false}>No</option>
					</select>
				</div>
			) : id === "pick_cash_status" ? (
				<div>
					<select
						className="form-control name-input-field"
						value={selectedVal}
						onChange={e => setSelectedVal(e.target.value)}
						style={{ padding: "5px 3px", fontSize: "12px" }}
					>
						<option value="">All</option>
						<option value="Collected">Collected</option>
						<option value="Initiated">Initiated</option>
						<option value="Pending">Pending</option>
						<option value="ptp">PTP</option>
					</select>
				</div>
			) : id === "rc" ? (
				<div>
					<select
						className="form-control name-input-field"
						value={selectedVal}
						onChange={e => setSelectedVal(e.target.value)}
						style={{ padding: "5px 3px", fontSize: "12px" }}
					>
						<option>Choose RC</option>
						<option value="yes">Yes</option>
						<option value="no">No</option>
					</select>
				</div>
			)
				: id === "repo_status" ? (
					<div>
						<select
							className="form-control name-input-field"
							value={selectedVal}
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option>Choose Status</option>
							<option value="initiated">Initiated</option>
							<option value="completed">Completed</option>
						</select>
					</div>
				) : id === "insurance" ? (
					<div>
						<select
							className="form-control name-input-field"
							value={selectedVal}
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option>Choose Insurance</option>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</div>
				) : id === "emi_payment_method" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option value="">Choose Payment Mode</option>
							<option value="1">QR</option>
							<option value="2">Link</option>
							<option value="3">POS</option>
							<option value="4">Cash</option>
							<option value="5">Cheque</option>
							<option value="6">Nach</option>
							<option value="7">eNach</option>
							<option value="9">Scanned QR</option>
							<option value="10">Digital Transfer</option>
							<option value="11">Paid To Dealer</option>
							<option value="83">Net Banking</option>
							<option value="84">RTGS/NEFT</option>
						</select>
					</div>
				) : id === "emi_payment_method_list" ? (
					<Multiselect
						className="custom-select-container"
						avoidHighlightFirstOption
						emptyRecordMsg="No Product Found"
						placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
						options={paymentMethods_list}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e)}
						onSelect={e => selectedCustomData(e)}
					/>
				) : id === "emi_payment_mode" ? (
					<Multiselect
						className="custom-select-container"
						avoidHighlightFirstOption
						emptyRecordMsg="No Product Found"
						placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
						options={paymentMode_list}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e)}
						onSelect={e => selectedCustomData(e)}
					/>
				) : id === "emi_payment_status" || id === "emi_payment_statuses" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							{id === "emi_payment_statuses" ? (
								<>
									<option value="">Choose Payment Status</option>
									<option value="captured">Paid</option>
									<option value="pending">Pending</option>
									<option value="failed">Failed</option>
									<option value="not paid">Not Paid</option>
								</>
							) : (
								<>
									<option value="">Choose Payment Status</option>
									<option value="paid">Paid</option>
									<option value="pending">Pending</option>
									<option value="pipeline">Pipeline</option>
									<option value="collected">Collected</option>
									<option value="rejected">Rejected</option>
									<option value="failed">Failed</option>
									<option value="not paid">Not Paid</option>
								</>
							)}
						</select>
					</div>
				) : id === "emi_payment_status_list" ? (
					<Multiselect
						className="custom-select-container"
						avoidHighlightFirstOption
						emptyRecordMsg="No Product Found"
						placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
						options={paymentStatus_list}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e)}
						onSelect={e => selectedCustomData(e)}
					/>
				) : id === "delay_reason" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option value="">Choose Delay Reason</option>
							<option value="Insufficient Balance">Insufficient Balance</option>
							<option value="Loss of Job">Loss of Job</option>
							<option value="Business Loss">Business Loss</option>
							<option value="Medical Emergency">Medical Emergency</option>
							<option value="Forgot to Pay">Forgot to Pay</option>
							<option value="Others">Others</option>
						</select>
					</div>
				) : id === "delay_reason_list" ? (
					<Multiselect
						className="custom-select-container"
						avoidHighlightFirstOption
						emptyRecordMsg="No Product Found"
						placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
						options={paymentDelayReasons_list}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e)}
						onSelect={e => selectedCustomData(e)}
					/>
				) : id === "payment_type" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option value="">Choose Payment Type</option>
							<option value="1">Nach</option>
							<option value="2">E-Nach</option>
						</select>
					</div>
				) : id === "category" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option value="">Choose Category</option>
							<option value="Distributor">Distributor</option>
							<option value="Main Dealer">Main Dealer</option>
							<option value="Sub Dealer">Sub Dealer</option>
							<option value="Channel Dealer">Channel Dealer</option>
						</select>
					</div>
				) : id === "enach_status" ? (
					<div className="d-flex align-items-center">
						<div className="w90">
							<Multiselect
								className="custom-select-container"
								avoidHighlightFirstOption
								emptyRecordMsg="No Record Found"
								placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
								displayValue="label"
								selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
								options={eNachStatusList}
								showCheckbox
								hideSelectedList
								onRemove={e => selectedCustomData(e)}
								onSelect={e => selectedCustomData(e)}
							/>
						</div>
						{sortArr ?
							<div className="sortIcon">
								{sortArr[id] === "1" ? (
									<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
									sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
										<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
							</div>
							: ""}
					</div>
				
				) : id === "dealer_category" ? (
					<Multiselect
						className="custom-select-container"
						avoidHighlightFirstOption
						emptyRecordMsg="No Product Found"
						placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
						options={dealerCategory_list}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e)}
						onSelect={e => selectedCustomData(e)}
					/>
				) : id === "dealer_type" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option value="">Choose Dealer Type</option>
							<option value="vehicle">Vehicle</option>
							<option value="electronics">Electronics</option>
						</select>
					</div>
				) : id === "ptp_status" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option value="">Choose Value</option>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</div>
				) : id === "status" ? (
					<div>
						<select
							className="form-control name-input-field"
							onChange={e => setSelectedVal(e.target.value)}
							value={selectedVal}
							style={{ padding: "5px 3px", fontSize: "12px" }}
						>
							<option value="">Select Status</option>
							{loanStatus
								? loanStatus.map((value, index) => {
									return (
										<option key={index} value={value.value}>
											{value.loan_status}
										</option>
									);
							  })
								: ""}
						</select>
					</div>
				) : id === "status_list" ? (
					<div className="d-flex align-items-center">
						<div className="w90">
							<Multiselect
								className="custom-select-container"
								avoidHighlightFirstOption
								emptyRecordMsg="No Status Found"
								placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
								selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
								displayValue="label"
								options={loan_status_list}
								showCheckbox
								hideSelectedList
								onRemove={e => selectedCustomData(e, loan_status_list)}
								onSelect={e => selectedCustomData(e, loan_status_list)}
							/>
						</div>
						{sortArr ?
							<div className="sortIcon">
								{sortArr.loan_status === "1" ? (
									<FaSortAlphaDown size={16} onClick={() => setSortedArray("loan_status", "2")}/>) :
									sortArr.loan_status === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray("loan_status", "")} />) :
										<FaSort size={16} onClick={() => setSortedArray("loan_status", "1")}/>}
							</div>
							: ""}
					</div>
				) : id === "apply_date" ||
			  id === "employee_joining_date" ||
			  id === "platform_joining_date" ||
			  id === "collection_date" ||
			  id === "ptp_date" ||
			  id === "created_on" ||
			  id === "repossession_date" ||
			  id === "disbursement_date" ? (
						<div className="d-flex align-items-center">
							<div className="w90">
								<Datepicker
									selected={selectedVal !== "~" ? selectedVal : null}
									onChange={date => setSelectedVal(date)}
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className={"form-control name-input-field"}
									maxDate={new Date()}
									isClearable={true}
									dateFormat="dd MMM yyyy"
								/>
							</div>
							{sortArr ?
								<div className="sortIcon">
									{sortArr[id] === "1" ? (
										<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
										sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
											<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
								</div>
								: ""}
						</div>
					) : id === "date_with_filter" || id === "disbursement_date_range" || id === "updated_on" || id === "transaction" || id === "collection_date_range" || id === "login_date_range" || id === "nach_date" ? (
						<Datepicker
							className={"form-control name-input-field"}
							selectsRange={true}
							startDate={startDate !== "~" ? startDate : null}
							endDate={endDate !== "~" ? endDate : null}
							onChange={(update) => {
								setDateRange(update);
							}}
							onKeyDown={(e) => {
								e.preventDefault();
						 }}
							isClearable={true}
							dateFormat="dd MMM"
							maxDate={new Date()}
						/>
				  ) : id === "dealership_date" ? (
						<div>
							<Datepicker
								selected={selectedVal !== "~" ? selectedVal : null}
								onChange={date => setSelectedVal(date)}
								dateFormat="MMM yyyy"
								showMonthYearPicker
								dropdownMode="select"
								className={"form-control name-input-field"}
								maxDate={new Date()}
								isClearable={true}
							/>
						</div>
					) : id === "last_emi_date" || id === "proposed_date" || id === "sales_target_date" || id === "telecaller_target_date" || id === "collection_target_date" || id === "target_date" || id === "last_emi_received_date" ? (
						<>
							<div className="d-flex align-items-center">
								<div className="w90">
									<Datepicker
										className={"form-control name-input-field"}
										selectsRange={true}
										startDate={startDate !== "~" ? startDate : null}
										endDate={endDate !== "~" ? endDate : null}
										onChange={(update) => {
											setDateRange(update);
										}}
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										isClearable={true}
										dateFormat="dd MMM"
									/>
								</div>
								{sortArr ?
									<div className="sortIcon">
										{sortArr[id] === "1" ? (
											<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
											sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
												<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
									</div>
									: ""}
							</div>
							{/* <div>
								 <label className="mb-1 d-flex">
									<input
										type="checkbox"
										className="checkbox-input"
										id="Unassigned"
										name="Unassigned"
										value="unassigned"
										onChange={e => {
											if (e.target.checked) {
												setSelectedVal(e.target.value);
											} else {
												setSelectedVal("");
											}
										}}
									/>
									<span className="ml-3">Unassigned</span>
								</label>
							</div> */}
						</>
					) : id === "emi_date" ? (
						<>
							<div className="d-flex align-items-center">
								<div className="w90">
									<Multiselect
										className="custom-select-container"
										avoidHighlightFirstOption
										emptyRecordMsg="No Emi Date Found"
										placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
										selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
										displayValue="label"
										options={Emidatelist}
										showCheckbox
										hideSelectedList
										onRemove={e => selectedCustomData(e, Emidatelist)}
										onSelect={e => selectedCustomData(e, Emidatelist)}
									/>
								</div>
								{sortArr ?
									<div className="sortIcon">
										{sortArr[id] === "1" ? (
											<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
											sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
												<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
									</div>
									: ""}
							</div>
							{/* <div>
								 <label className="mb-1 d-flex">
									<input
										type="checkbox"
										className="checkbox-input"
										id="Unassigned"
										name="Unassigned"
										value="unassigned"
										onChange={e => {
											if (e.target.checked) {
												setSelectedVal(e.target.value);
											} else {
												setSelectedVal("");
											}
										}}
									/>
									<span className="ml-3">Unassigned</span>
								</label>
							</div> */}
						</>


					) : id === "collection_priority" || id === "telecaller_priority" || id === "sales_priority" ? (
						<div className="d-flex align-items-center">
							<div className="w90">
								<Multiselect
									className="custom-select-container"
									avoidHighlightFirstOption
									emptyRecordMsg="No Priority Found"
									placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
									displayValue="label"
									selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
									options={priorityListOptions}
									showCheckbox
									hideSelectedList
									closeIcon="circle2"
									onRemove={e => selectedCustomData(e, priorityListOptions)}
									onSelect={e => selectedCustomData(e, priorityListOptions)}
								/>
							</div>
							{sortArr ?
								<div className="sortIcon">
									{sortArr[id] === "1" ? (
										<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
										sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
											<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
								</div>
								: ""}
							
						</div>
					)  : id === "priority"? (
						<div className="d-flex align-items-center">
							<div className="w90">
								<Multiselect
									className="custom-select-container"
									avoidHighlightFirstOption
									emptyRecordMsg="No Priority Found"
									placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
									displayValue="label"
									selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
									options={priorityList}
									showCheckbox
									hideSelectedList
									closeIcon="circle2"
									onRemove={e => selectedCustomData(e)}
									onSelect={e => selectedCustomData(e)}
								/>
							</div>
							{sortArr ?
								<div className="sortIcon">
									{sortArr[id] === "1" ? (
										<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
										sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
											<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
								</div>
								: ""}
							
						</div>
					) : id === "designations_list" ? (
						<div className="d-flex align-items-center">
							<div className="w-90">
								<Multiselect
									className="custom-select-container label-color"
									avoidHighlightFirstOption
									emptyRecordMsg="No Designation Found"
									placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
									selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
									displayValue="label"
									options={[{label: "Unassigned", value: "unassigned"}, ...options]}
									groupBy="cat"
									showCheckbox
									hideSelectedList
									onRemove={e => selectedCustomData(e)}
									onSelect={e => selectedCustomData(e)}
						      />
							</div>
							{sortArr ?
								<div className="sortIcon">
									{sortArr.designation_id === "1" ? (
										<FaSortAlphaDown size={16} onClick={() => setSortedArray("designation_id", "2")}/>) :
										sortArr.designation_id === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray("designation_id", "")} />) :
											<FaSort size={16} onClick={() => setSortedArray("designation_id", "1")}/>}
								</div>
								: ""}
						</div>
					)
					 : id === "department" ? (
							<div className="d-flex align-items-center">
								<div className="w-90">
									<Multiselect
										className="custom-select-container label-color"
										avoidHighlightFirstOption
										emptyRecordMsg="No Department Found"
										placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
										selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
										displayValue="label"
										options={[{label: "Unassigned", value: "unassigned"}, ...departmentOptions]}
										showCheckbox
										hideSelectedList
										onRemove={e => selectedCustomData(e)}
										onSelect={e => selectedCustomData(e)}
									/>
								</div>
								{sortArr ?
									<div className="sortIcon">
										{sortArr.department === "1" ? (
											<FaSortAlphaDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
											sortArr.department === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
												<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
								
									</div>
									: ""}
							</div>
						) : id === "grade" ? (
							<div>
								<select
									className="form-control name-input-field"
									onChange={e => setSelectedVal(e.target.value)}
									value={selectedVal}
									style={{ padding: "5px 3px", fontSize: "12px" }}
								>
									<option value="">Choose Grade</option>
									{grades
										? grades.map((value, index) => {
											return (
												<option value={value.id} key={`grade-${index}`}>
													{value.value}
												</option>
											);
							  })
										: ""}
								</select>
							</div>
						) : id === "sub_bucket" || id === "bucket" || id === "current_sub_bucket" ? (
							<div className="d-flex align-items-center">
								<div className="w90">
									<Multiselect
										className="custom-select-container"
										avoidHighlightFirstOption
										emptyRecordMsg="No bucket Found"
										placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
										displayValue="label"
										selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
										options={getSubBucketData()}
										showCheckbox
										hideSelectedList
										closeIcon="circle2"
										onRemove={e => selectedCustomData(e, getSubBucketData())}
										onSelect={e => selectedCustomData(e, getSubBucketData())}
									/>
								</div>
								{sortArr ?
									<div className="sortIcon">
										{sortArr[id] === "1" ? (
											<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
											sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
												<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
								
									</div>
									: ""}
							</div>
						) : id === "target_collection_amount" || id === "total_pending_amount" || id === "proposed_amount" || id === "emi_amount"
					|| id === "overdue_amount" || id === "pos" || id === "amount" || id === "bdo_approved_amount" || id === "tl_approved_amount"
						|| id === "soft_approval" || id === "soft_sanction" || id === "valuation" || id === "disbursement_approved" 
							|| id === "ptp_amount" || id === "current_market_value" || id === "km_driven" || id === "last_emi_received_amount" || minmaxarray.includes(id) 
							 ? (
								<>
									<div className="d-flex align-items-center position-relative">
										<div className="w90">
											<input
												type="text" readOnly={true}
												className="form-control name-input-field"
												onClick={() => {setShowMinMaxBox(true), addEvent();}}
												value={(Object.entries(minMaxAmount).length && minMaxAmount["min_"+id] ? "min:"+minMaxAmount["min_"+id] : "") + "  " + (Object.entries(minMaxAmount).length && minMaxAmount["max_"+id] ? "max:"+minMaxAmount["max_"+id] : "")}
											/>
										</div>


										{showMinMaxBox ? (
											<div className="min-max-box" ref={ref}>
												<div className="position-relative mb-3">
													<label>Minimum Amount</label>
													<input
														type="number"
														min={0}
														className="form-control name-input-field mb-0"
														value={compareAmount && compareAmount["min_"+id] ? compareAmount["min_"+id] :
															Object.entries(minMaxAmount).length && minMaxAmount["min_"+id] ? minMaxAmount["min_"+id] : ""}
														onChange={e => settingMinMaxArray("min_", id, e.target.value)}
													/>
													{Object.entries(amountErrorMsg).length && amountErrorMsg["min_"+id] ?
														<span className="error min-max-error">{amountErrorMsg["min_"+id]}</span> : ""}
												</div>
												<div className="position-relative">
													<label>Maximum Amount</label>
													<input
														type="number"
														min={0}
														className="form-control name-input-field mb-0"
														value={compareAmount && compareAmount["max_"+id] ? compareAmount["max_"+id] :
															Object.entries(minMaxAmount).length && minMaxAmount["max_"+id] ? minMaxAmount["max_"+id] : ""}
														onChange={e => settingMinMaxArray("max_", id, e.target.value)}
													/>
													{Object.entries(amountErrorMsg).length && amountErrorMsg["max_"+id] ?
														<span className="error min-max-error">{amountErrorMsg["max_"+id]}</span> : ""}
												</div>
											</div>
										) : ""}
										{sortArr ?
											<div className="sortIcon">
												{sortArr[id] === "1" ? (
													<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
													sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
														<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
							
											</div>
											: ""}
									</div>
									{/* <div>
								 <label className="mb-1 d-flex">
										<input
											type="checkbox"
											className="checkbox-input"
											id="Unassigned"
											name="Unassigned"
											value="unassigned"
											onChange={e => {
												if (e.target.checked) {
													setSelectedVal(e.target.value);
												} else {
													setSelectedVal("");
												}
											}}
										/>
										<span className="ml-3">Unassigned</span>
									</label>
								</div> */}
								</>
							) : (
								<>
									<div className="d-flex align-items-center">
										<div className="w90">
											<input
												type="text"
												className="form-control name-input-field"
												value={selectedVal !== "~" ? selectedVal : ""}
												onChange={e => setSelectedVal(e.target.value)}
											/>
										</div>


										{sortArr && sortType === "alpha" ?
											<div className="sortIcon">
												{sortArr[id] === "1" ? (
													<FaSortAlphaDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
													sortArr[id] === "2" ? (<FaSortAlphaDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
														<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
											</div>
											: sortArr && sortType === "numeric" ?
												<div className="sortIcon">
													{sortArr[id] === "1" ? (
														<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")}/>) :
														sortArr[id] === "2" ? (<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />) :
															<FaSort size={16} onClick={() => setSortedArray(id, "1")}/>}
												</div>
												: ""}
									</div>
									{id === "assigned_bdo" || id === "assigned_caller" || id === "collection_manager" || id === "collection_executive" || id === "reporting_person" ? (
										<div className="d-flex align-items-center justify-content-between">
											<label className="mb-1 d-flex">
												<input
													type="checkbox"
													className="checkbox-input"
													id="Unassigned"
													name="Unassigned"
													value="unassigned"
													checked={selectedVal === "unassigned"}
													onChange={e => {
														if (e.target.checked) {
															setSelectedVal(e.target.value);
														} else {
															setSelectedVal("");
														}
													}}
												/>
												<span className="ml-3">Unassigned</span>
											</label>
											{id === "assigned_bdo" || id === "collection_manager"  ? (
												<label className="mb-1 d-flex">
													<input
														type="checkbox"
														className="checkbox-input"
														id="multi"
														name="multi"
														value="multi"
														checked={selectedVal === "multi"}
														onChange={e => {
															if (e.target.checked) {
																setSelectedVal(e.target.value);
															} else {
																setSelectedVal("");
															}
														}}
													/>
													<span className="ml-3">Multi</span>
												</label>) : ""}
										</div>
									) : ""}
								</>
							)
			}
		</div>
	);
};
export default NameComp;
