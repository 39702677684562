import Navbar from "../../components/Navbar";
import React from "react";
import "../../assets/css/accountStyles.css";
import { Helmet } from "react-helmet";
import { OrganizationOperationsProvider } from "../../context/OrganizationOperations";
import EmiPaymentLinksTable from "../../components/dashboard/EmiPaymentLinksTable";

const EmiPaymentLinks = () => {
	return(
		<>
			<Helmet>
				<title>EMI Payments Links | {process.env.REACT_APP_NAME}</title>
			</Helmet>
			<Navbar />
			<OrganizationOperationsProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<EmiPaymentLinksTable />
					</div>
				</div>
			</OrganizationOperationsProvider>
		</>
	);
};

export default EmiPaymentLinks;