const AppReducer = (state, action) => {
	switch (action.type) {
	case "SET_STATS":
		return {
			...state,
			stats: action.payload
		};
	case "SET_NEW_STATS_AMOUNTS":
		return {
			...state,
			new_stats_amounts: action.payload
		};
	case "SET_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: { ...state.loan_applications, all: action.payload }
		};
	case "SET_PARTNER_LOAN_APPLICATION":
		return {
			...state,
			partner_loan_applications: { ...state.partner_loan_applications, all: action.payload }
		};
	case "SET_DISBURSED_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: {
				...state.loan_applications,
				disbursed: action.payload
			}
		};
	case "SET_NEW_LEAD_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: {
				...state.loan_applications,
				new_lead: action.payload
			}
		};
	case "SET_REJECTED_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: {
				...state.loan_applications,
				rejected: action.payload
			}
		};
	case "SET_COMPLETED_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: {
				...state.loan_applications,
				completed: action.payload
			}
		};
	case "SET_DISBURSED_ONLY_LOAN_APPLICATION": 
		return {
			...state,
			loan_applications: {
				...state.loan_applications,
				disbursed_only: action.payload
			}
		};
	case "SET_TVR_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: { ...state.loan_applications, tvr: action.payload }
		};
	case "SET_FI_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: { ...state.loan_applications, fi: action.payload }
		};
	case "SET_TECHNICAL_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: { ...state.loan_applications, technical: action.payload }
		};
	case "SET_LEGAL_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: { ...state.loan_applications, legal: action.payload }
		};
	case "SET_PD_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: { ...state.loan_applications, pd: action.payload }
		};
	case "SET_TV_SCHEME_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: { ...state.loan_applications, tv_scheme: action.payload }
		};
	case "SET_RELEASE_PAYMENT_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: {
				...state.loan_applications,
				release_payment: action.payload
			}
		};
	case "SET_LOAN_PRODUCTS_PURPOSE_DETAIL":
		return {
			...state,
			productPurposeData: action.payload
		};
	case "SET_LOAN_PRODUCTS_DOCUMENT_DETAIL":
		return {
			...state,
			productDocumentData: action.payload
		};
	case "SET_BRANCHES":
		return {
			...state,
			branches_list: action.payload
		};
	case "SET_CREDIT_REPORTS":
		return {
			...state,
			creditReports: action.payload
		};
	case "REMOVE_USER_DATA":
		return {
			...state,
			user: ""
		};
	case "NEGATIVE_LOCATIONS":
		return {
			...state,
			negativeLocations: action.payload
		};
	case "SET_ASSIGNED_LOAN_TYPES":
		return {
			...state,
			assigned_loan_types: action.payload
		};
	case "SET_FIN_ASSIGNED_LOAN_TYPES":
		return {
			...state,
			financer_assigned_loan_types: action.payload
		};
	case "SET_LOAN_PRODUCTS":
		return {
			...state,
			loan_products: action.payload
		};
	case "SET_STATS_AMOUNTS":
		return {
			...state,
			stats_amounts: action.payload
		};
	case "SET_APPLICATION_STATS":
		return {
			...state,
			application_stats: action.payload
		};
	case "SET_EMI_COLLECTION":
		return {
			...state,
			collection: { ...state.collection, all: action.payload }
		};
	case "SET_EMI_PIPELINE_COLLECTION":
		return {
			...state,
			collection: { ...state.collection, pipeline: action.payload }
		};
	case "SET_EMI_PENDING_COLLECTION":
		return {
			...state,
			collection: { ...state.collection, pending: action.payload }
		};
	case "SET_EMI_EXCEPTION_COLLECTION":
		return {
			...state,
			collection: { ...state.collection, exception: action.payload }
		};
	case "SET_EMI_DISCREPANCY_COLLECTION":
		return {
			...state,
			collection: { ...state.collection, discrepancy: action.payload }
		};
	case "REMOVE_EMI_COLLECTIONS_LIST":
		return {
			...state,
			collection: null
		};
	case "SET_EMI_STATS":
		return {
			...state,
			EmiCollectionStatsData: action.payload
		};
	case "SET_BRANDS_LIST":
		return {
			...state,
			brandsList: action.payload
		};
	case "SET_VEHICLE_TYPE_LIST":
		return {
			...state,
			vehicleTypeList: action.payload
		};
	case "SET_EMI_CASH_COLLECTION_DATA":
		return {
			...state,
			emiCashCollectionData: action.payload,
		};
	case "SET_LOAN_DISBURSED_CASES":
		return {
			...state,
			loanApplicationProcessed: action.payload,
		};
	case "SET_PRODUCT_REPORT_DATA":
		return {
			...state,
			loanProductReport: action.payload,
		};
	case "SET_NOTICES": {
		const imagesOnly = action.payload.length
			? action.payload.filter(value => value.image)
			: [];
		const messagesOnly = action.payload.length
			? action.payload.filter(value => value.notice)
			: [];
		return {
			...state,
			notices: action.payload,
			noticeImages: imagesOnly,
			noticeTextsOnly: messagesOnly
		};
	}
	case "SET_EMI_LOAN_ACCOUNT":
		return {
			...state,
			accountData: {...state.accountData, all: action.payload}
		};
	case "SET_EMI_NEW_CASES":
		return {
			...state,
			accountData: {...state.accountData, new_cases: action.payload}
		};
	case "SET_EMI_UPCOMING":
		return{
			...state,
			accountData: {...state.accountData, upcoming: action.payload}
		};
	case "SET_EMI_NPA":
		return{
			...state,
			accountData: {...state.accountData, npa: action.payload}
		};
	case "SET_EMI_HIGH_PRIORITY":
		return{
			...state,
			accountData: {...state.accountData, high_priority: action.payload}
		};
	case "SET_EMI_OTHER_CASES":
		return{
			...state,
			accountData: {...state.accountData, other_cases: action.payload}
		};
	case "SET_EMI_UPCOMING_PRIORITY":
		return{
			...state,
			accountData: {...state.accountData, upcoming_priority: action.payload}
		};
	case "SET_EMI_HARD_RECOVERY":
		return{
			...state,
			accountData: {...state.accountData, hard_recovery: action.payload}
		};
	case "SET_EMI_DASHBOARD":
		return{
			...state,
			accountData: {...state.accountData, dashboard: action.payload}
		};
	case "SET_EMI_NACH":
		return{
			...state,
			accountData: {...state.accountData, nach: action.payload}
		};
	case "SET_LOAN_ACCOUNT_STATS":
		return {
			...state,
			loanAccountStats: action.payload
		};
	case "SET_LOAN_ACCOUNT_TYPES":
		return {
			...state,
			loanAccountTypes: action.payload
		};
	case "SET_PTP_CASES":
		return {
			...state,
			ptpcases: action.payload
		};
	case "SET_PICK_CASH_CASES":
		return {
			...state,
			pickCash: action.payload
		};
	case "SET_STATES":
		return {
			...state,
			states_list: action.payload
		};
	case "SET_ORGANIZATION_DEPARTMENTS":
		return{
			...state,
			departmentList: action.payload
		};
	case "SET_DESIGNATION_LIST":
		return{
			...state,
			designationList: action.payload
		};
	case "SET_NACH_REQUESTS":
		return{
			...state,
			nach_requests: {...state.nach_requests, registered: action.payload}
		};
	case "SET_PENDING_NACH_REQUESTS":
		return{
			...state,
			nach_requests: {...state.nach_requests, pending: action.payload}
		};
	case "SET_NEW_NACH_REQUESTS":
		return{
			...state,
			new_nach_requests: { ...state.new_nach_requests, registered: action.payload }
		};
	case "SET_PENDING_LOAN_APPLICATION":
		return {
			...state,
			new_nach_requests: { ...state.new_nach_requests, pending: action.payload }
		};
	case "SET_DEALER_COLLECTION_LIST":
		return{
			...state,
			dealerCollectionList: action.payload
		};
	default:
		return state;
	}
};
export default AppReducer;
