import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import React, { useContext, useState } from "react";
import axios from "axios";
import { MainContext } from "../../context/MainState";
import { toast } from "react-toastify";
import MultiStepFormContainer from "../forms/MultiStepApplyForm/MultiStepFormContainer";
import { formatLoanNumber, getLatLong, isLocationEnabled } from "../../helpers/customFunctions";
import { IoCloseCircle} from "react-icons/io5";
import EmiCollectionNewStep1 from "./EmiCollectionNewStep1";
import EmiCollectionNewStep2 from "./EmiCollectionNewStep2";
import EmiCollectionNewStep3 from "./EmiCollectionNewStep3";

const EmiCollectionForm = ({ show, hide, reloadData = null, loan_number = null, type = "" }) => {
	const { user,loanAccountTypes } = useContext(MainContext);
	const { reset } = useForm();
	const [link, setLink] = useState(false);
	const [showLink, setShowLink] = useState("");
	const [qr, setQr] = useState("");
	const [formStep, setFormStep] = useState(0);
	const [allFormData, setAllFormData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [data , setData] = useState({});

	const nextFormStep = () => setFormStep(currentStep => currentStep + 1);

	const prevFormStep = () => setFormStep(currentStep => currentStep - 1);

	const hideLink = () => {
		setLink(false);
	};

	const copy = async () => {
		await navigator.clipboard.writeText(showLink);
		toast.success("Link copied");
	};

	const inputHandler = event => {
		setShowLink(event.target.value);
	};

	const onsubmit = async values => {
		setIsLoading(true);
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			setIsLoading(false);
			return;
		}
		try {
			const { latitude, longitude } = await getLatLong();
			Object.entries(allFormData).map(([key, value]) => {
				values.append(key, value ? value : "");
			});
			values.append("latitude", latitude);
			values.append("longitude", longitude);
			if(type){
				values.append("pickup_enc_id", show);
			}
			values.set("loan_account_number", formatLoanNumber(allFormData.loan_account_number));
			const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/emi-collection`, values);
			setIsLoading(false);
			if (response.data.response && response.data.response.status === 200) {
				toast.success(response.data.response.message ?? "Saved Successfully");
				setFormStep(1);
				reset();
				hide();
				prevFormStep();
				setAllFormData({});
				if (response.data.response.links) {
					setShowLink(response.data.response.links.link);
					setQr(response.data.response.links.qr);
					setLink(true);
				}
				if (reloadData) {
					reloadData();
				}
			} else {
				toast.error(
					response.data?.response?.message ? response.data.response.message.toString() : "An error occurred"
				);
				return false;
			}
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			toast.error(error.message);
		}
	};
	const setFormData = data => {
		if (data.online_offsystem && data.online_offsystem === "11") {
			data["payment_method"] = "11";
		}
		allFormData.delay_reason = data.delay_reason || [];
		if (data.delay_reason && data.delay_reason.includes("Nach/E-Nach")) {
			allFormData.delay_reason.push(data.delay_reason_payment);
		}
		setAllFormData({ ...allFormData, ...data });
	};

	const resetAllData = () => {
		reset();
		hide();
		setFormStep(0);
		setAllFormData({});
	};

	return (
		<>
			<Modal show={show} onHide={resetAllData} size={"lg"} centered>
				<Modal.Body>
					<h3 className="text-center mb-3 fs-3">Collection Details <span className="close-icon" onClick={resetAllData}><IoCloseCircle /></span></h3>
					<MultiStepFormContainer
						currentStep={formStep}
						prevFormStep={prevFormStep}
						maxStep={3}
						data={data}
					>
						{formStep >= 0 && (
							<EmiCollectionNewStep1
								user={user}
								formStep={formStep}
								nextFormStep={nextFormStep}
								setFormData={setFormData}
								loan_types={loanAccountTypes}
								loan_number={loan_number}
								data={data}
								setData={setData}
							/>
						)}
						{formStep >= 1 && (
							<EmiCollectionNewStep2
								user={user}
								formStep={formStep}
								nextFormStep={nextFormStep}
								setFormData={setFormData}
								data={data}
								fullFormData={allFormData}
							/>
						)}
						{formStep >= 2 && (
							<EmiCollectionNewStep3
								isLoading={isLoading}
								user={user}
								formStep={formStep}
								nextFormStep={nextFormStep}
								setFormData={onsubmit}
								fullFormData={allFormData}
							/>
						)}
					</MultiStepFormContainer>
				</Modal.Body>
			</Modal>

			{/* --------------------------------------------------------------------------------- */}

			<Modal show={link} onHide={hideLink} size={"lg"} centered>
				<Modal.Body>
					<fieldset>
						<div className="container">
							<div className="row align-items-center">
								{qr && qr.length ? (
									<div className="col-12 text-center">
										<h3>Payment QR Code</h3>
										<img src={qr} alt="Qr Code" className="qrImage" />
									</div>
								) : (
									""
								)}
								{showLink && showLink.length ? (
									<div className="col-12 mt-3">
										<label className="form-label d-block titles">
											Payment Link
										</label>
										<input
											disabled
											type="text"
											className="form-control"
											value={showLink}
											onChange={inputHandler}
										/>
										<button
											type="button"
											className="btn btn-primary m-auto d-block mt-3"
											onClick={copy}
										>
											Copy
										</button>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</fieldset>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default EmiCollectionForm;
