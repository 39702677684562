import React, { createContext, useReducer } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import LoanProductsReducer from "../reducers/LoanProductsReducer";

// Create context
export const LoanProductsContext = createContext({});

// Provider component
export const OrganizationLoanProductsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(LoanProductsReducer, {});

	// Actions
	const getLoanProductsDetail = async data => {
		const params = {
			financer_loan_product_enc_id: data.id,
			type: data.type
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-products/get-loan-product-details`,
				params
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_PRODUCTS_" + data.type.toUpperCase() + "_DETAIL",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_LOAN_PRODUCTS_" + data.type.toUpperCase() + "_DETAIL",
						payload: []
					});
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	return (
		<LoanProductsContext.Provider
			value={{
				productPurposeData: state.productPurposeData,
				productStatusData: state.productStatusData,
				productDocumentData: state.productDocumentData,
				productImagesData: state.productImagesData,
				productPendenciesData: state.productPendenciesData,
				productFeeData: state.productFeeData,
				productDisbursementCharges: state.productDisbursementCharges,
				getLoanProductsDetail
			}}
		>
			{children}
		</LoanProductsContext.Provider>
	);
};
