import "../../../assets/css/formstyles.css";
import { useForm } from "react-hook-form";
import LoanDetailsPartial from "../LoanDetailsPartial";

export default function Step2Partial({ formStep, nextFormStep, setFormData }) {
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
		clearErrors
	} = useForm();

	const onSubmit = values => {
		setFormData(values);
		nextFormStep();
	};

	return (
		<div className={formStep === 0 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LoanDetailsPartial
					register={register}
					errors={errors}
					getValues={getValues}
					setValue={setValue}
					clearErrors={clearErrors}
				/>
				<button type="submit" className="btn btn-primary">
					Next
				</button>
			</form>
		</div>
	);
}
