import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import IndividualLoanAccountDetails from "../components/IndividualLoanAccountDetails";

const IndividualLoanAccount = () => {
	const params = useParams();
	return(
		<>
			<Helmet>
				<title>Loan Account</title>
			</Helmet>
			<Navbar />
			<IndividualLoanAccountDetails loan_id={params.id}/>
		</>
	);
};

export default IndividualLoanAccount;