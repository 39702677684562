import { Nav } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import CaptureUserImage from "./CaptureUserImage";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MainContext } from "../../context/MainState";
import { toast } from "react-toastify";
import { vehicleProductsList } from "../../data/authData";
import { readOnlyUsers } from "../../helpers/authServices";
import { hasSmartphone } from "../../data/pagesDataOptions";

const BorrowerDetailCard = ({
	loanDetails,
	openSideBar,
	setOpenAddBorrower,
	setSelectedBorrower,
	genderSelect,
	setCibilReportDetail,
	handleCibilCheck,
	cibilLoading,
	value,
	feeStructures,
	creditCheckedOnce,
	changeBorrowerModal,
	applicationBorrower,
	getImageData,
	setReportReload= false
}) => {
	const { user } = useContext(MainContext);
	const [accessToCibil, setAccessToCibil] = useState(false);

	const changeBorrowerStatus = (borrowerType, loanId) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		const data = {
			borrower_type: borrowerType,
			loan_co_app_enc_id: loanId
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/add-co-applicant`,
				data
			)
			.then(() => {
				openSideBar(loanDetails.loan_app_enc_id);
			})
			.catch(error => {
				console.error(error);
				toast.error(error.message);
			});
	};
	const equifaxData =
		value.creditLoanApplicationReports && value.creditLoanApplicationReports.length
			? value.creditLoanApplicationReports.filter(e => e.request_source === "EQUIFAX")
			: [];
	const cibilData =
		value.creditLoanApplicationReports && value.creditLoanApplicationReports.length
			? value.creditLoanApplicationReports.filter(e => e.request_source === "CIBIL")
			: [];
	const crifData =
		value.creditLoanApplicationReports && value.creditLoanApplicationReports.length
			? value.creditLoanApplicationReports.filter(e => e.request_source === "CRIF")
			: [];

	useEffect(() => {
		if (loanDetails && loanDetails.loan_partners && loanDetails.loan_partners.length) {
			const checkAccess = loanDetails.loan_partners.filter(
				value => value.name === "PHF Leasing"
			);
			if (checkAccess.length > 0) {
				setAccessToCibil(true);
			}
		} else {
			setAccessToCibil(false);
		}
	}, [loanDetails]);

	return (
		<>
			<div
				className="col-12 col-xl-4 col-md-6"
				key={`loanCoApplicants-${value.borrower_type}`}
			>
				<div className="borrower-main p-3 mb-3 rounded-3 shadow-el position-relative">
					<div className="co-borrower-name">{value.borrower_type}</div>
					<div className="edit-box cursor-point d-flex">
						{value.borrower_type !== "Borrower" && loanDetails.is_completed !== "1" ? (
							<Nav
								variant="pills"
								className="flex-column set-width drop-margin remove-dropdown-arrow"
							>
								<Nav.Item>
									<Dropdown>
										<Dropdown.Toggle id="dropdown-button-dark-example1">
											<BsThreeDotsVertical className="text-gray-600" />
										</Dropdown.Toggle>
										<Dropdown.Menu className={"loanApplicationsDetails"}>
											{!feeStructures.length || loanDetails.assignedLoanPayments.filter(loan => loan.payment_status === "captured").length || vehicleProductsList.includes(loanDetails.loan_product) ? (
												<Dropdown.Item>
													<span
														onClick={() => {
															changeBorrowerModal(
																value.loan_co_app_enc_id
															);
														}}
													>
														Borrower
													</span>
												</Dropdown.Item>
											) : ""}
											{value.borrower_type !== "Co-Borrower" ? (
												<Dropdown.Item>
													<span
														onClick={() =>
															changeBorrowerStatus(
																"Co-Borrower",
																value.loan_co_app_enc_id
															)
														}
													>
														Co-Borrower
													</span>
												</Dropdown.Item>
											) : (
												""
											)}
											{value.borrower_type !== "Guarantor" ? (
												<Dropdown.Item>
													<span
														onClick={() =>
															changeBorrowerStatus(
																"Guarantor",
																value.loan_co_app_enc_id
															)
														}
													>
														Guarantor
													</span>
												</Dropdown.Item>
											) : (
												""
											)}
											{value.borrower_type !== "Others" ? (
												<Dropdown.Item>
													<span
														onClick={() =>
															changeBorrowerStatus(
																"Others",
																value.loan_co_app_enc_id
															)
														}
													>
														Others
													</span>
												</Dropdown.Item>
											) : (
												""
											)}
										</Dropdown.Menu>
									</Dropdown>
								</Nav.Item>
							</Nav>
						) : ""}
						{loanDetails.is_completed !== "1" ? (
							<span
								className="mx-1"
								onClick={() => {
									setOpenAddBorrower(true);
									setSelectedBorrower(value);
								}}
							>
								{" "}
								<FaEdit />
							</span>
						) : ""}
					</div>
					<div className="borrower-upper disFlex align-items-center mb-3">
						<div className="b-logo">
							<CaptureUserImage
								getImageData={(_, val) => getImageData(value.loan_co_app_enc_id, val)}
								id={value.loan_co_app_enc_id}
								image={value.image}
								details={loanDetails.loan_app_enc_id}
								openSideBar={openSideBar}
								type="Co-Applicant"
							/>
						</div>
						<div className="b-name-details mx-2">
							<h3 className="fs-4" style={{ marginTop: "10px" }}>
								{value.name}
								{value.cibil_score ? (
									<span className="userScores">CIBIL: {value.cibil_score}</span>
								) : (
									""
								)}
							</h3>
							<p>
								Gender :{" "}
								{genderSelect[value.gender] ? genderSelect[value.gender] : "-"}
							</p>
							<p>
								DOB :{" "}
								{value.co_applicant_dob
									? moment(value.co_applicant_dob).format("DD MMM YYYY")
									: "-"}
							</p>
						</div>
					</div>
					<div className="borrower-middle">
						<div className="state-added mb-3">
							<p className="mb-1">
							  Address: <span>{value.address ? value.address : "-"}</span>
							</p>
							<div className="disFlex justify-content-between">
								<p className="mb-1">
									City: <span>{value.city ? value.city : "-"}</span>
								</p>
								<p className="mb-1">
									State: <span>{value.state ? value.state : "-"}</span>
								</p>
							</div>
							<div className="disFlex justify-content-between">
								<p className="mb-1">
									Mobile: <span>{value.phone ? value.phone : "-"}</span>
								</p>
								{value.borrower_type !== "Borrower" && value.relation ? (
									<p className="mb-1">
										Relation : <span>{value.relation}</span>
									</p>
								) : ""}
								<div className="col-md-12">
									{user.user_type === "Financer" ? (
										<p className="mb-1">
										Has Smartphone: {""}
											<span>
												{value.has_smartphone ? hasSmartphone[value.has_smartphone] : "-"}
											</span>
										</p>
									) : ""}
								</div>		
								<div className="col-md-12">
									<p className="mb-1">
									Marital Status : <span>{value.marital_status}</span>
									</p>
								</div>			
								<p className="mb-1">
									Father Name : <span>{value.father_name}</span>
								</p>
								
							</div>
						</div>
						<p className="kyc-adds">
							KYC Added:{" "}
							<span
								style={{
									background: value.pan_number ? "green" : "red",
									marginBottom: "5px"
								}}
							>
								PAN
							</span>
							<span
								style={{
									background: value.aadhaar_number ? "green" : "red",
									marginBottom: "5px"
								}}
							>
								Aadhar
							</span>
							<span
								style={{
									background: value.voter_card_number ? "green" : "red",
									marginBottom: "5px"
								}}
							>
								Voter ID
							</span>
							    <span 
							        style={{ 
							            background: value.driving_license_number ? "green" : "red", 
										 marginBottom: "5px"
							        }} 
						     > 
							  Driving License 
						 </span> 
							    <span 
							        style={{ 
							            background: value.passport_number ? "green" : "red", 
									marginBottom: "5px"
							        }} 
						     > 
							  Passport
						 </span> 
						</p>

						{/* {value.cibil_score || value.equifax_score || value.crif_score ? (*/}
						{/*    <p className="mb-1 disFlex justify-content-start">Scores :*/}
						{/*        {value.cibil_score ? (*/}
						{/*            <p className="userScores">CIBIL: <span style={{color: "#9a9c9f"}}>{value.cibil_score}</span></p>*/}
						{/*        ) : ""}*/}
						{/*        {value.equifax_score ? (*/}
						{/*            <p className="userScores">Equifax: <span style={{color: "#9a9c9f"}}>{value.equifax_score}</span></p>*/}
						{/*        ) : ""}*/}
						{/*        {value.crif_score ? (*/}
						{/*            <p className="userScores">CRIF: <span style={{color: "#9a9c9f"}}>{value.crif_score}</span></p>*/}
						{/*        ) : ""}*/}
						{/*    </p>*/}
						{/* ) : ""}*/}
					</div>
					<hr />
					<div className="cibil-buttons disFlex">
						{process.env.REACT_APP_TYPE === "DEV" ||
						(user.organization_username &&
						(user.organization_username === "phfleasing" || accessToCibil)) ? (
								<>
									{equifaxData.length && equifaxData[0].file_url ? (
										<>
											{parseInt(equifaxData[0].days_till_now) < 30 ? (
												<button
													type="button"
													onClick={() =>
													{setReportReload(true);
														setCibilReportDetail(equifaxData[0].file_url);
													}
													}
													className="btn btn-success btn-sm px-0 fs-6 my-1"
												>
												Equifax R..
												</button>
											) : (
												<Dropdown>
													<Dropdown.Toggle className="btn btn-secondary btn-sm px-0 fs-6 my-1">
													View Equifax or Fetch
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() =>
															{setReportReload(true);
																setCibilReportDetail(
																	equifaxData[0].file_url
																);
															}}
														>
														Equifax R..
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => handleCibilCheck(value)}
														>
														Fetch Again
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											)}
										</>
									) : (
										<>
											{feeStructures.length && !vehicleProductsList.includes(loanDetails.loan_product) &&
											loanDetails.assignedLoanPayments.filter(loan => loan.payment_status === "captured").length === 0 ? (
													<button
														id="Submit"
														name="Submit"
														type="button"
														disabled={true}
														className="btn btn-primary btn-sm px-0 fs-6 my-1"
														onClick={() => toast.error("Login Fee Due")}
													>
												Check Equifax
													</button>
												) : (
													<>
														{cibilLoading ===
												value.loan_co_app_enc_id + "equifax" ? (
																<button
																	type="button"
																	id="Submit"
																	name="Submit"
																	className="btn btn-primary btn-sm px-0 fs-6 my-1"
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		style={{
																			margin: "auto",
																			background: "none",
																			display: "block",
																			shapeRendering: "auto"
																		}}
																		width="23px"
																		height="23px"
																		viewBox="0 0 100 100"
																		preserveAspectRatio="xMidYMid"
																	>
																		<circle
																			cx="50"
																			cy="50"
																			fill="none"
																			stroke="#fff"
																			strokeWidth="15"
																			r="32"
																			strokeDasharray="150.79644737231007 52.26548245743669"
																		>
																			<animateTransform
																				attributeName="transform"
																				type="rotate"
																				repeatCount="indefinite"
																				dur="1s"
																				values="0 50 50;360 50 50"
																				keyTimes="0;1"
																			></animateTransform>
																		</circle>
																	</svg>
																</button>
															) : (
																<button
																	type="button"
																	onClick={() => handleCibilCheck(value)}
																	className="btn btn-primary btn-sm px-0 fs-6 my-1"
																>
														Check Equifax
																</button>
															)}
													</>
												)}
										</>
									)}
									{cibilData.length && cibilData[0].file_url ? (
										<>
											{parseInt(cibilData[0].days_till_now) < 30 ? (
												<button
													type="button"
													onClick={() => {setReportReload(true);
														setCibilReportDetail(cibilData[0].file_url);}}
													className="btn btn-success btn-sm px-0 fs-6 my-1"
												>
												CIBIL R..
												</button>
											) : (
												<Dropdown>
													<Dropdown.Toggle className="btn btn-secondary btn-sm px-0 fs-6 my-1">
													View CIBIL or Fetch
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() =>
															{setReportReload(true);
																setCibilReportDetail(cibilData[0].file_url);}}
														>
														CIBIL R..
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() =>
																handleCibilCheck(value, "cibil")
															}
														>
														Fetch Again
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											)}
										</>
									) : (
										<>
											{feeStructures.length && !vehicleProductsList.includes(loanDetails.loan_product) &&
										(applicationBorrower?.loan_co_app_enc_id !== value.loan_co_app_enc_id &&
											((creditCheckedOnce.status && creditCheckedOnce.id !== value.loan_co_app_enc_id) ||
											applicationBorrower?.gender === value.gender)) &&
										loanDetails.assignedLoanPayments.filter(
											loan => loan.payment_status === "captured"
										).length === 0 ? (
													<button
														id="Submit"
														name="Submit"
														type="button"
														disabled={true}
														className="btn btn-primary btn-sm px-0 fs-6 my-1"
													>
												Check CIBIL
													</button>
												) : (
													<>
														{cibilLoading ===
												value.loan_co_app_enc_id + "cibil" ? (
																<button
																	type="button"
																	id="Submit"
																	name="Submit"
																	className="btn btn-primary btn-sm px-0 fs-6 my-1"
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		style={{
																			margin: "auto",
																			background: "none",
																			display: "block",
																			shapeRendering: "auto"
																		}}
																		width="23px"
																		height="23px"
																		viewBox="0 0 100 100"
																		preserveAspectRatio="xMidYMid"
																	>
																		<circle
																			cx="50"
																			cy="50"
																			fill="none"
																			stroke="#fff"
																			strokeWidth="15"
																			r="32"
																			strokeDasharray="150.79644737231007 52.26548245743669"
																		>
																			<animateTransform
																				attributeName="transform"
																				type="rotate"
																				repeatCount="indefinite"
																				dur="1s"
																				values="0 50 50;360 50 50"
																				keyTimes="0;1"
																			></animateTransform>
																		</circle>
																	</svg>
																</button>
															) : (
																<button
																	type="button"
																	onClick={() => {
																		if (!applicationBorrower?.gender) {
																			toast.error(
																				"Please add Gender of Main Borrower"
																			);
																		} else {
																			handleCibilCheck(value, "cibil");
																		}
																	}}
																	className="btn btn-primary btn-sm px-0 fs-6 my-1"
																>
														Check CIBIL
																</button>
															)}
													</>
												)}
										</>
									)}
									{crifData.length && crifData[0].file_url ? (
										<>
											{parseInt(crifData[0].days_till_now) < 30 ? (
												<button
													type="button"
													onClick={() =>
													{setReportReload(true);
														setCibilReportDetail(crifData[0].file_url);}
													}
													className="btn btn-success btn-sm px-0 fs-6 my-1 flex-xl-fill"
												>
												CRIF Report
												</button>
											) : (
												<Dropdown className="flex-xl-fill">
													<Dropdown.Toggle className="btn btn-secondary btn-sm px-0 fs-6 my-1">
													View CRIF or Fetch
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() =>
															{setReportReload(true);
																setCibilReportDetail(
																	crifData[0].file_url
																);}
															}
														>
														CRIF Report
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() =>
																handleCibilCheck(value, "crif")
															}
														>
														Fetch Again
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											)}
										</>
									) : (
										<>
											{feeStructures.length && !vehicleProductsList.includes(loanDetails.loan_product) &&
										(applicationBorrower?.loan_co_app_enc_id !== value.loan_co_app_enc_id &&
											((creditCheckedOnce.status && creditCheckedOnce.id !== value.loan_co_app_enc_id) ||
											applicationBorrower?.gender === value.gender)) &&
										loanDetails.assignedLoanPayments.filter(
											loan => loan.payment_status === "captured"
										).length === 0 ? (
													<button
														id="Submit"
														name="Submit"
														type="button"
														className="btn btn-primary btn-sm px-0 fs-6 my-1 flex-xl-fill"
														disabled={true}
														onClick={() => toast.error("Login Fee Due")}
													>
												Check CRIF
													</button>
												) : (
													<>
														{cibilLoading ===
												value.loan_co_app_enc_id + "crif" ? (
																<button
																	type="button"
																	id="Submit"
																	name="Submit"
																	className="btn btn-primary btn-sm px-0 fs-6 my-1 flex-xl-fill"
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		style={{
																			margin: "auto",
																			background: "none",
																			display: "block",
																			shapeRendering: "auto"
																		}}
																		width="23px"
																		height="23px"
																		viewBox="0 0 100 100"
																		preserveAspectRatio="xMidYMid"
																	>
																		<circle
																			cx="50"
																			cy="50"
																			fill="none"
																			stroke="#fff"
																			strokeWidth="15"
																			r="32"
																			strokeDasharray="150.79644737231007 52.26548245743669"
																		>
																			<animateTransform
																				attributeName="transform"
																				type="rotate"
																				repeatCount="indefinite"
																				dur="1s"
																				values="0 50 50;360 50 50"
																				keyTimes="0;1"
																			></animateTransform>
																		</circle>
																	</svg>
																</button>
															) : (
																<button
																	type="button"
																	onClick={() => {
																		if (!applicationBorrower?.gender) {
																			toast.error(
																				"Please add Gender of Main Borrower"
																			);
																		} else {
																			handleCibilCheck(value, "crif");
																		}
																	}}
																	className="btn btn-primary btn-sm px-0 fs-6 my-1 flex-xl-fill"
																>
														Check CRIF
																</button>
															)}
													</>
												)}
										</>
									)}
								</>
							) : (
								""
							)}
					</div>
				</div>
			</div>

		</>
	);
};
export default BorrowerDetailCard;
