import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import PickCashTable from "../../components/dashboard/PickCashTable";

const PickCash = () => {
	return (
		<>
			<Helmet>
				<title>Pick Cash</title>
				<meta name="description" content="Pick Cash" />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="shadow-lg rounded-3 py-4 px-6">
						<PickCashTable/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PickCash;
