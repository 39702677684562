const PageLoader = () => {
	return (
		<>
			<div className="loader">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style={{
						margin: "auto",
						background: "none",
						display: "block",
						shaperendering: "auto"
					}}
					width="100px"
					height="100px"
					viewBox="0 0 100 100"
					preserveAspectRatio="xMidYMid"
				>
					<path
						d="M10 50A40 40 0 0 0 90 50A40 44.5 0 0 1 10 50"
						fill="#FF7803"
						stroke="none"
					>
						<animateTransform
							attributeName="transform"
							type="rotate"
							dur="1s"
							repeatCount="indefinite"
							keyTimes="0;1"
							values="0 50 52.25;360 50 52.25"
						></animateTransform>
					</path>
				</svg>
			</div>
		</>
	);
};
export default PageLoader;
