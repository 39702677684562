import React, { useEffect, useState } from "react";
import { pdfToImg } from "../../helpers/pdfToImg";

const PdfToImg = ({ url }) => {
	const [images, setImages] = useState("");
	const handleThumbnail = link => {
		pdfToImg.getImage(link).then(res => {
			setImages(res);
		});
	};
	useEffect(() => {
		handleThumbnail(url);
	}, [url]);
	return <>{images ? <img src={images} /> : <img src="/images/icons/pdf.svg" />}</>;
};
export default PdfToImg;
