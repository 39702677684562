import { useContext, useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { CandidateDashboardContext } from "../context/CandidateDashboardState";
import { Link } from "react-router-dom";

const LoanList = () => {
	const { getLoanApplications, all_applications } = useContext(CandidateDashboardContext);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		getLoanApplications();
	}, []);

	useEffect(() => {
		if (all_applications) {
			setLoading(false);
		}
	}, [all_applications]);

	return(
		<>
			{loading ? (
				<div className="cus-loader vh-100">
					<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
				</div>
			) : (
				<>
					{all_applications && all_applications.map((data, index) => {
						return (
							<div className="individual-loan-card text-white" key={`loan_applications_list_${data.loan_app_enc_id}${index}`} >
								<Link to={`/account/loan-details/${data.loan_app_enc_id}`} className="text-white">
									<div className="individual-loan-card-content">
										<h3 className="fw-bold fs-3 mb-2 text-white">{data.loan_type} Loan:</h3>
										<p className="mb-0 fs-4 fw-500"><span className="fw-600">Loan Acc No.:-</span> {data.loan_account_number}</p>
										<p className="mb-0 fs-4 fw-500">
											<span className="fw-600">Amount:- </span>
											<FaRupeeSign size={12} />
											{parseFloat(data.loan_amount).toLocaleString(
												"en-IN"
											)}
										</p>
										<p className="mb-0 fs-4 fw-500">
											<span className="fw-600">EMI Amount:- </span>
											<FaRupeeSign size={12} />
											{parseFloat(data.emi_amount).toLocaleString(
												"en-IN"
											)}
										</p>
										<div className="d-flex align-items-center justify-content-between">
											<p className="mb-0 fs-4 fw-500">
												<span className="fw-600">Overdue Amount:- </span>
												<FaRupeeSign size={12} />
												{parseFloat(data.overdue_amount).toLocaleString(
													"en-IN"
												)}
											</p>
										</div>
									</div>
								</Link>
							</div> 
						);
					})}
				</>
			)}		
		</>
	);
};
export default LoanList;