import OrganizationStats from "../components/dashboard/OrganizationStats";

const TestStats = () => {
	return (
		<div className="dashboard-dsa py-5 position-relative">
			<div className="container-fluid">
				<OrganizationStats />
			</div>
		</div>
	);
};
export default TestStats;