const OrganizationOperationsReducer = (state, action) => {
	switch (action.type) {
	case "SET_EMI_LINKS":
		return {
			...state,
			emiPaymentLinks: action.payload
		};
	case "SET_NACH_RECORDS":
		return {
			...state,
			nachRecords: action.payload
		};
	default:
		return state;
	}
};
export default OrganizationOperationsReducer;
