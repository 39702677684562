import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { MainContext } from "../../context/MainState";
import { FaEdit } from "react-icons/fa";
import { IoCloseCircle  } from "react-icons/io5";
import { readOnlyUsers } from "../../helpers/authServices";

const EditEmiCollectionDate = ({
	defaultValue,
	getData,
	loanDetails,
	isVehicleCase,
	id,
	api,
	loanDetailStatus = false,
	inheritedCondition = true
}) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	const { user } = useContext(MainContext);
	const [statusToggle, setStatusToggle] = useState(false);
	const [loading, setLoading] = useState(false);
	const [datesList, setDatesList] = useState([]);

	useEffect(() => {
		if (id && user && user.date) {
			const currentDate = new Date(user.date);
			const nextDates = [];

			for (let i = 1; i <= 45; i++) {
				const nextDate = new Date(currentDate);
				nextDate.setDate(currentDate.getDate() + i);

				// Check if it's the 1st, 5th, or 11th date
				if (
					nextDate.getDate() === 1 ||
					nextDate.getDate() === 5 ||
					nextDate.getDate() === 11 ||
					(nextDate.getDate() === 21 && isVehicleCase && user.organization_username === "phfleasing" && loanDetails.provider_enc_id !== "jE3DW981MQMkDnLm1DAlol5zrZyBag")
				) {
					nextDates.push(moment(nextDate).format("YYYY-MM-DD"));
				}
			}
			setDatesList(nextDates);
		}
	}, [id]);

	const onSubmit = values => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm(`First EMI will be collected on ${moment(values.loan_dates).format("DD MMM YYYY")}. Are you sure you want to save?`)) {
			return false;
		}
		let data = {
			id,
			value: values.loan_dates,
			type:"emi_collection_date"
		};

		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				data
			)
			.then(response => {
				setLoading(false);
				getData(id);
				if (response.data.response.status === 200) {
					reset();
					setStatusToggle(false);
					toast.success("Successfully updated");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				// setLoading(false);
				toast.error("An error has occurred, Please try again");
			});
	};
	return (
		<>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div
					style={{ cursor: "pointer", padding: "5px", textAlign: "center" }}
					onClick={
						loanDetailStatus !== 28 && loanDetailStatus !== 32 && inheritedCondition && (!defaultValue || user.user_type === "Financer" || user.specialAccessRole)
							? () => {
								setStatusToggle(true);
							}
							: () => {
								setStatusToggle(false);
							}
					}
				>
					{defaultValue ? moment(defaultValue).format("DD MMM YYYY") : (<FaEdit color="#00a0e3" fontSize="16px" />)}
				</div>
			</div>
			<Modal show={statusToggle} onHide={() => setStatusToggle(false)} size={"lg"} centered>
				<Modal.Header>
					<Modal.Title>Choose EMI Collection Date</Modal.Title><span className="close-icon" onClick={()=>setStatusToggle(false)}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form method="post" id="applyForm" onSubmit={handleSubmit(onSubmit)}>
						<div className="col-12">
							<div className="mb-3">
								<div className="radio-wrapper simple">
									{datesList.map((value, index) => {
										return (
											<div className="radio-item mb-2" key={`dates${index}`}>
												<input
													type="radio"
													name="loan_dates"
													value={value}
													className="radioInput"
													id={`optionVehicleType-${index}-${value}`}
													{...register("loan_dates", {
														required: "This field in required"
													})}
												/>
												<label
													htmlFor={`optionVehicleType-${index}-${value}`}
													className="option option-1"
												>
													<span>{moment(value).format("DD MMM YYYY")}</span>
												</label>
											</div>
										);
									})}
								</div>
								<ErrorMessage
									errors={errors}
									name="loan_dates"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
							{loading ? (
								<button type="button" className="btn btn-primary" disabled={true}>
									<img src="/images/pages/home/loader.svg" />
								</button>
							) : (
								<button
									id="Submit"
									name="Submit"
									type="submit"
									className="btn btn-primary"
								>
									Save
								</button>
							)}
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default EditEmiCollectionDate;
