import "../../../assets/css/formstyles.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MainContext } from "../../../context/MainState";
import { RiLoader4Line } from "react-icons/ri";

export default function Step3({ formStep, formData, currentStep = 2, prevFormStep }) {
	const { register, handleSubmit } = useForm();
	const [borrowersData, setBorrowersData] = useState([]);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [formIsSubmitted, setFormIsSubmitted] = useState([]);
	const { user } = useContext(MainContext);
	const [base64, setBase64] = useState("");
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const [agrementData, setAgrementData] = useState({ agreement_id: "", docID: "" });
	const getPdf = async () => {
		setFormIsLoading(true);
		formData["tenure"] = "Monthly";
		formData["organization_id"] = user.organization_enc_id;
		formData["user_enc_id"] = user.user_enc_id;
		formData["env"] = process.env.REACT_APP_TYPE;
		await axios
			.post("https://services.empoweryouth.com/api/v1/leegality/generate-document", formData)
			.then(response => {
				if (response.data.code === 200) {
					setFormIsLoading(false);
					setAgrementData({
						docID: response.data.response.docID,
						agreement_id: response.data.response.agreement_id
					});
					toast.success("Submit Successfully");
					setBase64(response.data.response.fileUrl);
				} else {
					prevFormStep();
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				prevFormStep();
				toast.error("An error has occurred, Please try again");
			});
	};
	const onComplete = (documentId, irn) => {
		const values = {};
		values["legality_document_id"] = documentId;
		values["empower_loans_tracking_id"] = irn;
		axios.post(
			`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/save-organization-tracking`,
			values
		);
		// .then(response => {
		// if (response.data.status) {
		// 	// toast.success("Submit Successfully");
		// }
		// });
		// .catch(error => {});
	};
	const onSubmit = values => {
		setFormIsLoading(true);
		values["docID"] = agrementData.docID;
		values["agreement_id"] = agrementData.agreement_id;
		values["env"] = process.env.REACT_APP_TYPE;
		values["fileUrl"] = base64;
		axios
			.post("https://services.empoweryouth.com/api/v1/leegality/sign-doc", values)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.status) {
					handleShow();
					onComplete(response.data.data.documentId, response.data.data.irn);
					setFormIsSubmitted(response.data.data.invitations);
					toast.success("Submit Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setFormIsLoading(false);
			});
		// setFormData(values);
		// nextFormStep();
	};
	useEffect(() => {
		const tempArr = [];
		if (formData.borrower.length) {
			for (let i = 0; i < formData.borrower.length; i++) {
				const tempData = formData.borrower[i];
				tempData["type"] = "CB" + (i + 1);
				if (tempData.phone) {
					tempArr.push(tempData);
				}
			}
		}
		if (formData.guarantor.length) {
			for (let i = 0; i < formData.guarantor.length; i++) {
				const tempData = formData.guarantor[i];
				tempData["type"] = "G" + (i + 1);
				if (tempData.phone) {
					tempArr.push(tempData);
				}
			}
		}
		setBorrowersData([
			{
				name: formData.full_name,
				phone: formData.phone,
				aadhaar: formData.aadhaar,
				type: "B"
			},
			...tempArr
		]);
	}, [formData]);
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		getPdf();
	}, []);
	const handleRedirect = link => {
		window.open(link, "_blank").focus();
	};
	return (
		<div className={formStep === currentStep ? "showForm" : "hideForm"}>
			{formIsLoading ? (
				<div className="text-center">
					<RiLoader4Line className="spin" size={140} style={{ color: "#00a0e3" }} />
					<h1 className="mb-2 fw-semi-bold">Please wait..</h1>
				</div>
			) : (
				""
			)}
			{formIsSubmitted.length ? (
				<div className="text-center">
					<svg
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						width="300"
						height="300"
						fill="none"
					>
						<circle cx="12" cy="12" r="8" stroke="#0A0A30" strokeWidth="1.5" />
						<path
							stroke="#265BFF"
							strokeLinecap="round"
							strokeWidth="1.5"
							d="M9.215 12.052l1.822 1.805 3.748-3.714"
							style={{
								animation: "check 2s infinite cubic-bezier(.99,-.1,.01,1.02)"
							}}
							strokeDashoffset="100"
							strokeDasharray="100"
						/>
					</svg>
					<h1 className="mb-2 fw-semi-bold">Invitation Successfully Sent</h1>
				</div>
			) : (
				""
			)}
			{!formIsLoading && formIsSubmitted.length === 0 ? (
				<form onSubmit={handleSubmit(onSubmit)}>
					<iframe
						title="E-sign Pdf"
						style={{ width: "100%", height: "90vh", marginTop: "50px" }}
						src={`${base64}`}
					></iframe>
					<h3>Invites Details</h3>
					{borrowersData.length
						? borrowersData.map((value, index) => {
							return (
								<>
									{value.phone ? (
										<Row key={`gurrantor${value.phone}${index}`}>
											<Col className="mb-1" xl="3" md="6" sm="12">
												<div className="mb-3">
													<label className=" form-label">Name</label>
													<input
														className="form-control"
														type="text"
														id={`borrower[${index}].name`}
														value={value.name}
														readOnly={true}
														{...register(`borrower[${index}].name`)}
													/>
													<input
														type="hidden"
														value={value.type}
														{...register(`borrower.${index}.type`)}
													/>
												</div>
											</Col>
											<Col className="mb-1" xl="3" md="6" sm="12">
												<div className="mb-3">
													<label className=" form-label">Phone</label>{" "}
													<input
														type="tel"
														id={`borrower[${index}].phone`}
														value={value.phone}
														readOnly={true}
														className="form-control"
														{...register(
															`borrower[${index}].phone`
														)}
													/>
												</div>
											</Col>
											<Col className="mb-1" xl="6" md="6" sm="12">
												<div className="mb-3">
													<div className="mb-3">
														<label
															className=" form-label"
															htmlFor="email"
														>
																Verification With
														</label>
														<div className="d-block">
															<div className="radio-wrapper">
																<div className="radio-item">
																	<input
																		type="radio"
																		name={`borrower[${index}].verification_type`}
																		value="OTP"
																		className="radioInput"
																		id={`optionVerificationType-${index}`}
																		{...register(
																			`borrower[${index}].verification_type`,
																			{
																				required:
																						"This is required."
																			}
																		)}
																		defaultChecked={true}
																	/>
																	<label
																		htmlFor={`optionVerificationType-${index}`}
																		className="option option-1"
																	>
																		<span>Otp</span>
																	</label>
																</div>
																<div className="radio-item">
																	<input
																		type="radio"
																		name={`borrower[${index}].verification_type`}
																		value="BIO"
																		className="radioInput"
																		id={`option2VerificationType-${index}`}
																		{...register(
																			`borrower[${index}].verification_type`,
																			{
																				required:
																						"This is required."
																			}
																		)}
																	/>
																	<label
																		htmlFor={`option2VerificationType-${index}`}
																		className="option option-2"
																	>
																		<span>Biometric</span>
																	</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									) : (
										""
									)}
								</>
							);
						  })
						: ""}
					<Col size={12} className="text-center mt-2">
						{/* <button type="submit" className="btn btn-primary" style={{padding: '.75rem 2.25rem'}} onClick={() => {toast.success("Submit Successfully")}}>E-Sign</button>*/}
						<button
							type="submit"
							className="btn btn-primary"
							style={{ padding: ".75rem 2.25rem" }}
						>
							E-Sign
						</button>
					</Col>
				</form>
			) : (
				""
			)}
			<Modal show={show}>
				<Modal.Header>
					<Modal.Title>Sign in process</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{formIsSubmitted && formIsSubmitted.length ? (
						<ul style={{ listStyle: "none", padding: 0 }}>
							{formIsSubmitted.map((value, index) => {
								return (
									<li
										key={`invites-${index}`}
										className="d-flex justify-content-between mb-3"
									>
										<div className="d-flex flex-column">
											<h4 className="mb-0">{value.name}</h4>
											<p>{value.phone}</p>
										</div>
										<div>
											<button
												type="button"
												className="btn btn-primary"
												onClick={() => handleRedirect(value.signUrl)}
											>
												Sign Now
											</button>
										</div>
									</li>
								);
							})}
						</ul>
					) : (
						""
					)}
				</Modal.Body>
				{/* <Modal.Footer>*/}
				{/*    <Button variant="primary">*/}
				{/*        Click To Pay*/}
				{/*    </Button>*/}
				{/* </Modal.Footer>*/}
			</Modal>
		</div>
	);
}
