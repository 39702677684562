import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import PartnerLoanApplicationsTable from "../../components/dashboard/PartnerLoanApplicationsTable";
import { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainState";
import { useNavigate } from "react-router-dom";

const PartnerApplications = () => {
	const { user } = useContext(MainContext);
	const history = useNavigate();
	const accessControl = ["pardeepkaur", "vipin199", "Sharan07", "kavi123", "phf1117", "phf1104"];
	useEffect(() => {
		if (!(user.user_type === "Financer" || accessControl.includes(user.username))) {
			history("/account/dashboard");
		}
	}, []);
	return (
		<div>
			<Helmet>
				<title>Partner Applications</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<PartnerLoanApplicationsTable />
				</div>
			</div>
		</div>
	);
};
export default PartnerApplications;
