import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import AutoComplete from "./AutoComplete";
import axios from "axios";
import { MainContext } from "../../context/MainState";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";

// import Compressor from 'compressorjs';
// import {jsPDF} from "jspdf";

// let Pagewidth;
// let doc;
// let Pageheight;
// let pdf_uri;
// let base_uri = '';

const LoanDetails = ({
	register,
	errors,
	setValue,
	control,
	clearErrors,
	formFor = "user",
	orgLoanTypes = [],
	orgLoanPurposes = []
}) => {
	const phoneRegExp = /^[1-9]\d*$/;
	const { user, getAssignedLoanProducts, loan_products } = useContext(MainContext);
	const [loanType, setLoanType] = useState("");
	const [defaultPurposes, setDefaultPurposes] = useState([]);
	const [allowLoanType, setAllowLoanType] = useState(true);
	const [chooseVehicleDisable, setChooseVehicleDisable] = useState(false);
	const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
	const getCompanyTypeOptions = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}utilities/organization-types`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					setCompanyTypeOptions(response.data.response.organization_types);
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};
	const toTitleCase = str => {
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};
	useEffect(() => {
		const url = window.location.pathname.split("/")[1].replace(/-/g, " ");
		if (url !== "apply" && url !== "account") {
			register("loan_type");
			register("vehicle_type");
			setAllowLoanType(false);
			const val = toTitleCase(url);
			if (
				val === "Car Loan" ||
				val === "New Car Loan" ||
				val === "Used Car Loan" ||
				val === "Loan Against Car"
			) {
				setValue("loan_type", "Four Wheeler");
				setLoanType("Four Wheeler");
				// setVehicleOption('Four Wheeler');
				// setValue('vehicle_type', "Four Wheeler");
				setChooseVehicleDisable(true);
				if (val !== "Car Loan") {
					setValue(
						"vehicle_option",
						val === "Loan Against Car"
							? "Loan Against Car"
							: val === "Used Car Loan"
								? "Used Car"
								: "New Car"
					);
				}
			} else if (val === "Two Wheeler Loan") {
				setValue("loan_type", "Two Wheeler");
				setLoanType("Two Wheeler");
				// setValue('vehicle_type', "Two Wheeler");
				setChooseVehicleDisable(true);
			} else if (val === "E Two Wheeler Loan") {
				setValue("loan_type", "EV Two Wheeler");
				setLoanType("EV Two Wheeler");
				setChooseVehicleDisable(true);
			} else if (val === "E Rickshaw Loan") {
				setValue("loan_type", "E-Rickshaw");
				setLoanType("E-Rickshaw");
				setChooseVehicleDisable(true);
			} else if (val === "E Vehicle Loan" || val === "E Car Loan") {
				setValue("loan_type", "E-vehicle");
				setLoanType("E-vehicle");
				// setVehicleOption('E-vehicle')
				// setValue('vehicle_type', "E-vehicle");
				setChooseVehicleDisable(true);
				if (val === "E Car Loan") {
					setValue("vehicle_option", "Car");
				}
			} else {
				setValue("loan_type", val);
				setLoanType(val);
			}
		} else {
			setAllowLoanType(true);
			setValue("loan_type", "");
			setValue("vehicle_type", "");
			setLoanType("");
		}
	}, [window.location.pathname]);

	const handleLoanProductChange = val => {
		const tempProductPurposes = loan_products.filter(
			value => value.financer_loan_product_enc_id === val
		);
		if (tempProductPurposes.length) {
			setDefaultPurposes(tempProductPurposes[0].financerLoanProductPurposes);
		} else {
			setDefaultPurposes([]);
		}
	};
	const handleLoanTypeChange = val => {
		if (orgLoanPurposes.length > 0) {
			const tempPurposes = orgLoanPurposes.filter(value => value.loan === val);
			if (tempPurposes.length) {
				setDefaultPurposes(tempPurposes[0].financerLoanPurposes);
			} else {
				setDefaultPurposes([]);
			}
		}
		if (val) {
			clearErrors("loan_type");
		}
		setLoanType(val);
		setValue("loan_purpose", null);
		setValue("vehicle_type", null);
		setValue("occupation", null);
		setValue("company_name", null);
		setValue("annual_income", null);
		setValue("designation", null);
		setValue("company_type", null);
		setValue("annual_turnover", null);
		setValue("business_nature", null);
		if (val === "Business Loan") {
			setValue("business_premises", "Owned");
		} else {
			setValue("business_premises", null);
		}
	};
	// const readFileAsDataURL = async (file) => {
	//     let result_base64 = await new Promise((resolve) => {
	//         let fileReader = new FileReader();
	//         fileReader.onload = (e) => resolve(fileReader.result);
	//         fileReader.readAsDataURL(file);
	//     });
	//
	//     base_uri = result_base64;
	//     return result_base64;
	// }
	// const getUri = async (file) => {
	//     let result_base64 = await new Promise((resolve) => {
	//         new Compressor(file, {
	//             quality: 0.6,
	//             success(result) {
	//                 let dataURL = readFileAsDataURL(result).then(data => {
	//                     resolve(data);
	//                 });
	//             },
	//         });
	//     });
	//     return result_base64;
	// }
	// const callback = async (width, height, src, i, fileLength) => {
	//     let result_base64 = await new Promise((resolve) => {
	//         const widthRatio = Pagewidth / width;
	//         const heightRatio = Pageheight / height;
	//         const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
	//         const canvasWidth = width * ratio;
	//         const canvasHeight = height * ratio;
	//         const marginX = (Pagewidth - canvasWidth) / 2;
	//         const marginY = (Pageheight - canvasHeight) / 2;
	//         doc.addImage(src, 'JPEG', marginX, marginY, canvasWidth, canvasHeight, i);
	//
	//         pdf_uri = doc.output('blob');
	//         resolve(true);
	//     });
	//     return result_base64;
	// }
	// const createPDF = async (imgData) => {
	//     if (imgData.length == "undefined" || imgData.length === 0) {
	//         alert("select image");
	//         return false;
	//     }
	//     let result_base64 = await new Promise((resolve) => {
	//         doc = new jsPDF('p', 'pt', 'a4');
	//         Pagewidth = doc.internal.pageSize.width;
	//         Pageheight = doc.internal.pageSize.height;
	//
	//         const img = new Image();
	//         img.src = imgData;
	//         img.length = imgData.length;
	//         img.onload = function () {
	//             img.imgWidth = img.naturalWidth;
	//             img.imgHeight = img.naturalHeight;
	//             callback(img.imgWidth, img.imgHeight, img.src, img.i, img.length).then(data => {
	//                 resolve(true);
	//             });
	//         };
	//     });
	//     return result_base64;
	// }
	// const handleFileInputChange = (input) => {
	//     if (!input.target.files) {
	//         return false;
	//     }
	//     const file = input.target.files[0];
	//     const fileExtenstion = file.name.split('.').pop().toLowerCase();
	//     if (fileExtenstion !== 'pdf') {
	//         getUri(file).then(data2 => {
	//             createPDF(base_uri).then(data => {
	//                 setValue('bill', pdf_uri);
	//                 setBillHandling(pdf_uri)
	//                 // formData.append("image", pdf_uri);
	//                 // file_extension = 'pdf'
	//                 // uploadImage(formData,mainSection,elem,file_extension)
	//             })
	//         });
	//     } else {
	//         setValue('bill', file);
	//         setBillHandling(file)
	//     }
	// }
	useEffect(() => {
		if (loanType === "Medical Loan") {
			register("bill");
		}
		if (loanType === "Business Loan" && !companyTypeOptions.length) {
			getCompanyTypeOptions();
		}
	}, [loanType]);
	useEffect(() => {
		register("designation");
		getAssignedLoanProducts();
	}, []);
	return (
		<>
			{loanType !== "Hospi Shield" || formFor === "org" ? (
				<>
					<h4>Loan Details</h4>
					<div className="row mb-3">
						{user.user_type === "Financer" ||
						user.user_type === "DSA" ||
						user.user_type === "Employee" ? (
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
									<label className="form-label mb-0" htmlFor="loanType">
										Loan Products
									</label>
									<Controller
										name="loan_product_id"
										control={control}
										defaultValue={user.financer_loan_product_enc_id}
										render={({ field: { onChange, value} }) => (
											<Select
												id={"loanProduct"}
												className=""
												// {...register("loan_product_id", {
												// 	required: "This is required."
												// })}
												options={loan_products ? loan_products.filter(product => product.status === "Active").map(value => ({
													value: value.financer_loan_product_enc_id,
													label: value.name
												})) : []}
												isSearchable={true}
												value={loan_products && loan_products.length ? loan_products.find((c) => c.value === value) : []}
												onChange={(val) => {
													onChange(val.value);
													handleLoanProductChange(val.value);
												}
												}
												// onChange={selectedOption => {
												// 	handleLoanProductChange(selectedOption.value);
												// }}
											/>
										)}
										rules={{ required: true }}
									/>
									{errors.loan_product_id ? (
										<p style={{ color: "red", fontSize: "13px" }}>This Field is required</p>
									) : ""}
									{/* <ErrorMessage
										errors={errors}
										name="loan_product_id"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/> */}
								</div>
						
							) : (
								<>
									{allowLoanType ? (
										<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
											<label className="form-label mb-0" htmlFor="loanType">
											Loan Type
											</label>
											{formFor === "org" && orgLoanTypes.length ? (
												<select
													id={"loanType"}
													className="form-select"
													{...register("loan_type", {
														required: "This is required."
													})}
													onChange={e => {
														handleLoanTypeChange(e.target.value, loanType);
													}}
												>
													<option value="">Choose Loan Type</option>
													{orgLoanTypes.map((value, index) => {
														return (
															<option
																value={value.name}
																key={`loan-types-${value.loan_type_enc_id}${index}`}
															>
																{value.name}
															</option>
														);
													})}
												</select>
											) : (
												<select
													id={"loanType"}
													className="form-select"
													{...register("loan_type", {
														required: "This is required."
													})}
													onChange={e => {
														handleLoanTypeChange(e.target.value, loanType);
													}}
												>
													<option value="">Choose Loan Type</option>
													<option value="Vehicle Loan">Vehicle Loan</option>
													<option value="Two Wheeler">Two Wheeler</option>
													<option value="Four Wheeler">Four Wheeler</option>
													<option value="E-vehicle">E-vehicle</option>
													<option value="E-Rickshaw">E-Rickshaw</option>
													<option value="EV Two Wheeler">
													EV Two Wheeler
													</option>
													<option value="Education Loan">
													Education Loan
													</option>
													<option value="Home Loan">Home Loan</option>
													<option value="Business Loan">Business Loan</option>
													<option value="Personal Loan">Personal Loan</option>
													<option value="Medical Loan">Medical Loan</option>
													<option value="Loan Against Property">
													Loan Against Property
													</option>
													<option value="School Fee Loan">
													School Fee Loan
													</option>
													<option value="Teacher Loan">Teacher Loan</option>
													<option value="Gold Loan">Gold Loan</option>
													<option value="Micro Loan">Micro Loan</option>
													<option value="Solar Panel Loan">
													Solar Panel Loan
													</option>
													<option value="Marriage Loan">Marriage Loan</option>
													<option value="Consumer Durable Loan">
													Consumer Durable Loan
													</option>
												</select>
											)}
											<ErrorMessage
												errors={errors}
												name="loan_type"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									) : (
										""
									)}
								</>
							)}
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="loan_amt">
									Loan Amount
								</label>
								<input
									type="number"
									min={1}
									placeholder="Loan Amount"
									className="form-control"
									{...register("loan_amount", {
										required: "This is required.",
										minLength: {
											value: 4,
											message: "Loan Amount is to Less"
										},
										maxLength: {
											value: 8,
											message: "Loan Amount is to High"
										},
										pattern: {
											value: phoneRegExp,
											message: "Loan Amount is invalid"
										}
									})}
									onWheel={e => e.target.blur()}
								/>
								<ErrorMessage
									errors={errors}
									name="loan_amount"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						{/* {loanType === 'Medical Loan' ? (*/}
						{/*    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
						{/*        <div className="mb-3">*/}
						{/*            <label className="form-label mb-0" htmlFor="loan_amt">Choose Bill</label>*/}
						{/*            <input type="file" min={1} placeholder="Choose File"*/}
						{/*                   className="form-control" {...register("temp_file", {*/}
						{/*                required: 'This is required.',*/}
						{/*                validate: {*/}
						{/*                    lessThan10MB: (files) => files[0]?.size < 5000000 || "Max 5MB",*/}
						{/*                    acceptedFormats: (files) =>*/}
						{/*                        ["image/jpeg", "image/png", "application/pdf"].includes(files[0]?.type) ||*/}
						{/*                        "Only PNG, JPEG and PDF",*/}
						{/*                },*/}
						{/*            })} onChange={handleFileInputChange}/>*/}
						{/*            <ErrorMessage*/}
						{/*                errors={errors}*/}
						{/*                name="temp_file"*/}
						{/*                render={({message}) => <p style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
						{/*            />*/}

						{/*        </div>*/}
						{/*    </div>*/}
						{/* ) : ""}*/}
						{/* {loanType === 'Vehicle Loan' && !chooseVehicleDisable ? (*/}
						{/*    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
						{/*        <div className="mb-3">*/}
						{/*            <label className="form-label mb-0" htmlFor="inputGroupSelect01">Choose*/}
						{/*                Vehicle</label>*/}
						{/*            <select className="form-select" name="vehicle_type"*/}
						{/*                    id="inputGroupSelect01" {...register("vehicle_type", {required: 'This is required.',})}*/}
						{/*                    onChange={(e) => setVehicleOption(e.target.value)}>*/}
						{/*                <option value="">Choose</option>*/}
						{/*                <option value="Two Wheeler">Two Wheeler</option>*/}
						{/*                <option value="Four Wheeler">Four Wheeler</option>*/}
						{/*                <option value="E-vehicle">E-Vehicle</option>*/}
						{/*            </select>*/}
						{/*            <ErrorMessage*/}
						{/*                errors={errors}*/}
						{/*                name="vehicle_type"*/}
						{/*                render={({message}) => <p*/}
						{/*                    style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
						{/*            />*/}
						{/*        </div>*/}
						{/*    </div>*/}
						{/* ) : ""}*/}
						{loanType === "Four Wheeler" && !chooseVehicleDisable ? (
							<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
								<div className="mb-3">
									<label
										className="form-label mb-0"
										htmlFor="inputGroupSelectvehicle_option"
									>
										Vehicle Type
									</label>
									<select
										className="form-select"
										name="vehicle_option"
										id="inputGroupSelectvehicle_option"
										{...register("vehicle_option", {
											required: "This is required."
										})}
									>
										<option value="">Choose</option>
										<option value="New Car">New Car</option>
										<option value="Used Car">Used Car</option>
										<option value="Loan Against Car">Loan Against Car</option>
									</select>
									<ErrorMessage
										errors={errors}
										name="vehicle_option"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						) : (
							""
						)}
						{loanType === "E-vehicle" && !chooseVehicleDisable ? (
							<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
								<div className="mb-3">
									<label
										className="form-label mb-0"
										htmlFor="inputGroupSelectvehicle_option"
									>
										Vehicle Type
									</label>
									<select
										className="form-select"
										name="vehicle_option"
										id="inputGroupSelectvehicle_option"
										{...register("vehicle_option", {
											required: "This is required."
										})}
									>
										<option value="">Choose</option>
										<option value="E-Rickshaw">E-Rickshaw</option>
										<option value="Car">Car</option>
										<option value="Two Wheeler">Two Wheeler</option>
									</select>
									<ErrorMessage
										errors={errors}
										name="vehicle_option"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						) : (
							""
						)}
						{defaultPurposes && defaultPurposes.length ? (
							<div className="col-12">
								<div className="mb-3">
									<label
										className="form-label mb-0 d-block"
										htmlFor="loan_purpose12"
									>
										Purpose of Loan
									</label>
									<div className="radio-wrapper">
										{defaultPurposes.map((value, index) => {
											return (
												<div
													className="radio-item mb-2"
													key={`purpose${index}`}
												>
													<input
														type="checkbox"
														name="loan_purpose"
														value={
															value.financer_loan_product_purpose_enc_id
														}
														className="radioInput"
														id={`optionVehicleType-${index}-${value.financer_loan_product_purpose_enc_id}`}
														{...register("loan_purpose", {
															required: "This field in required"
														})}
													/>
													<label
														htmlFor={`optionVehicleType-${index}-${value.financer_loan_product_purpose_enc_id}`}
														className="option option-1"
													>
														<span>{value.purpose}</span>
													</label>
												</div>
											);
										})}
									</div>
									<ErrorMessage
										errors={errors}
										name="loan_purpose"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</>
			) : (
				""
			)}
			{formFor !== "org" ? (
				<>
					<h4>Other Details</h4>
					<div className="row mb-3">
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="annual_income">
									Annual Income
								</label>
								<input
									type="number"
									placeholder="Annual Income"
									className="form-control"
									{...register("annual_income", {
										required: "This is required.",
										minLength: {
											value: 3,
											message: "Annual Income is to Less"
										},
										maxLength: {
											value: 8,
											message: "Annual Income is to High"
										},
										pattern: {
											value: phoneRegExp,
											message: "Annual Income is Invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="annual_income"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				""
			)}
			{loanType === "Home Loan" || loanType === "Loan Against Property" ? (
				<div className="row mb-3">
					{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
					{/*    <div className="mb-3">*/}
					{/*        <label className="form-label mb-0" htmlFor="loan_purpose12">Purpose of Loan</label>*/}
					{/*        <select className="form-select" name="loan_purpose"*/}
					{/*                id="loan_purpose12" {...register("loan_purpose", {required: 'This is required.',})}>*/}
					{/*            <option value="">Choose</option>*/}
					{/*            <option value="Builder Purchase">Builder Purchase</option>*/}
					{/*            <option value="Resale">Resale</option>*/}
					{/*            <option value="Plot Purchase">Plot Purchase</option>*/}
					{/*            <option value="Plot Purchase + Construction">Plot Purchase + Construction</option>*/}
					{/*            <option value="Self Construction">Self Construction</option>*/}
					{/*        </select>*/}
					{/*        <ErrorMessage*/}
					{/*            errors={errors}*/}
					{/*            name="loan_purpose"*/}
					{/*            render={({message}) => <p style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
					{/*        />*/}
					{/*    </div>*/}
					{/* </div>*/}
					{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
					{/*    <div className="mb-3">*/}
					{/*        <label className="form-label mb-0" htmlFor="annual_income">Annual Income</label>*/}
					{/*        <input type="number" placeholder="Annual Income"*/}
					{/*               className="form-control" {...register("annual_income", {*/}
					{/*            required: 'This is required.', minLength: {*/}
					{/*                value: 3,*/}
					{/*                message: 'Annual Income is to Less',*/}
					{/*            }, maxLength: {*/}
					{/*                value: 8,*/}
					{/*                message: 'Annual Income is to High',*/}
					{/*            }, pattern: {*/}
					{/*                value: phoneRegExp,*/}
					{/*                message: 'Annual Income is Invalid',*/}
					{/*            }*/}
					{/*        })} />*/}
					{/*        <ErrorMessage*/}
					{/*            errors={errors}*/}
					{/*            name="annual_income"*/}
					{/*            render={({message}) => <p style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
					{/*        />*/}
					{/*    </div>*/}
					{/* </div>*/}
				</div>
			) : (
				""
			)}
			{/* {loanType === 'Micro Loan' ? (*/}
			{/*    <div className="row mb-3">*/}
			{/*        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
			{/*            <div className="mb-3">*/}
			{/*                <label className="form-label mb-0" htmlFor="loan_purpose23">Loan Purpose</label>*/}
			{/*                <input id="loan_purpose23" placeholder="Loan Purpose"*/}
			{/*                       className="form-control" {...register("loan_purpose", {required: 'This is required.',})}/>*/}
			{/*                <ErrorMessage*/}
			{/*                    errors={errors}*/}
			{/*                    name="loan_purpose"*/}
			{/*                    render={({message}) => <p style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
			{/*                />*/}
			{/*            </div>*/}
			{/*        </div>*/}
			{/*    </div>*/}
			{/* ) : ""}*/}
			{loanType === "Personal Loan" ? (
				<>
					<h4>Other Details</h4>
					<div className="row mb-3">
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="Occupation">
									Occupation
								</label>
								<input
									id="Occupation"
									placeholder="Occupation"
									className="form-control"
									{...register("occupation", { required: "This is required." })}
								/>
								<ErrorMessage
									errors={errors}
									name="occupation"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="company_name">
									Name of Company
								</label>
								<input
									id="company_name"
									placeholder="Company Name"
									className="form-control"
									{...register("company_name")}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="designation">
									Designation
								</label>
								<AutoComplete
									setFieldValue={setValue}
									link={"utilities/designations?keyword="}
									name={"designation"}
									method={"GET"}
									placeholder={"Designation"}
								/>
							</div>
						</div>
						{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
						{/*    <div className="mb-3">*/}
						{/*        <label className="form-label mb-0" htmlFor="annual_income">Annual Income</label>*/}
						{/*        <input type="number" placeholder="Annual Income"*/}
						{/*               className="form-control" {...register("annual_income", {*/}
						{/*            required: 'This is required.', minLength: {*/}
						{/*                value: 3,*/}
						{/*                message: 'Annual Income is to Less',*/}
						{/*            }, maxLength: {*/}
						{/*                value: 8,*/}
						{/*                message: 'Annual Income is to High',*/}
						{/*            }, pattern: {*/}
						{/*                value: phoneRegExp,*/}
						{/*                message: 'Annual Income is Invalid',*/}
						{/*            }*/}
						{/*        })} />*/}
						{/*        <ErrorMessage*/}
						{/*            errors={errors}*/}
						{/*            name="annual_income"*/}
						{/*            render={({message}) => <p style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
						{/*        />*/}
						{/*    </div>*/}
						{/* </div>*/}
					</div>
				</>
			) : (
				""
			)}
			{loanType === "Business Loan" ? (
				<>
					<h4>Company Details</h4>
					<div className="row mb-3">
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="Occupation">
									Name of Company
								</label>
								<input
									id="company_name"
									placeholder="Name of Company"
									className="form-control"
									{...register("company_name", {
										required: "This is required."
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="company_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="loanType">
									Type of Company
								</label>
								<select
									id={"company_type"}
									className="form-control"
									{...register("company_type", {
										required: "This is required."
									})}
								>
									<option value="">Choose</option>
									{companyTypeOptions.length
										? companyTypeOptions.map((value, index) => {
											return (
												<option
													key={`organizationTypes${index}`}
													value={value.organization_type_enc_id}
												>
													{value.organization_type}
												</option>
											);
										  })
										: ""}
								</select>
								<ErrorMessage
									errors={errors}
									name="company_type"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="business_nature">
									Nature of Business
								</label>
								<input
									id="business_nature"
									placeholder="Nature of Business"
									className="form-control"
									{...register("business_nature")}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="loan_amt">
									Annual Turnover
								</label>
								<input
									type="number"
									min={1}
									placeholder="Annual Turnover"
									className="form-control"
									{...register("annual_turnover", {
										required: "This is required.",
										minLength: {
											value: 4,
											message: "Annual Turnover is to Less"
										},
										maxLength: {
											value: 12,
											message: "Annual Turnover is to High"
										},
										pattern: {
											value: phoneRegExp,
											message: "Annual Turnover is invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="annual_turnover"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="company_name">
									Designation
								</label>
								<AutoComplete
									setFieldValue={setValue}
									link={"utilities/designations?keyword="}
									name={"designation"}
									method={"GET"}
									placeholder={"Designation"}
								/>
							</div>
						</div>
						{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
						{/*    <div className="mb-3">*/}
						{/*        <label className="form-label mb-0 d-block">Annual Income</label>*/}
						{/*        <div className="form-check form-check-inline">*/}
						{/*            <input id="business_premisesOwned"*/}
						{/*                   className="form-check-input" {...register("business_premises", {required: true})}*/}
						{/*                   type="radio" value="Owned" defaultChecked={true}/>*/}
						{/*            <label className="form-check-label" htmlFor="business_premisesOwned">Owned</label>*/}
						{/*        </div>*/}
						{/*        <div className="form-check form-check-inline">*/}
						{/*            <input id="business_premisesRented"*/}
						{/*                   className="form-check-input" {...register("business_premises", {required: true})}*/}
						{/*                   type="radio" value="Rented"/>*/}
						{/*            <label className="form-check-label" htmlFor="business_premisesRented">Rented</label>*/}
						{/*        </div>*/}
						{/*    </div>*/}
						{/* </div>*/}
					</div>
				</>
			) : (
				""
			)}
		</>
	);
};
export default LoanDetails;
