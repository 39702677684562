export const lenders = {
	allLenders: [
		{
			id: "1",
			name: "Agile Finserv",
			logo: "https://www.empoweryouth.com/assets/themes/ey/images/pages/index2/AG-logo.png",
			access: ["medical"],
			slug: "agile"
		},
		{
			id: "2",
			name: "PHF Leasing",
			logo: "https://www.empoweryouth.com/assets/themes/ey/images/pages/education-loans/phf-leasing-ltd.png",
			access: ["medical"],
			slug: "phfleasing"
		},
		{
			id: "3",
			name: "We Pay India",
			logo: "https://www.empoweryouth.com/assets/themes/ey/images/pages/education-loans/wepay.png",
			access: ["medical"],
			slug: "wepayindia"
		}
	]
};
export const medicalLoanLimits = {
	limits: [
		{
			amount: "10000",
			access: ["Business Loan", "Bike Loan", "Car Loan", "Vehicle Loan"]
		},
		{
			amount: "20000",
			access: ["Business Loan", "Bike Loan", "Car Loan", "Vehicle Loan"]
		},
		{
			amount: "30000",
			access: ["Business Loan", "Car Loan", "Vehicle Loan"]
		},
		{
			amount: "40000",
			access: ["Business Loan", "Car Loan", "Vehicle Loan"]
		},
		{
			amount: "50000",
			access: ["Business Loan", "Vehicle Loan"]
		},
		{
			amount: "60000",
			access: ["Business Loan"]
		}
	]
};
