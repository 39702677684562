import React, { useContext, useEffect, useRef, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import NameComp from "../NameComp";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

const DeletedEmployees = ({designationList, tabActive,}) => {
	const { getUsers, deleted, isLoading } = useContext(OrganizationUsersContext);
	const { branches_list, getBranches, getOrganizationDepartments, departmentList  } = useContext(OrganizationDashboardContext);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const deletedUser = useRef(false);

	const gradesList = [
		{ id: "unassigned", value: "Unassigned" },
		{ id: "Grade 1", value: "Grade 1" },
		{ id: "Grade 2", value: "Grade 2" },
		{ id: "Grade 3", value: "Grade 3" },
		{ id: "Grade 4", value: "Grade 4" },
		{ id: "Grade 5", value: "Grade 5" }
	];

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Code"}
					id={"employee_code"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.employee_code ? row.employee_code : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.name ? row.name : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<img src={row.image} alt={row.name} className="emp-img" />
						<h6 className="mb-0 text-capitalize text-link">
							{row.name}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Department"}
					id={"department"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					departmentList={departmentList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.department ? row.department : "-"),
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					designations={designationList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.designation ? row.designation : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Grade"}
					id={"grade"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					grades={gradesList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.grade ? row.grade : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Reporting Person"}
					id={"reporting_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.reporting_person ? row.reporting_person : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Phone"}
					id={"phone"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return <h6 className="mb-0 text-capitalize text-link">{row.phone}</h6>;
			},
			selector: row => row.phone,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Email"}
					id={"email"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return <h6 className="mb-0 emiAmt">{row.email}</h6>;
			},
			selector: row => row.email,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Date of Joining"}
					id={"employee_joining_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.employee_joining_date ? row.employee_joining_date : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"UserName"}
					id={"username"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.username ? row.username : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Status"}
					id={"status"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanStatus={[
						{ value: "Active", loan_status: "Active" },
						{ value: "Inactive", loan_status: "Inactive" },
						{ value: "Pending", loan_status: "Pending" }
					]}
					clearFilters={clearFilters}
				/>
			),  
			minWidth: "150px",
			selector: row => (row.status ? row.status : "-"),
			sortable: false
		}
	];


	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getUsers({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			type: "deleted"
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getUsers({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			type: "deleted"
		});
		setRowsPerPage(parseInt(e.target.value));
	};



	// ** Custom Pagination
	const CustomPagination = () => {
	// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(deleted?.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	useEffect(() => {
		if (searchArray && Object.keys(searchArray).length) {
			getUsers({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				type: "deleted",
			});
		}
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray]);

	useEffect(() => {
		if (tabActive === "5" && !deletedUser.current) {
			getUsers({
				page: 1,
				limit: rowsPerPage,
				type: "deleted",
			});
			if (!branches_list) {
				getBranches();
			}
			if (!departmentList || !departmentList.length) {
				getOrganizationDepartments();
			}
			deletedUser.current = true;
		}
	}, [tabActive]);

	const dataToRender = () => {
		if (deleted?.deleted && deleted?.deleted.length > 0) {
			return deleted.deleted;
		} else {
			return deleted?.deleted && deleted?.deleted.length > 0
				? deleted.deleted.slice(0, rowsPerPage)
				: [];
		}
	};
	return (
		   <>
			<div
				// className={`loan-application-table height-fix mb-5 ${
				// 	overflowToggle ? "fixOverflow" : ""
				// }`}
			>
				<Row className="mx-0 mt-1 mb-3">
					<Col sm="9">
						<div className="d-flex align-items-center justify-content-start mb-3">
							<label htmlFor="sort-select">Show</label>
							<select
								className="dataTable-select form-select loan_acc_select"
								style={{
									width: "65px",
									padding: "2px 8px",
									margin: "0px 10px"
								}}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
							<label htmlFor="sort-select">Results</label>
						</div>
					</Col>
					<Col
						className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
						sm="3"
					>
						<div className="reset-filters">
							<div className="filters-container d-flex justify-content-end">
								{showClearBtn ? (
									<button
										className="btn btn-primary btn-xs my-2"
										onClick={e => clearFilter(e)}
									>
								Reset
									</button>
								) : (
									""
								)}
							</div>
						</div>
					</Col>
				</Row>
				<DataTable
					columns={columns}
					data={dataToRender()}
					pagination
					paginationServer
					paginationComponent={CustomPagination}
					highlightOnHover="true"
					persistTableHead={true}
					className="fix-overflow myUser-table"
					progressPending={isLoading === "deleted"}
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={
						<p className="w-100 m-5 text-start">There are no records to display</p>
					}
				/>
			</div>
		</>
	);
};
export default DeletedEmployees;
