import React, { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { ImCross } from "react-icons/im";
import axios from "axios";
import { toast } from "react-toastify";
import { BsCameraFill, BsListCheck } from "react-icons/bs";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MainContext } from "../../context/MainState";
import NoticeBoard from "../NoticeBoard";
import Announcements from "../Announcements";
import CashModalDashboard from "./CashModalDashboard";
import ApproveCashEmployee from "./ApproveCashEmployee";
import InfiniteScroll from "react-infinite-scroll-component";
import { IoStar } from "react-icons/io5";
import { authServices } from "../../helpers/authServices";
import { MdEdit } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { HiBuildingOffice } from "react-icons/hi2";
import MultiDataFilters from "../MultiDataFilters";
import { OverlayTrigger,Tooltip } from "react-bootstrap";
// import { Tab, Tabs } from "react-bootstrap";
// import EmiCollectionStats from "./EmiCollectionStats";
const OrganizationStats = () => {
	const { loan_products, user, updateUser } = useContext(MainContext);
	const { getDashboardStatsAmount, stats_amounts} = useContext(
		OrganizationDashboardContext
	);
	const [taskVal, setTaskVal] = useState("");
	const [tasks, setTasks] = useState([]);
	const [statusToggle, setStatusToggle] = useState(false);
	const [cashCollect, setCashCollect] = useState(false);
	const [cashReceived, setCashReceived] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const [emiListData, setEmiListData] = useState("");
	const [totalCounts, setTotalCounts] = useState({ count: 0, amount: 0 });
	const [userIncentivePoints, setUserIncentivePoints] = useState("");
	const [employeesCashReport, setEmployeesCashReport] = useState([]);
	// const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const submitTask = value => {
		if (!value) {
			return;
		}
		let data = { name: value };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/create`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setTaskVal("");
					toast.success("Task created successfully");
					getTasks();
				}else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while creating task, Please try again");
			});
	};

	const saveTask = e => {
		if (e.keyCode === 13) {
			submitTask(e.target.value);
		}
	};

	const changeTaskStatus = (task_id, value) => {
		let data = { task_enc_id: task_id, is_completed: value };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/is-complete`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					getTasks();
					toast.success(`Task ${value ? "Marked as Completed" : "Incompleted"}`);
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred" + " " + error.message);
			});
	};

	const handleSave = (value, task_id) => {
		let data = { task_enc_id: task_id, name: value };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/update`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Task Updated Successfully");
					getTasks();
					setStatusToggle(false);
					setShowCurrent("654656566");
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getTasks = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/list`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setTasks(response.data.response.tasks);
				} else {
					setTasks([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting tasks list, Please try again");
			});
	};

	const deleteTask = async task_enc_id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/delete`,
				{
					task_enc_id
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Task Deleted Successfully");
					getTasks();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while removing tasks, Please try again");
			});
	};
	
	useEffect(() => {
		if (stats_amounts && Object.keys(stats_amounts).length) {
			let totalCount = 0;
			let totalAmounts = 0;
			Object.keys(stats_amounts)
				.filter(val => val.match(/_count/))
				.forEach(val => {
					totalCount += stats_amounts[val] ? parseInt(stats_amounts[val]) : 0;
				});
			Object.keys(stats_amounts)
				.filter(val => val.match(/_amount/))
				.forEach(val => {
					totalAmounts += stats_amounts[val] ? parseFloat(stats_amounts[val]) : 0;
				});
			setTotalCounts({ count: totalCount, amount: totalAmounts });
		}
	}, [stats_amounts]);


	const getUserIncentivePoints = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee-incentive-points/get-incentive-sum`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setUserIncentivePoints(response.data.response.data);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getJuniorsCashReport = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/employee-cash-stats`,
				{
					page: page,
				}
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEmployeesCashReport([
						...employeesCashReport,
						...response.data.response.data
					]);
				}
			})
			.catch(error => {
				console.error("An Error Occoured:", error);
			});
	};

	const fetchMoreData = () => {
		setPage(page + 1);
	};

	const getDataApi = (filters) => {
		getDashboardStatsAmount({
			start_date: filters.start_date,
			end_date: filters.end_date,
			loan_product: filters.loan_product,
			loan_type: filters.loan_type,
			branch_name: filters.branch_name,
			state_enc_id: filters.state_name
		});
	};

	useEffect(() => {
		getJuniorsCashReport(page);
	}, [page]);

	const getEmiList = () => {
		const data = {
			user_id: user.user_enc_id
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/employee-emi-collection`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEmiListData(response.data.response);
					// setLoading(false);
				} else {
					setEmiListData([]);
				}
			})
			.catch(error => {
				toast.error("An error has occurred while getting employee emi list", error);
			});
	};

	useEffect(() => {
		if (userIncentivePoints === "") {
			getUserIncentivePoints();
		}
	}, []);

	useEffect(() => {
		getTasks();
		if (
			process.env.REACT_APP_TYPE === "DEV" ||
			(user.organization_username && user.organization_username === "phfleasing")
		) {
			getEmiList();
		}
	}, []);

	const handleSaveImage = e => {
		if (e.target.files) {
			if (e.target.files.length !== 0) {
				setFormIsLoading(true);
				const file = e.target.files[0];
				const formData = new FormData();
				formData.append("profile_image", file);
				axios
					.post(
						`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/upload-profile-picture`,
						formData
					)
					.then(response => {
						setFormIsLoading(false);
						if (response.data.response.status === 200) {
							toast.success("Profile updated");
							authServices.findUser(updateUser);
						} else {
							toast.error("An error has occurred, Please try again");
						}
					})
					.catch(error => {
						console.log(error);
						setFormIsLoading(false);
						toast.error("An error has occurred, Please try again");
					});
			}
		}
	};

	return (
		<div className="dsa-stats row mb-6">
			<div className="col-md-4 col-lg-3">
				{user?.user_type === "Employee" ? (
					<div className="col-md-12 mb-1">
						<div className="incentive-points-card shadow-lg rounded-3 d-flex align-items-center">
							<div className="user-db-img img-preview-user text-center shadow-ell rounded-2 position-relative" style={{ flexShrink: 0 }}>
								<label
									className="form-label edit-image"
									htmlFor="change_image"
								>
									<BsCameraFill size={30} className="edit-image-camera" />
								</label>
								<input
									type={"file"}
									id="change_image"
									className="opacity-0 position-absolute input-hide"
									onChange={e => handleSaveImage(e)}
								/>
								{formIsLoading ? (
									<img
										src="/images/pages/home/loader-black.svg"
										alt={process.env.REACT_APP_NAME}
									/>
								) : (
									<img
										src={user.image}
										className="img-fluid"
										alt={user.name}
									/>
								)}
								 <label className="edit-button" htmlFor="change_image"><MdEdit size="16px" /></label>
							</div>
							<div className="d-flex flex-column mb-2">
								<h3 className="ms-2 mb-0 fw-600 text-capitalize">{user.first_name + " " + user.last_name}</h3>
								<p className="mb-0 ms-2 fs-5"><FaUserTie size="14px" className="me-1" />{user.designation}</p>
								<p className="mb-0 ms-2 fw-600 fs-5"><HiBuildingOffice size="14px" className="me-1" />{user.branch_name}</p>
								<p className="ms-2 mb-0 fs-5 d-flex align-items-center fw-600 primary-color flex-wrap"><IoStar size="14px" className="me-1" color="#F6BE00"/> Incentive Points: <span className="dark-text ms-1">{userIncentivePoints ? userIncentivePoints.active_count : "0"}
									{userIncentivePoints.pending_count ? (
										<OverlayTrigger
											placement="top"
											overlay={<Tooltip className="pending-count-tooltip">Pending Points</Tooltip>}
										>
											<span style={{fontSize:"13px"}}> ({userIncentivePoints.pending_count})</span>
										</OverlayTrigger>
									) : ""}
								</span>
									 </p>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				{process.env.REACT_APP_TYPE === "DEV" ||
				(user.organization_username && user.organization_username === "phfleasing") ? (
						<>
							<NoticeBoard />
							<Announcements />
						</>
					) : (
						""
					)}
				<div className="task-main shadow-ell rounded-3 overflow-hidden mb-3 mobile-view-hidden">
					<div className="task-heading-box bg-gray-400 py-3 px-4">
						<h3 className={"m-0"}>
							<BsListCheck /> Tasks
						</h3>
					</div>
					<div className="disFlex mb-3">
						<input
							type="text"
							className={"form-control p-2 w-75 rounded-0"}
							style={{ height: "40px" }}
							onKeyDown={e => saveTask(e)}
							onChange={e => setTaskVal(e.target.value)}
							value={taskVal}
							placeholder={"Type Here"}
						/>
						<button
							className="btn bg-gray-700 text-white btn-sm w-25 rounded-0"
							style={{ height: "40px" }}
							onClick={() => submitTask(taskVal)}
						>
							Add
						</button>
					</div>
					<ul className="task-list list-unstyled">
						<PerfectScrollbar>
							{tasks && tasks.length ? tasks.map(value => {
								return (
									<li
										className="disFlex justify-content-between px-4 mb-2 align-items-center"
										key={`tasklist${value.task_enc_id}`}
									>
										<div className="checkbox-custom checkbox-default text-left position-relative">
											<input
												type="checkbox"
												name="task"
												id={`${value.task_enc_id}`}
												checked={value.is_completed === "1"}
												className="uncheck"
												onChange={e =>
													changeTaskStatus(
														value.task_enc_id,
														e.target.checked
													)
												}
											/>
											{statusToggle && showCurrent === value.task_enc_id ? (
												<input
													style={{
														width: "auto",
														margin: "0px 5px",
														padding: "5px 10px",
														maxWidth: "calc(100% - 50px)"
													}}
													placeholder=""
													defaultValue={value.name}
													onKeyDown={event => {
														if (event.key === "Enter") {
															handleSave(
																event.target.value,
																value.task_enc_id
															);
														} else if (event.key === "Escape") {
															setStatusToggle(false);
															event.preventDefault();
															event.stopPropagation();
														}
													}}
													className="form-control"
												/>
											) : (
												<label
													className={`todo-label ${
														value.is_completed === "1"
															? "line-pass"
															: ""
													}`}
													onDoubleClick={() => {
														setShowCurrent(value.task_enc_id);
														setStatusToggle(true);
													}}
												>
													{value.name}
												</label>
											)}
										</div>
										<div className="todo-actions">
											<button
												type="button"
												className="link-button"
												onClick={() =>
													deleteTask(value.task_enc_id)
												}
											>
												<ImCross size={10} color={"#ff6a6a"} />
											</button>
										</div>
									</li>
								);
							})
								: ""}
						</PerfectScrollbar>
					</ul>
				</div>
				{emiListData?.display_data?.total_sum || employeesCashReport.length ? (
					<div className="shadow-ell rounded-3 overflow-hidden pt-3 pb-2">
						<h3 className="px-3 mb-1">Cash in hand</h3>
						<hr style={{ marginTop: "2px", marginBottom: "4px" }} />
						<div id="scrollableDiv" className="employees-cash-report px-2">
							<InfiniteScroll
								dataLength={employeesCashReport.length}
								next={fetchMoreData}
								hasMore={true}
								scrollableTarget="scrollableDiv"
							>
								{emiListData?.display_data?.total_sum ? (
									<>
										<div className="user-single">
											<div className="user-avatar">
												<img src={user.image} alt={user.first_name} />
											</div>
											<div className="user-description">
												<div>
													Me {user.employee_code ? `(${user.employee_code})` : ""}
													<span>{user.designation}</span>
												</div>
												<div>
													<span>Cash in hand</span>₹{" "}
													{parseFloat(
														emiListData?.display_data?.total_sum
													).toLocaleString("en-IN")}
												</div>
											</div>
										</div>
										<hr style={{ margin: "5px" }} />
									</>
								) : ""}

								{employeesCashReport.map((value, index) => (
									<React.Fragment key={`employeesCash${index}`}>
										<div className="user-single">
											<div className="user-avatar">
												<img src={value.image} alt={value.name} />
											</div>
											<div className="user-description">
												<div>
													{value.name}{" "}
													{value.employee_code
														? `(${value.employee_code})`
														: ""}
													<span>{value.designation}</span>
												</div>
												<div>
													<span>Cash in hand</span>₹{""}
													{parseFloat(
														parseFloat(value.collected_cash) +
														parseFloat(value.received_cash)
													).toLocaleString("en-IN")}
												</div>
											</div>
										</div>
										{employeesCashReport.length !== index + 1 ? (
											<hr style={{ margin: "5px" }} />
										) : (
											""
										)}
									</React.Fragment>
								))}
							</InfiniteScroll>
						</div>
					</div>
				): (
					""
				)}
			</div>
			<div className="col-md-8 col-lg-9">
				<div className="row d-flex align-items-end mb-3">
					<div className="col-md-12 col-12 d-flex align-items-center text-start my-2">
						{emiListData.display_data && emiListData.display_data.total_sum !== 0 ? (
							<div className="pending-amount text-center mx-1">
								<button
									className="btn-primary btn btn-sm m-0"
									onClick={() => setCashCollect(true)}
								>
									Submit Cash
								</button>
							</div>
						) : (
							""
						)}
						{emiListData.display_data &&
						emiListData.display_data.pending_amount_approval !== null ? (
								<div className="pending-amount text-center">
									<button
										className="btn-primary btn btn-sm m-0"
										onClick={() => setCashReceived(true)}
									>
										Received Cash Requests
									</button>
								</div>
							) : (
								""
							)}
					</div>
					<div className="col-md-12 col-12">
						<div className="d-flex align-items-center mb-2 justify-content-end flex-wrap">
							<MultiDataFilters
								loan_products={loan_products}
								getData={getDataApi}
								type={["LoanProduct", "branches", "states"]}
								defaultDate={{value: "1", label: "This Month"}}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/total-applications.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">{totalCounts.count}</p>
							<p className="card-head mb-1">
								<span>Total Leads</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹ {parseFloat(totalCounts.amount).toLocaleString("en-IN")}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/new-leads.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.new_lead_count
									? stats_amounts.new_lead_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>New Leads</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.new_lead_amount ? parseFloat(stats_amounts.new_lead_amount).toLocaleString("en-IN") : "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/log-in.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.login_count
									? stats_amounts.login_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>Login</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.login_amount
										? parseFloat(stats_amounts.login_amount).toLocaleString(
											"en-IN"
										  )
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/under-process.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.under_process_count
									? stats_amounts.under_process_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>Under Process</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.under_process_amount
										? parseFloat(
											stats_amounts.under_process_amount
										  ).toLocaleString("en-IN")
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/soft-approvals.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.soft_approval_count
									? stats_amounts.soft_approval_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>TVR / Soft Approval</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.soft_approval_amount
										? parseFloat(
											stats_amounts.soft_approval_amount
										  ).toLocaleString("en-IN")
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/soft-sanctioned.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.soft_sanction_count
									? stats_amounts.soft_sanction_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>Conditional / Soft Sanction</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.soft_sanctioned_amount
										? parseFloat(
											stats_amounts.soft_sanctioned_amount
										  ).toLocaleString("en-IN")
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/sanctioned.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.sanctioned_count
									? stats_amounts.sanctioned_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>Sanctioned</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.sanctioned_amount
										? parseFloat(
											stats_amounts.sanctioned_amount
										  ).toLocaleString("en-IN")
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/disbursed-approval.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.disbursement_approval_count
									? stats_amounts.disbursement_approval_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>Disbursement Approval</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.disbursed_approval_amount
										? parseFloat(
											stats_amounts.disbursed_approval_amount
										  ).toLocaleString("en-IN")
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/disbursed.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.disbursed_count
									? stats_amounts.disbursed_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>Disbursed</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.disbursed_amount
										? parseFloat(
											stats_amounts.insurance_charges_amount
												? parseFloat(
													stats_amounts.insurance_charges_amount
												) + parseFloat(stats_amounts.disbursed_amount)
												: stats_amounts.disbursed_amount
										  ).toLocaleString("en-IN")
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/rejected.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.rejected_count
									? stats_amounts.rejected_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>Rejected</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.rejected_amount
										? parseFloat(stats_amounts.rejected_amount).toLocaleString(
											"en-IN"
										  )
										: "0"}
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/cni.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.cni_count
									? stats_amounts.cni_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>CNI</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.cni_amount
										? parseFloat(stats_amounts.cni_amount).toLocaleString(
											"en-IN"
										  )
										: "0"}
								</p>
							</div>
						</div>
					</div>
					{/* <div className="col-lg-3 col-sm-6 col-6">
						<div className="card-body">
							<img
								src="/images/pages/dashboard/insurancecharges.png"
								className="card-right-img"
								alt="ds"
							/>
							<p className="card-stat mb-0">
								{stats_amounts && stats_amounts.insurance_charges_count
									? stats_amounts.insurance_charges_count
									: "0"}
							</p>
							<p className="card-head mb-1">
								<span>
									Insurance & <br />
									Incident Charges
								</span>
							</p>
							<div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									₹{" "}
									{stats_amounts && stats_amounts.insurance_charges_amount
										? parseFloat(
											stats_amounts.insurance_charges_amount
										  ).toLocaleString("en-IN")
										: "0"}
								</p>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			{process.env.REACT_APP_TYPE === "DEV" ||
			(user.organization_username && user.organization_username === "phfleasing") ? (
					<>
						<CashModalDashboard
							getEmiList={getEmiList}
							cashCollect={cashCollect}
							setCashCollect={setCashCollect}
							emiListData={emiListData}
						/>

						<ApproveCashEmployee
							getEmiList={getEmiList}
							setCashReceived={setCashReceived}
							cashReceived={cashReceived}
						/>
					</>
				) : (
					""
				)}
		</div>
	);
};
export default OrganizationStats;
