import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import EmandateRecordsTable from "../../components/dashboard/EmandateRecordsTable";
import { OrganizationOperationsProvider } from "../../context/OrganizationOperations";

const EmandateRecords = () => {

	return (
		<>
			<Helmet>
				<title>E-Mandate Records</title>
			</Helmet>
			<Navbar />
			<OrganizationOperationsProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5">
							<EmandateRecordsTable />
						</div>
					</div>
				</div>
			</OrganizationOperationsProvider>
		</>
	);
};

export default EmandateRecords;
