import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet";
import "../../../assets/css/accountStyles.css";
import { useContext } from "react";
import { MainContext } from "../../../context/MainState";
import { Link } from "react-router-dom";
import { OrganizationAppsProvider } from "../../../context/OrganizationAppsState";
import AppsTable from "../../../components/dashboard/apps/AppsTable";

const List = () => {
	const { user } = useContext(MainContext);

	return (
		<div>
			<Helmet>
				<title>Apps List | {process.env.REACT_APP_NAME}</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<OrganizationAppsProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className={"d-flex justify-content-between mb-3"}>
							<h1 className="head-title mb-0">My Apps</h1>
							<div className="dashboard-sub-action d-flex">
								{user.user_type === "Financer" || user.user_type === "DSA" ? (
									<Link
										to="/account/users"
										className="btn btn-primary btn-sm d-flex align-items-center mx-1"
									>
										My Users
									</Link>
								) : (
									""
								)}
								{user.organization_username && user.organization_username === "phfleasing" ? (
									<>
										<Link
											to="/account/apps/create"
											className="btn btn-primary btn-sm d-flex align-items-center mx-1"
										>
											Create App
										</Link>
										<Link
											to="/account/e-signing"
											className="btn btn-primary btn-sm d-flex align-items-center mx-1"
										>
											Document E-Sign
										</Link>
										<Link
											to="/account/e-sign/list"
											className="btn btn-primary btn-sm d-flex align-items-center mx-1"
										>
											E-Sign List
										</Link>
									</>
								) : (
									""
								)}
							</div>
						</div>

						<div className="shadow-lg rounded-3 py-6 px-6">
							<AppsTable />
						</div>
					</div>
				</div>
			</OrganizationAppsProvider>
			<Footer />
		</div>
	);
};
export default List;
