import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MainContext } from "../../context/MainState";
import { toast } from "react-toastify";
import Select from "react-select";
import { vehicleProductsList, propertyProductsList } from "../../data/authData";
import { readOnlyUsers } from "../../helpers/authServices";
import { getLatLong, isLocationEnabled } from "../../helpers/customFunctions";

const UpdateLoanStatus = ({
	// allStatus,
	defaultStatus,
	loanId,
	loanType,
	label = true,
	callback = () => {
		return true;
	},
	completeLoanDetails=null,
}) => {
	const { user, loan_status, handlePageLoaded} = useContext(MainContext);
	const [upcomingStatus, setUpcomingStatus] = useState([]);
	const [existingValue, setExistingValue] = useState(defaultStatus);
	const [statusOptions, setStatusOptions] = useState([]);
	const authorizedUsers = ["shgarima21", "phf607", "PHF491", "PHF141", "Kanchan", "phf672", "kavi123", "Sharan07", "vishal22", "PHF310", "Phf24", "pardeepkaur", "phf1254"];
	const importantStatuses = [26, 27, 31, 32, 33];
	const disburmentStatuses = [31];

	const vehicleRequiredFields = [
		"vehicle_delivery_date",
		"invoice_number",
		"invoice_date",
		"motor_number",
		"battery_number",
		"rc_number",
		"chassis_number",
		"model_year",
		"ex_showroom_price",
		"on_road_price",
		"number_of_emis",
		"emi_amount",
		"pf",
		"roi",
		"margin_money",
		"disbursement_approved"
	];

	const fieldNameMapping = {
		"disbursement_approved": "Disbursement Approval Amount",
		"ex_showroom_price": "Ex-Showroom Price",
		"model_year": "Year of Model",
		"pf": "PF",
		"roi": "ROI",
	};
	

	const changeStatus = async val => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (val === parseInt(existingValue)) {
			return false;
		}

		if (disburmentStatuses.includes(val) && vehicleProductsList.includes(loanType)) {
			const missingFields = vehicleRequiredFields
				.filter(field => !completeLoanDetails?.[field])
				.map(field => 
					fieldNameMapping[field] || 
					field.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())
				);
			if (missingFields.length) {
			  toast.error(`${missingFields.join(", ")} is Required.`);
			  return false;
			}
		  }

		if (disburmentStatuses.includes(val) && propertyProductsList.includes(loanType) && !completeLoanDetails?.has_tv_scheme) {
			toast.error("TV Scheme is Required.");
			return false;
		}

		if(disburmentStatuses.includes(val) && propertyProductsList.includes(loanType) && !completeLoanDetails?.asset_type){
			toast.error("Asset Type is Required.");
			return false;
		}

		if(disburmentStatuses.includes(val) && propertyProductsList.includes(loanType) && !completeLoanDetails?.mortgage_type){
			toast.error("Mortgage Type is Required.");
			return false;
		}
		
		if (importantStatuses.includes(val) && vehicleProductsList.includes(loanType) && !authorizedUsers.includes(user.username) && user.user_type !== "Financer") {
			toast.error("You don't have access to update this status");
			return false;
		}
		
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			handlePageLoaded(false);
			return;
		}
		setExistingValue(val);
		handlePageLoaded(true);
		try {
			const { latitude, longitude } = await getLatLong();
			const data = { loan_id: loanId, status: val, latitude, longitude };
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-provider-status`,
				data
			);
			handlePageLoaded(false);
			callback(true);
			if (response.data.response?.status === 200) {
				toast.success("Loan Status Updated");
			} else {
				toast.error(response.data.response?.error ? response.data.response?.error : "An error has occurred");
			}
		} catch (error) {
			handlePageLoaded(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		if (loan_status && loan_status.length > 0) {
			const assignedStatus = loan_status.find(item => item.loan === loanType);
			if (assignedStatus) {
				const { financerLoanProductStatuses } = assignedStatus;
				if (financerLoanProductStatuses) {
					setUpcomingStatus(financerLoanProductStatuses);
				}
			} else {
				setUpcomingStatus([]);
			}
		} else {
			setUpcomingStatus([]);
		}
	}, [defaultStatus, loan_status, loanType]);

	useEffect(() => {
		if (!upcomingStatus) return setStatusOptions([]);
    
		const groupOrder = ["lead", "verification", "physical_discussion", "approval", "legal", "sanction", "disbursement", "reject", "close", "unassigned"];
		const sortedGroups = groupOrder.filter(group => upcomingStatus[group]);
    
		const shouldShowAll = 
            user.user_type === "Financer" || 
            user.username === "pushapduggal" || 
            (user.specialAccessRole && ["32", "28", "29"].includes(defaultStatus));
    
		const groupedOptions = sortedGroups.map(group => ({
			label: group.replace("_", " "),
			options: upcomingStatus[group].map(status => ({
				label: status.name,
				value: +status.value
			}))
		}));
    
		if (shouldShowAll) return setStatusOptions(groupedOptions);
    
		const filteredOptions = groupedOptions.reduce((acc, group) => {
			if (acc.found) {
				acc.options.push(group);
				return acc;
			}
			const idx = group.options.findIndex(option => option.value.toString() === defaultStatus);
			if (idx !== -1) {
				acc.found = true;
				acc.options.push({ ...group, options: group.options.slice(idx) });
			}
			return acc;
		}, { options: [], found: false }).options;
    
		setStatusOptions(filteredOptions);
	}, [upcomingStatus, user, defaultStatus]);

	const CustomStyle = {
		option: (data, state) => ({
			...data,
			backgroundColor: state.isFocused ? "#00a0e3" : state.isSelected ? "#fff" : "#fff",
			color: state.isFocused ? "#fff" : state.isSelected ? "#00a0e3" : "#000",
			fontWeight: state.isSelected ? "bold" : "",
			cursor: "pointer",
		})
	};

	return (
		<>
			{label ? (
				<label className="form-label mb-0" htmlFor="loanType">
                    Status
				</label>
			) : (
				""
			)}
			<div className="d-flex align-items-center position-relative z-1">
				<Select
					options={statusOptions}
					className="smSelect smOption statuses-css fw-medium z-2"
					value={statusOptions.flatMap(group => group.options).find(option => option.value === parseInt(existingValue))}
					name="designation"
					isSearchable={false}
					placeholder="Select Status"
					onChange={e => changeStatus(e.value)}
					noOptionsMessage={() => "No Status Available"}
					styles={CustomStyle}
					GroupHeadingProps={{ style: { color: "#00a0e3", fontWeight: "bold" } }}
				/>
			</div>
		</>
	);
};

export default UpdateLoanStatus;
