import axios from "axios";
import { askForPermissionToReceiveNotifications } from "./firebaseInit";
import { toast } from "react-toastify";

export const authServices = {
	login,
	logout,
	findUser,
	redirect,
	sendOtp,
	validateOtp,
	updateNotificationToken
};

export const readOnlyUsers = ["dineshgupta", "Vksphf", "employeetest", "abhishekraval", "nikunjdavra", "nitintoshniwal", "ajay.kumar", "punitagarwal", "wishey"];

const specialUsers = ["Phf24", "PHF141", "phf607", "PHF491", "Satparkash", "shgarima21", "Sumit1992", "phf1021", "phf766"];
const assignSpecialRole = (userData) => {
	if (userData && specialUsers.includes(userData.username)){
		userData["specialAccessRole"] = true;
	}
	return userData;
};

async function login(data, updateUser) {
	let action = "login";
	if (data.token && data.login) {
		action = "auto-login";
	};
	const response = await axios.post(
		`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/${action}`,
		data
	);
	if (response.data.response && response.data.response.status === 200) {
		const userData = response.data.response.data;
		if (window.location.hostname !== "localhost" && window.location.hostname.split(".")[0] !== "192") {
			const server = await axios.get("/get-server-date");
			userData["date"] = server.data && server.data.date ? server.data.date : null;
		} else {
			userData["date"] = new Date();
		}
		const userValidated = assignSpecialRole(userData);
		updateUser(userValidated);
		askForPermissionToReceiveNotifications();
		return { status: 200, data: userData };
	} else if (response.data.response && response.data.response.status === 308) {
		return response.data.response;
	} else if (response.data.response && response.data.response.status === 404) {
		alert("Invalid Login Details");
		return { status: 404 };
	} else if (response.data.response && response.data.response.status === 409) {
		const errors = response.data.response.data;
		const messages = [];
		for (var propName in errors) {
			if (Object.prototype.hasOwnProperty.call(errors, propName)) {
				messages.push(errors[propName]);
			}
		}
		alert(messages.toString());
		return { status: 201 };
	} else {
		alert("An Error has occurred, Please try again later");
		return false;
	}
}

async function sendOtp(phone) {
	const request = await axios.post(
		`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/send-otp`,
		{ phone }
	);
	const response = request.data;
	if (response && response.status === 200) {
		return { status: 200, data: response };
	} else if (response && [500, 400, 201].includes(response.status)) {
		const errors = response.message;
		const messages = [];
		if (typeof errors !== "string") {
			for (var propName in errors) {
				if (Object.prototype.hasOwnProperty.call(errors, propName)) {
					messages.push(errors[propName]);
				}
			}
			alert(messages.toString());
		} else {
			alert(errors);
		}
		return { status: 201 };
	} else {
		alert("An Error has occurred, Please try again later");
		return { status: 201 };
	}
}

async function validateOtp(id, code) {
	const request = await axios.post(
		`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/validate-otp`,
		{ id, code }
	);
	const response = request?.data?.response;
	if (response && [200, 308].includes(response.status)) {
		return { status: response.status, data: response.data };
	} else if (response && [404, 500, 422].includes(response.status)) {
		const errors = response.message;
		const messages = [];
		if (typeof errors !== "string") {
			for (var propName in errors) {
				if (Object.prototype.hasOwnProperty.call(errors, propName)) {
					messages.push(errors[propName]);
				}
			}
			alert(messages.toString());
		} else {
			alert(errors);
		}
		return { status: 201 };
	} else {
		alert("An Error has occurred, Please try again later");
		return false;
	}
}

async function redirect(token) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/redirection-details`, {code:token});
		if (response.data.response && response.data.response.status === 200) {
			return { status: 200, data: response.data.response.data };
		} else if (response.data.response && response.data.response.status === 409) {
			const errors = response.data.response.data;
			const messages = [];
			for (var propName in errors) {
				if (Object.prototype.hasOwnProperty.call(errors, propName)) {
					messages.push(errors[propName]);
				}
			}
			alert(messages.toString());
			return { status: 201 };
		} else {
			alert("An Error has occurred, Please try again later");
			return { status: 201 };
		}
	} catch (error) {
		console.log(error);
		toast.error(error.message);
	}
}

function logout() {
	if (localStorage.user) {
		if ("caches" in window) {
			caches.keys().then(names => {
				names.forEach(async name => {
					await caches.delete(name);
				});
			});
			// window.location.reload()
		}
		if (localStorage.getItem("push_notification")) {
			updateNotificationToken(localStorage.getItem("push_notification"));
		} else {
			localStorage.clear();
			setTimeout(function () {
				return (window.location = "/");
			}, 1000);
		}
	}
}

async function updateNotificationToken(token) {
	await axios
		.post(
			`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}notifications/update-token`,
			{ token: token }
		)
		.then(response => {
			if (response?.data?.response?.status === 200) {
				localStorage.clear();
				setTimeout(function () {
					return (window.location = "/");
				}, 1000);
			}
		});
}

async function findUser(updateUser) {
	const user = JSON.parse(localStorage.getItem("user"));
	const response = await axios.post(
		`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/find-user`,
		{
			access_token: user.access_token,
			source: user.source
		}
	);
	if (
		response.data.response &&
		response.data.response.status === 200 &&
		response.data.response.data
	) {
		const userData = response.data.response.data;
		if (window.location.hostname !== "localhost" && window.location.hostname.split(".")[0] !== "192") {
			const server = await axios.get("/get-server-date");
			userData["date"] = server.data && server.data.date ? server.data.date : null;
		} else {
			userData["date"] = new Date();
		}
		const userValidated = assignSpecialRole(userData);
		updateUser(userValidated);
		return { status: 200, data: userData };
	} else if (response.data.response && response.data.response.status === 308) {
		return response.data.response;
	} else {
		alert("Token expire please log in");
		logout();
		return { status: 201 };
	}
}