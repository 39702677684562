import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { Col, Modal, Row } from "react-bootstrap";
import { MdArrowBackIos, MdArrowForwardIos, MdOutlineClose } from "react-icons/md";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import NameComp from "../NameComp";
import { MainContext } from "../../context/MainState";

const CreditReports = ({ heading = false }) => {
	const { getCreditReports, creditReports, isLoading } = useContext(OrganizationDashboardContext);
	const { loan_products } = useContext(MainContext);
	const [cibilReportDetail, setCibilReportDetail] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);

	const columns = [
		{
			name: (
				<NameComp
					title={"Request For"}
					id={"request_source"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.request_source ? row.request_source : "-"),
			sortable: false,
			cell: row => {
				return <h6 className="mb-0 text-capitalize text-link">{row.request_source}</h6>;
			}
		},
		{
			name: (
				<NameComp
					title={"Applicant Name"}
					id={"applicant_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.applicant_name ? row.applicant_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.co_applicant_name ? (
							<>
								<h6 className="mb-0 text-capitalize text-link">
									{row.co_applicant_name} ({row.relation})
								</h6>
								<p className="mb-0" style={{ color: "#7a7f84" }}>
									Main Applicant: {row.co_applicant_name}
								</p>
							</>
						) : (
							<h6 className="mb-0 text-capitalize text-link">{row.applicant_name}</h6>
						)}
					</div>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Loan Product"}
					id={"loan_product_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanProducts={loan_products}
					clearFilters={clearFilters}
				/>
			),
			cell: row => {
				return <h6 className="mb-0 emiAmt">{row.loan_product}</h6>;
			},
			selector: row => row.loan_product,
			sortable: false
		},
		{
			name: "Report",
			selector: row => (row.file_url ? row.file_url : "-"),
			sortable: false,
			cell: row => {
				return (
					<button
						type="button"
						onClick={() => setCibilReportDetail(row.file_url)}
						className="btn btn-primary btn-sm fs-6 my-1"
					>
						Preview Report
					</button>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Fetched By"}
					id={"created_by_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.created_by_name ? row.created_by_name : "-"),
			sortable: false
		}
	];
	//
	useEffect(() => {
		getCreditReports({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
		});
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray]);

	// ** Function to handle filter
	// const handleFilter = e => {
	// 	setSearchValue(e.target.value);
	// 	getCreditReports({
	// 		page: currentPage,
	// 		limit: rowsPerPage,
	// 		keyword: e.target.value
	// 	});
	// };

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getCreditReports({
			page: page.selected + 1,
			limit: rowsPerPage,
			keyword: searchArray,
			fields_search: searchArray,
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getCreditReports({
			page: 1,
			limit: parseInt(e.target.value),
			keyword: searchArray,
			fields_search: searchArray,
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(creditReports.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({name: ""});
	};

	// ** Table data to render
	const dataToRender = () => {
		const filters = {
			q: ""
		};

		const isFiltered = Object.keys(filters).some(function (k) {
			return filters[k].length > 0;
		});

		if (creditReports && creditReports.data.length > 0) {
			return creditReports.data;
		} else if (creditReports && creditReports.data.length === 0 && isFiltered) {
			return [];
		} else {
			return creditReports && creditReports.data.length > 0
				? creditReports.data.slice(0, rowsPerPage)
				: [];
		}
	};

	return (
		<div className="loan-application-table mb-5">
			{heading ? <h4>Connectors List</h4> : ""}
			<Row className="mx-0 mt-1 mb-50 mb-5">
				<Col sm="6">
					<div className="d-flex align-items-center">
						<label htmlFor="sort-select">Show</label>
						<select
							className="dataTable-select form-select"
							style={{ width: "65px", padding: "2px 8px", margin: "0px 10px" }}
							id="sort-select"
							value={rowsPerPage}
							onChange={e => handlePerPage(e)}
						>
							<option value={10}>10</option>
							<option value={25}>25</option>
							<option value={50}>50</option>
							<option value={75}>75</option>
							<option value={100}>100</option>
						</select>
						<label htmlFor="sort-select">Results</label>
					</div>
				</Col>
				<Col
					className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
					sm="6"
				>
					{/* <label className="mr-1" htmlFor="search-input">
						Search
					</label>
					<input
						className="dataTable-filter form-control"
						type="text"
						style={{ maxWidth: "150px", margin: "0px 5px", height: "35px" }}
						id="search-input"
						value={searchValue}
						onChange={handleFilter}
					/> */}
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button className="btn btn-primary btn-xs my-2" onClick={(e) => clearFilter(e)}>Clear Filters</button>
						): ""}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				pagination
				paginationServer
				columns={columns}
				data={dataToRender()}
				paginationComponent={CustomPagination}
				progressPending={isLoading === "creditTable"}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
				persistTableHead={true}
				className="loan-app-table-container fix-overflow sales-index"
			/>
			<Modal show={cibilReportDetail !== ""} size={"xl"} centered>
				<Modal.Header>
					<Modal.Title>Credit Report</Modal.Title>
					<button
						className={"link-button closeBtn"}
						onClick={() => setCibilReportDetail("")}
					>
						<MdOutlineClose size={18} />
					</button>
				</Modal.Header>
				<iframe
					title="Credit Report"
					src={`${cibilReportDetail}`}
					width="100%"
					height="580"
					style={{ border: "none" }}
				></iframe>
			</Modal>
		</div>
	);
};
export default CreditReports;
