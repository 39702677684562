import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import GetMyLocation from "../GetMyLocation";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";

const LoanResidentialDetails = ({
	register,
	errors,
	setValue,
	userData,
	formFor = "user",
	control,
	reloadLoc = "",
	locationBtn = "",
	checkedOnce = false,
	selectedBorrower 
}) => {
	const { user } = useContext(MainContext);
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const [stateOptions, setStateOptions] = useState([]);
	const [citiesOptions, setCitiesOptions] = useState([]);
	const [citySelected, setCitySelected] = useState("");
	// const [citySelectedArr, setCitySelectedArr] = useState({});
	const [dataSelected, setDataSelected] = useState({
		state: false,
		city: false,
		pincode: false
	});
	const [branches, setBranches] = useState([]);
	const [branchesOptions, setBranchesOptions] = useState([]);
	const [selectedBranch, setSelectedBranch] = useState({});
	const [selectState, setSelectState] = useState([]);


	useEffect(() => {
		if (stateOptions && stateOptions.length) {
			const tempBranchesOptions = stateOptions.map((val) => ({
				value: val.state_enc_id,
				label: val.name,
			}));
			setSelectState(tempBranchesOptions);
		}
	}, [stateOptions]);

	const getBranches = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-branches`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setBranches(response.data.response.branches);
				} else {
					setBranches([]);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};
	const getStateOptions = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}utilities/states`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					setStateOptions(response.data.response.states);
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};

	const matchState = stateName => {
		if (stateName && stateOptions) {
			let seletedState = stateOptions.filter(item => item.name === stateName);
			if (seletedState.length) {
				setValue("state", seletedState[0].state_enc_id);
				setDataSelected({ ...dataSelected, state: true });
				getCitiesData(seletedState[0].state_enc_id);
			}
		}
	};

	const setVals = (pinCode, streetAdd) => {
		if (pinCode) {
			setValue("zip", pinCode);
		}
		if (streetAdd) {
			setValue("address", streetAdd);
		}
	};

	const getCitiesData = async id => {
		setValue("state", id);
		setValue("city", "");
		if (id) {
			await axios
				.get(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}utilities/cities?state_id=${id}`
				)
				.then(response => {
					if (response.data.response && response.data.response.status === 200) {
						const tempCities = response.data.response.cities.map((value) => ({
							value: value.city_enc_id,
							label: value.name
						}));
						setCitiesOptions(tempCities);
					}else {
						toast.error("An error has occurred, Please try again");
					}
				})
				.catch(error => {
					console.log(error);
					toast.error(error.message);
				});
		}
	};
	const setCityVal = citySelected => {
		if (citySelected && citiesOptions) {
			let selectedCity = citiesOptions.filter(item => item.label === citySelected);
			if (selectedCity.length) {
				setValue("city", selectedCity[0].value);
				setDataSelected({ ...dataSelected, city: true });
			}
		}
	};

	useEffect(() => {
		if (branches && branches.length) {
			const tempBranchesOptions = branches.map(value => ({
				value: value.id,
				label: value.value
			}));
			setBranchesOptions(tempBranchesOptions);
		}
	}, [branches]);

	useEffect(() => {
		if (citySelected) {
			setCityVal(citySelected);
		}
		if (userData && citiesOptions.length && !dataSelected["city"]) {
			setCityVal(userData.city);
		}
	}, [citiesOptions]);

	const matchCity = cityName => {
		setCitySelected(cityName);
	};

	useEffect(() => {
		getStateOptions();
		if (formFor === "org") {
			getBranches();
		}
	}, []);

	useEffect(() => {
		if (userData && userData.state && stateOptions.length){	
			if (userData && stateOptions.length && !dataSelected["state"]) {
				matchState(userData.state);
			}
			if (userData && userData.postal_code && !dataSelected["pincode"]) {
				setValue("zip", userData.postal_code);
				setDataSelected({ ...dataSelected, pincode: true });
			}
		}
	}, [userData, stateOptions]);

	useEffect(() => {
		if (user && user.branch_id) {
			setSelectedBranch({ value: user.branch_id, label: user.branch_name });
			setValue("branch_id", user.branch_id);
		}
	}, [user]);

	return (
		<>
			{formFor !== "co-borrower" ? (
				<div className="disFlex justify-content-between align-content-center">
					{formFor !== "emi" ? (
						<h4>
							Residential {formFor === "org" && branches.length ? "and Branch " : ""}
							Details
						</h4>
					) : (
						<h4></h4>
					)}

					<GetMyLocation
						matchState={matchState}
						matchCity={matchCity}
						setVals={setVals}
						reload={reloadLoc}
						btnClasses={locationBtn}
					/>
				</div>
			) : (
				""
			)}
			{formFor == "emi" ? (
				<div className="row">
					<div className="col-sm-6 mb-3">
						<label className="form-label">Address</label>
						<input
							type="text"
							className="form-control required"
							placeholder="Address"
							autoComplete="off"
							{...register("address", {
								required: "This is required."
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="address"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
			) : (
				""
			)}
			<div className={formFor !== "co-borrower" ? "row mb-3" : "row"}>
				<div className="col-sm-6 mb-3">
					<label className="form-label" htmlFor="states">
						State
					</label>
					<Controller
						name="state"
						control={control}
						render={({ field }) => (
							<Select
								id="states"
								style={{ width: "100%" }}
								options={selectState}
								value={selectState && selectState.length ? selectState.find(c => c.value === field.value) : []}
								onBlur={field.onBlur}
								isSearchable={true}
								onChange={selectedOption => {
									field.onChange(selectedOption.value);
									getCitiesData(selectedOption.value);
									// setCitySelectedArr({});
								}}
								isDisabled={formFor === "co-borrower" && ((selectedBorrower.state && checkedOnce) || (selectedBorrower.state  && !(user.user_type === "Financer" || user.specialAccessRole)))}
								className="customStyleSelect"
							/>
						)}
						rules={{ required: true }}
					/>
					{errors.state ? (
						<p style={{ color: "red", fontSize: "13px" }}>This Field is required</p>
					) : ""}
					
				</div>
				<div className="col-xl-6 col-lg-6 col-sm-6 mb-3">
					<label className="form-label" htmlFor="cities">
      					  City
					</label>
					<Controller
						name="city"
						// defaultValue={citySelectedArr.value}
						control={control}
						render={({ field: { onChange, value, onBlur, ref} }) => (
							<Select
								id="cities"
								style={{ width: "100%" }}
								options={citiesOptions}
								inputRef={ref}
								value={citiesOptions && citiesOptions.length ? citiesOptions.find((c) => c.value === value) : []}
								// defaultValue={citiesOptions.find((c) => c.value === value)}
								isSearchable={true}
								onChange={selectedOption => {
									onChange(selectedOption.value);
									// setCitySelectedArr(selectedOption);
								}
								}
								onBlur={onBlur}
								isDisabled={formFor === "co-borrower" && ((selectedBorrower.city && checkedOnce) || (selectedBorrower.city  && !(user.user_type === "Financer" || user.specialAccessRole)))}
								className="customStyleSelect"
							/>
						)}
						rules={{ required: true }}
					/>
					{errors.city && (
						<p style={{ color: "red", fontSize: "13px" }}>This Field is required</p>
					)}
				</div> 	

				<div className="col-sm-6 mb-3">
					<label className="form-label">Postal Code</label>
					<input
						type="number"
						placeholder="Postal Code"
						min={1}
						autoComplete="off"
						className="form-control"
						{...register("zip", {
							required: "This is required.",
							minLength: {
								value: 6,
								message: "Postal Code Should be at least 6 numbers"
							},
							maxLength: {
								value: 6,
								message: "Postal Code maximum be at least 6 numbers"
							},
							pattern: {
								value: phoneRegExp,
								message: "Invalid Postal Code"
							}
						})}
						disabled={formFor === "co-borrower" && ((selectedBorrower?.postal_code && checkedOnce) || (selectedBorrower?.postal_code  && !(user.user_type === "Financer" || user.specialAccessRole)))}
					/>
					<ErrorMessage
						errors={errors}
						name="zip"
						render={({ message }) => (
							<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
						)}
					/>
				</div>
				{formFor === "org" && branchesOptions.length ? (
					<div className="col-sm-6 mb-3">
						<label className="form-label" htmlFor="states">
							Branch
						</label>
						<Select
							options={branchesOptions}
							className="fs-5 fix-input-height"
							value={selectedBranch}
							name="Branch"
							placeholder="Choose Branch"
							{...register("branch_id", {
								required: "This is required."
							})}
							onChange={option => {
								setSelectedBranch(option);
								setValue("branch_id", option.value);
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="branch_id"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
};
export default LoanResidentialDetails;
