import React, { useContext, useEffect, useState } from "react";
import { MdDelete, MdRemoveCircle } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
import axios from "axios";
import moment from "moment";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { MainContext } from "../context/MainState";

const UploadNotice = ({defaultLoad = 0}) => {
	const [selectedImage, setSelectedImage] = useState("");
	const [isChecked, setIsChecked] = useState({ check: true, id: "" });
	const [allNotices, setAllNotices] = useState([]);
	const { register, handleSubmit, reset, watch } = useForm();
	const { getOrganizationNotices, notices } = useContext(OrganizationDashboardContext);
	const { handlePageLoaded, user } = useContext(MainContext);
	const noticeFor = watch("notice_for");

	const imageChange = async e => {
		if (e.target.files && e.target.files.length > 0) {
			const imageSelected = e.target.files[0];

			try {
				const compressedImage = await new Promise((resolve, reject) => {
					new Compressor(imageSelected, {
						quality: 0.6,
						success: res => {
							resolve(res);
						},
						error: err => {
							reject(err);
						}
					});
				});
				setSelectedImage(compressedImage);
			} catch (error) {
				console.error("Compression error:", error);
				// Handle the error as needed
			}
		}
	};

	const removeSelectedImage = () => {
		reset();
		setSelectedImage("");
	};

	const changeStatus = async (notice_id, val, type = null) => {
		const myArray = [...notices];
		const objIndex = myArray.findIndex(obj => obj.notice_enc_id === notice_id);
		let data = {};
		if (type === "delete") {
			if (!window.confirm("Are you sure you want to delete")) {
				return false;
			}
			myArray.splice(objIndex, 1);
			setAllNotices(myArray);
			data = { delete: true, notice_enc_id: notice_id };
		} else {
			myArray[objIndex].status = val.status === "1" ? "0" : "1";
			setAllNotices(myArray);
			let status = val.status === "0" ? false : true;
			data = { status: status, notice_enc_id: notice_id };
		}

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-notice`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setIsChecked({ ...isChecked, id: "" });
					toast.success(` ${type === "delete" ? "Deleted" : "Updated"}  Successfully`);
					if (type === "delete") {
						getOrganizationNotices({type: "all"});
					}
				} else {
					toast.error(
						response.data.response.message
							? response.data.response.message
							: "An error occurred"
					);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(
					"An error has occurred while uploading Organization Notices, Please try again"
				);
			});
	};

	const onSumbit = async () => {
		if (selectedImage) {
			handlePageLoaded(true);
			let formdata = new FormData();
			formdata.append("image", selectedImage);
			formdata.append("type", 0);
			formdata.append("notice_for", noticeFor);
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/add-notice`,
					formdata
				)
				.then(response => {
					handlePageLoaded(false);
					if (response.data.response.status === 200) {
						setSelectedImage("");
						reset();
						getOrganizationNotices({type: "all"});
						toast.success("Saved Successfully");
					} else {
						toast.error(
							response.data.response.message
								? response.data.response.message
								: "An error occurred"
						);
					}
				})
				.catch(error => {
					handlePageLoaded(false);
					toast.error("Server Error Occured: " + error.message);
				});
		}
	};

	const noticeArray = {
		1: "Employee",
		2: "Customer",
	  };


	useEffect(() => {
		if (defaultLoad === 1 && (user.user_type && user.user_type === "Financer" || (user.username === "Isudhanshu4u" || user.username === "phf219"))) {
	  		getOrganizationNotices({type: "all"});
		}
	}, []);

	useEffect(() => {
		setAllNotices(notices);
	}, [notices]);
	return (
		<>
			<div className="container">
				<form onSubmit={handleSubmit(onSumbit)}>
					<div className="row">
						<div className="noticeBrd-main">
							{selectedImage && (
								<div className="imageUploaded-main">
									<span className="form-label" htmlFor="change_image">
										<MdRemoveCircle
											className="delete-image"
											onClick={removeSelectedImage}
										/>
									</span>
									<img src={URL.createObjectURL(selectedImage)} alt="Thumb" />
								</div>
							)}

							<label htmlFor={"noticeUpload"} style={{ textAlign: "center" }}>
								{!selectedImage ? <BsUpload className="uploadImg" /> : ""}
								<input
									id="noticeUpload"
									className="form-control noticeInput"
									name="noticeImg"
									accept="image/*"
									type="file"
									{...register("noticeImg")}
									onChange={imageChange}
								/>
							</label>

							<div className="mb-3">
								<div className="radio-wrapper hero-radio-wrapper bg-gray-200 remove-anim-notice">
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-1"
											defaultChecked={true}
											value="1"
											{...register("notice_for", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-1" className="option option-1">
											<div className="dot"></div>
											<span>Employee</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-2"
											value="2"
											{...register("notice_for", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-2" className="option option-2">
											<div className="dot"></div>
											<span>Customer</span>
										</label>
									</div>
								</div>
							</div>

							<div className="uploadBtn">
								<button type="submit" className="btn btn-primary">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
				<div className="row">
					<div className="imageList">
						<table className="table">
							<thead>
								<tr>
									<th>Image</th>
									<th>Date & Time</th>
									<th>For</th>
									<th className="text-center">Status</th>
									<th className="text-center">Delete</th>
								</tr>
							</thead>
							<tbody className="imageListTd">
								{allNotices && allNotices.length ? (
									allNotices.map((value, index) => {
										return (
											<>
												{value.image ? (
													<tr key={`notice-${index}`}>
														<td>
															<div className="upldNotice">
																<img
																	src={value.image}
																	alt="uploaded-Notice"
																/>
															</div>
														</td>
														<td>
															<div className="uploadDateTime">
																<p className="text-muted mb-0">
																	{moment(
																		value.created_on
																	).format("DD MMM YYYY")}
																</p>
															</div>
														</td>
														<td>
															<div className="uploadDateTime">
																<p className="text-muted mb-0">
																	{value.notice_for ? noticeArray[value.notice_for] : "-"}
																</p>
															</div>
														</td>
														<td className="text-center">
															<div className="toggle-button_wrap">
																<input
																	type="checkbox"
																	id={value.notice_enc_id}
																	className="toggler"
																	onChange={() =>
																		changeStatus(
																			value.notice_enc_id,
																			value
																		)
																	}
																	checked={value.status === "1"}
																/>
																<label
																	className="toggle-button"
																	htmlFor={value.notice_enc_id}
																></label>
																<div
																	className={`toggle ${
																		isChecked ? "on" : "off"
																	}`}
																></div>
															</div>
														</td>
														<td className="text-center">
															<button
																type="button"
																onClick={() =>
																	changeStatus(
																		value.notice_enc_id,
																		value,
																		"delete"
																	)
																}
																className="btn btn-primary btn-xs"
															>
																<MdDelete fontSize={15} />
															</button>
														</td>
													</tr>
												) : (
													""
												)}
											</>
										);
									})
								) : (
									<tr>
										<td colSpan="4"> No notices to display</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default UploadNotice;
