
import { useContext, useEffect, useState } from "react";
import NameComp from "../NameComp";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import EditTypehead from "../EditTypehead";
import { MainContext } from "../../context/MainState";
import MinMaxFilter from "../MinMaxFilter";
import moment from "moment";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { BiRupee } from "react-icons/bi";

const DealerCollectionTable = ({reloadState, setReloadState}) => {
	const {getOrganizationDepartments, getDesignationList, departmentList, designationList, branches_list, getBranches, states_list, getStates, getDealerCollectionList, dealerCollectionList, isLoading} = useContext(OrganizationDashboardContext);
	const { user, loanAccountTypes } = useContext(MainContext);
	const [searchArray, setSearchArray] = useState({});
	const [clearFilters, setClearFilters] = useState(false);
	const [sortArr, setSortArr] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray("");
	};

	const reloadDetails = () => {
		getDealerCollectionList({
			page: 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr
		});
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{(user.user_type === "Financer" ||
							(["KKB", "phf604", "phf403"].includes(user.username))) &&
							row.emi_payment_status !== "paid" ? (
								<EditTypehead
									getData={reloadDetails}
									api={"emi-collections/update-emi-number"}
									defaultValue={
										row.loan_account_number ? row.loan_account_number : ""
									}
									parentId={row.loan_account_enc_id}
									colName={row.emi_collection_enc_id}
									placeHolder="Search Loan Number"
									hideEditButton={true}
									searchUrl="organizations/search-emi-accounts?loan_number="
									type="loan_account_number"
								/>
							) : (
								<h6 className="mb-0 text-capitalize text-link">
									{row.loan_account_number ? row.loan_account_number : "-"}
								</h6>
							)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Customer Name"}
					id={"customer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.customer_name ? row.customer_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						{row.customer_name}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "240px",
			selector: row => (row.branch ? row.branch : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"Collection Date"}
					id={"collection_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => (row.collection_date ? moment(row.collection_date).valueOf() : 0),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 emiAmt cursor-point"
						// onClick={() => handleEditEmi(row, "date")}
					>
						{row.collection_date
							? moment(row.collection_date).format("DD MMM YYYY")
							: "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Collected Amount"}
					id={"collected_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "180px",
			selector: row => (row.collected_amount ? row.collected_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.collected_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.collected_amount).toLocaleString("en-IN")}
							</h6>
						) : ""}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "220px"
		},
		{
			name: (
				<NameComp
					title={"Collected By"}
					id={"collected_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "150px",
			selector: row => (row.collected_by ? row.collected_by : "-")
		},
		{
			name: (
				<NameComp
					title={"Mode of Payment"}
					id={"emi_payment_mode"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<>
						{row.emi_payment_mode ? row.emi_payment_mode : "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Payment Method"}
					id={"emi_payment_method_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<>
						{row.emi_payment_method ? row.emi_payment_method : "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Payment Status"}
					id={"emi_payment_status_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<>
						{row.emi_payment_status ? row.emi_payment_status : "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Departments"}
					id={"department"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					departmentList={departmentList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<>
						{row.department ? row.department : "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Designations"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					designations={designationList}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<>
						{row.designation ? row.designation : "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Dealer Name"}
					id={"dealer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<>
						{row.dealer_name ? row.dealer_name : "-"}
					</>
				);
			}
		},
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getDealerCollectionList({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getDealerCollectionList({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			fields_sort: sortArr
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(dealerCollectionList.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (dealerCollectionList && dealerCollectionList.data.length > 0) {
			return dealerCollectionList.data;
		} else if (dealerCollectionList && dealerCollectionList.data.length === 0 && isFiltered) {
			return [];
		} else {
			return dealerCollectionList && dealerCollectionList.data.length > 0
				? dealerCollectionList.data.slice(0, rowsPerPage)
				: [];
		}
	};
	
	useEffect(() => {
		const timerId = setTimeout(() => {
			getDealerCollectionList({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr
			});
			setCurrentPage(1);
		}, 800);

		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [searchArray, sortArr]);

	useEffect(() => {
		if(reloadState){
			getDealerCollectionList({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr
			});
			setReloadState(false);
		}
	}, [[reloadState]]);

	useEffect(() => {
		if (!branches_list) {
			getBranches();
		}
		if(!states_list){
			getStates();
		}
		if(!designationList){
			getDesignationList();
		}
		if(!departmentList){
			getOrganizationDepartments();
		}
	}, []);

	return (
		<>
			<div className="shadow-lg rounded-3 py-4 px-6 mt-5">
				<h2 className="ptp-heading mb-3">Dealer Collections</h2>
				<Row className="mx-0 mt-1 mb-5">
					<Col sm="9">
						<div className="d-flex align-items-center py-2 flex-wrap">
							<div className="form-group me-2">
								<label htmlFor="float-text" className="floating-label fs-5">
									Show Results
								</label>
								<select
									className="dataTable-select form-select"
									style={{ width: "95px", padding: "2px 8px", height: "48px" }}
									id="sort-select"
									value={rowsPerPage}
									onChange={e => handlePerPage(e)}
								>
									<option value={10}>10</option>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={75}>75</option>
									<option value={100}>100</option>
								</select>
							</div>
						</div>
					</Col>
					<Col
						className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
						sm="3"
					>
						<div className="filters-container d-flex justify-content-end">
							{showClearBtn ? (
								<button
									className="btn btn-primary btn-xs my-2"
									onClick={e => clearFilter(e)}
								>
									Reset
								</button>
							) : (
								""
							)}
						</div>
					</Col>
				</Row>
				<DataTable
					noHeader
					pagination
					paginationServer
					columns={columns}
					data={dataToRender()}
					sortServer={true}
					paginationComponent={CustomPagination}
					progressPending={isLoading}
					highlightOnHover="true"
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={
						<p className="w-100 m-5 text-start">There are no records to display</p>
					}
					persistTableHead={true}
					className="loan-app-table-container fix-overflow report-tables z-0"
					fixedHeader={true}
					fixedHeaderScrollHeight="100vh"
				/>
			</div>
		</>
	);
};

export default DealerCollectionTable;
