import { useContext } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { MainContext } from "../../context/MainState";
import axios from "axios";

const LoanPersonalDetails = ({ register, errors, setError, clearErrors, setNumberExist }) => {
	const { user } = useContext(MainContext);
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	// const [numberExist, setNumberExist] = useState(false);
	const checkNumber = async value => {
		if (value && value.length >= 10) {
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/check-number`,
					{ phone: value }
				)
				.then(response => {
					if (response.data.response.status === 200) {
						setError("phone_no", {
							type: "custom",
							message: "Number Already Exists"
						});
						setNumberExist(true);
					} else {
						clearErrors("phone_no");
						setNumberExist(false);
					}
				});
		}
	};

	return (
		<>
			<h4>Personal Details</h4>
			<div className="row mb-3">
				<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="mb-3">
						<label className="form-label mb-0" htmlFor="applicant_name">
							Full Name
						</label>
						<input
							placeholder="Full Name"
							className="form-control"
							{...register("applicant_name", {
								required: "This is required."
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="applicant_name"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
				{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
				{/*    <div className="mb-3">*/}
				{/*        <label className="form-label mb-0" htmlFor="last_name">Last Name</label>*/}
				{/*        <input placeholder="Last Name" className="form-control"*/}
				{/*               {...register("last_name", {*/}
				{/*                   required: 'This is required.',*/}
				{/*               })}*/}
				{/*        />*/}
				{/*        <ErrorMessage*/}
				{/*            errors={errors}*/}
				{/*            name="last_name"*/}
				{/*            render={({ message }) => <p style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
				{/*        />*/}
				{/*    </div>*/}
				{/* </div>*/}
				{user && user.user_type === "DSA" ? (
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="email">
								Email
							</label>
							<input
								placeholder="Email"
								className="form-control"
								{...register("email", {
									required: "This is required.",
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: "Email is invalid"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="email"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
				) : (
					""
				)}
				<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="mb-3">
						<label className="form-label mb-0" htmlFor="phone">
							Mobile Number (Whatsapp)
						</label>
						<input
							type="number"
							min="0"
							placeholder="Phone"
							className="form-control"
							{...register("phone_no", {
								required: "This is required.",
								minLength: {
									value: 10,
									message: "Mobile number Should be at least 10 numbers"
								},
								maxLength: {
									value: 10,
									message: "Mobile number maximum be at least 10 numbers"
								},
								pattern: {
									value: phoneRegExp,
									message: "Invalid Phone number"
								}
							})}
							onWheel={e => e.target.blur()}
							onBlur={e => {
								checkNumber(e.target.value);
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="phone_no"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>

				<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="mb-3">
						<label className="form-label d-block">Has Smartphone? <span style={{ color: "red", fontSize: "13px" }}>*</span> </label>
						<div className="radio-wrapper hero-radio-wrapper">
							<div className="radio-item">
								<input
									type="radio"
									name="select"
									className="radioInput"
									id="have-phone"
									value="1"
									{...register("has_smartphone", {
										required: "This is required."
									})}
								/>
								<label
									htmlFor="have-phone"
									className="option option-1"
								>
									<div className="dot"></div>
									<span>Yes</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="select"
									className="radioInput"
									id="doesnot-have-phone"
									value="0"
									{...register("has_smartphone", {
										required: "This is required."
									})}
								/>
								<label
									htmlFor="doesnot-have-phone"
									className="option option-1"
								>
									<div className="dot"></div>
									<span>No</span>
								</label>
							</div>
						</div>
						<ErrorMessage
							errors={errors}
							name="has_smartphone"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default LoanPersonalDetails;
