import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { toast } from "react-toastify";

const AutoSuggestLoanNumber = ({
	setSelectedcase,
	setVal,
	setCaseExist,
	selectedCase,
	fieldname=null,
	filledValue=null,
	placeholder=null
}) => {
	const [suggestions, setSuggestions] = useState([]);
	const [value, setValue] = useState("");

	const loadSuggestions = async inputValue => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/search-emi-accounts`,
				{loan_number: inputValue}
			);

			if (response.data.response.status === 200) {
				setSuggestions(response.data.response.data);
				setCaseExist({ status: false, caseNum: "" });
			} else {
				setSuggestions([]);
				setCaseExist({ status: true, caseNum: inputValue });
			}
		} catch (error) {
			toast.error("An error has occured while getting Loan Accounts: " + error.message);
			setSuggestions([]);
		}
	};

	const fetchLoanDetails = async encId => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/search-emi`,
				{loan_account_enc_id: encId}
			);
			if (response.data.response.status === 200) {
				setSelectedcase(response.data.response.data[0]);
			}else{
				setSelectedcase([]);
				toast.error("An error has occured while getting Loan Account Detail, Please try again");
			}
		} catch (error) {
			toast.error("Error fetching loan Account Details: " + error.message);
		}
	};

	const suggestionSelected = (event, { suggestion }) => {
		fetchLoanDetails(suggestion.id);
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const onChange = (event, { newValue }) => {
		const suggestNum = suggestions.find(
			(loan) => loan.loan_account_number === newValue
		);
		if (suggestNum) {
			setSelectedcase(suggestNum);
			fetchLoanDetails(suggestNum.id);
		} else {
			setSelectedcase({});
		}
		setValue(newValue);
	};

	const onBlur = (event) => {
		const inputValue = event.target.value;
		if (inputValue === "") {
			setSelectedcase({});
			setCaseExist({ status: false, caseNum: "" });
		} else {
			setVal("loan_account_number", inputValue);
		}
	};

	useEffect(() => {
		const requestDelay = setTimeout(() => {
			if (value && value !== selectedCase?.loan_account_number) {
				loadSuggestions(value);
			} else {
				setSuggestions([]);
			}
		}, 800);

		return () => clearTimeout(requestDelay);
	}, [value]);
	
	const inputProps = {
		placeholder: placeholder ? placeholder : "",
		value,
		onChange,
		onBlur,
		name: fieldname ? fieldname : "loan_account_number",
		id: "emi-list-input",
		className: "form-control",
	};

	useEffect(() => {
		if(filledValue){
			setValue(filledValue);
		}
	}, [filledValue]);
	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={({ value }) => setValue(value)}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			onSuggestionSelected={suggestionSelected}
			getSuggestionValue={(suggestion) => suggestion.loan_account_number ? suggestion.loan_account_number : ""}
			renderSuggestion={(suggestion) => (
				<div>
					<span style={{ color: "#777", marginLeft: "5px", fontSize: "13px" }}>
						{suggestion.loan_account_number
							? suggestion.loan_account_number
							: ""}
					</span>
				</div>
			)}
			inputProps={inputProps}
		/>
	);
};

export default AutoSuggestLoanNumber;
