import React, { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import DataTable from "react-data-table-component";
import { Badge, Col, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos, MdOutlineContentCopy } from "react-icons/md";
import NameComp from "../NameComp";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { OrganizationSidebarProvider } from "../../context/OrganizationSidebarState";
import LoanApplicationDetails from "./LoanApplicationDetails";
import MinMaxFilter from "../MinMaxFilter";
import { BiRupee } from "react-icons/bi";
import moment from "moment";

const NachRegisterNewCases = ({ callBack, type = "registered", loadDefault = false, active }) => {
	const { user, loan_products, financer_loan_status } = useContext(MainContext);
	const {
		getNewNachRequests,
		new_nach_requests,
		branches_list,
		states_list,
		getDashboardLoanApplications
	} = useContext(OrganizationDashboardContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [searchArray, setSearchArray] = useState({});
	const [sortArr, setSortArr] = useState({});
	const [clearFilters, setClearFilters] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [loanDetails, setLoanDetails] = useState("");

	useEffect(() => {
		if (new_nach_requests && new_nach_requests[type]) {
			setPageLoading(false);
		}
	}, [new_nach_requests]);

	useEffect(() => {
		if (searchArray) {
			setPageLoading(true);
			const timerId = setTimeout(() => {
				setClearFilters(false);
				setCurrentPage(1);
				if (type === "pending" && active === "3") {
					getDashboardLoanApplications({
						page: 1,
						limit: rowsPerPage,
						fields_search: searchArray,
						type,
					});
				} else if (type === "registered" && active === "4") {
					getNewNachRequests({
						type,
						page: 1,
						limit: rowsPerPage,
						fields_search: searchArray,
						fields_sort: sortArr,
					});
				}
			}, [1000]);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setShowClearBtn(true);
			}
			callBack(searchArray);
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArray, loadDefault, active]);

	const statusColorObj = {
		paid: "success",
		pending: "warning",
		rejected: "danger",
		failed: "danger",
		pipeline: "secondary",
		"not paid": "danger",
		collected: "secondary",
		Active: "success",
		Issued: "warning",
		Pending: "secondary",
		Inactive: "danger",
		Failed: "danger",
		Cancelled: "danger",
		"Not Registered": "primary"
	};

	const copy = async link => {
		await navigator.clipboard.writeText(link);
		toast.success("Link copied");
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Application Number"}
					id={"application_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "200px",
			selector: row => (row.application_number ? row.application_number : "--"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => setLoanDetails(row.loan_app_enc_id)}
					>
						{row.application_number ? row.application_number : "--"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Applicant Name"}
					id={"applicant_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			cell: row => {
				return <h6 className="mb-0 text-capitalize text-link" onClick={() => setLoanDetails(row.loan_app_enc_id)}>{row.applicant_name}</h6>;
			},
			selector: row => row.applicant_name,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Loan Product"}
					id={"loan_product_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanProducts={loan_products}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.loan_product ? row.loan_product : "-"),
			sortable: false
		}, 
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.branch ? row.branch : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Loan Status"}
					id={"status_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={type === "registered" ? sortArr : ""}
					setSortArr={setSortArr}
					sortType="alpha"
					loanStatus={financer_loan_status}
				/>
			),
			minWidth: "200px",
			cell: row => {
				return (
					<h6 className="fw-medium">
						{row.status
							? row.status
							: row.status_number === "26"
								? "Disbursement Approval"
								: row.status_number === "31"
									? "Disbursed"
									: "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Max Amount"}
					id={"amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			omit: type === "pending",
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						 {row.max_amount ? (
							<>
								<BiRupee size={16} />
								{parseFloat(row.max_amount).toLocaleString("en-IN")}
							</>
						) : "-"}
					</h6>
				);
			},
			selector: row => row.max_amount,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"eNACH Status"}
					id={"enach_status"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (
				<Badge
					className="text-capitalize mx-2 py-1"
					bg={statusColorObj[row.enach_status || "Not Registered"]}
					pill
				>
					{row.enach_status || "Not Registered"}
				</Badge>
			),
			sortable: false,
			omit: type === "pending"
		},
		{
			name: "eNACH URL",
			minWidth: "140px",
			selector: row => row.url,
			cell: row => {
				return row.enach_status !== "Active" ? (
					<button
						type="button"
						className="btn btn-primary btn-sm mb-1"
						onClick={() => copy(row.url)}
						style={{ padding: "3px 4px" }}
					>
						<MdOutlineContentCopy style={{ margin: "0px 2px" }} size={15} />
					</button>
				) : (
					"-"
				);
			},
			omit: type === "pending"
		},
		{
			name: (
				<NameComp
					title={"Created By"}
					id={"created_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => row.creator_name,
			cell: row => {
				return <div className="fw-medium">{row.creator_name}</div>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Created On"}
					id={"created_on"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			omit: type === "pending",
			selector: row => moment(row.created_on).valueOf(),
			sortable: false,
			cell: row => {
				return (
					row.created_on ? moment(row.created_on).format("DD MMM YYYY h:mm a") : "-"
				);
			}
		},
		{
			name: (
			  <NameComp
					title={"Payment Id"}
					id={"payment_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
			  />
			),
			minWidth: "250px",
			selector: row => (row.payment_id ? row.payment_id : "-"),
			sortable: false,
			omit: !(user && ["nisha123", "Satparkash"].includes(user.username)),
			cell: row => {
				return (
					<h6
						className="mb-0"
					>
						{row.payment_id ? row.payment_id : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Token ID"}
					id={"token_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "250px",
			selector: row => (row.token_id ? row.token_id : "-"),
			omit: !(user && ["nisha123", "Satparkash"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0"
					>
						{row.token_id ? row.token_id : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Customer ID"}
					id={"customer_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "250px",
			selector: row => (row.customer_id ? row.customer_id : "-"),
			omit: !(user && ["nisha123", "Satparkash"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0"
					>
						{row.customer_id ? row.customer_id : "-"}
					</h6>
				);
			}
		},
		{
			name: "Remarks",
			minWidth: "250px",
			selector: row => (row.error_description ? row.error_description : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize">
						{row.error_description ? row.error_description : "-"}
					</h6>
				);
			},
			omit: type === "pending"
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		setPageLoading(true);
		if(type === "pending" && active === "3") {
			getDashboardLoanApplications({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				type,
			});
		} else{
			getNewNachRequests({
				type,
				page: page.selected + 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setPageLoading(true);
		if(type === "pending" && active === "3") {
			getDashboardLoanApplications({
				page: 1,
				limit: parseInt(e.target.value),
				fields_search: searchArray,
				type,
				fields_sort: sortArr,
			});
		} else{
			getNewNachRequests({
				type,
				page:1,
				limit: parseInt(e.target.value),
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(new_nach_requests[type].count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		if (
			new_nach_requests &&
			new_nach_requests[type]?.loans &&
			new_nach_requests[type].loans.length > 0
		) {
			return new_nach_requests[type].loans;
		}
	};
	useEffect(() => {
		if (Object.keys(sortArr).length) {
			setCurrentPage(1);
			setPageLoading(true);
			if(type === "pending" && active === "3") {
				getDashboardLoanApplications({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					field_sort: sortArr,
					type,
				});
			} else{
				getNewNachRequests({
					type,
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					fields_sort: sortArr,
				});
			}
		}
	}, [sortArr]);

	const conditionalRowStyles = [
		{
			when: row => row.enach_status === "Active",
			style: { borderLeft: "4px solid green" }
		},
		{
			when: row => row.enach_status === "Pending",
			style: { borderLeft: "4px solid orange" }
		},
		{
			when: row => row.enach_status === "Issued",
			style: { borderLeft: "4px solid yellow" }
		},
		{
			when: row => row.enach_status === "Inactive",
			style: { borderLeft: "4px solid #00a0e3" }
		},
		{
			when: row => row.enach_status === "Failed",
			style: { borderLeft: "4px solid red" }
		}
	];

	return (
		<>
			<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5">
				<div className="loan-application-table">
					<div className="d-flex justify-content-between align-items-center table-heading-line mb-2">
						<h4 className="m-0 fs-3">{type === "pending" ? "Unregistered" : "Registered"} Applications</h4>
						<Row className="mx-0 mt-1 mb-50">
							<Col sm="6">
								<div className="d-flex align-items-center">
									<label htmlFor="sort-select">Show</label>
									<select
										className="dataTable-select form-select"
										style={{
											width: "60px",
											padding: "2px 8px",
											margin: "0px 10px"
										}}
										id="sort-select"
										value={rowsPerPage}
										onChange={e => handlePerPage(e)}
									>
										{/* <option value={10}>10</option>*/}
										<option value={25}>25</option>
										<option value={50}>50</option>
										<option value={75}>75</option>
										<option value={100}>100</option>
									</select>
									<label htmlFor="sort-select">Results</label>
								</div>
							</Col>
						</Row>
					</div>
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button
								className="btn btn-primary btn-xs my-2"
								onClick={e => clearFilter(e)}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
					<hr />
					<DataTable
						conditionalRowStyles={conditionalRowStyles}
						noHeader
						pagination
						paginationServer
						columns={columns}
						data={dataToRender()}
						paginationComponent={CustomPagination}
						persistTableHead={true}
						progressPending={pageLoading}
						progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
						noDataComponent={
							<p className="w-100 m-5 text-start">There are no records to display</p>
						}
						fixedHeader="true"
						highlightOnHover="true"
						fixedHeaderScrollHeight="80vh"
						className="loan-app-table-container fix-overflow report-tables z-0"
					/>
				</div>

				<div>
					<OrganizationSidebarProvider>
						<LoanApplicationDetails
							handleCloseSideBar={() => setLoanDetails("")}
							loanId={loanDetails}
							setTableLoading={setPageLoading}
						/>
					</OrganizationSidebarProvider>
				</div>
			</div>
		</>
	);
};
export default NachRegisterNewCases;
