import React, { useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import "../../assets/css/autoComplete.css";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
	<div className="d-flex align-items-center">
		<div>
			{suggestion.image || suggestion.logo ? (
				<img src={suggestion.image || suggestion.logo} width="25" className="rounded-50" />
			) : (
				""
			)}
			{suggestion.loan_account_number ? suggestion.loan_account_number + " - " : (
				""
			)}
		</div>
		<div className="px-1">
			<p className="m-0">
				{suggestion.name} {suggestion.employee_code ? `(${suggestion.employee_code})` : ""}
			</p>
		</div>
	</div>
);

const AutoComplete = props => {
	const [suggestions, setSuggestions] = useState([]);
	const [value, setValue] = useState(props.val && props.val !== "empty" ? props.val : "");
	const [validateValue, setValidateValue] = useState("");
	const timeoutRef = useRef(null);
	// const [prevValue, setPrevValue] = useState('')

	const onSuggestionsFetchRequested = async ({ value }) => {
		clearTimeout(timeoutRef.current);

		timeoutRef.current = setTimeout(
			async () => setSuggestions(await loadSuggestions(value)),
			800
		);
	};


	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};
	const onChange = (event, { newValue }) => {
		// setPrevValue(value);
		setValue(newValue);
	};
	// const onKeyDown = (e, {suggestion}) => {
	//     if (e.keyCode === 13) {
	//         setValidateValue(suggestion.name);
	//         if (props.labelAsVal) {
	//             props.setFieldValue(props.name, suggestion.name);
	//             return false;
	//         }
	//     }
	// }
	const onBlur = event => {
		if (event.target.value !== validateValue && !props.labelAsVal) {
			{props.type === "dealer_id" ? setValue(props.name) : setValue(" ");}
			props.setFieldValue(props.name, null);
		}
		if (props.labelAsVal) {
			setValidateValue(event.target.value);
			props.setFieldValue(event, event.target.value);
			setValue(event.target.value);
		}
	};
	const loadSuggestions = async value => {
		const inputValue = value.trim().toLowerCase();

		// if (inputValue.indexOf(' ') >= 0 ||
		//     prevValue.trim().toLowerCase().includes(inputValue)) {
		//     return []
		// }
		const user = JSON.parse(localStorage.getItem("user"));
		const response = await fetch(
			`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${props.link}` +
				inputValue,
			{
				method: props.method ? props.method : "GET",
				headers: {
					Authorization: `Bearer ${user && user.access_token ? user.access_token : ""}`,
					source: user && user.source ? user.source : "",
					"Content-Type": "application/json"
				}
			}
		);

		const json = await response.json();
		if (json.response && json.response.status === 200 && (json.response.list || json.response.data)) {
			return json.response.list && json.response.list.length ? json.response.list : json.response.data && json.response.data.length ? json.response.data : [];
		} else {
			return [];
		}
	};
	const suggestionSelected = (event, { suggestion }) => {
		if (suggestion.id) {
			setValidateValue(suggestion.name);
			if (props.labelAsVal) {
				props.setFieldValue(props.name, suggestion.name);
				return false;
			}
			props.setFieldValue(props.name, suggestion.id);
			if(props.type && props.type === "loan_account_number"){
				props.setFieldValue(props.name, suggestion.loan_account_number);
				setValidateValue(suggestion.loan_account_number);
				setValue(suggestion.loan_account_number);
			}
			if (props.type && props.type === "loan_accounts") {
				props.setFieldValue("image", suggestion.image);
				props.setFieldValue("name", suggestion.name);
			}

			if(props.type && props.type === "dealer_id"){
				props.setFieldValue(suggestion.name, suggestion.id);
			}
		} else {
			setValidateValue(suggestion.name);
			props.setFieldValue(props.name, null);
			props.setFieldValue("city_code", null);
			setValue("");
		}
		if (suggestion.city_code) {
			props.setFieldValue("city_code", suggestion.city_code);
		}
	};
	React.useEffect(() => {
		if (props.val) {
			setValue(props.val);
		}
		if (props.val === "empty") {
			setValue("");
		}
	}, [props.val]);
	const inputProps = {
		placeholder: props.placeholder ? props.placeholder : "Enter Keyword",
		value,
		onChange,
		onBlur,
		// onKeyDown,
		id: props.name + "-input",
		className: "form-control"
	};

	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			onSuggestionSelected={suggestionSelected}
			getSuggestionValue={getSuggestionValue}
			renderSuggestion={renderSuggestion}
			inputProps={inputProps}
		/>
	);
};
export default AutoComplete;
