import { FaMapMarkerAlt } from "react-icons/fa";
import React, { useState } from "react";
import DropLocationModal from "./dashboard/DropLocationModal";
import { getLatLong, isLocationEnabled } from "../helpers/customFunctions";
import { toast } from "react-toastify";


const GetMyLocation = ({ label, modal = false, loanDetails, type="", openSideBar}) => {
	const [show, setShow] = useState(false);
	const [locationData, setLocationData] = useState({});

	const fetchmylocation = async () => {
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			return;
		}
		const { latitude, longitude } = await getLatLong();
		setLocationData({latitude, longitude});
		if (modal) {
			setShow(true);
		}
	};
	return (
		<>
			<button type="button" className="btn btn-primary" onClick={fetchmylocation}>
				<FaMapMarkerAlt /> {label}
			</button>
			{modal ? (
				<DropLocationModal
					show={show}
					openSideBar={openSideBar}
					loanDetails={loanDetails}
					type={type}
					onHide={() => setShow(false)}
					locationData={locationData}
				/>
			) : (
				""
			)}
		</>
	);
};

export default GetMyLocation;
