import { Helmet } from "react-helmet";
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { IoIosArrowDown } from "react-icons/io";
import { BsCart2, BsSearch } from "react-icons/bs";
import MedicineCard from "../components/MedicineCard";

const PharmacyDetail = () => {
	return (
		<>
			<Helmet>
				<title>Buy Medicines Online</title>
			</Helmet>
			<Navbar />

			<div className="bg-white">
				<section className="online-med-head">
					<div className="container-fluid">
						<div className="row cflex">
							<div className="col-md-10 col-sm-10">
								<div className="order-med-searchbar searchbar-margin">
									<div className="select">
										<p>Select Pincode</p>
										<IoIosArrowDown size="18px" />
									</div>
									<input type="text" placeholder="Search Medicines" />
									<button className="searchBtn">
										<BsSearch size="20px" color="#6c757d" />
									</button>
								</div>
							</div>
							<div className="col-md-2 col-sm-2 text-center">
								<button id="cart-icon">
									<BsCart2 size="20px" />
								</button>
							</div>
						</div>
					</div>
				</section>

				<section className="search-results-main">
					<div className="container">
						<div className="row">
							<div className="col-md-12 colsm-12">
								<div className="search-results-text">
									<h1>
										Showing all results for <span>Paracetamol 500</span>
									</h1>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 col-sm-12">
								<MedicineCard />
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</>
	);
};
export default PharmacyDetail;
