const PDFJS = window.pdfjsLib;
export const pdfToImg = {
	getImage
};

async function getImage(url) {
	try {
		const _PDF_DOC = await PDFJS.getDocument({ url });
		const response = await renderPage(_PDF_DOC);
		if (response) {
			return response;
		}
	} catch (error) {
		// alert(error.message);
	}
}

async function renderPage(pdf) {
	const canvas = document.createElement("canvas");
	canvas.setAttribute("className", "canv");

	for (let i = 1; i <= pdf.numPages; i++) {
		var page = await pdf.getPage(i);
		var viewport = page.getViewport({ scale: 1 });
		canvas.height = viewport.height;
		canvas.width = viewport.width;
		var render_context = {
			canvasContext: canvas.getContext("2d"),
			viewport: viewport
		};

		await page.render(render_context).promise;
		let img = canvas.toDataURL("image/png");
		return img;
	}
}
