import React, { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import NameComp from "../NameComp";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
import MinMaxFilter from "../MinMaxFilter";
import PickCashModal from "./PickCashModal";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { toast } from "react-toastify";
import { Badge, Col, Row } from "react-bootstrap";
import EmiCollectionForm from "./EmiCollectionForm";
import { isLocationEnabled } from "../../helpers/customFunctions";

const PickCashTable = ({type= ""}) => {
	const {handlePageLoaded} = useContext(MainContext);
	const {
		multiLoading,
		pickCash,
		getPickCashCases,
	} = useContext(OrganizationDashboardContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [loanAccountId, showLoanSideBar] = useState({loan_account_enc_id: "",loan_account_number: ""});
	const [pickCashId, setPickCashId] = useState({loan_account_enc_id: "",loan_account_number: ""});
	const [sortArr, setSortArr] = useState({});
	const [isClicked, setIsClicked] = useState(false);
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [loanAccountNumber, setLoanAccountNmber] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	
	const [clearFilters, setClearFilters] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({name: ""});
	};

	const handleChangeStatus = async (loanId, val) => {
		if (!window.confirm("Are you sure you want to Pick Cash?")) {
			return false;
		}
		handlePageLoaded(true);
		let data = {};
		data["status"] = val;
		data["pickup_enc_id"] = loanId;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/cash-pickup`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Updated Successfully");
					getPickCashCases({
						page: currentPage,
						limit: rowsPerPage,
						fields_search: searchArray,
						fields_sort: sortArr,
						page_type: type
					});
				} else {
					toast.error("An error has occurred, Please try again");
				}
				handlePageLoaded(false);
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};

	const statusColorObj = {
		collected: "success",
		pending: "warning",
		initiated: "secondary",
		PTP:"warning"
	};

	const handleShowEmiModal = async (val) => {
		if (!await isLocationEnabled()) { // Check if location is enabled
			toast.error("Please allow location to continue");
			return;
		}
		setShowEmiModal(val);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
				/>
			),
			minWidth: "280px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			cell: row => {
				return (
					<>
						<div className="justify-content-between d-flex align-items-center w-100">
							<span
								className="me-2 text-link"
								onClick={() =>
									showLoanSideBar({
										loan_account_enc_id: row.loan_account_enc_id,
										loan_account_number: row.loan_account_number
									})
								}
							>
								{row.loan_account_number ? row.loan_account_number : "-"}
							</span>

							<div className="d-flex align-items-center">
								{row.status === "pending" ? (
									<button className="btn-table-emi cash-width"
										onClick={() => handleChangeStatus(row.cash_pickup_enc_id, "initiated")}>
									Accept
									</button>
								): row.status === "initiated" ? (
									<button className="btn-table-emi btn-table-emi-secondary cash-width"
										onClick={() => {
											handleShowEmiModal(row.cash_pickup_enc_id);
											setLoanAccountNmber({
												loan_account_enc_id: row.loan_account_enc_id,
												loan_account_number: row.loan_account_number
											});
										}}>
									Collect Now
									</button>
								): ""}
							</div>
						</div>
					</>
				);
			},
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "200px",
			selector: row => (row.name ? row.name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() =>
							showLoanSideBar({
								loan_account_enc_id: row.loan_account_enc_id,
								loan_account_number: row.loan_account_number
							})
						}
					>
						{row.name ? row.name : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Initiated Amount"}
					id={"initiated_amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						{row.initiated_amount ? (
							<>
								<BiRupee size={16} className="me-n1" />{" "}
								{parseFloat(
									row.initiated_amount
								).toLocaleString("en-IN")}
							</>) : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Pickup Date"}
					id={"pickup_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					futureDate={true}
					pastDate={true}
				/>
			),
			minWidth: "180px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						{row.pickup_date ? (
							moment(row.pickup_date).format("DD MMM YYYY")
						) : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Status"}
					id={"pick_cash_status"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "160px",
			cell: row => {
				return (
					row.status ?
						<Badge className="text-capitalize mx-2" bg={statusColorObj[row.status]} pill>{row.status}</Badge>	
						: ""
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Collected Amount"}
					id={"collected_amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						{row.collected_amount ? (
							<>
								<BiRupee size={16} className="me-n1" />{" "}
								{parseFloat(
									row.collected_amount
								).toLocaleString("en-IN")}
							</>) : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Last Update On"}
					id={"updated_on"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
				/>
			),
			minWidth: "180px",
			omit: type === "dashboard",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						{row.updated_on && ["collected", "PTP"].includes(row.status) ? (
							moment(row.updated_on).format("DD MMM YYYY")
						) : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Collected By"}
					id={"collected_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "180px",
			selector: row => (row.collected_by ? row.collected_by : "-"),
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"Created By"}
					id={"created_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "180px",
			selector: row => (row.created_by ? row.created_by : "-"),
			sortable: false,
		},
	];

	const handlePagination = page => {
		getPickCashCases({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
			page_type: type
		});
		setCurrentPage(page.selected + 1);
	};

	const handlePerPage = e => {
		getPickCashCases({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			fields_sort: sortArr,
			page_type: type
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const reloadDetails = () => {
		clearFilter();
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = pickCash ? parseInt(pickCash.count) / rowsPerPage : 1;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};
	useEffect(() => {
		if(Object.keys(searchArray).length){
			const timerId = setTimeout(() => {
				setRowsPerPage(25);
				setCurrentPage(1);
				getPickCashCases({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					fields_sort: sortArr,
					page_type: type
				});
			}, [1000]);
			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArray]);
	
	useEffect(() => {
		if(Object.keys(sortArr).length){
			const timerId = setTimeout(() => {
				getPickCashCases({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					fields_sort: sortArr,
					page_type: type
				});
			}, [1000]);
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [sortArr]);

	useEffect(() => {
		if(!type){
			getPickCashCases({
				page: currentPage,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
	}, []);
	return (
		<>

			<Row className="mx-0 align-items-center">
			    <Col sm="6">
				 <div className="d-flex align-items-center p-0">
					 <h2 className= "ptp-heading">Pick Cash Requests</h2>
				 </div>
				</Col>
				{(!type || isClicked) && 
					<Col sm="6">
						<div className="d-flex align-items-center justify-content-end mb-3">
							<label htmlFor="sort-select">Show</label>
							<select
								className="dataTable-select form-select loan_acc_select"
								style={{
									width: "65px",
									padding: "2px 8px",
									margin: "0px 10px"
								}}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
							<label htmlFor="sort-select">Results</label>
						</div>
					</Col>
				}

				{showClearBtn && 
					<Col sm={12}>
						<div className="d-flex align-items-center justify-content-end">		
							<button className="btn btn-primary btn-xs txt-btn-sm-w" onClick={e => clearFilter(e)}>
									Reset
							</button>
						</div>
					</Col>
				}

				{(type && !isClicked) &&
					<Col sm={isClicked ? "12" : "6"}>
						<div className="d-flex align-items-center justify-content-end">		
							<button
								type="button"
								className="btn btn-primary btn-sm txt-btn-sm-w mb-0 p-2"
								onClick={() => {
									if (reloadDetails && !isClicked) {
										reloadDetails();
										setIsClicked(true);
									}
								}}
							>
							Click here to load
							</button>
						
						</div>
					</Col>
				}
			</Row>

			<DataTable
				columns={columns}
				data={isClicked && pickCash?.data || !type && pickCash?.data || []}
				pagination
				paginationServer
				paginationComponent={CustomPagination}
				highlightOnHover="true"
				persistTableHead={isClicked && pickCash?.data || !type && pickCash?.data ? true : false}
				progressPending={multiLoading?.includes("pick-cash")}
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				className={`fix-overflow ${isClicked && pickCash?.data ? "after-load-data" : "" }`}
				noDataComponent={isClicked && pickCash?.data || !type && pickCash?.data ?
					<p className="w-100 m-5 text-start">
							There are no records to display
					</p> : 
					""
				}
			/>

			<EmiLoanAccountSidebar
				handleCloseSideBar={() => showLoanSideBar("")}
				loanAccountId={loanAccountId}
				reloadDetails={reloadDetails}
			/>

			<EmiCollectionForm
				show={showEmiModal}
				hide={() => setShowEmiModal(false)}
				loan_number={loanAccountNumber}
				type={"pickCash"}
				reloadData={reloadDetails}
			/>

			<PickCashModal
				loanId={pickCashId}
				onHide={() => setPickCashId(false)}
				type={"table"}
				reload={reloadDetails}
			/>
		</>
	);
};

export default PickCashTable;
