import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import EmiCollectionTable from "../../components/dashboard/EmiCollectionTable";
import { useContext, useEffect } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";

const EmiCollection = () => {
	const {getDesignationList, designationList} = useContext(OrganizationDashboardContext);
	useEffect(() => {
		if (!designationList || !designationList.length) {
			getDesignationList();
		}
	}, []);
	return (
		<>
			<Helmet>
				<title>EMI Collection</title>
			</Helmet>
			<Navbar />
			<EmiCollectionTable stats={true}/>

			<EmiCollectionTable headingName={"Collection in Pipeline"} cases={"pipeline"}/>

			<EmiCollectionTable headingName={"Pending Collection in Link/QR"} cases={"pending"} paymentMethod={["1", "2"]}/>
		</>
	);
};

export default EmiCollection;
