import React, { useContext, useEffect, useState } from "react";
import { BsTelephonePlus } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { vehicleProductsList } from "../../data/authData";
import { readOnlyUsers } from "../../helpers/authServices";

const InitiateTvr = ({ loanDetails, reload, collectedAmount, feeStructures }) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const [tvrStatus, setTvrStatus] = useState({ id: "", status: "" });
	const initiateTVR = status => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm("Are you sure you want to change the status?")) {
			return false;
		}
		handlePageLoaded(true);
		let data = {
			loan_app_enc_id: loanDetails.loan_app_enc_id,
			type: 0,
			status: status
		};
		if (tvrStatus.id) {
			data["loan_application_tvr_enc_id"] = tvrStatus.id;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-tvr`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success(`TVR ${status === 0 ? "Initiated" : "Completed"} Successfully`);
					reload(loanDetails.loan_app_enc_id);
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "TVR Initiated Error"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};
	useEffect(() => {
		if (
			loanDetails &&
			loanDetails.loanApplicationTvrs &&
			loanDetails.loanApplicationTvrs.length
		) {
			const tempStatus = loanDetails.loanApplicationTvrs;
			if (tempStatus.length) {
				setTvrStatus({
					id: tempStatus[0].loan_application_tvr_enc_id,
					status: tempStatus[0].status
				});
			}
		}
	}, [loanDetails]);
	return (
		<>
			{collectedAmount >= 2000 || !feeStructures.length || vehicleProductsList.includes(loanDetails.loan_product) ? (
				<>
					{tvrStatus.status === "1" ? (
						<button type="button" className="btn btn-success">
							<BsTelephonePlus /> TVR Completed
						</button>
					) : (
						<>
							{tvrStatus.status === "0" ? (
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => initiateTVR(1)}
								>
									<BsTelephonePlus /> TVR Initiated
								</button>
							) : (
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => initiateTVR(0)}
								>
									<BsTelephonePlus /> Initiate TVR
								</button>
							)}
						</>
					)}
				</>
			) : (
				<button type="button" className="btn btn-primary" disabled={true}>
					<BsTelephonePlus /> Initiate TVR
				</button>
			)}
		</>
	);
};

export default InitiateTvr;
