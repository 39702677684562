import moment from "moment";
import { Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { FaCheck, FaClock, FaTimes, FaUserCheck } from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import EmiCollectedCashSidebarData from "./EmiCollectedCashSidebarData";
import { useContext, useState, useEffect } from "react";
import { MainContext } from "../../context/MainState";
import ApproveCashAmountModal from "./ApproveCashAmountModal";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { readOnlyUsers } from "../../helpers/authServices";

const EmiCashListSidebar = ({
	emiNameDetails,
	setShowsidebar,
	reload,
}) => {
	
	const {user} = useContext(MainContext);
	const [loading, setLoading] = useState(false);
	const [emiTableDetails, setEmiTableDetails] = useState("");
	const [bankDetails, setBankDetails] = useState("");
	const [cashData, setCashData] = useState({type:""});
	// const [key, setKey] = useState("basic_details");
	const [emiReceivedData, setEmiReceivedData] = useState([]);

	const columns = [
		{
			name: "Total Amount",
			minWidth: "150px",
			sortable: false,
			cell: row => {
				return (
					<p className="m-0">
						<BiRupee size={16} />{parseFloat(row.remaining_amount).toLocaleString("en-IN")}
					</p>
				);
			}
		},
		{
			name: "Received From",
			minWidth: "160px",
			selector: row => (row.received_from ? row.received_from : "-"),
			sortable: true,
		},
		{
			name: "Received On",
			minWidth: "160px",
			sortable: true,
			cell: row => {
				return (
					<p className="m-0">
						{moment(row.created_on).format(
							"DD MMM YYYY h:mm a"
						)}
					</p>
				);
			}
		},
		{
			name: "Action",
			minWidth: "100px",
			sortable: false,
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || user.username === "KKB" || user.username === "phf604" ? (
							<button
								className="btn btn-success btn-xs" title="Approve"
								onClick={() => {
									handleEmiCollect(row, "cash-amount");
								}}
							>
								<FaCheck/>
							</button>
						):""}
					</>
				);
			}
		}
	];

	const bankColumns = [
		{
			name: "Total Amount",
			minWidth: "150px",
			sortable: false,
			cell: row => {
				return (
					<p className="m-0">
						<BiRupee size={16} />{parseFloat(row.remaining_amount).toLocaleString("en-IN")}
					</p>
				);
			}
		},
		{
			name: "Received On",
			minWidth: "160px",
			sortable: true,
			cell: row => {
				return (
					<p className="m-0">
						{moment(row.created_on).format(
							"DD MMM YYYY h:mm a"
						)}
					</p>
				);
			}
		},
		{
			name: "Reference Number",
			minWidth: "160px",
			sortable: true,
			selector: row => (row.reference_number ? row.reference_number : "-"),
		},
		{
			name: "Receipt",
			minWidth: "160px",
			sortable: true,
			cell: row => {
				return (
					<Link to={row.receipt} target="blank">Preview</Link>
				);
			}
		},
		{
			name: "Approve",
			maxWidth: "80px",
			sortable: false,
			cell: row => {
				return (
					<>
						<button
							className="btn btn-success btn-xs" title="Approve"
							onClick={() => {
								approveToBank({id: row.cash_report_enc_id, type: 1});
							}}
						>
							<FaCheck/>
						</button>
					</>
				);
			}
		},
		{
			name: "Reject",
			maxWidth: "80px",
			sortable: false,
			cell: row => {
				return (
					<>
						<button
							className="btn btn-danger btn-xs" title="Approve"
							onClick={() => {
								approveToBank({id: row.cash_report_enc_id, type: 0});
							}}
						>
							<FaTimes/>
						</button>
					</>
				);
			}
		}
	];

	const openSideBar = () => {
		setLoading(true);
		// setKey("basic-details");
		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
		}
		const data = {
			user_id: emiNameDetails.user_id,
			org_id: user.organization_enc_id,
		};
		// addEvent()
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/employee-emi-collection`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEmiTableDetails(response.data.response);
					setLoading(false);
				}else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "An error occurred while getting employee emi collection list. Please try again"
					);
					setLoading(false);
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error("An error occurred while getting employee emi collection list. Please try again", error);
			});
	};

	const handleCloseSidebar = () => {
		setShowsidebar();
		setEmiTableDetails("");
		setBankDetails([]);
		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};

	const handleEmiCollect = (elem, type) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (type === "cash-amount") {
			setCashData({created_by: emiNameDetails.user_id, cash_id: elem.cash_report_enc_id, amount: elem.remaining_amount, type});
		}else{
			setCashData({created_by: elem.given_to,type});
		}
	};

	const getBankPayments = async () => {
		const data = {
			user_id: emiNameDetails.user_id,
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/pending-approvals`,data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setBankDetails(response.data.response.data);
				}else{
					setBankDetails([]);
				}
			})
			.catch(error => {
				toast.error("An error has occurred while pending emi cash approvals", error);
			});
	};

	const approveToBank = async (row) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm(`Are you sure you want to ${row.type === 1 ? "Approve" : "Reject"} this transaction`)) {
			return false;
		}
		const data = {
			cash_id: row.id,
			type: row.type,
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/authorised-approve`,data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					openSideBar();
					getBankPayments();
					reload();
					toast.success(`Transaction ${row.type === 1 ? "Approved" : "Rejected"} Successfully`);
				}else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "An error has occurred. Please try again"
					);
					setLoading(false);
				}
			})
			.catch(error => {
				toast.error("An error has occurred", error);
			});
	};

	const CashColumns = [
		{
			name: "Total Amount",
			minWidth: "150px",
			sortable: false,
			cell: row => {
				return (
					<p className="m-0">
						<BiRupee size={16} />{parseFloat(row.remaining_amount).toLocaleString("en-IN")}
					</p>
				);
			}
		},
		{
			name: "Received From",
			minWidth: "160px",
			selector: row => (row.received_from ? row.received_from : "-"),
			sortable: true,
		},
		{
			name: "Received On",
			minWidth: "160px",
			sortable: true,
			cell: row => {
				return (
					<p className="m-0">
						{moment(row.created_on).format(
							"DD MMM YYYY h:mm a"
						)}
					</p>
				);
			}
		},
	];

	const getReceivedCashList = () => {
		const data = {
			user_id: emiNameDetails.user_id,
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/approve-by-employee-list`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEmiReceivedData(response.data.response.data);
				} else {
					setEmiReceivedData([]);
				}
			})
			.catch(error => {
				toast.error("An error has occurred while getting received cash list. Please try again", error);
			});
	};

	useEffect(() => {
		if (emiNameDetails) {
			openSideBar();
			getBankPayments();
			if(emiNameDetails.received_pending_cash && parseFloat(emiNameDetails.received_pending_cash) > 0){
				getReceivedCashList();
			} else {
				setEmiReceivedData([]);
			}
		}
	}, [emiNameDetails]);

	// useEffect(() => {
	// 	if (key == "approve-cash" && !bankDetails.length) {
	// 		getBankPayments();
	// 	}
	// }, [key, setKey]);

	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail ${!emiNameDetails ? "expend-detail-close" : ""}`}>
				<div className="closeIcon" onClick={handleCloseSidebar}>
					<AiFillCloseCircle />
				</div>
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					{loading ? (
						<div className="cus-loader">
							<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
						</div>
					) : (
						<div className="emis-details-tab p-5">
							<div className="row my-3">
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										Employee Name:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{emiNameDetails.name}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										Mobile Number:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{emiNameDetails.phone}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										Branch:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{emiNameDetails.location_name
												? emiNameDetails.location_name
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										Received Cash:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{emiNameDetails.received_cash
												? parseFloat(
													emiNameDetails.received_cash
												  ).toLocaleString("en-IN")
												: "0"}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										EMI&#39;s Cash In Hand:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{emiNameDetails.collected_cash
												? parseFloat(
													emiNameDetails.collected_cash
												  ).toLocaleString("en-IN")
												: "0"}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										Total Cash In Hand:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{parseFloat(
												parseFloat(
													emiNameDetails.collected_cash ? emiNameDetails.collected_cash : 0
												) + parseFloat(
													emiNameDetails.received_cash ? emiNameDetails.received_cash : 0
												)).toLocaleString("en-IN")}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										No. Of EMI&#39;s In Hand:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{emiTableDetails && emiTableDetails.data ? emiTableDetails.data.length : 0}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										Unverified Cash In Bank:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{emiNameDetails.bank_unapproved_cash ? parseFloat(
												emiNameDetails.bank_unapproved_cash
												  ).toLocaleString("en-IN")
												: "0"}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex flex-wrap">
										Cash Transferred :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{emiNameDetails.received_pending_cash ? parseFloat(
												emiNameDetails.received_pending_cash
												  ).toLocaleString("en-IN")
												: "0"}
										</span>
									</p>
								</div>
							</div>
							<Tabs defaultActiveKey={"approve-cash"} id="emi">
								{user.user_type === "Financer" || user.username === "KKB" || user.username === "phf604" ? (
									<Tab eventKey={"approve-cash"} title="Approve Cash">
										<div className="row my-5">
											<DataTable
												noHeader
												// pagination
												// paginationServer
												columns={bankColumns}
												data={bankDetails}
												expandableRows 
												expandableRowsComponent={EmiCollectedCashSidebarData}
												noDataComponent="No Pending Cash EMI's"
												progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
											/>
										</div>
									</Tab>
								) : ""}
								<Tab eventKey={"Received-cash"} title="Received Cash">
									<div className="row my-5">
										<DataTable
											noHeader
											// pagination
											// paginationServer
											columns={columns}
											data={emiTableDetails.received_cash}
											expandableRows 
											expandableRowsComponent={EmiCollectedCashSidebarData}
											noDataComponent="No Pending Received Cash Requests"
											progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
										/>
									</div>
								</Tab>
								<Tab eventKey={"basic-detail"} title="Pending Cash EMI's">
									{/* {user.user_type === "Financer" || user.specialAccessRole ? (
										<div className="mt-3 text-end">
											<button
												className="btn btn-primary btn-sm m-0" title="Approve"
												onClick={() => {
													handleEmiCollect(emiNameDetails, "EMI");
												}}
											>
											Approve EMI
											</button>
										</div>
									): ""} */}
									<div className="my-3 w-100 pb-3" style={{ overflowX: "scroll" }}>
										<table className="table">
											<thead>
												<tr>
													<th>Loan Account Number</th>
													<th>Collection Date</th>
													<th>Customer Name</th>
													<th>Amount</th>
													<th>Loan Type</th>
													<th>Number</th>
													<th>Delay Reason</th>
													<th>PTP Amount</th>
													<th>PTP Date</th>
												</tr>
											</thead>
											<tbody>
												{emiTableDetails.data && emiTableDetails.data.length ? (
													emiTableDetails.data.map((value, index) => {
														return (
															<tr key={`branch_location${index}`}>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{value.loan_account_number}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{moment(value.collection_date).format(
																			"DD MMM YYYY"
																		)}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{value.customer_name}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		<BiRupee size={16} />
																		{parseFloat(
																			value.amount
																		).toLocaleString("en-IN")}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{value.loan_type
																			? value.loan_type
																			: value.other_loan_type}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{value.phone
																			? value.phone
																			: value.other_phone}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{value.delay_reason
																			? value.delay_reason
																			: "-"}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{value.ptp_amount ? (
																			<>
																				<BiRupee size={16} />
																				{parseFloat(
																					value.ptp_amount
																				).toLocaleString("en-IN")}
																			</>
																		) : (
																			"-"
																		)}
																	</p>
																</td>
																<td>
																	<p className="mb-0 text-gray-900 fw-medium">
																		{value.ptp_date
																			? moment(value.ptp_date).format(
																				"DD MMM YYYY"
																	  )
																			: "-"}
																	</p>
																</td>
															</tr>
														);
													})
												) : (
													<tr>
														<td colSpan="3">
															<div className="not-branch text-center">
														No Data Found
															</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
									<hr />
									<div className="mb-3">
										<h4>Attachments</h4>
										<div className="row">
											{emiTableDetails.data && emiTableDetails.data.length
												? emiTableDetails.data.map((value, index) => {
													return (
														<>
															{value.borrower_image ? (
																<div
																	className="col-md-3"
																	key={`allImages-b-${index}`}
																>
																	<a
																		href={
																			value.borrower_image
																				? value.borrower_image
																				: ""
																		}
																		target="_blank"
																		rel="noreferrer"
																		className="documentView imagesView"
																	>
																		<img
																			src={value.borrower_image}
																			alt={value.customer_name}
																		/>
																		<hr className="w-100 my-2" />
																		<div className="uploaded-by line-clamp-1">
																			<p className={"mb-2 m-0"}>
																				<FaUserCheck
																					fontSize={18}
																					color={"#00a0e3"}
																				/>{" "}
																				{value.customer_name}
																			</p>
																			{value.collection_date ? (
																				<p className={"m-0"}>
																					<FaClock
																						fontSize={14}
																						color={"#00a0e3"}
																					/>{" "}
																					{moment(
																						value.collection_date
																					).format(
																						"DD MMM YYYY"
																					)}
																				</p>
																			) : ""}
																		</div>
																	</a>
																</div>
															) : (
																""
															)}
															{value.pr_receipt_image ? (
																<div
																	className="col-md-3"
																	key={`allImages-p-${index}`}
																>
																	<a
																		href={
																			value.pr_receipt_image
																				? value.pr_receipt_image
																				: ""
																		}
																		target="_blank"
																		rel="noreferrer"
																		className="documentView imagesView"
																	>
																		<img
																			src={value.pr_receipt_image}
																			alt={value.customer_name}
																		/>
																		<hr className="w-100 my-2" />
																		<div className="uploaded-by line-clamp-1">
																			<p className={"mb-2 m-0"}>
																				<FaUserCheck
																					fontSize={18}
																					color={"#00a0e3"}
																				/>{" "}
																				{value.customer_name}
																			</p>
																			{value.collection_date ? (
																				<p className={"m-0"}>
																					<FaClock
																						fontSize={14}
																						color={"#00a0e3"}
																					/>{" "}
																					{moment(
																						value.collection_date
																					).format(
																						"DD MMM YYYY"
																					)}
																				</p>
																			) : ""}
																		</div>
																	</a>
																</div>
															) : (
																""
															)}
															{value.other_doc_image ? (
																<div
																	className="col-md-3"
																	key={`allImages-o-${index}`}
																>
																	<a
																		href={
																			value.other_doc_image
																				? value.other_doc_image
																				: ""
																		}
																		target="_blank"
																		rel="noreferrer"
																		className="documentView imagesView"
																	>
																		<img
																			src={value.other_doc_image}
																			alt={value.customer_name}
																		/>
																		<hr className="w-100 my-2" />
																		<div className="uploaded-by line-clamp-1">
																			<p className={"mb-2 m-0"}>
																				<FaUserCheck
																					fontSize={18}
																					color={"#00a0e3"}
																				/>{" "}
																				{value.customer_name}
																			</p>
																			{value.collection_date ? (
																				<p className={"m-0"}>
																					<FaClock
																						fontSize={14}
																						color={"#00a0e3"}
																					/>{" "}
																					{moment(
																						value.collection_date
																					).format(
																						"DD MMM YYYY"
																					)}
																				</p>
																			) : ""}
																		</div>
																	</a>
																</div>
															) : (
																""
															)}
														</>
													);
										  })
												: <div className="text-center">No Attachments Found</div>}
										</div>
									</div>
								</Tab>
								{emiNameDetails.received_pending_cash > 0 ? 
									<Tab eventKey={"cash-transferred"} title="Cash Transferred">
										<DataTable
											noHeader
											columns={CashColumns}
											striped
											data={emiReceivedData}
											expandableRows 
											expandableRowsComponent={EmiCollectedCashSidebarData}
											progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
											noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
										/>
									</Tab>
								 : ""}
							</Tabs>
							
						</div>
					)}
				</PerfectScrollbar>

				<ApproveCashAmountModal
					setCashModal={() => setCashData({type:""})}
					emiListData={emiTableDetails.data}
					openSideBar={openSideBar}
					cashData={cashData}
					reload={reload}
				/>
			</div>
		</>
	);
};

export default EmiCashListSidebar;
