import axios from "axios";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import BankDetails from "./BankDetails";
import AddLocation from "./AddLocation";
import { toast } from "react-toastify";
import BdoSearch from "./BdoSearch";
import { BsPersonFillAdd } from "react-icons/bs";

const DealerSideBar = ({ showSideBar, handleClose, dealerId }) => {
	const [showBankDetails, setShowBankDetails] = useState(false);
	const [showLocationModal, setShowLocationModal] = useState(false);
	const [dealerBankDetails, setDealerBankDetails] = useState("");
	const [dealerDetails, setDealerDetails] = useState("");
	const [dealerLocation, setDealerLocation] = useState("");
	const [loading, setLoading] = useState(false);
	const [showAssignBdoModal, setShowAssignBdoModal] = useState(false);
	
	const getDealersLocation = () => {
		let data = {
			organization_enc_id: dealerId,
		};

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/get-location`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setDealerLocation(response.data.response.list);
				} else {
					setDealerLocation([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred, Please try again");
			});
	};

	const openSideBar = () => {
		setLoading(true);

		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
		}

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/get-dealer-details`,
				{
					organization_enc_id: dealerId,
				}
			)
			.then((response) => {
				if (response.data.response.status === 200) {
					setDealerBankDetails(response.data.response.dealer_bank_details);
					setDealerDetails(response.data.response.dealer_info);
					setLoading(false);
					getDealersLocation(dealerId);
				}else{
					setDealerBankDetails({});
					setDealerDetails([]);
					setDealerLocation([]);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error("An error has occurred, Please try again");
				setLoading(false);
			});
	};

	const hideBankDetailModal = () => {
		setShowBankDetails(false);
	};

	const hideLocationModal = () => {
		setShowLocationModal(false);
	};

	const hideAssignBdoModal = () => {
		setShowAssignBdoModal(false);
	};

	const deleteLocation = async elem => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}

		let data = {
			location_enc_id: elem,
		};

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/remove-location`,
				data
			)

			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Location Deleted Successfully");
					getDealersLocation();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	useEffect(() => {
		if (showSideBar === true) {
			openSideBar();
		}
	}, [showSideBar]);


	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail ${showSideBar === false ? "expend-detail-close" : ""}`}>
				<div className="closeIcon" onClick={handleClose}>
					<AiFillCloseCircle />
				</div>
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					{loading ? (
						<div className="cus-loader">
							<img
								src="/images/pages/home/loader-black.svg"
								alt={process.env.REACT_APP_NAME}
							/>
						</div>
					):(
						<div className="expend-data expand-data-details">
							<div className="row px-4">
								<div className="d-flex align-items-center justify-content-end">
									{dealerBankDetails.name && dealerBankDetails.bank_account_number && dealerBankDetails.bank_name && dealerBankDetails.ifsc_code ? (
										""
									): (
										<button className="btn btn-primary me-2" onClick={() => setShowBankDetails(true)}>Add Bank Details</button>
									)}
									<button className="btn btn-primary me-2" onClick={() => setShowLocationModal(true)}>Add Location</button>
								</div>
							</div>

							<div className="row mb-1 mb-3 p-2">
								<div className="col-md-6 ht35">
									<p className="d-flex align-items-center ht35 text-capitalize">
										<span className="me-1">Name: </span> {dealerDetails.org_name}
									</p>
								</div>
								<div className="col-md-6 ht35">
									<p className="d-flex align-items-center ht35">
										<span className="me-1">Category: </span> {dealerDetails.category}
									</p>
								</div>
								<div className="col-md-6 ht35">
									<p className="d-flex align-items-center ht35 text-capitalize">
										<span className="me-1">Dealer Type: </span> {dealerDetails.dealer_type}
									</p>
								</div>
								<div className="col-md-6 ht35">
									<p className="d-flex align-items-center ht35">
										<span className="me-1">Contact Person: </span> {dealerDetails.contact_person}
									</p>
								</div>
								<div className="col-md-6 ht35">
									<p className="d-flex align-items-center ht35">
										<span className="me-1">Phone: </span> {dealerDetails.phone}
									</p>
								</div>
								<div className="col-md-6 ht35">
									<p className="d-flex align-items-center ht35">
										<span className="me-1">Email: </span> {dealerDetails.email ? dealerDetails.email : ""}
									</p>
								</div>
								<div className="col-md-6 ht35">
									<p className="d-flex align-items-center ht35">
										<span className="me-1">Assign BDO: </span> 
										<button
											onClick={() => setShowAssignBdoModal(true)}
											className="btn-share mb-0 ml-3"
										>
											<BsPersonFillAdd style={{ color: "rgb(41, 121, 255)" }}
												size={22}/>
										</button>
									</p>
								</div>
							</div>

							{dealerBankDetails.name && dealerBankDetails.bank_account_number && dealerBankDetails.bank_name && dealerBankDetails.ifsc_code ? (
								<div className="row mb-1 mb-3 p-2">
									<h3 className="mb-3">Bank Details
										<span onClick={() => setShowBankDetails(true)}><FaRegEdit style={{color: "rgb(41, 121, 255)", cursor: "pointer", marginLeft: "5px"}}
											size={16}/></span>
									</h3>
									<div className="col-md-6 ht35">
										<p className="d-flex align-items-center ht35">
											<span className="me-1">Account Holder Name: </span> {dealerBankDetails.name}
										</p>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex align-items-center ht35">
											<span className="me-1">Bank Name:</span> {dealerBankDetails.bank_name}
										</p>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex align-items-center ht35">
											<span className="me-1">Account Number:</span> {dealerBankDetails.bank_account_number}
										</p>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex align-items-center ht35">
											<span className="me-1">IFSC Code:</span> {dealerBankDetails.ifsc_code}
										</p>
									</div>
								</div>
							): ("")}

							{dealerLocation && dealerLocation.length ? (
								<div className="row mb-1 mb-3 p-2">
									<h3 className="mb-3">Location Details</h3>
									<table className="table">
										<thead>
											<tr>
												<th className="w-100">Name</th>
												<th className="text-center">Edit</th>
												<th className="text-center">Delete</th>
											</tr>
										</thead>
										<tbody>
											{dealerLocation && dealerLocation.length ? dealerLocation.map((value, index) => {
												return(
													<tr key={`dealer-loc-${index}`}>
														<td>
															<div className="branch-info">
																<p className="mb-0 fs-4">{value.location_name}, {value.address},{" "}
																	{value.city_name}</p>
															</div>
														</td>
														<td className="text-center">
															<button type="button" className="editBtn">
																<FaRegEdit
																	style={{
																		color: "rgb(41, 121, 255)",
																		cursor: "pointer"
																	}}
																	size={16}
																	onClick={() => setShowLocationModal(value)}
																/>
															</button>
														</td>
														<td className="text-center">
															<button type="button" className="editBtn">
																<RiDeleteBin6Fill
																	style={{ color: "red", cursor: "pointer" }}
																	size={16}
																	onClick={() => deleteLocation(value.location_enc_id)}
																/>
															</button>
														</td>
													</tr>
												);
											}) : (
												<tr>
													<td colSpan="3">
														<div className="not-branch text-center">
															No Branches Found
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							): ("")}

						</div>
					)}

				</PerfectScrollbar>
			</div>

			<BankDetails show={showBankDetails} onHide={hideBankDetailModal} dealerId={dealerId} reload={openSideBar} dealerBankDetails={dealerBankDetails}/>
			
			<AddLocation show={showLocationModal} onHide={hideLocationModal} dealerId={dealerId} reload={openSideBar} dealerLocation={dealerLocation}/>

			<BdoSearch show={showAssignBdoModal} onHide={hideAssignBdoModal} dealerDetails={dealerDetails} />
		</>
	);
};
export default DealerSideBar;
