import React, { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

const AddReferenceModal = ({
	showDiv,
	hide,
	refType,
	references,
	loanId,
	reload,
	allReferences
}) => {
	const {
		register,
		handleSubmit,
		reset,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm();
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [refLength, setRefLength] = useState();
	const [duplicateNumber, setDuplicateNumber] = useState(false);
	const hideDiv = () => {
		reset();
		hide();
		setRefLength();
	};
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const updateValue = (index, type, value) => {
		let items = [...refLength];
		let item = items[index];
		item[type] = value;
		item.reference = refType;
		items[index] = item;
		setRefLength(items);
	};
	const addMoreReference = () => {
		setRefLength([...refLength, { name: "", value: "", reference: refType }]);
	};
	const deleteReference = () => {
		let items = [...refLength];
		items.pop();
		setRefLength(items);
	};
	const onsubmit = () => {
		setFormIsLoading(true);
		if (duplicateNumber) {
			setFormIsLoading(false);
			toast.error("Number Already Exists");
			return false;
		}
		{refLength ? refLength.map((item, index) => {
			return(
				<>
					<div key={index}>
						{item.name = item?.name?.replace(/\b\w/g, char => char.toUpperCase())}
					</div>
				</>
			);
		}): "";}
		let data = { loan_app_enc_id: loanId, data: refLength };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-references`,
				data
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 200) {
					reset();
					hide();
					setRefLength();
					reload(loanId);
				}
			});
	};



	const handleUpdateData = (function() {
		let timer;
		return (val, index, type, fieldName) => {
		  clearTimeout(timer);
		  timer = setTimeout(() => {
				checkDuplicate(val, index, type, fieldName);
		  }, 800);
		};
	  })();

	const checkDuplicate = async (val, index, type, fieldName) => {
		let data = { phone: val, loan_id: loanId };
		let exists = "";
		if (val) {
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/check-number`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						exists = true;
					}
				});
		}
		if (val.length > 10) {
			val = val.replace(/\D/g, "").slice(-10);
		}
		let duplicate = allReferences ? allReferences.filter(item => item.value === val) : [];
		let duplicateRef = refLength ? refLength.filter(item => item.value === val) : [];

		if (duplicate.length > 0 || duplicateRef.length > 0 || exists) {
			setError(fieldName, { type: "custome", message: "Number Exists" });
			setDuplicateNumber(true);
		} else {
			clearErrors(fieldName);
			updateValue(index, type, val);
			setDuplicateNumber(false);
		}
	};

	useEffect(() => {
		references && references.length > 0
			? setRefLength([{ name: "", value: "", reference: refType }])
			: setRefLength([
				{ name: "", value: "", reference: refType },
				{ name: "", value: "", reference: refType }
			  ]);
	}, [references]);
	
	return (
		<>
			{showDiv ? (
				<form onSubmit={handleSubmit(onsubmit)}>
					<h5>Enter {refType} Reference</h5>
					<div className="row">
						{refLength
							? refLength.map((value, index) => {
								return (
									<>
										<div className="col-lg-5" key={`ref-${index}`}>
											<div className="mb-3">
												<input
													placeholder="Full Name"
													className="form-control"
													{...register(`name_${index}`, {
														required: "This is required."
													})}
													onChange={e => {
														updateValue(
															index,
															"name",
															e.target.value
														);
													}}
												/>
												<ErrorMessage
													errors={errors}
													name={`name_${index}`}
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-lg-5">
											<div className="mb-3">
												<input
												   type="number"
													placeholder="Phone Number"
													className="form-control"
													{...register(`phone_no_${index}`, {
														required: "This is required.",
														minLength: {
															value: 10,
															message:
																	"Mobile number Should be at least 10 numbers"
														},
														maxLength: {
															value: 10,
															message:
																	"Mobile number maximum be at least 10 numbers"
														},
														pattern: {
															value: phoneRegExp,
															message:
																	"Please enter a valid phone number"
														}
													})}
													onKeyUp={e => {
														handleUpdateData(
															e.target.value,
															index,
															"value",
															`phone_no_${index}`
														);
													}}
												/>
												<ErrorMessage
													errors={errors}
													name={`phone_no_${index}`}
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
									</>
								);
							  })
							: ""}

						<div className="col-lg-2 d-flex p-0">
							<button
							
								type="button"
								className="btn btn-sm btn-primary"
								onClick={() => addMoreReference()}
								style={{ marginRight: "5px",height:"47px"}}
							>
								<AiOutlinePlus fontSize={20} />
							</button>
							{(refLength && refLength.length > 2) ||
							(references.length !== 0 && refLength && refLength.length > 1) ? (
									<button
										type="button"
										className="btn btn-sm btn-primary"
										onClick={() => deleteReference()}
										style={{height:"47px"}}
									>
										<MdDelete fontSize={20} />
									</button>
								) : (
									""
								)}
						</div>
						<div className="col-lg-12">
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => hideDiv()}
								style={{ marginRight: "20px" }}
							>
								Cancel
							</button>
							{formIsLoading ? (
								<button type="button" className="btn btn-primary" disabled={true}>
									<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
								</button>
							) : (
								<button type="submit" className="btn btn-primary">
									Save
								</button>
							)}
						</div>
					</div>
				</form>
			) : (
				""
			)}
		</>
	);
};

export default AddReferenceModal;
