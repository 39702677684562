import Login from "../components/Login";
import { Helmet } from "react-helmet";

const LoginPage = () => {
	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>
			<div className="container">
				<div className="row">
					<div className="offset-md-2 col-sm-8 offset-md-2 mgtp">
						<Login withModal={false} />
					</div>
				</div>
			</div>
		</>
	);
};
export default LoginPage;
