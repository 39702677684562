import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import IncentivePointsReportTable from  "../../components/dashboard/IncentivePointsReportTable";
import { OrganizationUsersProvider } from "../../context/OrganizationUsersState";

const IncentivePointsReport = () => {
	return (
		<>
			<Helmet>
				<title>Incentive Points Report </title>
			</Helmet>
			<Navbar />
			
			<OrganizationUsersProvider>
			  <IncentivePointsReportTable headingName={"Incentive Points Report"}/>
			</OrganizationUsersProvider>
		</>
	);
};

export default IncentivePointsReport;