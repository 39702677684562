import { Tab, Tabs } from "react-bootstrap";
import PurposeTab from "./PurposeTab";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BsArrowRightShort } from "react-icons/bs";
import StatusTab from "./StatusTab";
import DocumentsTab from "./DocumentsTab";
import ProductLoginFeeTab from "./ProductLoginFeeTab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import ImagesTab from "./ImagesTab";
import PendencyTab from "./PendencyTab";
import DisbursementCharges from "./DisbursementCharges";
import { ErrorMessage } from "@hookform/error-message";

const LoanProductTabs = ({
	back,
	assigned_loan_types,
	getLoanProducts,
	productData,
	handlePageLoaded
}) => {
	const { register, handleSubmit, reset, setValue, formState: {errors} } = useForm();

	const [showDocuments, setShowDocuments] = useState(false);
	const [showStatus, setShowStatus] = useState(false);
	const [showImages, setShowImages] = useState(false);
	const [showPendencies, setShowPendencies] = useState(false);
	const [showLoginFee, setShowLoginFee] = useState(false);
	const [showDisbursementFee, setShowDisbursementFee] = useState(false);
	const alphaNumericRegex = new RegExp(/^[a-zA-Z0-9]+$/);
	const getSelectedTab = k => {
		if (k === "document") {
			setShowDocuments(true);
		}
		if (k === "status") {
			setShowStatus(true);
		}
		if (k === "images") {
			setShowImages(true);
		}
		if (k === "pendencies") {
			setShowPendencies(true);
		}
		if (k === "Login Fee") {
			setShowLoginFee(true);
		}
		if (k === "Disbursement") {
			setShowDisbursementFee(true);
		}
	};

	const onsubmit = async values => {
		values["financer_loan_product_enc_id"] = productData.financer_loan_product_enc_id;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product`,
				values
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reset();
					back();
					getLoanProducts();
					toast.success("Product Updated successfully");
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	const validateInputVal = (e) => {
		const inputVal = e.target.value;
		if (!alphaNumericRegex.test(inputVal)) {
			setValue("product_code", inputVal.substring(0, inputVal.length - 1));
		} else {
			setValue("product_code", inputVal.toUpperCase());
		}
	};
	useEffect(() => {
		if (productData.assigned_financer_enc_id) {
			setValue("assigned_financer_loan_type_enc_id", productData.assigned_financer_enc_id);
			setValue("name", productData.name);
			setValue("product_code", productData.product_code);
			setValue("status", productData.status);
		}
	}, [productData]);
	return (
		<>
			<div className="mb-3 disFlex justify-content-between align-items-center">
				<h3>
					<span className="font-light">{productData.loan}</span> <BsArrowRightShort />{" "}
					<span className="color-b">{productData.name}</span>
				</h3>
				<button className="btn btn-sm btn-primary" onClick={back}>
					<IoMdArrowRoundBack /> Back
				</button>
			</div>
			<form className="mb-5" onSubmit={handleSubmit(onsubmit)}>
				<h3 className="mb-2 text-primary">Update Loan Products</h3>
				<div className="row align-items-start">
					<div className="col-lg-3">
						<label className="form-label">Loan Type</label>
						<select
							className="form-control mh-48"
							{...register("assigned_financer_loan_type_enc_id", {
								required: "This field is required"
							})}
						>
							<option value="">Select Loan Type</option>
							{assigned_loan_types
								? assigned_loan_types.map((value, index) => {
									return (
										<option
											key={`loan-options-${index}`}
											value={value.assigned_financer_enc_id}
										>
											{value.name}
										</option>
									);
								  })
								: ""}
						</select>
						<ErrorMessage
							errors={errors}
							name="assigned_financer_loan_type_enc_id"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
					<div className="col-lg-3">
						<label className="form-label">Loan Product</label>
						<input
							type="text"
							className="form-control"
							{...register("name", {
								required: "This field is required"
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="name"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
					<div className="col-lg-2 disFlex align-items-end">
						<label className="form-label">Product Code</label>
						<input
							type="text"
							className="form-control"
							{...register("product_code", {
								required: "This field is required",
							})}
							onChange={validateInputVal}
						/>
						<ErrorMessage
							errors={errors}
							name="product_code"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
					<div className="col-lg-2 mb-2">
						<label className="form-label">Status</label>
						<select
							className="form-control mh-48" style={{"height":"48px"}}
							{...register("status", {
								required: "This field is required"
							})}
						>
						
							<option value="Active">Active</option>
							<option value="Inactive">Inactive</option>
						</select>
						<ErrorMessage
							errors={errors}
							name="status"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>

					<div className="col-lg-2 disFlex align-items-end mt-5">
						<button type="submit" className="btn btn-primary">Update</button>
					</div>
				</div>
			</form>
			<Tabs onSelect={k => (getSelectedTab(k))}>
				<Tab eventKey="purpose" title="Purpose">
					<PurposeTab
						productId={productData.financer_loan_product_enc_id}
						handlePageLoaded={handlePageLoaded}
					/>
				</Tab>
				<Tab eventKey="status" title="Status">
					<StatusTab
						productId={productData.financer_loan_product_enc_id}
						showDocuments={showStatus}
						handlePageLoaded={handlePageLoaded}
					/>
				</Tab>
				<Tab eventKey="document" title="Documents">
					<DocumentsTab
						productId={productData.financer_loan_product_enc_id}
						showDocuments={showDocuments}
						handlePageLoaded={handlePageLoaded}
					/>
				</Tab>
				<Tab eventKey="images" title="Images">
					<ImagesTab
						productId={productData.financer_loan_product_enc_id}
						showDocuments={showImages}
						handlePageLoaded={handlePageLoaded}
					/>
				</Tab>
				<Tab eventKey="pendencies" title="Pendencies">
					<PendencyTab
						productId={productData.financer_loan_product_enc_id}
						showDocuments={showPendencies}
						handlePageLoaded={handlePageLoaded}
					/>
				</Tab>
				<Tab eventKey="Login Fee" title="Fee Structure">
					<ProductLoginFeeTab
						productId={productData.financer_loan_product_enc_id}
						showDocuments={showLoginFee}
						handlePageLoaded={handlePageLoaded}
					/>
				</Tab>
				<Tab eventKey="Disbursement" title="Disbursement Charges">
					<DisbursementCharges
						productId={productData.financer_loan_product_enc_id}
						showDocuments={showDisbursementFee}
						handlePageLoaded={handlePageLoaded}
					/>
				</Tab>
			</Tabs>
		</>
	);
};
export default LoanProductTabs;
