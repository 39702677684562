import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BiRupee } from "react-icons/bi";
import NameComp from "../NameComp";
import moment from "moment/moment";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos, MdDelete } from "react-icons/md";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import { MainContext } from "../../context/MainState";
import { RiUserSharedFill } from "react-icons/ri";
import axios from "axios";
import { useForm } from "react-hook-form";

const RecoveryLoanAccountTable = ({type = "hard_recovery"}) => {
	const {
		getEmiAccountData,
		accountData,
		isLoading,
		branches_list,
		getBranches
	} = useContext(OrganizationDashboardContext);
	const { reset } = useForm();
	const { user, handlePageLoaded, loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [sharedModal, setSharedModal] = useState(false);
	const [loanAccountId, showLoanSideBar] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [userSpecial, setUserSpecial] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [selectedRows, setSelectedRows] = useState(false);

	const handleSelectedRows = ({ selectedRows }) => {
		setSelectedRows(selectedRows);
	};

	const reloadDetails = () => {
		getEmiAccountData({
			page: currentPage,
			limit: rowsPerPage,
			type
		});
	};

	const bucketList = [
		{
			label: "On Time",
			value: "onTime"
		},
		{
			label: "0-30",
			value: "sma-0"
		},
		{
			label: "30-60",
			value: "sma-1"
		},
		{
			label: "60-90",
			value: "sma-2"
		},
		{
			label: "90+",
			value: "npa"
		}
	];

	const handleAccessChange = async (val, id) => {
		if (val === "delete" && !window.confirm("Are you sure you want to remove the access?")) {
			return;
		}

		let data = {};
		data["assigned_enc_id"] = id;
		if (val === "delete") {
			data["delete"] = true;
		} else {
			data["access"] = val;
		}

		handlePageLoaded(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-loan-acc-access`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					reset();
					setSharedModal(false);
					reloadDetails();
					toast.success("Saved Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error(error.message);
				console.log(error);
			});
	};

	const hardRecovery = val => {
		if (!window.confirm("Are you sure you want to Change Status")) {
			return false;
		}
		const data = {};
		data["loan_accounts"] = selectedRows.map(vals => vals.loan_account_enc_id);
		data["value"] = val;
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/hard-recovery`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Update Successfully");
					reloadDetails();
					setSelectedRows(false);
					setToggleClearRows(!toggledClearRows);
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
				console.log(error);
			});
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "260px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			cell: row => {
				return (
					<div className="justify-content-between d-flex align-content-center w-100">
						<span
							className="me-2 text-link"
							onClick={() =>
								showLoanSideBar({
									loan_account_enc_id: row.loan_account_enc_id,
									loan_account_number: row.loan_account_number
								})
							}
						>
							{row.loan_account_number ? row.loan_account_number : "-"}
						</span>
					</div>
				);
			},
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() =>
							showLoanSideBar({
								loan_account_enc_id: row.loan_account_enc_id,
								loan_account_number: row.loan_account_number
							})
						}
					>
						{row.name ? row.name : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Bucket"}
					id={"bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					bucketList={bucketList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.sub_bucket ? row.sub_bucket === "0" ? "X" : row.sub_bucket : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "180px"
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					branches={branches_list}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"EMI Date"}
					id={"emi_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => moment(row.emi_date).valueOf(),
			sortable: false,
			cell: row => moment(row.emi_date).format("DD")
		},
		{
			name: (
				<NameComp
					title={"EMI Amount"}
					id={"emi_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.emi_amount ? row.emi_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{row.emi_amount
								? parseFloat(row.emi_amount).toLocaleString("en-IN")
								: ""}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Pending Amount"}
					id={"total_pending_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: "",
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{row.total_pending_amount
								? parseFloat(row.total_pending_amount).toLocaleString("en-IN")
								: "0"}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Overdue EMI"}
					id={"overdue_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.overdue_amount ? row.overdue_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.overdue_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.overdue_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Ledger Amount"}
					id={"ledger_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.ledger_amount ? row.ledger_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.ledger_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.ledger_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},

		{
			name: (
				<NameComp
					title={"Financed Amount"}
					id={"financed_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.financed_amount ? row.financed_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.financed_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.financed_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},

		{
			name: (
				<NameComp
					title={"POS"}
					id={"pos"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.pos ? row.pos : "-"),
			omit: !(user.user_type === "Financer" || ["monika200", "phf1136"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.pos ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} /> {parseFloat(row.pos).toLocaleString("en-IN")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},

		{
			name: (
				<NameComp
					title={"Sales Priority"}
					id={"sales_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "100px",
			selector: row => (row.sales_priority ? row.sales_priority : "-"),
			sortable: false,
			cell: row => {
				return <>{row.sales_priority ? row.sales_priority : "-"}</>;
			},
			omit: !userSpecial
		},

		{
			name: (
				<NameComp
					title={"Telecaller Priority"}
					id={"telecaller_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "100px",
			selector: row => (row.telecaller_priority ? row.telecaller_priority : "-"),
			sortable: false,
			cell: row => {
				return <>{row.telecaller_priority ? row.telecaller_priority : "-"}</>;
			},
			omit: !userSpecial
		},
		{
			name: (
				<NameComp
					title={"Collection Priority"}
					id={"collection_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "100px",
			selector: row => (row.collection_priority ? row.collection_priority : "-"),
			sortable: false,
			cell: row => {
				return <>{row.collection_priority ? row.collection_priority : "-"}</>;
			},
			omit: !userSpecial
		},
		{
			name: (
				<NameComp
					title={"Priority"}
					id={"priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "100px",
			selector: row => (row.priority ? row.priority : "-"),
			sortable: false,
			cell: row => {
				return <>{row.priority ? row.priority : "-"}</>;
			},
			omit: userSpecial
		},

		{
			name: (
				<NameComp
					title={"Assigned Telecaller"}
					id={"assigned_caller"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => row.assigned_caller,
			sortable: false,
			cell: row => (row.assigned_caller ? row.assigned_caller : "-")
		},

		{
			name: (
				<NameComp
					title={"Shared With"}
					id={"sharedTo"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => moment(row.apply_date).valueOf(), // omit: user.user_type !== 'Financer',
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								{row.assignedLoanViews && row.assignedLoanViews.length > 0 ? (
									<div
										className="register-count"
										onClick={() => setSharedModal(row)}
									>
										<div className="reg-img">
											{row.assignedLoanViews.map((value, index) => {
												if (index <= 1) {
													return (
														<span
															className={`reg${index + 1} reg`}
															key={`accessList-${index}`}
														>
															<img src={value.image} />
														</span>
													);
												}
											})}
										</div>
										{row.assignedLoanViews.length > 2 ? (
											<span style={{ color: "#00a0e3", fontSize: "13px" }}>
												{" "}
												+{row.assignedLoanViews.length - 2}{" "}
											</span>
										) : (
											""
										)}

										<span className="cont">
											<RiUserSharedFill color="#00a0e3" size={20} />
										</span>
									</div>
								) : (
									""
								)}
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Last Collection Amount"}
					id={"last_emi_received_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "190px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.last_emi_received_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.last_emi_received_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Last Collection Date"}
					id={"last_emi_received_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.last_emi_received_date
							? moment(row.last_emi_received_date).format("DD MMM")
							: "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Total Installments"}
					id={"total_installments"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.total_installments ? row.total_installments : "-"),
			sortable: false
		}
	];

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	useEffect(() => {
		if (Object.keys(searchArray).length) {
			setCurrentPage(1);
			getEmiAccountData({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				type
			});
		}
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray]);

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getEmiAccountData({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			type
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getEmiAccountData({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			type
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = accountData && accountData[type] ? parseInt(accountData[type].count) / rowsPerPage : 1;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	useEffect(() => {
		if (!branches_list) {
			getBranches();
		}
		if (user.user_type === "Financer" || user.specialAccessRole) {
			setUserSpecial(true);
		}
		getEmiAccountData({
			page: 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			type: "hard_recovery"
		});
	}, []);

	return (
		<>
			<div className="dashboard-dsa pb-5">
				<div className="shadow-lg rounded-3 py-4 px-6">
					<h2 className="mb-3">Recovery Loan Accounts</h2>
					<Row className="mx-0 mt-1 mb-50 align-items-center">
						<Col sm="6">
							<div className="d-flex align-items-center">
								<label htmlFor="sort-select">Show</label>
								<select
									className="dataTable-select form-select loan_acc_select"
									style={{
										width: "65px",
										padding: "2px 8px",
										margin: "0px 10px"
									}}
									id="sort-select"
									value={rowsPerPage}
									onChange={e => handlePerPage(e)}
								>
									<option value={10}>10</option>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={75}>75</option>
									<option value={100}>100</option>
								</select>
								<label htmlFor="sort-select">Results</label>
							</div>
						</Col>
						<Col sm="6">
							<div className="d-flex align-items-center justify-content-end">
								{showClearBtn ? (
									<button
										className="btn btn-primary"
										onClick={e => clearFilter(e)}
									>
										Reset
									</button>
								) : (
									""
								)}
								{selectedRows && selectedRows.length ? (
									<div>
										<button
											type="button"
											className="btn btn-primary ms-2"
											onClick={() => hardRecovery("0")}
										>
										Move to Loan Accounts ({selectedRows.length})
										</button>
									</div>
								) : ("")}
							</div>
						</Col>
					</Row>
					<hr />
					<DataTable
						columns={columns}
						data={accountData && accountData[type] ? accountData[type].data : []}
						pagination
						paginationServer
						paginationComponent={CustomPagination}
						highlightOnHover="true"
						persistTableHead={true}
						progressPending={isLoading === "table"}
						progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
						reloadData={reloadDetails}
						className="loan-account-table-container fix-overflow"
						selectableRows={user.user_type === "Financer" || user.specialAccessRole}
						onSelectedRowsChange={handleSelectedRows}
						clearSelectedRows={toggledClearRows}
						noDataComponent={
							<p className="w-100 m-5 text-start">There are no records to display</p>
						}
					/>
				</div>
			</div>

			<EmiLoanAccountSidebar
				handleCloseSideBar={() => showLoanSideBar("")}
				loanAccountId={loanAccountId}
			/>

			<Modal show={sharedModal} onHide={() => setSharedModal(false)} size={"md"} centered>
				<div className="container position-relative">
					<div className="row">
						<div className="col-md-12">
							<div className="bg-white rounded-3 py-5 px-3">
								{sharedModal &&
								sharedModal.assignedLoanViews.filter(
									name => name.user_type === "bdo"
								).length > 0 ? (
										<div className="imageList">
											<h4>BDO List</h4>
											<ul className="usersAccessList p-0">
												{sharedModal &&
												sharedModal.assignedLoanViews
													.filter(name => name.user_type === "bdo")
													.map((value, index) => {
														return (
															<>
																<li
																	key={`usersList-${index}`}
																	className="d-flex align-items-center mb-3"
																>
																	<div className="userAvatar">
																		<img src={value.image} />
																	</div>
																	<div className="userDetails">
																		<h4 className="m-0">
																			{value.name}
																		</h4>
																	</div>
																	<div>
																		<button
																			type="button"
																			onClick={() =>
																				handleAccessChange(
																					"delete",
																					value.assigned_enc_id
																				)
																			}
																			className="btn btn-primary btn-xs"
																		>
																			<MdDelete
																				fontSize={15}
																			/>
																		</button>
																	</div>
																</li>
															</>
														);
													})}
											</ul>
										</div>
									) : (
										""
									)}
								{sharedModal &&
								sharedModal.assignedLoanViews.filter(
									name => name.user_type === "collection_manager"
								).length > 0 ? (
										<>
											<div className="imageList">
												<h4>Collection Manager List</h4>
												<ul className="usersAccessList p-0">
													{sharedModal &&
													sharedModal.assignedLoanViews
														.filter(
															name =>
																name.user_type ===
																"collection_manager"
														)
														.map((value, index) => {
															return (
																<>
																	<li
																		key={`usersList-${index}`}
																		className="d-flex align-items-center mb-3"
																	>
																		<div className="userAvatar">
																			<img
																				src={value.image}
																			/>
																		</div>
																		<div className="userDetails">
																			<h4 className="m-0">
																				{value.name}
																			</h4>
																		</div>
																		<div>
																			<button
																				type="button"
																				onClick={() =>
																					handleAccessChange(
																						"delete",
																						value.assigned_enc_id
																					)
																				}
																				className="btn btn-primary btn-xs"
																			>
																				<MdDelete
																					fontSize={15}
																				/>
																			</button>
																		</div>
																	</li>
																</>
															);
														})}
												</ul>
											</div>
										</>
									) : (
										""
									)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default RecoveryLoanAccountTable;
