import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import PageInnerTitle from "../components/PageInnerTitle";
import { Link } from "react-router-dom";
import { isCredifinUser } from "../helpers/customFunctions";

const NotFound = () => {
	return (
		<div>
			<Helmet>
				<title>Page Not Found</title>
				<meta name="description" content="Page Not Found" />
			</Helmet>
			<Navbar />
			<PageInnerTitle
				title="Not Found"
				disabledTabs={["about", "types", "features", "eligibility", "faq", "get_in_touch"]}
				disableTitle={true}
			/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="mt-n6 bg-white mb-10 rounded-3 shadow-sm p-10 position-relative">
							<div className="row">
								<div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12">
									<div className="mb-8">
										<img
											src="/images/background/error-img.png"
											className="img-fluid"
											alt="Borrow - Loan Company Responsive Website Templates"
										/>
									</div>
									<div className="text-center">
										<h2 className="text-primary text-uppercase">Sorry</h2>
										<h1 className="mb-2 fw-semi-bold">Page Not Found</h1>
										<p className="mb-5">
											The webpage you were trying to reach could not be found
											on the server, or that you typed in the URL incorrectly.
										</p>
										<Link to="/" className="btn btn-primary">
											Go to homepage
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{!isCredifinUser() ? (
				<Footer />
			) : ""}
		</div>
	);
};
export default NotFound;
