import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import EmiCollectionTable from "../../components/dashboard/EmiCollectionTable";
import { useContext } from "react";
import { MainContext } from "../../context/MainState";

const ExceptionReport = () => {
	const { user } = useContext(MainContext);
	return (
		<>
			<Helmet>
				<title>Exception Report </title>
			</Helmet>
			<Navbar />
			<EmiCollectionTable headingName={"Exception Report"} type={user.user_type === "Financer" || user.specialAccessRole} cases={"exception"}/>
		</>
	);
};

export default ExceptionReport;
