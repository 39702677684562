const authData = {
	user: localStorage.user ? JSON.parse(localStorage.getItem("user")) : ""
};
export const vehicleProductsList = [
	"E-Rickshaw",
	"Used Commercial Vehicle Loan",
	"Used Car Loan",
	"EV Two Wheeler",
	"Two Wheeler",
	"Used E-Rickshaw",
	"L5 E-Rickshaw",
	"Used Bike Loan"
];
export const propertyProductsList = [
	"Loan Against Property",
	"Capital LAP BC 10",
	"Capital HL BC 25",
	"Amrit Home loan",
	"Amrit LAP",
	"BHN HL",
	"BHN LAP"
];

export default authData;
