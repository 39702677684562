import React, { useContext, useEffect, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { MainContext } from "../../context/MainState";
import { GiCheckMark, GiSandsOfTime } from "react-icons/gi";
import moment from "moment";
import CaptureUserImage from "../dashboard/CaptureUserImage.js";
import { FaClock, FaPlus, FaUserCheck } from "react-icons/fa";
import { getLatLong, isLocationEnabled } from "../../helpers/customFunctions";
import { IoCloseCircle } from "react-icons/io5";
import { BsFillCameraFill } from "react-icons/bs";

let base_uri2 = "";

const FinancerImagesTab = ({ rawData, openSideBar, showImages, getDocumentsData, loanImages, getImageData, handleChangeData}) => {
	const [addImage, setAddImage] = useState(false);
	const [uploadedFile, setUploadedFile] = useState(false);
	const [uploadedImageName, setUploadedImageName] = useState({
		name: "",
		id: ""
	});
	const [previewImages, setPreviewImages] = useState([]);
	const genderSelect = {
		1: "Male",
		2: "Female",
		3: "Others"
	};

	const { images_types, handlePageLoaded, user } = useContext(MainContext);
	const [loanApplicationImages, setloanApplicationImages] = useState([]);

	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		base_uri2 = result_base64;
		return result_base64;
	};

	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};

	const imagePreviews = [];

	const handleFileInputChange = (input, certificate_name = "", id = "") => {
		if (!input.target.files) {
			return false;
		}
		if (certificate_name && id) {
			setUploadedImageName({ name: certificate_name, id });
		}
		const file = input.target.files[0];
		const fileExtenstion = file.name.split(".").pop().toLowerCase();

		if(certificate_name === "tv_image"){
			setUploadedImageName({ name: certificate_name });
			const reader = new FileReader();
			reader.onload = async e => {
				const result = e.target.result;
				imagePreviews.push(result);
				setPreviewImages([...previewImages, ...imagePreviews]);
			};
			reader.readAsDataURL(file);
		}

		if (fileExtenstion === "png" || fileExtenstion === "jpg" || fileExtenstion === "jpeg") {
			handlePageLoaded(true);
			getUri(file).then(() => {
				setUploadedFile(base_uri2);
			});
		}
	};

	const onSubmit = async () => {
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			handlePageLoaded(false);
			return;
		}
		try {
			const { latitude, longitude } = await getLatLong();
			const formData = new FormData();
			formData.append("loan_app_enc_id", rawData.loan_app_enc_id);
			formData.append("product_image_enc_id", uploadedImageName.id ? uploadedImageName.id : "");
			formData.append("image", uploadedFile);
			formData.append("latitude", latitude);
			formData.append("longitude", longitude);
			formData.append("type", "2");
			const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/upload-application-image`, formData);
			handlePageLoaded(false);
			setUploadedImageName("");
			if (response.data.response.status === 200) {
				toast.success("Submit Successfully");
				openSideBar();
				if(addImage){
					handleChangeData("Delivered", "tv_delivery_status");
				}
				getDocumentsData(rawData.loan_app_enc_id, 2);
			} else {
				toast.error("An error has occurred, Please try again");
			}
		} catch (error) {
			handlePageLoaded(false);
			console.log(error);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		const imagesData = images_types?.length
			? images_types.filter(item => item.name === rawData.loan_product)
			: [];
		setloanApplicationImages(imagesData.length ? imagesData[0].financerLoanProductImages : []);
	}, [rawData]);

	const handleTvModalHide = () => {
		setAddImage(false);
		setPreviewImages([]);
	};

	useEffect(() => {
		if (showImages && !loanImages) {
			getDocumentsData(rawData.loan_app_enc_id, 2);
		}
	}, [showImages]);

	useEffect(() => {
		if ((uploadedImageName.name === "tv_image") || (uploadedImageName.id && uploadedImageName.name)) {
			onSubmit({
				assigned_to: "",
				financer_document_type: uploadedImageName.name,
				financer_loan_product_document_enc_id: uploadedImageName.id,
				short_description: ""
			});
		}
	}, [uploadedFile]);

	return (
		<>
			{user.user_type !== "Lender Auditor" ? (
				<>
					{rawData?.has_tv_scheme === "Yes" && process.env.REACT_APP_TYPE === "DEV" ? (
						<div className="row">
							<div className="capture_tv mb-3">
								<button
									type="button"
									className={`btn btn-sm mx-1 btn-sm ${rawData?.tv_delivery_status === "Pending" ? "btn-primary" : "btn-secondary"}`}
									onClick={() => setAddImage("tv")}
								>
									TV {rawData?.tv_delivery_status !== "Pending" ? "Delivered" : "Delivery Image"}
								</button>
							</div>
						</div>
					): ""}
					<div className="row mb-4">
						{rawData?.loanCoApplicants?.length
							? rawData.loanCoApplicants
								.slice()
								.sort((a, b) => a.borrower_type.localeCompare(b.borrower_type))
								.map((value, index) => {
									return (
										<>
											<div key={`card-${index}`} className="col-md-3">
												<div className="borrower-main p-3 mb-3 rounded-3 shadow-el position-relative">
													<div className="co-borrower-name">{value.borrower_type}</div>
													<div className="borrower-upper d-flex align-items-center">
														<div className="b-logo">
															<CaptureUserImage
																upload={true}
																getImageData={(_, val) => getImageData(value.loan_co_app_enc_id, val)}
																id={value.loan_co_app_enc_id}
																image={value.image}
																details={value.loan_app_enc_id}
																openSideBar={openSideBar}
																type="Co-Applicant"
															/>

														</div>
														<div className="b-name-details mx-2">
															<h3 className="fs-4" style={{ marginTop: "10px" }}>
																{value.name}
															</h3>
															<p>
																Gender :{" "}
																{value.gender ? genderSelect[value.gender] : "-"}
															</p>
														</div>
													</div>
												</div>
											</div>

										</>
									);
								})
							: ""}

						<h5 className="mb-3 remove-mobile">Note: Only live images are allowed to be uploaded and case will only
							be disbursed if live images are uploaded.</h5>
						{loanApplicationImages && loanApplicationImages.length
							? loanApplicationImages.map((value, index) => {
								return (
									<div className="col-md-4" key={`document_type_input${index}`}>
										<div
											className="shadow-lg user-document1 p-3 mb-4"
											style={{ position: "relative", overflow: "hidden" }}
										>
											<div
												style={{
													position: "absolute",
													top: "-5px",
													right: "-5px"
												}}
											>
												{loanImages &&
												loanImages.length &&
												loanImages.find(o => o.name === value.name) ? (
														<Badge className="mx-1 fs-7" bg="success">
															<GiCheckMark /> Uploaded
														</Badge>
													) : (
														<Badge className="mx-1 fs-7" bg="warning">
															<GiSandsOfTime /> Pending
														</Badge>
													)}
											</div>
											<div className="upload-document d-flex justify-content-between align-items-center">
												<h3 className="my-2">{value.name}</h3>
												<label
													className="text-center form-control w-auto bg-primary btn-sm g-mr-15 my-4 d-md-none"
													style={{
														cursor: "pointer"
													}}
													htmlFor={`capture_image${value.product_image_enc_id}`}
												>
													<FaPlus color="#fff" fontSize={"16"} />
												</label>
												<input
													id={`capture_image${value.product_image_enc_id}`}
													type="file"
													min={1}
													accept="image/*"
													placeholder="Choose File"
													className="form-control d-none"
													capture="environment"
													onChange={e => handleFileInputChange(
														e,
														value.name,
														value.product_image_enc_id
													)}
												/>
											</div>
										</div>
									</div>
								);
							})
							: ""}
					</div>
				</>
			) : ""}
			<div className="row mb-4">
				{loanImages && loanImages.length ? (
					<>
						<div className="row">
							<div className="col-md-12">
								<h3>Uploaded Images</h3>
							</div>
						</div>
						<div className="row">
							{loanImages.map((value, index) => {
								return (
									<>
										{value.image ? (
											<div className="col-md-3" key={`images-${index}`}>
												<a
													href={value.image ? value.image : ""}
													target="_blank"
													rel="noreferrer"
													className="documentView imagesView"
												>
													<img src={value.image} alt={value.name} />
													<p>{value.name}</p>
													<hr className="w-100 my-2" />
													<div className="uploaded-by">
														<p className={"mb-2 m-0"}>
															<FaUserCheck
																fontSize={18}
																color={"#00a0e3"}
															/>{" "}
															{value.created_by}
														</p>
														<p className={"m-0"}>
															<FaClock
																fontSize={14}
																color={"#00a0e3"}
															/>{" "}
															{moment(value.created_on).format(
																"DD MMM YYYY h:mm a"
															)}
														</p>
													</div>
												</a>
											</div>
										) : (
											""
										)}
									</>
								);
							})}
						</div>
					</>
				) : (
					""
				)}
			</div>

			<Modal show={addImage} onHide={() => handleTvModalHide()} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>Capture TV Image</Modal.Title><span className="close-icon" onClick={()=>setAddImage(false)}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3 d-md-none">
						<label
							className="form-control text-center"
							style={{
								backgroundColor: "#00a0e3",
								color: "#fff",
								cursor: "pointer"
							}}
							htmlFor="captureImage"
						>
							<BsFillCameraFill /> Capture Image
						</label>
						<input
							id="captureImage"
							accept="image/*"
							placeholder="Capture Image"
							className="form-control d-none"
							type="file"
							name="images"
							capture="environment"
							onChange={e => handleFileInputChange(e,"tv_image")}
						/>
					</div>
					<p className="text-red fs-5	">Please capture the image using your mobile device, as only live photos are allowed for upload.</p>

					{previewImages.length != 0 ? (
						<div>
							<div>
								<label className="form-label" htmlFor="selected_image">
									Recently Uploaded
								</label>
							</div>
							<div>
								{previewImages.map((image, index) => (
									<img
										key={index}
										src={image}
										name="selected_image"
										className="previewImages"
										alt={`Preview ${index}`}
										style={{
											width: "100px",
											height: "100px",
											objectFit: "cover",
											margin: "0 1% 1%",
											borderRadius: "5%"
										}}
									/>
								))}
							</div>
							<div className="text-center mt-2" onClick={() => handleTvModalHide()}>
								<button type="button" className="btn btn-primary">Done</button>
							</div>
						</div>
					) : (
						""
					)}
					
				</Modal.Body>
			</Modal>
		</>
	);
};
export default FinancerImagesTab;
