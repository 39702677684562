import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonCard = ({type, widthMatch= ""}) => {
	return (
		<>
			{type === "pie_chart"?(
				 <div className="col-lg-3 col-md-3  col-sm-4 d-flex justify-content-center">
				   <div className="skeleton-card" style={{ borderRadius: "50%" }}>
					 <Skeleton circle={true} height={240} width={240} />
					 <div className="skeleton-card-bottom mt-2 d-flex justify-content-center">
							<Skeleton duration={5} height={25} width={80} />
						</div>
				 </div>
			   </div>
			)
				:type === "loan_accounts" ? (
					<div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6">
					 <div className={`skeleton-card-body-${type} shadow-lg p-2 mb-2 rounded-2 position-relative`}>
							<div className={`skeleton-card-img-${type} position-absolute`} style={{right:"20px"}}>
						 <Skeleton duration={5} height={15} width={80} />
							</div>
							<div className="skeleton-card-head">
					  		<Skeleton duration={5} height={15} width={80}/>
							</div>
						</div>
					</div>
				): (
					<div className={`${widthMatch ? "col-xl-3 col-lg-4 col-md-6 col-sm-6" : "col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6"}`}>
						<div className={`skeleton-card-body-${type} shadow-lg p-2 mb-2 rounded-2 position-relative`}>
							<div className={`skeleton-card-img-${type} position-absolute`} style={{right:"20px"}}>
							 <Skeleton duration={5} height={40} width={40} />
							</div>
							<div className="skeleton-card-head">
						  <Skeleton duration={5} height={15} width={60}/>  
							</div>
							<div className={`skeleton-card-head-${type}`}>
								<Skeleton duration={5} height={15} width={80} />
							</div>
							{type==="sm" ?(
								<div className="skeleton-loan-amounts text-end">
									<Skeleton duration={5} height={15} width={80} />
								</div>
							):
					  <>
					  <div className="skeleton-card-head">
						  <Skeleton duration={5} height={15} width={60}/>  
					  </div>
					  <div className=" text-start skeleton-card-detail position-absolute">
						 <Skeleton duration={5} height={15} width={80}/> 
						 <Skeleton duration={5} height={15} width={60}/>
					  </div>
					  <div className="text-end skeleton-card-detail">
							 <Skeleton duration={5} height={15} width={80}/>
							 <Skeleton duration={5} height={15} width={60}/>
					  </div>
					  </>
							}
						</div>
					</div>
				)}
		</>
	);
};
export default SkeletonCard;
