import React, { createContext, useReducer } from "react";
import OrganizationESignReducer from "../reducers/OrganizationESignReducer";
import axios from "axios";
import { toast } from "react-toastify";
// import authData from "../data/authData";

// Create context
export const OrganizationESignContext = createContext({});

// Provider component
export const OrganizationESignProvider = ({ children }) => {
	const [state, dispatch] = useReducer(OrganizationESignReducer, {});

	// Actions
	const getStatsData = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/lead-stats`,
				{}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_STATS",
						payload: response.data.response.stats
					});
				} else {
					dispatch({
						type: "SET_STATS",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting states Data, Please try again");
			});
	};
	// Actions
	const getLoanApplications = async filter => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/get-esign-applications`,
				{ filter }
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_APPLICATION",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_LOAN_APPLICATION",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting esign applications, Please try again");
			});
	};

	return (
		<OrganizationESignContext.Provider
			value={{
				stats: state.stats,
				loan_applications: state.loan_applications,
				getStatsData,
				getLoanApplications
			}}
		>
			{children}
		</OrganizationESignContext.Provider>
	);
};
