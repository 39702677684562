import { ReactSortable } from "react-sortablejs";
import { RiDeleteBin6Line, RiDragMove2Line } from "react-icons/ri";
import { BsPlusCircleFill } from "react-icons/bs";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LoanProductsContext } from "../../context/LoanProductsState";

const PurposeTab = ({ productId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const alphaNumericRegex = new RegExp(/^[a-zA-Z0-9]+$/);
	const [purposeList, setPurposeList] = useState([{ purpose: "", purpose_code: "" }]);
	const { getLoanProductsDetail, productPurposeData } = useContext(LoanProductsContext);
	const addNewPurpose = () => {
		setPurposeList([...purposeList, { id: purposeList.length + 1, purpose: "" }]);
	};

	const removeElement = (index, id = "") => {
		if (id) {
			if (!window.confirm("Do you really want to delete this purpose")) {
				return false;
			}
			removePurpose(id);
		}
		let items = [...purposeList];
		items.splice(index, 1);
		setPurposeList(items);
	};

	const removePurpose = async id => {
		let data = { financer_loan_product_purpose_enc_id: id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/remove-loan-product-purpose`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Purpose removed successfully");
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	const handleChange = (index, purpose, code) => {
		let items = [...purposeList];
		let item = { ...items[index] };
		item.purpose = purpose;
		item.purpose_code = code;
		if (item.purpose === "" && item.financer_loan_purpose_enc_id) {
			if (window.confirm("Do you really want to delete this document")) {
				removePurpose(item.financer_loan_purpose_enc_id);
			}
		}
		if (!item.financer_loan_purpose_enc_id) {
			item.financer_loan_purpose_enc_id = "";
		}

		item.purpose === "" ? items.splice(index, 1) : (items[index] = item);
		setPurposeList(items);
	};

	const onsubmit = async () => {
		let data = {
			financer_loan_product_enc_id: productId,
			loan_purpose: purposeList
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product-purpose`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Purpose Updated successfully");
					getLoanProductsDetail({ id: productId, type: "purpose" });
				} else {
					toast.error(
						"An error occurred " +
							(response.data.response.error
								? response.data.response.error
								: response.data.response.message)
					);
				}
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};

	const validateInputVal = (e) => {
		const inputVal = e.target.value;
		if (!alphaNumericRegex.test(inputVal)) {
			e.target.value = inputVal.substring(0, inputVal.length - 1);
		} else {
			e.target.value = inputVal.toUpperCase();
		}
	};

	const submitWithDelay = () => {
		setIsLoading(true);
		setTimeout(() => {
			onsubmit();
		}, 700);
	};

	useEffect(() => {
		getLoanProductsDetail({ id: productId, type: "purpose" });
	}, []);

	useEffect(() => {
		if (productPurposeData) {
			setPurposeList(productPurposeData);
		} else {
			setPurposeList([]);
		}
	}, [productPurposeData]);
	return (
		<>
			{/* <form onSubmit={handleSubmit(onsubmit)} className="mt-6"> */}
			<div className="proof-selection mb20 mt-6">
				<div className="mb-5">
					<label>
						Click the button to add loan purpose and drag element to adjust sequence
					</label>
				</div>
				{purposeList && purposeList.length ? (
					<>
						<ReactSortable list={purposeList} setList={setPurposeList}>
							{purposeList.map((value, index) => {
								return (
									<div
										className="documentTypehead"
										key={`div-${value.financer_loan_product_purpose_enc_id}${
											value.purpose ? `-${value.purpose}` : ""
										}`}
									>
										<RiDragMove2Line
											size={25}
											style={{ marginRight: "10px" }}
										/>
										<div className="col-4 mx-2">
											<input
												type="text"
												placeholder="Enter Purpose"
												className="form-control typeheadStyle"
												defaultValue={value.purpose ? value.purpose : ""}
												onBlur={e =>
													handleChange(index, e.target.value, value.code)
												}
											/>
										</div>
										<div className="col-2">
											<input
												type="text"
												min={1}
												placeholder="Purpose Code"
												className="form-control typeheadStyle"
												defaultValue={
													value.purpose_code ? value.purpose_code : ""
												}
												onChange={validateInputVal}
												onBlur={e =>
													handleChange(
														index,
														value.purpose,
														e.target.value
													)
												}
											/>
										</div>
										<button
											type="button"
											className="link-button"
											style={{ color: "red", marginLeft: "10px" }}
											onClick={() =>
												removeElement(
													index,
													value.financer_loan_product_purpose_enc_id
												)
											}
										>
											<RiDeleteBin6Line size={25} />
										</button>
									</div>
								);
							})}
						</ReactSortable>
					</>
				) : (
					""
				)}
				<button
					className="link-button"
					type="button"
					style={{ color: "#6c757d", marginLeft: "10px" }}
					onClick={() => addNewPurpose()}
				>
					<BsPlusCircleFill size={30} />
				</button>
			</div>
			<div className="mt-5 text-center">
				{isLoading ? (
					<button type="button" className="btn btn-primary" disabled={true}>
						<img src="/images/pages/home/loader.svg" alt="...loading" />
					</button>
				) : (
					<button
						type="submit"
						className="btn btn-primary"
						onClick={() => submitWithDelay()}
					>
						{" "}
						Save{" "}
					</button>
				)}
			</div>
			{/* </form> */}
		</>
	);
};

export default PurposeTab;
