import "../../../assets/css/formstyles.css";
import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Col, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import Compressor from "compressorjs";
import { jsPDF } from "jspdf";
import axios from "axios";
import { toast } from "react-toastify";
import { RiLoader4Line } from "react-icons/ri";

let Pagewidth;
let doc;
let Pageheight;
let pdf_uri;
let base_uri = "";

export default function Step2({ formStep, nextFormStep, setFormData, formData, defaultData }) {
	const {
		register,
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		defaultValues: {
			borrower: [{ name: "", phone: "", aadhaar: "", type: "2" }],
			guarantor: [{ name: "", phone: "", aadhaar: "", type: "3" }],
			others: [{ name: "", phone: "", aadhaar: "", type: "4" }]
		}
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: "borrower"
	});
	const {
		fields: guarantorFields,
		append: guarantorAppend,
		remove: guarantorRemove
	} = useFieldArray({ control, name: "guarantor" });

	const {
		fields: othersFields,
		append: othersAppend,
		remove: othersRemove
	} = useFieldArray({ control, name: "others" });

	const licenseAvailable = watch("license_available");
	const numberValidateRegex = /^[0-9]\d*$/;
	const [isImageUploading, setIsImageUploading] = useState(false);
	const [documentTemplates, setDocumentTemplates] = useState(false);
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const licenseValidate = /^([A-Z]{2})(\d{2}|\d{3})[a-zA-Z]{0,1}(\d{4})(\d{7})$/;
	const uploadFile = pdf => {
		setIsImageUploading(true);
		const formData = new FormData();
		formData.append("file", pdf);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}utilities/file-upload`,
				formData
			)
			.then(response => {
				setIsImageUploading(false);
				if (response.data.response.status === 200) {
					setValue("driving_license_doc", response.data.response.path);
					toast.success("Document Successfully Uploaded");
				} else {
					setValue("driving_license_doc", "");
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setIsImageUploading(false);
			});
	};
	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		// base = result_base64;
		base_uri = result_base64;
		// base_uri = base;
		return result_base64;
	};
	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};
	const callback = async (width, height, src, i) => {
		let result_base64 = await new Promise(resolve => {
			const widthRatio = Pagewidth / width;
			const heightRatio = Pageheight / height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
			const canvasWidth = width * ratio;
			const canvasHeight = height * ratio;
			const marginX = (Pagewidth - canvasWidth) / 2;
			const marginY = (Pageheight - canvasHeight) / 2;
			doc.addImage(src, "JPEG", marginX, marginY, canvasWidth, canvasHeight, i);

			pdf_uri = doc.output("blob");
			resolve(true);
		});
		return result_base64;
	};
	const createPDF = async imgData => {
		if (imgData.length === "undefined" || imgData.length === 0) {
			alert("select image");
			return false;
		}
		let result_base64 = await new Promise(resolve => {
			doc = new jsPDF("p", "pt", "a4");
			Pagewidth = doc.internal.pageSize.width;
			Pageheight = doc.internal.pageSize.height;

			const img = new Image();
			img.src = imgData;
			img.length = imgData.length;
			img.onload = function () {
				img.imgWidth = img.naturalWidth;
				img.imgHeight = img.naturalHeight;
				callback(img.imgWidth, img.imgHeight, img.src, img.i, img.length).then(() => {
					resolve(true);
				});
			};
		});
		return result_base64;
	};
	const handleFileInputChange = input => {
		if (!input.target.files) {
			return false;
		}
		const file = input.target.files[0];
		const fileExtenstion = file.name.split(".").pop().toLowerCase();
		if (fileExtenstion !== "pdf") {
			getUri(file).then(() => {
				createPDF(base_uri).then(() => {
					uploadFile(pdf_uri);
				});
			});
		} else {
			uploadFile(file);
		}
	};

	const getDocumentTemplates = async () => {
		await axios
			.get("https://services.empoweryouth.com/api/v1/leegality/get-document-templates")
			.then(response => {
				if (
					response.data.code === 200 &&
					response.data.response.data &&
					response.data.response.data.length
				) {
					setDocumentTemplates(response.data.response.data);
				} else {
					toast.error(
						"Document templates not found, please create template before proceed"
					);
				}
			});
	};
	useEffect(() => {
		if (defaultData.loan_app_enc_id) {
			setValue("full_name", defaultData.applicant_name);
			setValue("relation", defaultData.relation ? defaultData.relation : 1);
			setValue("relation_name", defaultData.relation_name ? defaultData.relation_name : "");
			setValue(
				"license_available",
				defaultData.license_available ? defaultData.license_available : ""
			);
			setValue("phone", defaultData.phone);
			setValue("age", defaultData.age);
			setValue("aadhaar", defaultData.aadhaar_number);
			setValue("address", defaultData.address);

			if (defaultData.loanCoApplicants && defaultData.loanCoApplicants.length) {
				append(
					defaultData.loanCoApplicants.filter(
						item => item.borrower_type === "Co-Borrower"
					)
				);
				remove(0);
				guarantorAppend(
					defaultData.loanCoApplicants.filter(item => item.borrower_type === "Guarantor")
				);
				guarantorRemove(0);
				othersAppend(
					defaultData.loanCoApplicants.filter(item => item.borrower_type === "Others")
				);
				othersRemove(0);
			}
		}
	}, [defaultData]);
	useEffect(() => {
		getDocumentTemplates();
		//     window.scrollTo({top: 0, behavior: 'smooth'});
	}, []);

	const onSubmit = values => {
		// console.log(values);
		setFormData(values);
		nextFormStep();
	};

	return (
		<div className={formStep === 1 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<fieldset>
					<div className="row">
						<h4>Basic Details</h4>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label" htmlFor="last_name">
									Full Name
								</label>
								<input
									placeholder="Enter Name"
									className="form-control"
									disabled={defaultData.applicant_name ? true : false}
									{...register("full_name", {
										required: "This is required."
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="full_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label" htmlFor="last_name">
									Choose Son/Daughter/Wife of and Enter Name
								</label>
								<div className="d-flex flex-wrap-xs">
									<select
										id={"relation"}
										className="form-select mb-md-0 mb-2"
										disabled={defaultData.relation ? true : false}
										{...register("relation", {
											required: "This is required."
										})}
										style={{
											maxWidth: "136px",
											paddingRight: "40px",
											paddingLeft: "15px",
											marginRight: "5px"
										}}
									>
										<option value="1">Son of</option>
										<option value="3">Daughter of</option>
										<option value="2">Wife of</option>
									</select>
									<input
										placeholder="Enter Name"
										className="form-control"
										disabled={defaultData.relation_name ? true : false}
										{...register("relation_name", {
											required: "This is required."
										})}
									/>
								</div>
								<ErrorMessage
									errors={errors}
									name="relation_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label" htmlFor="last_name">
									Phone
								</label>
								<input
									type="tel"
									placeholder="Enter Phone"
									disabled={defaultData.phone ? true : false}
									className="form-control"
									{...register("phone", {
										required: "This is required.",
										minLength: {
											value: 10,
											message: "Mobile number Should be at least 10 numbers"
										},
										maxLength: {
											value: 10,
											message: "Mobile number maximum be at least 10 numbers"
										},
										pattern: {
											value: phoneRegExp,
											message: "Invalid Phone number"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="phone"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label" htmlFor="last_name">
									Age
								</label>
								<input
									type="number"
									placeholder="Enter Age"
									className="form-control"
									disabled={defaultData.age ? true : false}
									{...register("age", {
										required: "This is required."
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="age"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label" htmlFor="last_name">
									Aadhaar Number
								</label>
								<input
									placeholder="Enter Aadhaar"
									className="form-control"
									disabled={defaultData.aadhaar ? true : false}
									{...register("aadhaar", {
										required: "This is required.",
										minLength: {
											value: 12,
											message: "Aadhaar number must be at least 12 numbers"
										},
										maxLength: {
											value: 12,
											message: "Aadhaar number must be at least 12 numbers"
										},
										pattern: {
											value: numberValidateRegex,
											message: "Aadhaar number is invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="aadhaar"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className=" form-label" htmlFor="email">
									Driving License Available
								</label>
								<div className="d-block">
									<div className="radio-wrapper">
										<div className="radio-item">
											<input
												type="radio"
												name="license_available"
												value="Yes"
												className="radioInput"
												id="optionlicense-1"
												{...register("license_available", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="optionlicense-1"
												className="option option-1"
											>
												<span>Yes</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="radio"
												name="license_available"
												value="No"
												className="radioInput"
												id="optionlicense-2"
												{...register("license_available", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="optionlicense-2"
												className="option option-2"
											>
												<span>No</span>
											</label>
										</div>
									</div>
									<ErrorMessage
										errors={errors}
										name="license_available"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						</div>
						{licenseAvailable === "Yes" ? (
							<>
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
									<div className="mb-3">
										<label className="form-label" htmlFor="last_name">
											Driving License Number
										</label>
										<input
											placeholder="Enter Driving License Number"
											className="form-control"
											disabled={
												defaultData.driving_license_number ? true : false
											}
											{...register("driving_license_number", {
												required: "This is required.",
												minLength: {
													value: 15,
													message:
														"Driving license number must be at least 15 numbers"
												},
												maxLength: {
													value: 15,
													message:
														"Driving license number must be at least 15 numbers"
												},
												pattern: {
													value: licenseValidate,
													message: "Driving license number is invalid"
												}
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="driving_license_number"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="loan_amt">
											Choose Driving License
										</label>
										<input
											type="file"
											min={1}
											accept=".png, .jpg, .jpeg, .pdf, .webp"
											placeholder="Choose File"
											className="form-control"
											{...register("temp_file", {
												required: "This is required.",
												validate: {
													// lessThan10MB: (files) => files[0]?.size < 5000000 || "Max 5MB",
													acceptedFormats: files =>
														[
															"image/jpeg",
															"image/webp",
															"image/png",
															"application/pdf"
														].includes(files[0]?.type) ||
														"Only PNG, JPEG and PDF"
												}
											})}
											onChange={handleFileInputChange}
										/>
										<input
											type="hidden"
											{...register("driving_license_doc", {
												required: "This is required."
											})}
										/>
										{isImageUploading ? (
											<p
												className="text-primary"
												style={{ fontSize: "13px" }}
											>
												<RiLoader4Line className="spin" size={14} /> Please
												wait, file is uploading.
											</p>
										) : (
											""
										)}
										<ErrorMessage
											errors={errors}
											name="temp_file"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="driving_license_doc"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
							</>
						) : (
							""
						)}
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label" htmlFor="last_name">
									Address
								</label>
								<input
									placeholder="Enter Address"
									className="form-control"
									disabled={defaultData.address ? true : false}
									{...register("address", {
										required: "This is required."
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="address"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className=" form-label" htmlFor="email">
									Agreement For
								</label>
								<div className="d-block">
									<div className="radio-wrapper">
										<div className="radio-item mb-1">
											<input
												type="radio"
												name="agreement_for"
												value="1"
												className="radioInput"
												id="optionAppFor-1"
												{...register("agreement_for", {
													required: "This is required."
												})}
												defaultChecked={formData.ltv <= 90}
											/>
											<label
												htmlFor="optionAppFor-1"
												className="option option-1"
											>
												<span>Self</span>
											</label>
										</div>
										<div className="radio-item mb-1">
											<input
												type="radio"
												name="agreement_for"
												value="2"
												className="radioInput"
												id="optionAppFor-2"
												{...register("agreement_for", {
													required: "This is required."
												})}
												defaultChecked={formData.ltv > 90}
											/>
											<label
												htmlFor="optionAppFor-2"
												className="option option-2"
											>
												<span>BC</span>
											</label>
										</div>
										<div className="radio-item mb-1">
											<input
												type="radio"
												name="agreement_for"
												value="3"
												className="radioInput"
												id="optionAppFor-3"
												{...register("agreement_for", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="optionAppFor-3"
												className="option option-3"
											>
												<span>Co Lending</span>
											</label>
										</div>
									</div>
									<ErrorMessage
										errors={errors}
										name="agreement_for"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className=" form-label" htmlFor="email">
									Document Template
								</label>
								<div className="d-block">
									<select
										id={"doc_template_id"}
										className="form-select mb-md-0 mb-2"
										{...register("doc_template_id", {
											required: "This is required."
										})}
									>
										{documentTemplates.length &&
											documentTemplates.map((value, index) => {
												return (
													<option
														key={`doc_template_${index}`}
														value={value.doc_template_id}
													>
														{value.name}
													</option>
												);
											})}
									</select>
									<ErrorMessage
										errors={errors}
										name="doc_template_id"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						</div>
						<h4>Guarantor Details</h4>
						{guarantorFields.map((item, index) => {
							return (
								<Row key={item.id}>
									<Col className="mb-1" xl="4" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Name</label>
											<input
												className="form-control"
												maxLength={20}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												type="text"
												id={`guarantor.${index}.name`}
												placeholder="Enter Name"
												{...register(`guarantor.${index}.name`)}
											/>
										</div>
									</Col>
									<Col className="mb-1" xl="4" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Phone</label>{" "}
											<input
												type="tel"
												placeholder="Enter Phone"
												id={`guarantor.${index}.phone`}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												className="form-control"
												{...register(`guarantor.${index}.phone`, {
													required: false,
													minLength: {
														value: 10,
														message:
															"Mobile number Should be at least 10 numbers"
													},
													maxLength: {
														value: 10,
														message:
															"Mobile number maximum be at least 10 numbers"
													},
													pattern: {
														value: phoneRegExp,
														message: "Invalid Phone number"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name={`guarantor.${index}.phone`}
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</Col>
									<Col className="mb-1" xl="3" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Aadhaar Number</label>{" "}
											<input
												className="form-control"
												type="text"
												id={`guarantor.${index}.aadhaar`}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												placeholder="Enter Aadhaar Number"
												{...register(`guarantor.${index}.aadhaar`, {
													required: false,
													minLength: {
														value: 12,
														message:
															"Aadhaar number must be at least 12 numbers"
													},
													maxLength: {
														value: 12,
														message:
															"Aadhaar number must be at least 12 numbers"
													},
													pattern: {
														value: numberValidateRegex,
														message: "Aadhaar number is invalid"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name={`guarantor.${index}.aadhaar`}
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</Col>
									{defaultData.loanCoApplicants &&
									defaultData.loanCoApplicants.length ? (
											<Col className="mb-1 text-center" xl="1" md="6" sm="12">
												<label className=" form-label">Signature</label>
												<input type="checkbox" className="form-check-input" />
											</Col>
										) : (
											<Col
												className="mb-1 align-items-center d-flex"
												xl="1"
												md="6"
												sm="12"
											>
												<input
													type="hidden"
													value="3"
													{...register(`guarantor.${index}.type`)}
												/>
												<button
													id="Submit"
													name="Submit"
													className="btn btn-danger btn-sm mt-3"
													onClick={() => guarantorRemove(index)}
												>
													<FaTimes />
												</button>
											</Col>
										)}
								</Row>
							);
						})}

						{defaultData.loanCoApplicants && defaultData.loanCoApplicants.length ? (
							" "
						) : (
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-3">
								<button
									className="link-button"
									onClick={() => {
										guarantorAppend({ name: "", phone: "", aadhaar: "" });
									}}
								>
									<BsPlus /> Add More
								</button>
							</div>
						)}

						<h4>Borrower Details</h4>
						{fields.map((item, index) => {
							return (
								<Row key={item.id}>
									<Col className="mb-1" xl="4" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Name</label>
											<input
												className="form-control"
												maxLength={20}
												type="text"
												id={`borrower.${index}.name`}
												placeholder="Enter Name"
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												{...register(`borrower.${index}.name`, {
													required: "This is required."
												})}
											/>
											<ErrorMessage
												errors={errors}
												name={`borrower.${index}.name`}
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</Col>
									<Col className="mb-1" xl="4" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Phone</label>{" "}
											<input
												type="tel"
												placeholder="Enter Phone"
												id={`borrower.${index}.phone`}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												className="form-control"
												{...register(`borrower.${index}.phone`, {
													required: "This is required.",
													minLength: {
														value: 10,
														message:
															"Mobile number Should be at least 10 numbers"
													},
													maxLength: {
														value: 10,
														message:
															"Mobile number maximum be at least 10 numbers"
													},
													pattern: {
														value: phoneRegExp,
														message: "Invalid Phone number"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name={`borrower.${index}.phone`}
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</Col>
									<Col className="mb-1" xl="3" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Aadhaar Number</label>{" "}
											<input
												className="form-control"
												type="text"
												id={`borrower.${index}.aadhaar`}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												placeholder="Enter Aadhaar Number"
												{...register(`borrower.${index}.aadhaar`, {
													required: "This is required.",
													minLength: {
														value: 12,
														message:
															"Aadhaar number must be at least 12 numbers"
													},
													maxLength: {
														value: 12,
														message:
															"Aadhaar number must be at least 12 numbers"
													},
													pattern: {
														value: numberValidateRegex,
														message: "Aadhaar number is invalid"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name={`borrower.${index}.aadhaar`}
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</Col>
									{defaultData.loanCoApplicants &&
									defaultData.loanCoApplicants.length ? (
											<Col className="mb-1 text-center" xl="1" md="6" sm="12">
												<label className=" form-label">Signature</label>
												<input type="checkbox" className="form-check-input" />
											</Col>
										) : (
											<Col
												className="mb-1 align-items-center d-flex"
												xl="1"
												md="6"
												sm="12"
											>
												<input
													type="hidden"
													value="2"
													{...register(`borrower.${index}.type`)}
												/>
												{index !== 0 ? (
													<button
														type="button"
														className="btn btn-danger btn-sm mt-3"
														onClick={() => remove(index)}
													>
														<FaTimes />
													</button>
												) : (
													""
												)}
											</Col>
										)}
								</Row>
							);
						})}
						{defaultData.loanCoApplicants && defaultData.loanCoApplicants.length ? (
							" "
						) : (
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
								<button
									className="link-button"
									type="button"
									onClick={() => {
										append({ name: "", phone: "", aadhaar: "" });
									}}
								>
									<BsPlus /> Add More
								</button>
							</div>
						)}

						<h4>Others</h4>
						{othersFields.map((item, index) => {
							return (
								<Row key={item.id}>
									<Col className="mb-1" xl="4" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Name</label>
											<input
												className="form-control"
												maxLength={20}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												type="text"
												id={`others.${index}.name`}
												placeholder="Enter Name"
												{...register(`others.${index}.name`)}
											/>
										</div>
									</Col>
									<Col className="mb-1" xl="4" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Phone</label>{" "}
											<input
												type="tel"
												placeholder="Enter Phone"
												id={`others.${index}.phone`}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												className="form-control"
												{...register(`others.${index}.phone`, {
													required: false,
													minLength: {
														value: 10,
														message:
															"Mobile number Should be at least 10 numbers"
													},
													maxLength: {
														value: 10,
														message:
															"Mobile number maximum be at least 10 numbers"
													},
													pattern: {
														value: phoneRegExp,
														message: "Invalid Phone number"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name={`others.${index}.phone`}
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</Col>
									<Col className="mb-1" xl="3" md="6" sm="12">
										<div className="mb-3">
											<label className=" form-label">Aadhaar Number</label>{" "}
											<input
												className="form-control"
												type="text"
												id={`others.${index}.aadhaar`}
												disabled={
													defaultData.loanCoApplicants &&
													defaultData.loanCoApplicants.length
														? true
														: false
												}
												placeholder="Enter Aadhaar Number"
												{...register(`others.${index}.aadhaar`, {
													required: false,
													minLength: {
														value: 12,
														message:
															"Aadhaar number must be at least 12 numbers"
													},
													maxLength: {
														value: 12,
														message:
															"Aadhaar number must be at least 12 numbers"
													},
													pattern: {
														value: numberValidateRegex,
														message: "Aadhaar number is invalid"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name={`others.${index}.aadhaar`}
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</Col>
									{defaultData.loanCoApplicants &&
									defaultData.loanCoApplicants.length ? (
											<Col className="mb-1 text-center" xl="1" md="6" sm="12">
												<label className=" form-label">Signature</label>
												<input type="checkbox" className="form-check-input" />
											</Col>
										) : (
											<Col
												className="mb-1 align-items-center d-flex"
												xl="1"
												md="6"
												sm="12"
											>
												<input
													type="hidden"
													value="3"
													{...register(`others.${index}.type`)}
												/>
												<button
													id="Submit"
													name="Submit"
													className="btn btn-danger btn-sm mt-3"
													onClick={() => othersRemove(index)}
												>
													<FaTimes />
												</button>
											</Col>
										)}
								</Row>
							);
						})}

						{defaultData.loanCoApplicants && defaultData.loanCoApplicants.length ? (
							" "
						) : (
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-3">
								<button
									className="link-button"
									onClick={() => {
										othersAppend({ name: "", phone: "", aadhaar: "" });
									}}
								>
									<BsPlus /> Add More
								</button>
							</div>
						)}

						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
							<div className="d-grid">
								{/* {formIsLoading ? (*/}
								{/*    <button id="Submit" name="Submit"*/}
								{/*            className="btn btn-primary ">*/}
								{/*        <img src="/images/pages/home/loader.svg"/>*/}
								{/*    </button>*/}
								{/* ) : (*/}
								{/*    <button id="Submit" name="Submit"*/}
								{/*            className="btn btn-primary ">Next</button>*/}
								{/* )}*/}
							</div>
						</div>
					</div>
				</fieldset>
				<Col size={12} className="text-center mt-2">
					<button type="submit" className="btn btn-primary">
						Next
					</button>
				</Col>
			</form>
		</div>
	);
}
