import { Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { FaMobileAlt } from "react-icons/fa";
import ResetPasswordWithNumber from "./ResetPasswordWithNumber";
import { useState } from "react";
import { getLogo } from "../helpers/customFunctions";

const ForgotPassword = ({ show, onHide, showLogin, showForgot }) => {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors }
	} = useForm();
	const [showNumberModal, setShowNumberModal] = useState(false);
	const showNumberPopup = () => {
		setShowNumberModal(true);
		onHide();
	};
	const showForgotPassword = () => {
		setShowNumberModal(false);
		showForgot();
	};
	const hidePasswordNumberModal = () => {
		setShowNumberModal(false);
	};
	const onSubmit = async data => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/forgot-password`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					onHide();
					toast.success(
						"Reset password instructions have been sent to your registered email id"
					);
				} else {
					setError("username", {
						type: "custom",
						message: "Please enter a valid username or registered email address"
					});
				}
			});
	};
	return (
		<>
			<Modal show={show} onHide={onHide} size={"lg"} centered>
				<div className="login-page-modal">
					<div className="login-box">
						<div className="login-form-box">
							<div className="logo">
								<img
									src={getLogo()}
									alt={process.env.REACT_APP_NAME}
								/>
							</div>

							<div className="login-form">
								<h1 className="mb10">Forgot Password</h1>
								<p className="font-12">
									If you&#39;ve forgotten your password, enter your registered
									e-mail address or username and we&#39;ll send you an e-mail with
									a reset password link.
								</p>
								<form className="form" onSubmit={handleSubmit(onSubmit)}>
									<div className="upper-form">
										<div className="login-username mb-3">
											<input
												placeholder="Enter Your Username or Email"
												className="form-control"
												{...register("username", {
													required: "This is required.",
													minLength: {
														value: 3,
														message:
															"Please enter a valid username or email"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name="username"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
										<div className="login-buttons submit-form">
											<button type="submit" className="submit-btn btn">
												Send Link
											</button>
										</div>
										<span className="or">OR</span>
										<div className="login-buttons social-continue">
											<button
												type="button"
												className="social-submit btn-google btn"
												onClick={showNumberPopup}
											>
												<FaMobileAlt /> Reset Password With Number
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="login-right-img">
							<div>
								<h1>Back to Login</h1>
								<div className="signup-as">
									<div className="signup-as-btns mb-3">
										<button
											onClick={() => {
												showLogin();
												onHide();
											}}
										>
											Login
										</button>
									</div>
									{/* <div className="signup-as-btns">*/}
									{/*    <button onClick={organizationShow}>Sign Up as Organization</button>*/}
									{/* </div>*/}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<ResetPasswordWithNumber
				show={showNumberModal}
				back={showForgotPassword}
				onHide={hidePasswordNumberModal}
			></ResetPasswordWithNumber>
		</>
	);
};

export default ForgotPassword;
