import axios from "axios";
import React, { useState } from "react";
import { Modal, ModalBody} from "react-bootstrap";
import { BsTelephoneOutbound } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import { toast } from "react-toastify";

const PhoneListModal= ({ show, hide, phoneData, type , loanAccNumber=null, reload}) => {
	const [phoneNumber, setPhoneNumber] = useState();
	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const submitNumber = () => {
		if (phoneNumber.length !== 10 || !phoneRegExp.test(phoneNumber)){
			toast.error("Please enter a valid phone number");
			return false;
		}
		let data = {};
		data["type"] = "phone";
		data["value"] = phoneNumber;
		data["loan_account_enc_id"] = loanAccNumber;
		
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-basic-details`, data).then(
			response => {
				if(response.data.response.status === 200){
					reload();
					hide();
					toast.success("Number Added Successfully");
				}else{
					toast.error("Some Error Occured");
				}
			}
		)
			.catch((error) => {
				toast.error(error.message);
			});
	};

	
	return (
		 <Modal show={show} onHide={hide} >
			 {/* <Modal.Header><h2>Phone Numbers</h2></Modal.Header> */}
			 <ModalBody>
				{!type ? (
					<>					
						<h3 className="mb-2">All Phone Numbers</h3><span className="close-icon" onClick={hide}><IoCloseCircle /></span>
						{phoneData && phoneData.length ? 
							phoneData.map((phone, index) => (
								<div key={index}>
									<a href={`tel:${phone}`}><BsTelephoneOutbound size={13} style={{marginRight: "3px"}}/> {phone}</a>
								</div>
							))
							: "No phone numbers to display"}
					</>
				): (
					<>
						<h3 className="mb-2">Add New Number</h3><span className="close-icon" onClick={hide}><IoCloseCircle /></span>
						<input
							type="tel"
							placeholder="Phone"
							className="form-control mb-2"
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
						<div className="text-center">
							<button type="button" className="btn btn-primary" onClick={submitNumber}>Save</button>
						</div>
					
					</>
				)}
			 </ModalBody>
		 </Modal>
		
	);
};

export default PhoneListModal;