import MinMaxFilter from "../MinMaxFilter";

const CollectionReportFilters = ({name, searchArray, setSearchArray, sortArr, setSortArr, clearFilters, type = null}) => {

	return(
		<>  
        	<div className="w-100">
				<h3 className="text-center">{name}</h3>
				<div className="d-flex justify-content-around fix-collection-w">
					<div>
						<MinMaxFilter
							title={"Total Cases"}
							id={type !== "total" ? `${type}_total_cases_count` : "total_cases_count"}
							placeholder="Total Cases"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<MinMaxFilter
							title={"Collection in Cases"}
							id={`${type}_collected_cases_count`}
							placeholder="Collection in Cases"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<MinMaxFilter
							title={"EMI Demand"}
							id={`${type}_emi_demand`}
							placeholder="EMI Demand"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<MinMaxFilter
							title={"Target Amount"}
							id={`${type}_target_amount`}
							placeholder="Target Amount"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<MinMaxFilter
							title={"Verified Amount"}
							id={`${type}_collected_verified_amount`}
							placeholder="Verified Amount"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<MinMaxFilter
							title={"Unverified Amount"}
							id={`${type}_collected_unverified_amount`}
							placeholder="Unverified Amount"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>
					
				</div>
			</div>
		</>
	);
};

export default CollectionReportFilters;