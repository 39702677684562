import { Route, Routes, useNavigate } from "react-router-dom";
import ProtectedRoutes from "./helpers/ProtectedRoutes";
import { authServices } from "./helpers/authServices";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MainContext } from "./context/MainState";
import ApplyLoanPartial from "./components/ApplyLoanPartial";
import AuthRoutes from "./helpers/AuthRoutes";
import OrganizationSignUp from "./components/OrganizationSignUp";
import ChangePasswordModal from "./components/ChangePasswordModal";
import DynamicManifest from "./components/DynamicManifest";
import DealerSignUp from "./components/DealerSignUp";
import Loader from "./components/Loader";
import PageLoader from "./components/PageLoader";
import Pharmacy from "./pages/Pharmacy";
import PharmacyDetail from "./pages/PharmacyDetail";
import Cart from "./pages/Cart";
import moment from "moment";
import SharingIdCard from "./components/dashboard/SharingIdCard";
import { isCredifinUser } from "./helpers/customFunctions";
import Redirect from "./pages/redirecting/Redirect";
import EmiCalculator from "./components/EmiCalculator";
import IndividualLoanAccount from "./pages/IndividualLoanAccount";
import PrReceipt from "./pages/PrReceipt";
import { toast } from "react-toastify";

const NotFound = React.lazy(() => import("./pages/NotFound"));
const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const HomeLoan = React.lazy(() => import("./pages/HomeLoan"));
const PersonalLoan = React.lazy(() => import("./pages/PersonalLoan"));
const BusinessLoan = React.lazy(() => import("./pages/BusinessLoan"));
const EducationLoan = React.lazy(() => import("./pages/EducationLoan"));
const SchoolFeeFinance = React.lazy(() => import("./pages/SchoolFeeFinance"));
const MicroLoan = React.lazy(() => import("./pages/MicroLoan"));
const ApplyLoan = React.lazy(() => import("./pages/ApplyLoan"));
const LapLoan = React.lazy(() => import("./pages/LapLoan"));
const CarLoan = React.lazy(() => import("./pages/CarLoan"));
const TwoWheelerLoan = React.lazy(() => import("./pages/TwoWheelerLoan"));
const EVehicleLoan = React.lazy(() => import("./pages/EVehicleLoan"));
const EducationLoanApply = React.lazy(() => import("./pages/EducationLoanApply"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const TermsCondition = React.lazy(() => import("./pages/TermsCondition"));
const PaymentConfirmation = React.lazy(() => import("./pages/PaymentConfirmation"));
const EPartners = React.lazy(() => import("./pages/EPartners"));
const GoDaddyAcademy = React.lazy(() => import("./pages/GoDaddyAcademy"));
const NewCarLoan = React.lazy(() => import("./pages/car-loan/NewCarLoan"));
const AnnualFeeFinance = React.lazy(() => import("./pages/AnnualFeeFinance"));
const InterestFreeLoan = React.lazy(() => import("./pages/InterestFreeLoan"));
const LoanAgainstCar = React.lazy(() => import("./pages/car-loan/LoanAgainstCar"));
const UsedCarLoan = React.lazy(() => import("./pages/car-loan/UsedCarLoan"));
const RefinanceLoan = React.lazy(() => import("./pages/RefinanceLoan"));
const TeacherLoan = React.lazy(() => import("./pages/TeacherLoan"));
const AbroadLoan = React.lazy(() => import("./pages/AbroadLoan"));
const MedicalLoan = React.lazy(() => import("./pages/MedicalLoan"));
const HospiShield = React.lazy(() => import("./pages/HospiShield"));
const BlogDetail = React.lazy(() => import("./pages/BlogDetail"));
const DemoForm = React.lazy(() => import("./pages/DemoForm"));
const SolarPanelLoan = React.lazy(() => import("./pages/SolarPanelLoan"));
const MarriageLoan = React.lazy(() => import("./pages/MarriageLoan"));
const MedicalLoanApply = React.lazy(() => import("./pages/MedicalLoanApply"));
const ConsumerDurableLoan = React.lazy(() => import("./pages/ConsumerDurableLoan"));
const ProMembership = React.lazy(() => import("./pages/ProMembership"));
const CareNowPayLater = React.lazy(() => import("./pages/CareNowPayLater"));
const Nbfcs = React.lazy(() => import("./pages/Nbfcs"));
const ProDetails = React.lazy(() => import("./pages/ProDetails"));
const DiwaliDhmaka = React.lazy(() => import("./pages/DiwaliDhmaka"));
// const FinancerLanding = React.lazy(() => import("./pages/FinancerLanding"));
const InsuranceAndFinance = React.lazy(() => import("./pages/InsuranceAndFinance"));
const BuyUsedMobile = React.lazy(() => import("./pages/BuyUsedMobile"));
const ReEmpower = React.lazy(() => import("./pages/ReEmpower"));
const ReEmpowerList = React.lazy(() => import("./pages/ReEmpowerList"));
const ReEmpowerDetail = React.lazy(() => import("./pages/ReEmpowerDetail"));
const PhfLeasing = React.lazy(() => import("./pages/PhfLeasing"));
// const LoanStats = React.lazy(() => import("./pages/LoanStats"));

function Main() {
	const history = useNavigate();
	const [show, setShow] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [manifestLocation, setManifestLocation] = useState("");
	const {
		user,
		orgSignUp,
		dealerSignUp,
		orgSignUpHandler,
		pageLoaded,
		dealerSignUpHandler,
		applyModal,
		updateUser,
		resetPasswordModal,
		resetPasswordHandler,
		handleApplyModal
	} = useContext(MainContext);
	const preventClose = () => {
		return false;
	};
	useEffect(() => {
		let currentUrl = window.location.pathname.split("/");
		let data = { app_id: currentUrl[3] };
		if (currentUrl[2] == "app") {
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organization-apps/detail`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						setManifestLocation(response.data.response.detail);
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	}, []);

	useEffect(() => {
		async function fetchData() {
			if (
				localStorage.getItem("app_applied_for") &&
				localStorage.getItem("loan_app_enc_id")
			) {
				handleApplyModal(true);
			}
			if (user) {
				if (user.user_type !== "Individual" && window.location.pathname === "/") {
					history("/account/dashboard");
				}
				try {
					await authServices.findUser(updateUser).then(response => {
						setPageLoading(false);
						if (response) {
							if (response.weak_password && !localStorage.getItem("loan_app_enc_id")) {
								resetPasswordHandler(true);
							}
							if(response?.status === 308 && response.access_token && response.auto_login_token) {
								history(`/redirect/${response.access_token}/${response.auto_login_token}`);
							}
						}
					});
				} catch (error) {
					toast.error("Token expired, please log in.");
					authServices.logout();
				}
			} else {
				setPageLoading(false);
			}
			if (localStorage.getItem("last_version")) {
				// start time and end time
				const startTime = moment(localStorage.getItem("last_version"));
				const endTime = moment();

				const duration = moment.duration(endTime.diff(startTime));

				const hours = parseInt(duration.asHours());
				if (hours >= 7) {
					if ("caches" in window) {
						caches.keys().then(names => {
							names.forEach(async name => {
								await caches.delete(name);
							});
						});
					}
					localStorage.setItem("last_version", moment().format("YYYY-MM-DD H:mm:s"));
				}
			} else {
				localStorage.setItem("last_version", moment().format("YYYY-MM-DD H:mm:s"));
			}
		}

		fetchData();
	}, []);

	useEffect(() => {
		setShow(applyModal);
	}, [applyModal]);

	// ** Request Interceptor
	axios.interceptors.request.use(
		config => {
			// ** Get token from localStorage
			const accessToken = localStorage.user
				? JSON.parse(localStorage.user).access_token
				: null;
			const source = localStorage.user ? JSON.parse(localStorage.user).source : null;

			// ** If token is present add it to request's Authorization Header
			if (accessToken && config && config.headers) {
				// ** eslint-disable-next-line no-param-reassign

				config.headers.Authorization = `Bearer ${accessToken}`;
				config.headers.source = `${source}`;
			}
			return config;
		},
		error => Promise.reject(error)
	);

	// ** Add request/response interceptor
	axios.interceptors.response.use(
		response => response,
		error => {
			// ** const { config, response: { status } } = error
			const { response } = error;

			// ** if (status === 401) {
			if (response && response.status === 401) {
				authServices.logout();
			}

			return Promise.reject(error);
		}
	);

	return (
		<>
			{pageLoaded && <Loader />}
			<DynamicManifest path={manifestLocation} />
			<OrganizationSignUp show={orgSignUp} onHide={orgSignUpHandler} />
			<DealerSignUp show={dealerSignUp} onHide={dealerSignUpHandler} />
			<ApplyLoanPartial show={show} onHide={preventClose} />
			<ChangePasswordModal show={resetPasswordModal} onHide={resetPasswordHandler} />
			{pageLoading ? <PageLoader /> : <React.Suspense fallback={<PageLoader />}>
				<Routes>
					{!isCredifinUser() ? (
						<>
							<Route exact path="/" element={<Home />} />
							<Route path="/car-loan" element={<CarLoan />} />
							<Route path="/apply-demo" element={<DemoForm />} />
							<Route path="/medical-loan" element={<MedicalLoan />} />
							<Route path="/care-now-pay-later" element={<CareNowPayLater />} />
							<Route path="/blog/:id" element={<BlogDetail />} />
							<Route path="/hospi-shield" element={<HospiShield />} />
							<Route path="/new-car-loan" element={<NewCarLoan />} />
							<Route path="/consumer-durable-loan" element={<ConsumerDurableLoan />} />
							<Route path="/consumer-durable-loan/apply" element={<ApplyLoan />} />
							<Route path="/used-car-loan" element={<UsedCarLoan />} />
							<Route path="/loan-against-car" element={<LoanAgainstCar />} />
							<Route path="/godaddy-academy" element={<GoDaddyAcademy />} />
							<Route path="/E-partners" element={<EPartners />} />
							<Route path="/two-wheeler-loan" element={<TwoWheelerLoan />} />
							<Route path="/e-vehicle-loan" element={<EVehicleLoan />} />
							<Route path="/home-loan" element={<HomeLoan />} />
							<Route path="/personal-loan" element={<PersonalLoan />} />
							<Route path="/business-loan" element={<BusinessLoan />} />
							<Route path="/education-loan" element={<EducationLoan />} />
							<Route path="/school-fee-finance" element={<SchoolFeeFinance />} />
							<Route path="/annual-fee-finance" element={<AnnualFeeFinance />} />
							<Route path="/interest-free-loan" element={<InterestFreeLoan />} />
							<Route path="/refinance-education-loan" element={<RefinanceLoan />} />
							<Route path="/teacher-loan" element={<TeacherLoan />} />
							<Route path="/study-abroad-loan" element={<AbroadLoan />} />
							<Route path="/micro-loan" element={<MicroLoan />} />
							<Route path="/loan-against-property" element={<LapLoan />} />
							<Route path="/solar-panel-loan" element={<SolarPanelLoan />} />
							<Route path="/solar-panel-loan/apply" element={<ApplyLoan />} />
							<Route path="/marriage-loan" element={<MarriageLoan />} />
							<Route path="/marriage-loan/apply" element={<ApplyLoan />} />
							<Route path="/privacy-policy" element={<PrivacyPolicy />} />
							<Route path="/terms-condition" element={<TermsCondition />} />
							<Route path="/diwali-dhmaka" element={<DiwaliDhmaka />} />
							{/* <Route path="/mudra-loan" element={<MudraLoan/>}/>*/}
							<Route path="/about-us" element={<About />} />
							<Route path="/contact-us" element={<Contact />} />
							<Route path="/apply" element={<ApplyLoan />} />
							<Route path="/personal-loan/apply" element={<ApplyLoan />} />
							<Route path="/home-loan/apply" element={<ApplyLoan />} />
							<Route path="/school-fee-finance/apply" element={<EducationLoanApply />} />
							<Route path="/business-loan/apply" element={<ApplyLoan />} />
							<Route path="/medical-loan/apply" element={<MedicalLoanApply />} />
							<Route path="/hospi-shield/apply" element={<ApplyLoan />} />
							<Route path="/two-wheeler-loan/apply" element={<ApplyLoan />} />
							<Route path="/battery-loan/apply" element={<ApplyLoan />} />
							<Route path="/car-loan/apply" element={<ApplyLoan />} />
							<Route path="/e-vehicle-loan/apply" element={<ApplyLoan />} />
							<Route path="/e-car-loan/apply" element={<ApplyLoan />} />
							<Route path="/e-two-wheeler-loan/apply" element={<ApplyLoan />} />
							<Route path="/e-rickshaw-loan/apply" element={<ApplyLoan />} />
							<Route path="/new-car-loan/apply" element={<ApplyLoan />} />
							<Route path="/used-car-loan/apply" element={<ApplyLoan />} />
							<Route path="/loan-against-car/apply" element={<ApplyLoan />} />
							<Route path="/loan-against-property/apply" element={<ApplyLoan />} />
							<Route path="/education-loan/apply" element={<ApplyLoan />} />
							<Route path="/micro-loan/apply" element={<ApplyLoan />} />
							<Route path="/payment/transaction" element={<PaymentConfirmation />} />
							<Route path="/re-empower" element={<ReEmpower />} />
							<Route path="/re-empower-mobiles" element={<BuyUsedMobile />} />
							<Route
								path="/re-empower/vehicles"
								element={<ReEmpowerList type={"Two Wheeler"} />}
							/>
							<Route
								path="/re-empower/mobiles"
								element={<ReEmpowerList type={"Mobiles"} />}
							/>
							<Route path="/vehicle/:id" element={<ReEmpowerDetail type={"Two Wheeler"} />} />
							<Route path="/mobile/:id" element={<ReEmpowerDetail type={"Mobiles"} />} />
							<Route path="/pro" element={<ProMembership />} />
							<Route path="/pro/:id" element={<ProDetails />} />
							<Route path="/not-found" element={<NotFound />} />
							<Route path="/nbfcs" element={<Nbfcs />} />
							<Route path="/phf" element={<PhfLeasing />} />
							<Route path="/pages/YCw7E" element={<PhfLeasing />} />
							<Route path="/pages/7IYGv" element={<PhfLeasing />} />
							<Route path="/pages/8qvsg" element={<PhfLeasing />} />
							<Route path="/pages/9e7Pj" element={<PhfLeasing />} />
							<Route path="/insurance-and-finance" element={<InsuranceAndFinance />} />
							<Route path="/buy-medicines" element={<Pharmacy />} />
							<Route path="/buy-medicines-details" element={<PharmacyDetail />} />
							<Route path="/cart" element={<Cart />} />
							<Route path="/buy-used-mobile" element={<BuyUsedMobile />} />
							<Route path="/emiCalculator" element={<EmiCalculator />} />
						</>
					) : ""}
					<Route path="/loan-account/:id" element={<IndividualLoanAccount/>} />
				 	<Route path="/pr-receipt/:id" element={<PrReceipt/>} />
				 	<Route path="/r/:token" element={<Redirect type="general" />} />
					<Route path="account/*" element={<ProtectedRoutes />} />
					<Route path="*" element={<AuthRoutes />} />
					{/* <Route path="/:slug" element={<FinancerLanding />} /> */}
					<Route path="/employee/:username" element={<SharingIdCard />} />
					<Route path="/redirect/:token/:login" element={<Redirect type="redirect" />} />
					<Route path="/auto-login/:token/:login" element={<Redirect type="login" />} />
					{/* <Route path="/loan-stats" element={<LoanStats />} /> */}
				</Routes>
			</React.Suspense>}
		</>
	);
}

export default Main;
