import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import VehiclesDataList from "../../components/dashboard/VehiclesDataList";
import { Helmet } from "react-helmet";
import { ReEmpowerProvider } from "../../context/ReEmpowerState";

const VehicleList = () => {
	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME}</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<ReEmpowerProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className="shadow-lg rounded-3 py-4 px-6">
							<div className={"d-flex justify-content-between mb-3"}>
								<h2 className="mb-0">Vehicles List</h2>
								<div className="dashboard-sub-action d-flex">
									<Link
										to="/account/re-empower/add-vehicle"
										className="btn btn-primary btn-sm mx-1"
									>
										Add Vehicle
									</Link>
								</div>
							</div>
							<VehiclesDataList category={"Two Wheeler"} />
						</div>
					</div>
				</div>
			</ReEmpowerProvider>
		</>
	);
};
export default VehicleList;
