import React, { createContext, useReducer, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ReEmpowerReducer from "../reducers/ReEmpowerReducer";

// Create context
export const ReEmpowerContext = createContext({});

// Provider component
export const ReEmpowerProvider = ({ children }) => {
	const [state, dispatch] = useReducer(ReEmpowerReducer, {});
	const [isLoading, setIsLoading] = useState(false);
	// Actions
	const getVehicles = async (filters = {}) => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/get-products`,
				filters
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_VEHICLES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_VEHICLES",
						payload: []
					});
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const updateStatus = async (user_id, status) => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/change-status`,
				{
					user_id,
					status
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Data Updated");
					getVehicles();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while changing status, Please try again");
			});
	};

	const handleLoading = type => {
		setIsLoading(type);
	};
	const deleteApp = async app_id => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organization-apps/remove-app`,
				{
					app_id
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("App Removed Successfully");
					getVehicles();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while removing application, Please try again");
			});
	};

	return (
		<ReEmpowerContext.Provider
			value={{
				vehicles: state.vehicles,
				getVehicles,
				deleteApp,
				updateStatus,
				isLoading,
				handleLoading
			}}
		>
			{children}
		</ReEmpowerContext.Provider>
	);
};
