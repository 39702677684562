const OrganizationUsersReducer = (state, action) => {
	switch (action.type) {
	case "SET_STATS":
		return {
			...state,
			stats: action.payload
		};
	case "SET_EMPLOYEE_LIST_DATA":
		return {
			...state,
			users: action.payload
		};
	case "SET_DSA_DATA":
		return {
			...state,
			dsa: action.payload
		};
	case "SET_CONNECTOR_DATA":
		return {
			...state,
			connector: action.payload
		};
	case "SET_DEALER":
		return {
			...state,
			dealer: action.payload
		};
	case "SET_DELETED_DATA":
		return {
			...state,
			deleted: action.payload
		};
	case "REMOVE_USER_DATA":
		return {
			...state,
			user: ""
		};
	case "SET_EMPLOYEE_SUM_DATA":
		return {
			...state,
			employeeData:{...state.employeeData, sum:action.payload}
		};
	case "SET_EMPLOYEE_SALES_COUNT_DATA":
		return {
			...state,
			employeeData:{...state.employeeData, count:action.payload}
		};
	case "SET_STATES_SUM_DATA":
		return {
			...state,
			employeeData:{...state.employeeData, state_sum:action.payload}
		};
	case "SET_STATES_COUNT_DATA":
		return {
			...state,
			employeeData:{...state.employeeData, state_count:action.payload}
		};
		
	// case "SET_EMPLOYEE_DATA_BY_AMOUNT":
	// 	return {
	// 		...state,
	// 		employeeDataByAmount: action.payload
	// 	};
	case "SET_DEALER_DATA":
		return {
			...state,
			dealerData: action.payload
		};
	case "SET_DEALER_TOTAL_STATS":
		return {
			...state,
			dealerAllStats: action.payload
		};
	case "SET_INACTIVE_EMPLOYEE":
		return {
			...state,
			inactiveEmployee: action.payload
		};
	case "SET_INCENTIVE_POINTS":
		return {
			...state,
			incentivePoints: action.payload
		};
	case "SET_BRANCHES_REPORT_AMOUNTS":
		return {
			...state,
			branchesReport: { ...state.branchesReport, by_amount: action.payload }
		};
	case "SET_BRANCHES_REPORT_COUNT":
		return {
			...state,
			branchesReport: { ...state.branchesReport, by_cases: action.payload }
		};
	case "SET_STATES_REPORT_AMOUNTS":
		return {
			...state,
			statesReport: { ...state.statesReport, by_amount: action.payload }
		};
	case "SET_STATES_REPORT_COUNT":
		return {
			...state,
			statesReport: { ...state.statesReport, by_cases: action.payload }
		};
	default:
		return state;
	}
};
export default OrganizationUsersReducer;
