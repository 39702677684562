import React, { useContext, useEffect, useState } from "react";
import CollectNow from "./CollectNow";
import "../assets/css/paymentDetails.css";
import { MainContext } from "../context/MainState";
import { IoWalletOutline } from "react-icons/io5";

function PaymentDetails({ loanDetails, feeStructures, reload, applicationBorrower }) {
	const { getAssignedLoanProducts, loan_products } = useContext(MainContext);

	const [collectNow, setCollectNow] = useState(false);

	const handleCollectNow = () => {
		setCollectNow(true);
	};
	useEffect(() => {
		if (loanDetails && !loan_products) {
			getAssignedLoanProducts();
		}
	}, [loanDetails]);

	return (
		<>
			<div>
				{loanDetails && feeStructures.length ? (
					<div className="mx-1">
						<button className="btn btn-primary" onClick={handleCollectNow}>
							<IoWalletOutline /> Collect{" "}
							{!loanDetails.assignedLoanPayments ||
							loanDetails.assignedLoanPayments.filter(
								loan => loan.payment_status === "captured"
							).length === 0
								? "Login "
								: ""}
							Fee
						</button>
					</div>
				) : null}
			</div>
			<CollectNow
				show={collectNow}
				loanDetails={loanDetails}
				applicationBorrower={applicationBorrower}
				reload={reload}
				onHide={() => setCollectNow(false)}
			/>
		</>
	);
}

export default PaymentDetails;
