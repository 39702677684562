import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import 'bootstrap/dist/css/bootstrap.css';
// import './index.css';
import "./assets/css/theme.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import {BrowserRouter} from "react-router-dom";

// ReactDOM.render(<App/>, document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
