const ReportsReducer = (state, action) => {
	switch (action.type) {
	case "SET_COLLECTION_STATS":
		return {
			...state,
			collectionReports: action.payload
		};
	case "SET_COLLECTION_STATS_BRANCHES":
		return {
			...state,
			collectionReportBranches: action.payload
		};
	case "SET_COLLECTION_STATS_STATES":
		return {
			...state,
			collectionReportStates: action.payload
		};
	case "SET_DAILY_COLLECTION_STATS":
		return {
			...state,
			dailyCollectionReports: action.payload
		};
	case "SET_DAILY_COLLECTION_STATS_BRANCHES":
		return {
			...state,
			dailyReportBranches: action.payload
		};
	case "SET_DAILY_COLLECTION_LOAN_TYPES":
		return {
			...state,
			dailyReportLoanTypes: action.payload
		};
	case "SET_RC_PENDING_CASES":
		return {
			...state,
			rcPendingApplications: action.payload
		};
	default:
		return state;
	}
};
export default ReportsReducer;
