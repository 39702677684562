import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { MainContext } from "../../context/MainState";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { BiRupee } from "react-icons/bi";
import moment from "moment";

const LoanProductReportTable = () => {
	const {
		getLoanProductsReport,
		loanProductReport,
		isLoading,
		branches_list,
		getBranches
	} = useContext(OrganizationDashboardContext);
	const { getAssignedLoanTypes, loan_types, user } = useContext(MainContext);
	const [branchesOptions, setBranchesOptions] = useState([]);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		loan_type: "",
		field: "",
		order_by: ""
	});
	const [selectedBranch, setSelectedBranch] = useState({
		value: "",
		label: "All"
	});
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "1",
		label: "This Month"
	});
	const columns = [
		{
			name: "Product Name",
			minWidth: "180px",
			selector: row => (row.product_name ? row.product_name : "-"),
			sortable: false
		},
		{
			name: "Total Cases",
			minWidth: "120px",
			selector: row => (row.total_cases ? row.total_cases : "-"),
			sortable: true,
		},
		{
			name: "Total Amount",
			selector: row => (row.total_amount ? row.total_amount : "-"),
			minWidth: "120px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						<BiRupee size={16} />{" "}
						{row.total_amount ? parseFloat(row.total_amount).toLocaleString("en-IN") : "-"}
					</h6>
				);
			}
		},
		{
			name: "New Lead",
			minWidth: "120px",
			selector: row => (row.new_lead ? row.new_lead : "-"),
			sortable: true,
		},
		{
			name: "Sanctioned",
			minWidth: "120px",
			selector: row => (row.sanctioned ? row.sanctioned : "-"),
			sortable: false
		},
		{
			name: "Sanctioned Amount",
			selector: row => (row.sanctioned_amount ? row.sanctioned_amount : "-"),
			minWidth: "120px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						<BiRupee size={16} />{" "}
						{row.sanctioned_amount ? parseFloat(row.sanctioned_amount).toLocaleString("en-IN") : "-"}
					</h6>
				);
			}
		},
		{
			name: "Disbursed",
			minWidth: "120px",
			selector: row => (row.disbursed ? row.disbursed : "-"),
			sortable: false
		},
		{
			name: "Disbursed Amount",
			selector: row => (row.disbursed_amount ? row.disbursed_amount : "-"),
			minWidth: "120px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						<BiRupee size={16} />{" "}
						{row.disbursed_amount ? parseFloat(row.disbursed_amount).toLocaleString("en-IN") : "-"}
					</h6>
				);
			}
		},
		{
			name: "Rejected",
			minWidth: "120px",
			selector: row => (row.rejected ? row.rejected : "-"),
			sortable: false
		},
		{
			name: "Rejected Amount",
			selector: row => (row.rejected_amount ? row.rejected_amount : "-"),
			minWidth: "120px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						<BiRupee size={16} />{" "}
						{row.rejected_amount ? parseFloat(row.rejected_amount).toLocaleString("en-IN") : "-"}
					</h6>
				);
			}
		},
	];


	// ** Table data to render
	const dataToRender = () => {
		if (
			loanProductReport &&
			loanProductReport.data &&
			loanProductReport.data.length > 0
		) {
			return loanProductReport.data;
		} else if (
			loanProductReport &&
			loanProductReport.data &&
			loanProductReport.data.length === 0
		) {
			return [];
		}
	};
	const handleSort = async (column, sortDirection) => {
		setFilters({
			...filters,
			field: column.id,
			order_by: sortDirection === "asc" ? 0 : 1
		});
	};

	const getCurrentMonthData = () => {
		const tempDate = new Date();
		const fromDate = moment(
			tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-1"
		).format("YYYY-MM-DD");
		const toDate = moment(tempDate).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const getTodayData = () => {
		const formattedToday = moment().format("YYYY-MM-DD");
		setFilters({
			...filters,
			start_date: formattedToday,
			end_date: formattedToday
		});
	};

	const getThisWeekData = () => {
		const today = new Date();
		const dayOfWeek = today.getDay();
		const startOfWeek = new Date(today);

		// Calculate the number of days to subtract to get to Monday
		const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
		startOfWeek.setDate(today.getDate() - daysToSubtract);

		const formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
		const formattedToday = today.toISOString().split("T")[0];

		setFilters({
			...filters,
			start_date: formattedStartOfWeek,
			end_date: formattedToday
		});
	};
	const getPrevMonthData = () => {
		const tempDate = new Date();
		const lastDay = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
		const fromDate = moment((tempDate.getMonth() === 0 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) + "-" + (tempDate.getMonth() === 0 ? 12 : tempDate.getMonth()) + "-1").format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const getThreeMonthData = () => {
		const tempDate = new Date();
		const startingMonth = (tempDate.getMonth() - 3 + 12) % 12;
		const lastDay = new Date((tempDate.getMonth() < 3 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()), startingMonth + 3, 0);
		const fromDate = moment((tempDate.getMonth() <= 2 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) + "-" + (startingMonth + 1) + "-1").format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};

	useEffect(() => {
		if (selectedStatusModel.value === "1") {
			getCurrentMonthData();
		} else if (selectedStatusModel.value === "0") {
			getPrevMonthData();
		} else if (selectedStatusModel.value === "2") {
			getThreeMonthData();
		} else if (selectedStatusModel.value === "3") {
			getTodayData();
		} else if (selectedStatusModel.value === "4") {
			getThisWeekData();
		}
	}, [selectedStatusModel]);

	useEffect(() => {
		if (!loan_types) {
			if (!branches_list) {
				getBranches();
			}
			getAssignedLoanTypes();
		}
	}, []);

	useEffect(() => {
		if (branches_list && branches_list.length) {
			const tempBranchesOptions = branches_list.map(value => ({
				value: value.id,
				label: value.value
			}));
			setBranchesOptions(tempBranchesOptions);
		}
	}, [branches_list]);

	const handleBranchChange = data => {
		if (data.value !== selectedBranch.value) {
			setSelectedBranch(data);
			setFilters({ ...filters, branch_name: data.value });
		}
	};

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			getLoanProductsReport({
				start_date: filters.start_date,
				end_date: filters.end_date,
				field: filters.field,
				order_by: filters.order_by,
				branch_name: filters.branch_name,
				provider_id: user.organization_enc_id,
			});
		}
	}, [filters]);

	return (
		<div className="loan-application-table mb-5">
			<Row className="mx-0 mt-1 mb-50">
				<Col sm="9">
					<div className="d-flex align-items-center py-2">
						<div className="form-group">
							<label htmlFor="float-text" className="floating-label fw400 fs-5">
								Branch
							</label>
							<Select
								options={[{ value: "", label: "All" }, ...branchesOptions]}
								className="dashboard-stats-design smSelect m-0 fs-5 w-100"
								value={selectedBranch}
								isSearchable={false}
								name="status"
								placeholder="Choose Branch"
								onChange={e => handleBranchChange(e)}
							/>
						</div>
						<div style={{display: "flex",alignItems: "center"}}>
							<div className="form-group">
								<label htmlFor="float-text" className="floating-label fw400 ft14">
									Range
								</label>
								<Select
									options={[
										{
											value: "1",
											label: "This Month"
										},
										{ value: "3", label: "Today" },
										{ value: "4", label: "This Week" },
										{
											value: "0",
											label: "Last Month"
										},
										{
											value: "2",
											label: "Last Three Months"
										},
										{
											value: "Custom",
											label: "Custom Range"
										}
									]}
									className="customStyleSelect smSelect smOption"
									value={selectedStatusModel}
									name="status"
									isSearchable={false}
									placeholder="Choose Status"
									onChange={option => {
										if (option.value !== selectedStatusModel.value) {
											setSelectedStatusModel(option);
										}
									}}
								/>
							</div>
						</div>
						{selectedStatusModel && selectedStatusModel.value === "Custom" ? (
							<>
								<div className="form-group">
									<label
										htmlFor="float-text"
										className="floating-label fw400 ft14"
									>
										From
									</label>
									<input
										type="date"
										className="form-control"
										style={{ maxWidth: "150px", height: "48px" }}
										value={filters.start_date}
										onChange={e => {
											setFilters({ ...filters, start_date: e.target.value });
										}}
									/>
								</div>
								<div className="form-group">
									<label
										htmlFor="float-text"
										className="floating-label fw400 ft14"
									>
										To
									</label>
									<input
										type="date"
										className="form-control"
										style={{ maxWidth: "150px", height: "48px" }}
										value={filters.end_date}
										onChange={e => {
											setFilters({ ...filters, end_date: e.target.value });
										}}
									/>
								</div>
							</>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				paginationServer
				columns={columns}
				data={dataToRender()}
				sortServer={true}
				onSort={handleSort}
				progressPending={isLoading === "employeeStat"}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
			/>

		</div>
	);
};
export default LoanProductReportTable;
