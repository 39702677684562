import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import RecoveryLoanAccountTable from "../../components/dashboard/RecoveryLoanAccountTable";
import { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainState";
import { useNavigate } from "react-router";

const RecoveryAccounts = () => {
	const { user } = useContext(MainContext);
	const history = useNavigate();
	useEffect(() => {
		if (!((user.user_type === "Financer" || user.username === "tandon") && (user.organization_username === "phfleasing" || process.env.REACT_APP_TYPE === "DEV"))) {
			history("/account/dashboard");
		}
	}, []);
	return (
		<>
			<Helmet>
				<title>Recovery Loan Accounts</title>
				<meta name="description" content="Recovery Accounts" />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<RecoveryLoanAccountTable />
				</div>
			</div>
			<Footer />
		</>
	);
};

export default RecoveryAccounts;
