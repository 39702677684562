import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { FaEdit, FaTimes } from "react-icons/fa";
import { MainContext } from "../../context/MainState";
import { RiFlag2Fill } from "react-icons/ri";
import { readOnlyUsers } from "../../helpers/authServices";

const EditRadioInput = ({
	defaultValue,
	optionList,
	loanDetailStatus = null,
	id,
	api,
	required = false,
	parentId = "",
	reload,
	type,
	inheritedCondition = true
}) => {
	const {user} = useContext(MainContext);
	const [statusToggle, setStatusToggle] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const [label, setLabel] = useState("");
	
	const handleSave = (value) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let data = { id: id, value: value, parent_id: parentId, type: type };
		if (required && value === "") {
			toast.error("Please Enter Value");
			return false;
		}
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				data
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					reload(id, value);
					setStatusToggle(false);
					toast.success("Successfully updated");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
				toast.error("An error has occurred, Please try again");
			});
	};

	const colorMapping = {
		2: "green",
		3: "yellow",
		4: "red",
	  };

	useEffect(() => {
		const selectedOption = optionList.find(option => option.value === defaultValue);
		if (selectedOption) {
		  setLabel(selectedOption.label);
		}
	  }, [defaultValue, optionList, reload]);

	return (
		<>
			{inheritedCondition && (statusToggle && showCurrent === id || type === "priority") ? (
				<>
					{!loading ? (
						<>
							<div className="radio-wrapper">
								{optionList.length ? optionList.map((value, index) => {
									const flagColor = colorMapping[value.value];
									return (
										<div className="radio-item" key={`radio-list-${index}`}>
											<input
												type="radio"
												name="radioselect"
												className="radioInput"
												id={`${type}-${value.value}`}
												defaultChecked={value.value === defaultValue}
												value={value.value}
												onChange={() => handleSave(value.value)}
											/>
											<label htmlFor={`${type}-${value.value}`} className="option" style={{minWidth: "100px", height:"40px"}}>
												<span>
													{type === "priority" && <RiFlag2Fill color={flagColor} />} {value.label}
												</span>
											</label>
										</div>
									);
								}) : (
									""
								)}
							</div>
							{type !== "priority" ? (
								<span style={{margin: "0px 5px",marginTop: "-2px",cursor: "pointer",color: "red"}} onClick={() => {setStatusToggle(false);}}>
									<FaTimes size={15} />
								</span>
							): ""}
						</>
					) : (
						<i>Please Wait Saving...</i>
					)}
				</>
			) : (
				<>
					{defaultValue ? label : ("--")} {" "}
					{inheritedCondition && (!defaultValue || user.user_type === "Financer" || user.specialAccessRole || ((user.designation === "HR Executive" || user.designation === "HR Manager")  && (id === "designation_id" || id === "grade" || id === "branch_enc_id"))) ? (
						<div
							style={{ margin: "0px 5px", cursor: "pointer"}}
							onClick={
								loanDetailStatus !== 28 &&
						loanDetailStatus !== 32
									? () => {setShowCurrent(id);setStatusToggle(true);}
									: () => {setStatusToggle(false);}
							}>
					 <FaEdit color="#00a0e3" fontSize="16px" />
						</div>
					): ""}
				</>
			)}
		</>
	);
};
export default EditRadioInput;
