import { Button, Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { auth, firebase } from "../helpers/firebaseInit";
import { useRef, useState } from "react";
import AuthCode from "react-auth-code-input";
import ResetPasswordModal from "./ResetPasswordModal";
import { getLogo } from "../helpers/customFunctions";

const ResetPasswordWithNumber = ({ show, back, onHide }) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [show2, setShow2] = useState(false);
	const [otp, setOtp] = useState("");
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [basePhone, setBasePhone] = useState("");
	const [final, setFinal] = useState("");
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [token, setToken] = useState("");
	const AuthInputRef = useRef(null);
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const showForgotModal = () => {
		back();
	};
	const hideResetPasswordModal = () => {
		setShowPasswordModal(false);
	};
	const encode = str => {
		var encoded = "";
		str = btoa(str);
		str = btoa(str);
		for (let i = 0; i < str.length; i++) {
			var a = str.charCodeAt(i);
			var b = a ^ 10; // bitwise XOR with any number, e.g. 123
			encoded = encoded + String.fromCharCode(b);
		}
		encoded = btoa(encoded);
		return encoded;
	};
	const handleSignIn = async phone => {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
			size: "invisible",
			callback: function (response) {
				console.log(response);
				console.log("Captcha Resolved");
			},
			defaultCountry: "IN"
		});
		setFormIsLoading(true);
		let verify = window.recaptchaVerifier;
		// setFormData(phone);
		await auth
			.signInWithPhoneNumber("+91" + phone, verify)
			.then(result => {
				setFinal(result);
				setFormIsLoading(false);
				setShow2(true);
				window.recaptchaVerifier.clear();
			})
			.catch(err => {
				alert(err);
				// window.location.reload()
			});
	};
	const ValidateOtp = (otpnumb = otp) => {
		if (otpnumb === null || final === null) return;
		final
			.confirm(otpnumb)
			.then(() => {
				// toast.success("Mobile Number Verified");
				formSubmit(basePhone);
			})
			.catch(err => {
				console.log(err);
				alert("Wrong code");
			});
	};
	const handleOtpChange = res => {
		setOtp(res);
		if (res.length === 6) {
			ValidateOtp(res);
		}
	};
	const onSubmit = data => {
		setBasePhone(data.phone);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/verify-phone`,
				{ phone: encode(data.phone) }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					handleSignIn(data.phone);
				} else {
					toast.error("No account linked with this number");
				}
			});
	};
	const formSubmit = formData => {
		const phoneNo = { phone: encode(formData) };
		// formData = {phone : phoneNo}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/otp-change-password`,
				phoneNo
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setShow2(false);
					setToken(response.data.response.token);
					setShowPasswordModal(true);
					onHide();
				}
			});
	};
	return (
		<>
			<Modal show={show} onHide={onHide} size={"lg"} centered>
				<div className="login-page-modal">
					<div className="login-box">
						<div className="login-form-box">
							<div className="logo">
								<img
									src={getLogo()}
									alt={process.env.REACT_APP_NAME}
								/>
							</div>

							<div className="login-form">
								<h1 className="mb10">Reset Password</h1>
								<p className="font-12">
									To reset your password, enter your registered mobile number.
								</p>
								<form className="form" onSubmit={handleSubmit(onSubmit)}>
									<div className="upper-form">
										<div className="login-username mb-3">
											<div className="d-flex">
												<input
													style={{
														width: "50px",
														padding: "8px",
														height: "49px",
														marginRight: "-4px"
													}}
													type="text"
													value="+91"
													className="form-control"
													disabled={true}
												/>
												<input
													style={{
														borderLeftColor: "transparent",
														borderTopLeftRadius: 0,
														borderBottomLeftRadius: 0
													}}
													type="tel"
													placeholder="Phone"
													className="form-control"
													{...register("phone", {
														required: "This is required.",
														minLength: {
															value: 10,
															message:
																"Mobile number Should be at least 10 numbers"
														},
														maxLength: {
															value: 10,
															message:
																"Mobile number maximum be at least 10 numbers"
														},
														pattern: {
															value: phoneRegExp,
															message: "Invalid Phone number"
														}
													})}
												/>
											</div>
											<ErrorMessage
												errors={errors}
												name="phone"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
											<div id="recaptcha-container"></div>
										</div>

										<div className="login-buttons submit-form">
											<button type="submit" className="submit-btn btn">
												Reset
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="login-right-img">
							<div>
								<h1>Back to forgot password</h1>
								<div className="signup-as">
									<div className="signup-as-btns mb-3">
										<button type="button" onClick={showForgotModal}>
											Forgot Password
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal show={show2}>
				<Modal.Header>
					<Modal.Title>Verify Mobile Number</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Please enter the OTP received on number +91{basePhone}{" "}
					<span
						className="text-primary"
						style={{ cursor: "pointer", fontSize: "13px" }}
						onClick={() => setShow2(false)}
					>
						Change Number
					</span>
					<AuthCode
						autoFocus={true}
						onChange={handleOtpChange}
						ref={AuthInputRef}
						placeholder="-"
						containerClassName="otp-container"
						inputClassName="otp-input-field"
					/>
				</Modal.Body>
				<Modal.Footer>
					{formIsLoading ? (
						<button type="button" className="btn btn-primary" disabled={true}>
							<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
						</button>
					) : (
						<Button variant="primary" onClick={ValidateOtp}>
							Submit
						</Button>
					)}
				</Modal.Footer>
			</Modal>
			<ResetPasswordModal
				show={showPasswordModal}
				token={token}
				onHide={hideResetPasswordModal}
			/>
		</>
	);
};
export default ResetPasswordWithNumber;
