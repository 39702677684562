/* eslint-disable indent */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import LoanProductTabs from "./LoanProductTabs";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { ErrorMessage } from "@hookform/error-message";

const FinancerLoanProducts = () => {
	const { register, handleSubmit, setValue, reset, formState: {errors} } = useForm();
	const {
		financer_assigned_loan_types,
		getFinancerAssignedLoanTypes,
		loan_products,
		getLoanProducts
	} = useContext(OrganizationDashboardContext);
	const { handlePageLoaded } = useContext(MainContext);
	const alphaNumericRegex = new RegExp(/^[a-zA-Z0-9]+$/);
	const [showProductTabs, setShowProductTabs] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [productData, setProductData] = useState("");

	const productArray = () => ["Two-wheeler Loan", "E-vehicle", "E-Rickshaw"];
	const getLoanProductDetails = value => {
		setProductData(value);
		setShowProductTabs(true);
	};

	const onsubmit = async values => {
		setIsLoading(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product`,
				values
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reset();
					getLoanProducts();
					toast.success("Product added successfully");
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
				setIsLoading(false);
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};

	const deleteLoanProduct = async id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product`,
				{ financer_loan_product_enc_id: id, deleted: 1 }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					getLoanProducts();
					toast.success("Product removed successfully");
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	const validateInputVal = (e) => {
		const inputVal = e.target.value;
		if (!alphaNumericRegex.test(inputVal)) {
			setValue("product_code", inputVal.substring(0, inputVal.length - 1));
		} else {
			setValue("product_code", inputVal.toUpperCase());
		}
	};

	const backTab = () => {
		setShowProductTabs(false);
	};
	
	// let productFor = watch("product_for");
	useEffect(() => {
		getFinancerAssignedLoanTypes();
		getLoanProducts();
	}, []);

	return (
		<>
			{!showProductTabs ? (
				<>
					<form className="mb-5" onSubmit={handleSubmit(onsubmit)}>
						<h3 className="mb-4 text-primary">Create Loan Products</h3>
						<div className="row align-items-start">
							<div className="col-lg-3 mb-2">
								<label className="form-label">Loan Type</label>
								<select
									className="form-control mh-48" style={{"height":"48px"}}
									{...register("assigned_financer_loan_type_enc_id", {
										required: "This field is required"
									})}
								>
								
								<option value="">Select Loan Type</option>
									
									{financer_assigned_loan_types
										? financer_assigned_loan_types.map((value, index) => {
											return (
												<option
													key={`loan-options-${index}`}
													value={value.assigned_financer_enc_id}
												>
													{value.name}
												</option>
											);
										})
										: ""}
								</select>
								<ErrorMessage
									errors={errors}
									name="assigned_financer_loan_type_enc_id"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-3 mb-2">
								<label className="form-label">Loan Product</label>
								<input
									type="text"
									className="form-control"
									{...register("name", {
										required: "This field is required"
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-2 mb-2 disFlex align-items-end">
								<label className="form-label">Product Code</label>
								<input
									type="text"
									className="form-control"
									{...register("product_code", {
										required: "This field is required"
									})}
									onChange={validateInputVal}
								/>
								<ErrorMessage
									errors={errors}
									name="product_code"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-2 mb-2">
								<label className="form-label">Status</label>
								<select
									className="form-control mh-48" style={{"height":"48px"}}
									{...register("status", {
										required: "This field is required"
									})}
								>
								<option value="Active">Active</option>
								<option value="Inactive">Inactive</option>
								</select>
								<ErrorMessage
									errors={errors}
									name="status"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
								</div>

							{/* <div className="d-flex">
								<div className="me-4">
									<div className="d-flex flex-column">
										<label className="form-label mb-2">
											Product For
										</label>
										<div className="radio-wrapper">
											<div className="radio-item">
												<input
													type="radio"
													name="product_for"
													className="radioInput"
													id="directBankCash"
													value="1"
													defaultChecked={true}
													{...register("product_for", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="directBankCash"
													className="option option-1"
													style={{
														justifyContent: "flex-start",
														marginBottom: "5px"
													}}
												>
													<div className="dot"></div>
													<span>Self</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="product_for"
													className="radioInput"
													id="product_for_bc"
													value="2"
													{...register("product_for", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="product_for_bc"
													className="option option-1"
													style={{
														justifyContent: "flex-start",
														marginBottom: "5px"
													}}
												>
													<div className="dot"></div>
													<span>BC</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="product_for"
													className="radioInput"
													id="product_for_co_lending"
													value="3"
													{...register("product_for", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="product_for_co_lending"
													className="option option-1"
													style={{
														justifyContent: "flex-start",
														marginBottom: "5px"
													}}
												>
													<div className="dot"></div>
													<span>Co Lending</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								{productFor != "1" ? 
									<div className="col-lg-3">
										<label className="form-label">Lender</label>
										<select 
											className="form-control mh-48"
											{...register("assigned_lender", {
												required: "This field is required"
											})}>
											<option value="">EV Finserve</option>
										</select>
									</div>
									: ""}
								
							</div> */}
							<div className="col-lg-2 disFlex align-items-end justify-content-center mt-5">
								<button type="submit" className="btn btn-primary">
									Save
								</button>
							</div>
						</div>
					</form>
					<div className="row">
						<h3 className="mb-4 text-primary">Loan Products</h3>
						{loan_products && loan_products.length ? (
							loan_products
								.sort((a, b) => a.loan.localeCompare(b.loan))
								.map((value, index) => {
									const productName = value.loan;
									const productImg = productArray().includes(productName)
										? productName.trim().replace(/ /g, "-")
										: productName.split("-")[0].trim().replace(/ /g, "-");
									return (
										<div key={`loan-types-${index}`} className="col-lg-4">
											<div className="loan-product-box shadow-lg rounded-1 pointer-cursor position-relative pt-4">
												<div className="product-btn">
													{isLoading[
														value.financer_loan_product_enc_id
													] ? (
															<button
																id="Submit"
																name="Submit"
																className="link-button"
															>
																<img
																	src="/images/pages/home/loader-black.svg"
																	alt={process.env.REACT_APP_NAME}
																/>
															</button>
														) : (
															<button
																className="link-button"
																style={{ color: "red" }}
																onClick={() =>
																	getLoanProductDetails(value)
																}
															>
																<FaEdit size={15} color={"#555"} />
															</button>
														)}
													<button
														className="link-button"
														style={{ color: "red" }}
														onClick={() =>
															deleteLoanProduct(
																value.financer_loan_product_enc_id
															)
														}
													>
														<RiDeleteBin6Line size={15} />
													</button>
												</div>
												<img
													onError={e => {
														e.target.src =
															"/images/pages/dashboard/Loan-products/Default-Loan.png";
													}}
													src={`/images/pages/dashboard/Loan-products/${productImg}.png`}
													alt={value.name}
													className="mb-3 loan-product-img"
												/>
												<div>
													<h5 className="m-0">{value.name}</h5>
													<p className="m-0 fs-5">{value.loan}</p>
												</div>
											</div>
										</div>
									);
								})
						) : (
							<div className="text-center">No Products Found</div>
						)}
					</div>
				</>
			) : (
				<LoanProductTabs
					handlePageLoaded={handlePageLoaded}
					productData={productData}
					back={backTab}
					assigned_loan_types={financer_assigned_loan_types}
					getLoanProducts={getLoanProducts}
				/>
			)}
		</>
	);
};

export default FinancerLoanProducts;
