import { ErrorMessage } from "@hookform/error-message";
import { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { toast } from "react-toastify";

const BankDetails = ({show, onHide, dealerId, dealerBankDetails, reload}) => {
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm();
	const { handlePageLoaded } = useContext(MainContext);
	const ifscregex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

	const onSubmit = values => {
		values["bank_details_enc_id"] = dealerBankDetails.bank_details_enc_id;
		values["organization_enc_id"] = dealerId;
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/update-bank-details`,
				values
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Location added successfully");
					onHide();
					reset();
					reload(dealerId);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
				handlePageLoaded(false);
			});
	};

	const hide = () => {
		onHide();
	};

	useEffect(() => {
		if(dealerBankDetails){
			setValue("name", dealerBankDetails.name);
			setValue("bank_name", dealerBankDetails.bank_name);
			setValue("bank_account_number", dealerBankDetails.bank_account_number);
			setValue("ifsc_code", dealerBankDetails.ifsc_code);
		}
	}, [dealerBankDetails]);

	return(
		<Modal
			size={"lg"}
			show={show} 
			onHide={hide}
		>
			<Modal.Header>
				<Modal.Title>
                        Bank Details
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="name">
                                    Account Holder Name
								</label>
								<input
									placeholder="Account Holder Name"
									className="form-control"
									{...register("name", {
										required: false
									})}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="bank_name">
                                    Bank Name
								</label>
								<input
									placeholder="Bank Name"
									className="form-control"
									{...register("bank_name", {
										required: false
									})}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="bank_account_number">
                                    Account Number
								</label>
								<input
									placeholder="Account Number"
									className="form-control"
									{...register("bank_account_number", {
										required: false
									})}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="ifsc_code">
                                    IFSC Code
								</label>
								<input
									placeholder="IFSC Code"
									className="form-control"
									{...register("ifsc_code", {
										required: false,
										pattern: {
											value: ifscregex,
											message: "Invalid IFSC Code"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="ifsc_code"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>
											{message}
										</p>
									)}
								/>
							</div>
						</div>
						<hr/>
						<div className="text-center">
							<button type="submit" className="btn btn-primary">
                                Save{" "}
							</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};
export default BankDetails;