import { ReactSortable } from "react-sortablejs";
import { RiDeleteBin6Line, RiDragMove2Line } from "react-icons/ri";
import { BsPlusCircleFill } from "react-icons/bs";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LoanProductsContext } from "../../context/LoanProductsState";

const DisbursementCharges = ({ productId, showDocuments, handlePageLoaded }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [disbursementFee, setDisbursementFee] = useState([]);
	const { getLoanProductsDetail, productDisbursementCharges } = useContext(LoanProductsContext);
	const addCharges = () => {
		setDisbursementFee([...disbursementFee, { id: disbursementFee.length + 1, name: "" }]);
	};

	const removeElement = (index, id = "") => {
		if (id) {
			if (!window.confirm("Do you really want to delete this Disbursement Charges")) {
				return false;
			}
			removeDisburseFee(id);
		}
		let items = [...disbursementFee];
		items.splice(index, 1);
		setDisbursementFee(items);
	};

	const removeDisburseFee = async id => {
		let data = { disbursement_charges_enc_id: id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-products/update-d-charges`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Disbursement charges removed successfully");
					getLoanProductsDetail({ id: productId, type: "charges" });
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	const handleChange = (index, value) => {
		let items = [...disbursementFee];
		let item = { ...items[index] };
		item.name = value;
		if (item.name === "" && item.disbursement_charges_enc_id) {
			if (window.confirm("Do you really want to delete this Disbursement Fee")) {
				removeDisburseFee(item.disbursement_charges_enc_id);
			}
		}
		if (!item.disbursement_charges_enc_id) {
			item.disbursement_charges_enc_id = "";
		}

		item.name === "" ? items.splice(index, 1) : (items[index] = item);
		setDisbursementFee(items);
	};

	const onsubmit = async () => {
		if (!disbursementFee.length) {
			toast.error("Please add atleast one Disbursement Charge name");
			return false;
		}
		for (let i = 0; i < disbursementFee.length; i++) {
			if (!disbursementFee[i].name) {
				toast.error("Please enter image name");
				return false;
			}
		}
		setIsLoading(true);
		let data = {
			financer_loan_product_enc_id: productId,
			charges: disbursementFee
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-products/update-d-charges`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Disbursement Charges Updated successfully");
					getLoanProductsDetail({ id: productId, type: "charges" });
				} else {
					toast.error(
						"An error occurred " +
							(response.data.response.error
								? response.data.response.error
								: response.data.response.message)
					);
				}
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};

	const submitWithDelay = () => {
		setTimeout(() => {
			onsubmit();
		}, 700);
	};

	useEffect(() => {
		if (showDocuments) {
			getLoanProductsDetail({ id: productId, type: "charges" });
			handlePageLoaded(true);
		}
	}, [showDocuments]);

	useEffect(() => {
		if (productDisbursementCharges) {
			handlePageLoaded(false);
			setDisbursementFee(productDisbursementCharges);
		} else {
			setDisbursementFee([]);
			handlePageLoaded(false);
		}
	}, [productDisbursementCharges]);
	return (
		<>
			{/* <form onSubmit={handleSubmit(onsubmit)} className="mt-6"> */}
			<div className="proof-selection mb20 mt-6">
				<div className="mb-5">
					<label>Click the button to add Disbursement Charges Names</label>
				</div>
				{disbursementFee && disbursementFee.length ? (
					<>
						<ReactSortable list={disbursementFee} setList={setDisbursementFee}>
							{disbursementFee.map((value, index) => {
								return (
									<div
										className="documentTypehead"
										key={`div-${value.id}${value.name ? `-${value.name}` : ""}`}
									>
										<RiDragMove2Line
											size={25}
											style={{ marginRight: "10px" }}
										/>
										<input
											type="text"
											placeholder="Enter Disbursement Charges Name"
											className="form-control typeheadStyle"
											defaultValue={value.name ? value.name : ""}
											onBlur={e => handleChange(index, e.target.value)}
										/>
										<button
											type="button"
											className="link-button"
											style={{ color: "red", marginLeft: "10px" }}
											onClick={() =>
												removeElement(
													index,
													value.disbursement_charges_enc_id
												)
											}
										>
											<RiDeleteBin6Line size={25} />
										</button>
									</div>
								);
							})}
						</ReactSortable>
					</>
				) : (
					""
				)}
				<button
					className="link-button"
					type="button"
					style={{ color: "#6c757d", marginLeft: "10px" }}
					onClick={() => addCharges()}
				>
					<BsPlusCircleFill size={30} />
				</button>
			</div>
			<div className="mt-5 text-center">
				{isLoading ? (
					<button type="button" className="btn btn-primary" disabled={true}>
						<img src="/images/pages/home/loader.svg" alt="" />
					</button>
				) : (
					<button
						type="submit"
						className="btn btn-primary"
						onClick={() => submitWithDelay()}
					>
						{" "}
						Save{" "}
					</button>
				)}
			</div>
			{/* </form> */}
		</>
	);
};

export default DisbursementCharges;
