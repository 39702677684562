import { FaRegEdit, FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import React, { useState } from "react";
import { statusDropdownData } from "../../data/StatusDropdownData";

const UserStatusField = ({
	row,
	updateStatus,
	dataFields = statusDropdownData.defaultData,
	nagativeId = "",
	type = ""
}) => {
	const [statusToggle, setStatusToggle] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const statusObj = {
		Pending: "warning",
		Active: "success",
		Inactive: "danger"
	};

	const negativeLocObj = {
		Pending: "Pending",
		Active: "Accept",
		Inactive: "Reject"
	};

	return (
		<>
			{/* <span className='text-capitalize'>{row.tbl_uid}</span>*/}
			{statusToggle && showCurrent === row.user_enc_id ? (
				<>
					<select
						defaultValue={row.status}
						onChange={event => {
							event.preventDefault();
							event.stopPropagation();
							updateStatus(
								row.user_enc_id,
								event.target.value,
								type,
								nagativeId,
								row.product_enc_id,
							).then(() => setStatusToggle(false));
						}}
					>
						{dataFields.map((value, index) => {
							return (
								<option key={`statusEdit-${index}`} value={value.value}>
									{value.label}
								</option>
							);
						})}
						{/* <option value='Active'>{nagativeId ? 'Accept' : 'Active'}</option>*/}
						{/* <option value='Inactive'>{nagativeId ? 'Reject' : 'Inactive'}</option>*/}
						{/* <option value='Pending'>Pending</option>*/}
					</select>
					<span
						style={{
							margin: "0px 5px",
							marginTop: "-2px",
							cursor: "pointer",
							color: "red"
						}}
						onClick={() => {
							setStatusToggle(false);
						}}
					>
						<FaTimes size={15} />
					</span>
				</>
			) : (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Badge
						className="text-capitalize mx-2"
						bg={statusObj[row.status]}
						onDoubleClick={() => {
							setShowCurrent(row.user_enc_id);
							setStatusToggle(true);
						}}
						pill
					>
						{nagativeId ? negativeLocObj[row.status] : row.status}
					</Badge>
					<FaRegEdit
						style={{ color: "rgb(41, 121, 255)", cursor: "pointer" }}
						size={14}
						onClick={() => {
							setShowCurrent(row.user_enc_id);
							setStatusToggle(true);
						}}
					/>
				</div>
			)}
		</>
	);
};
export default UserStatusField;
