import React from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import { AiOutlineSortDescending } from "react-icons/ai";
import { BsSortAlphaUpAlt } from "react-icons/bs";

const SortTableColumn = ({
	column,
	sortColumn,
	currentSort,
	callback = () => {
		return true;
	}
}) => {
	// const { loan_status } = useContext(MainContext);
	// const [upcomingStatus, setUpcomingStatus] = useState([]);
	// const changeStatus = val => {
	// 	const data = {};
	// 	axios
	// 		.post(
	// 			`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-provider-status`,
	// 			data
	// 		)
	// 		.then(() => {
	// 			callback(true);
	// 		});
	// };

	return (
		<>
			{!currentSort || sortColumn !== column ? (
				<span
					style={{
						margin: "0px 5px 0px 0px",
						marginTop: "-2px",
						cursor: "pointer",
						color: "#00a0e3"
					}}
					onClick={() => {
						callback(column);
					}}
				>
					<TiArrowUnsorted size={15} />
				</span>
			) : (
				""
			)}
			{currentSort === "ASC" && sortColumn === column && (
				<span
					style={{
						margin: "0px 5px 0px 0px",
						marginTop: "-2px",
						cursor: "pointer",
						color: "#00a0e3"
					}}
					onClick={() => {
						callback(column);
					}}
				>
					<AiOutlineSortDescending size={15} />
				</span>
			)}
			{currentSort === "DESC" && sortColumn === column && (
				<span
					style={{
						margin: "0px 5px 0px 0px",
						marginTop: "-2px",
						cursor: "pointer",
						color: "#00a0e3"
					}}
					onClick={() => {
						callback(column);
					}}
				>
					<BsSortAlphaUpAlt size={15} />
				</span>
			)}
		</>
	);
};
export default SortTableColumn;
