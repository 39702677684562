import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import LoanProductReportTable from "../components/dashboard/LoanProductReportTable";

const LoanProductReport = () => {

	return (
		<div>
			<Helmet>
				<title>Loan Product Report | {process.env.REACT_APP_NAME}</title>
			</Helmet>
			<Navbar />
			<div className="branch-report-main py-5">
				<div className="container-fluid">
					<div className="shadow-lg rounded-3 py-4 px-6 bg-white">
						<div className={"d-flex justify-content-between mb-3"}>
							<h2 className="mb-0">Loan Product Report</h2>
						</div>
						<LoanProductReportTable type="by_amount" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default LoanProductReport;
