import Navbar from "../../components/Navbar";
import "../../assets/css/accountStyles.css";
import { Helmet } from "react-helmet";
import { EmployeeReportsProvider } from "../../context/EmployeeReports";
import DailyCollectionReportTable from "../../components/dashboard/DailyCollectionReportTable";
import { useContext, useState } from "react";
import { MainContext } from "../../context/MainState";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "react-bootstrap";
import DailyReportTableBranches from "../../components/dashboard/DailyReportTableBranches";
import DailyReportTableLoanType from "../../components/dashboard/DailyReportTableLoanType";

const DailyCollectionReport = () => {
	const { user } = useContext(MainContext);
	const [state, setState] = useState({
		activeTab: "1",
		loadSecond: false,
		loadThird: false,
	});

	const toggle = (tab) => {
		if (state.activeTab !== tab) {
			setState((prevState) => ({
				...prevState,
				activeTab: tab,
				loadSecond: tab === "2" ? true : prevState.loadSecond,
				loadThird: tab === "3" ? true : prevState.loadThird,
			}));
		}
	};

	return (
		<>
			<Helmet>
				<title>Daily Collection Report | {process.env.REACT_APP_NAME}</title>
			</Helmet>
			<Navbar />
			<EmployeeReportsProvider>
				<div className="dashboard-dsa py-5 branch-report-main">
					<div className="container-fluid">
						<div className="loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
							<div className={"d-flex justify-content-between mb-3"}>
								<h4 className="mb-0">Daily Collection Reports</h4>
							</div>
							{user.user_type === "Financer" ? (
								<Nav className="mb-4 users-nav">
									<NavItem>
										<NavLink
											active={state.activeTab === "1"}
											onClick={() => toggle("1")}
										>
											By Employees
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											active={state.activeTab === "2"}
											onClick={() => toggle("2")}
										>
											By Branches
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											active={state.activeTab === "3"}
											onClick={() => toggle("3")}
										>
											By Loan Type
										</NavLink>
									</NavItem>
								</Nav>
							) : ""}
							<TabContent className="py-50" activeTab={state.activeTab}>
								<TabPane tabId="1" className={state.activeTab === "1" ? "active" : ""}>
									<DailyCollectionReportTable />
								</TabPane>
								{user.user_type === "Financer" ? (
									<>
										<TabPane tabId="2" className={state.activeTab === "2" ? "active" : ""}>
											<DailyReportTableBranches loadDefault={state.loadSecond} />
										</TabPane>
										<TabPane tabId="3" className={state.activeTab === "3" ? "active" : ""}>
											<DailyReportTableLoanType loadDefault={state.loadThird} />
										</TabPane>
									</>
								) : ""}
							</TabContent>
						</div>
					</div>
				</div>
			</EmployeeReportsProvider>
		</>
	);
};

export default DailyCollectionReport;
