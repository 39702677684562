import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import LoanResidentialDetails from "./LoanResidentialDetails";
import { MainContext } from "../../context/MainState";
import { readOnlyUsers } from "../../helpers/authServices";
import Compressor from "compressorjs";
import { MdFileUpload } from "react-icons/md";

const AddBorroweForm = ({ selectedBorrower = {}, loanDetails, callback }) => {
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm();
	const { user } = useContext(MainContext);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [userAddressData, setUserAddressData] = useState("");
	const [checkedOnce, setCheckedOnce]=useState(false);
	const [selectedOptionsModel, setSelectedOptionsModel] = useState({
		value: "",
		label: "Select Relation"
	});
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "",
		label: "Select Marital Status"
	});

	useEffect(() => {
		if(loanDetails.loan_status){
			setCheckedOnce(parseInt(loanDetails.loan_status)>= 31);
		}
		else{
			setCheckedOnce(false);
		}
	}, [selectedBorrower]);
	
	const [numberExist, setNumberExist] = useState({
		phone: false,
		pan_number: false,
		aadhaar_number: false,
		voter_card_number: false
	});
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const optionList = [
		{
			value: "Father",
			label: "Father"
		},
		{
			value: "Mother",
			label: "Mother"
		},
		{
			value: "Brother",
			label: "Brother"
		},
		{
			value: "Sister",
			label: "Sister"
		},
		{
			value: "Son",
			label: "Son"
		},
		{
			value: "Daughter",
			label: "Daughter"
		},
		{
			value: "Guardian",
			label: "Guardian"
		},
		{
			value: "Husband",
			label: "Husband"
		},
		{
			value: "Wife",
			label: "Wife"
		},
		{
			value: "Uncle",
			label: "Uncle"
		},
		{
			value: "Aunt",
			label: "Aunt"
		},
		{
			value: "Son In Law",
			label: "Son In Law"
		},
		{
			value: "Daughter In Law",
			label: "Daughter In Law"
		},
		{
			value: "Father In Law",
			label: "Father In Law"
		},
		{
			value: "Mother In Law",
			label: "Mother In Law"
		},
		{
			value: "Brother In Law",
			label: "Brother In Law"
		},
		{
			value: "Sister In Law",
			label: "Sister In Law"
		},
		{
			value: "Neighbour",
			label: "Neighbour"
		},
		{
			value: "Friend",
			label: "Friend"
		},
		{
			value: "Other",
			label: "Other"
		}
		// {
		//     "value": "Guarantor",
		//     "label": "Guarantor"
		// }
	];
	const maritalStatusList = [
		{
			value: "Married",
			label: "Married"
		},
		{
			value: "Unmarried",
			label: "Unmarried"
		},
		{
			value: "Divorced",
			label: "Divorced"
		}
	];
	const checkNumber = async (value, key) => {
		if (value) {
			let data = { [key]: value };
			if (selectedBorrower && selectedBorrower[key] === value) {
				return false;
			}
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/check-number`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						setError(key, {
							type: "custom",
							message: "Already Exists"
						});
						setNumberExist({ ...numberExist, [key]: true });
					} else {
						clearErrors(key);
						setNumberExist({ ...numberExist, [key]: false });
					}
				});
		}
	};
	const [uploadedImageName, setUploadedImageName] = useState({
		name: "",
		id: ""
	});
	let base_uri2 = "";
	const [uploadedFile, setUploadedFile] = useState(false);
	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		base_uri2 = result_base64;
		return result_base64;
	};

	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};
	const handleFileInputChange = (input, certificate_name = "", id = "") => {
		if (!input.target.files) {
			return false;
		}
		if (certificate_name && id) {
			setUploadedImageName({ name: certificate_name, id });
		}
		const file = input.target.files[0];
		const fileExtenstion = file.name.split(".").pop().toLowerCase();
		if (fileExtenstion === "png" || fileExtenstion === "jpg" || fileExtenstion === "jpeg") {
			getUri(file).then(() => {
				setUploadedFile(base_uri2);
			});
		}
	};

	useEffect(() => {
		console.log(uploadedFile);
		console.log(uploadedImageName);
	}, []);

	const onSubmit = async data => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		data.name = data?.name?.replace(/\b\w/g, char => char.toUpperCase());
		if (selectedOptionsModel.value === "") {
			toast.error("Please select the relation");
			return false;
		}
		if (selectedStatusModel.value === "") {
			toast.error("Please select the marital status");
			return false;
		}
		if (!data.pan_number && !data.aadhaar_number && !data.voter_card_number) {
			toast.error("Please add at least one ID proof (PAN or Aadhaar or Voter ID)");
			return false;
		}
		if (
			numberExist.aadhaar_number ||
			numberExist.pan_number ||
			numberExist.phone ||
			numberExist.voter_card_number
		) {
			toast.error(
				<div>
					Details Already Exists: <br />
					{numberExist.phone ? " - Phone Number " : ""}
					{numberExist.pan_number ? " - PAN " : ""}
					{numberExist.aadhaar_number ? " - Aadhaar Number " : ""}
					{numberExist.voter_card_number ? " - Voter Card " : ""}
				</div>
			);
			return false;
		}
		data["loan_id"] = loanDetails.loan_app_enc_id;
		data["relation"] = selectedOptionsModel.value;
		data["marital_status"] = selectedStatusModel.value;
		if (selectedBorrower.loan_co_app_enc_id) {
			data["loan_co_app_enc_id"] = selectedBorrower.loan_co_app_enc_id;
		}
		if (selectedBorrower.aadhaar_number && !(user.user_type === "Financer" || user.specialAccessRole)){
			data["aadhaar_number"] = selectedBorrower.aadhaar_number;
		}
		if (selectedBorrower.pan_number && !(user.user_type === "Financer" || user.specialAccessRole)){
			data["pan_number"] = selectedBorrower.pan_number;
		}
		if (selectedBorrower.voter_card_number && !(user.user_type === "Financer" || user.specialAccessRole)){
			data["voter_card_number"] = selectedBorrower.voter_card_number;
		}
		if (selectedBorrower.name && !(user.user_type === "Financer" || user.specialAccessRole)){
			data["name"] = selectedBorrower.name;
		}
		if (selectedBorrower.phone && !(user.user_type === "Financer" || user.specialAccessRole)){
			data["phone"] = selectedBorrower.phone;
		}
		setFormIsLoading(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/add-co-applicant`,
				data
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Borrower added successfully");
					reset();
					callback();
				} else {
					toast.error("An error has occurred, Please try again later");
				}
			})
			.catch(error => {
				console.log(error);
				setFormIsLoading(false);
				toast.error("An error has occurred, Please try again");
			});
	};
	useEffect(() => {
		if (selectedBorrower.loan_co_app_enc_id) {
			setValue("borrower_type", selectedBorrower.borrower_type);
			setValue("name", selectedBorrower.name);
			setValue("father_name", selectedBorrower.father_name);
			setValue("phone", selectedBorrower.phone);
			setValue("address", selectedBorrower.address);
			setValue("pan_number", selectedBorrower.pan_number);
			setValue("aadhaar_number", selectedBorrower.aadhaar_number);
			setValue("voter_card_number", selectedBorrower.voter_card_number);
			setValue("driving_license_number", selectedBorrower.driving_license_number);
			setValue("passport_number", selectedBorrower.passport_number);
			setValue("dob", selectedBorrower.co_applicant_dob);
			setValue("gender", selectedBorrower.gender);
			setValue("has_smartphone", selectedBorrower.has_smartphone);
			setSelectedOptionsModel({
				value: selectedBorrower.relation,
				label: selectedBorrower.relation
			});
			setSelectedStatusModel({
				value: selectedBorrower.marital_status,
				label: selectedBorrower.marital_status
			});
			let userData = {};
			userData["state"] = selectedBorrower.state;
			userData["city"] = selectedBorrower.city;
			userData["postal_code"] = selectedBorrower.postal_code;
			setUserAddressData(userData);
		}
	}, [selectedBorrower]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<fieldset>
				<div className="row">
					{!selectedBorrower.loan_co_app_enc_id ? (
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label d-block">Select Borrower Type <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
								<div className="radio-wrapper hero-radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-2"
											value="Co-Borrower"
											{...register("borrower_type", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="option-2"
											className="option option-2"
											style={{
												cursor: selectedBorrower.loan_co_app_enc_id
													? "not-allowed"
													: "auto"
											}}
										>
											<div className="dot"></div>
											<span>Co-Borrower</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-6"
											value="Guarantor"
											{...register("borrower_type", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="option-6"
											className="option option-6"
											style={{
												cursor: selectedBorrower.loan_co_app_enc_id
													? "not-allowed"
													: "auto"
											}}
										>
											<div className="dot"></div>
											<span>Guarantor</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-7"
											value="Others"
											{...register("borrower_type", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="option-7"
											className="option option-7"
											style={{
												cursor: selectedBorrower.loan_co_app_enc_id
													? "not-allowed"
													: "auto"
											}}
										>
											<div className="dot"></div>
											<span>Other</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Full Name <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
							<input
								className="form-control"
								disabled={(selectedBorrower.name && checkedOnce) || (selectedBorrower.name  && !(user.user_type === "Financer" || user.specialAccessRole))}
								{...register("name", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="name"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Mobile Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("phone", {
									required: false,
									minLength: {
										value: 10,
										message: "Mobile number Should be at least 10 numbers"
									},
									maxLength: {
										value: 10,
										message: "Mobile number maximum be at least 10 numbers"
									},
									pattern: {
										value: phoneRegExp,
										message: "Invalid Phone Number"
									}
								})}
								onBlur={e => {
									checkNumber(e.target.value, "phone");
								}}
								disabled={(selectedBorrower.phone && checkedOnce) || (selectedBorrower.phone  && !(user.user_type === "Financer" || user.specialAccessRole))}
							/>
							<ErrorMessage
								errors={errors}
								name="phone"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label d-block">Has Smartphone? <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
							<div className="radio-wrapper hero-radio-wrapper">
								<div className="radio-item">
									<input
										type="radio"
										name="select"
										className="radioInput"
										id="have-phone"
										value="1"
										{...register("has_smartphone", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="have-phone"
										className="option option-1"
									>
										<div className="dot"></div>
										<span>Yes</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="select"
										className="radioInput"
										id="doesnot-have-phone"
										value="0"
										{...register("has_smartphone", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="doesnot-have-phone"
										className="option option-1"
									>
										<div className="dot"></div>
										<span>No</span>
									</label>
								</div>
							</div>
							<ErrorMessage
								errors={errors}
								name="has_smartphone"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						{process.env.REACT_APP_TYPE === "DEV" ? (
							<div className="upload-document d-flex justify-content-between align-items-center">
								<label
									className="text-center color-white form-control w-auto bg-primary btn-sm g-mr-15 my-4 d-md-none"
									style={{
										cursor: "pointer"
									}}
									htmlFor={"capture_image_pan"}
								>
									Click PAN Card Photo <MdFileUpload color="#fff" fontSize={"16"} />
								</label>
								<input
									id={"capture_image_pan"}
									type="file"
									min={1}
									accept="image/*"
									placeholder="Choose File"
									className="form-control d-none"
									capture="environment"
									onChange={e => handleFileInputChange(e)}
								/>
							</div>
						) :"" }
						<div className="mb-3">
							<label className="form-label">Enter PAN Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("pan_number", {
									required: false,
									pattern: {
										value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
										message: "Invalid PAN Number"
									}
								})}
								disabled={(selectedBorrower.pan_number && checkedOnce )|| (selectedBorrower.pan_number  && !(user.user_type === "Financer" || user.specialAccessRole))}
								onBlur={e => {
									checkNumber(e.target.value, "pan_number");
								}}
							/>
							<ErrorMessage
								errors={errors}
								name="pan_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						{process.env.REACT_APP_TYPE === "DEV" ? (
							<div className="upload-document d-flex justify-content-between align-items-center">
								<label
									className="text-center color-white form-control w-auto bg-primary btn-sm g-mr-15 my-4 d-md-none"
									style={{
										cursor: "pointer"
									}}
									htmlFor={"capture_image_aadhaar"}
								>
									Click Aadhaar Card Photo <MdFileUpload color="#fff" fontSize={"16"} />
								</label>
								<input
									id={"capture_image_aadhaar"}
									type="file"
									min={1}
									accept="image/*"
									placeholder="Choose File"
									className="form-control d-none"
									capture="environment"
									onChange={e => handleFileInputChange(e)}
								/>
							</div>
						) : ""}
						<div className="mb-3">
							<label className="form-label">Enter Aadhaar Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("aadhaar_number", {
									required: false,
									pattern: {
										value: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
										message: "Invalid Aadhaar Number"
									}
								})}
								disabled={(selectedBorrower.aadhaar_number && checkedOnce) || (selectedBorrower.aadhaar_number  && !(user.user_type === "Financer" || user.specialAccessRole))}
								onBlur={e => {
									checkNumber(e.target.value, "aadhaar_number");
								}}
							/>
							<ErrorMessage
								errors={errors}
								name="aadhaar_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Enter Voter Card Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("voter_card_number", {
									required: false
								})}
								disabled={(selectedBorrower.voter_card_number && checkedOnce) || (selectedBorrower.voter_card_number  && !(user.user_type === "Financer" || user.specialAccessRole))}
								onBlur={e => {
									checkNumber(e.target.value, "voter_card_number");
								}}
							/>
							<ErrorMessage
								errors={errors}
								name="voter_card_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Enter Driving License Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("driving_license_number", {
									required: false,
									pattern: {
										value: /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
										message: "Invalid Driving License Number"
									}
								})}
								// onBlur={e => {
								//   checkNumber(e.target.value, "driving_license_number");
								// }}
								disabled={(selectedBorrower.driving_license_number && checkedOnce) || (selectedBorrower.driving_license_number  && !(user.user_type === "Financer" || user.specialAccessRole))}
							/>
							<ErrorMessage
								errors={errors}
								name="driving_license_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Enter Passport Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("passport_number", {
									required: false,
									pattern: {
										value: /^[A-Z0-9]{8,15}$/,
										message: "Invalid Passport Number"
									}
								})}
								disabled={(selectedBorrower.passport_number && checkedOnce )|| (selectedBorrower.passport_number  && !(user.user_type === "Financer" || user.specialAccessRole))}

							/>
							<ErrorMessage
								errors={errors}
								name="passport_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className=" form-label">Date of Birth <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
							<input
								type="date"
								min="1920-01-01"
								max="2008-12-31"
								className="form-control"
								{...register("dob", {
									required: "This is required."
								})}
								disabled={(selectedBorrower.co_applicant_dob && checkedOnce) ||(selectedBorrower.co_applicant_dob  && !(user.user_type === "Financer" || user.specialAccessRole))}
							/>
							<ErrorMessage
								errors={errors}
								name="dob"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					{selectedBorrower.borrower_type === "Borrower" ? (
						""
					) : (
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label">Relation</label>
								<Select
									className="customStyleSelect"
									options={optionList}
									value={selectedOptionsModel}
									name="relation"
									placeholder="Choose Model"
									isDisabled={selectedBorrower.loan_co_app_enc_id}
									onChange={option => {
										setSelectedOptionsModel(option);
									}}
								/>
							</div>
						</div>
					)}
					
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Marital Status</label>
							<Select
								className="customStyleSelect"
								options={maritalStatusList}
								value={selectedStatusModel}
								name="marital_status"
								placeholder="Choose Model"
								onChange={option => {
									setSelectedStatusModel(option);
								}}
								isDisabled={(selectedBorrower.marital_status && checkedOnce) || (selectedBorrower.marital_status  && !(user.user_type === "Financer" || user.specialAccessRole))}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Father Name </label>
							<input
								className="form-control"
								disabled={(selectedBorrower.father_name && checkedOnce) || (selectedBorrower.father_name  && !(user.user_type === "Financer" || user.specialAccessRole))}
								{...register("father_name", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="father_name"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					{!selectedBorrower.loan_co_app_enc_id || !selectedBorrower.gender || (user.user_type === "Financer" || user.specialAccessRole) ? (
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label d-block">Gender</label>
								<div className="radio-wrapper hero-radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="gender"
											className="radioInput"
											id="gender-1"
											value="1"
											readOnly={selectedBorrower.loan_co_app_enc_id}
											{...register("gender", {
												required: !checkedOnce ? "This is required." : false
											})}
											disabled={(selectedBorrower.gender && checkedOnce )|| (selectedBorrower.gender  && !(user.user_type === "Financer" || user.specialAccessRole))}
										/>
										<label htmlFor="gender-1" className="option option-1">
											<div className="dot"></div>
											<span>Male</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="gender"
											className="radioInput"
											id="gender-2"
											value="2"
											disabled={(selectedBorrower.gender && checkedOnce )|| (selectedBorrower.gender  && !(user.user_type === "Financer" || user.specialAccessRole))}
											readOnly={selectedBorrower.loan_co_app_enc_id}
											{...register("gender", {
												required: !checkedOnce ? "This is required." : false
											})}
										/>
										<label htmlFor="gender-2" className="option option-6">
											<div className="dot"></div>
											<span>Female</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="gender"
											className="radioInput"
											id="gender-3"
											value="3"
											disabled={(selectedBorrower.gender && checkedOnce )|| (selectedBorrower.gender  && !(user.user_type === "Financer" || user.specialAccessRole))}
											readOnly={selectedBorrower.loan_co_app_enc_id}
											{...register("gender", {
												required: !checkedOnce ? "This is required." : false
											})}
										/>
										<label htmlFor="gender-3" className="option option-7">
											<div className="dot"></div>
											<span>Transgender</span>
										</label>
									</div>
								</div>
								<ErrorMessage
									errors={errors}
									name="gender"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
					) : (
						""
					)}
					<div className="col-12">
						<div className="mb-3">
							<label className="form-label">Enter Address</label>
							<input
								placeholder=""
								className="form-control"
								{...register("address", {
									required: false
								})}
								disabled={(selectedBorrower.address && checkedOnce) || (selectedBorrower.address  && !(user.user_type === "Financer" || user.specialAccessRole))}
							/>
							<ErrorMessage
								errors={errors}
								name="address"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div>
						<LoanResidentialDetails
							formFor="co-borrower"
							register={register}
							errors={errors}
							setValue={setValue}
							control={control}
							userData={userAddressData}
							checkedOnce={checkedOnce}
							selectedBorrower={selectedBorrower}
						/>
					</div>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 text-center">
						{formIsLoading ? (
							<button id="Submit" disabled={true} className="btn btn-primary ">
								<img src="/images/pages/home/loader.svg" alt="Form Loading" />
							</button>
						) : (
							<button id="Submit" name="Submit" className="btn btn-primary ">
								Save
							</button>
						)}
					</div>
				</div>
			</fieldset>
		</form>
	);
};

export default AddBorroweForm;
