import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Col, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsPersonFillAdd } from "react-icons/bs";
import { MainContext } from "../../context/MainState";
import { IoCloseCircle } from "react-icons/io5";
import { readOnlyUsers } from "../../helpers/authServices";

const AggrementTypeEdit = ({
	defaultValue,
	optionList,
	getData,
	id,
	api,
	parentId = "",
	selectPlaceholder = "",
	loanDetailStatus,
	type = "application",
	inheritedCondition = true
}) => {
	const { user } = useContext(MainContext);
	const [statusToggle, setStatusToggle] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const { register, handleSubmit, watch } = useForm();
	const toBeShared = watch("partner_id");
	const handleRemoveLender = partner_id => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/remove-partner`,
				{ loan_partner_id: partner_id }
			)
			.then(response => {
				setLoading(false);
				getData(id);
				if (response.data.response.status === 200) {
					toast.success("Successfully updated");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred, Please try again");
			});
	};
	const onSubmit = values => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let data = {
			loan_id: id,
			partner_id: values.partner_id,
			type: values.type,
			parent_id: parentId
		};
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				data
			)
			.then(response => {
				setLoading(false);
				getData(id);
				if (response.data.response.status === 200) {
					setStatusToggle(false);
					toast.success("Successfully updated");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
				toast.error("An error has occurred, Please try again");
			});
	};
	return (
		<>
			{statusToggle && showCurrent === id ? (
				<>{!loading ? <div></div> : <i>Please Wait Saving...</i>}</>
			) : (
				<div
					className="tags-bar"
					style={{ margin: "0px 5px" }}
					onDoubleClick={
						loanDetailStatus != 28 && loanDetailStatus != 32 && !(defaultValue?.loan_partners?.length || defaultValue?.assignedFinancerEnc?.name)
							? () => {
								setShowCurrent(id);
								setStatusToggle(true);
							  }
							: () => setStatusToggle(false)
					}
				>
					{defaultValue &&
					defaultValue.loan_partners &&
					defaultValue.loan_partners.length ? (
							defaultValue.loan_partners.map((value, index) => {
								return (
									<>
										<span key={`loan_partners-${index}`}>
											<b
												style={{ fontWeight: "normal" }}
												dangerouslySetInnerHTML={{
													__html: value.name
												}}
											></b>
											{process.env.REACT_APP_TYPE === "DEV" || user.organization_username === "phfleasing" ? (
												<i
													onClick={() => {
														handleRemoveLender(
															value.loan_application_partner_enc_id
														);
													}}
												>
														x
												</i>
											) : ""}
										</span>
									</>
								);
							})
						) : ""}
					{!(defaultValue?.loan_partners?.length || defaultValue?.assignedFinancerEnc?.name) && inheritedCondition ? (
						<div
							style={{
								color: "rgb(41, 121, 255)",
								display: "inline-block",
								cursor: "pointer"
							}}
							onClick={
								loanDetailStatus != 28 && loanDetailStatus != 32
									? () => {
										setShowCurrent(id);
										setStatusToggle(true);
									}
									: () => setStatusToggle(false)
							}
						>
							<BsPersonFillAdd color="#00a0e3" size={22} />
						</div>
					) : ""}
				</div>
			)}

			<Modal show={statusToggle} onHide={setStatusToggle} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>Update Application Access</Modal.Title><span className="close-icon" onClick={()=>setStatusToggle(false)}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="col-sm-12 col-12">
							<div className="mb-3 d-flex">
								{/* <label className="form-label">Enter Username</label>*/}
								<div className="w-100">
									<select
										className="form-select"
										{...register("partner_id", {
											required: "This is required."
										})}
										style={
											{
												// width: 'auto',
												// minWidth: '150px',
												// padding: '5px',
												// paddingRight: '40px',
												// paddingLeft: '15px',
												// maxWidth: 'calc(100% - 50px)',
												// margin: '0px 5px',
												// height: '34px'
											}
										}
									>
										<option value="">
											{selectPlaceholder ? selectPlaceholder : "Choose"}
										</option>
										{optionList && optionList.length
											? optionList.map((value, index) => {
												return (
													<option
														value={value.organization_enc_id}
														key={`financers-set-${index}`}
													>
														{value.name}
													</option>
												);
											  })
											: ""}
									</select>
								</div>
								{toBeShared && type === "application" ? (
									<select
										style={{ maxWidth: "160px", marginLeft: "10px" }}
										className="form-select"
										name="access"
										id="shared_access"
										{...register("type", { required: "This is required." })}
									>
										<option value="BC">BC</option>
										<option value="Co-Lending">Co-Lending</option>
									</select>
								) : (
									""
								)}
							</div>
						</div>
						{toBeShared ? (
							<div className="col-sm-12 col-12">
								<Col size={12} className="text-center mt-2">
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</Col>
							</div>
						) : (
							""
						)}
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default AggrementTypeEdit;
