import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { authServices } from "../helpers/authServices";
import { MainContext } from "../context/MainState";
import { BsCameraFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const ProfileDetails = ({ user }) => {
	const [formIsLoading, setFormIsLoading] = useState(false);
	const { updateUser } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const uppercaseRegExp = /^[A-Z0-9]+$/;
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();

	const handleSaveLogo = e => {
		if (e.target.files) {
			if (e.target.files.length !== 0) {
				setFormIsLoading(true);
				const file = e.target.files[0];
				const formData = new FormData();
				formData.append("logo", file);
				axios
					.post(
						`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/upload-logo`,
						formData
					)
					.then(response => {
						setFormIsLoading(false);
						if (response.data.response.status === 200) {
							toast.success("Profile updated");
							authServices.findUser(updateUser);
						} else {
							toast.error("An error has occurred, Please try again");
						}
					})
					.catch(error => {
						console.log(error);
						setFormIsLoading(false);
						toast.error("An error has occurred, Please try again");
					});
			}
		}
	};
	const handleSaveImage = e => {
		if (e.target.files) {
			if (e.target.files.length !== 0) {
				setFormIsLoading(true);
				const file = e.target.files[0];
				const formData = new FormData();
				formData.append("profile_image", file);
				axios
					.post(
						`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/upload-profile-picture`,
						formData
					)
					.then(response => {
						setFormIsLoading(false);
						if (response.data.response.status === 200) {
							toast.success("Profile updated");
							authServices.findUser(updateUser);
						} else {
							toast.error("An error has occurred, Please try again");
						}
					})
					.catch(error => {
						console.log(error);
						setFormIsLoading(false);
						toast.error("An error has occurred, Please try again");
					});
			}
		}
	};

	const onSubmit = async data => {
		setIsLoading(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/update-profile`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Profile updated successfully");
					authServices.findUser(updateUser);
					reset();
				} else if (response.data.response.status === 400) {
					toast.error("Employee code already exists");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<>
					{user && user.user_type === "Financer" ? (
						<>
							<div className="row">
								<div className="mb-4">
									<div className="img-preview-user text-center p-2 shadow-ell rounded-2 m-auto position-relative">
										<label
											className="form-label edit-image"
											htmlFor="change_image"
										>
											<BsCameraFill size={30} className="edit-image-camera" />
										</label>
										<input
											type={"file"}
											id="change_image"
											className="opacity-0 position-absolute input-hide"
											onChange={e => handleSaveLogo(e)}
										/>
										{formIsLoading ? (
											<img
												src="/images/pages/home/loader-black.svg"
												alt={process.env.REACT_APP_NAME}
											/>
										) : (
											<img
												src={user.logo}
												className="img-fluid"
												alt={user.name}
											/>
										)}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="mb-4 col-12">
									<label className="form-label" htmlFor="org_name">
										Organization Name
									</label>
									<input
										placeholder="Enter Name"
										className="form-control text-capitalize"
										value={user.organization_name}
										disabled={true}
									/>
								</div>
								<div className="mb-6 col-md-6 col-12">
									<label className="form-label" htmlFor="org_mail">
										Organization Number
									</label>
									<input
										placeholder="Enter Mobile Number"
										className="form-control text-capitalize"
										value={user.organization_phone}
										disabled={true}
									/>
								</div>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="org_mail">
										Organization E-Mail
									</label>
									<input
										placeholder="Enter E-Mail"
										className="form-control text-lowercase"
										value={user.organization_email}
										disabled={true}
									/>
								</div>
							</div>
							<div className="row">
								<h3 className="mb-4 text-primary">Personal Details</h3>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="first_name">
										First Name
									</label>
									<input
										placeholder="First Name"
										className="form-control text-capitalize"
										value={user.first_name ? user.first_name + " " : user.name}
										disabled={true}
									/>
								</div>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="last_name">
										Last Name
									</label>
									<input
										placeholder="Last Name"
										className="form-control text-capitalize"
										value={user.last_name ? user.last_name + " " : user.name}
										disabled={true}
									/>
								</div>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="first_num">
										Mobile Number
									</label>
									<input
										placeholder="Enter Mobile Number"
										className="form-control"
										value={user.phone}
										disabled={true}
									/>
								</div>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="first_mail">
										E-Mail Address
									</label>
									<input
										placeholder="Enter your E-mail id"
										className="form-control"
										value={user.email}
										disabled={true}
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="row">
								<div className="mb-4">
									<div className="img-preview-user text-center p-2 shadow-ell rounded-2 m-auto position-relative">
										<label
											className="form-label edit-image"
											htmlFor="change_image"
										>
											<BsCameraFill size={30} className="edit-image-camera" />
										</label>
										<input
											type={"file"}
											id="change_image"
											className="opacity-0 position-absolute input-hide"
											onChange={e => handleSaveImage(e)}
										/>
										{formIsLoading ? (
											<img
												src="/images/pages/home/loader-black.svg"
												alt={process.env.REACT_APP_NAME}
											/>
										) : (
											<img
												src={user.image}
												className="img-fluid"
												alt={user.name}
											/>
										)}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="first_name">
										First Name
									</label>
									<input
										placeholder="First Name"
										className="form-control text-capitalize"
										value={user.first_name ? user.first_name + " " : user.name}
										disabled={true}
									/>
								</div>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="last_name">
										Last Name
									</label>
									<input
										placeholder="Last Name"
										className="form-control text-capitalize"
										value={user.last_name ? user.last_name + " " : user.name}
										disabled={true}
									/>
								</div>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="first_num">
										Mobile Number
									</label>
									<input
										placeholder="Enter Mobile Number"
										className="form-control"
										value={user.phone}
										disabled={!!user.phone}
										{...register("phone", {
											required: false,
											minLength: {
												value: 10,
												message:
													"Mobile number Should be at least 10 numbers"
											},
											maxLength: {
												value: 10,
												message:
													"Mobile number maximum be at least 10 numbers"
											},
											pattern: {
												value: phoneRegExp,
												message: "Invalid Phone number"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="phone"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
								<div className="mb-4 col-md-6 col-12">
									<label className="form-label" htmlFor="first_mail">
										Email Address
									</label>
									<input
										placeholder="Enter your E-mail id"
										className="form-control"
										value={user.email}
										disabled={!!user.email}
										{...register("email", {
											required: false,
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: "Email is invalid"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="email"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
								{!user.email || !user.phone ? (
									<div className="mb-4 text-center">
										{isLoading ? (
											<button
												type="button"
												className="btn btn-primary"
												disabled={true}
											>
												<img
													src="/images/pages/home/loader.svg"
													alt={process.env.REACT_APP_NAME}
												/>
											</button>
										) : (
											<button className="btn btn-primary">
														Save
											</button>
										)}
									</div>
								) : (
									""
								)}
								{user && user.user_type === "Employee" ? (
									<>
										<div className="mb-4 col-md-6 col-12">
											<label className="form-label" htmlFor="first_mail">
												Organization Name
											</label>
											<input
												placeholder="Employee Code"
												className="form-control"
												value={
													user.organization_name
														? user.organization_name
														: "-"
												}
												disabled={true}
											/>
										</div>
										<div className="mb-4 col-md-6 col-12">
											<label className="form-label" htmlFor="first_mail">
												Employee Code
											</label>
											<input
												placeholder="Employee Code"
												className="form-control"
												value={user.employee_code}
												disabled={!!user.employee_code}
												{...register("employee_code", {
													required: false,
													pattern: {
														value: uppercaseRegExp,
														message: "Please use Uppercase letters"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name="employee_code"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
										<div className="mb-4 col-md-6 col-12">
											<label className="form-label" htmlFor="first_mail">
												Designation
											</label>
											<input
												placeholder="designation"
												className="form-control"
												value={user.designation ? user.designation : "-"}
												disabled={true}
											/>
										</div>
										<div className="mb-4 col-md-6 col-12">
											<label className="form-label" htmlFor="first_mail">
												Grade
											</label>
											<input
												placeholder="grade"
												className="form-control"
												value={user.grade ? user.grade : "-"}
												disabled={true}
											/>
										</div>
										<div className="mb-4 col-md-6 col-12">
											<label className="form-label" htmlFor="first_mail">
												Branch
											</label>
											<input
												placeholder="Branch"
												className="form-control"
												value={user.branch_name ? user.branch_name : "-"}
												disabled={true}
											/>
										</div>
										<div className="mb-4 col-md-6 col-12">
											<label className="form-label" htmlFor="first_mail">
												Reporting Person
											</label>
											<input
												placeholder="Reporting Person"
												className="form-control"
												value={
													user.reporting_person
														? user.reporting_person
														: "-"
												}
												disabled={true}
											/>
										</div>
										{!user.employee_code || !user.email || !user.phone ? (
											<div className="mb-4 text-center">
												{isLoading ? (
													<button
														type="button"
														className="btn btn-primary"
														disabled={true}
													>
														<img
															src="/images/pages/home/loader.svg"
															alt={process.env.REACT_APP_NAME}
														/>
													</button>
												) : (
													<button className="btn btn-primary">
														Save
													</button>
												)}
											</div>
										) : (
											""
										)}
									</>
								) : (
									""
								)}
							</div>
						</>
					)}
				</>
			</form>
		</>
	);
};
export default ProfileDetails;
