import React, { useContext, useEffect, useMemo, useState } from "react";
import { OrganizationAppsContext } from "../../../context/OrganizationAppsState";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import { Badge } from "react-bootstrap";
import moment from "moment";
import { RiDeleteBin6Fill, RiHistoryLine } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

const AppsTable = () => {
	const { getApps, apps, deleteApp } = useContext(OrganizationAppsContext);
	const [filterText, setFilterText] = useState({ text: "", date: "" });
	const [allLoans, setAllLoans] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const handleAppDelete = app_id => {
		if (window.confirm("Are you sure you want to remove this App")) {
			deleteApp(app_id);
		}
	};

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText.date || filterText.text) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText({ text: "", date: "" });
				setAllLoans(apps);
				getApps();
			}
		};

		const handleChange = (val, type) => {
			setFilterText({ [type]: val });
			getApps({ employee_search: val });
			// axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-applications`, {search_keyword: val}).then(
			//     (response)=>{
			//         if(response.data.response.status == 200){
			//             setAllLoans(response.data.response.loans)
			//             setResetPaginationToggle(!resetPaginationToggle)
			//         }
			//     }
			// )
		};
		return (
			<FilterComponent
				onFilter={handleChange}
				onClear={handleClear}
				filterText={filterText}
				hideDate={true}
			/>
		);
	}, [filterText, resetPaginationToggle, apps]);

	const columns = [
		{
			name: "App Name",
			selector: row => (row.app_name ? row.app_name : "-"),
			sortable: true,
			minWidth: "60%",
			marginBottom: "20px",
			cell: row => {
				return (
					<Link to={`/account/app/${row.app_enc_id}`} className="d-flex my-3">
						<div className="app-icon mx-3">
							<img
								style={{
									width: "80px",
									height: "80px",
									border: "4px solid #fff",
									boxShadow: "0px 1px 10px 1px #ddd",
									borderRadius: "6px"
								}}
								src={row.logo}
								alt={row.app_name}
							/>
						</div>
						<div className="app-description">
							<h5 className="my-2 text-capitalize text-link">{row.app_name}</h5>
							<p className="mb-1">{row.app_description}</p>
							<ul className="app-sub-action mb-0">
								{/* <li><RiFileListLine size={18}/>{row.elements_count} Forms</li>*/}
								{/* <li><BiCheckCircle size={18}/>0 Submissions</li>*/}
								<li>
									<RiHistoryLine />
									Created On:{" "}
									{moment(row.created_on).format("DD MMM YYYY h:mm a")}
								</li>
							</ul>
						</div>
					</Link>
				);
			}
		},
		{
			name: "Assigned To",
			selector: row => (row.assigned_to ? row.assigned_to : "-"),
			sortable: true,
			cell: row =>
				row.assigned_to.map((item, index) => {
					return (
						<Badge
							className="mx-1"
							key={`assigned_to_${row.app_enc_id}${index}`}
							color={item.color}
							pill
						>
							{item}
						</Badge>
					);
				})
		},
		{
			name: "Actions",
			selector: row => moment(row.created_on).format("DD MMM YYYY h:mm a"),
			sortable: true,
			cell: row => (
				<>
					<Link
						to={`/account/app/${row.app_enc_id}/edit`}
						className="btn btn-primary mx-1"
						style={{ padding: "10px 15px", color: "#fff" }}
					>
						<FaRegEdit size={18} />
					</Link>
					{/* <button className="btn btn-primary mx-1" style={{padding: '10px 15px', color: '#fff'}}>*/}
					{/*    <FaRegEdit size={18}/>*/}
					{/* </button>*/}
					<button
						onClick={() => handleAppDelete(row.app_enc_id)}
						className="btn btn-danger mx-1"
						style={{ padding: "10px 15px", color: "#fff" }}
					>
						<RiDeleteBin6Fill size={18} />
					</button>
				</>
			)
		}
	];

	useEffect(() => {
		getApps();
	}, []);

	useEffect(() => {
		setAllLoans(apps);
		setResetPaginationToggle(!resetPaginationToggle);
	}, [apps]);

	return (
		<div className="loan-application-table mb-5">
			<DataTable
				columns={columns}
				data={allLoans}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
			/>
		</div>
	);
};
export default AppsTable;
