export const statusDropdownData = {
	defaultData: [
		{
			label: "Active",
			value: "Active"
		},
		{
			label: "Inactive",
			value: "Inactive"
		},
		{
			label: "Pending",
			value: "Pending"
		}
	],

	negativeLocObj: [
		{
			label: "Accept",
			value: "Active"
		},
		{
			label: "Reject",
			value: "Inactive"
		},
		{
			label: "Pending",
			value: "Pending"
		}
	],

	statusVehicle: [
		{
			label: "Active",
			value: "Active"
		},
		{
			label: "Inactive",
			value: "Inactive"
		},
		{
			label: "Sold",
			value: "Sold"
		}
	]
};
