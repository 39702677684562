import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { authServices } from "../helpers/authServices";

const ChangePassword = () => {
	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors }
	} = useForm();
	const newpassword = watch("new_password");
	const [isLoading, setIsLoading] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
		  setIsMobile(window.innerWidth <= 768);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	  }, []);

	
	const onSubmit = async data => {
		setIsLoading(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/reset-old-password`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Password Changed Successfully");
					reset();
					authServices.logout();
				} else {
					toast.error(response.data?.response?.message ? response.data.response.message : "Old Password is incorrect");
				}
			});
	};
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className={`mb-4 col-md-6 ${!isMobile && "offset-3"}`}>
						<h3 className="mb-4 text-primary text-center">Change Password</h3>
						<label className="form-label" htmlFor="org_name">
							Old Password
						</label>
						<input
							placeholder="Enter Old Password"
							type="password"
							className="form-control"
							{...register("old_password", {
								required: "This is required."
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="old_password"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
					<div className={`mb-4 col-md-6 ${!isMobile && "offset-3"}`}>
						<label className="form-label" htmlFor="org_name">
							New Password
						</label>
						<input
							placeholder="Password"
							type="password"
							className="form-control"
							{...register("new_password", {
								required: "This is required.",
								minLength: {
									value: 8,
									message: "Password must have at least 8 characters"
								}
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="new_password"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
					<div className={`mb-4 col-md-6 ${!isMobile && "offset-3"}`}>
						<label className="form-label">Confirm Password</label>
						<input
							placeholder="Confirm Password"
							type="password"
							className="form-control"
							{...register("repeat_new_password", {
								required: "This is required.",
								validate: value =>
									value === newpassword || "The Password does not match"
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="repeat_new_password"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
					<div className="mb-4 text-center">
						{isLoading ? (
							<button type="button" className="btn btn-primary" disabled={true}>
								<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
							</button>
						) : (
							<button className="btn btn-primary">Save</button>
						)}
					</div>
				</div>
			</form>
		</>
	);
};
export default ChangePassword;
