import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";


const BasicDetails = ({ register, errors, setError, clearErrors, setNumberExist, setEmailExist }) => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const checkNumber = async value => {
		if (value && value.length >= 10) {
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/check-numbers`,
					{ phone: value }
				)
				.then(response => {
					if (response.data.response.status === 400) {
						setError("phone", {
							type: "custom",
							message: "Number Already Exists"
						});
						setNumberExist(true);
					} else {
						clearErrors("phone");
						setNumberExist(false);
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	};

	const checkEmail = async value => {
		if (value) {
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/check-email`,
					{ email: value }
				)
				.then(response => {
					if (response.data.response.status === 400) {
						setError("email", {
							type: "custom",
							message: "Email Already Exists"
						});
						setEmailExist(true);
					} else {
						clearErrors("email");
						setEmailExist(false);
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	};

	return (
		<>
			<h4>Contact Details</h4>
			<div className="row">
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
					<div className="mb-3">
						<label className="form-label mb-0" htmlFor="first_name">
							First Name <span style={{ color: "red", fontSize: "13px" }}>*</span>
						</label>
						<input
							placeholder="First Name"
							className="form-control"
							{...register("first_name", {
								required: "This is required."
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="first_name"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
					<div className="mb-3">
						<label className="form-label mb-0" htmlFor="last_name">
							Last Name <span style={{ color: "red", fontSize: "13px" }}>*</span>
						</label>
						<input
							placeholder="Last Name"
							className="form-control"
							{...register("last_name", {
								required: "This is required."
							})}
						/>
						<ErrorMessage
							errors={errors}
							name="last_name"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
					<div className="mb-3">
						<label className="form-label mb-0" htmlFor="email">
							Email
						</label>
						<input
							placeholder="Email"
							className="form-control"
							{...register("email", {
								required: false,
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Email is invalid"
								}
							})}
							onWheel={e => e.target.blur()}
							onBlur={e => {
								checkEmail(e.target.value);
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="email"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
					<div className="mb-3">
						<label className="form-label mb-0" htmlFor="last_name">
							Phone Number <span style={{ color: "red", fontSize: "13px" }}>*</span>
						</label>
						<div className="d-flex">
							<input
								style={{
									width: "50px",
									padding: "8px",
									height: "49px",
									marginRight: "-4px"
								}}
								type="text"
								value="+91"
								className="form-control"
								disabled={true}
							/>
							<input
								style={{
									borderLeftColor: "transparent",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0
								}}
								type="tel"
								placeholder="Phone"
								className="form-control"
								{...register("phone", {
									required: "This is required.",
									minLength: {
										value: 10,
										message:
											"Mobile number Should be at least 10 numbers"
									},
									maxLength: {
										value: 10,
										message:
											"Mobile number maximum be at least 10 numbers"
									},
									pattern: {
										value: phoneRegExp,
										message: "Invalid Phone number"
									}
								})}
								onWheel={e => e.target.blur()}
								onBlur={e => {
									checkNumber(e.target.value);
								}}
							/>
						</div>
						<ErrorMessage
							errors={errors}
							name="phone"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default BasicDetails;
