import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsFillCameraFill } from "react-icons/bs";
import Compressor from "compressorjs";
import { jsPDF } from "jspdf";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { Col } from "react-bootstrap";
import { getLatLong, isLocationEnabled } from "../../helpers/customFunctions";

let Pagewidth2;
let doc2;
let Pageheight2;
let pdf_uri2;
let base_uri2 = "";
const CaptureImageForm = ({ rawData, setHideModal, reload }) => {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors }
	} = useForm();
	const fiType = watch("fi_type");
	const customFi = watch("custom_fi");
	const [editingComplete, setEditingComplete] = useState(false);
	const [previewImages, setPreviewImages] = useState([]);
	const { handlePageLoaded } = useContext(MainContext);

	useEffect(() => {
		setValue("custom_fi", "");
	}, [fiType]);
	const onSubmit = async data => {
		handlePageLoaded(true);
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			handlePageLoaded(false);
			return;
		}
		try {
			const { latitude, longitude } = await getLatLong();
			const formData = new FormData();
			formData.append("loan_id", rawData.loan_app_enc_id);
			formData.append("file", data);
			formData.append("type", "3");
			formData.append("assigned_to", "2");
			formData.append("document_type", fiType);
			formData.append("short_description", customFi);
			formData.append("latitude", latitude);
			formData.append("longitude", longitude);
			const response = await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/upload-document`,
					formData
				);
			handlePageLoaded(false);
			setEditingComplete(true);
			if (response.data.response.status === 200) {
				toast.success("Submit Successfully");
				reset();
				// openSideBar(rawData.loan_app_enc_id);
			} else {
				toast.error("An error has occurred, Please try again");
			}
		} catch (error) {
			handlePageLoaded(false);
			console.log(error);
			toast.error(error.message);
		}
	};
	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		// base = result_base64;
		base_uri2 = result_base64;
		// base_uri2 = base;
		return result_base64;
	};
	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};
	const callback = async (width, height, src, i) => {
		let result_base64 = await new Promise(resolve => {
			const widthRatio = Pagewidth2 / width;
			const heightRatio = Pageheight2 / height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
			const canvasWidth = width * ratio;
			const canvasHeight = height * ratio;
			const marginX = (Pagewidth2 - canvasWidth) / 2;
			const marginY = (Pageheight2 - canvasHeight) / 2;
			doc2.addImage(src, "JPEG", marginX, marginY, canvasWidth, canvasHeight, i);

			pdf_uri2 = doc2.output("blob");
			resolve(true);
		});
		return result_base64;
	};
	const createPDF = async imgData => {
		if (imgData.length == "undefined" || imgData.length === 0) {
			alert("select image");
			return false;
		}
		let result_base64 = await new Promise(resolve => {
			doc2 = new jsPDF("p", "pt", "a4");
			Pagewidth2 = doc2.internal.pageSize.width;
			Pageheight2 = doc2.internal.pageSize.height;

			const img = new Image();
			img.src = imgData;
			img.length = imgData.length;
			img.onload = function () {
				img.imgWidth = img.naturalWidth;
				img.imgHeight = img.naturalHeight;
				callback(img.imgWidth, img.imgHeight, img.src, img.i, img.length).then(() => {
					resolve(true);
				});
			};
		});
		return result_base64;
	};
	const imagePreviews = [];
	const handleCapture = target => {
		if (target.files) {
			if (target.files.length !== 0) {
				handlePageLoaded(true);
				const files = target.files;
				// const newUrl = URL.createObjectURL(file);
				// setSource(newUrl);

				for (let i = 0; i < files.length; i++) {
					const file = files[i];
					const reader = new FileReader();
					reader.onload = async e => {
						const result = e.target.result;
						imagePreviews.push(result);
						if (imagePreviews.length === files.length) {
							setPreviewImages([...previewImages, ...imagePreviews]);
						}
					};
					reader.readAsDataURL(file);
					setEditingComplete(false);
					getUri(file).then(() => {
						createPDF(base_uri2).then(() => {
							onSubmit(pdf_uri2);
						});
					});
				}
			}
		}
	};

	const handleModalHide = () =>{
		setHideModal();
		reload();
	};

	return (
		<div className="col-12">
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-12 col-12 mb-3">
						<label className="form-label" htmlFor="loan_amt">
							Select Type
						</label>
						<div className="radio-wrapper simple" style={{ width: "100%" }}>
							<div className="radio-item">
								<input
									type="radio"
									name="fi_type"
									className="radioInput"
									id="BDO_FI"
									value="BDO FI"
									{...register("fi_type", {
										required: "This is required."
									})}
								/>
								<label htmlFor="BDO_FI" className="option">
									<span>BDO FI</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="fi_type"
									className="radioInput"
									id="BM_FI"
									value="BM FI"
									{...register("fi_type", {
										required: "This is required."
									})}
								/>
								<label htmlFor="BM_FI" className="option dents-2">
									<span>BM FI</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="fi_type"
									className="radioInput"
									id="Credit_FI"
									value="Credit FI"
									{...register("fi_type", {
										required: "This is required."
									})}
								/>
								<label htmlFor="Credit_FI" className="option">
									<span>Credit FI</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="fi_type"
									className="radioInput"
									id="Collection_FI"
									value="Collection FI"
									{...register("fi_type", {
										required: "This is required."
									})}
								/>
								<label htmlFor="Collection_FI" className="option dents-2">
									<span>Collection FI</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="fi_type"
									className="radioInput"
									id="Property_Image"
									value="Property Image"
									{...register("fi_type", {
										required: "This is required."
									})}
								/>
								<label htmlFor="Property_Image" className="option dents-2">
									<span>Property Image</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="fi_type"
									className="radioInput"
									id="other_fi"
									value="Other"
									{...register("fi_type", {
										required: "This is required."
									})}
								/>
								<label htmlFor="other_fi" className="option dents-2">
									<span>Others</span>
								</label>
							</div>
						</div>
					</div>
					{fiType === "Other" ? (
						<div className="col-md-6 col-12 mb-3">
							{/* <div className="mb-3">*/}
							<label className="form-label">Enter Type</label>
							<input
								type="text"
								placeholder="Enter Type"
								className="form-control"
								{...register("custom_fi", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="custom_fi"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
							{/* </div>*/}
						</div>
					) : (
						""
					)}
					{(fiType && fiType !== "Other" && !customFi) ||
					(fiType === "Other" && customFi) ? (
							<div className="col-md-6 col-12 mb-3">
								<label className="form-label">Image</label>
								<label
									className="form-control text-center"
									style={{
										backgroundColor: "#00a0e3",
										color: "#fff",
										cursor: "pointer"
									}}
									htmlFor="captureImage"
								>
									<BsFillCameraFill /> Capture Image
								</label>
								<input
									id="captureImage"
									accept="image/*"
									placeholder="Capture Image"
									className="form-control d-none"
									{...register("images", {
										required: "This is required."
									})}
									type="file"
									name="images"
									capture="environment"
									onChange={e => handleCapture(e.target)}
								/>
								<ErrorMessage
									errors={errors}
									name="images"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						) : (
							""
						)}

					{previewImages.length != 0 ? (
						<div>
							<div>
								<label className="form-label" htmlFor="selected_image">
									Recently Uploaded
								</label>
							</div>
							<div>
								{previewImages.map((image, index) => (
									<img
										key={index}
										src={image}
										name="selected_image"
										className="previewImages"
										alt={`Preview ${index}`}
										style={{
											width: "100px",
											height: "100px",
											objectFit: "cover",
											margin: "0 1% 1%",
											borderRadius: "5%"
										}}
									/>
								))}
							</div>
						</div>
					) : (
						""
					)}

					{editingComplete && !fiType ? (
						<Col
							size={12}
							className="text-center mt-2"
							onClick={handleModalHide}
						>
							<button type="button" className="btn btn-primary">
								Done
							</button>
						</Col>
					) : (
						""
					)}
				</div>
			</form>
		</div>
	);
};
export default CaptureImageForm;
