import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { MainContext } from "../../context/MainState";

const AccessDenied = ({title = "Page Not Found", statusType }) => {
	const { user } = useContext(MainContext);

	return(
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={title} />
			</Helmet>

			<div className="container">
				<div className="row">
					<div className="col-md-12 mt-5">
						<div className="bg-white mb-10 rounded-3 shadow-sm p-10 position-relative">
							<div className="row">
								<div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12">
									<div className="mb-8">
										<img
											src="/images/background/error-img.png"
											className="img-fluid"
											alt="Not Found Image"
										/>
									</div>
									<div className="text-center">
										<h2 className="text-primary text-uppercase">Sorry</h2>
										<h1 className="mb-2 fw-semi-bold">{title}</h1>
										{statusType === "checkStatus" ? (
											<p className="mb-5">
												The page you were trying to reach could not be found
												on the server, or that you typed in the URL incorrectly.
											</p>
										) : (
											<p className="mb-5">
												The Application you were trying to reach has been removed. 
												To retrieve the Application, Contact the subsequent Credit 
												Head or Operation Manager.
											</p>
										)}
										{user && user.access_token ? (
											<Link to="/account/dashboard" className="btn btn-primary">
												Go to Dashboard
											</Link>
										) : (
											<Link to="/" className="btn btn-primary">
												Go to Homepage
											</Link>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AccessDenied;