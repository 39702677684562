import axios from "axios";
import { Modal} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AutoComplete from "../forms/AutoComplete";
import { ErrorMessage } from "@hookform/error-message";
import { useState, useEffect, useContext } from "react";
import Compressor from "compressorjs";
import { MainContext } from "../../context/MainState";
import { BiCheck, BiRupee } from "react-icons/bi";
import DataTable from "react-data-table-component";
import EmiCollectedCashSidebarData from "./EmiCollectedCashSidebarData";
import moment from "moment";
import { IoCloseCircle } from "react-icons/io5";

const CashModalDashboard = ({ cashCollect, setCashCollect, emiListData,getEmiList}) => {
	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
		setValue
		// clearErrors
	} = useForm();
	const { handlePageLoaded, user } = useContext(MainContext);
	const [compressedFile, setCompressedFile] = useState("");
	const depositTo = watch("type");
	let base_uri2 = "";

	const compressImage = input => {
		if (!input.target.files) {
			return false;
		}
		const file = input.target.files[0];
		const fileExtenstion = file.name.split(".").pop().toLowerCase();
		if (fileExtenstion === "png" || fileExtenstion === "jpg" || fileExtenstion === "jpeg") {
			getUri(file).then(() => {
				setCompressedFile(base_uri2);
			});
		} else {
			const fileReader = new FileReader();
			let base64;
			// Onload of file read the file content
			fileReader.onload = function (fileLoadedEvent) {
				base64 = fileLoadedEvent.target.result;
				// Print data in console
				console.log(base64);
				setCompressedFile(base64);
			};
			// Convert data to base64
			fileReader.readAsDataURL(file);
		}
	};

	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};

	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		// base = result_base64;
		base_uri2 = result_base64;
		// base_uri2 = base;
		return result_base64;
	};

	const columns = [
		{
			name: "Total Amount",
			minWidth: "150px",
			sortable: false,
			cell: row => {
				return (
					<p className="m-0">
						<BiRupee size={16} />{parseFloat(row.remaining_amount).toLocaleString("en-IN")}
					</p>
				);
			}
		},
		{
			name: "Received From",
			minWidth: "160px",
			selector: row => (row.received_from ? row.received_from : "-"),
			sortable: true,
		},
		{
			name: "Received On",
			minWidth: "160px",
			sortable: true,
			cell: row => {
				return (
					<p className="m-0">
						{moment(row.created_on).format(
							"DD MMM YYYY h:mm a"
						)}
					</p>
				);
			}
		},
	];
    
	const modalData = emiListData.display_data;

	const onSubmit = async values => {
		if (depositTo === "1") {
			delete values.given_to;
		}
		if (depositTo === "2" && !values.given_to) {
			toast.error("Please Enter Employee Name");
			return false;
		}
		if (depositTo === "1" && !compressedFile) {
			toast.error("Please Choose Receipt");
			return false;
		}
		handlePageLoaded(true);
		const formData = new FormData();
		Object.entries(values).map(([key, value]) => {
			formData.append(key, value);
		});
		const cashId = [...emiListData.data.map(data => data.cash_report_enc_id), ...emiListData.received_cash.map(data => data.cash_report_enc_id)];
		formData.append("cash_ids", cashId);
		formData.append("amount", modalData.total_sum);
		formData.append("received_from", user.user_enc_id);
		formData.append("receipt", compressedFile);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/collect-cash`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Saved Successfully");
					handleResetForm();
					getEmiList();
				} else {
					toast.error(
						response.data?.response?.error
							? response.data.response.error.toString()
							: "An error has occurred, Please try again"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("An error has occurred", error);
			});
	};

	const handleResetForm = () => {
		reset();
		setCashCollect(false);
		setCompressedFile("");
	};
	useEffect(() => {
		if (depositTo === "2") {
			setCompressedFile("");
		}
	}, [depositTo]);

	return (
		<>
			<Modal show={cashCollect} onHide={() => handleResetForm()} size={"lg"} centered>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<h3 className="text-center mb-3">Submit Cash <span className="close-icon" onClick={() => handleResetForm()}><IoCloseCircle /></span></h3>
						<div className="row">
							<div className="col-lg-6 col-sm-12">
								<label htmlFor="float-text" className="d-block fw400 ft14">
									Deposit To <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper hero-radio-wrapper mb-4">
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-1"
											value="1"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-1" className="option option-1">
											<div className="dot"></div>
											<span>Bank</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-2"
											value="2"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-2" className="option option-6">
											<div className="dot"></div>
											<span>Employee</span>
										</label>
									</div>
									<ErrorMessage
										errors={errors}
										name="type"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px", width: "100%"}}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							{depositTo === "1" ? (
								<>
									<div className="col-lg-6 col-sm-12 mb-4">
										<label
											className="floating-label fw400 ft14"
											htmlFor="pr_receipt"
										>
											Reference Number <span style={{ color: "red" }}>*</span>
										</label>
										<input
											type="text"
											placeholder="Enter Reference Number"
											className="form-control"
											{...register("reference_number", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="reference_number"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="col-lg-6 col-sm-12 mb-4">
										<label
											className="floating-label fw400 ft14"
											htmlFor="pr_receipt"
										>
											Choose Receipt <span style={{ color: "red" }}>*</span>
										</label>
										<input
											id="pr_receipt"
											accept=".png, .jpg, .jpeg, .pdf"
											placeholder="Capture Image"
											className="form-control"
											type="file"
											capture="environment"
											onChange={e => compressImage(e)}
										/>
									</div>
								</>
							) : (
								""
							)}
							{depositTo === "2" ? (
								<div className="col-lg-6 col-sm-12">
									<div className="search-box-auto mb-4">
										<label
											htmlFor="float-text"
											className="floating-label fw400 ft14"
										>
											Search Employee <span style={{ color: "red" }}>*</span>
										</label>
										<AutoComplete
											setFieldValue={setValue}
											link={
												"company-dashboard/employee-search?type=employees&employee_search="
											}
											name={"given_to"}
											method={"GET"}
											placeholder={"Enter Username"}
											{...register("given_to", {})}
										/>
									</div>
								</div>
							) : (
								""
							)}
							{modalData && modalData.emi !== "0" ? (
								<div className="col-12 mb-3">
									<div className="d-flex align-items-center justify-content-between fs-6">
										<label className="floating-label fw400 ft14 px-2 py-1 bg-primary text-white">
										EMI&#39;s Amount : 
											<span className="fw-bold">
												<BiRupee size={14} />
												{modalData && modalData.emi
													? parseFloat(
														modalData.emi
													).toLocaleString("en-IN")
													: "0"}
											</span>
										</label>
										<label className="floating-label fw400 ft14 px-2 py-1 bg-primary text-white">
										Total EMI&#39;s :   {" "}
											<span className="fw-bold">
												{modalData && modalData.emi_count ? modalData.emi_count : "0"}
											</span>
										</label>
									</div>
									<div className="border cash-modal-box p-3">
										<div className="row">
											{emiListData.data && emiListData.data.length ? (
												emiListData.data.map((value, index) => {
													return (
														<div className="col-lg-4 col-sm-6 col-12 px-2" key={`CashInHand${index}`}>
															<div className="cash-main-content mb-3 p-2 shadow-ell fs-7 position-relative">
																<BiCheck size={20} color="#fff" className="check-btn position-absolute r-0 top-0 bg-primary rounded-5"/>
																<p className="mb-1">
																	Loan No.{" "}
																	<span className="fw-medium text-gray-700">
																		{value.loan_account_number}
																	</span>
																</p>
																<p className="mb-1" style={{ WebkitLineClamp: 1, display: "-webkit-box", WebkitBoxOrient: "vertical", overflow: "hidden" }}>
																	Name:{" "}
																	<span className="fw-medium text-gray-700 text-capitalize">{value.customer_name}</span>
																</p>
																<p className="mb-1">
																	Loan Type:{" "}
																	<span className="fw-medium text-gray-700">{value.loan_type}</span>
																</p>
																<p className="mb-1">
																	Amount:{" "}
																	<span className="fw-bold text-primary">
																		<BiRupee size={12} />
																		{parseFloat(value.amount).toLocaleString("en-IN")}
																	</span>
																</p>
															</div>
														</div>
													);
												})
											) : (
												<tr>
													<td colSpan="3">
														<div className="not-branch text-center">
															No Pending EMI&#39;s
														</div>
													</td>
												</tr>
											)}
										</div>
									</div>
								</div>
							) : ""}
							{modalData && modalData.cash !== null ? (
								<div className="col-12 mb-4">
									<label className="floating-label fw400 ft14 px-2 py-1 bg-primary text-white">
											Received Amount :
										<span className="fw-bold"> 
											<BiRupee size={14} />
											{modalData && modalData.cash
												? parseFloat(
													modalData.cash
												).toLocaleString("en-IN")
												: "0"}
										</span>
									</label>
									<div className="border">
										<DataTable
											noHeader
											columns={columns}
											striped
											data={emiListData.received_cash && emiListData.received_cash.length ? emiListData.received_cash : []}
											expandableRows 
											expandableRowsComponent={EmiCollectedCashSidebarData}
											progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
											noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
										/>
									</div>
								</div>
							) : ""}
							
							<div className="col-12 mb-4">
								<label htmlFor="float-text" className="floating-label fw400 ft14">
									Remarks
								</label>
								<textarea
									className="form-control"
									rows="3"
									placeholder="Enter you comments"
									{...register("remarks", {
										required: false
									})}
								></textarea>
							</div>
							<div className="text-center mb-2">
								Total Amount : {" "}
								<span className="fw-bold text-primary">
									<BiRupee size={16} /> 
									{modalData && modalData.total_sum
										? parseFloat(
											modalData.total_sum
										).toLocaleString("en-IN")
										: "0"}
								</span>
							</div>
							<div className="text-center">
								<button className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default CashModalDashboard;
