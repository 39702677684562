import { Helmet } from "react-helmet";
import HealthTable from "./HealthTable";
import Navbar from "../../components/Navbar";

const HealthRequests = () => {
	return (
		<>
			<Helmet>
				<title>Health Requests</title>
				<meta name="description" content="Loan Accounts" />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
						<h4 className="mb-3">Health Requests</h4>
						<HealthTable />
					</div>
				</div>
			</div>
		</>
	);
};

export default HealthRequests;
