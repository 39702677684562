import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { FiUsers } from "react-icons/fi";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { RiFlag2Fill } from "react-icons/ri";
import { IoCloseCircle } from "react-icons/io5";
import { readOnlyUsers } from "../../helpers/authServices";

const InitiatePd = ({ loanDetails, reload }) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const [show, setShow] = useState(false);
	const [tvrStatus, setTvrStatus] = useState(false);
	const [pdStatus, setPdStatus] = useState({ id: "", status: "" });
	const [dates, setDates] = useState(["", "", ""]);
	const [showDates, setShowDates] = useState([]);

	const initiatePd = status => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (status === 1) {
			if (!window.confirm("Are you sure you want to change the status?")) {
				return false; // User cancelled the action
			}
		} else {
			if (dates.indexOf("") !== -1) {
				toast.error("Please Choose all dates");
				return false;
			}
		}

		let data = {
			loan_app_enc_id: loanDetails.loan_app_enc_id,
			type: 0,
			status: status,
			dates: dates.length > 0 ? JSON.stringify(dates) : ""
		};

		if (pdStatus.id) {
			data["loan_application_pd_enc_id"] = pdStatus.id;
		}

		handlePageLoaded(true);

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-pd`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success(`PD ${status === 0 ? "Initiated" : "Completed"} Successfully`);
					reload(loanDetails.loan_app_enc_id);
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "PD Initiated Error"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	const handleDateChange = (index, value) => {
		const selectedDate = new Date(value);
		const currentDate = new Date();

		if (selectedDate >= currentDate) {
			const updatedDates = [...dates];

			// Check if the selected date already exists in earlier selections
			if (updatedDates.includes(moment(selectedDate).format("YYYY-MM-DD"))) {
				toast.error("You cannot select the same date again.");
				return false;
			}

			// Update the selected date in the array
			updatedDates[index] = moment(selectedDate).format("YYYY-MM-DD");
			setDates(updatedDates);
			return true;
		} else {
			toast.error("You cannot select this date.");
			return false;
		}
	};

	useEffect(() => {
		const today = new Date();
		const tomorrowDate = new Date(today);
		tomorrowDate.setDate(today.getDate() + 1); // tomorrow's date

		// Check if the time is before 2 PM
		if (today.getHours() <= 14) {
			today.setDate(today.getDate() + 1);
		} else {
			today.setDate(today.getDate() + 2);
		}

		const dates = [];

		// dates for the next 7 days, excluding Sundays
		for (let i = 0; dates.length < 7; i++) {
			const currentDate = new Date(today);
			currentDate.setDate(currentDate.getDate() + i);

			// if the current date is not a Sunday
			if (currentDate.getDay() !== 0) {
				dates.push(currentDate);
			}
		}

		// if it's the last day of the month
		if (dates[dates.length - 1].getDate() === new Date().getDate()) {
			const nextMonth = new Date(today);
			nextMonth.setMonth(nextMonth.getMonth() + 1);
			nextMonth.setDate(1); // first day of next month
			while (dates.length < 7) {
				dates.push(nextMonth);
				nextMonth.setDate(nextMonth.getDate() + 1);
			}
		}

		// if it's the last day of the year
		if (dates[dates.length - 1].getDate() === 31 && dates[dates.length - 1].getMonth() === 11) {
			const nextYear = new Date(today);
			nextYear.setFullYear(nextYear.getFullYear() + 1);
			nextYear.setMonth(0); // First month of the next year
			nextYear.setDate(1); // First day of the next year
			while (dates.length < 7) {
				dates.push(nextYear);
				nextYear.setDate(nextYear.getDate() + 1);
			}
		}

		setShowDates(dates);
	}, []);

	useEffect(() => {
		if (
			loanDetails &&
			loanDetails.loanApplicationTvrs &&
			loanDetails.loanApplicationTvrs.length
		) {
			const tempStatus2 = loanDetails.loanApplicationTvrs.filter(item => item.status === "1");
			if (tempStatus2.length) {
				setTvrStatus(true);
			}
		}
		if (
			loanDetails &&
			loanDetails.apply_date &&
			moment(loanDetails.apply_date).isBefore("2023-07-24")
		) {
			setTvrStatus(true);
		}
		if (
			loanDetails &&
			loanDetails.loanApplicationPds &&
			loanDetails.loanApplicationPds.length
		) {
			const tempStatus = loanDetails.loanApplicationPds;
			if (tempStatus.length) {
				setPdStatus({
					id: tempStatus[0].loan_application_pd_enc_id,
					status: tempStatus[0].status
				});
			}
		}
	}, [loanDetails]);
	return (
		<>
			{tvrStatus ? (
				<>
					{pdStatus.status === "1" ? (
						<button type="button" className="btn btn-success">
							<FiUsers /> PD Completed
						</button>
					) : (
						<>
							{pdStatus.status === "0" ? (
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => initiatePd(1)}
								>
									<FiUsers /> PD Initiated
								</button>
							) : (
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => setShow(true)}
								>
									<FiUsers /> Initiate PD
								</button>
							)}
						</>
					)}
				</>
			) : (
				<button type="button" className="btn btn-primary" disabled={true}>
					<FiUsers /> Initiate PD
				</button>
			)}

			<Modal size={"lg"} show={show} onHide={setShow}>
				<Modal.Header>
					<Modal.Title>Select Dates</Modal.Title><span className="close-icon" onClick={()=>setShow(false)}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<div className="col-lg-12 col-12 mb-3">
						<div className="mb-3">
							<div className="pd-dates-main">
								<span>
									<RiFlag2Fill
										color="#008000"
										style={{ marginRight: "10px", fontSize: "20px" }}
									/>
									<label className="form-label fw-bold">First Date</label>
								</span>
								<div className="radio-wrapper" style={{ marginBottom: "12px" }}>
									{showDates.map((val, index) => {
										return (
											<div className="radio-item" key={`pds-date-${index}`}>
												<input
													type="radio"
													name="date1"
													className="radioInput"
													id={`first-date-${index}`}
													value={val}
													checked={
														dates.length &&
														dates[0] ===
															moment(val).format("YYYY-MM-DD")
													}
													onChange={e =>
														handleDateChange(0, e.target.value)
													}
												/>
												<label
													htmlFor={`first-date-${index}`}
													className="option option-1"
													style={{
														marginBottom: "10px",
														marginLeft: "0"
													}}
												>
													<span>{moment(val).format("DD MMM YYYY")}</span>
												</label>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="mb-3">
							<div className="pd-dates-main">
								<span>
									<RiFlag2Fill
										color="#FFFF00"
										style={{ marginRight: "10px", fontSize: "20px" }}
									/>
									<label className="form-label fw-bold">Second Date</label>
								</span>
								<div className="radio-wrapper" style={{ marginBottom: "12px" }}>
									{showDates.map((val, index) => {
										return (
											<div className="radio-item" key={`pds-date-${index}`}>
												<input
													type="radio"
													name="date2"
													className="radioInput"
													id={`second-date-${index}`}
													value={val}
													checked={
														dates.length &&
														dates[1] ===
															moment(val).format("YYYY-MM-DD")
													}
													onChange={e =>
														handleDateChange(1, e.target.value)
													}
												/>
												<label
													htmlFor={`second-date-${index}`}
													className="option option-1"
													style={{
														marginBottom: "10px",
														marginLeft: "0"
													}}
												>
													<span>{moment(val).format("DD MMM YYYY")}</span>
												</label>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="mb-3">
							<div className="pd-dates-main">
								<span>
									<RiFlag2Fill
										color="#FF0000"
										style={{ marginRight: "10px", fontSize: "20px" }}
									/>
									<label className="form-label fw-bold">Third Date</label>
								</span>
								<div className="radio-wrapper" style={{ marginBottom: "12px" }}>
									{showDates.map((val, index) => {
										return (
											<div className="radio-item" key={`pds-date-${index}`}>
												<input
													type="radio"
													name="date3"
													className="radioInput"
													id={`third-date-${index}`}
													value={val}
													checked={
														dates.length &&
														dates[2] ===
															moment(val).format("YYYY-MM-DD")
													}
													onChange={e =>
														handleDateChange(2, e.target.value)
													}
												/>
												<label
													htmlFor={`third-date-${index}`}
													className="option option-1"
													style={{
														marginBottom: "10px",
														marginLeft: "0"
													}}
												>
													<span>{moment(val).format("DD MMM YYYY")}</span>
												</label>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => initiatePd(0)}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default InitiatePd;
