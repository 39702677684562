import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { toast } from "react-toastify";
import AutoComplete from "../../components/forms/AutoComplete";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import Compressor from "compressorjs";

const ReEmpowerMobile = () => {
	const history = useNavigate();
	const { getBrandsList, brands } = useContext(MainContext);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors }
	} = useForm();
	const any_dent = watch("any_dent");
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const [selectedImagesDent, setSelectedImagesDent] = useState([]);
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "Active",
		label: "Active"
	});
	const [imagesVal, setImagesVal] = useState([]);
	const [dentImagesVal, setDentImagesVal] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [brandModels, setBrandModels] = useState("");
	const [modelId, setModelId] = useState("");
	const [modelSelection, setModelSelection] = useState("");
	const [updatedProduct, setUpdatedProduct] = useState("");

	const handleUpload = (e, type) => {
		let AllFiles = [];
		let testFiles = [];
		[...e.target.files].map(file => {
			AllFiles.push(URL.createObjectURL(file));
			new Compressor(file, {
				quality: 0.6,
				// Files whose file type is included in this list, and whose file size exceeds the convertSize value will be converted to JPEGs.
				// convertTypes:["image/png"],
				// Files whose file type is included in the convertTypes list, and whose file size exceeds this value will be converted to JPEGs. To disable this, just set the value to Infinity.
				convertSize: 1, // Default: 5000000 (5 MB)
				success(result) {
					testFiles.push(result);
				},
				error(err) {
					console.log(err.message);
				}
			});
		});
		if (type === "scratches") {
			setDentImagesVal(testFiles);
			setSelectedImagesDent(AllFiles);
		} else {
			setImagesVal(testFiles);
			setSelectedImages(AllFiles);
		}
	};

	const addModelName = e => {
		if (modelSelection.length && modelSelection[0]["model_enc_id"]) {
			setModelId(modelSelection[0]["model_enc_id"]);
		} else {
			let data = { model: e, brand_id: selectedOptions["brand_enc_id"] };
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/add-model`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						setModelId(response.data.response.model_id);
						setBrandModels([
							...brandModels,
							{
								model_enc_id: response.data.response.model_id,
								value: response.data.response.model_id,
								label: response.data.response.model_name
							}
						]);
						setModelSelection([
							{
								model_enc_id: response.data.response.model_id,
								value: response.data.response.model_id,
								label: response.data.response.model_name
							}
						]);
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	};
	const getModelSelection = selection => {
		setModelSelection(selection);
	};
	const onSubmit = values => {
		setFormIsLoading(true);
		const formData = new FormData();
		imagesVal.map((value, i) => {
			formData.append(`images[${i}]`, value);
		});
		dentImagesVal.map((value, i) => {
			formData.append(`dent_images[${i}]`, value);
		});
		formData.append("model_id", modelId);
		formData.append("status", selectedStatusModel.value);
		formData.append("assigned_category", "Mobiles");
		if (updatedProduct) {
			formData.append("product_name", updatedProduct.name);
			formData.append("product_enc_id", updatedProduct.product_enc_id);
		} else {
			formData.append(
				"product_name",
				selectedOptions.label + " " + modelSelection[0]["label"]
			);
		}
		[
			"ram",
			"rom",
			"price",
			"front_camera",
			"sim_type",
			"back_camera",
			"screen_size",
			"city_id",
			"ownership_type"
		].forEach(e => {
			formData.append(e, values[e]);
			delete values[e];
		});
		delete values["images"];
		delete values["dent_images"];
		formData.append("product_other_detail", JSON.stringify(values));

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${
					process.env.REACT_APP_API_URL_VERSION
				}products/${updatedProduct ? "update" : "add"}`,
				formData
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Mobile Phone Added Successfully");
					reset();
					setDentImagesVal([]);
					setBrandModels([]);
					setModelSelection("");
					setImagesVal([]);
					setSelectedImagesDent([]);
					setSelectedOptions([]);
					setSelectedImages([]);
					history("/account/re-empower/mobiles");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setFormIsLoading(false);
			});
	};

	let params = useParams();
	const getProductDetails = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/get-product-details`,
				{ slug: params.slug }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					let tempData = response.data.response.products;
					setSelectedOptions({ value: tempData.brand, label: tempData.brand });
					if (tempData.productOtherDetails.length) {
						const other_detail = JSON.parse(
							tempData.productOtherDetails[0].other_detail
						);
						tempData["ownership_type"] = tempData.productOtherDetails[0].ownership_type;
						tempData["other_detail"] = {
							...other_detail,
							...tempData.productOtherDetails[0]
						};
					}
					let brand_models = brands.filter(item => item.label === tempData.brand);
					setBrandModels(brand_models[0].brandModels);
					let modelIndex = brand_models[0].brandModels.findIndex(
						models => models.label == tempData.model
					);
					tempData["modelIndex"] = modelIndex;
					setUpdatedProduct(tempData);

					setModelId(tempData.model_id);
					let mainImages = tempData.productImages.filter(item => item.type === "default");
					setSelectedImages(mainImages);
					let dentImages = tempData.productImages.filter(item => item.type === "defect");
					setSelectedImagesDent(dentImages);
					setValue("imei_number", tempData.other_detail.imei_number);
					setValue("color", tempData.other_detail.color);
					setValue("ram", tempData.other_detail.ram);
					setValue("rom", tempData.other_detail.rom);
					setValue("screen_size", tempData.other_detail.screen_size);
					setValue("back_camera", tempData.other_detail.back_camera);
					setValue("front_camera", tempData.other_detail.front_camera);
					setValue("sim_type", tempData.other_detail.sim_type);
					setValue("city_id", tempData.city_enc_id);
					setValue("price", parseFloat(tempData.price));
					setValue("warranty", tempData.other_detail.warranty);
					setValue("insured", tempData.other_detail.insured);
					setValue("status", tempData.status);
				}
			});
	};
	let imeiRegex = /^[0-9]{15}$/;
	useEffect(() => {
		getBrandsList("mobile");
	}, []);
	useEffect(() => {
		if (params.slug && brands) {
			getProductDetails();
		}
	}, [params.slug, brands]);
	return (
		<>
			<Helmet>
				<title>Re Empower Mobile</title>
				{/* <meta name="description" content="Tutorial for React Helmet"/>*/}
			</Helmet>
			<Navbar />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-10 bg-white py-5 px-10 mb-10 mt-5">
						<div className="row">
							<h1 className="text-center mb-5">Mobile Details</h1>
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="mobile-basic-detail">
									<div className="row">
										<h3 className="text-primary">Basic Details</h3>
										<div className="col-md-12 col-12">
											<div className="col-md-4 col-12 mb-4">
												<label className="form-label" htmlFor="loan_amt">
													Mobile Images{" "}
													<span style={{ color: "red" }}>*</span>
												</label>
												<input
													type="file"
													multiple={true}
													min={1}
													accept=".png, .jpg, .jpeg"
													placeholder="Choose File"
													className="form-control"
													{...register("images", {
														required: updatedProduct
															? false
															: "This is required."
													})}
													onChange={e => handleUpload(e, "default")}
												/>

												<ErrorMessage
													errors={errors}
													name="images"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
												<p className="fs-6 mt-2">
													Note: Multiple images can be selected only once.
													Images must be in the JPG, JPEG or PNG format.
												</p>
											</div>
											<div className="uploaded-images mb-6">
												{selectedImages.map((data, index) => {
													return data.image_link ? (
														<img
															src={data.image_link}
															key={`defaultImages${index}`}
															alt=""
														/>
													) : (
														<img
															src={data}
															key={`defaultImages${index}`}
															alt=""
														/>
													);
												})}
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Brand <span style={{ color: "red" }}>*</span>
												</label>
												<Select
													className="customStyleSelect"
													options={brands && brands.length ? brands : []}
													value={selectedOptions}
													name="brand_name"
													placeholder="Choose Brand"
													isDisabled={updatedProduct ? true : ""}
													onChange={option => {
														setBrandModels(option["brandModels"]);
														setSelectedOptions(option);
														// setModelSelection('')
													}}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Model Name{" "}
													<span style={{ color: "red" }}>*</span>
												</label>
												{brandModels ? (
													<>
														{updatedProduct.model_id ? (
															<>
																<Typeahead
																	allowNew
																	disabled={true}
																	id="model_name"
																	options={brandModels}
																	defaultSelected={brandModels.slice(
																		updatedProduct.modelIndex,
																		updatedProduct.modelIndex +
																			1
																	)}
																	newSelectionPrefix=""
																	onChange={getModelSelection}
																	onBlur={e =>
																		addModelName(e.target.value)
																	}
																	placeholder="Select Your Device"
																/>
															</>
														) : !params.slug ? (
															<>
																<Typeahead
																	allowNew
																	id="model_name"
																	options={brandModels}
																	newSelectionPrefix=""
																	onChange={getModelSelection}
																	onBlur={e =>
																		addModelName(e.target.value)
																	}
																	placeholder="Select Your Device"
																/>
															</>
														) : (
															<input
																placeholder="Select Your Device"
																className="form-control"
																{...register("model_name", {
																	required: "This is required."
																})}
																onBlur={e =>
																	addModelName(e.target.value)
																}
															/>
														)}
														<ErrorMessage
															errors={errors}
															name="model_name"
															render={({ message }) => (
																<p
																	style={{
																		color: "red",
																		fontSize: "13px"
																	}}
																>
																	{message}
																</p>
															)}
														/>
													</>
												) : (
													<>
														<input
															placeholder="Select Your Device"
															className="form-control"
															{...register("model_name", {
																required: "This is required."
															})}
															onBlur={e =>
																addModelName(e.target.value)
															}
														/>
													</>
												)}
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">IMEI Number</label>
												<input
													type="text"
													placeholder="Enter IMEI Number"
													className="form-control"
													{...register("imei_number", {
														required: false,
														pattern: {
															value: imeiRegex,
															message:
																"Please enter a valid IMEI Number"
														}
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="imei_number"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="mobile-specifications">
									<div className="row">
										<h3 className="text-primary">Specifications</h3>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">Color</label>
												<input
													placeholder="Enter Color"
													className="form-control"
													{...register("color", {
														required: false
													})}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													RAM (GB) <span style={{ color: "red" }}>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter Ram"
													className="form-control"
													{...register("ram", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="ram"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													ROM / Internal Storage (GB){" "}
													<span style={{ color: "red" }}>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter ROM"
													className="form-control"
													{...register("rom", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="rom"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Screen Size (Inches){" "}
													<span style={{ color: "red" }}>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter Screen Size"
													className="form-control"
													{...register("screen_size", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="rom"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Front Camera (PX){" "}
													<span style={{ color: "red" }}>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter Front Camera Pixels"
													className="form-control"
													{...register("front_camera", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="rom"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Back Camera (PX){" "}
													<span style={{ color: "red" }}>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter Back Camera Pixels"
													className="form-control"
													{...register("back_camera", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="rom"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Sim Type <span style={{ color: "red" }}>*</span>
												</label>
												<select
													className="form-select mb-md-0 mb-2"
													{...register("sim_type", {
														required: "This is required."
													})}
												>
													<option value="Single Sim">Single Sim</option>
													<option value="Dual Sim">Dual Sim</option>
													<option value="Dual Sim (Nano + eSim)">
														Dual Sim (Nano + eSim)
													</option>
													<option value="Dual Sim (Physical + eSim)">
														Dual Sim (Physical + eSim)
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="mobile-other-details">
									<div className="row">
										<h3 className="text-primary">Other Details</h3>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className="form-label" htmlFor="cities">
													City <span style={{ color: "red" }}>*</span>
												</label>
												<AutoComplete
													setFieldValue={setValue}
													link={"utilities/search-cities?keyword="}
													name={"city_id"}
													method={"GET"}
													placeholder={"Enter City"}
													val={updatedProduct ? updatedProduct.city : ""}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Selling Price (Digits Only){" "}
													<span style={{ color: "red" }}>*</span>
												</label>
												<input
													placeholder="Enter Offer Price"
													className="form-control"
													{...register("price", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="price"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className=" form-label">
													Ownership Type
												</label>
												<select
													name="ownership_type"
													className="form-control"
													{...register("ownership_type", {
														required: "This is required."
													})}
												>
													<option value="1">I bought it new</option>
													<option value="2">
														I&#39;m the Second Owner
													</option>
													<option value="3">
														I&#39;m the Third Owner
													</option>
													<option value="4">
														I&#39;m the Fourth Owner
													</option>
												</select>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className="form-label">In warranty</label>
												<div
													className="radio-wrapper simple"
													style={{ width: "100%" }}
												>
													<div className="radio-item">
														<input
															type="radio"
															name="warranty"
															className="radioInput"
															id="warranty"
															value="Yes"
															{...register("warranty", {
																required: "This is required."
															})}
															defaultChecked={true}
														/>
														<label
															htmlFor="warranty"
															className="option option-1"
														>
															<span>Yes</span>
														</label>
													</div>
													<div className="radio-item">
														<input
															type="radio"
															name="warranty"
															className="radioInput"
															id="warranty-2"
															value="No"
															{...register("warranty", {
																required: "This is required."
															})}
														/>
														<label
															htmlFor="warranty-2"
															className="option option-2"
														>
															<span>No</span>
														</label>
													</div>
												</div>
												<ErrorMessage
													errors={errors}
													name="insured"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className="form-label">Insured</label>
												<div
													className="radio-wrapper simple"
													style={{ width: "100%" }}
												>
													<div className="radio-item">
														<input
															type="radio"
															name="lead_type"
															className="radioInput"
															id="lead_type-1"
															value="Yes"
															{...register("insured", {
																required: "This is required."
															})}
															defaultChecked={true}
														/>
														<label
															htmlFor="lead_type-1"
															className="option option-1"
														>
															<span>Yes</span>
														</label>
													</div>
													<div className="radio-item">
														<input
															type="radio"
															name="lead_type"
															className="radioInput"
															id="lead_type-2"
															value="No"
															{...register("insured", {
																required: "This is required."
															})}
														/>
														<label
															htmlFor="lead_type-2"
															className="option option-2"
														>
															<span>No</span>
														</label>
													</div>
												</div>
												<ErrorMessage
													errors={errors}
													name="insured"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className="form-label">Status</label>
												<Select
													options={[
														{ value: "Active", label: "Active" },
														{
															value: "Inactive",
															label: "Inactive"
														}
													]}
													className="customStyleSelect"
													value={selectedStatusModel}
													name="status"
													placeholder="Choose Status"
													onChange={option => {
														setSelectedStatusModel(option);
													}}
												/>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-6">
												<label className="form-label">Any Dent</label>
												<div
													className="radio-wrapper simple"
													style={{ width: "100%" }}
												>
													<div className="radio-item">
														<input
															type="radio"
															name="bike_condition"
															className="radioInput"
															id="bike_condition2"
															value="No"
															{...register("any_dent", {
																required: false
															})}
															defaultChecked={true}
														/>
														<label
															htmlFor="bike_condition2"
															className="option dents-2"
														>
															<span>No</span>
														</label>
													</div>
													<div className="radio-item">
														<input
															type="radio"
															name="bike_condition"
															className="radioInput"
															id="bike_condition1"
															value="Yes"
															{...register("any_dent", {
																required: false
															})}
														/>
														<label
															htmlFor="bike_condition1"
															className="option dents-1"
														>
															<span>Yes</span>
														</label>
													</div>
												</div>
											</div>
										</div>
										{any_dent === "Yes" ? (
											<div className="col-md-12 col-12">
												<div className="col-md-4 col-12 mb-4">
													<label
														className="form-label"
														htmlFor="loan_amt"
													>
														Scrathes / Dent Images
													</label>
													<input
														type="file"
														multiple={true}
														min={1}
														accept=".png, .jpg, .jpeg"
														placeholder="Choose File"
														className="form-control"
														{...register("dent_files", {
															required: updatedProduct
																? false
																: "This is required.",
															validate: {
																// lessThan10MB: (files) => files[0]?.size < 5000000 || "Max 5MB",
																acceptedFormats: updatedProduct
																	? false
																	: files =>
																		[
																			"image/jpeg",
																			"image/png"
																		].includes(
																			files[0]?.type
																		) ||
																			"Only PNG, JPEG and PDF"
															}
														})}
														onChange={e => handleUpload(e, "scratches")}
													/>
													<ErrorMessage
														errors={errors}
														name="dent_files"
														render={({ message }) => (
															<p
																style={{
																	color: "red",
																	fontSize: "13px"
																}}
															>
																{message}
															</p>
														)}
													/>
													<p className="fs-6 mt-2">
														Note: Multiple images can be selected only
														once. Images must be in the JPG, JPEG or PNG
														format.
													</p>
												</div>
												<div className="uploaded-images mb-6">
													{selectedImagesDent.map((data, index) => {
														return data.image_link ? (
															<img
																key={`selectedDentImages${index}`}
																src={data.image_link}
																alt=""
															/>
														) : (
															<img
																key={`selectedDentImages${index}`}
																src={data}
																alt=""
															/>
														);
													})}
												</div>
											</div>
										) : (
											""
										)}
										<div className="text-center mt-2">
											{formIsLoading ? (
												<button
													type="button"
													className="btn btn-primary"
													disabled={true}
												>
													<img
														src="/images/pages/home/loader.svg"
														alt={process.env.REACT_APP_NAME}
													/>
												</button>
											) : (
												<button type="submit" className="btn btn-primary">
													Submit
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReEmpowerMobile;
