import axios from "axios";
import moment from "moment";
import { useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import Select from "react-select";
import { FaRegEdit, FaTimes } from "react-icons/fa";
import SearchDealerModal from "../../components/SearchDealerModal";

const RepoSidebar = ({ showSidebar, handleClose, repoDetails, sidebarLoading, openSideBar,getRepoList }) => {
	const [commentVal, setCommentVal] = useState("");
	const [allComments, setAllComments] = useState([]);
	const [key, setKey] = useState("basic_details");
	const commentsFetched = useRef(false);
	const [editRepoStatus, setEditRepoStatus] = useState(false);
	const[requestEncId,setRequestEncId] =useState("");
	const [soldToModal,setSoldToModal] =useState(false);
	const RepoStatusOptions = [
		{ value: 1, label: "Initiated" },
		{ value: 2, label: "Completed" },
	  ];

	  const hideSoldToModal = () => {
		setRequestEncId("");
		setSoldToModal(false);
	};

	const saveComment = (e, repo_id, loan_id, type) => {
		if (e.keyCode === 13) {
			submitComment(e.target.value, repo_id, type);
		}
	};
	const submitComment = (value, repo_id, type) => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/save-repo-comments`,
				{"request_enc_id": repo_id, "comment": value, "type": type }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setCommentVal("");
					getComments(repo_id, "repossession");
				}else {
					toast.error("An error has occured while saving comments");
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};
	const getComments = (repo_id, type) => {

		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-repo-comments`, 
			{"request_enc_id": repo_id, "type": type})
			.then(response => {
				if(response.data.response.status === 200){
					setAllComments(response.data.response.data);
				} else {
					toast.error("An error has occured while loading comments, Please try again");
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};
	const changeRepoStatus = (request_enc_id,val) => {
		const data = { request_enc_id, repo_status: val };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-repo-status`,
				data
			)
			.then((response) => {
		
				if(response.data.response?.status === 200){
					toast.success("Repossession Status Updated");
					setEditRepoStatus(false);
					getRepoList();
					openSideBar(request_enc_id);
					
				}else{
					toast.error(response.data.response?.error ? response.data.response?.error : "An error has occurred");
				}
			}).catch(error => {
				toast.error("An Error Occurred: " + error.message);
			});
	};
	const assignDealer = (request_enc_id,val) => {
		const data = { request_enc_id,sold_to: val };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-repo-status`,
				data
			)
			.then((response) => {
				if(response.data.response?.status === 200){
					toast.success("Dealer Assigned Successfully");
					getRepoList();
					openSideBar(request_enc_id);
				}else{
					toast.error(response.data.response?.error ? response.data.response?.error : "An error has occurred");
				}
			}).catch(error => {
				toast.error("An Error Occurred: " + error.message);
			});
	};

	const getSelectedTab = k => {
		if (k === "chat" &&  !commentsFetched.current) {
			getComments(repoDetails.request_enc_id, "repossession");
			commentsFetched.current = true;
		}
	};

	const clearSidebarData = () => {
		setAllComments([]);
		commentsFetched.current = false;
	};
	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail ${showSidebar === false ? "expend-detail-close" : ""}`}>
				<div
					className="closeIcon"
					onClick={() => {
						handleClose();
						clearSidebarData();
					}}
				>
					<AiFillCloseCircle />
				</div>
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					{sidebarLoading ? (
						<div className="cus-loader">
							<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
						</div>
					) : (
						<div className="expend-data expand-data-details">
							<Tabs onSelect={k => (setKey(k), getSelectedTab(k))} defaultActiveKey="basic_details">
								<Tab 
									eventKey="basic_details"
									title="Basic Details"
									className="pt-4"
								>
									<div className="emis-details-tab p-5">
										<div className="row">
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									Loan Account Number:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.loan_account_number}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									Vehicle Model:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.vehicle_model}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									Vehicle Brand:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.brand_name}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									Current Market Value:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.current_market_value}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									Insurance:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.insurance}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									RC:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.rc}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									Repossession Date:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.repossession_date}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
                                                   Repossession Status:{" "}
													<div className="d-flex mx-2">
														{editRepoStatus ? (
															<>
																<Select
																	options={RepoStatusOptions}
																	className="smSelect smOption editRepo-css fw-medium"
																	value={RepoStatusOptions.find(option => option.label === repoDetails.repo_status)}
																	name="Repo Status"
																	isSearchable={false}
																	placeholder="Select Status"
																	onChange={e => changeRepoStatus(repoDetails.request_enc_id, e.value)}
																	noOptionsMessage={() => "No Status Available"}
																/>
																<span onClick={() => setEditRepoStatus(false)} className="mx-2 pointer-cursor">
																	<FaTimes color="red" fontSize="16px" />
																</span>
															</>
														) : (
															<>
																<span className="text-gray-700 fw-medium">{repoDetails.repo_status ? repoDetails.repo_status : "-"}</span>
																<span onClick={() => setEditRepoStatus(true)} className="pointer-cursor mx-2">
																	{repoDetails.repo_status !== "Completed" && (
																		<FaRegEdit
																			style={{
																				color: "rgb(41, 121, 255)",
																				cursor: "pointer"
																			}}
																			size={14}
																		/>
																	)}
																</span>
															</>
														)}
													</div>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
									              Sold To :
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{repoDetails.sold_to ? repoDetails.sold_to :(
															<FaRegEdit
																style={{
																	color: "rgb(41, 121, 255)",
																	cursor: "pointer"
																}}
																size={14}	
																onClick={() => {
																	setRequestEncId(repoDetails.request_enc_id),
																	setSoldToModal(requestEncId);
																}}
															/>
														)}
													</span>
													<SearchDealerModal 
			                                          assignDealer={assignDealer}
			                                         hideSoldToModal={hideSoldToModal}
			                                          soldToModal={soldToModal}
			                                           requestEncId={requestEncId}
			                                                  />
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-12">
												{repoDetails.images || repoDetails.rc_image ? <h2>Images</h2> : ""}
												{repoDetails.rc_image ? (
													<>												
														<h4 className="text-capitalize">RC Image</h4>
														<div className="d-flex">
															<div className="imageUploaded-main uploaded-repo-img me-4 mb-10">
																<img src={repoDetails.rc_image } />
															</div>
														</div>
													</>
												) : ""}
												{repoDetails.images ? Object.entries(repoDetails.images).map(([key, value], index) => {
													return(
														<div key={`img-div-${index}`}>
															{value.length ? <h4 className="text-capitalize">{key} Side</h4> : ""}
															<div className="d-flex flex-column">
																{value.length
																	? value.map((val, i) => {
																		return(
																			<>
																				<div className="imageUploaded-main uploaded-repo-img me-4 mb-10" key={`img-${i}`}>
																					<img src={val.image} />
																				</div>
																			</>
																		);
																	})
																	: ""}
															</div>
														</div>
													);
												}) : ""}
											</div>
										</div>
									</div>
								</Tab>
								<Tab 
									eventKey="chat"
									title="Chat"
									className="pt-4"
								>
									{key === "chat" ? (
										<div className="row mb-5">
											<div className="panel-body mb20">
												<textarea className="form-control"	rows="2" value={commentVal}
													onKeyDown={e =>
														saveComment(e, repoDetails.request_enc_id, "repossession")
													}
													onChange={e => setCommentVal(e.target.value)} placeholder="Add New Comment"
												></textarea>
												<button
													className="btn btn-primary btn-sm mt-2"
													onClick={() =>
														submitComment(
															commentVal,
															repoDetails.request_enc_id,
															"repossession"
														)
													}
												>
											Comment
												</button>
											</div>
											{allComments &&
											allComments.length
												? allComments.map(
													(value, index) => {
														return (
															<div
																className="media g-mb-30 media-comment mb20"
																key={`comment-${index}`}
															>
																<div className="media-body u-shadow-v18 g-bg-secondary g-pa-30">
																	<div className="g-mb-15 comment-main">
																		<h5 className="h5 g-color-gray-dark-v1 mb-0">
																			<span>
																				<img
																					src={
																						value.logo ||
																							value.user_image
																							? value.logo ||
																								value.user_image
																							: value.image
																					}
																					alt={
																						value.created_by
																					}
																					className="creator-img"
																				/>
																			</span>
																			{value.created_by}
																		</h5>
																		<span className="comment-date">
																			{moment(
																				value.created_on
																			).format(
																				"DD MMM YYYY h:mm A"
																			)}
																		</span>
																	</div>

																	<p>{value.comment}</p>
																</div>
															</div>
														);
													}
												)
												: ""}
										</div>
									) : ""}
								</Tab>
							</Tabs>
						</div>
					)}
				</PerfectScrollbar>
			</div>
		</>
	);
};

export default RepoSidebar;
