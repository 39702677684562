import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import moment from "moment";
import { Link } from "react-router-dom";
import RequestSidebar from "./RequestSidebar";
import { toast } from "react-toastify";

const HealthTable = () => {
	const [legalList, setLegalList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	// const [searchArray, setSearchArray] = useState({});
	const [isLoading, setIsLoading] = useState(true);	
	const [loading, setLoading] = useState(false);
	const [emiAccountTableDetails, setEmiAccountTableDetails] = useState("");
	const [showSidebar, setShowsidebar] = useState(false);
	const [sidebarDetails, setSidebarDetails] = useState(false);
	const [requestId, setRequestId] = useState("");

	const handleClose = () => {
		setShowsidebar(false);

		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};
	const openSideBar = (elem, request_id) => {
		setShowsidebar(true);
		setLoading(true);
		// addEvent();
		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
		}

		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/emi-account-details`,
			{loan_account_enc_id: elem}
		)
			.then(response => {
				setLoading(false);
				if(response.data.response.status === 200){
					setSidebarDetails(response.data.response.data);
					setEmiAccountTableDetails(response.data.response.display_data);
					setRequestId(request_id);
				}else{
					setSidebarDetails("");
					setEmiAccountTableDetails([]);
					setRequestId("");
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error("An error has occurred while getting emi account details, Please try again");
				setLoading(false);
		  });
	};
	const handlePerPage = e => {
		getHealthList({
			page: currentPage,
			limit: rowsPerPage,
			// fields_search: searchArray,
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = legalList ? parseInt(legalList.count) / rowsPerPage : 1;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};
	const handlePagination = page => {
		getHealthList({
			page: currentPage,
			limit: rowsPerPage,
			// fields_search: searchArray,
		});
		setCurrentPage(page.selected + 1);
	};
	
	const getHealthList = () => {
		setIsLoading(true);
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-health-list`)
			.then(response => {
				setIsLoading(false);
				if(response.data.response.status === 200){
					setLegalList(response.data.response.data);
				}else{
					setLegalList([]);
				};
			}).catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting Health Requests Data, Please try again"
				);
			});;
	};
	const reloadDetails = () => {
		getHealthList({
			page: currentPage,
			limit: rowsPerPage,
			// fields_search: searchArray,
		});
	};
	const columns = [
		{
			name:"Loan Account Number",
			minWidth: "200px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2 text-link" onClick={() => (openSideBar(row.loan_account_enc_id, row.request_enc_id))}>{row.loan_account_number ? row.loan_account_number : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name:"Remarks",
			minWidth: "200px",
			selector: row => (row.remarks ? row.remarks : "-"),
			cell: row => {
				return(
					<>
						<span className="me-2 text-link">{row.remarks ? row.remarks : "-"}</span>
					</>
				);
			},
			sortable: false,
		},
		{
			name:"Image",
			minWidth: "200px",
			selector: row => (row.image ? row.image : "-"),
			cell: row => {
				return(
					<>
						<div className="me-2 text-img">{ row.image ? <Link rel="noreferrer" to={row.image} target="_blank">View Image</Link> : "-"}</div>
					</>
				);
			},
			sortable: false,
		},
		{
			name:"Requested By",
			minWidth: "200px",
			selector: row => (row.created_by ? row.created_by : "-"),
			cell: row => {
				return(
					<>
						<div className="me-2">{row.created_by ? row.created_by : ""}</div>
					</>
				);
			},
			sortable: false,
		},
		{
			name:"Requested On",
			minWidth: "200px",
			selector: row => (row.created_on ? row.created_on : "-"),
			cell: row => {
				return(
					<>
						<div className="me-2">{row.created_on ? moment(row.created_on).format("DD MMM YYYY") : ""}</div>
					</>
				);
			},
			sortable: false,
		},
	];
	useEffect(() => {
		if (legalList.length <= 0) {
			getHealthList({
				page: currentPage,
				limit: rowsPerPage,
				// fields_search: searchArray,
			});
		}
	}, []);

	return(
		<>
        		<Row className="mx-0 mt-1 mb-50">
				<Col sm="6">
					<div className="d-flex align-items-center">
						<label htmlFor="sort-select">Show</label>
						<select
							className="dataTable-select form-select loan_acc_select"
							style={{
								width: "65px",
								padding: "2px 8px",
								margin: "0px 10px"
							}}
							id="sort-select"
							value={rowsPerPage}
							onChange={e => handlePerPage(e)}
						>
							<option value={10}>10</option>
							<option value={25}>25</option>
							<option value={50}>50</option>
							<option value={75}>75</option>
							<option value={100}>100</option>
						</select>
						<label htmlFor="sort-select">Results</label>
					</div>
				</Col>
			</Row>
			<hr />
			<DataTable
				columns={columns}
				data={legalList ? legalList : []}
				pagination
				paginationServer
				paginationComponent={CustomPagination}
				highlightOnHover="true"
				persistTableHead={true}
				progressPending={isLoading}
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
				reloadData={reloadDetails}
				className="loan-account-table-container"
			/>
			<RequestSidebar
				showSidebar={showSidebar} 
				handleClose={handleClose} 
				sidebarDetails={sidebarDetails}
				emiAccountTableDetails={emiAccountTableDetails}
				loading={loading}
				sidebarFor="health"
				requestId={requestId}
				openSideBar={openSideBar}
				reloadDetails={reloadDetails}
			/>
		</>
	);
};

export default HealthTable;