import { BiRupee } from "react-icons/bi";
import { RiAttachment2 } from "react-icons/ri";
import moment from "moment";

const EmiCollectedCashSidebarData = row => {
	return (
		<div className="mt-3 d-flex flex-wrap">
			{row.data.emiCollectionEnc && row.data.emiCollectionEnc.length
				? row.data.emiCollectionEnc.map((value, index) => {
					return (
						<>
							<div className="col-lg-4 col-sm-6 col-6 px-2" key={`innerCash${index}`}>
								<div className="cash-main-content mb-3 p-3 shadow-ell fs-7 position-relative">
									{value.pr_receipt ? (
										<a href={value.pr_receipt} target="_blank" rel="noreferrer" className="pr-receipt-btn"><RiAttachment2 /></a>
									) : ""}
									<p className="mb-1">
											Loan No.{" "}
										<span className="fw-medium">
											{value.loan_account_number}
										</span>
									</p>
									<p className="mb-1" style={{WebkitLineClamp : 1, display: "-webkit-box", WebkitBoxOrient: "vertical", overflow: "hidden"}}>
											Name:{" "}
										<span className="fw-medium text-capitalize">{value.customer_name}</span>
									</p>
									<p className="mb-1">
											Loan Type:{" "}
										<span className="fw-medium">{value.loan_type}</span>
									</p>
									<p className="mb-1">
											Amount:{" "}
										<span className="fw-bold text-primary">
											<BiRupee size={12} />
											{parseFloat(value.amount).toLocaleString("en-IN")}
										</span>
									</p>
									<p className="mb-1">
											Collection Date:{" "}
										<span className="fw-medium">
											{value.collection_date ? moment(value.collection_date).format("DD MMM YYYY") : "-"}
										</span>
									</p>
								</div>
							</div>
						</>
					);
				  })
				: null}
		</div>
	);
};
export default EmiCollectedCashSidebarData;
