import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";

const LoanAccountRemarks = ({loanAccountComments, getLoanAccountComments, loanAccountEncId,showReportIssues}) => {
	const [commentVal, setCommentVal] = useState("");
	const {handlePageLoaded } = useContext(MainContext);
	const saveComment = (e, loan_id) => {
	
		if (e.keyCode === 13) {
			submitComment(e.target.value, loan_id);
		}
	};

	const submitComment = (value, loan_id) => {
		if(!value){
			return false;
		}
		handlePageLoaded(true);
		let data = { loan_account_enc_id: loan_id, comment: value };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/save-loan-account-comments`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					setCommentVal("");
					getLoanAccountComments();
				}
			}).catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};

	return (
		<>
			<div className="emis-details-tab p-5">
				<div className="panel-body mb20">
					<textarea
						className="form-control"
						rows="2"
						onKeyDown={e =>
							saveComment(e, loanAccountEncId)
						}
						onChange={e => setCommentVal(e.target.value)}
						placeholder="Add New Remark"
						value={commentVal}
					></textarea>
					<button
						className="btn btn-primary btn-sm mt-2"
						onClick={() =>
							submitComment(
								commentVal,
								loanAccountEncId
							)
						}
					>
							Save
					</button>
				</div>

				{loanAccountComments && loanAccountComments.length
					? loanAccountComments.map(
						(value, index) => {
							return (
								<div
									className="media g-mb-30 media-comment mb20"
									key={`comment-${index}`}
								>
									<div className="media-body u-shadow-v18 g-bg-secondary g-pa-30">
										<div className="g-mb-15 comment-main">
											<h5 className="h5 g-color-gray-dark-v1 mb-0">
												<span>
													<img
														src={
															value.logo ||
                                                                value.user_image
																? value.logo ||
                                                                value.user_image
																: value.image
														}
														alt={
															value.created_by
														}
														className="creator-img"
													/>
												</span>
												{value.created_by}
											</h5>
											<span className="comment-date">
												{moment(
													value.created_on
												).format(
													"DD MMM YYYY h:mm A"
												)}
											</span>
										</div>

										<p>{value.comment}</p>
									</div>
								</div>
							);
						}
					)
					: ""}

				<div className="row mb-3">
					{showReportIssues && showReportIssues.length
						? showReportIssues.map((val, ind) => {
							return (
								<div
									className="media g-mb-30 media-comment mb20"
									key={`showReport-${ind}`}
								>
									<div className="media-body u-shadow-v18 g-bg-secondary g-pa-30">
										<div className="g-mb-15 comment-main">
											<h5 className="text-capitalize mx-2">
												<span>
												 <img
														src={val.user_image}
														alt={val.created_by}
														className="creator-img"
													/>
												</span>
												{val.created_by}
											</h5>

											<span className="comment-date">
											 {moment(
													val.created_on
												).format(
													"DD MMM YYYY h:mm a"
												)}
											</span>
										</div>
										<div className="d-flex justify-content-between">
											<p> {val.remarks}</p>
											<p className="" style={{color:"red"}}> Reported {val.reasons} Issues</p>
										</div>
									</div>
								</div>
							);
							 })
						: ""}
				</div>
			</div>
		</>
	);
};

export default LoanAccountRemarks;