/* eslint-disable indent */
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import DealerCollectionForm from "../components/dashboard/DealerCollectionForm";
import DealerCollectionTable from "../components/dashboard/DealerCollectionTable";
import { useState } from "react";

const DealerCollection = () => {
	const [reloadState, setReloadState] = useState(false);
	return (
		<>
			<Helmet>
				<title>Dealer Collection</title>
			</Helmet>
			<Navbar />
            <div className="container-fluid py-5 bg-white">
				<DealerCollectionForm setReloadState={setReloadState}/>
				<DealerCollectionTable reloadState={reloadState} setReloadState={setReloadState}/>
            </div>
		</>
	);
};
export default DealerCollection;
