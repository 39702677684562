import moment from "moment";
import { AiFillCloseCircle } from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";

const DealersSalesReportList = ({
	showSidebar,
	handleClose,
	userResponse,
	userData,
	userLoans,
	loading,
	selectedRow,
	setSelectedRow
}) => {
	function debounce(func) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			});
		};
	}

	const debounceMoreApps = debounce(() => getMoreLoanApps());

	const getMoreLoanApps = () => {
		if (
			userLoans.length <= userResponse.page * userResponse.limit &&
			userResponse.page === selectedRow.page
		) {
			setSelectedRow({ ...selectedRow, page: selectedRow.page + 1 });
		}
	};

	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail ${showSidebar === false ? "expend-detail-close" : ""}`}>
				<div className="closeIcon" onClick={handleClose}>
					<AiFillCloseCircle />
				</div>
				{userResponse ? (
					<PerfectScrollbar
						options={{ suppressScrollX: true }}
						onYReachEnd={debounceMoreApps}
					>
						<div className="expend-data expand-data-details">
							<div className="row mb-3">
								<h3>Dealer Details</h3>
								<div className="col-md-4">
									<p className="text-capitalize">
										<span>Name:</span> 
										<img src={userData.dealer_logo}
											className="EmployeeAvatar" />
										{userData.dealer_name}
									</p>
								</div>
								<div className="col-md-4">
									<p className="text-lowercase">
										<span className="text-capitalize">Email:</span> {userData.email}
									</p>
								</div>
								<div className="col-md-4">
									<p className="text-capitalize">
										<span>Phone:</span> {userData.phone}
									</p>
								</div>
								<div className="col-md-4">
									<p style={{ textTransform: "capitalize" }}>
										<span>Contact Person:</span> 
										<img src={userData.contact_image}
											className="EmployeeAvatar" />
										{userData.contact_person}
									</p>
								</div>
							</div>
							<div className="row">
								<h3>Loans</h3>
								{userLoans
									? userLoans.map((value, index) => {
										const copyLink =
										window.location.origin + "/account/loan-application/" + value.loan_app_enc_id;
										return (
											<div
												className="col-md-6"
												key={`loanBox-${index}`}
											>
												<a
													href={copyLink}
													target="_blank"
													rel="noreferrer"
												>
													<div className="loanBox">
														<div className="row">
															<div className="col-md-12">
																<p className="text-capitalize">
																	<span>Applicant Name:</span>{" "}
																	{value.name}
																</p>
															</div>
															<div className="col-md-6">
																<p className="text-capitalize">
																	<span>Product</span>:{" "}
																	{value.product_name}
																</p>
															</div>
															<div className="col-md-6">
																<p>
																	<span>Loan Amount</span>:{" "}
																	{parseFloat(
																		value.amount
																	).toLocaleString("en-IN")}
																</p>
															</div>
															<div className="col-md-6">
																<p className="text-capitalize" style={{color: "#00a0e3"}}>
																	<span>Loan Status</span>:{" "}
																	{value.loan_status}
																</p>
															</div>
															<div className="col-md-6">
																<p className="text-capitalize">
																	<span className="me-1">Login Date:</span>{value.login_date ? moment(value.login_date).format(
																		"DD MMM YYYY"
																	) : ("-")}
																</p>
															</div>
															<div className="col-md-12">
																<p>
																	<span>Last Status Updated On</span>:{" "}
																	{moment(value.loan_status_updated_on).format(
																		"DD MMM YYYY"
																	)}
																</p>
															</div>
														</div>
													</div>
												</a>
											</div>
										);
									})
									: ""}
							</div>
						</div>
					</PerfectScrollbar>
				) : (
					<>
						{loading ? (
							<div className="cus-loader">
								<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
							</div>
						): (
							<div className="mt-2 p-2">
								No Data To Show
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default DealersSalesReportList;
