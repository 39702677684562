import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { OrganizationSidebarProvider } from "../context/OrganizationSidebarState";
import LoanApplicationDetails from "../components/dashboard/LoanApplicationDetails";

const ApplicantDetail = () => {
	const params = useParams();
	return (
		<div>
			<Helmet>
				<title>Loan Application Details</title>
				<meta name="description" content="Loan Application Details" />
			</Helmet>
			<Navbar />
			<OrganizationSidebarProvider>
				<LoanApplicationDetails 
					loanId={params.id} 
					type={"fullwidth"} 
					handleCloseSideBar={() => {return false;}}
				/>
			</OrganizationSidebarProvider>
			<footer />
		</div>
	);
};
export default ApplicantDetail;
