export const appsForms = {
	allForms: [
		{
			name: "2 Wheeler Loan Application Form",
			link: "https://www.jotform.com/app/222103523981449/222123806590451"
		},
		{
			name: "Physical Field Investigation Form LAP",
			link: "https://www.jotform.com/app/222103523981449/222080472912046"
		},
		{
			name: "Consumer Loan Application",
			link: "https://www.jotform.com/app/222103523981449/222073027865455"
		},
		{
			name: "Used Car Loan",
			link: "https://www.jotform.com/app/222103523981449/222073857043454"
		},
		{
			name: "LAP Loan Application Form",
			link: "https://www.jotform.com/app/222103523981449/222072923715049"
		}
	]
};
