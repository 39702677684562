const OrganizationAppsReducer = (state, action) => {
	switch (action.type) {
	case "SET_STATS":
		return {
			...state,
			stats: action.payload
		};
	case "SET_APPS":
		return {
			...state,
			apps: action.payload
		};
	default:
		return state;
	}
};
export default OrganizationAppsReducer;
