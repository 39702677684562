import { RiDeleteBin6Line } from "react-icons/ri";
import { BsPlusCircleFill } from "react-icons/bs";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LoanProductsContext } from "../../context/LoanProductsState";

const ProductLoginFeeTab = ({ productId, showDocuments, handlePageLoaded }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [feesList, setFeesList] = useState([{ name: "", amount: "" }]);
	const { getLoanProductsDetail, productFeeData } = useContext(LoanProductsContext);

	const addNewProductFees = () => {
		setFeesList([...feesList, { name: "", amount: "" }]);
	};

	const removeElement = (index, id = "") => {
		if (id) {
			if (!window.confirm("Do you really want to delete this process")) {
				return false;
			}
			removeProductFees(id);
		}
		let items = [...feesList];
		items.splice(index, 1);
		setFeesList(items);
	};

	const removeProductFees = async id => {
		let data = { financer_loan_product_login_fee_structure_enc_id: id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/remove-loan-product-fees`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Fee removed successfully");
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	const handleChange = (index, name, amount) => {
		let items = [...feesList];
		let item = { ...items[index] };
		item.name = name;
		item.amount = amount;
		if (item.name === "" && item.financer_loan_product_login_fee_structure_enc_id) {
			if (window.confirm("Do you really want to delete this document")) {
				removeProductFees(item.financer_loan_product_login_fee_structure_enc_id);
			}
		}
		if (!item.financer_loan_product_login_fee_structure_enc_id) {
			item.financer_loan_product_login_fee_structure_enc_id = "";
		}

		item.name === "" ? items.splice(index, 1) : (items[index] = item);
		setFeesList(items);
	};

	const onsubmit = async () => {
		setIsLoading(true);
		let data = {
			financer_loan_product_enc_id: productId,
			product_no_dues: feesList
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product-fees`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					setFeesList([]);
					toast.success("Updated successfully");
					getLoanProductsDetail({ id: productId, type: "fee" });
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};
	const submitWithDelay = () => {
		setIsLoading(true);
		setTimeout(() => {
			onsubmit();
		}, 700);
	};

	useEffect(() => {
		if (showDocuments) {
			handlePageLoaded(true);
			getLoanProductsDetail({ id: productId, type: "fee" });
		}
	}, [showDocuments]);

	useEffect(() => {
		if (productFeeData) {
			handlePageLoaded(false);
			setFeesList(productFeeData);
		} else {
			handlePageLoaded(false);
		}
	}, [productFeeData]);

	return (
		<>
			{/* <form onSubmit={handleSubmit(submitWithDelay)} className="mt-6">*/}
			<div className="proof-selection mt-6 mb20">
				<div className="mb-5">
					<label>Click the button to End Dues</label>
				</div>
				{feesList && feesList.length ? (
					<>
						{feesList.map((value, index) => {
							return (
								<div
									className="documentTypehead"
									key={`div-${value.financer_loan_product_login_fee_structure_enc_id}-doc`}
								>
									<div className="col-4 mx-2">
										<input
											type="text"
											placeholder="Enter Name"
											className="form-control typeheadStyle"
											defaultValue={value.name ? value.name : ""}
											onBlur={e =>
												handleChange(index, e.target.value, value.amount)
											}
										/>
									</div>
									<div className="col-2">
										<input
											type="number"
											min={1}
											placeholder="Amount"
											className="form-control typeheadStyle"
											defaultValue={value.amount ? value.amount : ""}
											onBlur={e =>
												handleChange(index, value.name, e.target.value)
											}
											onWheel={e => e.target.blur()}
										/>
									</div>
									<button
										type="button"
										className="link-button"
										style={{ color: "red", marginLeft: "10px" }}
										onClick={() =>
											removeElement(
												index,
												value.financer_loan_product_login_fee_structure_enc_id
											)
										}
									>
										<RiDeleteBin6Line size={25} />
									</button>
								</div>
							);
						})}
					</>
				) : (
					""
				)}
				<button
					className="link-button"
					type="button"
					style={{ color: "#6c757d", marginLeft: "10px" }}
					onClick={() => addNewProductFees()}
				>
					<BsPlusCircleFill size={30} />
				</button>
			</div>
			<div className="mt-5 text-center">
				{isLoading ? (
					<button type="button" className="btn btn-primary" disabled={true}>
						<img src="/images/pages/home/loader.svg" />
					</button>
				) : (
					<button
						type="submit"
						className="btn btn-primary"
						onClick={() => submitWithDelay()}
					>
						{" "}
						Save{" "}
					</button>
				)}
			</div>
			{/* </form>*/}
		</>
	);
};

export default ProductLoginFeeTab;
