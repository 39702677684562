import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import VehiclesDataList from "../../components/dashboard/VehiclesDataList";
import { ReEmpowerProvider } from "../../context/ReEmpowerState";

const MobilesList = () => {
	return (
		<>
			<Helmet>
				<title>
					Business loans without Collateral - Apply for Small Business loans | Empower
					Loans
				</title>
				<meta
					name="description"
					content={`Avail business loans without collateral at a low rate of interest. ${process.env.REACT_APP_NAME} offers small business loans up to 30 lac with flexible repayment options. Contact us at : +91-8727985888.`}
				/>
			</Helmet>
			<Navbar />
			<ReEmpowerProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className="shadow-lg rounded-3 py-4 px-6">
							<div className={"d-flex justify-content-between mb-3"}>
								<h2 className="mb-0">Mobiles List</h2>
								<div className="dashboard-sub-action d-flex">
									<Link
										to="/account/re-empower/add-mobile"
										className="btn btn-primary btn-sm mx-1"
									>
										Add Mobile
									</Link>
								</div>
							</div>
							<VehiclesDataList category={"Mobiles"} />
						</div>
					</div>
				</div>
			</ReEmpowerProvider>
			<Footer />
		</>
	);
};
export default MobilesList;
