import React, { createContext, useEffect, useReducer, useState } from "react";
import CandidateDashboardReducer from "../reducers/CandidateDashboardReducer";
import axios from "axios";
import { toast } from "react-toastify";
// import authData from "../data/authData";

// Create context
export const CandidateDashboardContext = createContext({});

// Provider component
export const CandidateDashboardProvider = ({ children }) => {
	const [state, dispatch] = useReducer(CandidateDashboardReducer, {});
	const [selectedApplication, setSelectedApplication] = useState("");

	// Actions
	const setChoosedApplication = data => {
		setSelectedApplication(data);
	};

	useEffect(() => {
		if (state.all_applications && state.all_applications.length > 0) {
			setSelectedApplication(state.all_applications[0]);
		}
	}, [state.all_applications]);

	useEffect(() => {
		if (selectedApplication.loan_status === "Disbursed") {
			getEmiDetails(selectedApplication.loan_app_enc_id);
		}
	}, [selectedApplication]);

	const clearLenderDetails = () => {
		dispatch({
			type: "REMOVE_LENDER_DETAILS"
		});
	};

	const getLoanApplications = data => {
		 axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/loan-details`,
				data
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_ALL_LOAN_APPLICATION",
						payload: response.data.response.loan_applications
					});
				} else {
					dispatch({
						type: "SET_ALL_LOAN_APPLICATION",
						payload: []
					});
				}
			})
			.catch(error => {
				toast.error("An error has occured while getting Loan Applications, Please try again.", error);
			});
	};

	const getEmiDetails = data => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/get-emi-details`,
				{loan_app_enc_id : data}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_EMI_DETAILS",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_EMI_DETAILS",
						payload: []
					});
				}
			})
			.catch(error => {
				toast.error("An error has occured while getting EMI details, Please try again.", error);
			});
	};

	const getLenderDetail = async loan_provider_id => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/loan-provider-detail`,
				{ loan_provider_id }
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_LENDER_DETAILS",
						payload: response.data.response.assigned_loan_provider
					});
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getScratchCards = async (deal_slug = "diwali-dhamaka") => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/scratch-cards-code`,
				{ deal_slug }
			)
			// .post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/scratch-cards`, {})
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_SCRATCH_CARDS",
						// payload: response.data.response.count
						payload: response.data.response.coupon_codes
					});
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getMyRewards = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}deals/get-claimed`,
				{}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_MY_REWARDS",
						payload: response.data.response.claimed_deals
					});
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};
	const getCandidateNoticeBoard = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/get-notice`,
				{}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "CANDIDATE_DASHBOARD_NOTICE",
						payload: response.data.response.notices
					});
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	return (
		<CandidateDashboardContext.Provider
			value={{
				all_applications: state.all_applications,
				lender_details: state.lender_details,
				scratch_cards: state.scratch_cards,
				emiDetails: state.emiDetails,
				my_rewards: state.my_rewards,
				candidate_notice: state.candidate_notice,
				getMyRewards,
				getLoanApplications,
				getLenderDetail,
				getCandidateNoticeBoard,
				getScratchCards,
				clearLenderDetails,
				selectedApplication,
				setChoosedApplication,
				getEmiDetails
			}}
		>
			{children}
		</CandidateDashboardContext.Provider>
	);
};
