import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { RiCloseFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { MainContext } from "../context/MainState";
import { useForm } from "react-hook-form";
import { BsCameraFill } from "react-icons/bs";

const FinancerVehicleTypes = () => {
	const { register, handleSubmit, reset } = useForm();
	const [allVehicleList, setAllVehicleList] = useState([]);
	const { user, handlePageLoaded } = useContext(MainContext);
	const [selectedImage, setSelectedImage] = useState(null);

	const getVehicleType = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/get-financer-vehicle-type`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setAllVehicleList(response.data.response.financer_list);
				} else {
					setAllVehicleList([]);
				}
			})
			.catch(error => {
				console.error(error);
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
			});
	};

	const onSubmit = async values => {
		if (!values.vehicle_type) {
			toast.error("Please Enter vehicle type Name");
			return;
		}
		handlePageLoaded(true);
		const formData = new FormData();
		formData.append("organization_enc_id", user.organization_enc_id);
		formData.append("vehicle_type", values.vehicle_type);
		formData.append("icon_image", selectedImage);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/financer-vehicle-type`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Added Successfully");
					setSelectedImage("");
					reset();
					getVehicleType();
				} else {
					toast.error(
						response.data.response && response.data.response.message
							? response.data.response.message
							: "An Error has occurred"
					);
				}
			})
			.catch(error => {
				console.error(error);
				handlePageLoaded(false);
				console.error("Error in axios call:", error);
			});
	};
	const removeType = async id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		handlePageLoaded(true);
		let data = { financer_vehicle_type_enc_id: id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/remove-financer-vehicle-type`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					getVehicleType();
				}
			})
			.catch(error => {
				console.error(error);
				handlePageLoaded(false);
				console.error("Error in axios call:", error);
			});
	};
	useEffect(() => {
		getVehicleType();
	}, []);
	return (
		<>
			<h2>Types</h2>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-6 mb-4 m-auto">
						<div className="img-preview-user text-center p-2 shadow-ell rounded-2 m-auto position-relative mb-3">
							{selectedImage && (
								<img
									alt="not found"
									width={"100px"}
									src={URL.createObjectURL(selectedImage)}
								/>
							)}
							<label
								className={`brand-logos flex-column fs-6 fw-bold form-label edit-image ${
									selectedImage ? "opacity-0" : ""
								}`}
								htmlFor="type_logo"
							>
								<BsCameraFill size={30} className="edit-image-camera" />
								Choose Logo
							</label>
							<input
								className="d-none"
								id="type_logo"
								type="file"
								accept=".png, .jpg, .jpeg"
								name="type_logo"
								onChange={e => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
						</div>
						<label className="form-label" htmlFor="org_name">
							Vehicle Type
						</label>
						<input
							placeholder="Enter Vehicle Type Name"
							className="form-control"
							{...register("vehicle_type")}
						/>
					</div>
					<div className="mb-4 text-center">
						<button className="btn btn-primary">Save</button>
					</div>
				</div>
			</form>
			<hr />
			<div className="mt-5">
				<h4>Vehicle Types List</h4>
				{allVehicleList && allVehicleList.length ? (
					<div
						style={{
							border: "3px solid #eee",
							borderRadius: "10px",
							padding: "15px"
						}}
					>
						{allVehicleList.map((value, index) => {
							return (
								<div className="chip-list brand-chips" key={index}>
									{value.icon ? (
										<img
											src={value.icon}
											alt={value.vehicle_type}
											className="img-fluid"
										/>
									) : (
										""
									)}
									{value.vehicle_type}
									<RiCloseFill
										style={{ margin: "0px 5px", cursor: "pointer" }}
										size={15}
										onClick={() =>
											removeType(value.financer_vehicle_type_enc_id)
										}
									/>
								</div>
							);
						})}
					</div>
				) : (
					"No Types Found!"
				)}
			</div>
		</>
	);
};

export default FinancerVehicleTypes;
