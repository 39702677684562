import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FaMobileAlt } from "react-icons/fa";

const SignInWithPhone = ({ handleLoginType, handleSignIn, formIsLoading }) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const onSubmit = async data => {
		handleSignIn(data["phone_no"]);
		return true;
	};

	return (
		<div className="login-form">
			<h1>Sign In</h1>
			<form onSubmit={handleSubmit(onSubmit)} className="form">
				<div className="upper-form">
					<div className="login-username mb-3">
						<label className="form-label mb-0" htmlFor="first_num">
							Mobile Number
						</label>
						<div className="d-flex">
							<input
								style={{
									width: "50px",
									padding: "8px",
									height: "49px",
									marginRight: "-4px"
								}}
								type="text"
								value="+91"
								className="form-control"
								disabled={true}
							/>
							<input
								style={{
									borderLeftColor: "transparent",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0
								}}
								type="tel"
								placeholder="Phone"
								className="form-control"
								{...register("phone_no", {
									required: "This is required.",
									minLength: {
										value: 10,
										message: "Mobile number Should be at least 10 numbers"
									},
									maxLength: {
										value: 10,
										message: "Mobile number maximum be at least 10 numbers"
									},
									pattern: {
										value: phoneRegExp,
										message: "Invalid Phone number"
									}
								})}
							/>
						</div>
						<ErrorMessage
							errors={errors}
							name="phone_no"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
				<div className="login-buttons submit-form">
					{formIsLoading ? (
						<button
							type="button"
							id="Submit"
							name="Submit"
							className="submit-btn btn"
							disabled={true}
						>
							<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
						</button>
					) : (
						<button type="submit" className="submit-btn btn">
							Sign In
						</button>
					)}
				</div>
				<span className="or">OR</span>
				<div className="login-buttons social-continue">
					<button
						className="social-submit btn-google btn"
						onClick={() => handleLoginType("Login")}
					>
						<FaMobileAlt /> Login with Username and Password
					</button>
				</div>
			</form>
		</div>
	);
};
export default SignInWithPhone;
