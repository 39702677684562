import React, { useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { BsCameraFill } from "react-icons/bs";
import { readOnlyUsers } from "../../helpers/authServices";

const CaptureUserImage = ({ id, image, type, details, upload=false, getImageData }) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const handleCapture = async target => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (target.files) {
			if (target.files.length !== 0) {
				handlePageLoaded(true);
				const file = target.files[0];
				const formData = new FormData();
				formData.append("image", file);
				formData.append("id", id);
				formData.append("type", type);
				await axios
					.post(
						`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/upload-applicant-image`,
						formData
					)
					.then(response => {
						handlePageLoaded(false);
						if (response.data.response.status === 200) {
							toast.success("Submit Successfully");
							// openSideBar(details);
							const objectURL = URL.createObjectURL(file);
							getImageData(details, objectURL);
						} else {
							toast.error("An error has occurred, Please try again");
						}
					})
					.catch(error => {
						console.log(error);
						toast.error(error.message);
						handlePageLoaded(false);
					});
			}
		}
	};

	return (
		<>
			<label
				className={`text-center d-block camera-img position-relative ${!image && upload ? "image-rel" : ""}`}
				htmlFor={`captureImage${id}`}
			>
				<BsCameraFill />
				<img
					src={image ? image : "/images/pages/re-empower/avatar.svg"}
					className="img-fluid"
				/>
			</label>
			<input
				id={`captureImage${id}`}
				accept="image/*"
				placeholder="Capture Image"
				className="form-control d-none"
				type="file"
				capture="environment"
				onChange={e => handleCapture(e.target)}
			/>
		</>
	);
};
export default CaptureUserImage;
