import { useEffect, useState } from "react";

const FilterComponent = ({ filterText, onFilter, onClear, hideDate = false }) => {
	const [maxDate, setMaxDate] = useState("");
	useEffect(() => {
		const dtToday = new Date();

		let month = dtToday.getMonth() + 1;
		let day = dtToday.getDate();
		const year = dtToday.getFullYear();

		if (month < 10) month = "0" + month.toString();
		if (day < 10) day = "0" + day.toString();

		setMaxDate(year + "-" + month + "-" + day);
	}, []);
	return (
		<div className="search-flex align-items-center">
			{/* <div className="bowUGI">
				<input
					id="search"
					type="text"
					placeholder="Enter Keyword"
					aria-label="Search Input"
					className="sc-hiCibw djsRDF"
					value={filterText.text}
					onChange={e => onFilter(e.target.value, "text")}
				/>
				<button type="button" className="sb-btn" onClick={onClear}>
					X
				</button>
			</div> */}
			{!hideDate ? (
				<div className="bowUGI">
					<input
						type="date"
						value={filterText.date}
						className="sc-hiCibw djsRDF"
						max={maxDate}
						onChange={e => onFilter(e.target.value, "date")}
					/>
					<button type="button" className="sb-btn" onClick={onClear}>
						X
					</button>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default FilterComponent;
