import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import MultiStepFormContainer from "../../components/forms/MultiStepApplyForm/MultiStepFormContainer";
import Step1 from "./e-signing/Step1";
import Step3 from "./e-signing/Step3";
import Step2 from "./e-signing/Step2";
import { useParams } from "react-router-dom";
import { MainContext } from "../../context/MainState";

const TestPage = () => {
	const params = useParams();
	const { user } = useContext(MainContext);
	const [formStep, setFormStep] = useState(0);
	const [allFormData, setAllFormData] = useState({});
	const [defaultData, setDefaultData] = useState({});

	const nextFormStep = () => setFormStep(currentStep => currentStep + 1);

	const prevFormStep = () => setFormStep(currentStep => currentStep - 1);

	const getApplicationData = async loan_id => {
		let data = { loan_id, provider_id: user.organization_enc_id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-detail`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setDefaultData(response.data.response.loan_detail);
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				toast.error(error);
			});
	};
	const onSubmit = async data => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/contact-us`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Submit Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};

	const setFormData = data => {
		setAllFormData({ ...allFormData, ...data });
	};
	useEffect(() => {
		if (params.id) {
			getApplicationData(params.id);
		}
	}, []);

	return (
		<div>
			<Helmet>
				<title>Document E-Sign</title>
				{/* <meta name="description" content="Tutorial for React Helmet"/>*/}
			</Helmet>
			<Navbar />
			<section>
				<div className="container mt-4">
					<div className="row">
						<div className="col-md-12 col-sm-12 col-xs-12">
							<div className="bg-white mb-10 rounded-3 shadow-sm p-lg-8 p-5">
								<div className="mb-8">
									<div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
										<div className="mb-6  text-center  ">
											<h1 className="mb-0">Document e-sign</h1>
											<p>Document e-sign for Loan Agreement</p>
										</div>
									</div>
									<MultiStepFormContainer
										currentStep={formStep}
										prevFormStep={prevFormStep}
									>
										{formStep >= 0 && (
											<Step1
												formStep={formStep}
												defaultData={defaultData}
												nextFormStep={nextFormStep}
												setFormData={setFormData}
											/>
										)}
										{formStep >= 1 && (
											<Step2
												formStep={formStep}
												defaultData={defaultData}
												nextFormStep={nextFormStep}
												setFormData={setFormData}
												formData={allFormData}
											/>
										)}
										{formStep >= 2 && (
											<Step3
												formStep={formStep}
												nextFormStep={nextFormStep}
												setFormData={onSubmit}
												formData={allFormData}
												prevFormStep={prevFormStep}
											/>
										)}
										{/* <div className="row mb-3">*/}
										{/*    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">*/}
										{/*        {formIsLoading ? (*/}
										{/*            <button type="button" className="btn btn-primary" disabled={true}>*/}
										{/*                <img src="/images/pages/home/loader.svg" />*/}
										{/*            </button>*/}
										{/*        ) : (*/}
										{/*            <button id="Submit" name="Submit" className="btn btn-primary">*/}
										{/*                Submit*/}
										{/*            </button>*/}
										{/*        )}*/}

										{/*    </div>*/}
										{/* </div>*/}
									</MultiStepFormContainer>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};
export default TestPage;
