import React, { useContext, useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { LoanProductsContext } from "../../context/LoanProductsState";

const PendencyTab = ({ productId, showDocuments, handlePageLoaded }) => {
	const [isLoading, setIsLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		setValue	} = useForm();
	const [updatePendencies, setUpdatePendencies] = useState({});
	
	const { getLoanProductsDetail, productPendenciesData } = useContext(LoanProductsContext);

	const onSumbit = values => {
		values["financer_loan_product_enc_id"] = productId;

		if (updatePendencies && updatePendencies.pendencies_enc_id) {
			values["pendencies_enc_id"] = updatePendencies.pendencies_enc_id;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-pendency`,
				values
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					reset();
					setUpdatePendencies({});
					toast.success("Pendency Saved successfully");
					getLoanProductsDetail({ id: productId, type: "pendencies" });
				} else {
					toast.error(
						"An error occurred " +
							(response.data.response.error
								? response.data.response.error
								: response.data.response.message)
					);
				}
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};

	const editPendency = (pendency_id, type) => {
		let editpendencies = productPendenciesData[type].filter(
			pendency => pendency.pendencies_enc_id === pendency_id
		);
		if (editpendencies.length) {
			setUpdatePendencies(editpendencies[0]);
			setValue("name", editpendencies[0]["name"]);
			setValue("type", editpendencies[0]["type"]);
		}
	};

	const removePendency = async id => {
		let data = { delete: true, pendencies_enc_id: id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-pendency`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Pendency removed successfully");
					getLoanProductsDetail({ id: productId, type: "pendencies" });
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	useEffect(() => {
		if (showDocuments) {
			getLoanProductsDetail({ id: productId, type: "pendencies" });
			handlePageLoaded(true);
		}
	}, [showDocuments]);

	useEffect(() => {
		if (productPendenciesData) {
			handlePageLoaded(false);
		}
	}, [productPendenciesData]);

	return (
		<>
			<form onSubmit={handleSubmit(onSumbit)}>
				<div className="proof-selection text-left mb20 mt-6">
					<div className="offset-md-3 col-md-6 mt-4">
						<div className="col-12 mb-3">
							<label className="form-label">Pendency Name</label>
							<input
								type="text"
								id="pendency_name"
								{...register("name", {
									required: "This is required."
								})}
								placeholder="Enter the Pendency"
								className="form-control"
							/>
						</div>
						<div className="col-12 mb-3">
							<div className="pFlex">
								<label className="form-label">Select Pendency Type</label>
								<div className="radio-wrapper simple">
									<div className="radio-item">
										<input
											type="radio"
											name="type"
											className="radioInput"
											id="Individual"
											value="1"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="Individual"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px",
												marginLeft: "0"
											}}
										>
											<span>Individual</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="type"
											className="radioInput"
											id="Company"
											value="2"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="Company"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<span>Company</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="type"
											className="radioInput"
											id="Property"
											value="3"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="Property"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<span>Property</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="type"
											className="radioInput"
											id="Miscellaneous"
											value="4"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="Miscellaneous"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<span>Miscellaneous</span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="text-center mt-3">
							{isLoading ? (
								<button type="button" className="btn btn-primary" disabled={true}>
									<img src="/images/pages/home/loader.svg" />
								</button>
							) : (
								<button type="submit" className="btn btn-primary">
									Save{" "}
								</button>
							)}
						</div>
					</div>
				</div>
			</form>

			{productPendenciesData && productPendenciesData.Individual && productPendenciesData.Individual.length ? (
				<div className="mt-4">
					<div className="pendency-list">
						<table className="table">
							<thead>
								<tr>
									<th width="80%" className="pendency-th">
										Individual Pendencies
									</th>
									<th width="10%" className="text-center pendency-th">
										Edit
									</th>
									<th width="10%" className="text-center pendency-th">
										Delete
									</th>
								</tr>
							</thead>
							<tbody>
								{productPendenciesData.Individual.map((value, index) => {
									return (
										<tr key={`individual-${index}`}>
											<td>
												<p className="pendency-name">{value.name}</p>
											</td>
											<td className="text-center">
												<button type="button" className="link-button">
													<CiEdit
														size={16}
														color="#00a0e3"
														onClick={() =>
															editPendency(
																value.pendencies_enc_id,
																"Individual"
															)
														}
													/>
												</button>
											</td>

											<td className="text-center">
												<button type="button" className="link-button">
													<BiTrash
														size={16}
														color="red"
														onClick={() =>
															removePendency(
																value.pendencies_enc_id,
																value,
																"delete"
															)
														}
													/>
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				""
			)}

			{productPendenciesData && productPendenciesData.Company && productPendenciesData.Company.length ? (
				<div className="mt-4">
					<div className="pendency-list">
						<table className="table">
							<thead>
								<tr>
									<th width="80%" className="pendency-th">
										Company Pendencies
									</th>
									<th width="10%" className="text-center pendency-th">
										Edit
									</th>
									<th width="10%" className="text-center pendency-th">
										Delete
									</th>
								</tr>
							</thead>
							<tbody>
								{productPendenciesData.Company.map((value, index) => {
									return (
										<tr key={`company-${index}`}>
											<td>
												<p className="pendency-name">{value.name}</p>
											</td>
											<td className="text-center">
												<button type="button" className="link-button">
													<CiEdit
														size={16}
														color="#00a0e3"
														onClick={() =>
															editPendency(
																value.pendencies_enc_id,
																"Company"
															)
														}
													/>
												</button>
											</td>

											<td className="text-center">
												<button type="button" className="link-button">
													<BiTrash
														size={16}
														color="red"
														onClick={() =>
															removePendency(
																value.pendencies_enc_id,
																value,
																"delete"
															)
														}
													/>
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				""
			)}

			{productPendenciesData && productPendenciesData.Property && productPendenciesData.Property.length ? (
				<div className="mt-4">
					<div className="pendency-list">
						<table className="table">
							<thead>
								<tr>
									<th width="80%" className="pendency-th">
										Property Pendencies
									</th>
									<th width="10%" className="text-center pendency-th">
										Edit
									</th>
									<th width="10%" className="text-center pendency-th">
										Delete
									</th>
								</tr>
							</thead>
							<tbody>
								{productPendenciesData.Property.map((value, index) => {
									return (
										<tr key={`property-${index}`}>
											<td>
												<p className="pendency-name">{value.name}</p>
											</td>
											<td className="text-center">
												<button type="button" className="link-button">
													<CiEdit
														size={16}
														color="#00a0e3"
														onClick={() =>
															editPendency(
																value.pendencies_enc_id,
																"Property"
															)
														}
													/>
												</button>
											</td>

											<td className="text-center">
												<button type="button" className="link-button">
													<BiTrash
														size={16}
														color="red"
														onClick={() =>
															removePendency(
																value.pendencies_enc_id,
																value,
																"delete"
															)
														}
													/>
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				""
			)}

			{productPendenciesData && productPendenciesData.Miscellaneous && productPendenciesData.Miscellaneous.length ? (
				<div className="mt-4">
					<div className="pendency-list">
						<table className="table">
							<thead>
								<tr>
									<th width="80%" className="pendency-th">
										Miscellaneous Pendencies
									</th>
									<th width="10%" className="text-center pendency-th">
										Edit
									</th>
									<th width="10%" className="text-center pendency-th">
										Delete
									</th>
								</tr>
							</thead>
							<tbody>
								{productPendenciesData.Miscellaneous.map((value, index) => {
									return (
										<tr key={`property-${index}`}>
											<td>
												<p className="pendency-name">{value.name}</p>
											</td>
											<td className="text-center">
												<button type="button" className="link-button">
													<CiEdit
														size={16}
														color="#00a0e3"
														onClick={() =>
															editPendency(
																value.pendencies_enc_id,
																"Miscellaneous"
															)
														}
													/>
												</button>
											</td>

											<td className="text-center">
												<button type="button" className="link-button">
													<BiTrash
														size={16}
														color="red"
														onClick={() =>
															removePendency(
																value.pendencies_enc_id,
																value,
																"delete"
															)
														}
													/>
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default PendencyTab;
