import { BsArrowRight } from "react-icons/bs";
import moment from "moment/moment";
import React from "react";

const AuditTrail = ({ auditKey }) => {
	const statusObj = {
		SET: "text-success",
		CHANGE: "text-danger"
	};

	return (
		<>
			<div className="audit-trail-main ">
				<div className="audit-trail-list">
					<div className="audit-msg " >
						<p style={{width: "30%"}}>
							<img
								src={auditKey.image}
								alt={auditKey.created_by}
								className="employer-img"
							/>
							<span className="audit-text-color">	
								{auditKey.created_by}
							</span>
						</p>

						<p className="d-flex align-items-center flex-wrap text-break " style={{width: "100%"}}>
							<span className={`chngd ${statusObj[auditKey.action]}`}>
								{auditKey.action} {auditKey.field}
							</span>
							<span className="trail-status audit-text-color">
								{auditKey.old_value}
							</span>
							{auditKey.old_value ? <BsArrowRight /> : ""}
							<span className="trail-status audit-text-color">
								{auditKey.new_value}
							</span>
						</p>
					</div>
					<div className="audit-msg-date ">
						<p>{moment(auditKey.stamp).format("DD MMM YYYY h:mm a")}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default AuditTrail;
