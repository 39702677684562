import { ErrorMessage } from "@hookform/error-message";
import Compressor from "compressorjs";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsFillCameraFill } from "react-icons/bs";
import { toast } from "react-toastify";

export default function DealerStep3({ formStep, setFormData, formIsLoading }) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	const [dealerLogo, setDealerLogo] = useState({ image: "", name: "" });

	const compressImage = (e, setImageState) => {
		if (e.target.files.length) {
			const selectedImage = e.target.files[0];
			setImageState({
				image: selectedImage,
				name: selectedImage.name
			});

			new Compressor(selectedImage, {
				quality: 0.6,
				success: res => {
					setImageState(prevState => ({
						...prevState,
						compressedImage: res
					}));
				}
			});
		}
	};

	const onSubmit = values => {
		const isAnyBankDetailFilled = values.account_name || values.bank_name || values.account_number || values.ifsc_code;
	
		if (isAnyBankDetailFilled && (!values.account_name || !values.bank_name || !values.account_number || !values.ifsc_code)) {
			toast.error("Please fill all bank details");
		} else {
			let formData = new FormData();
			formData.append("dealer_logo", dealerLogo.image);
			formData.append("account_name", values.account_name);
			formData.append("bank_name", values.bank_name);
			formData.append("account_number", values.account_number);
			formData.append("ifsc_code", values.ifsc_code);
			setFormData(formData);
			reset();
		}
	};

	return (
		<div className={formStep === 2 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-6 col-12 mb-3">
						<label className="form-label">Upload Logo</label>
						<div className="d-flex align-items-center">
							<label
								className="form-control w-auto text-center"
								style={{
									backgroundColor: "#00a0e3",
									color: "#fff",
									cursor: "pointer"
								}}
								htmlFor="locationimage"
							>
								<BsFillCameraFill /> Upload
							</label>
							<input
								id="locationimage"
								placeholder="upload Image"
								className="form-control d-none"
								{...register("dealer_logo", {
									required: false
								})}
								type="file"
								onChange={e => compressImage(e, setDealerLogo)}
							/>
						</div>
						{dealerLogo.name ? (
							<p
								style={{
									marginBottom: "0px",
									marginTop: "10px",
									fontSize: "14px",
									color: "#000"
								}}
							>
								{dealerLogo.name}{" "}
							</p>
						) : (
							""
						)}
					</div>
					<div>
						<h4>Bank Details</h4>
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="account_name">
										Account Holder Name
									</label>
									<input
										placeholder="Account Holder Name"
										className="form-control"
										{...register("account_name", {
											required: false
										})}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="bank_name">
										Bank Name
									</label>
									<input
										placeholder="Bank Name"
										className="form-control"
										{...register("bank_name", {
											required: false
										})}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="account_number">
										Account Number
									</label>
									<input
										type="number"
										placeholder="Account Number"
										className="form-control"
										{...register("account_number", {
											required: false
										})}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="ifsc_code">
										IFSC Code
									</label>
									<input
										placeholder="IFSC Code"
										className="form-control"
										{...register("ifsc_code", {
											required: false,
											pattern: {
												value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
												message: "Ifsc Code is invalid"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="ifsc_code"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-12 text-center">
						{formIsLoading ? (
							<button
								type="button"
								id="Submit"
								name="Submit"
								className="btn btn-primary"
								disabled={true}
							>
								<img
									src="/images/pages/home/loader.svg"
									alt={process.env.REACT_APP_NAME}
								/>
							</button>
						) : (
							<button type="submit" className="btn btn-primary">
								Save
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
