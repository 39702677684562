import "../../../assets/css/formstyles.css";
import { useForm } from "react-hook-form";
import LoanResidentialDetails from "../LoanResidentialDetails";

export default function Step3({ formStep, setFormData, currentStep = 2 }) {
	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors }
	} = useForm();

	const onSubmit = values => {
		setFormData(values);
		// nextFormStep();
	};

	return (
		<div className={formStep === currentStep ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LoanResidentialDetails
					formFor="org"
					register={register}
					control={control}
					errors={errors}
					setValue={setValue}
				/>
				<button type="submit" className="btn btn-primary">
					Next
				</button>
			</form>
		</div>
	);
}
