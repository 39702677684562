import React, { useContext, useEffect, useState } from "react";
import { CandidateDashboardContext } from "../../context/CandidateDashboardState";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { useForm } from "react-hook-form";
import Geocode from "react-geocode";
import IndividualLoanDetails from "../../pages/account/IndividualLoanDetails";

const CandidateDashboardLoanDetails = ({ type = null, loan_id = null }) => {
	const { handlePageLoaded } = useContext(MainContext);
	const { handleSubmit, reset } = useForm();
	const { selectedApplication, emiDetails, getLoanApplications } =
		useContext(CandidateDashboardContext);
	const [collectNow, setCollectNow] = useState(false);
	const [showLink, setShowLink] = useState(false);
	// const [isHidden, setIsHidden] = useState(false);
	const [vals, setVals] = useState({ latitude: "" });
	const onSubmit = async data => {
		data.address = vals.city;
		data.pincode = vals.postal_code;
		data.latitude = vals.latitude;
		data.longitude = vals.longitude;
		data.emi_amount = selectedApplication.emi_amount ? selectedApplication.emi_amount : "0";
		data.pay_by = "0";
		data.loan_app_enc_id = selectedApplication.loan_app_enc_id;
		handlePageLoaded(true);
		console.log(data);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/create-payment-link`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Payment link generated successfully");
					setShowLink(response.data.response);
					setCollectNow(false);
					reset();
				} else {
					toast.error(
						response.data.response.message
							? response.data.response.message.toString()
							: "An Error occurred"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occurred: " + error.message);
			});
	};
	const copyPaymentLink = async () => {
		await navigator.clipboard.writeText(showLink.link);
		toast.success("Link copied");
	};

	useEffect(() => {
		if (type === "details") {
			getLoanApplications({ loan_app_enc_id: loan_id, type: type });
		}
	}, [type]);

	// useEffect(() => {
	// 	if (loan_id) {
	// 		setIsHidden(true);
	// 	}
	// }, []);

	// const copyLink =
	// 	window.location.origin +
	// 	"/account/loan-applications/" +
	// 	selectedApplication.loan_app_enc_id;

	// const copy = async () => {
	// 	await navigator.clipboard.writeText(copyLink);
	// 	toast.success("Link copied");
	// };

	// const downloadQr = () => {
	// 	window
	// 		.open(
	// 			`${process.env.REACT_APP_BASE_API_URL}${
	// 				process.env.REACT_APP_API_URL_VERSION
	// 			}loan-applications/download-qr-code?url=${copyLink}&name=Qr-code${
	// 				selectedApplication.application_number
	// 					? selectedApplication.application_number
	// 					: ""
	// 			}`,
	// 			"_blank"
	// 		)
	// 		.focus();
	// };

	const location = (type) => {
		if ("geolocation" in navigator) {
			setCollectNow(type);
			navigator.geolocation.getCurrentPosition(success);
		} else {
			navigator.geolocation.getCurrentPosition(locerrors);
		}
	};

	const success = async pos => {
		var crd = pos.coords;
		Geocode.fromLatLng(crd.latitude, crd.longitude).then(
			response => {
				let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
				for (let i = 0; i < response.results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
						case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
							// case "country":
							// 	country = response.results[0].address_components[i].long_name;
							// 	break;
						case "postal_code":
							postalCode = response.results[0].address_components[i].long_name;
							break;
						case "premise":
							route = response.results[0].address_components[i].long_name;
							break;
						case "sublocality":
							sublocality = response.results[0].address_components[i].long_name;
							break;
						case "sublocality_level_2":
							sublocality_2 = response.results[0].address_components[i].long_name;
						}
					}
				}
				if (route && sublocality_2 && sublocality) {
					streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
				} else if (route && sublocality) {
					streetAdd = route + ", " + sublocality;
				} else {
					streetAdd = route;
				}
				setVals({
					latitude: crd.latitude,
					longitude: crd.longitude,
					postal_code: postalCode,
					streetAdd,
					city,
					state
				});
			},
			error => {
				console.error(error);
			}
		);
	};

	const locerrors = err => {
		toast.error("Please enable location to continue");
		alert(`ERROR(${err.code}): ${err.message}`);
	};

	const fetchmylocation = type => {
		console.log(type);
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				if (result.state === "granted") {
					location(type);
					// If granted then you can directly call your function here
				} else if (result.state === "prompt") {
					location(type);
				} else if (result.state === "denied") {
					toast.error("Please enable location to continue");
					// If denied then you have to show instructions to enable location
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			// alert("Sorry Not available!");
			toast.error("Please enable location to continue");
		}
	};

	return (
		<>
			{/* {selectedApplication?.loan_status === "Disbursed" || selectedApplication?.loan_status === "Sanctioned" &&
			emiDetails &&
			process.env.REACT_APP_TYPE === "DEV" ? (
					<div className="pay-now-emi mb-3 nd-shadow bg-white">
						<div className="row align-items-center">
							<div className="col-xl-8 col-lg-6 col-md-12 col-12">
								<div className="pay-emi-des p-5">
									{selectedApplication?.loan_status === "Sanctioned" ? (
										<h2 className="text-capitalize lh-md fw-bold">
											Congratulations! Your loan has been <span className="text-secondary"> Sanctioned. </span>
										</h2>
									) : (
										<h2 className="text-capitalize lh-md fw-bold">
											Exciting Update! Your loan has been <span className="text-secondary"> Disbursed. </span>
										</h2>
									)}
									
									<p className="fw-medium info">
										<p className="mb-1">Stay updated on your Loan Details:</p>
										{selectedApplication?.loan_amount && (
											<p className="mb-1">
        										Amount: ₹{" "} 
												<span className="fw-bold fs-text">
													{parseFloat(selectedApplication.loan_amount).toLocaleString("en-IN")}
												</span>
											</p>
										)}

										{(selectedApplication?.loan_status === "Disbursed" || selectedApplication?.loan_status === "Sanctioned") && selectedApplication.roi !== null && (
											<p className="mb-1">
    											Interest Rate: {" "} {selectedApplication.roi}
											</p>
										)}

										{selectedApplication?.emi_amount && (
											<p className="mb-1">
											Monthly EMI: ₹{" "}
												<span className="fw-bold fs-text"> {parseFloat(selectedApplication.emi_amount).toLocaleString("en-IN")}</span>
											</p>
										)}

										{emiDetails?.emi_date && (
											<p>
											First EMI Date: {" "}
												<span className="fw-bold text-primary fs-text">{moment(emiDetails.emi_date).format("DD-MMM-YYYY")}</span>
											</p>
										)}
									</p>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-12 col-12">
								<div className="pay-emi-img py-4">
									<img src="/./images/pages/dashboard/loan-approved.png" className="img-fluid" alt="Loan Approved And Disbursed" />
								</div>
							</div>
						</div>
					</div>
				): ""} */}
			{selectedApplication?.loan_status === "Disbursed" &&
			emiDetails &&
			process.env.REACT_APP_TYPE === "DEV" ? (
					<div className="pay-now-emi mb-3 nd-shadow bg-white mobile-view-hidden">
						<div className="row align-items-center">
							<div className="col-xl-4 col-lg-6 col-md-12 col-12">
								<div className="pay-emi-img py-4">
									<img src="/./images/pages/dashboard/overdue-icon.png" className="img-fluid" alt="Overdue Icon" />
								</div>
							</div>
							<div className="col-xl-8 col-lg-6 col-md-12 col-12">
								<div className="pay-emi-des p-5">
									<h2 className="text-capitalize lh-md fw-bold">
									Overdue Loan Payments</h2>
									<p className="fw-medium info">
								   Due Date :{" "}
										<span className="fw-bold text-primary fs-text">{emiDetails.emi_date ? moment(emiDetails.emi_date).format("DD-MMM-YYYY") : ""}</span> <br />
										Overdue Amount : <span className="text-danger fs-3"> ₹{" "}</span> 
										<span className="fw-bold fs-3 text-danger">{emiDetails.overdue_amount ? parseFloat( emiDetails.overdue_amount).toLocaleString("en-IN" ): "-"}</span>
									</p> 
									{/* <button className="btn-primary btn" onClick={() => fetchmylocation("overdue")}>
										{selectedApplication.paid ? "Pay Advance" : "Pay Now"}
									</button> */}
								</div>
							</div>
							
							
						</div>
					</div>
				) : (
					""
				)}

			{selectedApplication?.loan_status === "Disbursed" &&
			emiDetails &&
			process.env.REACT_APP_TYPE === "DEV" ? (
					<div className="pay-now-emi mb-3 nd-shadow bg-white mobile-view-hidden">
						<div className="row align-items-center">
							<div className="col-xl-8 col-lg-6 col-md-12 col-12">
								<div className="pay-emi-des p-5">
									<h2 className="text-capitalize lh-md fw-bold">
								Take control and <span className="text-secondary"> pay now </span>to stay on top
								of your expenses
									</h2>
									<p className="mb-1">Keep track of your financial commitments:</p>
									<p className="mb-1">
										Next EMI due on :{" "}
										<span className="fw-bold text-primary fs-text">{emiDetails.emi_date ? moment(emiDetails.emi_date).format("DD-MMM-YYYY") : ""}</span> 
									</p>
									<p className="mb-2">
										Amount : ₹{" "}
										<span className="fw-bold fs-text"> {selectedApplication.target_collection_amount ? parseFloat(selectedApplication.target_collection_amount).toLocaleString(
											"en-IN"
										  ) : "0"}</span>
									</p>
									<button className="btn-primary btn" onClick={() => fetchmylocation("collect")}>
										{selectedApplication.paid ? "Pay Advance" : "Pay Now"}
									</button>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-12 col-12">
								<div className="pay-emi-img py-4">
									<img src="/./images/pages/dashboard/EMI.png" className="img-fluid" alt="EMI illustration" />
								</div>
							</div>
						</div>
					</div>
				): ""}

			<div className="mobile-view-hidden">
				<IndividualLoanDetails type={"web"} />
			</div>
			
			{/* {selectedApplication?.loanCoApplicants?.length ? (
				<div className="nd-shadow bg-white p-4 mb-3">
					<div className="row">
						<div className="col-md-12">
							<h3 className="text-primary fw-bold">Borrowers</h3>
						</div>
						{selectedApplication.loanCoApplicants.map((value, index) => {
							return (
								<div
									className="col-xl-4 col-lg-6 col-md-12 col-12"
									key={`b-card-${index}`}
								>
									<div className="borrower-main p-3 mb-3 rounded-3 shadow-el position-relative">
										<div className="co-borrower-name">
											{value.borrower_type}
										</div>
										<div className="borrower-upper disFlex align-items-center mt-2">
											<div className="b-logo">
												<img
													src={
														value.image
															? value.image
															: "/images/pages/re-empower/avatar.svg"
													}
													alt={value.name}
													className="img-fluid"
												/>
											</div>
											<div className="b-name-details mx-2">
												<h3 className="fs-4">{value.name}</h3>
												<p>Gender : {value.gender}</p>
												<p>
													DOB :{" "}
													{value.co_applicant_dob
														? moment(value.co_applicant_dob).format(
															"DD MMM YYYY"
														  )
														: "-"}
												</p>
											</div>
											<div className="borrower-middle w-100 mt-3">
												<div className="state-added">
													<p className="mb-1">
														{" "}
														Current Address:{" "}
														<span>
															{value.address ? value.address : "-"}
														</span>
													</p>
													<p className="mb-1">
														Permanent Address:{" "}
														<span>
															{value.address ? value.address : "-"}
														</span>
													</p>
													<div className="disFlex justify-content-between">
														<p className="mb-1 w-50">
															State:{" "}
															<span>
																{value.state ? value.state : "-"}
															</span>
														</p>
														<p className="mb-1 w-50">
															City:{" "}
															<span>
																{value.city ? value.city : "-"}
															</span>
														</p>
													</div>
													<div className="disFlex justify-content-between w-100">
														<p className="mb-1 w-50">
															Mobile:{" "}
															<span>
																{value.phone ? value.phone : "-"}
															</span>
														</p>
														{value.borrower_type !== "Borrower" &&
														value.relation ? (
																<p className="mb-1 w-50">
																Relation :{" "}
																	<span>{value.relation}</span>
																</p>
															) : (
																""
															)}

														<p className="mb-1 w-50">
															Marital Status :{" "}
															<span>{value.marital_status}</span>
														</p>
														<p className="mb-1 w-50">
															Father Name :{" "}
															<span>{value.father_name}</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				""
			)} */}

			<Modal show={collectNow}  onHide={() => setCollectNow(false)} size={"md"} centered>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)} className="form">
						<fieldset>
							<div className="container">
								<div className="row">
									<div className="col-12 text-center mb-3">
										<h2>{collectNow === "collect" ? "Pay EMI" : collectNow === "overdue" ? "Pay Overdue Amount":"" }</h2>
									</div>
								</div>
								{/* <div className="row">
									<div className="col-lg-12">
										<label className="form-label mb-1" htmlFor="">
											Pay Through <span style={{ color: "red" }}>*</span>
										</label>
									</div>
									<div className="col-md-12 mb-3">
										<div className="radio-wrapper">
											<div className="radio-item">
												<input
													type="radio"
													name="payment_delay"
													className="radioInput"
													id="qr"
													value="1"
													{...register("pay_by", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="qr"
													className="option option-1"
													style={{
														justifyContent: "flex-start",
														margin: "0 0 10px 0"
													}}
												>
													<div className="dot"></div>
													<span>QR</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="payment_delay"
													className="radioInput"
													id="link"
													value="0"
													{...register("pay_by", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="link"
													className="option option-1"
													style={{
														justifyContent: "flex-start",
														marginBottom: "10px"
													}}
												>
													<div className="dot"></div>
													<span>Link</span>
												</label>
											</div>
											<ErrorMessage
												errors={errors}
												name="pay_by"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
												)}
											/>
										</div>
									</div>
								</div> */}
								<div className="row">
									<div className="col-md-6">
										<p className="d-flex flex-wrap">
											{collectNow === "collect" ? "EMI Date :": collectNow === "overdue" ? "Overdue Amount Date :" : ""}	
											<span className="fw-medium text-gray-700 mx-2 text-capitalize">
												{collectNow === "collect" || collectNow === "overdue"  && emiDetails && emiDetails.emi_date
													? moment(emiDetails.emi_date).format(
														"DD MMM YYYY"
													  )
													:  ""
												}
											</span>
										</p>
									</div>
									<div className="col-md-6">
										<p className="d-flex flex-wrap">
    									{collectNow === "collect" ? "EMI Amount :": collectNow === "overdue" ? "Overdue Amount :" : ""}
											<span className="fw-medium text-gray-700 mx-2 text-capitalize">
												₹{" "}
												{collectNow === "collect" && selectedApplication.target_collection_amount
													? parseFloat(selectedApplication.target_collection_amount).toLocaleString("en-IN")
													: collectNow === "overdue" && selectedApplication.overdue_amount
														? parseFloat(selectedApplication.overdue_amount).toLocaleString("en-IN")
														: "0"}
											</span>
										</p>

									</div>
								</div>
								<div className="text-center mt-3">
									<button type="submit" className="btn btn-primary">
										Generate Qr
									</button>
								</div>
							</div>
						</fieldset>
					</form>
				</Modal.Body>
			</Modal>

			<Modal show={showLink} onHide={() => setShowLink(false)} size={"lg"} centered>
				<Modal.Body>
					<fieldset>
						<div className="container">
							<div className="row align-items-center">
								{showLink && showLink.type === "1" ? (
									<>
										<div className="col-12 text-center">
											<h3>Payment QR Code</h3>
											<img
												src={showLink.link}
												alt="Qr Code"
												className="qrImage"
											/>
										</div>
									</>
								) : showLink && showLink.type === "0" ? (
									<div className="col-12">
										<label className="form-label d-block titles">
											Payment Link
										</label>
										<input
											type="text"
											className="form-control"
											value={showLink.link}
											disabled={true}
										/>
										<button
											type="button"
											className="btn btn-primary m-auto d-block mt-3"
											onClick={copyPaymentLink}
										>
											Copy
										</button>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</fieldset>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default CandidateDashboardLoanDetails;
