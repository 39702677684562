import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { useContext } from "react";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import moment from "moment/moment";
import "swiper/css/navigation";

SwiperCore.use([Autoplay]);

const Announcements = () => {
	const { noticeTextsOnly } = useContext(OrganizationDashboardContext);
	return (
		<>
			<div className="announcement-main">
				<div className="announcement-slider">
					{noticeTextsOnly && noticeTextsOnly.length ? (
						<Swiper
							className="swiper-wrapper"
							spaceBetween={30}
							navigation={true}
							modules={[Navigation]}
							slidesPerView={1}
							autoplay={true}
							loop={true}
							speed={3000}
							autoHeight={true}
							breakpoints={{
								320: {
									slidesPerView: 1,
									spaceBetween: 10
								},
								768: {
									slidesPerView: 1,
									spaceBetween: 10
								},
								1024: {
									slidesPerView: 1,
									spaceBetween: 10
								}
							}}
						>
							{noticeTextsOnly.map(value => {
								return (
									<SwiperSlide key={`noticeMessages${value.notice_enc_id}`}>
										<div className="announcements">
											<div className="announcement-title">
												<h2>
													<HiOutlineSpeakerphone
														style={{
															color: "#00a0e3"
														}}
													/>{" "}
													Announcements
												</h2>
											</div>
											<div className="announcement-msg">
												<p>{value.notice}</p>
											</div>
											<div className="date-of-announcement">
												<p>
													{moment(value.created_on).format(
														"DD MMM YYYY h:mm a"
													)}
												</p>
											</div>
										</div>
									</SwiperSlide>
								);
							})}
						</Swiper>
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
};

export default Announcements;
