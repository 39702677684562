const AppReducer = (state, action) => {
	switch (action.type) {
	case "SET_STATS":
		return {
			...state,
			stats: action.payload
		};
	case "SET_LOAN_APPLICATION":
		return {
			...state,
			loan_applications: action.payload
		};
	case "REMOVE_USER_DATA":
		return {
			...state,
			user: ""
		};
	default:
		return state;
	}
};
export default AppReducer;
