const LoanProductsReducer = (state, action) => {
	switch (action.type) {
	case "SET_LOAN_PRODUCTS_PURPOSE_DETAIL":
		return {
			...state,
			productPurposeData: action.payload
		};
	case "SET_LOAN_PRODUCTS_STATUS_DETAIL":
		return {
			...state,
			productStatusData: action.payload
		};
	case "SET_LOAN_PRODUCTS_DOCUMENT_DETAIL":
		return {
			...state,
			productDocumentData: action.payload
		};
	case "SET_LOAN_PRODUCTS_IMAGES_DETAIL":
		return {
			...state,
			productImagesData: action.payload
		};
	case "SET_LOAN_PRODUCTS_PENDENCIES_DETAIL":
		return {
			...state,
			productPendenciesData: action.payload
		};
	case "SET_LOAN_PRODUCTS_CHARGES_DETAIL":
		return {
			...state,
			productDisbursementCharges: action.payload
		};
	case "SET_LOAN_PRODUCTS_FEE_DETAIL":
		return {
			...state,
			productFeeData: action.payload
		};
	default:
		return state;
	}
};
export default LoanProductsReducer;
