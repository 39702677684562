import "../../../assets/css/formstyles.css";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import BasicDetails from "./BasicDetails";
import CompanyDetails from "./CompanyDetails";
import { toast } from "react-toastify";

export default function DealerStep1({
	formStep,
	nextFormStep,
	setFormData,
	isUser,
	formIsLoading,
	onSubmitForm
}) {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
		clearErrors
	} = useForm();
	const [numberExist, setNumberExist] = useState(false);
	const [emailExist, setEmailExist] = useState(false);

	const onSubmit = values => {
		if (numberExist) {
			toast.error("Phone number already exists");
			return false;
		}
		if (emailExist) {
			toast.error("email already exists");
			return false;
		}
		setFormData(values);
		if (!isUser) {
			nextFormStep();
		} else {
			onSubmitForm(values);
		}
	};
	return (
		<>
			<div className={formStep === 0 ? "showForm" : "hideForm"}>
				<form className="row" onSubmit={handleSubmit(onSubmit)}>
					<div className="row mb-3">
						<CompanyDetails register={register} errors={errors} isUser={isUser} />
					</div>
					<BasicDetails 
						register={register} 
						errors={errors} 
						isUser={isUser} 
						setNumberExist={setNumberExist}
						clearErrors={clearErrors} 
						setError={setError}
						setEmailExist={setEmailExist}
					/>
					<div className="col-12 text-center">
						{isUser ? (
							<>
								{formIsLoading ? (
									<button
										type="button"
										className="btn btn-primary"
										disabled={true}
									>
										<img src="/images/pages/home/loader.svg" />
									</button>
								) : (
									<button id="Submit" name="Submit" className="btn btn-primary">
										Submit
									</button>
								)}
							</>
						) : (
							<button type="submit" className="btn btn-primary">
								Next
							</button>
						)}
					</div>
				</form>
			</div>
		</>
	);
}
