import MinMaxFilter from "../MinMaxFilter";

const CollectionReportBranchesHeading = ({ name, search_id, searchArray, setSearchArray, clearFilters, sortArr, setSortArr }) => {
	return (
		<>
			<div className="w-100">
				<h3 className="text-center">{name}</h3>
				<div className="d-flex justify-content-around fix-collection-w">
					<span>
						<MinMaxFilter
							title={"Total Cases"}
							id={`${search_id}_cases_count`}
							placeholder="Total Cases"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</span>
					<span>
						<MinMaxFilter
							title={"Collection in Cases"}
							id={`${search_id}_collected_cases_count`}
							placeholder="Collection in Cases"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</span>
					<span>
						<MinMaxFilter
							title={"Target Amount"}
							id={`${search_id}_target_amount`}
							placeholder="Target Amount"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</span>
					<span>
						<MinMaxFilter
							title={"Verified Amount"}
							id={`${search_id}_collected_verified_amount`}
							placeholder="Verified Amount"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</span>
					<span>
						<MinMaxFilter
							title={"Unverified Amount"}
							id={`${search_id}_collected_unverified_amount`}
							placeholder="Unverified Amount"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</span>
				</div>
			</div>
		</>
	);
};

export default CollectionReportBranchesHeading;
