import "../../../assets/css/formstyles.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FaInfoCircle, FaRupeeSign } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { jsPDF } from "jspdf";
import { RiLoader4Line } from "react-icons/ri";

let Pagewidth2;
let doc2;
let Pageheight2;
let pdf_uri2;
let base_uri2 = "";

export default function Step1({ formStep, nextFormStep, setFormData, defaultData }) {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors }
	} = useForm();
	const loanType = watch("loan_type");
	const vehicleType = watch("vehicle_type");
	const selectedMaker = watch("make");
	const numberOfCoBorrowers = watch("number_of_co_borrowers");
	const [calculateAsUpFront, setCalculateAsUpFront] = useState({
		pf: false,
		hospicare: false,
		medical_loan: false
	});
	const [isImageUploading, setIsImageUploading] = useState(false);
	const [dataToCalculate, setDataToCalculate] = useState({
		invoice: "",
		rc: "",
		insurance: "",
		hypothecation: "",
		accessories: "",
		other: "",
		pf: "",
		hospicare: "",
		medical_loan: "",
		margin_money: ""
	});
	const [calculationResult, setCalculationResult] = useState({
		total: 0,
		net_total: 0,
		loan_amount: 0,
		ltv: 0,
		capitalLtv: 0
	});
	// const numberValidateRegex = /^[0-9]\d*$/;
	// const numberValidateRegex = /^[1-9]\d*(\.\d+)?$/;
	const numberValidateRegex = /^[+-]?\d*(?:[.,]\d*)?$/;
	const uploadFile = pdf => {
		setIsImageUploading(true);
		const formData = new FormData();
		formData.append("file", pdf);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}utilities/file-upload`,
				formData
			)
			.then(response => {
				setIsImageUploading(false);
				if (response.data.response.status === 200) {
					setValue("chassis_number_doc", response.data.response.path);
					toast.success("Document Successfully Uploaded");
				} else {
					setValue("chassis_number_doc", "");
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setIsImageUploading(false);
			});
	};
	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		// base = result_base64;
		base_uri2 = result_base64;
		// base_uri2 = base;
		return result_base64;
	};
	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};
	const callback = async (width, height, src, i) => {
		let result_base64 = await new Promise(resolve => {
			const widthRatio = Pagewidth2 / width;
			const heightRatio = Pageheight2 / height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
			const canvasWidth = width * ratio;
			const canvasHeight = height * ratio;
			const marginX = (Pagewidth2 - canvasWidth) / 2;
			const marginY = (Pageheight2 - canvasHeight) / 2;
			doc2.addImage(src, "JPEG", marginX, marginY, canvasWidth, canvasHeight, i);

			pdf_uri2 = doc2.output("blob");
			resolve(true);
		});
		return result_base64;
	};
	const createPDF = async imgData => {
		if (imgData.length == "undefined" || imgData.length === 0) {
			alert("select image");
			return false;
		}
		let result_base64 = await new Promise(resolve => {
			doc2 = new jsPDF("p", "pt", "a4");
			Pagewidth2 = doc2.internal.pageSize.width;
			Pageheight2 = doc2.internal.pageSize.height;

			const img = new Image();
			img.src = imgData;
			img.length = imgData.length;
			img.onload = function () {
				img.imgWidth = img.naturalWidth;
				img.imgHeight = img.naturalHeight;
				callback(img.imgWidth, img.imgHeight, img.src, img.i, img.length).then(() => {
					resolve(true);
				});
			};
		});
		return result_base64;
	};
	const handleFileInputChange = input => {
		if (!input.target.files) {
			return false;
		}
		const file = input.target.files[0];
		const fileExtenstion = file.name.split(".").pop().toLowerCase();
		if (fileExtenstion !== "pdf") {
			getUri(file).then(() => {
				createPDF(base_uri2).then(() => {
					uploadFile(pdf_uri2);
				});
			});
		} else {
			console.log("file");
			console.log(file);
			uploadFile(file);
		}
	};
	const onSubmit = values => {
		if (values.make === "Other") {
			values["make"] = values.make_name;
		}
		let emi_schedule = [];
		if (values.roi_options === "FLAT") {
			var month = parseInt(values.tenure_period);
			let firstemi =
				(parseFloat(values.loan_amount) +
					((parseFloat(values.roi) / 100) *
						parseFloat(values.loan_amount) *
						parseFloat(values.tenure_period)) /
						12) /
				parseFloat(values.tenure_period);

			let tempfirstinterest = parseFloat(
				((parseFloat(values.roi) / 100) *
					parseFloat(values.loan_amount) *
					parseFloat(values.tenure_period)) /
					12
			).toFixed(2);

			for (let i = 1; i <= month; i++) {
				let obj = {};
				obj["month"] = i;
				obj["emi"] = Math.round(firstemi);
				obj["interest"] = Math.round(tempfirstinterest / month);
				obj["principal"] = Math.round(Math.round(firstemi) - tempfirstinterest / month);
				obj["loan_balance"] = Math.max(
					Math.round(
						emi_schedule.length
							? emi_schedule[emi_schedule.length - 1].loan_balance -
									(Math.round(firstemi) - tempfirstinterest / month)
							: values.loan_amount -
									(Math.round(firstemi) - tempfirstinterest / month)
					),
					0
				);
				emi_schedule.push(obj);
			}
		} else {
			var amount = parseInt(values.loan_amount);
			var fv = 0;
			var emi;
			var month2 = parseInt(values.tenure_period);
			var rate = parseFloat(values.roi / 1200);
			if (rate === 0) {
				emi = (amount + fv) / month2;
			} else {
				emi =
					(rate * amount * Math.pow(1 + rate, month2)) / (Math.pow(1 + rate, month2) - 1);
			}
			let tempsecondemi = Math.abs(Math.round(emi));
			// let tempsecondinterest = parseFloat(
			// 	parseFloat(tempsecondemi) * month2 - parseFloat(values.loan_amount)
			// ).toFixed(2);

			// let tempsecondpayment = parseFloat(tempsecondinterest) + parseFloat(values.loan_amount);
			for (let i = 1; i <= month2; i++) {
				let obj = {};
				obj["month"] = i;
				obj["emi"] = Math.round(tempsecondemi);
				// obj['interest'] = Math.round(tempfirstinterest / month);
				let tempInt = Math.round(
					emi_schedule.length
						? (emi_schedule[emi_schedule.length - 1].loan_balance *
								parseFloat(values.roi)) /
								month2 /
								100
						: (parseFloat(values.loan_amount) * parseFloat(values.roi)) / month2 / 100
				);
				obj["interest"] = tempInt;
				let tempPrincipal = Math.round(tempsecondemi) - tempInt;
				obj["principal"] = tempPrincipal;
				obj["loan_balance"] = Math.max(
					emi_schedule.length
						? emi_schedule[emi_schedule.length - 1].loan_balance - tempPrincipal
						: parseFloat(values.loan_amount) - tempPrincipal,
					0
				);
				emi_schedule.push(obj);
			}
		}
		values["emi"] = emi_schedule;
		console.log(emi_schedule);
		console.log(values);
		window.scrollTo({ top: 0, behavior: "smooth" });
		setFormData(values);
		nextFormStep();
	};
	useEffect(() => {
		if (defaultData.loan_app_enc_id) {
			setValue("file_number", defaultData.application_number);
			setValue("number_of_co_borrowers", defaultData.loanCoApplicants.length);
		}
	}, [defaultData]);
	useEffect(() => {
		register("loan_amount");
		register("total");
		register("net_total");
		register("ltv");
	}, []);
	useEffect(() => {
		let tempTotal = 0;
		let tempNetTotal = 0;
		let tempCapitalNetTotal = 0;
		let tempLoanAmount = 0;
		let tempCapitalLoanAmount = 0;
		let tempLtv = 0;
		let tempCapitalLtv = 0;
		tempTotal += dataToCalculate.invoice ? parseFloat(dataToCalculate.invoice) : 0;
		tempTotal += dataToCalculate.rc ? parseFloat(dataToCalculate.rc) : 0;
		tempTotal += dataToCalculate.insurance ? parseFloat(dataToCalculate.insurance) : 0;
		tempCapitalNetTotal += tempTotal;
		tempTotal += dataToCalculate.hypothecation ? parseFloat(dataToCalculate.hypothecation) : 0;
		tempTotal += dataToCalculate.accessories ? parseFloat(dataToCalculate.accessories) : 0;
		tempTotal += dataToCalculate.other ? parseFloat(dataToCalculate.other) : 0;
		tempNetTotal += tempTotal;
		tempNetTotal +=
			dataToCalculate.pf && !calculateAsUpFront.pf ? parseFloat(dataToCalculate.pf) : 0;
		tempCapitalNetTotal += dataToCalculate.pf ? parseFloat(dataToCalculate.pf) : 0;
		tempNetTotal +=
			dataToCalculate.hospicare && !calculateAsUpFront.hospicare
				? parseFloat(dataToCalculate.hospicare) * numberOfCoBorrowers
				: 0;
		tempCapitalNetTotal += dataToCalculate.hospicare
			? parseFloat(dataToCalculate.hospicare) * numberOfCoBorrowers
			: 0;
		tempNetTotal +=
			dataToCalculate.medical_loan && !calculateAsUpFront.medical_loan
				? parseFloat(dataToCalculate.medical_loan)
				: 0;
		tempCapitalNetTotal += dataToCalculate.medical_loan
			? parseFloat(dataToCalculate.medical_loan)
			: 0;
		tempLoanAmount +=
			parseFloat(tempNetTotal) -
			(dataToCalculate.margin_money ? parseFloat(dataToCalculate.margin_money) : 0);
		tempCapitalLoanAmount +=
			parseFloat(tempCapitalNetTotal) -
			(dataToCalculate.margin_money ? parseFloat(dataToCalculate.margin_money) : 0);
		tempLtv +=
			tempLoanAmount > 0 && tempNetTotal > 0 ? (tempLoanAmount / tempNetTotal) * 100 : 0;
		tempCapitalLtv +=
			tempCapitalLoanAmount > 0 && tempCapitalNetTotal > 0
				? (tempCapitalLoanAmount / tempCapitalNetTotal) * 100
				: 0;
		setCalculationResult({
			total: tempTotal,
			net_total: tempNetTotal,
			loan_amount: tempLoanAmount,
			ltv: tempLtv.toFixed(2),
			capitalLtv: tempCapitalLtv.toFixed(2)
		});
		setValue("total", tempTotal);
		setValue("net_total", tempNetTotal);
		setValue("loan_amount", tempLoanAmount);
		setValue("ltv", tempLtv);
	}, [dataToCalculate, calculateAsUpFront]);

	return (
		<div className={formStep === 0 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<h4>Loan Details</h4>
				<Row>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label" htmlFor="first_name">
								Product Name
							</label>
							<div className="d-block">
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-1"
											value="1"
											{...register("loan_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-1" className="option option-1">
											<div className="dot"></div>
											<span>Vehicle loan</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-6"
											value="6"
											{...register("loan_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-6" className="option option-6">
											<div className="dot"></div>
											<span>E-Rickshaw Loan</span>
										</label>
									</div>
									{/* <div className="radio-item">*/}
									{/*    <input type="radio" name="select" className="radioInput" id="option-3" value="3" {...register("loan_type", {*/}
									{/*        required: 'This is required.',*/}
									{/*    })}/>*/}
									{/*    <label htmlFor="option-3" className="option option-3">*/}
									{/*        <div className="dot"></div>*/}
									{/*        <span>Business Loan</span>*/}
									{/*    </label>*/}
									{/* </div>*/}
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-2"
											value="2"
											{...register("loan_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-2" className="option option-2">
											<div className="dot"></div>
											<span>Personal Loan</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-4"
											value="4"
											{...register("loan_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-4" className="option option-4">
											<div className="dot"></div>
											<span>Education Loan</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-5"
											value="5"
											{...register("loan_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-5" className="option option-5">
											<div className="dot"></div>
											<span>Loan Against Property</span>
										</label>
									</div>
								</div>
								<ErrorMessage
									errors={errors}
									name="loan_type"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
					</div>
					{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
					{/*    <div className="mb-3">*/}
					{/*        <label className="form-label">Loan Account Number</label>*/}
					{/*        <input placeholder="Loan Account Number" className="form-control"*/}
					{/*               {...register("loan_account_number", {*/}
					{/*                   required: 'This is required.'*/}
					{/*               })}*/}
					{/*        />*/}
					{/*        <ErrorMessage*/}
					{/*            errors={errors}*/}
					{/*            name="loan_account_number"*/}
					{/*            render={({message}) => <p*/}
					{/*                style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
					{/*        />*/}
					{/*    </div>*/}
					{/* </div>*/}
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">File Number</label>
							<input
								placeholder="File Number"
								className="form-control"
								disabled={defaultData.application_number ? true : false}
								{...register("file_number", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="file_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					{loanType === "1" || loanType === "6" ? (
						<>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label">Vehicle Type</label>
									<div className="d-block">
										<div className="radio-wrapper">
											<div className="radio-item">
												<input
													type="radio"
													name="vehicle_type"
													value="New"
													className="radioInput"
													id="optionVehicleType-1"
													{...register("vehicle_type", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="optionVehicleType-1"
													className="option option-1"
												>
													<span>New</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="vehicle_type"
													value="Old"
													className="radioInput"
													id="optionVehicleType-2"
													{...register("vehicle_type", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="optionVehicleType-2"
													className="option option-2"
												>
													<span>Old</span>
												</label>
											</div>
										</div>
										<ErrorMessage
											errors={errors}
											name="vehicle_type"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
							</div>
							{vehicleType === "Old" ? (
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="mb-3">
										<label className=" form-label">Vehicle Type Option</label>
										<div className="d-block">
											<div className="radio-wrapper">
												<div className="radio-item">
													<input
														type="radio"
														name="vehicle_type_option"
														value="Purchase"
														className="radioInput"
														id="optionVehicleTypeOption-1"
														{...register("vehicle_type_option", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="optionVehicleTypeOption-1"
														className="option option-1"
													>
														<span>Purchase</span>
													</label>
												</div>
												<div className="radio-item">
													<input
														type="radio"
														name="vehicle_type_option"
														value="Pre-Owned"
														className="radioInput"
														id="optionVehicleTypeOption-2"
														{...register("vehicle_type_option", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="optionVehicleTypeOption-2"
														className="option option-2"
													>
														<span>Pre-Owned</span>
													</label>
												</div>
											</div>
											<ErrorMessage
												errors={errors}
												name="vehicle_type_option"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</div>
								</div>
							) : (
								""
							)}
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label d-block" htmlFor="last_name">
										Make
									</label>
									{loanType === "1" ? (
										<div className="radio-wrapper simple">
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="makeoption-1"
													value="Hero"
													{...register("make", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="makeoption-1"
													className="option option-1"
												>
													<span>Hero</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="makeoption-2"
													value="Bajaj"
													{...register("make", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="makeoption-2"
													className="option option-2"
												>
													<span>Bajaj</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="makeoption-3"
													value="Honda"
													{...register("make", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="makeoption-3"
													className="option option-1"
												>
													<span>Honda</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="makeoption-4"
													value="Other"
													{...register("make", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="makeoption-4"
													className="option option-2"
												>
													<span>Others</span>
												</label>
											</div>
										</div>
									) : (
										""
									)}
									{selectedMaker === "Other" || loanType === "6" ? (
										<>
											<input
												placeholder="Enter Maker Name"
												className="form-control mt-2"
												{...register("make_name", {
													required: "This is required."
												})}
											/>
											<ErrorMessage
												errors={errors}
												name="make_name"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</>
									) : (
										""
									)}
									{/* <select id={`make`} className="form-select" {...register("make", {*/}
									{/*    required: 'This is required.',*/}
									{/* })}>*/}
									{/*    <option value="">Choose Make</option>*/}
									{/*    <option value="Hero">Hero</option>*/}
									{/*    <option value="Bajaj">Bajaj</option>*/}
									{/*    <option value="TVS">TVS</option>*/}
									{/*    <option value="Royal Enfield">Royal Enfield</option>*/}
									{/*    <option value="Honda">Honda</option>*/}
									{/*    <option value="KTM">KTM</option>*/}
									{/*    <option value="Yamaha">Yamaha</option>*/}
									{/* </select>*/}
									<ErrorMessage
										errors={errors}
										name="make"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label" htmlFor="last_name">
										Vehicle Model
									</label>
									<input
										placeholder="Enter Model Name"
										className="form-control"
										{...register("vehicle_model", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="vehicle_model"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label" htmlFor="last_name">
										Manufactured Year
									</label>
									{vehicleType === "New" ? (
										<div
											className="radio-wrapper simple"
											style={{ width: "100%" }}
										>
											<div className="radio-item">
												<input
													type="radio"
													name="manufactured_year"
													className="radioInput"
													id="manufactured_year-1"
													value="2021"
													{...register("manufactured_year", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="manufactured_year-1"
													className="option option-1"
												>
													<span>2021</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="manufactured_year"
													className="radioInput"
													id="manufactured_year-2"
													value="2022"
													{...register("manufactured_year", {
														required: "This is required."
													})}
													defaultChecked={true}
												/>
												<label
													htmlFor="manufactured_year-2"
													className="option option-2"
												>
													<span>2022</span>
												</label>
											</div>
										</div>
									) : (
										<input
											placeholder="Enter Manufactured Year"
											className="form-control"
											{...register("manufactured_year", {
												required: "This is required."
											})}
										/>
									)}
									<ErrorMessage
										errors={errors}
										name="manufactured_year"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label" htmlFor="last_name">
										Chassis Number
									</label>
									<input
										placeholder="Enter Chassis Number"
										className="form-control"
										{...register("chassis_number", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="chassis_number"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label" htmlFor="loan_amt">
										Choose Chassis Number
									</label>
									<input
										type="file"
										min={1}
										accept=".png, .jpg, .jpeg, .pdf, .webp"
										placeholder="Choose File"
										className="form-control"
										{...register("temp_file2", {
											required: "This is required.",
											validate: {
												// lessThan10MB: (files) => files[0]?.size < 5000000 || "Max 5MB",
												acceptedFormats: files =>
													[
														"image/jpeg",
														"image/webp",
														"image/png",
														"application/pdf"
													].includes(files[0]?.type) ||
													"Only PNG, JPEG and PDF"
											}
										})}
										onChange={handleFileInputChange}
									/>
									<input
										type="hidden"
										{...register("chassis_number_doc", {
											required: "This is required."
										})}
									/>
									{isImageUploading ? (
										<p className="text-primary" style={{ fontSize: "13px" }}>
											<RiLoader4Line className="spin" size={14} /> Please
											wait, file is uploading
										</p>
									) : (
										""
									)}
									<ErrorMessage
										errors={errors}
										name="temp_file2"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="chassis_number_doc"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						</>
					) : (
						""
					)}
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="mb-3">
							<label className=" form-label" htmlFor="roi">
								Rate of Interest (in %)
							</label>
							<div className="d-flex flex-wrap-xs">
								<input
									type="number"
									step={0.1}
									max={99}
									min={0}
									placeholder="Enter ROI"
									className="form-control"
									{...register("roi", {
										required: "This is required.",
										// valueAsNumber: true,
										// maxLength: {
										//     value: 3,
										//     message: 'Rate of Interest is invalid',
										// },
										pattern: {
											value: numberValidateRegex,
											message: "Rate of Interest is invalid"
										}
									})}
								/>
								<div className="radio-wrapper simple" style={{ width: "100%" }}>
									<div className="radio-item">
										<input
											type="radio"
											name="roi_options"
											className="radioInput"
											id="roi_options-1"
											value="FLAT"
											{...register("roi_options", {
												required: "This is required."
											})}
											defaultChecked={true}
										/>
										<label htmlFor="roi_options-1" className="option option-1">
											<span>Flat</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="roi_options"
											className="radioInput"
											id="roi_options-2"
											value="REDUCING"
											{...register("roi_options", {
												required: "This is required."
											})}
										/>
										<label htmlFor="roi_options-2" className="option option-2">
											<span>Reducing</span>
										</label>
									</div>
								</div>
							</div>
							<ErrorMessage
								errors={errors}
								name="roi"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label" htmlFor="last_name">
								Tenure (Monthly)
							</label>
							<input
								placeholder="Enter Tenure"
								className="form-control"
								{...register("tenure_period", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="tenure_period"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label" htmlFor="last_name">
								Number Of Co-Borrowers
							</label>
							<input
								type="number"
								min={1}
								defaultValue={1}
								placeholder="Enter Number Of Co-Borrowers"
								className="form-control"
								disabled={defaultData.loanCoApplicants ? true : false}
								{...register("number_of_co_borrowers", {
									required: "This is required."
								})}
							/>
							`
							<ErrorMessage
								errors={errors}
								name="number_of_co_borrowers"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<h4 className="mt-3">LTV Calculator</h4>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-between align-items-center w-100 flex-wrap-xs">
							<div>Vehicle Invoice</div>
							<div>
								<input
									type="number"
									min={1}
									placeholder="Enter Vehicle Amount"
									className="form-control"
									{...register("invoice", {
										required: "This is required."
									})}
									onChange={e => {
										setDataToCalculate({
											...dataToCalculate,
											invoice: e.target.value
										});
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-between align-items-center w-100 flex-wrap-xs">
							<div>RC</div>
							<div>
								<input
									type="number"
									min={1}
									placeholder="Enter RC Amount"
									className="form-control"
									{...register("rc", {
										// required: 'This is required.',
									})}
									onChange={e => {
										setDataToCalculate({
											...dataToCalculate,
											rc: e.target.value
										});
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-between align-items-center w-100 flex-wrap-xs">
							<div>Insurance</div>
							<div>
								<input
									type="number"
									min={1}
									placeholder="Enter Insurance Amount"
									className="form-control"
									{...register("insurance", {
										// required: 'This is required.',
									})}
									onChange={e => {
										setDataToCalculate({
											...dataToCalculate,
											insurance: e.target.value
										});
									}}
								/>
							</div>
						</div>
					</div>
					{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">*/}
					{/*    <div className="calculateItem my-1 d-flex justify-content-between align-items-center w-100 flex-wrap-xs">*/}
					{/*        <div>*/}
					{/*            Hypothecation*/}
					{/*        </div>*/}
					{/*        <div>*/}
					{/*            <input type="number" min={0} placeholder="Enter Hypothecation Amount" className="form-control"*/}
					{/*                   {...register("hypothecation", {*/}
					{/*                       // required: 'This is required.',*/}
					{/*                   })}*/}
					{/*                   onChange={e => {*/}
					{/*                       setDataToCalculate({*/}
					{/*                           ...dataToCalculate,*/}
					{/*                           hypothecation: e.target.value*/}
					{/*                       });*/}
					{/*                   }}*/}
					{/*            />*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/* </div>*/}
					{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">*/}
					{/*    <div className="calculateItem my-1 d-flex justify-content-between align-items-center w-100 flex-wrap-xs">*/}
					{/*        <div>*/}
					{/*            Accessories*/}
					{/*        </div>*/}
					{/*        <div>*/}
					{/*            <input type="number" min={0} placeholder="Enter Accessories Amount" className="form-control"*/}
					{/*                   {...register("accessories", {*/}
					{/*                       // required: 'This is required.',*/}
					{/*                   })}*/}
					{/*                   onChange={e => {*/}
					{/*                       setDataToCalculate({*/}
					{/*                           ...dataToCalculate,*/}
					{/*                           accessories: e.target.value*/}
					{/*                       });*/}
					{/*                   }}*/}
					{/*            />*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/* </div>*/}
					{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">*/}
					{/*    <div className="calculateItem my-1 d-flex justify-content-between align-items-center w-100 flex-wrap-xs">*/}
					{/*        <div>*/}
					{/*            Others*/}
					{/*        </div>*/}
					{/*        <div>*/}
					{/*            <input type="number" min={0} placeholder="Enter Other Amount" className="form-control"*/}
					{/*                   {...register("other", {*/}
					{/*                       // required: 'This is required.',*/}
					{/*                   })}*/}
					{/*                   onChange={e => {*/}
					{/*                       setDataToCalculate({*/}
					{/*                           ...dataToCalculate,*/}
					{/*                           other: e.target.value*/}
					{/*                       });*/}
					{/*                   }}*/}
					{/*            />*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/* </div>*/}
					<hr style={{ width: "100%", margin: "15px 0px" }} />
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
						<div className="calculateItem my-1 d-flex justify-content-end align-items-center w-100 flex-wrap-xs">
							<div className="mx-2">
								<h5 className="m-0">Total:</h5>
							</div>
							<div className="d-flex align-items-center">
								<FaRupeeSign /> {calculationResult.total}
							</div>
						</div>
					</div>
					<hr style={{ width: "100%", margin: "15px 0px" }} />
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-between w-100 flex-wrap-xs">
							<div className="mt-2 pt-1">PF</div>
							<div>
								<input
									type="number"
									min={0}
									placeholder="Enter PF Amount"
									className="form-control"
									{...register("pf", {
										// required: 'This is required.',
									})}
									onChange={e => {
										setDataToCalculate({
											...dataToCalculate,
											pf: e.target.value
										});
									}}
								/>
								<div className="d-flex align-items-center mb-2">
									<input
										type="checkbox"
										className="mr-2"
										checked={calculateAsUpFront.pf}
										onChange={e => {
											setCalculateAsUpFront({
												...calculateAsUpFront,
												pf: e.target.checked
											});
										}}
									/>
									<label className="mx-1" style={{ fontSize: "13px" }}>
										Collected in Upfront
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-between w-100 flex-wrap-xs">
							<div className="mt-2 pt-1">Hospicare</div>
							<div>
								<div className="d-flex align-items-center">
									<div className="d-block">
										<div className="radio-wrapper simple">
											<div className="radio-item mb-1">
												<input
													type="radio"
													name="hospicare"
													value="150"
													className="radioInput"
													id="optionhospicare-1"
													{...register("hospicare", {
														required: "This is required."
													})}
													onChange={e => {
														setDataToCalculate({
															...dataToCalculate,
															hospicare: e.target.value
														});
													}}
												/>
												<label
													htmlFor="optionhospicare-1"
													className="option option-1"
												>
													<span>1 Year (Rs.150)</span>
												</label>
											</div>
											<div className="radio-item mb-1">
												<input
													type="radio"
													name="hospicare"
													value="288"
													className="radioInput"
													id="optionhospicare-2"
													{...register("hospicare", {
														required: "This is required."
													})}
													onChange={e => {
														setDataToCalculate({
															...dataToCalculate,
															hospicare: e.target.value
														});
													}}
												/>
												<label
													htmlFor="optionhospicare-2"
													className="option option-2"
												>
													<span>2 Years (Rs.288)</span>
												</label>
											</div>
											<div className="radio-item mb-1">
												<input
													type="radio"
													name="hospicare"
													value="417"
													className="radioInput"
													id="optionhospicare-3"
													{...register("hospicare", {
														required: "This is required."
													})}
													onChange={e => {
														setDataToCalculate({
															...dataToCalculate,
															hospicare: e.target.value
														});
													}}
												/>
												<label
													htmlFor="optionhospicare-3"
													className="option option-3"
												>
													<span>3 Years (Rs.417)</span>
												</label>
											</div>
											<div className="radio-item mb-1">
												<input
													type="radio"
													name="hospicare"
													value="541"
													className="radioInput"
													id="optionhospicare-4"
													{...register("hospicare", {
														required: "This is required."
													})}
													onChange={e => {
														setDataToCalculate({
															...dataToCalculate,
															hospicare: e.target.value
														});
													}}
												/>
												<label
													htmlFor="optionhospicare-4"
													className="option option-4"
												>
													<span>4 Years (Rs.541)</span>
												</label>
											</div>
											<div className="radio-item mb-1">
												<input
													type="radio"
													name="hospicare"
													value="657"
													className="radioInput"
													id="optionhospicare-5"
													{...register("hospicare", {
														required: "This is required."
													})}
													onChange={e => {
														setDataToCalculate({
															...dataToCalculate,
															hospicare: e.target.value
														});
													}}
												/>
												<label
													htmlFor="optionhospicare-5"
													className="option option-5"
												>
													<span>5 Years (Rs.657)</span>
												</label>
											</div>
										</div>
										<ErrorMessage
											errors={errors}
											name="hospicare"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									{/* <input type="number" min={0} placeholder="Enter Hospicare Amount" className="form-control"*/}
									{/*       {...register("hospicare", {*/}
									{/*           // required: 'This is required.',*/}
									{/*       })}*/}
									{/*       onChange={e => {*/}
									{/*           setDataToCalculate({*/}
									{/*               ...dataToCalculate,*/}
									{/*               hospicare: e.target.value*/}
									{/*           });*/}
									{/*       }}*/}
									{/* />*/}
									<span style={{ margin: "0px 5px" }}>
										x{numberOfCoBorrowers}
									</span>
								</div>
								<div className="d-flex align-items-center mb-2">
									<input
										type="checkbox"
										className="mr-2"
										checked={calculateAsUpFront.hospicare}
										onChange={e => {
											setCalculateAsUpFront({
												...calculateAsUpFront,
												hospicare: e.target.checked
											});
										}}
									/>
									<label className="mx-1" style={{ fontSize: "13px" }}>
										Collected in Upfront
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-between w-100 flex-wrap-xs">
							<div className="mt-2 pt-1">
								Medical Facility
								<OverlayTrigger
									placement="left"
									delay={{ show: 250, hide: 400 }}
									overlay={
										<Tooltip id="Medical-Facility">
											Empower Pro Membership (Free) + Medical Facility
										</Tooltip>
									}
								>
									<div style={{ display: "inline-block", marginLeft: "8px" }}>
										<FaInfoCircle size={22} />
									</div>
								</OverlayTrigger>
							</div>
							<div>
								<input
									type="number"
									min={0}
									placeholder="Enter Medical Loan Amount"
									className="form-control"
									{...register("medical_loan", {
										// required: 'This is required.',
									})}
									onChange={e => {
										setDataToCalculate({
											...dataToCalculate,
											medical_loan: e.target.value
										});
									}}
								/>
								<div className="d-flex align-items-center mb-2">
									<input
										type="checkbox"
										className="mr-2"
										checked={calculateAsUpFront.medical_loan}
										onChange={e => {
											setCalculateAsUpFront({
												...calculateAsUpFront,
												medical_loan: e.target.checked
											});
										}}
									/>
									<label className="mx-1" style={{ fontSize: "13px" }}>
										Collected in Upfront
									</label>
								</div>
							</div>
						</div>
					</div>
					<hr style={{ width: "100%", margin: "15px 0px" }} />
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
						<div className="calculateItem my-1 d-flex justify-content-end align-items-center w-100 flex-wrap-xs">
							<div className="mx-2">
								<h5 className="m-0">
									Hospicare * Number of Co-Borrowers(
									{dataToCalculate.hospicare
										? parseFloat(dataToCalculate.hospicare)
										: 0}{" "}
									* {numberOfCoBorrowers}):
								</h5>
							</div>
							<div className="d-flex align-items-center">
								<FaRupeeSign />{" "}
								{dataToCalculate.hospicare
									? parseFloat(dataToCalculate.hospicare) * numberOfCoBorrowers
									: 0}
							</div>
						</div>
					</div>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
						<div className="calculateItem my-1 d-flex justify-content-end align-items-center w-100 flex-wrap-xs">
							<div className="mx-2">
								<h5 className="m-0">Net Total:</h5>
							</div>
							<div className="d-flex align-items-center">
								<FaRupeeSign /> {calculationResult.net_total}
							</div>
						</div>
					</div>
					<hr style={{ width: "100%", margin: "15px 0px" }} />
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"></div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-between align-items-center w-100 flex-wrap-xs">
							<div>Margin Money/Down Payment</div>
							<div>
								<input
									type="number"
									min={0}
									placeholder="Enter Margin Money"
									className="form-control"
									{...register("margin_money", {
										// required: 'This is required.',
									})}
									onChange={e => {
										setDataToCalculate({
											...dataToCalculate,
											margin_money: e.target.value
										});
									}}
								/>
							</div>
						</div>
					</div>
					<hr style={{ width: "100%", margin: "15px 0px" }} />
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-end align-items-center w-100 flex-wrap-xs">
							<div className="mx-2">
								<h5 className="m-0">Loan Amount:</h5>
							</div>
							<div className="d-flex align-items-center">
								<FaRupeeSign /> {calculationResult.loan_amount}
							</div>
						</div>
					</div>
					<hr style={{ width: "100%", margin: "15px 0px" }} />
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="calculateItem my-1 d-flex justify-content-end align-items-center w-100 flex-wrap-xs">
							<div className="mx-2">
								<h5 className="m-0">LTV:</h5>
							</div>
							<div>{calculationResult.ltv}%</div>
						</div>
					</div>
					{/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">*/}
					{/*    <div className="calculateItem my-1 d-flex justify-content-end align-items-center w-100 flex-wrap-xs">*/}
					{/*        <div className="mx-2">*/}
					{/*            <h5 className="m-0">LTV for Capital Bank:</h5>*/}
					{/*        </div>*/}
					{/*        <div>*/}
					{/*            {calculationResult.capitalLtv}%*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/* </div>*/}
					<hr style={{ width: "100%", margin: "15px 0px" }} />
					{/* </div>*/}
					{/* </div>*/}
				</Row>
				<Col size={12} className="text-center mt-2">
					<button type="submit" className="btn btn-primary">
						Next
					</button>
				</Col>
			</form>
		</div>
	);
}
