import { Col, Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import React from "react";

const ChangePasswordModal = ({ show, onHide }) => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm();
	const newpassword = watch("new_password");

	const onSubmit = async data => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/change-password`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Password Changed Successfully");
					onHide();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			});
	};
	return (
		<Modal show={show} onHide={onHide} size={"md"} centered>
			<Modal.Header>
				<Modal.Title>Update Password</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					<div className="col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">New Password</label>
							<input
								placeholder="Password"
								type="password"
								className="form-control"
								{...register("new_password", {
									required: "This is required.",
									minLength: {
										value: 8,
										message: "Password must have at least 8 characters"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="new_password"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Confirm Password</label>
							<input
								placeholder="Confirm Password"
								type="password"
								className="form-control"
								{...register("confirm_password", {
									required: "This is required.",
									validate: value =>
										value === newpassword || "The Password does not match"
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="confirm_password"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<Col size={12} className="text-center mt-2">
							<button type="submit" className="btn btn-primary">
								Submit
							</button>
						</Col>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default ChangePasswordModal;
