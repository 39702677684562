import { Modal } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { MainContext } from "../context/MainState";
import axios from "axios";
import { toast } from "react-toastify";

const OrganizationSignUp = ({ show, onHide }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	const [formIsLoading, setFormIsLoading] = useState(false);
	const { updateUser } = useContext(MainContext);
	const [passwordValue, setPasswordValue] = useState("");
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const onSubmit = async data => {
		setFormIsLoading(true);
		data["user_type"] = "Financer";
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/signup`,
				data
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 201) {
					reset();
					toast.success("Signup Successfully");
					localStorage.setItem("user", JSON.stringify(response.data.response.data));
					updateUser(response.data.response.data);
					onHide();
				} else if (response.data.response.status === 409) {
					const errors = response.data.response.error;
					const messages = [];
					for (var propName in errors) {
						if (Object.prototype.hasOwnProperty.call(errors, propName)) {
							messages.push(errors[propName]);
						}
					}
					console.log(messages);
					toast.error(messages.toString());
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error occurred: " + error.message);
				setFormIsLoading(false);
			});
	};
	return (
		<Modal show={show} onHide={onHide} size={"xl"} centered>
			<div className="sign-page">
				<div className="sign-box">
					<div className="login-benifit">
						<div className="benifit-list">
							<ul>
								<li>
									<div className="ben-icon">
										<img src="/images/pages/home/team-icon.png" alt="" />
									</div>
									<div className="ben-text">
										<h2>Tech-Driven Platform</h2>
										<p>
											We value change and innovation in order to provide
											innovative solutions for all of your requirements.
										</p>
									</div>
								</li>
								<li>
									<div className="ben-icon">
										<img src="/images/pages/home/credit.png" alt="" />
									</div>
									<div className="ben-text">
										<h2>A large pool of loan products</h2>
										<p>
											We&#39;ve gathered everything under one roof, from
											education and personal loans to house loans and
											financial advice.
										</p>
									</div>
								</li>
								<li>
									<div className="ben-icon">
										<img src="/images/pages/home/secure.png" alt="" />
									</div>
									<div className="ben-text">
										<h2>Personalized Dashboard</h2>
										<p>
											With a customizable dashboard, you may tailor it to your
											needs and expectations.
										</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="signup-form">
						<h1 className="mb-3">Sign Up</h1>
						<form className="row" onSubmit={handleSubmit(onSubmit)}>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="organization_name">
										Organization Name
									</label>
									<input
										placeholder="Organization Name"
										className="form-control"
										{...register("organization_name", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="organization_name"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="organization_email">
										Organization Email
									</label>
									<input
										placeholder="Organization Email"
										className="form-control"
										{...register("organization_email", {
											required: "This is required.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: "Email is invalid"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="organization_email"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label
										className="form-label mb-0"
										htmlFor="organization_website"
									>
										Website
									</label>
									<input
										placeholder="Website"
										className="form-control"
										{...register("organization_website", {
											required: false
											// pattern: {
											//     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
											//     message: "Email is invalid"
											// }
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="organization_website"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="last_name">
										Phone Number
									</label>
									<input
										type="tel"
										placeholder="Phone Number"
										className="form-control"
										{...register("organization_phone", {
											required: "This is required.",
											minLength: {
												value: 10,
												message:
													"Mobile number Should be at least 10 numbers"
											},
											maxLength: {
												value: 10,
												message:
													"Mobile number maximum be at least 10 numbers"
											},
											pattern: {
												value: phoneRegExp,
												message: "Invalid Phone number"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="organization_phone"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="mb-3">
									<label htmlFor="basic-url" className="form-label mb-0">
										Username
									</label>
									<div className="input-group mb-3">
										<span
											className="input-group-text"
											id="inputGroup-sizing-default"
										>
											{window.location.origin}/
										</span>
										<input
											aria-label="Sizing example input"
											aria-describedby="inputGroup-sizing-default"
											placeholder="Username"
											className="form-control"
											{...register("username", {
												required: "This is required.",
												minLength: {
													value: 3,
													message:
														"Username must have at least 3 characters"
												}
											})}
										/>
									</div>
									<ErrorMessage
										errors={errors}
										name="username"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="password">
										Password
									</label>
									<input
										id="password"
										name="password"
										type="password"
										placeholder="Password"
										className="form-control"
										{...register("password", {
											required: "This is required.",
											minLength: {
												value: 8,
												message: "Password must have at least 8 characters"
											}
										})}
										onChange={e => setPasswordValue(e.target.value)}
									/>
									<ErrorMessage
										errors={errors}
										name="password"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="cpassword">
										Confirm Password
									</label>
									<input
										id="cpassword"
										name="cpassword"
										type="password"
										placeholder="Confirm Password"
										className="form-control"
										{...register("cpassword", {
											required: "This is required.",
											validate: value =>
												value === passwordValue ||
												"The passwords do not match"
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="cpassword"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<h3 className="heading">Contact Info</h3>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="first_name">
										First Name
									</label>
									<input
										placeholder="First Name"
										className="form-control"
										{...register("first_name", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="first_name"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="last_name">
										Last Name
									</label>
									<input
										placeholder="Last Name"
										className="form-control"
										{...register("last_name", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="last_name"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="email">
										Email
									</label>
									<input
										placeholder="Email"
										className="form-control"
										{...register("email", {
											required: "This is required.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: "Email is invalid"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="email"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label mb-0" htmlFor="last_name">
										Phone Number
									</label>
									<input
										type="tel"
										placeholder="Phone"
										className="form-control"
										{...register("phone", {
											required: "This is required.",
											minLength: {
												value: 10,
												message:
													"Mobile number Should be at least 10 numbers"
											},
											maxLength: {
												value: 10,
												message:
													"Mobile number maximum be at least 10 numbers"
											},
											pattern: {
												value: phoneRegExp,
												message: "Invalid Phone number"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="phone"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
								<div className="mb-3">
									{formIsLoading ? (
										<button
											type="button"
											id="Submit"
											name="Submit"
											className="btn btn-primary"
											disabled={true}
										>
											<img
												src="/images/pages/home/loader.svg"
												alt={process.env.REACT_APP_NAME}
											/>
										</button>
									) : (
										<button type="submit" className="btn btn-primary">
											Sign Up
										</button>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default OrganizationSignUp;
