import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import DateRangeFilter from "../../components/dashboard/DateRangeFilter";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { MainContext } from "../../context/MainState";
import NachRequesStats from "../../components/dashboard/NachRequestStats";
import NachRegisterOldCases from "../../components/dashboard/NachRegisterOldCases";
import NachRegisterNewCases from "../../components/dashboard/NachRegisterNewCases";

const NachRequests = () => {
	const { getStates, state_list, getBranches, branches_list } = useContext(OrganizationDashboardContext);
	const { getFinancerLoanStatusList, financer_loan_status } = useContext(MainContext);
	const [active, setActive] = useState("1");
	const [activeCases, setActiveCases] = useState("1");
	const [statsType, setStatsType] = useState("old_pending");
	const [loadApp, setLoadApp] = useState(false);
	const [loadNach, setLoadNach] = useState(false);
	const [finalDate, setFinalDate] = useState({});
	const [searchField, setSearchField]=useState({});
	// const [finalDate2, setFinalDate2] = useState({});

	useEffect(() => {
		if (!state_list) {
			getStates();
		}
		if (!branches_list) {
			getBranches();
		}
		if (!financer_loan_status) {
			getFinancerLoanStatusList();
		}
	}, []);

	const toggle = tab => {
		if (active !== tab) {
			setActive(tab);
			setActiveCases("1"); // Reset sub-tab to "Registered" on tab change
		}
	};

	const handleTabChange = (type) => {
		setStatsType(type);
	};

	const toggleCases = tab => {
		if (activeCases !== tab) {
			setActiveCases(tab);
			// getDataApi()
		}
	};
	const handleFieldSearch=(searchArray)=>{
		setSearchField(searchArray);
	};

	const getDataApi = filters => {
		// if ((active === "1" && activeCases === "1" || active === "1" && activeCases === "2") && filters.start_date !== finalDate.start_date) {
		setFinalDate(filters);
		// } else if ((active === "2" && activeCases === "3" || active === "2" && activeCases === "4") && filters.start_date !== finalDate2.start_date) {
		// 	setFinalDate2(filters);
		// }
	};

	return (
		<>
			<Helmet>
				<title>eNACH Registrations</title>
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-12">
							<div className="d-flex align-items-center mb-2 justify-content-end flex-wrap">
								{/* {active === "1" && activeCases === "1" ? (*/}
								<DateRangeFilter
									callBack={getDataApi}
									defaultDate={{ value: "1", label: "This Month" }}
								/>
								{/* ) : ""}*/}
								{/* {active === "1" && activeCases === "2" ? (*/}
								{/*	<DateRangeFilter*/}
								{/*		callBack={getDataApi}*/}
								{/*		defaultDate={{ value: "1", label: "This Month" }}*/}
								{/*	/>*/}
								{/* ) : ""}*/}
								{/* {active === "2" && activeCases === "3" ? (*/}
								{/*	<DateRangeFilter*/}
								{/*		callBack={getDataApi}*/}
								{/*		defaultDate={{ value: "1", label: "This Month" }}*/}
								{/*	/>*/}
								{/* ) : ""}*/}
								{/* {active === "2" && activeCases === "4" ? (*/}
								{/*	<DateRangeFilter*/}
								{/*		callBack={getDataApi}*/}
								{/*		defaultDate={{ value: "1", label: "This Month" }}*/}
								{/*	/>*/}
								{/* ) : ""}*/}
							</div>
						</div>
						<NachRequesStats finalDate={finalDate} type={statsType} searchField={searchField}/>
					</div>
					<div className="row px-3">
						<div className="col-md-12 col-12 mt-4">
							<Nav className="d-flex flex-wrap users-nav overflow-hidden">
								<NavItem>
									<NavLink className="shadow-none" active={active === "1"}
											 onClick={() => {
											toggle("1");
											handleTabChange("old_pending");
										}}>
										Old Cases
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className="shadow-none" active={active === "2"} onClick={() => {
										toggle("2");
										toggleCases("3");
										handleTabChange("new_pending");
									}}>
										New Cases
									</NavLink>
								</NavItem>
							</Nav>
						</div>
					</div>
					<TabContent className="py-50" activeTab={active}>
						<TabPane tabId="1" className={active === "1" ? "active" : ""}>
							<Row className="mx-0 mt-1 mb-5">
								<div className="d-flex align-items-center mb-3">
									<NavItem className="nachRegister">
										<NavLink className="py-1 px-3 shadow-none"
												 active={active === "1" && activeCases === "1"}
												 onClick={() => {
												toggleCases("1"); 
												handleTabChange("old_pending");
											}
											}>
											Unregistered
										</NavLink>
									</NavItem>
									<NavItem className="nachRegister">
										<NavLink  active={activeCases === "2"} className="py-1 px-3 mx-2 shadow-none" onClick={() => { toggleCases("2"); setLoadNach(true);handleTabChange("old_registered"); }}>
											Initiated / Registered
										</NavLink>
									</NavItem>
								</div>
								<TabContent className="py-50" activeTab={activeCases}>
									<TabPane tabId="1" className={activeCases === "1" ? "active" : ""}>
										<NachRegisterOldCases active={activeCases} type={"pending"} callBack={handleFieldSearch}/>
									</TabPane>
									<TabPane tabId="2" className={activeCases === "2" ? "active" : ""}>
										<NachRegisterOldCases active={activeCases} type={"registered"} loadDefault={loadNach} callBack={handleFieldSearch} />
									</TabPane>
								</TabContent>
							</Row>
						</TabPane>
						<TabPane tabId="2" className={active === "2" ? "active" : ""}>
							<Row className="mx-0 mt-1 mb-5">
								<div className="d-flex align-items-center mb-3">
									<NavItem className="nachRegister">
										<NavLink className="py-1 px-3 shadow-none" active={active === "2" && activeCases !== "4"} onClick={() => {
											toggleCases("3");
											handleTabChange("new_pending");
										}}>
											Unregistered
										</NavLink>
									</NavItem>
									<NavItem className="nachRegister">
										<NavLink  className="py-1 px-3 mx-2 shadow-none"  active={active === "2" && activeCases === "4"} onClick={() => { toggleCases("4"); setLoadApp(true);handleTabChange("new_registered"); }}>
											Initiated / Registered
										</NavLink>
									</NavItem>
								</div>
								<TabContent className="py-50" activeTab={activeCases}>
									<TabPane tabId="3" className={activeCases === "3" ? "active" : ""}>
										<NachRegisterNewCases active={activeCases} type={"pending"} callBack={handleFieldSearch}/>
									</TabPane>
									<TabPane tabId="4" className={activeCases === "4" ? "active" : ""}>
										<NachRegisterNewCases active={activeCases} type={"registered"}  loadDefault={loadApp} callBack={handleFieldSearch}/>
									</TabPane>
								</TabContent>
							</Row>
						</TabPane>
					</TabContent>
				</div>
			</div>
		</>
	);
};

export default NachRequests;
