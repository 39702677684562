import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import LoanApplicationsTable from "../../components/dashboard/LoanApplicationsTable";
import { useContext } from "react";
import { MainContext } from "../../context/MainState";
import { Link } from "react-router-dom";

const DiwaliOffersList = () => {
	const { user } = useContext(MainContext);
	return (
		<div>
			<Helmet>
				<title>Dashboard</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className={"d-flex justify-content-between mb-3 flex-wrap"}>
						<h1 className="head-title mb-0">Dashboard</h1>
						<div className="dashboard-sub-action d-flex flex-wrap">
							{user && user.user_type === "Financer" ? (
								<Link
									to="/account/users"
									className="btn btn-primary btn-sm d-flex align-items-center mx-1"
								>
									My Users
								</Link>
							) : (
								""
							)}
							{user &&
							user.organization_username &&
							user.organization_username === "phfleasing" ? (
									<Link
										to="/account/apps/list"
										className="btn btn-primary btn-sm d-flex align-items-center mx-1"
									>
									My Apps
									</Link>
								) : (
									""
								)}
							{user && user.user_type === "DSA" ? (
								<Link
									to="/account/users"
									className="btn btn-primary btn-sm d-flex align-items-center mx-1"
								>
									Connectors
								</Link>
							) : (
								""
							)}
							{/* {user.organization_username && user.organization_username === 'phfleasing' && (user.user_type === "Employee" || user.user_type === 'Financer') ? (*/}
							{/*    <>*/}
							{/*        <Link to="/account/diwali-dhamaka" className="btn btn-primary btn-sm d-flex align-items-center mx-1">*/}
							{/*            Diwali Offer*/}
							{/*        </Link>*/}
							{/*    </>*/}
							{/* ): ""}*/}
							{user.organization_username &&
							user.organization_username === "phfleasing" ? (
									<>
										<Link
											to="/account/e-signing"
											className="btn btn-primary btn-sm d-flex align-items-center mx-1"
										>
										Document E-Sign
										</Link>
										<Link
											to="/account/e-sign/list"
											className="btn btn-primary btn-sm d-flex align-items-center mx-1"
										>
										E-Sign List
										</Link>
									</>
								) : (
									""
								)}
						</div>
					</div>
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5">
						<LoanApplicationsTable formType="diwali-dhamaka" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default DiwaliOffersList;
