import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import React from "react";
import { CandidateDashboardProvider } from "../../context/CandidateDashboardState";
import { Tab } from "react-bootstrap";
import CandidateSidebar from "../../components/dashboard/CandidateSidebar";
import ProComponentDashboard from "../../components/dashboard/ProComponentDashboard";

const ProMember = () => {
	return (
		<div>
			<Helmet>
				<title>Candidate Dashboard</title>
				{/* <meta name="description" content="Tutorial for React Helmet"/>*/}
			</Helmet>
			<Navbar />
			<CandidateDashboardProvider>
				<section>
					<div className="container-fluid mt-4">
						<Tab.Container id="left-tabs-example" defaultActiveKey="first">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12" id="side-bar-main">
									<CandidateSidebar />
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12" id="integration-main">
									<ProComponentDashboard />
								</div>
							</div>
						</Tab.Container>
					</div>
				</section>
			</CandidateDashboardProvider>
		</div>
	);
};

export default ProMember;
