import React, { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Badge, Col, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos, MdOutlineContentCopy } from "react-icons/md";
import NameComp from "../NameComp";
import { toast } from "react-toastify";
import { BiRupee } from "react-icons/bi";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import MinMaxFilter from "../MinMaxFilter";
import { MainContext } from "../../context/MainState";

const NachRegisterOldCases = ({ callBack, type = "", loadDefault = false, active }) => {
	const { user,loanAccountTypes } = useContext(MainContext);
	const { getNachRequests, nach_requests, branches_list,states_list,} = useContext(OrganizationDashboardContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [searchArray, setSearchArray] = useState({});
	const [sortArr, setSortArr] = useState({});
	const [clearFilters, setClearFilters] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [loanAccountId, showLoanSideBar] = useState({loan_account_enc_id: "", loan_account_number: ""});
	const [reloadSidebar, setReloadSidebar] = useState(false);


	useEffect(()=>{
		if(nach_requests && nach_requests[type]){
			setPageLoading(false);
		}
	},[nach_requests]);

	useEffect(() => {
		if (searchArray) {
			setPageLoading(true);
			const timerId = setTimeout(() => {
				setClearFilters(false);
				setCurrentPage(1);
				if(type === "pending" && active === "1"){
					getNachRequests({
						type,
						page: 1,
						limit: rowsPerPage,
						fields_search: searchArray,
						fields_sort: sortArr,
					});
				}
				else if(type === "registered" && active === "2"){
					getNachRequests({
						type,
						page: 1,
						limit: rowsPerPage,
						fields_search: searchArray,
						fields_sort: sortArr,
					});
				}
			}, [1000]);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setShowClearBtn(true);
			}
			callBack(searchArray);
			return () => {
				clearTimeout(timerId);
			};
		}
		
	}, [searchArray, loadDefault, active]);

	const statusColorObj = {
		paid: "success",
		pending: "warning",
		rejected: "danger",
		failed: "danger",
		pipeline: "secondary",
		"not paid": "danger",
		collected: "secondary",
		Active: "success",
		Issued: "warning",
		Pending: "secondary",
		Inactive: "danger",
		Failed: "danger",
		Cancelled: "danger",
		"Not Registered": "primary"
	};

	const copy = async (link) => {
		await navigator.clipboard.writeText(link);
		toast.success("Link copied");
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "--"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => showLoanSideBar({loan_account_enc_id: row.loan_account_enc_id , loan_account_number: row.loan_account_number })}
					>
						{row.loan_account_number ? row.loan_account_number : "--"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.name ? row.name : "--"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => showLoanSideBar({loan_account_enc_id: row.loan_account_enc_id , loan_account_number: row.loan_account_number })}
					>
						{row.customer_name ? row.customer_name : "--"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "220px"
		},
		{
			name: (
				<NameComp
					title={"Sub Bucket"}
					id={"sub_bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.sub_bucket ? row.sub_bucket : "-"),
			sortable: false,
			omit: type !== "pending",
			minWidth: "220px"
		},
		{
			name: (
				<NameComp
					title={"Last EMI Date"}
					id={"last_emi_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.last_emi_date ? moment(row.last_emi_date).format("DD MMM YYYY") : "-"),
			omit: type !== "pending",
			sortable: false,
			minWidth: "220px"
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"Max Amount"}
					id={"amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			omit: type === "pending",
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						 {row.max_amount ? (
							<>
								<BiRupee size={16} />
								{parseFloat(row.max_amount).toLocaleString("en-IN")}
							</>
						) : "-"}
					</h6>
				);
			},
			selector: row => row.max_amount,
			sortable: false
		}, 
		{
		  name: (
		    <NameComp
		      title={"eNACH Status"}
		      id={"enach_status"}
		      searchArray={searchArray}
		      setSearchArray={setSearchArray}
			  sortArr={sortArr}
			  setSortArr={setSortArr}
			  clearFilters={clearFilters}
		    />
		  ),
		  minWidth: "200px",
		  omit: type === "pending",
		  selector: row => (
				row.enach_status ? (
					<Badge
						className="text-capitalize mx-2 py-1"
						bg={statusColorObj[row.enach_status]}
						pill
					>
						{row.enach_status}
					</Badge>
				) : <Badge
					className="text-capitalize mx-2 py-1"
					bg={"secondary"}
					pill>
					Not Registered
				</Badge>
			),
		  sortable: false
		},
		{
			name: "eNACH URL",
			minWidth: "140px",
			omit: type === "pending",
			selector: row => row.url,
			cell: row => {
				return (
					row.enach_status && row.enach_status !== "Active" ? (
						<button
							type="button"
							className="btn btn-primary btn-sm mb-1"
							onClick={() => copy(row.url)}
							style={{ padding: "3px 4px" }}
						>
							<MdOutlineContentCopy style={{ margin: "0px 2px" }} size={15} />
						</button>
					) : (
						"-"
					)
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Created By"}
					id={"created_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			omit: type === "pending",
			selector: row => row.created_by,
			style: { padding: "0px" },
			cell: row => {
				return (
					<div
						style={{
							fontWeight: 500,
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100%"
						}}
					>
						{row.created_by ? row.created_by : "-"}
					</div>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Created On"}
					id={"created_on"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			omit: type === "pending",
			selector: row => moment(row.created_on).valueOf(),
			sortable: false,
			cell: row => {
				return (
					row.created_on ? moment(row.created_on).format("DD MMM YYYY h:mm a") : "-"
				);
			}
		},
		{
			name: (
			  <NameComp
					title={"Payment ID"}
					id={"payment_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
			  />
			),
			minWidth: "250px",
			selector: row => (row.payment_id ? row.payment_id : "-"),
			omit: !(user && ["nisha123", "Satparkash"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0"
					>
						{row.payment_id ? row.payment_id : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Token ID"}
					id={"token_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "250px",
			selector: row => (row.token_id ? row.token_id : "-"),
			omit: !(user && ["nisha123", "Satparkash"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0"
					>
						{row.token_id ? row.token_id : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Customer ID"}
					id={"customer_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "250px",
			selector: row => (row.customer_id ? row.customer_id : "-"),
			omit: !(user && ["nisha123", "Satparkash"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0"
					>
						{row.customer_id ? row.customer_id : "-"}
					</h6>
				);
			}
		},
		{
			name: "Remarks",
			minWidth: "250px",
			omit: type === "pending",
			selector: row => (row.error_description ? row.error_description : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize"
					>
						{row.error_description ? row.error_description : "-"}
					</h6>
				);
			}
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		setPageLoading(true);
		getNachRequests({
			type,
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setPageLoading(true);
		getNachRequests({
			type,
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			fields_sort: sortArr,
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: ""});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(nach_requests[type].count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		if (	nach_requests &&
			nach_requests[type] &&
			nach_requests[type].data &&
			nach_requests[type].data.length > 0
		) {
			return nach_requests[type].data;
		}
	};
	useEffect(() => {
		if(Object.keys(sortArr).length){
			setCurrentPage(1);
			setPageLoading(true);
			getNachRequests({
				type,
			    page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
	}, [sortArr]);

	const reloadDetails = () => {
		setReloadSidebar(true);
		getNachRequests({
			type,
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
		});
	};

	const conditionalRowStyles = [
		{
			when: row => row.enach_status === "Active",
			style: { borderLeft: "4px solid green" },
		},
		{
			when: row => row.enach_status === "Pending",
			style: { borderLeft: "4px solid orange" },
		},
		{
			when: row => row.enach_status === "Issued",
			style: { borderLeft: "4px solid yellow" },
		},
		{
			when: row => row.enach_status === "Inactive",
			style: { borderLeft: "4px solid #00a0e3" },
		},
		{
			when: row => row.enach_status === "Failed",
			style: { borderLeft: "4px solid red" },
		},
	];

	return (
		<>
			<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5">
				<div className="loan-application-table">
					<div className="d-flex justify-content-between align-items-center table-heading-line mb-2">
						<h4 className="m-0 fs-3">{type === "registered" ? "Registered" : "Unregistered" } Applications</h4>
						<Row className="mx-0 mt-1 mb-50">
							<Col sm="6">
								<div className="d-flex align-items-center">
									<label htmlFor="sort-select">Show</label>
									<select
										className="dataTable-select form-select"
										style={{
											width: "60px",
											padding: "2px 8px",
											margin: "0px 10px"
										}}
										id="sort-select"
										value={rowsPerPage}
										onChange={e => handlePerPage(e)}
									>
										{/* <option value={10}>10</option>*/}
										<option value={25}>25</option>
										<option value={50}>50</option>
										<option value={75}>75</option>
										<option value={100}>100</option>
									</select>
									<label htmlFor="sort-select">Results</label>
								</div>
							</Col>
						</Row>
					</div>
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button className="btn btn-primary btn-xs my-2" onClick={(e) => clearFilter(e)}>Reset</button>
						): ""}
					</div>
					<hr/>
					<DataTable
						noHeader
						conditionalRowStyles={conditionalRowStyles}
						pagination
						paginationServer
						columns={columns}
						data={dataToRender()}
						paginationComponent={CustomPagination}
						persistTableHead={true} 
						progressPending={pageLoading}
						progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
						noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
						fixedHeader="true"
						highlightOnHover="true"
						fixedHeaderScrollHeight="80vh"
						className="loan-app-table-container fix-overflow report-tables z-0"
					/>
				</div>
			</div>

			<EmiLoanAccountSidebar
				handleCloseSideBar={() => showLoanSideBar("")}
				loanAccountId={loanAccountId}
				reloadDetails={reloadDetails}
				reloadSidebar={reloadSidebar}
			/>
		</>
	);
};
export default NachRegisterOldCases;
