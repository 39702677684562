import { useForm } from "react-hook-form";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import LoanPersonalDetails from "../forms/LoanPersonalDetails";
import LoanDetails from "../forms/LoanDetails";
import LoanResidentialDetails from "../forms/LoanResidentialDetails";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { IoCloseCircle  } from "react-icons/io5";
const OneStepLoanApplyForm = ({ callback, geoLoc, formFor = "org" }) => {
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		control,
		setValue,
		setError,
		formState: { errors },
		clearErrors
	} = useForm();
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [resReload, setResReload] = useState("");
	const [numberExist, setNumberExist] = useState(false);
	const [btnDesign, setBtnDesign] = useState(false);
	const { user, getAssignedLoanTypes, loan_types } = useContext(MainContext);
	// const [paymentLink, setPaymentLink] = useState("");
	// const [pageTitle, setPageTitle] = useState('Apply Loan');
	const form = useRef(null);
	const [bill, setBill] = useState({});
	const handleLocationReload = () => {
		setResReload("yes");
		setTimeout(() => {
			setResReload("");
		}, 1000);
	};
	useEffect(() => {
		if (user && user.organization_enc_id && !loan_types) {
			getAssignedLoanTypes();
		}
	}, []);
	useEffect(() => {
		if (geoLoc.latitude !== "") {
			setBtnDesign(true);
		}
	}, [geoLoc]);

	const setBillHandling = e => {
		setBill(e);
	};
	const onSubmit = async data => {
		if (numberExist) {
			toast.error("Phone number already exists");
			return false;
		}
		if (!data.branch_id) {
			toast.error("Please Choose a branch");
			return false;
		}
		setFormIsLoading(true);
		let formData;
		if (data.loan_type === "Medical Loan") {
			formData = new FormData(form.current);
			formData.append("loan_type", "Medical Loan");
			formData.append("bill", bill);
		} else {
			formData = data;
		}
		if (user && user.referral_code) {
			formData["ref_id"] = user.referral_code;
		}
		formData.applicant_name = formData?.applicant_name?.replace(/\b\w/g, char => char.toUpperCase());
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/loan-application`,
				formData
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 201) {
					localStorage.setItem("paymentRequested", "true");
					// setPaymentLink(response.data.response.data.payment_url);
					toast.success("Application Submitted");
				} else if (response.data.response.status === 200) {
					toast.success("Application Submitted");
					reset();
				} else {
					toast.error("An error has occurred, Please try again");
				}
				callback();
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
				setFormIsLoading(false);
			});
	};

	return (
		<div>
			<div className="container position-relative">
				<div className="row">
					<div className="col-md-12">
						<div className="p-5">
							{/* <div>*/}
							<div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
								<div className="mb-6 text-center">
									<h1 className="mb-0">Customer Information <span className="close-icon" onClick={callback}><IoCloseCircle /></span></h1>
								</div>
							</div>

							<form
								ref={form}
								method="post"
								id="applyForm"
								onSubmit={handleSubmit(onSubmit)}
							>
								<LoanPersonalDetails
									register={register}
									errors={errors}
									setError={setError}
									clearErrors={clearErrors}
									setNumberExist={setNumberExist}
									numberExist={numberExist}
								/>
								<LoanDetails
									formFor={formFor}
									orgLoanTypes={loan_types}
									// orgLoanPurposes={purposes}
									register={register}
									errors={errors}
									control={control}
									getValues={getValues}
									setValue={setValue}
									clearErrors={clearErrors}
									setBillHandling={setBillHandling}
								/>
								<LoanResidentialDetails
									user={user}
									formFor={formFor}
									register={register}
									errors={errors}
									userData={geoLoc}
									setValue={setValue}
									reloadLoc={resReload}
									control={control}
									locationBtn={"d-none"}
								/>
								<div className="row mb-3">
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-start">
										<button
											type="button"
											className={`btn ${
												btnDesign ? "btn-success" : "btn-primary"
											}`}
											onClick={() => handleLocationReload()}
										>
											Fetch My Location
										</button>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
										{formIsLoading ? (
											<button
												type="button"
												className="btn btn-primary"
												disabled={true}
											>
												<img src="/images/pages/home/loader.svg" />
											</button>
										) : (
											<button
												id="Submit"
												name="Submit"
												type="submit"
												className="btn btn-primary"
											>
												Save
											</button>
										)}
									</div>
								</div>
							</form>
							{/* </div>*/}
						</div>
					</div>
				</div>
			</div>

			{/* <Modal show={show}>*/}
			{/*    <Modal.Header>*/}
			{/*        <Modal.Title>Application Successfully Submitted</Modal.Title>*/}
			{/*    </Modal.Header>*/}
			{/*    <Modal.Body>Proceed to pay Application Login Fee</Modal.Body>*/}
			{/*    <Modal.Footer>*/}
			{/*        <Button variant="primary" onClick={handleRedirect}>*/}
			{/*            Click To Pay*/}
			{/*        </Button>*/}
			{/*    </Modal.Footer>*/}
			{/* </Modal>*/}
		</div>
	);
};
export default OneStepLoanApplyForm;
