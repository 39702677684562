import { Col, Modal } from "react-bootstrap";
import AutoComplete from "../components/forms/AutoComplete";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import { IoCloseCircle} from "react-icons/io5";
import { readOnlyUsers } from "../helpers/authServices";
import { MainContext } from "../context/MainState";

const ShareAccessModal = ({
	selectedApp,
	onHide,
	setTableLoading,
	createrName,
	loanDetails,
	params,
	openSideBar = null
}) => {
	const {user} = useContext(MainContext);
	const { register, handleSubmit, watch, setValue, reset } = useForm();
	const toBeShared = watch("shared_to");
	const { getDashboardLoanApplications, getLoanApplicationsCases } = useContext(
		OrganizationDashboardContext
	);

	const handleAccessChange = async (val, id) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let data = {};
		data["shared_loan_app_id"] = id;
		if (val === "delete") {
			data["delete"] = true;
		} else {
			data["access"] = val;
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-shared-application`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reset();
					if(params){
						setTableLoading(params.type);
						if (params.type === "disbursed" || params.type === "removed") {
							getLoanApplicationsCases(params);
						} else {
							getDashboardLoanApplications(params);
						}
					}
					if (openSideBar) {
						openSideBar(selectedApp.loan_app_enc_id);
					}
					onHide();
					toast.success("Submit Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};

	const onSubmit = async data => {
		data["loan_id"] = selectedApp.loan_app_enc_id;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/share-application`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reset();
					if(params){
						setTableLoading(params.type);
						if (params.type === "disbursed" || params.type === "removed") {
							getLoanApplicationsCases(params);
						} else {
							getDashboardLoanApplications(params);
						}
						onHide();
					}
					if (openSideBar) {
						openSideBar(selectedApp.loan_app_enc_id);
					}
					toast.success("Submit Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};

	return (
		<>
			<Modal
				show={selectedApp && selectedApp.loan_app_enc_id}
				onHide={onHide}
				size={"md"}
				centered
			>
				<Modal.Header>
					<Modal.Title>Update Application Access</Modal.Title><span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="col-sm-12 col-12">
							<div className="mb-3 d-flex">
								{/* <label className="form-label">Enter Username</label>*/}
								<div className="w-100">
									<AutoComplete
										setFieldValue={setValue}
										link={`company-dashboard/employee-search?type=employees&loan_id=${selectedApp.loan_app_enc_id}&employee_search=`}
										name={"shared_to"}
										method={"GET"}
										placeholder={"Enter Username"}
									/>
								</div>
								{toBeShared ? (
									<select
										style={{ maxWidth: "160px", marginLeft: "10px" }}
										className="form-select"
										name="access"
										id="shared_access"
										{...register("access", { required: "This is required." })}
									>
										<option value="Full Access">Full Access</option>
										<option value="Read Only">Read Only</option>
									</select>
								) : (
									""
								)}
							</div>
						</div>
						{toBeShared ? (
							<div className="col-sm-12 col-12">
								<Col size={12} className="text-center mt-2">
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</Col>
							</div>
						) : (
							""
						)}
					</form>
					{selectedApp.sharedTo && !toBeShared && selectedApp.sharedTo.length > 0 ? (
						<>
							<h3>People with Access</h3>
							<div className="col-sm-12 col-12">
								<div className="mb-3">
									<ul className="usersAccessList p-0">
										{selectedApp.sharedTo.map((value, index) => {
											return (
												<li
													key={`usersList-${index}`}
													className="d-flex align-items-center mb-3"
												>
													<div className="userAvatar">
														<img src={value.image} />
													</div>
													<div className="userDetails">
														<h4 className="m-0">{value.name}</h4>
														<p className="m-0">{value.designation ? value.designation : ""}</p>
													</div>
													<div className="userActions">
														<select
															style={{
																width: "160px",
																marginLeft: "10px"
															}}
															defaultValue={value.access}
															className="form-select"
															id={`shared_app_access${index}`}
															onChange={e =>
																handleAccessChange(
																	e.target.value,
																	value.shared_loan_app_enc_id
																)
															}
														>
															<option value="Read Only">
																Read Only
															</option>
															<option value="Full Access">
																Full Access
															</option>
															<option disabled>_________</option>
															<option value="delete">
																Remove Access
															</option>
														</select>
													</div>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
							<p className=" text-end m-0 fs-5">Originator: <span style={{color: "#000"}}>{loanDetails && loanDetails.created_by ? loanDetails.created_by : createrName}</span></p>
						</>
					) : (
						""
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ShareAccessModal;
