import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import LegalTable from "./LegalTable";

const LegalRequests = () => {
	return (
		<>
			<Helmet>
				<title>Legal Requests</title>
				<meta name="description" content="Loan Accounts" />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
						<h4 className="mb-3">Legal Requests</h4>
						<LegalTable />
					</div>
				</div>
			</div>
		</>
	);
};

export default LegalRequests;
