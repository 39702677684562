import { ErrorMessage } from "@hookform/error-message";
import Multiselect from "multiselect-react-dropdown";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IoCloseCircle } from "react-icons/io5";
import Select from "react-select";
import { MainContext } from "../../context/MainState";
import Datepicker from "react-datepicker";

const SmsFrequencyModal = ({ onHide, show, type, templates }) => {
	const {loan_products} = useContext(MainContext);
	// const [bucket, setBuket] = useState("");
	const [emiDateFrequency, setEmiDateFrequency] = useState("");
	const [loanProductsOptions, setLoanProductsOptions] = useState([]);
	const [multiSelectValues, setMultiSelectValues] = useState("~");
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [selectedCount, setSelectedCount] = useState("0");
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	let bucketListData = [
		{ label: "X", value: "X" },
		{ label: "1", value: "1" },
		{ label: "2", value: "2" },
		{ label: "3", value: "3" },
		{ label: "4", value: "4" },
		{ label: "5", value: "5" },
		{ label: "6", value: "6" },
		{ label: "7", value: "7" },
		{ label: "8", value: "8" }
	];

	const Emidatelist = [
		{ label: "01", value: "01" },
		{ label: "05", value: "05" },
		{ label: "11", value: "11" },
		{ label: "15", value: "15" },
		{ label: "21", value: "21" }
	];

	const MsgFrequency = [
		{ label: "1 day before due date", value: "1" },
		{ label: "2 day before due date", value: "2" },
		{ label: "3 days before due date", value: "3" },
		{ label: "5 days before due date", value: "5" },
		{ label: "7 days before due date", value: "7" },
		{ label: "10 days before due date", value: "10" },
		{ label: "15 days before due date", value: "15" }
	];

	const [selectAllArr, setSelectAllArr] = useState({});
	const [previousAllArr, setpreviousAllArr] = useState({});
	const selectedCustomData = (selected, list = null, id) => {
		let selectAll = selected.filter(item => item.value === "all");
		selectAll = selectAll.length ? selectAll[0] : "";
		let prevSelectAll = previousAllArr && previousAllArr[id] ? previousAllArr[id].filter(item => item.value === "all") : ""; 
		let flag = false;

		if(list && selected.length === list.length - 1 && !selectAll && prevSelectAll.length){
			flag = false;
			selected = [];
		}else if((selected.length && selectAll && !selectAllArr[id]) || (list && selected.length === list.length - 1 && !selectAll)){
			flag = true;
			selected = list;
		}else{
			flag = false;
			selected = selected.filter(item => item.value != "all");
		}
						
		setpreviousAllArr({...previousAllArr, [id]: selected});		
		setSelectAllArr({...selectAllArr, [id]: flag});
		setMultiSelectValues({...multiSelectValues, [id]:selected});
		setSelectedCount({...selectedCount, [id]: selected.length});
	};

	const onSubmit = () => {
		setFormIsLoading(true);
	};

	useEffect(() => {
		if (loan_products && loan_products.length) {
			const loanProduct = loan_products.map(loanProducts => ({
				value: loanProducts.financer_loan_product_enc_id,
				label: loanProducts.name
			}));
			const optionsWithAll = [{ label: "All", value: "all" }, ...loanProduct];
			setLoanProductsOptions(optionsWithAll);
		}
	}, []);

	useEffect(() => {
		console.log(templates);
	}, [templates]);

	return (
		<>
			<Modal size="lg" show={show} onHide={onHide}>
				<Modal.Header>
					<Modal.Title>
                         Set SMS Frequency
					</Modal.Title><span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							{type != "emiDate" || type === "overdue" ? (
								<div className="col-md-4">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="bucket">
											Bucket
										</label>
										<Multiselect
											className="multiselect-form-control"
											avoidHighlightFirstOption
											emptyRecordMsg="No Branches Found"
											placeholder={
												selectedCount?.bucket > 0 ? selectedCount.bucket + " " + "Selected" : "Select Bucket"
											}
											displayValue="label"
											options={bucketListData}
											selectedValues={
												multiSelectValues !== "~" && multiSelectValues.bucket ? multiSelectValues.bucket : []
											}
											showCheckbox
											hideSelectedList
											onRemove={e => selectedCustomData(e, bucketListData, "bucket")}
											onSelect={e => selectedCustomData(e, bucketListData, "bucket")}
										/>

										<ErrorMessage
											errors={errors}
											name="bucket"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
											)}
										/>
									</div>
								</div>
							) : ""}
							{type === "emiDate" || type === "overdue" ? (
								<div className="col-md-4"> 
									<div className="mb-3">
										<label>Select EMI Date</label>
										<Select
											value={emiDateFrequency}
											onChange={e => setEmiDateFrequency(e.value)}
											options={Emidatelist}
											placeholder="Choose EMI Date"
											className="select-placehoder"
										/>
									</div>
								</div>
							) : ""}
							<div className="col-md-4">
								<div className="mb-3">
									<label htmlFor="float-text" className="floating-label fw400 fs-5">
										Loan Product
									</label>
									<Multiselect
										className="custom-select-multifilters"
										avoidHighlightFirstOption
										emptyRecordMsg="No Loan Product Found"
										placeholder={selectedCount?.loan_product > 0 ? selectedCount.loan_product + " " + "Selected" : "Select Loan Product"}
										displayValue="label"
										selectedValues={multiSelectValues !== "~" && multiSelectValues.loan_product ? multiSelectValues.loan_product : []}
										options={loanProductsOptions}
										showCheckbox
										hideSelectedList
										onRemove={e => selectedCustomData(e, loanProductsOptions, "loan_product")}
										onSelect={e => selectedCustomData(e, loanProductsOptions, "loan_product")}
									/>
								</div>
							</div>
						
							<div className="col-md-4">
								<div className="mb-3">
									<label>Messages Per Day</label>
									<input
										placeholder="Number of messages"
										className="form-control"
										{...register("message_per_day", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="message_per_day"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
							</div>
							
							<div className="col-md-4">
								<div className="mb-3">
									<label>Frequency</label>
									<Select
										className="frequency-select select-placehoder"
										value={emiDateFrequency}
										onChange={e => setEmiDateFrequency(e.value)}
										options={MsgFrequency}
										placeholder="Choose Frequency"
									/>

									<ErrorMessage
										errors={errors}
										name="bucket"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="mb-3 timepicker">
									<label>Start Time</label>
									<Datepicker
										selected={startTime}
										onChange={(date) => setStartTime(date)}
										showTimeSelect
										showTimeSelectOnly
										className={"form-control"}
										timeIntervals={15}
										timeCaption="Time"
										dateFormat="h:mm aa"
										placeholderText="Choose Start Time"
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="mb-3 timepicker">
									<label>End Time</label>
									<Datepicker
										selected={endTime}
										onChange={(date) => setEndTime(date)}
										showTimeSelect
										showTimeSelectOnly
										className={"form-control"}
										timeIntervals={15}
										timeCaption="Time"
										dateFormat="h:mm aa"
										placeholderText="Choose End Time"
									/>
								</div>
							</div>
							<div className="col-md-12 template">
								<div className="mb-3">
									<label>Select Message Template</label>
									<div className="radio-wrapper">
										
										{templates && templates.length ? templates.map((value, index) => {
											return(
												<div className="radio-item" key={`message-temp-${index}`}>
													<input
														type="radio"
														name="message_templates"
														className="radioInput"
														id={`message_templates-${index}`}
														value={value.sms_log_enc_id}
														{...register("message_templates", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor={`message_templates-${index}`}
														className="option option-1"
														style={{
															justifyContent: "flex-start",
															marginBottom: "10px"
														}}
													>
														<div className="dot"></div>
														<span>{value.content}</span>
													</label>
												</div>
											);
										}) : ""}
										
									</div>
								</div>
							</div>
							<div className="col-md-12 text-center">
								<div className="mb-3">
									{formIsLoading ? (
										<button
											type="button"
											id="Submit"
											name="Submit"
											className="btn btn-primary"
											disabled={true}
										>
											<img
												src="/images/pages/home/loader.svg"
												alt={process.env.REACT_APP_NAME}
											/>
										</button>
									) : (
										<button type="submit" className="btn btn-primary">
											Save
										</button>
									)}
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default SmsFrequencyModal;
