import AppsFormDisplay from "../../components/dashboard/AppsFormDisplay";
import { useEffect, useState } from "react";
import axios from "axios";
import { RiLoader4Line } from "react-icons/ri";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const AppView = () => {
	const [show, setShow] = useState(false);
	const [appData, setAppData] = useState({});
	const [selectedApp, setSelectedApp] = useState({});
	const showFormPage = data => {
		setSelectedApp(data);
		setShow(true);
	};
	const hideFormDetail = () => {
		setShow(false);
	};
	const getAppDetail = async app_id => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organization-apps/detail`,
				{ app_id }
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					setAppData(response.data.response.detail);
				}else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting application detail, Please try again");
			});
	};
	useEffect(() => {
		async function fetchData() {
			const app_id = window.location.pathname.split("/").slice(-1).toString();
			await getAppDetail(app_id);
		}

		fetchData();
	}, []);
	return (
		<div className="grayBg">
			{appData && appData.app_enc_id ? (
				<>
					<Helmet>
						<title>{appData.app_name} | {process.env.REACT_APP_NAME}</title>
						<meta name="description" content={appData.app_description} />
					</Helmet>
					<div className="appWidth">
						<div className="applogo">
							<div className="applogoimg">
								<img src={appData.logo} alt={appData.app_name} />
							</div>
						</div>
						<h1>{appData.app_name}</h1>
						<p>{appData.app_description}</p>
						{appData.organizationAppFields.length
							? appData.organizationAppFields.map(data => {
								return (
									<div className="formWidgets" key={data.field_enc_id}>
										<button
											className="appWidgets"
											onClick={() => showFormPage(data)}
										>
											{data.name}
										</button>
									</div>
								);
							  })
							: ""}
					</div>
					<AppsFormDisplay show={show} hide={hideFormDetail} data={selectedApp} />
				</>
			) : (
				<>
					<div className="text-center">
						<RiLoader4Line className="spin" size={140} style={{ color: "#00a0e3" }} />
						<h1 className="mb-2 fw-semi-bold">Please wait..</h1>
					</div>
				</>
			)}
		</div>
	);
};

export default AppView;
