import { useContext, useEffect, useState } from "react";
import DateRangeFilter from "./dashboard/DateRangeFilter";
import Multiselect from "multiselect-react-dropdown";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";

const MultiDataFilters = ({
	loan_products,
	loan_types,
	loan_product_portfolio,
	getData = null,
	type = [],
	defaultDate = null,
	dateRange = true,
	nameLoanType = false,
	dealer_products
}) => {
	const [filters, setFilters] = useState({start_date: "",end_date: ""});
	const [dealerProductsOptions, setDealerProductsOptions] = useState([]);
	const [loanProductsOptions, setLoanProductsOptions] = useState([]);
	const [portfolioOptions, setLoanPortfolioOptions] = useState([]);
	const [loanTypeOptions, setLoanTypeOptions] = useState([]);
	const [multiSelectValues, setMultiSelectValues] = useState("~");
	const [selectedCount, setSelectedCount] = useState("0");
	const [branchesOptions, setBranchesOptions] = useState([]);
	const [statesOptions, setStatesOptions] = useState([]);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const {branches_list, getBranches, getStates, states_list, departmentList, getOrganizationDepartments} =useContext(OrganizationDashboardContext);

	const dealerProductArray = [
		"E-Rickshaw",
		"EV Two Wheeler",
		"Two Wheeler",
		"L5 E-Rickshaw",
		"Used E-Rickshaw",
		"Used Commercial Vehicle Loan",
		"Used Bike Loan",
		"Used Car Loan",
	];

	useEffect(() => {

		if (dealer_products && dealer_products.length) {
			const tempdealerProductsOptions = [];
			dealer_products.map(dealer_products => {
				if(dealerProductArray.includes(dealer_products.name)){
					tempdealerProductsOptions.push({
						value: dealer_products.financer_loan_product_enc_id,
						label: dealer_products.name
					});
				}
			});
			setDealerProductsOptions(tempdealerProductsOptions);
		}

		if (loan_products && loan_products.length) {
			const loanProduct = loan_products.map(loanProducts => ({
				value: loanProducts.financer_loan_product_enc_id,
				label: loanProducts.name
			}));
			const optionsWithAll = [{ label: "All", value: "all" }, ...loanProduct];
			setLoanProductsOptions(optionsWithAll);
		}

		if (loan_product_portfolio && loan_product_portfolio.length) {
			const productPortfolio = loan_product_portfolio.map(loanProducts => ({
				value: loanProducts.loan_type_enc_id,
				label: loanProducts.name
			}));
			const optionsWithAll = [{ label: "All", value: "all" }, ...productPortfolio];
			setLoanPortfolioOptions(optionsWithAll);
		}

		if (loan_types && loan_types.length) {
			const loanTypes = loan_types.map(loanProducts => ({
				value: loanProducts.loan_type,
				label: loanProducts.loan_type
			}));
			const optionsWithAll = [{ label: "All", value: "all" }, ...loanTypes];
			setLoanTypeOptions(optionsWithAll);
		}

		if (departmentList && departmentList.length) {
			const departmentOptions = departmentList.map(value => ({
				value: value.value,
				label: value.label
			}));
			const optionsWithAll = [{ label: "All", value: "all" }, ...departmentOptions];
			setDepartmentOptions(optionsWithAll);
		}

		if (branches_list && branches_list.length) {
			const branches = branches_list.map(value => ({
				value: value.id,
				label: value.value,
				state_enc_id: value.state_enc_id
			}));
			const optionsWithAll = [{ label: "All", value: "all" }, ...branches];
			setBranchesOptions(optionsWithAll);
		}
		
		if (states_list && states_list.length) {
			const states = states_list.map(value => ({
				value: value.state_enc_id,
				label: value.name
			}));
			const optionsWithAll = [{ label: "All", value: "all" }, ...states];
			setStatesOptions(optionsWithAll);
		}
	}, [loan_products, loan_product_portfolio, branches_list, loan_types, departmentList, states_list, dealer_products]);

	const renderOptions = (list, value = []) =>{
		return value.length ? list.filter(branch =>
			value.includes(branch.state_enc_id)
		) : list;
	};

	useEffect(() => {
		if (filters.start_date && filters.end_date || filters.loan_type) {
			getData(filters);
		}
	}, [filters]);

	useEffect(() => {
		if(!departmentList){
			getOrganizationDepartments();
		}
		if (!branches_list) {
			getBranches();
		}
		if (!states_list || !states_list.length) {
			getStates();
		}
	},[]);

	const [selectAllArr, setSelectAllArr] = useState({});
	const [previousAllArr, setpreviousAllArr] = useState({});

	const selectedCustomData = (selected, list = null, id) => {
		let selectAll = selected.filter(item => item.value === "all");
		selectAll = selectAll.length ? selectAll[0] : "";
		let prevSelectAll = previousAllArr && previousAllArr[id] ? previousAllArr[id].filter(item => item.value === "all") : ""; 
		let flag = false;

		if(list && selected.length === list.length - 1 && !selectAll && prevSelectAll.length){
			flag = false;
			selected = [];
		}else if((selected.length && selectAll && !selectAllArr[id]) || (list && selected.length === list.length - 1 && !selectAll)){
			flag = true;
			selected = list;
		}else{
			flag = false;
			selected = selected.filter(item => item.value != "all");
		}
						
		setpreviousAllArr({...previousAllArr, [id]: selected});		
		setSelectAllArr({...selectAllArr, [id]: flag});
		setFilters({
			...filters,
			[id]: selected.filter(data => data.value !== "all").map(option => option.value),
			...(nameLoanType && id === "loan_product" && {
			  loanNameType: selected.filter(data => data.value !== "all").map(option => option.label)
			})
		  });
		setMultiSelectValues({...multiSelectValues, [id]:selected});
		setSelectedCount({...selectedCount, [id]: selected.length});
	};
	return (
		<>
			{dateRange ? (
				<DateRangeFilter
					callBack={vals => setFilters({ ...filters, ...vals })}
					defaultDate={defaultDate}
				/>
			) : ""}
			{type.includes("LoanProductPortfolio") && (
				<div className="form-group stats-width">
					<label htmlFor="float-text" className="floating-label fw400 fs-5">
						Loan Product Portfolio
					</label>
					<Multiselect
						className="custom-select-multifilters"
						avoidHighlightFirstOption
						emptyRecordMsg="No Loan Product Portfolio Found"
						placeholder={selectedCount?.loan_product_portfolio > 0 ? selectedCount.loan_product_portfolio + " " + "Selected" : "Select"}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" && multiSelectValues.loan_product_portfolio ? multiSelectValues.loan_product_portfolio : []}
						options={portfolioOptions}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e, portfolioOptions, "loan_product_portfolio")}
						onSelect={e => selectedCustomData(e, portfolioOptions, "loan_product_portfolio")}
					/>
				</div>
			)}
			{type.includes("DealerProduct") && (
				<div className="form-group stats-width res-design">
					<label htmlFor="float-text" className="floating-label fw400 fs-5">
						Loan Product
					</label>
					<Multiselect
						className="custom-select-multifilters"
						avoidHighlightFirstOption
						emptyRecordMsg="No Loan Product Portfolio Found"
						placeholder={selectedCount?.dealer_product > 0 ? selectedCount.dealer_product + " " + "Selected" : "Select"}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" && multiSelectValues.dealer_product ? multiSelectValues.dealer_product : []}
						options={dealerProductsOptions}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e, portfolioOptions, "dealer_product")}
						onSelect={e => selectedCustomData(e, portfolioOptions, "dealer_product")}
					/>
				</div>
			)}
			{type.includes("LoanProduct") && (
				<div className="form-group stats-width res-design">
					<label htmlFor="float-text" className="floating-label fw400 fs-5">
						Loan Product
					</label>
					<Multiselect
						className="custom-select-multifilters"
						avoidHighlightFirstOption
						emptyRecordMsg="No Loan Product Found"
						placeholder={selectedCount?.loan_product > 0 ? selectedCount.loan_product + " " + "Selected" : "Select"}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" && multiSelectValues.loan_product ? multiSelectValues.loan_product : []}
						options={loanProductsOptions}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e, loanProductsOptions, "loan_product")}
						onSelect={e => selectedCustomData(e, loanProductsOptions, "loan_product")}
					/>
				</div>
			)}
			{type.includes("loanAccountsType") && (
				<div className="form-group stats-width">
					<label htmlFor="float-text" className="floating-label fw400 fs-5">
            			Loan Type
					</label>
					<Multiselect
						className="custom-select-multifilters"
						avoidHighlightFirstOption
						emptyRecordMsg="No Loan Type Found"
						placeholder={selectedCount?.loan_type > 0 ? selectedCount.loan_type + " " + "Selected" : "Select"}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" && multiSelectValues.loan_type ? multiSelectValues.loan_type : []}
						options={loanTypeOptions}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e, loanTypeOptions, "loan_type")}
						onSelect={e => selectedCustomData(e, loanTypeOptions, "loan_type")}
					/>
				</div>
			)}
			{type.includes("departments") && (
				<div className="form-group stats-width">
					<label htmlFor="float-text" className="floating-label fw400 fs-5">
            			Department
					</label>
					<Multiselect
						className="custom-select-multifilters"
						avoidHighlightFirstOption
						emptyRecordMsg="No Department Found"
						placeholder={selectedCount?.departmnets > 0 ? selectedCount.departmnets + " " + "Selected" : "Select"}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" && multiSelectValues.departmnets ? multiSelectValues.departmnets : []}
						options={departmentOptions}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e, departmentOptions, "departmnets")}
						onSelect={e => selectedCustomData(e, departmentOptions, "departmnets")}
					/>
				</div>
			)}
			{type.includes("branches") && (
				<div className="form-group stats-width">
					<label htmlFor="float-text" className="floating-label fw400 fs-5">
           				 Branch
					</label>
					<Multiselect
						className="custom-select-multifilters"
						avoidHighlightFirstOption
						emptyRecordMsg="No Branches Found"
						placeholder={selectedCount?.branch_name > 0 ? selectedCount.branch_name + " " + "Selected" : "Select"}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" && multiSelectValues.branch_name ? multiSelectValues.branch_name : []}
						options={renderOptions(branchesOptions, filters?.state_name)}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e, branchesOptions, "branch_name")}
						onSelect={e => selectedCustomData(e, branchesOptions, "branch_name")}
					/>
				</div>
			)}
			{type.includes("states") && (
				<div className="form-group stats-width">
					<label htmlFor="float-text" className="floating-label fw400 fs-5">
           				 State
					</label>
					<Multiselect
						className="custom-select-multifilters"
						avoidHighlightFirstOption
						emptyRecordMsg="No States Found"
						placeholder={selectedCount?.state_name > 0 ? selectedCount.state_name + " " + "Selected" : "Select"}
						displayValue="label"
						selectedValues={multiSelectValues !== "~" && multiSelectValues.state_name ? multiSelectValues.state_name : []}
						options={statesOptions}
						showCheckbox
						hideSelectedList
						onRemove={e => selectedCustomData(e, statesOptions, "state_name")}
						onSelect={e => selectedCustomData(e, statesOptions, "state_name")}
					/>
				</div>
			)}
		</>
	);
};
export default MultiDataFilters;
