import React, { createContext, useReducer, useState } from "react";
import axios from "axios";
import OrganizationSidebarReducer from "../reducers/OrganizationSidebarReducer";
import { toast } from "react-toastify";



export const OrganizationSidebarContext = createContext({});

export const OrganizationSidebarProvider = ({ children }) => {
	const [state, dispatch] = useReducer(OrganizationSidebarReducer, {});
	const [isLoading, setIsLoading] = useState(false);
	const getDocumentsData = async (loan_id, type) => {
		setIsLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/loan-detail-images`,
				{
					loan_id: loan_id,
					type: type
				}
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: type === 1 ? "SET_CERTIFICATES" : "SET_IMAGES",
						payload:
							type === 1
								? response.data.response.data.loanCertificates
								: response.data.response.data.loanApplicationImages
					});
				} else {
					dispatch({
						type: type === 1 ? "SET_CERTIFICATES" : "SET_IMAGES",
						payload: ""
					});
				}
			})
			.catch(err => {
				console.log("Error Occurred", err);
			});
	};
	const clearSidebarData = () => {
		dispatch({
			type: "SET_CERTIFICATES",
			payload: ""
		});
		dispatch({
			type: "SET_IMAGES",
			payload: ""
		});
	};

	const getLoanApplicationPendencies = async (loan_id, product_id, type = null) => {
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/get-assigned-pendencies`, 
			{"loan_id": loan_id, "product_id": product_id, "type": type })
			.then(response => {
				if(response.data.response.status === 200){
					dispatch({
						type: type ? "SET_SELECTED_PENDENCIES" : "SET_DEFAULT_PENDENCIES",
						payload: response.data.response.data
					});
				}else{
					dispatch({
						type: type ? "SET_SELECTED_PENDENCIES" : "SET_DEFAULT_PENDENCIES",
						payload: []
					});
				}
			}
			).catch(err => {
				console.log("Error Occured", err);
			});
	};

	const getPendencyImages = async (loan_id) => {
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/get-pendency-images`, {"loan_id": loan_id})
			.then(response => {
				if(response.data.response.status === 200){
					dispatch({
						type: "SET_PENDENCY_IMAGES",
						payload: response.data.response.data
					});
				}else{
					dispatch({
						type: "SET_PENDENCY_IMAGES",
						payload: []
					});
				}
			}).catch(err => {
				console.log("Error Occured", err);
			});
	};

	const getTatReport = async (loan_id) => {
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-applications/status-tat`, {"loan_app_enc_id": loan_id})
			.then(response => {
				if(response.data.response.status === 200){
					dispatch({
						type: "SET_TAT_REPORT",
						payload: response.data.response.data
					});
				}else{
					dispatch({
						type: "SET_TAT_REPORT",
						payload: []
					});
				}
			}).catch(err => {
				console.log("Error Occured", err);
				toast.error("Error Occured", err);
			});
	};
	const getEnachLogs = async (loan_id) => {
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/lead-nach-logs`, {"loan_account_enc_id": loan_id})
			.then(response => {
				if(response.data.response.status === 200){
					dispatch({
						type: "SET_ENACH_LOGS",
						payload: response.data.response.data
					});
				}else{
					dispatch({
						type: "SET_ENACH_LOGS",
						payload: []
					});
				}
			}).catch(err => {
				console.log("Error Occured", err);
				toast.error("Error Occured", err);
			});
	};

	return (
		<OrganizationSidebarContext.Provider
			value={{
				certificates: state.certificates,
				loanImages: state.loanImages,
				defaultPendencies: state.defaultPendencies,
				assignedPendencies: state.assignedPendencies,
				pendencyImages: state.pendencyImages,
				isLoading,
				tatReportData : state.tatReportData,
				enachLogsData : state.enachLogsData,
				getTatReport,
				getEnachLogs,
				setIsLoading,
				getDocumentsData,
				clearSidebarData,
				getLoanApplicationPendencies,
				getPendencyImages
			}}
		>
			{children}
		</OrganizationSidebarContext.Provider>
	);
};
