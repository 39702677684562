export const mortgage_type_options = [
	{
		value: "Lien",
		label: "Lien"
	},
	{
		value: "RM",
		label: "RM"
	},
	{
		value: "EM",
		label: "EM"
	}
];

export const property_asset_types = [
	{
		value: "SORP",
		label: "SORP"
	},
	{
		value: "SOCP",
		label: "SOCP"
	},
	{
		value: "Plot",
		label: "Plot"
	}
];

export const property_tv_scheme = [
	{
		value: "Yes",
		label: "Yes"
	},
	{
		value: "No",
		label: "No"
	}
];