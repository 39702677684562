import moment from "moment";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoOpenOutline } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";

const EmployeeLoanList = ({
	showSidebar,
	handleClose,
	userResponse,
	userData,
	userLoans,
	loading,
	selectedRow,
	setSelectedRow
}) => {
	function debounce(func) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			});
		};
	}

	const debounceMoreApps = debounce(() => getMoreLoanApps());

	const getMoreLoanApps = () => {
		if (
			userLoans.length <= userResponse.page * userResponse.limit &&
			userResponse.page === selectedRow.page
		) {
			setSelectedRow({ ...selectedRow, page: selectedRow.page + 1 });
		}
	};

	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail ${showSidebar === false ? "expend-detail-close" : ""}`}>
				<div className="closeIcon" onClick={handleClose}>
					<AiFillCloseCircle />
				</div>
				
				<PerfectScrollbar
					options={{ suppressScrollX: true }}
					onYReachEnd={debounceMoreApps}
				>
					<div className="expend-data expand-data-details">
						<div className="row mb-3">
							<h3>Employee Details</h3>
							<div className="col-md-4">
								<p style={{ textTransform: "capitalize" }}>
									<span>Name:</span> 
									<img src={userData.employee_image}
										className="EmployeeAvatar" />
									{userData.employee_name}
								</p>
							</div>
							<div className="col-md-4">
								<p style={{ textTransform: "capitalize" }}>
									<span>Code:</span> {userData.employee_code}
								</p>
							</div>
							<div className="col-md-4">
								<p style={{ textTransform: "capitalize" }}>
									<span>Branch:</span> {userData.branch_name ? userData.branch_name : "--"}
								</p>
							</div>
							<div className="col-md-4">
								<p style={{ textTransform: "capitalize" }}>
									<span>Email:</span> {userData.email}
								</p>
							</div>
							<div className="col-md-4">
								<p style={{ textTransform: "capitalize" }}>
									<span>Phone:</span> {userData.phone}
								</p>
							</div>
							<div className="col-md-4">
								<p style={{ textTransform: "capitalize" }}>
									<span>Reporting Person:</span> 
									<img src={userData.reporting_image}
										className="EmployeeAvatar" />
									{userData.reporting_person}
								</p>
							</div>
						</div>
						
						<div className="row">
							<h3>Loans</h3>
							{userResponse ? (
								<>
									{userLoans
										? userLoans.map((value, index) => {
											const copyLink =
										window.location.origin + "/account/loan-application/" + value.loan_app_enc_id;
											const copy = async () => {
												await navigator.clipboard.writeText(copyLink);
												toast.success("Link copied");
											};
											return (
												<div
													className="col-md-6"
													key={`loanBox-${index}`}
												>
													<div className="loanBox position-relative">
														<div className="position-absolute link-buttons mx-3 mt-2">
															<button
																type="button"
																className="btn btn-primary btn-sm mb-1"
																onClick={copy}
																style={{ marginLeft: "4px", padding: "4px 6px" }}
															>
																<MdOutlineContentCopy style={{ margin: "0px 2px" }} size={16} />
															</button>
															<a
																href={copyLink}
																target="_blank"
																className="btn btn-primary btn-sm mb-1"
																style={{ marginLeft: "4px", padding: "4px 6px" }}
																rel="noreferrer"
															>
																<IoOpenOutline style={{ margin: "0px 2px" }} size={16} />
															</a>
														</div>
														<div className="row my-2">
															<div className="col-md-12">
																<p
																	style={{
																		textTransform:
																			"capitalize"
																	}}
																>
																	<span>Applicant Name:</span>{" "}
																	{value.name}
																</p>
															</div>
															<div className="col-md-6">
																<p>
																	<span>Product</span>:{" "}
																	{value.product_name}
																</p>
															</div>
															<div className="col-md-6">
																<p>
																	<span>Loan Amount</span>:{" "}
																	{parseFloat(
																		value.amount
																	).toLocaleString("en-IN")}
																</p>
															</div>
															<div className="col-md-6">
																<p style={{ textTransform: "capitalize" }}>
																	<span className="me-1">Login Date:</span>{value.login_date ? moment(value.login_date).format(
																		"DD MMM YYYY"
																	) : ("-")}
																</p>
															</div>
															<div className="col-md-12">
																<p style={{color: "#00a0e3"}}>
																	<span>Loan Status</span>:{" "}
																	{value.loan_status}<span>{value.loan_status_updated_on ? " (As of " + moment(value.loan_status_updated_on).format(
																		"DD MMM YYYY"
																	) + ")" : ""}</span>
																</p>
															</div>
															<div className="col-md-4">
																<p>
																	<span>Cibil</span>:{" "}
																	{value
																		.creditLoanApplicationReports
																		.length
																		? value
																			.creditLoanApplicationReports[0]
																			.cibil
																		: "0"}
																</p>
															</div>
															<div className="col-md-4">
																<p>
																	<span>Equifax</span>:{" "}
																	{value
																		.creditLoanApplicationReports
																		.length
																		? value
																			.creditLoanApplicationReports[0]
																			.equifax
																		: "0"}
																</p>
															</div>
															<div className="col-md-4">
																<p>
																	<span>Crif</span>:{" "}
																	{value
																		.creditLoanApplicationReports
																		.length
																		? value
																			.creditLoanApplicationReports[0]
																			.crif
																		: "0"}
																</p>
															</div>
														</div>
													</div>
												</div>
											);
										})
										: ""}
								</>
							) : (
								<>
									{loading ? (
										<div className="cus-loader">
											<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
										</div>
									): (
										<div className="mt-2 p-2 mx-2">
													No Loan Application Found!
										</div>
									)}
								</>
							)}
						</div>
						
					</div>
				</PerfectScrollbar>
				
			</div>
		</>
	);
};

export default EmployeeLoanList;
