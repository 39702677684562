import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import LoanAccountPtpTable from "../components/dashboard/LoanAccountPtpTable";

const PtpApplications = () => {
	return (
		<>
			<Helmet>
				<title>PTP Applications</title>
			</Helmet>
			<Navbar />
			<div className="container-fluid py-5">
				<LoanAccountPtpTable pageType="ptpPage" />
			</div>
		</>
	);
};

export default PtpApplications;
