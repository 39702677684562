import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { BiEdit, BiPlusCircle } from "react-icons/bi";
import { RiDeleteBin6Line, RiDragMove2Line } from "react-icons/ri";
import { ReactSortable } from "react-sortablejs";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const CreateMyApp = () => {
	const history = useNavigate();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const form = useRef(null);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [editForm, setEditForm] = useState(false);
	const [elementsData, setElementsData] = useState([]);
	const [selectedValue, setSelectedValue] = useState({ index: "", name: "", link: "" });
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm();
	const [allUsers, setAllUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [usersList, setUsersList] = useState([]);

	const params = useParams();
	let all_users = [];
	const getAllUsers = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/all-employees`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setAllUsers(response.data.response.all_employees);
					all_users = response.data.response.all_employees;
				}else {
					toast.error("An error has occurred, please try again later.");
				}
			})
			.catch(error => {
				console.log(error);
				setFormIsLoading(false);
				toast.error("An Internal error has occurred, please try again later.");
			});
	};

	const getAppDetails = async app_id => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organization-apps/detail`,
				{ app_id }
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 200) {
					const appData = response.data.response.detail;
					setElementsData(appData.organizationAppFields);
					setFile(appData.logo);
					setValue("app_name", appData.app_name);
					setValue("app_description", appData.app_description);
					setValue("assigned_to", appData.assigned_to);
					setList(appData.assigned_to);
					getDefaultValue(appData.organizationAppUsers);
					// toast.success("App Created Successfully");
				} else {
					toast.error("An error has occurred, please try again later.");
				}
			})
			.catch(error => {
				console.log(error);
				setFormIsLoading(false);
				toast.error("An Internal error has occurred, please try again later.");
			});
	};
	const setList = list => {
		let allFilUser = [];
		for (let i = 0; i < list.length; i++) {
			let filUser = all_users.filter(item => item.user_type === list[i]);
			allFilUser = [...allFilUser, ...filUser];
		}
		setFilteredUsers(allFilUser);
	};

	const getDefaultValue = users => {
		let seletedAppUsers = [];
		for (let i = 0; i < users.length; i++) {
			seletedAppUsers.push({
				value: users[i].value,
				label: users[i].label,
				user_type: users[i].user_type
			});
		}
		setUsersList(seletedAppUsers);
	};

	const removeField = async field_id => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organization-apps/remove-element`,
				{ field_id }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					// toast.success("App Updated");
				} else {
					toast.error("An error has occurred, please try again later.");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An Internal error has occurred, please try again later.");
			});
	};
	const addNewElement = () => {
		setElementsData([
			...elementsData,
			{ id: elementsData.length + 1, name: "", link: "", field_type: "button" }
		]);
		setSelectedValue({ index: elementsData.length, name: "", link: "" });
		handleShow();
	};

	const handleEdit = (index, name, link) => {
		setSelectedValue({ index, name, link });
		handleShow();
	};

	const removeElement = (index, id) => {
		if (window.confirm("Are you sure you want to remove this element")) {
			const tempElements = [...elementsData];
			tempElements.splice(index, 1);
			setElementsData(tempElements);
			if (id) {
				removeField(id);
			}
		}
	};

	const handleChange = (index, type, value) => {
		setSelectedValue({ ...selectedValue, [type]: value });
		let items = [...elementsData];
		let item = { ...items[index] };
		item[type] = value;
		items[index] = item;
		setElementsData(items);
	};

	const onsubmit = async values => {
		const formData = new FormData(form.current);
		if (!elementsData.length) {
			toast.warn("Please create at least one element");
			return false;
		}
		if (elementsData.length) {
			for (let i = 0; i < elementsData.length; i++) {
				if (!elementsData[i].name || !elementsData[i].link) {
					toast.warn("Please fill all detail in elements");
					return false;
				}
			}
		}
		formData.append("assigned_to", JSON.stringify(values.assigned_to));
		if (editForm) {
			formData.append("app_id", params.id);
		}
		formData.append("elements", JSON.stringify(elementsData));
		values.elements = elementsData;
		setFormIsLoading(true);

		if (selectedUsers) {
			formData.append("assigned_users", JSON.stringify(selectedUsers));
		}
		values.assigned_users = selectedUsers;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${
					process.env.REACT_APP_API_URL_VERSION
				}organization-apps/${editForm ? "update" : "add"}`,
				formData
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 201) {
					reset();
					setElementsData([]);
					setFile("");
					toast.success(`App ${editForm ? "Updated" : "Created"} Successfully`);
					history("/account/apps/list");
				} else {
					toast.success("An error has occured, please try again later.");
				}
			})
			.catch(error => {
				console.log(error);
				setFormIsLoading(false);
			});
	};
	const [file, setFile] = useState();
	const handleLogo = e => {
		if (e.target.files) {
			setFile(URL.createObjectURL(e.target.files[0]));
		} else {
			setFile("");
		}
	};

	const formatOptionLabel = ({ label, user_type }) => {
		return (
			<div style={{ display: "flex" }}>
				<div>{label}</div>
				<div style={{ marginLeft: "10px", color: "#ccc" }}>{user_type}</div>
			</div>
		);
	};

	const [selectedUsers, setSelectedUsers] = useState([]);
	const selectedUser = selectedOption => {
		let customSelectUser = [];
		if (selectedOption) {
			selectedOption.map(value => {
				customSelectUser.push(value.value);
			});
		}
		setSelectedUsers(customSelectUser);
	};
	const filterUsers = elem => {
		if (elem.target.checked) {
			let filUser = allUsers.filter(item => item.user_type === elem.target.value);
			setFilteredUsers([...filteredUsers, ...filUser]);
		} else {
			const tempArray = filteredUsers.filter(item => item.user_type !== elem.target.value);
			setFilteredUsers(tempArray);
		}
	};

	useEffect(() => {
		if (params && params.id) {
			setEditForm(true);
			getAppDetails(params.id);
		}
		getAllUsers();
	}, []);

	return (
		<div>
			<Helmet>
				<title>Dashboard</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="max-width-600 bg-white shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5">
						{/* <div className="">*/}
						<h4 className="dashboard-heading">
							{editForm ? "Update" : "Create New"} App
						</h4>
						<form
							ref={form}
							method="post"
							id="appForm"
							onSubmit={handleSubmit(onsubmit)}
						>
							<div className="mb-3 d-flex justify-content-center align-items-center">
								{/* <div>*/}
								<img
									style={{
										maxWidth: "150px",
										maxHeight: "150px",
										border: "5px solid #fff",
										boxShadow: "0px 1px 10px 1px #ddd",
										borderRadius: "6px"
									}}
									src={file ? file : "https://place-hold.it/150"}
									alt="Logo"
								/>
								{/* </div>*/}
							</div>
							<div className="mb-3">
								<div>
									<label className="form-label mb-0" htmlFor="loan_amt">
										Choose Logo
									</label>
									<input
										type="file"
										min={1}
										placeholder="Choose File"
										className="form-control"
										{...register("logo", {
											required: !editForm ? "This is required." : false,
											validate: {
												lessThan10MB: files =>
													files.length
														? files[0]?.size < 5000000 || "Max 5MB"
														: true,
												acceptedFormats: files =>
													files.length
														? ["image/jpeg", "image/png"].includes(
															files[0]?.type
														  ) || "Only PNG, JPEG and PDF"
														: true
											}
										})}
										onChange={handleLogo}
									/>
									<ErrorMessage
										errors={errors}
										name="logo"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="app_name">
									App Name
								</label>
								<input
									placeholder="Full Name"
									className="form-control"
									{...register("app_name", {
										required: "This is required."
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="app_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>
											{message}
										</p>
									)}
								/>
							</div>
							<div className="mb-3">
								<label
									className="sr-only form-label mb-0"
									htmlFor="app_description"
								>
									App Description{" "}
								</label>
								<textarea
									className="form-control"
									id="app_description"
									rows="4"
									{...register("app_description", {
										required: "This is required."
									})}
									placeholder="App Description"
								></textarea>
								<ErrorMessage
									errors={errors}
									name="app_description"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>
											{message}
										</p>
									)}
								/>
							</div>
							<div className="mb-3">
								<h4>Elements</h4>
								{elementsData.length ? (
									<>
										<ReactSortable
											list={elementsData}
											setList={setElementsData}
										>
											{elementsData.map((value, index) => {
												return (
													<div
														className="element-item"
														key={`form-${index}`}
													>
														<RiDragMove2Line
															size={25}
															style={{ marginRight: "10px" }}
														/>
														<div className="element-label">
															{value.name
																? value.name
																: "Click edit to enter name and their link"}
														</div>
														<button
															className="link-button"
															style={{ marginRight: "10px" }}
															onClick={() =>
																handleEdit(
																	index,
																	value.name,
																	value.link
																)
															}
														>
															<BiEdit size={25} />
														</button>
														<button
															className="link-button"
															style={{ color: "red" }}
															onClick={() =>
																removeElement(
																	index,
																	value.field_enc_id
																		? value.field_enc_id
																		: ""
																)
															}
														>
															<RiDeleteBin6Line size={25} />
														</button>
													</div>
												);
											})}
										</ReactSortable>
										<button
											className="add-element-btn-sm bg-primary text-white text-center d-block link-button"
											onClick={() => addNewElement()}
										>
											<BiPlusCircle /> Add New Element
										</button>
									</>
								) : (
									<button
										className="add-element-btn-lg bg-primary text-white text-center d-block link-button"
										onClick={() => addNewElement()}
									>
										<BiPlusCircle /> Click here to add first element
									</button>
								)}

								{/* <label className="sr-only form-label mb-0">Select Forms</label>*/}
								{/* {appsForms.allForms.map((value,index) => {*/}
								{/*    return (*/}
								{/*        <div className="form-check form-check-inline-2" key={`form-${index}`}>*/}
								{/*            <input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}`}*/}
								{/*                value={value.link} name={`forms[${index}].${value.name}`} {...register(`forms[${index}].${value.name}`, {})}/>*/}
								{/*            <label className="form-check-label" htmlFor={`inlineCheckbox${index}`}>*/}
								{/*                {value.name}*/}
								{/*                <a href={value.link}><BsEyeFill/></a>*/}
								{/*            </label>*/}
								{/*        </div>*/}
								{/*    )*/}
								{/* })}*/}
							</div>
							<div className="mb-3">
								<label className="form-label" htmlFor="">
									Assigned to
								</label>
								<div className="d-block">
									<div className="radio-wrapper">
										<div className="radio-item">
											<input
												type="checkbox"
												name="assigned_to"
												value="Employee"
												className="radioInput"
												id="optionlicense-1"
												{...register("assigned_to", {
													required: "This is required.",
													onChange: filterUsers
												})}
											/>
											<label
												htmlFor="optionlicense-1"
												className="option option-1"
											>
												<span>Employee</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="checkbox"
												name="assigned_to"
												value="DSA"
												className="radioInput"
												id="optionlicense-2"
												{...register("assigned_to", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="optionlicense-2"
												className="option option-2"
											>
												<span>DSA</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="checkbox"
												name="assigned_to"
												value="Connector"
												className="radioInput"
												id="optionlicense-3"
												{...register("assigned_to", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="optionlicense-3"
												className="option option-3"
											>
												<span>Connector</span>
											</label>
										</div>
									</div>
									<ErrorMessage
										errors={errors}
										name="assigned_to"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="mb-3">
								<label className="form-label"></label>
								{usersList && usersList.length ? (
									<>
										<Select
											options={filteredUsers ? filteredUsers : allUsers}
											formatOptionLabel={formatOptionLabel}
											isMulti={true}
											onChange={selectedUser}
											placeholder={"Select Specific Users"}
											defaultValue={usersList}
										/>
									</>
								) : (
									""
								)}
								{params && !params.id ? (
									<Select
										options={filteredUsers ? filteredUsers : allUsers}
										formatOptionLabel={formatOptionLabel}
										isMulti={true}
										onChange={selectedUser}
										placeholder={"Select Specific Users"}
									/>
								) : (
									""
								)}
							</div>
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 text-center">
								{formIsLoading ? (
									<button
										id="Submit"
										disabled={true}
										className="btn btn-primary "
									>
										<img
											src="/images/pages/home/loader.svg"
											alt="Form Loading"
										/>
									</button>
								) : (
									<button
										id="Submit"
										name="Submit"
										className="btn btn-primary"
										style={{ padding: "10px 20px" }}
									>
										Submit
									</button>
								)}
							</div>
						</form>
						{/* </div>*/}
					</div>
				</div>
			</div>
			<Footer />
			<Modal show={show} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>Update Element</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label" htmlFor="last_name">
								Element Name
							</label>
							<input
								placeholder="Enter Name"
								className="form-control"
								value={selectedValue.name}
								onChange={e =>
									handleChange(selectedValue.index, "name", e.target.value)
								}
							/>
						</div>
					</div>
					<div className="col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label" htmlFor="last_name">
								Element Link
							</label>
							<input
								placeholder="Enter Link"
								className="form-control"
								value={selectedValue.link}
								onChange={e =>
									handleChange(selectedValue.index, "link", e.target.value)
								}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>
						Save & Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default CreateMyApp;
