import Navbar from "../../components/Navbar";
import "../../assets/css/accountStyles.css";
import { Helmet } from "react-helmet";
import CreditReports from "../../components/dashboard/CreditReports";

const CreditReportHistory = () => {
	return (
		<div>
			<Helmet>
				<title>Credit Reports | {process.env.REACT_APP_NAME}</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="shadow-lg rounded-3 py-4 px-6">
						<h2 className="mb-4">Credit Reports</h2>
						<CreditReports />
					</div>
				</div>
			</div>
		</div>
	);
};
export default CreditReportHistory;
