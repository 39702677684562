import { Helmet } from "react-helmet";
import IndividualLoanDetails from "./IndividualLoanDetails";
import Navbar from "../../components/Navbar";
import { CandidateDashboardProvider } from "../../context/CandidateDashboardState";
import { useParams } from "react-router-dom";

const IndividualDetails = () => {
	const params = useParams();
	return(
		<>
			<Helmet>
				<title>Loan Details</title>
			</Helmet>
			<Navbar />
			<CandidateDashboardProvider>
				<>
					<IndividualLoanDetails 
						loan_id={params.id}
						type={"mobile"}
					/>
				</>
			</CandidateDashboardProvider>
		</>
	);
};
export default IndividualDetails;