import React, { useContext, useEffect, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import NameComp from "../NameComp";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import DealersSalesReportList from "./DealersSalesReportList";
import MinMaxFilter from "../MinMaxFilter";
import { toast } from "react-toastify";
import MultiDataFilters from "../MultiDataFilters";

const DealersSalesReportTable = () => {
	const { getDealerData, dealerData, getDealerstotalStats, dealerAllStats} = useContext(OrganizationUsersContext);
	const { branches_list, getBranches } = useContext(OrganizationDashboardContext);
	const {loan_products, getAssignedLoanProducts, getAssignedLoanTypes, loan_types  } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	// const [searchValue, setSearchValue] = useState("");
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	// const [selectedUser, setSelectedUser] = useState('')
	const [userLoans, setUserLoans] = useState([]);
	const [userResponse, setUserResponse] = useState("");
	const [totalCounts, setTotalCounts] = useState({count:0, amount: 0});
	const [selectedRow, setSelectedRow] = useState({
		value: "",
		status: "",
		page: 1
	});
	const [loading, setLoading] = useState(false);
	const [sortArr, setSortArr] = useState({});
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		product_id: "",
		loan_type_enc_id:"",
		field: "",
		order_by: ""
	});
	// const [selectedLoanProduct, setSelectedLoanProduct] = useState();
	// const [loanProductsOptions, setLoanProductsOptions] = useState([]);
	const [pageLoading, setPageLoading] = useState(true);
	// const [selectedLoanType, setSelectedLoanType] = useState();
	// const [loanTypesOptions, setLoanTypesOptions] = useState([]);
	const openSideBar = () => {
		setShowSidebar(true);
		// setLoading(true);

		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
		}

		getEmployeeLoanApps();
	};
 
	useEffect(()=>{
		if(dealerData){
			setPageLoading(false);
		}
	},[dealerData]);

	const getEmployeeLoanApps = () => {
		setLoading(true); 

		let data = {
			user_enc_id: selectedRow.value.user_enc_id,
			status: selectedRow.status,
			page: selectedRow.page,
			start_date: filters.start_date + "  00:00:00",
			end_date: filters.end_date + " 23:59:59",
			organization_enc_id: selectedRow.value.assigned_dealer
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/dealer-report-details`,
				data
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					setUserLoans(prev => [...prev, ...response.data.response.data]);
					setUserResponse(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (dealerAllStats && Object.keys(dealerAllStats).length) {
			let totalCount = 0;
			let totalAmounts = 0;
			Object.keys(dealerAllStats).filter((val) => val.match(/_count/)).forEach(val => {
				totalCount += dealerAllStats[val] ? parseInt(dealerAllStats[val]) : 0;
			});
			Object.keys(dealerAllStats).filter((val) => val.match(/_amount/)).forEach(val => {
				totalAmounts += dealerAllStats[val] ? parseFloat(dealerAllStats[val]) : 0;
			});
			setTotalCounts({count: totalCount, amount: totalAmounts});
		}
	}, [dealerAllStats]);
	useEffect(() => {
		if (selectedRow.value) {
			openSideBar(selectedRow.value.user_enc_id, selectedRow.status, selectedRow.value);
		}
	}, [selectedRow]);
	const handleClose = () => {
		setShowSidebar(false);
		setSelectedRow({ value: "", status: "", page: "" });
		setUserResponse("");
		setUserLoans([]);

		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};
	const columns = [
		{
			name: (
				<NameComp
					title={"Name"}
					id={"dealer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}		
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "160px",
			selector: row => (row.dealer_name ? row.dealer_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => setSelectedRow({ value: row, status: [], page: 1 })}
					>
						{row.dealer_name}
					</h6>
				);
			}
		},
		{
			// name: "Total Cases",
			name: <MinMaxFilter
				title={"Total Cases"}
				id={"total_cases"}
				placeholder="Total Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "120px",
			cell: row => {
				return (
					<div>
						{row.total_cases ? row.total_cases : "-"}
						{/* {parseInt(row.new_lead) + parseInt(row.login) + parseInt(row.under_process) + parseInt(row.sanctioned) + parseInt(row.disbursed) + parseInt(row.rejected)} */}
					</div>
				);
			},
			sortable: false
		},
		{
			// name: "New Lead",
			name:  <MinMaxFilter
				title={"New Lead"}
				id={"new_lead"}
				placeholder="Total Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "120px",
			// selector: row => row.new_lead ? row.new_lead : '-',
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.new_lead ? row.new_lead : "-"}
					</div>
				);
			}
		},
		{
			// name: "Login",
			name:  <MinMaxFilter
				title={"Login"}
				id={"login"}
				placeholder="Login"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "120px",
			selector: row => row.login ? row.login : "-",
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.login ? row.login : "-"}

					</div>
				);
			}
		},
		{
			// name: "Under Process",
			name:  <MinMaxFilter
				title={"Under Process"}
				id={"under_process"}
				placeholder="Under Process"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "120px",
			selector: row => (row.under_process ? row.under_process : "-"),
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.under_process ? row.under_process : "-"} 
					</div>
				);
			}
		},
		{	
			
			// name: "Sanctioned",
			name:  <MinMaxFilter
				title={"Sanctioned"}
				id={"sanctioned"}
				placeholder="Sanctioned"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "120px",
			selector: row => (row.sanctioned ? row.sanctioned : "-"),
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.sanctioned ? row.sanctioned : "-"}

					</div>
				);
			}
		},
		{
			// name: "Disbursed",
			name:  <MinMaxFilter
				title={"Disbursed"}
				id={"disbursed"}
				placeholder="Disbursed"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "120px",
			selector: row => (row.disbursed ? row.disbursed : "-"),
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.disbursed ? row.disbursed : "-"}
					</div>
				);
			}
		},
		{
			// name: "Rejected/CNI",
			name:  <MinMaxFilter
				title={"Rejected/CNI"}
				id={"rejected"}
				placeholder="Rejected/CNI"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "130px",
			selector: row => (row.rejected ? row.rejected : "-"),
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.rejected ? row.rejected : "-"}
					</div>
				);
			}
		},
	];

	// ** Function to handle filter
	// const handleFilter = e => {
	// 	setSearchValue(e.target.value);
	// 	getDealerData({
	// 		page: currentPage,
	// 		limit: rowsPerPage,
	// 		keyword: e.target.value,
	// 		start_date: filters.start_date,
	// 		end_date: filters.end_date,
	// 		product_id: filters.loan_id,
	// 		loan_type_enc_id: filters.loan_type_enc_id,
	// 		field: filters.field,
	// 		order_by: filters.order_by
	// 	});
	// };

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		setPageLoading(true);
		getDealerData({
			page: page.selected + 1,
			limit: rowsPerPage,
			keyword: searchArray,
			start_date: filters.start_date,
			end_date: filters.end_date,
			product_id: filters.loan_id,
			loan_type_enc_id: filters.loan_type_enc_id,
			fields_search: searchArray,
			fields_sort: sortArr,
			order_by: filters.order_by
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setPageLoading(true);
		getDealerData({
			page: 1,
			limit: parseInt(e.target.value),
			keyword: searchArray,
			start_date: filters.start_date,
			end_date: filters.end_date,
			product_id: filters.product_id,
			loan_type_enc_id: filters.loan_type_enc_id,
			fields_search: searchArray,
			fields_sort: sortArr,
			order_by: filters.order_by
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({name: ""});
		setPageLoading(true);
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(dealerData.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (dealerData && dealerData.data.length > 0) {
			return dealerData.data;
		} else if (dealerData && dealerData.data.length === 0 && isFiltered) {
			return [];
		} else {
			return dealerData && dealerData.data.length > 0
				? dealerData.data.slice(0, rowsPerPage)
				: [];
		}
	};

	// const handleLoanProductChange = selectedOptions => {
	// 	// Extracting values from selected options
	// 	const selectedValues = selectedOptions.map(option => option.value);

	// 	setSelectedLoanProduct(selectedOptions);

	// 	// Modify the state accordingly
	// 	setFilters({ ...filters, loan_product: selectedValues });
	//   };


	useEffect(() => {
		if (!loan_products) {
			getAssignedLoanProducts();
		}
		if (!loan_types) {
			getAssignedLoanTypes();
		}
		if (!branches_list) {
			getBranches();
		}
	}, []);

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			setPageLoading(true);
			getDealerData({
				page: currentPage,
				limit: rowsPerPage,
				// keyword: searchValue,
				start_date: filters.start_date,
				end_date: filters.end_date,
				product_id: filters.dealer_product,
				loan_type_enc_id: filters.loan_type,
				fields_search: searchArray,
				fields_sort: sortArr,
				order_by: filters.order_by
			});
		}

		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [filters, searchArray, sortArr]);

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			setPageLoading(true);
			getDealerstotalStats({
				start_date: filters.start_date,
				end_date: filters.end_date,
			});
		}
	},[filters]);

	const getDataApi = (filters) => {
		setPageLoading(true);
		setFilters({...searchArray, ...filters});
	};

	return (
		<>
			<div className="row dsa-stats">
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/total-applications.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{totalCounts ? totalCounts.count : "0"}
						</p>
						<p className="card-head mb-1">
							<span>Total Cases</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹ {totalCounts.amount ? parseFloat(totalCounts.amount).toLocaleString("en-IN") : "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/new-leads.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{dealerAllStats && dealerAllStats.new_lead_count
								? dealerAllStats.new_lead_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>New Leads</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{dealerAllStats && dealerAllStats.new_lead_amount
									? parseFloat(dealerAllStats.new_lead_amount).toLocaleString(
										"en-IN"
									)
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/log-in.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{dealerAllStats && dealerAllStats.login_count
								? dealerAllStats.login_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Login</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{dealerAllStats && dealerAllStats.login_amount
									? parseFloat(dealerAllStats.login_amount).toLocaleString(
										"en-IN"
									)
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/under-process.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{dealerAllStats && dealerAllStats.under_process_count
								? dealerAllStats.under_process_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Under Process</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{dealerAllStats && dealerAllStats.under_process_amount
									? parseFloat(
										dealerAllStats.under_process_amount
									).toLocaleString("en-IN")
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/sanctioned.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{dealerAllStats && dealerAllStats.sanctioned_count
								? dealerAllStats.sanctioned_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Sanctioned</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{dealerAllStats && dealerAllStats.sanctioned_amount
									? parseFloat(dealerAllStats.sanctioned_amount).toLocaleString("en-IN")
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/disbursed.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{dealerAllStats && dealerAllStats.disbursed_count
								? dealerAllStats.disbursed_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Disbursed</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{dealerAllStats && dealerAllStats.disbursed_amount
									? parseFloat(dealerAllStats.disbursed_amount).toLocaleString("en-IN")
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/rejected.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{dealerAllStats && dealerAllStats.rejected_count
								? dealerAllStats.rejected_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Rejected/CNI</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{dealerAllStats && dealerAllStats.rejected_amount
									? parseFloat(dealerAllStats.rejected_amount).toLocaleString(
										"en-IN"
									)
									: "0"}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="dashboard-dsa loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
			
				<div className="salesRep px-3">
					<h4 className="m-0 text-capitalize">Dealers Sales Reports</h4>
				</div>
				<Row className="mx-0 mt-1 mb-5">
					<Col sm="9">
						<div className="d-flex flex-wrap align-items-center py-2">
							<div className="form-group">
								<label htmlFor="float-text" className="floating-label fw400 ft14">
								Show Results
								</label>
								<select
									className="dataTable-select form-select"
									style={{ width: "95px", padding: "2px 8px", height: "48px" }}
									id="sort-select"
									value={rowsPerPage}
									onChange={e => handlePerPage(e)}
								>
									<option value={10}>10</option>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={75}>75</option>
									<option value={100}>100</option>
								</select>
							</div>
							<MultiDataFilters
								dealer_products={loan_products}
								getData={getDataApi}
								type={["DealerProduct"]}
							/>
						</div> 
					</Col>
					<Col
						className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
						sm="3"
					>
						{/* <label className="mr-1" htmlFor="search-input">
						Search
					</label>
					<input
						className="dataTable-filter form-control"
						type="text"
						style={{ maxWidth: "150px", margin: "0px 5px", height: "48px" }}
						id="search-input"
						value={searchValue}
						onChange={handleFilter}
					/> */}
						<div className="filters-container d-flex justify-content-end">
							{showClearBtn ? (
								<button
									className="btn btn-primary btn-xs my-2"
									onClick={e => clearFilter(e)}
								>
								Reset
								</button>
							) : (
								""
							)}
						</div>
					</Col>
				</Row>
				<DataTable
					noHeader
					pagination
					paginationServer
					columns={columns}
					data={dataToRender()}
					sortServer={true}
					paginationComponent={CustomPagination}
					progressPending={pageLoading}
					highlightOnHover="true"
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={
						<p className="w-100 m-5 text-start">There are no records to display</p>
					}
					persistTableHead={true}
					className="loan-app-table-container fix-overflow sales-index"
				/>
				<DealersSalesReportList
					showSidebar={showSidebar}
					handleClose={handleClose}
					userData={selectedRow.value}
					userResponse={userResponse}
					userLoans={userLoans}
					loading={loading}
					setSelectedRow={setSelectedRow}
					selectedRow={selectedRow}
				/>
			</div>
		</>
		
	);
};
export default DealersSalesReportTable;
