import { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import AutoComplete from "./AutoComplete";
import axios from "axios";
import { toast } from "react-toastify";

const LoanDetailsPartial = ({ register, errors, setValue }) => {
	const phoneRegExp = /^[1-9]\d*$/;
	const [loanType, setLoanType] = useState("");
	// const [allowLoanType, setAllowLoanType] = useState(true);
	const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
	const vehicleOp = localStorage.getItem("vehicle_option");
	const getCompanyTypeOptions = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}utilities/organization-types`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					setCompanyTypeOptions(response.data.response.organization_types);
				}
				else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
			});
	};

	useEffect(() => {
		register("vehicle_type");
		const val = localStorage.getItem("app_applied_for");
		setLoanType(val);
	}, [window.location.pathname]);

	useEffect(() => {
		if (loanType === "Medical Loan") {
			register("bill");
		}
		if (loanType === "Business Loan" && !companyTypeOptions.length) {
			getCompanyTypeOptions();
		}
	}, [loanType]);
	useEffect(() => {
		register("designation");
	}, []);
	return (
		<>
			{loanType !== "Hospi Shield" ? (
				<>
					{!vehicleOp && loanType === "Four Wheeler" ? <h4>Loan Details</h4> : ""}
					<div className="row mb-3">
						{/* {loanType === 'Vehicle Loan' && !vehicleType ? (*/}
						{/*    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
						{/*        <div className="mb-3">*/}
						{/*            <label className="form-label mb-0" htmlFor="inputGroupSelect01">Choose*/}
						{/*                Vehicle</label>*/}
						{/*            <select className="form-control" name="vehicle_type" id="inputGroupSelect01" {...register("vehicle_type", {required: 'This is required.',})} onChange={(e) => setVehicleOption(e.target.value)}>*/}
						{/*                <option value="">Choose</option>*/}
						{/*                <option value="Two Wheeler">Two Wheeler</option>*/}
						{/*                <option value="Four Wheeler">Four Wheeler</option>*/}
						{/*                <option value="E-vehicle">E-Vehicle</option>*/}
						{/*            </select>*/}
						{/*            <ErrorMessage*/}
						{/*                errors={errors}*/}
						{/*                name="vehicle_type"*/}
						{/*                render={({message}) => <p*/}
						{/*                    style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
						{/*            />*/}
						{/*        </div>*/}
						{/*    </div>*/}
						{/* ) : ""}*/}
						{loanType === "Four Wheeler" ? (
							<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
								<div className="mb-3">
									<label
										className="form-label mb-0"
										htmlFor="inputGroupSelectvehicle_option"
									>
										Vehicle Type
									</label>
									{/* {vehicleOp ? (*/}
									{/*    <select className="form-control" name="vehicle_option" value={vehicleOp ? vehicleOp : ''} defaultValue={vehicleOp ? vehicleOp : ''} readOnly={true}*/}
									{/*            id="inputGroupSelectvehicle_option" {...register("vehicle_option", {required: 'This is required.',})}>*/}
									{/*        <option value="">Choose</option>*/}
									{/*        <option value="New Car">New Car</option>*/}
									{/*        <option value="Used Car">Used Car</option>*/}
									{/*        <option value="Loan Against Car">Loan Against Car</option>*/}
									{/*    </select>*/}
									{/* ) : (*/}
									<select
										className="form-control"
										name="vehicle_option"
										id="inputGroupSelectvehicle_option"
										{...register("vehicle_option", {
											required: "This is required."
										})}
									>
										<option value="">Choose</option>
										<option value="New Car">New Car</option>
										<option value="Used Car">Used Car</option>
										<option value="Loan Against Car">Loan Against Car</option>
									</select>
									{/* )}*/}
									<ErrorMessage
										errors={errors}
										name="vehicle_option"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						) : (
							""
						)}
						{/* {loanType === 'E-vehicle' ? (*/}
						{/*    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
						{/*        <div className="mb-3">*/}
						{/*            <label className="form-label mb-0" htmlFor="inputGroupSelectvehicle_option">Vehicle*/}
						{/*                Type</label>*/}
						{/*            <select className="form-control" name="vehicle_option"*/}
						{/*                    id="inputGroupSelectvehicle_option" {...register("vehicle_option", {required: 'This is required.',})}>*/}
						{/*                <option value="">Choose</option>*/}
						{/*                <option value="E-Rickshaw">E-Rickshaw</option>*/}
						{/*                <option value="Car">Car</option>*/}
						{/*                <option value="Two Wheeler">Two Wheeler</option>*/}
						{/*            </select>*/}
						{/*            <ErrorMessage*/}
						{/*                errors={errors}*/}
						{/*                name="vehicle_option"*/}
						{/*                render={({message}) => <p*/}
						{/*                    style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
						{/*            />*/}
						{/*        </div>*/}
						{/*    </div>*/}
						{/* ) : ""}*/}
					</div>
				</>
			) : (
				""
			)}
			{/* {loanType === 'Vehicle Loan' ? (*/}
			<>
				<h4>Other Details</h4>
				<div className="row mb-3">
					{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
					{/*    <div className="mb-3">*/}
					{/*        <label className="form-label mb-0" htmlFor="Occupation">Occupation</label>*/}
					{/*        <input id="Occupation" placeholder="Occupation"*/}
					{/*               className="form-control" {...register("occupation", {required: 'This is required.',})}/>*/}
					{/*        <ErrorMessage*/}
					{/*            errors={errors}*/}
					{/*            name="occupation"*/}
					{/*            render={({message}) => <p style={{color: 'red', fontSize: '13px'}}>{message}</p>}*/}
					{/*        />*/}
					{/*    </div>*/}
					{/* </div>*/}
					{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
					{/*    <div className="mb-3">*/}
					{/*        <label className="form-label mb-0" htmlFor="company_name">Name of Company</label>*/}
					{/*        <input id="company_name" placeholder="Company Name"*/}
					{/*               className="form-control" {...register("company_name")}/>*/}
					{/*    </div>*/}
					{/* </div>*/}
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="annual_income">
								Annual Income
							</label>
							<input
								type="number"
								placeholder="Annual Income"
								className="form-control"
								{...register("annual_income", {
									required: "This is required.",
									minLength: {
										value: 3,
										message: "Annual Income is to Less"
									},
									maxLength: {
										value: 8,
										message: "Annual Income is to High"
									},
									pattern: {
										value: phoneRegExp,
										message: "Annual Income is Invalid"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="annual_income"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
				</div>
			</>
			{/* ) : ""}*/}
			{loanType === "Home Loan" || loanType === "Loan Against Property" ? (
				<div className="row mb-3">
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="loan_purpose12">
								Purpose of Loan
							</label>
							<select
								className="form-control"
								name="loan_purpose"
								id="loan_purpose12"
								{...register("loan_purpose", { required: "This is required." })}
							>
								<option value="">Choose</option>
								<option value="Builder Purchase">Builder Purchase</option>
								<option value="Resale">Resale</option>
								<option value="Plot Purchase">Plot Purchase</option>
								<option value="Plot Purchase + Construction">
									Plot Purchase + Construction
								</option>
								<option value="Self Construction">Self Construction</option>
							</select>
							<ErrorMessage
								errors={errors}
								name="loan_purpose"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="annual_income">
								Annual Income
							</label>
							<input
								type="number"
								placeholder="Annual Income"
								className="form-control"
								{...register("annual_income", {
									required: "This is required.",
									minLength: {
										value: 3,
										message: "Annual Income is to Less"
									},
									maxLength: {
										value: 8,
										message: "Annual Income is to High"
									},
									pattern: {
										value: phoneRegExp,
										message: "Annual Income is Invalid"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="annual_income"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
				</div>
			) : (
				""
			)}
			{loanType === "Micro Loan" ? (
				<div className="row mb-3">
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="loan_purpose23">
								Loan Purpose
							</label>
							<input
								id="loan_purpose23"
								placeholder="Loan Purpose"
								className="form-control"
								{...register("loan_purpose", { required: "This is required." })}
							/>
							<ErrorMessage
								errors={errors}
								name="loan_purpose"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
				</div>
			) : (
				""
			)}
			{loanType === "Personal Loan" ? (
				<>
					<h4>Other Details</h4>
					<div className="row mb-3">
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="Occupation">
									Occupation
								</label>
								<input
									id="Occupation"
									placeholder="Occupation"
									className="form-control"
									{...register("occupation", { required: "This is required." })}
								/>
								<ErrorMessage
									errors={errors}
									name="occupation"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="company_name">
									Name of Company
								</label>
								<input
									id="company_name"
									placeholder="Company Name"
									className="form-control"
									{...register("company_name")}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="designation">
									Designation
								</label>
								<AutoComplete
									setFieldValue={setValue}
									link={"utilities/designations?keyword="}
									name={"designation"}
									method={"GET"}
									placeholder={"Designation"}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="annual_income">
									Annual Income
								</label>
								<input
									type="number"
									placeholder="Annual Income"
									className="form-control"
									{...register("annual_income", {
										required: "This is required.",
										minLength: {
											value: 3,
											message: "Annual Income is to Less"
										},
										maxLength: {
											value: 8,
											message: "Annual Income is to High"
										},
										pattern: {
											value: phoneRegExp,
											message: "Annual Income is Invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="annual_income"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				""
			)}
			{loanType === "Business Loan" ? (
				<>
					<h4>Company Details</h4>
					<div className="row mb-3">
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="Occupation">
									Name of Company
								</label>
								<input
									id="company_name"
									placeholder="Name of Company"
									className="form-control"
									{...register("company_name", { required: "This is required." })}
								/>
								<ErrorMessage
									errors={errors}
									name="company_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="loanType">
									Type of Company
								</label>
								<select
									id={"company_type"}
									className="form-control"
									{...register("company_type", {
										required: "This is required."
									})}
								>
									<option value="">Choose</option>
									{companyTypeOptions.length
										? companyTypeOptions.map((value, index) => {
											return (
												<option
													key={`organizationTypesPartial${index}`}
													value={value.organization_type_enc_id}
												>
													{value.organization_type}
												</option>
											);
										  })
										: ""}
								</select>
								<ErrorMessage
									errors={errors}
									name="company_type"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="business_nature">
									Nature of Business
								</label>
								<input
									id="business_nature"
									placeholder="Nature of Business"
									className="form-control"
									{...register("business_nature")}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="loan_amt">
									Annual Turnover
								</label>
								<input
									type="number"
									min={1}
									placeholder="Annual Turnover"
									className="form-control"
									{...register("annual_turnover", {
										required: "This is required.",
										minLength: {
											value: 4,
											message: "Loan Amount is to Less"
										},
										maxLength: {
											value: 12,
											message: "Loan Amount is to High"
										},
										pattern: {
											value: phoneRegExp,
											message: "Loan Amount is invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="annual_turnover"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0" htmlFor="company_name">
									Designation
								</label>
								<AutoComplete
									setFieldValue={setValue}
									link={"utilities/designations?keyword="}
									name={"designation"}
									method={"GET"}
									placeholder={"Designation"}
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label mb-0 d-block">Annual Income</label>
								<div className="form-check form-check-inline">
									<input
										id="business_premisesOwned"
										className="form-check-input"
										{...register("business_premises", { required: true })}
										type="radio"
										value="Owned"
										defaultChecked={true}
									/>
									<label
										className="form-check-label"
										htmlFor="business_premisesOwned"
									>
										Owned
									</label>
								</div>
								<div className="form-check form-check-inline">
									<input
										id="business_premisesRented"
										className="form-check-input"
										{...register("business_premises", { required: true })}
										type="radio"
										value="Rented"
									/>
									<label
										className="form-check-label"
										htmlFor="business_premisesRented"
									>
										Rented
									</label>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				""
			)}
		</>
	);
};
export default LoanDetailsPartial;
