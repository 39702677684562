import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { useContext, useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const ReportIssueModal = ({selectedRow, onHide, reload, issueType}) => {

	const { register, handleSubmit, reset, formState: { errors } } = useForm();
	const { handlePageLoaded } = useContext(MainContext);
	const [documentImage, setDocumentImage] = useState({ image: "", name: "" });

	const onSubmit = values => {
		const formData = new FormData();
		formData.append("document", documentImage.image);
		formData.append("loan_account_enc_id", selectedRow.loan_account_enc_id);
		formData.append("reasons", issueType);
		formData.append("remarks", values.remarks);
		
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/emi-payment-issues`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					reset();
					toast.success("Saved successfully");
					onHide();
					reload(selectedRow.loan_account_enc_id);
				}
			})
			.catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};
	
	useEffect(() => {
		if (selectedRow) {
		  reset(); // Reset the form when the modal is opened
		  setDocumentImage({ image: "", name: "" });
		}
	  }, [selectedRow, reset]);
	
	return(
		<>
			<Modal
				size={"md"}
				show={selectedRow && selectedRow.loan_account_enc_id} 
				onHide={onHide}
			>
				<Modal.Header>
					<Modal.Title>
						Select Report Issue
					</Modal.Title><span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<>
							{/* <div className="col-12 mb-3 d-flex flex-column">
								<label className="form-label mb-0" htmlFor="first_name">
									Select Issue <span style={{ color: "red", fontSize: "13px" }}>*</span>
								</label>
								<div className="radio-wrapper simple">
									<div className="radio-item">
										<input
											type="radio"
											name="reasons"
											className="radioInput"
											id="Legal"
											value="1"
											{...register("reasons", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="Legal"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px",
												marginLeft: "0"
											}}
										>
											<span>Legal</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="reasons"
											className="radioInput"
											id="Accident"
											value="2"
											{...register("reasons", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="Accident"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<span>Accident</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="reasons"
											className="radioInput"
											id="Health"
											value="3"
											{...register("reasons", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="Health"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<span>Health Issues</span>
										</label>
									</div>
								</div>
							</div> */}
							<div className="col-12 mb-3">
								<label className="form-label mb-0" htmlFor="first_name">
									Enter Reason <span style={{ color: "red", fontSize: "13px" }}>*</span>
								</label>
								<textarea
									className="form-control"
									id="reasons_description"
									rows="4"
									{...register("remarks", {
										required: "This is required."
									})}
									placeholder="Type Here..."
								></textarea>
								<ErrorMessage
									errors={errors}
									name="remarks"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							
							<div>
								<label className="form-label">Upload Image If Any</label>
								<div
									className="d-flex flex-column align-items-start">
									<label
										className="form-control w-auto text-center"
										style={{
											backgroundColor: "#00a0e3",
											color: "#fff",
											cursor: "pointer"
										}}
										htmlFor="issueImage"
									>
										<FaUpload /> Upload
									</label>
									<input
										id="issueImage"
										placeholder="Upload Document"
										className="form-control d-none"
										{...register("documentImage")}
										type="file"
										onChange={e => {
											if (e.target.files.length) {
												setDocumentImage({
													image: e.target.files[0],
													name: e.target.files[0].name
												});
											}
										}}
									/>
									{documentImage.name ? (
										<p
											style={{
												marginBottom: "0px",
												marginTop: "10px",
												fontSize: "14px",
												color: "#000"
											}}
										>
											{documentImage.name}
										</p>
									) : (
										""
									)}
								</div>
							</div>
							<hr/>
							<div className="text-center">
								<button type="submit" className="btn btn-primary">
									Save{" "}
								</button>
							</div>
						</>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ReportIssueModal;