import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Login from "../Login";
import { MainContext } from "../../context/MainState";
import { toast } from "react-toastify";

const SharingIdCard = () => {
	const [userData, setUserData] = useState("");
	const params = useParams();
	const history = useNavigate();
	const { user, modalLogin,handleModalLogin, } = useContext(MainContext);
	const handleClose = () => handleModalLogin(false);
	const handleShow = () => handleModalLogin(true);
	const signShow = () => {
		handleModalLogin(false);
	};

	const getEmployeeData = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/get-employee-details`,
				params
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setUserData(response.data.response.data);
				} else if (response.data.response.status === 404) {
					history("/not-found");
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	useEffect(() => {
		getEmployeeData();
	}, []);
	return (
		<div className="container">
			<div className="idCard-main row">
				<div className="idCard idCardAlt">
					<div className="id-details">
						<div className="cmp-logo">
							<img
								src="/images/pages/dashboard/phfleasing-logo.png"
								alt="Phf-leasing"
							/>
						</div>
						<div className="emp-details"></div>
						<div className="emo-img emo-img-alt">
							<img src={userData.employee_image} alt={userData.employee_image} />
						</div>

						<div className="emp-name">
							<h3 className="text-uppercase">{userData.name}</h3>
							<p className="mb-2">{userData.designation}</p>
						</div>

						<div
							className={`${
								userData.status === "Active" ? "bg-success" : "bg-warning"
							} text-white px-2 rounded-50 fs-6 fw-medium`}
						>
							{userData.status}
						</div>

						<hr></hr>

						<div className="empDetls card-other-details">
							<div className="emp-details">
								<p>Employee Code :</p>
								<p className="emp-subDetails">{userData.employee_code}</p>
							</div>
							{/* <div className="emp-details">
								<p>Joining Date:</p>
								<p className="emp-subDetails">
									{userData.employee_joining_date
										? moment(userData.employee_joining_date).format(
											"DD MMM YYYY"
										  )
										: "-"}
								</p>
							</div> */}
							<div className="emp-details">
								<p>Branch :</p>
								<p className="emp-subDetails">{userData.location_name}</p>
							</div>
							{user && user.user_type ? (
								<>
									{userData.email ? (
										<div className="emp-details">
											<p>Email :</p>
											<p className="emp-subDetails">{userData.email}</p>
										</div>
									) : (
										""
									)}

									<div className="emp-details">
										<p>Phone :</p>
										<p className="emp-subDetails">{userData.phone}</p>
									</div>
								</>
							) : (
								<>
									<div className="login-b text-center w-100 mt-1">
										<p className="fs-6 fw-medium m-0 text-secondary">
											Login to see full details
										</p>
										<button
											className="btn btn-secondary btn-xs shadow-none"
											onClick={handleShow}
										>
											Login
										</button>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="sharing-card-borders"></div>
				</div>
			</div>
			<Login
				withModal={true}
				show={modalLogin}
				onHide={handleClose}
				signShow={signShow}
				redirect={false}
				handleShow={handleShow}
			/>
		</div>
	);
};
export default SharingIdCard;
