import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

const TargetPriorityDates = ({reload, loanId, user, handlePageLoaded}) => {
	const [targetDates, setTargetDates] = useState({sales_target_date: null, telecaller_target_date: null, collection_target_date: null});

	const updateTargetDates = (date, type) =>{
		handlePageLoaded(true);
		let data = {};
		data["loan_account_enc_id"] = loanId.loan_account_enc_id;
		data[type] = moment(date).format("YYYY-MM-DD");
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-target-dates`, data).then(
			response => {
				handlePageLoaded(false);
				if(response.data.response.status === 200){
					toast.success(`${type.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())} updated successfully`);
					setTargetDates({sales_target_date: null, telecaller_target_date: null, collection_target_date: null});
					reload(moment(date).format("YYYY-MM-DD"), type);
				}else{	
					console.log(response.data.response);
					toast.error("An error has occured while updating target dates, Please try again");
				}
			}
		).catch((error) => {
			handlePageLoaded(false);
			toast.error(error.message);
		});
	};

	useEffect(() => {
		setTargetDates({
			sales_target_date: loanId.sales_target_date ? new Date(loanId.sales_target_date) : null,
			telecaller_target_date: loanId.telecaller_target_date ? new Date(loanId.telecaller_target_date) : null, 
			collection_target_date: loanId.collection_target_date ? new Date(loanId.collection_target_date) : null, 
		});
	}, [loanId]);

	return(
		<>
			<div className="col-md-6">
				<p className="d-flex flex-wrap align-items-center ht-48 m-0">
					<span className="me-1">
                        Sales Target Date:
					</span>
					<div className="w-auto">
						{user.user_type === "Financer" ? (
							<DatePicker
								showIcon
								selected={targetDates.sales_target_date}
								onChange={date => {
									updateTargetDates(date, "sales_target_date"); 
									setTargetDates({...targetDates, sales_target_date: date});}
								}
								showMonthDropdown
								showYearDropdown
								className={"form-control date-priority-height"}
								minDate={new Date()}
								dropdownMode="select"
								isClearable={true}
								dateFormat="dd MMM yyyy"
							/>
						) : 
							<span className="fw-medium text-gray-700 mx-2 text-capitalize">{loanId.sales_target_date ? moment(loanId.sales_target_date).format("DD MMM yyyy") : "-"}</span>
						}
					</div>
				</p>
			</div>
			<div className="col-md-6">
				<p className="d-flex flex-wrap align-items-center ht-48 m-0">
					<span className="me-1">
                        Telecaller Target Date: 
					</span>
					<div className="w-auto">
						{user.user_type === "Financer" ? (
							<DatePicker
								showIcon
								selected={targetDates.telecaller_target_date}
								onChange={date => {
									updateTargetDates(date, "telecaller_target_date"); 
									setTargetDates({...targetDates, telecaller_target_date: date});}
								}
								showMonthDropdown
								showYearDropdown
								className={"form-control date-priority-height"}
								minDate={new Date()}
								dropdownMode="select"
								isClearable={true}
								dateFormat="dd MMM yyyy"
							/>
						) : 
							<span className="fw-medium text-gray-700 mx-2 text-capitalize">{loanId.telecaller_target_date ? moment(loanId.telecaller_target_date).format("DD MMM yyyy") : "-"}</span>
						}
					</div>
				</p>
			</div>
			<div className="col-md-6">
				<p className="d-flex flex-wrap align-items-center ht-48 m-0">
					<span className="me-1">
                        Collection Target Date: 
					</span>
					<div className="w-auto date-priority">
						{user.user_type === "Financer" ? (
							<DatePicker
								showIcon
								selected={targetDates.collection_target_date}
								onChange={date => {
									updateTargetDates(date, "collection_target_date"); 
									setTargetDates({...targetDates, collection_target_date: date});}
								}
								showMonthDropdown
								showYearDropdown
								className={"form-control date-priority-height"}
								minDate={new Date()}
								dropdownMode="select"
								isClearable={true}
								dateFormat="dd MMM yyyy"
							/>
						) : 
							<span className="fw-medium text-gray-700 mx-2 text-capitalize">{loanId.collection_target_date ? moment(loanId.collection_target_date).format("DD MMM yyyy") : "-"}</span>
						}
					</div>
				</p>
			</div>
		</>
	);
};

export default TargetPriorityDates;