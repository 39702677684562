import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";

const UsersResetPassword = ({ passwordModal, setPasswordModal }) => {
	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors }
	} = useForm();
	const { handlePageLoaded } = useContext(MainContext);

	const newpassword = watch("new_password");
	const onSubmit = async data => {
		data.user_enc_id = passwordModal.id;
		handlePageLoaded(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/reset-password`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Password changed successfully");
					setPasswordModal("");
					reset();
				} else {
					toast.error(
						response.data.response.message
							? response.data.response.message.toString()
							: "An Error occurred"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occurred: " + error.message);
			});
	};

	const resetForm = () => {
		setPasswordModal("");
		reset();
	};
	return (
		<>
			<Modal show={passwordModal} onHide={() => resetForm()} size={"md"} centered>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					<div className="reset-password-container p-5">
						<h3 className="mb10">Reset Password</h3>
						<div className="login-username mb-3">
							<input
								placeholder="Password"
								type="password"
								className="form-control"
								{...register("new_password", {
									required: "This is required.",
									minLength: {
										value: 8,
										message: "Password must have at least 8 characters"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="new_password"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<div className="login-username mb-3">
							<input
								placeholder="Confirm Password"
								type="password"
								className="form-control"
								{...register("confirm_password", {
									required: "This is required.",
									validate: value =>
										value === newpassword || "The Password does not match"
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="confirm_password"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<div className="reset-pass-btn text-center">
							<button type="submit" className="btn btn-primary btn-md">
								Save
							</button>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};
export default UsersResetPassword;
