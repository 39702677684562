import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import NameComp from "../NameComp";
import { OrganizationOperationsContext } from "../../context/OrganizationOperations";
import { BiRupee } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";
import { MainContext } from "../../context/MainState";
import MinMaxFilter from "../MinMaxFilter";

const EmiPaymentLinksTable = () => {
	const { getEmiPaymentLinks, emiPaymentLinks, isLoading } = useContext(
		OrganizationOperationsContext
	);
	const {loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		product_id: "",
		loan_type: "",
		field: "",
		order_by: ""
	});
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "1",
		label: "This Month"
	});

	const bucketList = [
		{
			label: "On Time",
			value: "onTime"
		},
		{
			label: "0-30",
			value: "sma-0"
		},
		{
			label: "30-60",
			value: "sma-1"
		},
		{
			label: "60-90",
			value: "sma-2"
		},
		{
			label: "90+",
			value: "npa"
		}
	];

	const statusColorObj = {
		captured: "success",
		pending: "warning",
		rejected: "danger",
		failed: "danger"
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Customer Name"}
					id={"customer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.name ? row.name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Bucket"}
					id={"sub_bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					bucketList={bucketList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.sub_bucket ? row.sub_bucket === "0" ? "X" : row.sub_bucket : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Phone"}
					id={"phone"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.phone ? row.phone : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<a href={row.phone}>
							<FaPhoneAlt /> {row.phone}
						</a>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "160px"
		},
		{
			name: (
				<MinMaxFilter
					title={"Collection Date"}
					id={"collection_date_range"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			sortable: false,
			cell: row => {
				return (
					<>
						<h6>
							{row.collection_date
								? moment(row.collection_date).format(" DD MMM YYYY")
								: "-"}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Transaction Initiated Date"}
					id={"transaction"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			sortable: false,
			cell: row => {
				return (
					<>
						<h6>
							{row.transaction_initiated_date
								? moment(row.transaction_initiated_date).format(" DD MMM YYYY ")
								: "-"}
						</h6>
					</>
				);
			}
		},
		{
			name: "Payment Amount",
			selector: row => (row.payment_link ? row.payment_link : "-"),
			sortable: false,
			minWidth: "160px",
			cell: row => {
				return (
					<h6 className="mb-0 emiAmt cursor-point">
						<BiRupee size={16} />{" "}
						{row.payment_amount
							? parseFloat(row.payment_amount).toLocaleString("en-IN")
							: "0"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Payment Status"}
					id={"emi_payment_statuses"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "160px",
			cell: row => {
				const paymentStatus =
					row.payment_status && row.payment_status.length ? row.payment_status : "-";
				return paymentStatus === "-" ? (
					paymentStatus
				) : (
					<div
						className={`text-capitalize mx-2 badge rounded-pill bg-${statusColorObj[paymentStatus]}`}
					>
						{paymentStatus === "captured" ? "Paid" : paymentStatus}
					</div>
				);
			}
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getEmiPaymentLinks({
			page: page.selected + 1,
			limit: rowsPerPage,
			start_date: filters.start_date,
			end_date: filters.end_date,
			fields_search: searchArray,
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getEmiPaymentLinks({
			page: 1,
			limit: parseInt(e.target.value),
			start_date: filters.start_date,
			end_date: filters.end_date,
			fields_search: searchArray,
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(emiPaymentLinks.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (emiPaymentLinks && emiPaymentLinks.data && emiPaymentLinks.data.length > 0) {
			return emiPaymentLinks.data;
		} else if (emiPaymentLinks && emiPaymentLinks.data && emiPaymentLinks.data.length === 0 && isFiltered) {
			return [];
		} else {
			return emiPaymentLinks && emiPaymentLinks.data && emiPaymentLinks.data.length > 0
				? emiPaymentLinks.data.slice(0, rowsPerPage)
				: [];
		}
	};
	const getCurrentMonthData = () => {
		const tempDate = new Date();
		const fromDate = moment(
			tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-1"
		).format("YYYY-MM-DD");
		const toDate = moment(tempDate).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const getTodayData = () => {
		const formattedToday = moment().format("YYYY-MM-DD");
		setFilters({
			...filters,
			start_date: formattedToday,
			end_date: formattedToday
		});
	};

	const getThisWeekData = () => {
		const today = new Date();
		const dayOfWeek = today.getDay();
		const startOfWeek = new Date(today);

		// Calculate the number of days to subtract to get to Monday
		const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
		startOfWeek.setDate(today.getDate() - daysToSubtract);

		const formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
		const formattedToday = today.toISOString().split("T")[0];

		setFilters({
			...filters,
			start_date: formattedStartOfWeek,
			end_date: formattedToday
		});
	};
	const getPrevMonthData = () => {
		const tempDate = new Date();
		const lastDay = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
		const fromDate = moment(
			(tempDate.getMonth() === 0 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) +
				"-" +
				(tempDate.getMonth() === 0 ? 12 : tempDate.getMonth()) +
				"-1"
		).format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const getThreeMonthData = () => {
		const tempDate = new Date();
		const startingMonth = (tempDate.getMonth() - 3 + 12) % 12;
		const lastDay = new Date(
			tempDate.getMonth() < 3 ? tempDate.getFullYear() - 1 : tempDate.getFullYear(),
			startingMonth + 3,
			0
		);
		const fromDate = moment(
			(tempDate.getMonth() <= 2 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) +
				"-" +
				(startingMonth + 1) +
				"-1"
		).format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const handleSort = async (column, sortDirection) => {
		setFilters({
			...filters,
			field: column.id,
			order_by: sortDirection === "asc" ? 0 : 1
		});
	};

	useEffect(() => {
		if (selectedStatusModel.value === "1") {
			getCurrentMonthData();
		} else if (selectedStatusModel.value === "0") {
			getPrevMonthData();
		} else if (selectedStatusModel.value === "2") {
			getThreeMonthData();
		} else if (selectedStatusModel.value === "3") {
			getTodayData();
		} else if (selectedStatusModel.value === "4") {
			getThisWeekData();
		}
	}, [selectedStatusModel]);

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			getEmiPaymentLinks({
				page: 1,
				limit: rowsPerPage,
				start_date: filters.start_date,
				end_date: filters.end_date,
				fields_search: searchArray,
			});
			setCurrentPage(1);
		}

		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [filters, searchArray]);

	return (
		<div className="loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
			<div className="salesRep px-3">
				<h4 className="mb-0 text-capitalize">EMI Payment Links</h4>
			</div>
			<Row className="mx-0 mt-1 mb-5">
				<Col sm="9">
					<div className="d-flex align-items-center py-2">
						<div className="form-group">
							<label htmlFor="float-text" className="floating-label fw400 ft14">
								Show Results
							</label>
							<select
								className="dataTable-select form-select"
								style={{ width: "95px", padding: "2px 8px", height: "48px" }}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
						</div>
						<div
							style={{
								marginLeft: "15px",
								display: "flex",
								alignItems: "center"
							}}
						>
							<div className="form-group">
								<label htmlFor="float-text" className="floating-label fw400 ft14">
									Range
								</label>
								<Select
									options={[
										{
											value: "1",
											label: "This Month"
										},
										{ value: "3", label: "Today" },
										{ value: "4", label: "This Week" },
										{
											value: "0",
											label: "Last Month"
										},
										{
											value: "2",
											label: "Last Three Months"
										},
										{
											value: "Custom",
											label: "Custom Range"
										}
									]}
									className="customStyleSelect smSelect smOption"
									value={selectedStatusModel}
									name="status"
									isSearchable={false}
									placeholder="Choose Status"
									onChange={option => {
										if (option.value !== selectedStatusModel.value) {
											setSelectedStatusModel(option);
										}
									}}
								/>
							</div>
						</div>
						{selectedStatusModel && selectedStatusModel.value === "Custom" ? (
							<>
								<div className="form-group">
									<label
										htmlFor="float-text"
										className="floating-label fw400 ft14"
									>
										From
									</label>
									<input
										type="date"
										className="form-control"
										style={{
											maxWidth: "150px",
											height: "48px",
											marginRight: "5px"
										}}
										value={filters.start_date}
										onChange={e => {
											setFilters({ ...filters, start_date: e.target.value });
										}}
									/>
								</div>
								<div className="form-group">
									<label
										htmlFor="float-text"
										className="floating-label fw400 ft14"
									>
										To
									</label>
									<input
										type="date"
										className="form-control"
										style={{ maxWidth: "150px", height: "48px" }}
										value={filters.end_date}
										onChange={e => {
											setFilters({ ...filters, end_date: e.target.value });
										}}
									/>
								</div>
							</>
						) : (
							""
						)}
					</div>
				</Col>
				<Col
					className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
					sm="3"
				>
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button
								className="btn btn-primary btn-xs my-2"
								onClick={e => clearFilter(e)}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				pagination
				paginationServer
				columns={columns}
				data={dataToRender()}
				sortServer={true}
				onSort={handleSort}
				paginationComponent={CustomPagination}
				progressPending={isLoading === "employeeStat"}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent={
					<p className="w-100 m-5 text-start">There are no records to display</p>
				}
				persistTableHead={true}
				className="loan-app-table-container fix-overflow"
				fixedHeader
				fixedHeaderScrollHeight="600px"
			/>
			{/* <EmployeeLoanList
				showSidebar={showSidebar}
				handleClose={handleClose}
				userData={selectedRow.value}
				userResponse={userResponse}
				userLoans={userLoans}
				loading={loading}
				setSelectedRow={setSelectedRow}
				selectedRow={selectedRow}
			/> */}
		</div>
	);
};
export default EmiPaymentLinksTable;
