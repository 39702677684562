import { lenders, medicalLoanLimits } from "../../data/lendersData";
import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { CandidateDashboardContext } from "../../context/CandidateDashboardState";

const MedicalLoanForm = () => {
	const {
		register,
		handleSubmit,
		reset,
		setError,
		setValue,
		clearErrors,
		formState: { errors }
	} = useForm();
	const [loanType, setLoanType] = useState("");
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [showContactDetails, setShowContactDetails] = useState(false);
	const { all_applications, getLoanApplications } = useContext(CandidateDashboardContext);
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const getLoanLimit = async loan_id => {
		if (loan_id) {
			let data = { loan_id: loan_id };
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/detail`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						setLoanType(
							response.data.response.detail ? response.data.response.detail : ""
						);
						clearErrors("loan_acc_no");
					} else {
						setError("loan_acc_no", {
							type: "custom",
							message: "Invalid Loan Account Number"
						});
						setValue("loan_acc_no", null);
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	};
	const onSubmit = async data => {
		setFormIsLoading(true);
		data = { ...data, loan_type: "Medical Loan" };
		if (loanType) {
			data = { ...data, applicant_name: loanType.applicant_name, phone_no: loanType.phone };
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/loan-application`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Application Submitted");
					reset();
					setLoanType("");
					setFormIsLoading(false);
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};
	useEffect(() => {
		const url = window.location.pathname.split("/")[1].replace(/-/g, " ");
		if (url !== "account") {
			setShowContactDetails(true);
		}
		getLoanApplications();
	}, []);
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<fieldset>
				<div className="row">
					{!all_applications || all_applications.length === 0 || showContactDetails ? (
						<>
							<div className="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label">Full Name</label>
									<input
										placeholder=""
										className="form-control"
										{...register("applicant_name", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="applicant_name"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12">
								<div className="mb-3">
									<label className="form-label">Mobile Number (Whatsapp)</label>
									<input
										placeholder=""
										className="form-control"
										{...register("phone_no", {
											required: "This is required.",
											minLength: {
												value: 10,
												message:
													"Mobile number Should be at least 10 numbers"
											},
											maxLength: {
												value: 10,
												message:
													"Mobile number maximum be at least 10 numbers"
											},
											pattern: {
												value: phoneRegExp,
												message: "Invalid Phone Number"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="phone_no"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
						</>
					) : (
						""
					)}
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Select Lender</label>
							<div className="col-12 disFlex flex-wrap">
								{lenders.allLenders
									? lenders.allLenders.map((value, index) => {
										if (value.access.includes("medical")) {
											return (
												<div key={`lender-${index}`}>
													<input
														className="checkbox-tools"
														type="radio"
														name="tools"
														id={`tool-${value.id}`}
														{...register("loan_lender", {
															required: "This is required."
														})}
														value={value.slug}
													/>
													<label
														className="for-checkbox-tools"
														htmlFor={`tool-${value.id}`}
													>
														<img
															src={value.logo}
															alt={value.name}
														/>
														<p>{value.name}</p>
													</label>
												</div>
											);
										}
									  })
									: ""}
							</div>
							<ErrorMessage
								errors={errors}
								name="loan_lender"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Enter PAN Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("pan_number", {
									required: "This is required.",
									pattern: {
										value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
										message: "Invalid PAN Number"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="pan_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>

					{all_applications && all_applications.length && !showContactDetails ? (
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label">Enter Loan Account Number</label>
								<input
									placeholder=""
									className="form-control"
									{...register("loan_acc_no", {
										required: "This is required."
									})}
									onBlur={e => {
										getLoanLimit(e.target.value);
									}}
								/>
								<ErrorMessage
									errors={errors}
									name="loan_acc_no"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
					) : (
						""
					)}
					<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label className="form-label">Enter Aadhar Number</label>
							<input
								placeholder=""
								className="form-control"
								{...register("aadhar_number", {
									required: "This is required.",
									pattern: {
										value: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
										message: "Invalid Aadhar Number"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="aadhar_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					{!all_applications || all_applications.length === 0 || showContactDetails ? (
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
							<div className="mb-3">
								<label className="form-label" htmlFor="loan_amt">
									Loan Amount
								</label>
								<input
									type="number"
									min={1}
									className="form-control"
									{...register("loan_amount", {
										required: "This is required.",
										minLength: {
											value: 4,
											message: "Loan Amount is to Less"
										},
										maxLength: {
											value: 8,
											message: "Loan Amount is to High"
										},
										pattern: {
											value: /^[1-9]\d*$/,
											message: "Loan Amount is invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="loan_amount"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
					) : (
						""
					)}

					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						{loanType ? (
							<div className="mb-3">
								<p className="form-label">Choose Loan Limit</p>
								<div className="radio-wrapper">
									{medicalLoanLimits.limits
										? medicalLoanLimits.limits.map((value, index) => {
											if (value.access.includes(loanType.loan_type)) {
												return (
													<div
														className="radio-item"
														key={`limit-${index}`}
													>
														<input
															type="radio"
															value={value.amount}
															className="radioInput"
															name="loan_amount"
															id={`limit-${index}`}
															{...register("loan_amount", {
																required: "This is required."
															})}
														/>
														<label
															htmlFor={`limit-${index}`}
															className="option option-1"
														>
															<div className="dot"></div>
															<span>{value.amount}</span>
														</label>
													</div>
												);
											}
										  })
										: ""}
								</div>
								<ErrorMessage
									errors={errors}
									name="loan_amount"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						) : (
							""
						)}
					</div>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 text-center">
						{formIsLoading ? (
							<button id="Submit" disabled={true} className="btn btn-primary ">
								<img src="/images/pages/home/loader.svg" alt="Form Loading" />
							</button>
						) : (
							<button id="Submit" name="Submit" className="btn btn-primary ">
								Submit
							</button>
						)}
					</div>
				</div>
			</fieldset>
		</form>
	);
};

export default MedicalLoanForm;
