import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { IoCloseCircle} from "react-icons/io5";
import { toast } from "react-toastify";

const AssignTelecallerModal = ({ show, hide, callerList, bucket }) => {
	const [selectedCallers, setSelectedCallers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const assignCallers = () => {
		setIsLoading(true);
		toast.warning("Your Request to assign Telecallers is under processing, Please wait few minutes to complete");
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/assign-telecaller`,
				{
					caller_ids: selectedCallers,
					bucket: bucket
				}
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					setSelectedCallers([]);
				}
			});
		hide();
	};

	return (
		<>
			<Modal show={show} onHide={hide}>
				<Modal.Header>
					<Modal.Title>Assign Telecaller</Modal.Title><span className="close-icon" onClick={hide}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					{callerList && callerList.length ? (
						<>
							<Typeahead
								id="select-telecaller"
								labelKey="name"
								multiple
								onChange={setSelectedCallers}
								options={callerList}
								placeholder="Select Telecallers"
								selected={selectedCallers}
							/>
							<div className="mt-3 text-center">
								{isLoading ? (
									<button
										type="button"
										className="btn btn-primary"
										disabled={true}
									>
										<img
											src="/images/pages/home/loader.svg"
											alt={process.env.REACT_APP_NAME}
										/>
									</button>
								) : (
									<button
										type="button"
										className="btn btn-primary btn-sm"
										onClick={() => assignCallers()}
									>
										Assign
									</button>
								)}
							</div>
						</>
					) : (
						""
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AssignTelecallerModal;
