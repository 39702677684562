import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { readOnlyUsers } from "../../helpers/authServices";
import { BsHouseGear } from "react-icons/bs";

const InitiateTechnical = ({ loanDetails, reload, collectedAmount, feeStructures }) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const [technicalStatus, setTechnicalStatus] = useState({ id: "", status: "" });

	const InitiateTechnical = status => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm("Are you sure you want to change the status?")) {
			return false;
		}
		handlePageLoaded(true);
		let data = {
			loan_app_enc_id: loanDetails.loan_app_enc_id,
			status: status
		};
		if (technicalStatus.id) {
			data["loan_application_technical_enc_id"] = technicalStatus.id;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-technical`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success(`TVR ${status === 0 ? "Initiated" : "Completed"} Successfully`);
					reload(loanDetails.loan_app_enc_id);
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "TVR Initiated Error"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};
	useEffect(() => {
		if (
			loanDetails &&
			loanDetails.loanApplicationTechnicals &&
			loanDetails.loanApplicationTechnicals.length
		) {
			const tempStatus = loanDetails.loanApplicationTechnicals;
			if (tempStatus.length) {
				setTechnicalStatus({
					id: tempStatus[0].loan_application_technical_enc_id,
					status: tempStatus[0].status
				});
			}
		}
	}, [loanDetails]);
	return (
		<>
			{(user.department === "Credit" || user.user_type === "Financer") && (collectedAmount >= 2000 || !feeStructures.length) ? (
				<>
					{technicalStatus.status === "1" ? (
						<button type="button" className="btn btn-success">
							<BsHouseGear /> Technical Completed
						</button>
					) : (
						<>
							{technicalStatus.status === "0" ? (
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => InitiateTechnical(1)}
								>
									<BsHouseGear /> Technical Initiated
								</button>
							) : (
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => InitiateTechnical(0)}
								>
									<BsHouseGear /> Initiate Technical
								</button>
							)}
						</>
					)}
				</>
			) : (
				<button type="button" className="btn btn-primary" disabled={true}>
					<BsHouseGear /> Initiate Technical
				</button>
			)}
		</>
	);
};

export default InitiateTechnical;
