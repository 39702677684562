import axios from "axios";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const UpdateBorrowerStatus = ({ updateBorrower, onHide, openSideBar, loanDetails }) => {
	const {
		register,
		handleSubmit,
	} = useForm();

	const onSubmit = values => {
		const data = {
			...values,
			borrower_type: "Borrower",
			loan_co_app_enc_id: updateBorrower.currentId,
			old_loan_co_app_enc_id: updateBorrower.id
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/set-borrower`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Borrower Type Updated Successfully");
					openSideBar(loanDetails.loan_app_enc_id);
					return false;
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "An error has occurred, Please try again"
					);
				}
			})
			.catch(error =>{
				toast.error(error.message);
			});
	};

	return (
		<>
			<Modal size={"lg"} show={updateBorrower && updateBorrower.id} onHide={onHide}>
				<Modal.Header>
					<Modal.Title>
					Select Current Borrower Type
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="mb-4">
									<h3>{updateBorrower.name}</h3>
								</div>
								<div className="mb-4">
									<label className="form-label d-block">Select Borrower Type </label>
									<div className="radio-wrapper hero-radio-wrapper">
										<div className="radio-item">
											<input
												type="radio"
												name="select"
												className="radioInput"
												id="option-2"
												value="Co-Borrower"
												{...register("old_borrower_type", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="option-2"
												className="option option-2"
											>
												<div className="dot"></div>
												<span>Co-Borrower</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="radio"
												name="select"
												className="radioInput"
												id="option-6"
												value="Guarantor"
												{...register("old_borrower_type", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="option-6"
												className="option option-6"
											>
												<div className="dot"></div>
												<span>Guarantor</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="radio"
												name="select"
												className="radioInput"
												id="option-7"
												value="Others"
												{...register("old_borrower_type", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="option-7"
												className="option option-7"
											>
												<div className="dot"></div>
												<span>Other</span>
											</label>
										</div>
									</div>
								</div>
								<hr/>
								<div className="mt-4 text-end">
									<button className="btn btn-primary">
										Save
									</button>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default UpdateBorrowerStatus;