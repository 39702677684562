import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../context/MainState";
import { useForm } from "react-hook-form";
import { BsCameraFill } from "react-icons/bs";
import Select from "react-select";
import DataTable from "react-data-table-component";
import BrandsListDashboard from "./dashboard/BrandsListDashboard";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";

const VehicleBrandsSettings = ({ tabRender }) => {
	const { register, handleSubmit, reset, setValue } = useForm();
	const { getVehicleBrand, brandsList, getVehicleType, vehicleTypeList } = useContext(
		OrganizationDashboardContext
	);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const { user, handlePageLoaded } = useContext(MainContext);
	const [selectedImage, setSelectedImage] = useState(null);
	const [valueImage, setValueImage] = useState(null);
	const [brandId, setBrandId] = useState("");

	const onSubmit = async values => {
		if (!values.brand_name) {
			toast.error("Please Enter Brand Name");
			return;
		}
		handlePageLoaded(true);
		const formData = new FormData();
		formData.append("organization_enc_id", user.organization_enc_id);
		formData.append("brand_name", values.brand_name);
		formData.append("logo_image", selectedImage);
		const finalData = selectedOptions.map(option => option.value);
		if (brandId !== "") {
			formData.append("financer_vehicle_brand_enc_id", brandId);
		} else {
			brandsList.forEach(function (outerEach) {
				outerEach.assignedVehicleTypes.forEach(function (innerEach) {
					if (innerEach.brand_name === values.brand_name) {
						if (!finalData.includes(outerEach.financer_vehicle_type_enc_id)) {
							finalData.push(outerEach.financer_vehicle_type_enc_id);
						}
					}
				});
			});
		}
		formData.append("vehicle_type", finalData);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/financer-vehicle-brand`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success(`Brand ${brandId ? "Updated" : "Added"} Successfully`);
					setSelectedImage("");
					reset();
					getVehicleBrand();
					setValueImage(null);
					setSelectedOptions([]);
					setBrandId("");
				} else {
					toast.error(
						response.data.response && response.data.response.message
							? response.data.response.message
							: "An Error has occurred"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("An error has occurred:", error);
			});
	};

	const columns = [
		{
			// name: "Vehicle Type",
			cell: row => {
				return <p className="m-0 fs-5">{row.vehicle_type}</p>;
			}
		}
	];

	const removeBrand = async id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		handlePageLoaded(true);
		let data = { assigned_vehicle_types_enc_id: id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/remove-vehicle-type`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					getVehicleBrand();
					toast.warn("Brand Removed Successfully");
				}
			})
			.catch(error => {
				console.error(error);
				handlePageLoaded(false);
				console.error("Error in axios call:", error);
			});
	};

	const selectedTypes = selected => {
		setSelectedOptions(selected);
	};

	const vehicleTypeOptions =
		vehicleTypeList && vehicleTypeList.length
			? vehicleTypeList.map(vehicle => ({
				value: vehicle.financer_vehicle_type_enc_id,
				label: (
					<div>
						<img
							src={vehicle.icon}
							alt="Vehicle Icon"
							className="img-fluid brand-img-label"
						/>
						{vehicle.vehicle_type}
					</div>
				)
			  }))
			: "";

	const brandUpdates = id => {
		const finalData = [];
		brandsList.forEach(function (outerEach) {
			outerEach.assignedVehicleTypes.forEach(function (innerEach) {
				if (innerEach.brand_name === id.name) {
					let vehicleTypeList = [];
					vehicleTypeList["value"] = outerEach.financer_vehicle_type_enc_id;
					vehicleTypeList["label"] = (
						<div>
							<img src={outerEach.icon} alt="" className="brand-img-label" />
							{outerEach.vehicle_type}
						</div>
					);
					finalData.push(vehicleTypeList);
				}
			});
		});
		setSelectedOptions(finalData);
		setValue("brand_name", id.name);
		setValueImage(id.logo);
		setBrandId(id.brand_id);
		setSelectedImage(null);
	};

	const resetFormData = () => {
		reset();
		setSelectedOptions([]);
		setValueImage(null);
		setBrandId("");
	};

	useEffect(() => {
		if (tabRender) {
			getVehicleBrand();
			getVehicleType();
		}
	}, [tabRender]);
	return (
		<>
			<h2>Brands</h2>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-6 mb-4 m-auto">
						<div className="img-preview-user text-center p-2 shadow-ell rounded-2 m-auto position-relative mb-3">
							{selectedImage || valueImage ? (
								<img
									alt="not found"
									width={"100px"}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: valueImage
									}
								/>
							) : (
								""
							)}
							<label
								className={`brand-logos flex-column fs-6 fw-bold form-label edit-image ${
									selectedImage ? "opacity-0" : valueImage ? "opacity-0" : ""
								}`}
								htmlFor="brand_logo"
							>
								<BsCameraFill size={30} className="edit-image-camera" />
								Choose Logo
							</label>
							<input
								className="d-none"
								id="brand_logo"
								type="file"
								accept=".png, .jpg, .jpeg"
								name="brand_logo"
								onChange={e => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
						</div>
						<label className="form-label" htmlFor="org_name">
							{brandId !== "" ? "Update" : ""} Brand Name
						</label>
						<input
							placeholder="Enter Brand Name"
							className="form-control"
							{...register("brand_name")}
						/>
						<label className="form-label mt-4" htmlFor="org_name">
							{brandId !== "" ? "Update" : ""} Vehicle Type
						</label>
						<Select
							className="customStyleSelect smSelect smOption"
							options={vehicleTypeOptions}
							// formatOptionLabel={formatOptionLabel}
							isMulti={true}
							value={selectedOptions}
							onChange={selectedTypes}
							placeholder={"Choose Type"}
						/>
					</div>
					<div className="mb-4 text-center">
						<button type="submit" className="btn btn-primary">
							Save
						</button>
						{brandId !== "" ? (
							<button
								type="button"
								className="btn btn-primary mx-1"
								onClick={() => resetFormData()}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
				</div>
			</form>
			<hr />
			<div className="mt-5">
				<div className="row my-5">
					<DataTable
						header
						columns={columns}
						data={brandsList}
						expandableRows
						expandableRowExpanded={() => true}
						expandableRowsComponent={row => (
							<BrandsListDashboard
								row={row}
								removeBrand={removeBrand}
								brandUpdates={brandUpdates}
							/>
						)}
					/>
				</div>
			</div>
		</>
	);
};

export default VehicleBrandsSettings;
