import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import EmiCollectionTable from "../../components/dashboard/EmiCollectionTable";
import { useContext } from "react";
import { MainContext } from "../../context/MainState";

const DiscrepancyEmiCollection = () => {
	const { user } = useContext(MainContext);
	return (
		<>
			<Helmet>
				<title>Discrepancy EMI Collection</title>
			</Helmet>
			<Navbar />
			<EmiCollectionTable headingName={"Discrepancy EMI's"} type={user.user_type === "Financer" || user.specialAccessRole} cases={"discrepancy"}/>
		</>
	);
};

export default DiscrepancyEmiCollection;
