import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { readOnlyUsers } from "../../helpers/authServices";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const InitiateLoanAccounts = ({ accountId, reloadDetails, type = "", basicDetail, handleCloseSidebar }) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const [status, setStatus] = useState({ foreClose: "", noc: "" });
	const [initiatedModal, setInitiatedModal] = useState(false);
	const [formModal, setFormModal] = useState(false);
	const [chargesModal, setChargesModal] = useState(false);
	const [commentVal, setCommentVal] = useState("");
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();

	useEffect(() => {
		if (basicDetail?.loanAccountActions?.length) {
			const foreclose = basicDetail.loanAccountActions.filter(data => data.type === "1");
			const noc = basicDetail.loanAccountActions.filter(data => data.type === "2");
			const initiateClose = basicDetail.loanAccountActions.filter(data => data.type === "3");
			setStatus({ foreClose: foreclose[0]?.loan_status, noc: noc[0]?.loan_status, initiateClose: initiateClose[0]?.loan_status });
		}
	}, [basicDetail]);

	const onSubmit = async data => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/initiate-status-form`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Foreclose Intiated");
					reset();
				} else {
					toast.error(response.data?.response?.message ? response.data.response.message : "");
				}
			});
	};

	const STATUS_MESSAGES = {
		foreclose: {
			1: "Foreclosure Request Initiated",
			2: "Loan Account Foreclosure Completed",
			3: "Foreclosure Request Rejected",
			default: "Foreclosure Initiated"
		},
		noc: {
			1: "NOC Request Initiated",
			2: "NOC Request Accepted",
			3: "NOC Request Cancelled"
		},
		initiate: {
			1: "Loan Account Closure Request Initiated",
			2: "Loan Account Closure Request Accepted",
			3: "Loan Account Closure Request Cancelled"
		}
	};

	const confirmAction = (message) => window.confirm(message);

	const initiateLoanStatus = (statusVal) => {
		if (!commentVal.trim() && (statusVal === "2" || statusVal === "3")) {
			toast.error("Please write a reason");
			return;
		}
		if (statusVal === "1" && !confirmAction(`Are you sure you want to Initiate ${type === "noc" ? "NOC" : type === "foreclose" ? "Foreclose" : "Loan Account Closure"}?`)) return;
		// if (statusVal === "2" && !confirmAction(`"Are you sure you want Accept ${type === "noc" ? "NOC" : type === "foreclose" ? "Foreclose Request" : "Close Request"}?"`)) return;
		if (readOnlyUsers.includes(user.username)) return;
		handlePageLoaded(true);
		const data = {
			loan_account_enc_id: accountId,
			status: statusVal,
			type: type === "noc" ? 2 : type === "initiate" ? 3 : 1,
			comments: commentVal
		};
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/initiate-status`,
			data)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					handleSuccess(statusVal);
					reloadDetails(statusVal === "2" ? "reload" : "");
				} else {
					toast.error(response.data.response?.message || "Foreclosure Initiated Error");
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occurred: " + error.message);
			});
	};

	const handleSuccess = (statusVal) => {
		const typeMessages = STATUS_MESSAGES[type] || {};
		const message = typeMessages[statusVal] || typeMessages.default || "Action Completed";
	
		toast[statusVal === "3" ? "warning" : "success"](message);
	
		if (type === "noc" && statusVal === "1") {
			setChargesModal(true);
		}
	
		if (statusVal === "2") {
			handleCloseSidebar();
		}
		if (statusVal === "3") {
			setInitiatedModal(false);
			setCommentVal("");
		}
	};

	const saveComment = (e, status) => {
		if (e.keyCode === 13) initiateLoanStatus(status);
	};

	return (
		<>
			{type === "foreclose" ? (
				<>
					{status.foreClose === "2" ? (
						<button type="button" className="btn btn-danger btn-xs mb-0">Foreclosed</button>
					) : status.foreClose === "1" ? (
						<>
							<button type="button" className="btn btn-warning btn-xs mb-0">Foreclose Initiated</button>
							{(user.user_type === "Financer" || user.user_name === "KKB") && (
								<>
									<button type="button" className="btn btn-success btn-xs mb-0 mx-2" onClick={() => user.user_type === "Financer" ? setInitiatedModal("2") : false}>
										<FaCheck />
									</button>
									<button type="button" className="btn btn-danger btn-xs mb-0" title="Reject" onClick={() => setInitiatedModal("3")}>
										<FaTimes />
									</button>
								</>
							)}
						</>
					) : (
						<button type="button" className="btn btn-danger btn-xs mb-0" onClick={() => process.env.REACT_APP_TYPE === "DEV" ? setFormModal("1") : initiateLoanStatus("1")}>Initiate Foreclose</button>
					)}
				</>
			): type === "noc" ? (
				status.noc === "1" ? (
					<>
						<button type="button" className="btn btn-warning btn-xs mb-0" onClick={() => setChargesModal(true)}>Initiated</button>
						{(user.user_type === "Financer" || user.user_name === "KKB") && (
							<>
								<button type="button" className="btn btn-success btn-xs mb-0 mx-2" onClick={() => user.user_type === "Financer" ? setInitiatedModal("2") : false}>
									<FaCheck />
								</button>
								<button type="button" className="btn btn-danger btn-xs mb-0" title="Reject" onClick={() => setInitiatedModal("3")}>
									<FaTimes />
								</button>
							</>
						)}
					</>
				) : (
					<button type="button" className="btn btn-primary btn-xs mb-0" onClick={() => initiateLoanStatus("1")}>Initiate NOC</button>
				)
			): type === "initiate" ? (
				status.initiateClose === "1" ? (
					<>
						<button type="button" className="btn btn-warning btn-xs mb-0">Initiated</button>
						{(user.user_type === "Financer" || user.user_name === "KKB") && (
							<>
								<button type="button" className="btn btn-success btn-xs mb-0 mx-2" onClick={() => user.user_type === "Financer" ? setInitiatedModal("2") : false}>
									<FaCheck />
								</button>
								<button type="button" className="btn btn-danger btn-xs mb-0" title="Reject" onClick={() => setInitiatedModal("3")}>
									<FaTimes />
								</button>
							</>
						)}
					</>
				) : (
					<button type="button" className="btn btn-primary btn-xs mb-0" onClick={() => initiateLoanStatus("1")}>Initiate Closure</button>
				)
			) : ""}

			<Modal show={initiatedModal} onHide={() => {setInitiatedModal(false); setCommentVal("");}} size="md" centered>
				<div className="reject-container p-4">
					<div>
						<label htmlFor="float-text" className="floating-label fw400 ft14">Reason</label>
						<textarea
							className="form-control"
							rows="4"
							onKeyDown={e => saveComment(e, initiatedModal)}
							onChange={e => setCommentVal(e.target.value)}
							placeholder="Add Reason"
						></textarea>
						<button className="btn btn-primary btn-sm mt-2 d-block m-auto" onClick={() => initiateLoanStatus(initiatedModal)}>Save</button>
					</div>
				</div>
			</Modal>

			<Modal show={formModal} onHide={() => setFormModal(false)} size="md" centered>
				<div className="reject-container p-4">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row justify-content-center">
							<h4 className="text-center mb-4">Foreclose Charges Detail</h4>
							<div className="mb-4 col-md-4 text-secondary fw-600">
								<label className="form-label d-block text-black-50 fw-500 mb-0" htmlFor="org_name">
									Foreclose Charges
								</label>
								₹{basicDetail.total_pending_amount ? parseFloat(basicDetail.total_pending_amount).toLocaleString("en-In") : "0"}
							</div>
							<div className="mb-4 col-md-4 text-secondary fw-600">
								<label className="form-label d-block text-black-50 fw-500 mb-0" htmlFor="org_name">
									Collection Charges
								</label>
								₹{basicDetail.total_pending_amount ? parseFloat(basicDetail.total_pending_amount).toLocaleString("en-In") : "0"}
							</div>
							<div className="mb-4 col-md-4 text-secondary fw-600">
								<label className="form-label d-block text-black-50 fw-500 mb-0" htmlFor="org_name">
									Outstanding Amount
								</label>
								₹{basicDetail.total_pending_amount ? parseFloat(basicDetail.total_pending_amount).toLocaleString("en-In") : "0"}
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="org_name">
									Visit Charges
								</label>
								<input
									placeholder="Enter Visit Charges"
									className="form-control"
									{...register("visit_charges", {
										required: "This is Required"
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="visit_charges"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="org_name">
									Interest
								</label>
								<input
									placeholder="Enter Interest"
									className="form-control"
									{...register("interest", {
										required: "This is Required"
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="interest"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
								
							<div className="text-center">
								<button className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
			
			<Modal show={chargesModal} onHide={() => setChargesModal(false)} size="sm" centered>
				<div className="py-4 text-center">
					<h4>Total Pending Amount</h4>
					<div className="fw-bold mb-3 text-secondary d-block">₹{basicDetail.total_pending_amount ? parseFloat(basicDetail.total_pending_amount).toLocaleString("en-In") : "0"}</div>
					<p className="fs-6 m-0 fw-medium"><b>Note:</b> NOC is Initiated please collect all Pending Amount</p>
				</div>
			</Modal>
		</>
	);
};

export default InitiateLoanAccounts;
