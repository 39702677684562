import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authServices } from "../../helpers/authServices";
import { MainContext } from "../../context/MainState";

const Redirect = ({type}) => {
	const searchParams = useParams();
	const { updateUser } = useContext(MainContext);
	const history = useNavigate();
	useEffect(() => {
		setTimeout(() => {
			if (type === "redirect"){
				window.location.replace("https://www.credif.in/auto-login/" + searchParams.token + "/" + searchParams.login);
			} else if (type === "login") {
				authServices.login({token:searchParams.token, login:searchParams.login}, updateUser).then(data => {
					if (data?.status === 200) {
						history("/account/dashboard");
					} else {
						history("/");
					}
				});
			} else {
				authServices.redirect(searchParams.token).then(data => {
					if (data?.status === 200 && data?.data?.url) {
						history(data?.data?.url);
					} else {
						history("/");
					}
				});
			}
		}, 2500);
	}, []);
	return (
		<>
			<div className="bg-white redirect-page ">
				<div className="container">
					<div className="row ">
						<div className="col-md-12">
							<div className="redirect-section text-center">
								<div className="redirect-text">
									<p>
										{type === "general" ? (
											<span className="me-3">Please wait, Redirecting...</span>
										) : type === "redirect" ? (
											<span className="me-3">Redirecting You To</span>
										) : (
											<span className="me-3">Please wait, Logging you to</span>
										)}
										<img src="/images/brand/logo/credifin_logo.png" className="my-4" />
									</p>

									<div className="credifin-logo">
										<img
											src="/images/Redirect/Researching.gif"
											alt=""
											width={350}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Redirect;
