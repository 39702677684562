import "../../assets/css/formstyles.css";
import { Controller, useForm } from "react-hook-form";
import AutoSuggestLoanNumber from "./AutoSuggestLoanNumber";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BiRupee } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";

export default function EmiCollectionNewStep1({
	user,
	formStep,
	nextFormStep,
	setFormData,
	loan_types,
	loan_number,
	setData
}) {
	const [selectedCase, setSelectedCase] = useState({});
	const [branches, setBranches] = useState([]);
	const [branchesOptions, setBranchesOptions] = useState([]);
	const [totalTarget, setTotalTarget] = useState({});
	const [caseExist, setCaseExist] = useState({ status: false, caseNum: "" });
	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const {
		register,
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm();
	
	const customerInteraction = watch("customer_interaction");

	const requiredDetails = [
		"branch_enc_id",
		"loan_type",
		"phone",
		"customer_name",
		"loan_account_number"
	];

	const onSubmit = (values) => {
		if (!values.customer_visit) {
			delete values.customer_visit;
		}
		values.customer_name = values?.customer_name?.replace(/\b\w/g, char => char.toUpperCase());
		for (let i = 0; i < requiredDetails.length; i++) {
			let val = requiredDetails[i];
			if (!values[val]) {
				toast.error(
					(val === "branch_enc_id" ? "Branch" : val) +
					" is not found, Please fill the data to proceed"
				);
				return false;
			}
		}
		setFormData(values);
		nextFormStep();
	};

	const getBranches = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-branches`
			)
			.then((response) => {
				if (response.data.response.status === 200) {
					setBranches(response.data.response.branches);
				} else {
					setBranches([]);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	useEffect(() => {
		if (branches && branches.length) {
			const tempBranchesOptions = branches.map((value) => ({
				value: value.id,
				label: value.value,
			}));
			setBranchesOptions(tempBranchesOptions);
		}
	}, [branches]);

	useEffect(() => {
		if (selectedCase) {
			setValue("customer_name", selectedCase.name);
			setValue("loan_type", selectedCase.loan_type);
			setValue("phone", "");
			setValue("overdue_amount", selectedCase.overdue_amount);
			setData(selectedCase);
			if(loan_number){
				setValue("loan_account_number", loan_number.loan_account_number);
			}
		}
	}, [selectedCase, setValue]);

	useEffect(() => {
		register("overdue_amount");
		register("branch_enc_id");
		if (user && user.organization_enc_id) {
			getBranches();
		}
	}, []);

	useEffect(() => {
		if (user && user.branch_id) {
			setTimeout(() => {
				setValue("branch_enc_id", user.branch_id);
			}, 2000);
		}
	}, [user]);

	useEffect(() => {
		const targetAmount = selectedCase.target_collection_amount ? parseFloat(selectedCase?.target_collection_amount) : "0";
		const outStandingAmount = selectedCase.total_pending_amount ? parseFloat(selectedCase?.total_pending_amount) : "0";
		const collectionCharges = 400;
		const visitCharges = customerInteraction === "Visit" ? 118 : 0;
		const totalAmount = targetAmount + collectionCharges + visitCharges;
		const totalOutstandingAmount = outStandingAmount + collectionCharges + visitCharges;
	  
		setTotalTarget({target: Math.round(totalAmount).toLocaleString("en-IN"), outstanding: Math.round(totalOutstandingAmount).toLocaleString("en-IN")});
	  }, [selectedCase, customerInteraction]);

	useEffect(() => {
		if(loan_number){
			axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-loan-account`, {loan_number: loan_number}
			).then(
				response => {
					if(response.data.response.status === 200){
						setSelectedCase(response.data.response.data[0]);
					}else{
						setSelectedCase({loan_account_number: loan_number});
						toast.error("An error has occured while getting Loan Account Number, Please try again");
					}
				}
			)
				.catch((error) => {
					toast.error(error.message);
				});
		}
	}, [loan_number]);
	return (
		<div className={formStep === 0 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-lg-6 mb-3 mx-auto">
						<label className="form-label mb-0" htmlFor="loan_account_number">
              				Loan Account Number / Phone Number <span style={{ color: "red" }}>*</span>
						</label>
						{loan_number ?
							<input
								disabled="true"
								type="text"
								className="form-control"
								{...register("loan_account_number", {
									required: "This is required.",
								})}
							/>
								 :
							<AutoSuggestLoanNumber
								setSelectedcase={setSelectedCase}
								setVal={setValue}
								setCaseExist={setCaseExist}
								caseExist={caseExist}
								loan_number={loan_number}
								fetchedCase={selectedCase}
								selectedCase={selectedCase}
							/>
						}
					</div>
					{caseExist.status || selectedCase.loan_account_number ? (
						<>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
									Customer Name <span style={{ color: "red" }}>*</span>
								</label>
								<input
									readOnly={selectedCase && selectedCase.name}
									type="text"
									className="form-control"
									{...register("customer_name", {
										required: "This is required.",
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="customer_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
										Phone Number <span style={{ color: "red" }}>*</span>
								</label>
								<input
									className="form-control"
									{...register("phone", {
										required: "This is required.",
										minLength: {
											value: 10,
											message: "Mobile number Should be at least 10 numbers",
										},
										maxLength: {
											value: 10,
											message: "Mobile number maximum be at least 10 numbers",
										},
										pattern: {
											value: phoneRegExp,
											message: "Invalid Phone number",
										},
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="phone"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="states">
									Choose Branch <span style={{ color: "red" }}>*</span>
								</label>
								<Controller
									name="branch_enc_id"
									control={control}
									defaultValue={user.branch_id}
									render={({ field: { onChange, value, onBlur, ref } }) => (
										<Select
											options={branchesOptions}
											className="fs-5 selectBranch"
											onBlur={onBlur}
											inputRef={ref}
											placeholder="Choose Branch"
											value={branchesOptions.find((c) => c.value === value)}
											onChange={(val) => {
												onChange(val.value);
											}
											}
											defaultValue={branchesOptions.find((c) => c.value === user.branch_id)}
											style={{
												border: "1px solid #dee2e6",
												paddingTop: "0.95rem",
												paddingBottom: "0.95rem",
											}}
										/>
									)}
									rules={{ required: true }}
								/>
								{errors.branch_enc_id ? (
									<p style={{ color: "red", fontSize: "13px" }}>This Field is required</p>
								) : ""}
							</div>
						</>
					) : (
						""
					)}
					{caseExist.status || caseExist.caseNum !== "" ? (
						<>
							<div className="row mb-3">
								<div className="col-lg-12">
									<label className="form-label mb-0" htmlFor="">
                    					Loan Type <span style={{ color: "red" }}>*</span>
									</label>
								</div>
								<div className="radio-wrapper">
									{loan_types && loan_types.length
										? loan_types.map((value, index) => {
											return (
												<div
													className="radio-item mb-2"
													key={`limit-${index}`}
												>
													<input
														type="radio"
														value={value.loan_type}
														className="radioInput"
														name="loan_type"
														id={`payment-${index}`}
														{...register("loan_type", {
															required: "This is required.",
														})}
													/>
													<label
														htmlFor={`payment-${index}`}
														className="option option-1 justify-content-start"
													>
														<div className="dot"></div>
														<span>{value.loan_type}</span>
													</label>
												</div>
											);
										})
										: ""}
								</div>
							</div>
						</>
					) : (
						""
					)}
					{selectedCase && selectedCase.loan_account_number ? (
						<>
							<hr/>
							<div className="col-md-12 col-12 mb-3">
								<div className="mb-3">
									<label className="form-label">Customer Interaction</label>
									<div className="d-block">
										<div className="radio-wrapper">
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="customer_interaction-1"
													value="Visit"
													{...register("customer_interaction", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="customer_interaction-1"
													className="option option-1"
												>
													<span>Visit</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="customer_interaction-6"
													// disabled={fullFormData.payment_mode === "2" && fullFormData.payment_method === "4"}
													value="On Call"
													{...register("customer_interaction", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="customer_interaction-6"
													className="option option-6"
												>
													<span>On Call</span>
												</label>
											</div>
										</div>
										<ErrorMessage
											errors={errors}
											name="customer_interaction"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
											)}
										/>
									</div>
								</div>
							</div>

							{customerInteraction === "Visit" && !(user.designation === "Tele Caller Collection" || user.designation === "Tele Sales Executive") ? (
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
									<div className="mb-3">
										<label className="form-label">Customer Visit</label>
										<div className="d-block">
											<div className="radio-wrapper">
												<div className="radio-item">
													<input
														type="radio"
														name="select"
														className="radioInput"
														id="customer_visit-1"
														value="Branch"
														{...register("customer_visit", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="customer_visit-1"
														className="option option-1"
													>
														<span>Branch</span>
													</label>
												</div>
												<div className="radio-item">
													<input
														type="radio"
														name="select"
														className="radioInput"
														id="customer_visit-2"
														value="Home"
														{...register("customer_visit", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="customer_visit-2"
														className="option option-2"
													>
														<span>Home</span>
													</label>
												</div>
												<div className="radio-item">
													<input
														type="radio"
														name="select"
														className="radioInput"
														id="customer_visit-3"
														value="Work"
														{...register("customer_visit", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="customer_visit-3"
														className="option option-3"
													>
														<span>Work</span>
													</label>
												</div>
												<div className="radio-item">
													<input
														type="radio"
														name="select"
														className="radioInput"
														id="customer_visit-4"
														value="Others"
														{...register("customer_visit", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="customer_visit-4"
														className="option option-4"
													>
														<span>Others</span>
													</label>
												</div>
											</div>
											<ErrorMessage
												errors={errors}
												name="customer_visit"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</div>
								</div>
							) : ""}
							<hr />
							<div className="borrower-emi-details row my-2">
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>Bucket:</span>
										<div className="text-secondary fw-medium mx-2 fw-bold px-2">
											{selectedCase.sub_bucket === "0" ? "X" : selectedCase.sub_bucket}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span style={{ fontWeight: 600, color: "#444" }}>Target Collection Amount:</span>
										<div className="text-secondary fw-medium mx-2 d-flex align-items-center fw-bold px-2">
											<BiRupee size={16} /> {totalTarget.target}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>Loan Type:</span>
										<div className="text-gray-800 fw-medium mx-2">
											{selectedCase.loan_type}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>EMI Amount:</span>
										<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
											{selectedCase.emi_amount ? (
												<>
													<BiRupee size={16} />
													{parseFloat(selectedCase.emi_amount).toLocaleString("en-IN")}
												</>
											) : "-"}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>Nach Status:</span>
										<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
											{selectedCase.nach_approved ? selectedCase.nach_approved : "-"}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>Net Outstanding Amount:</span>
										<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
											<BiRupee size={16} /> {totalTarget.outstanding}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>Telecaller:</span>
										<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
											{selectedCase.assigned_caller ? selectedCase.assigned_caller : "-"}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>Overdue EMI:</span>
										<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
											{selectedCase.overdue_amount && parseFloat(selectedCase.overdue_amount) > 0 ? (
												<>
													<BiRupee size={16} />
													{parseFloat(selectedCase.overdue_amount).toLocaleString("en-IN")}
												</>
											) : "-"}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex mb-2">
										<span>Collection Manager:</span>
										<div className="text-gray-800 fw-medium list-data d-inline-block">
											{selectedCase.assignedLoanAccounts && selectedCase.assignedLoanAccounts.length ? selectedCase.assignedLoanAccounts.filter(row => row.user_type === "collection_manager").map((val, index) => {
												return (
													<span key={`collection_manager${index}`}>{val.name}</span>
												);
											}) : "-"}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex flex-wrap mb-2">
										<span>Ledger Amount:</span>
										<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
											{selectedCase.ledger_amount && parseFloat(selectedCase.ledger_amount) > 0 ? (
												<>
													<BiRupee size={16} />
													{parseFloat(selectedCase.ledger_amount).toLocaleString("en-IN")}
												</>
											) : "-"}
										</div>
									</p>
								</div>
								<div className="col-6">
									<p className="d-flex mb-2">
										<span>Sales Manager:</span>
										<div className="text-gray-800 fw-medium list-data d-inline-block">
											{selectedCase.assignedLoanAccounts && selectedCase.assignedLoanAccounts.length ? selectedCase.assignedLoanAccounts.filter(row => row.user_type === "bdo").map((val, index) => {
												return (
													<span key={`bdo${index}`}>{val.name}</span>
												);
											}) : "-"}
										</div>
									</p>
								</div>
								{selectedCase.emiCollectionsCustom?.proposed_amount ? (
									<div className="col-6">
										<p className="d-flex flex-wrap mb-2">
											<span>PTP Amount:</span>
											<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
												<>
													<BiRupee size={16} />
													{parseFloat(selectedCase.emiCollectionsCustom.proposed_amount).toLocaleString("en-IN")}
												</>
											</div>
										</p>
									</div>
								) : ""}
								{selectedCase.emiCollectionsCustom?.proposed_date ? (
									<div className="col-6">
										<p className="d-flex flex-wrap mb-2">
											<span>PTP Date:</span>
											<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center">
												<>
													{moment(selectedCase.emiCollectionsCustom.proposed_date).format("DD MMM YYYY")}
												</>
											</div>
										</p>
									</div>
								) : ""}
							</div>
						</>
					) : (
						""
					)}
				</div>
				<button type="submit" className="btn btn-primary d-block m-auto">
					Next
				</button>
			</form>
		</div>
	);
}
