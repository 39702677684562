import React, { createContext, useContext, useReducer, useState } from "react";
import OrganizationDashboardReducer from "../reducers/OrganizationDashboardReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "./MainState";
import moment from "moment";

// Create context
export const OrganizationDashboardContext = createContext({});

// Provider component
export const OrganizationDashboardProvider = ({ children }) => {
	const [state, dispatch] = useReducer(OrganizationDashboardReducer, {});
	const [isLoading, setIsLoading] = useState(false);
	const [multiLoading, setMultiLoading] = useState([]);
	const [emiFilters, setEmiFilters] = useState({
		start_date: "",
		end_date: "",
		branch_name: "",
		type: true
	});
	const [cancelTokenSource, setCancelTokenSource] = useState(null);
	const { user } = useContext(MainContext);

	// useEffect(() => {
	// Cleanup function to cancel the request if component unmounts
	// return () => {
	// 	if (cancelTokenSource) {
	// 		cancelTokenSource.cancel("Component unmounted");
	// 	}
	// };
	// }, [cancelTokenSource]);

	// Actions
	const getStatsData = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/lead-stats`,
				{}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_STATS",
						payload: response.data.response.stats
					});
				} else {
					dispatch({
						type: "SET_STATS",
						payload: []
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while getting states Data, Please try again");
			});
	};

	const getDashboardStatsAmount = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/dashboard-stats`,
				filter
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_STATS_AMOUNTS",
						payload: response.data.response.data
					});
				}else {
					dispatch({
						type: "SET_STATS_AMOUNTS",
						payload: []
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while getting dashboard stats amount, Please try again");
			});
	};

	const getNewDashboardStatsAmount = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/new-dashboard-stats`,
				filter
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_NEW_STATS_AMOUNTS",
						payload: response.data.response.data
					});
				}else {
					dispatch({
						type: "SET_NEW_STATS_AMOUNTS",
						payload: []
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while getting dashboard stats amount, Please try again");
			});
	};

	const getEmiCollectionStats = async filter => {
		if (cancelTokenSource && cancelTokenSource["emi_stats"]) {
			cancelTokenSource["emi_stats"].cancel("Previous request canceled");
		}

		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["emi_stats"]: newCancelTokenSource});

		setMultiLoading([...multiLoading, "emiStats"]);
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/emi-stats`,
				filter, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setMultiLoading(multiLoading.filter(val => val !== "emiStats"));
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_EMI_STATS",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_EMI_STATS",
						payload: []
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error("An error has occurred while getting emi collection stats, Please try again");
				}

			});
	};

	const emiCombinedData = async filter => {
		const combinedFilter = { ...emiFilters, ...filter };
		setEmiFilters(combinedFilter);
		if (combinedFilter.type) {
			await getEmiCollectionStats(combinedFilter);
		}
		// await getEmiCollection(combinedFilter);
	};

	const handleLoading = type => {
		setIsLoading(type);
	};

	const getLoanProductsReport = async filter => {
		setIsLoading("employeeStat");
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/product-list-stats`,
				filter
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_PRODUCT_REPORT_DATA",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_PRODUCT_REPORT_DATA",
						payload: {}
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while getting loan products reports, Please try again");
			});
	};

	const getEmiCashCollectionData = async filter => {
		setIsLoading("cashReport");
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/emi-employee-stats`,
				filter
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_EMI_CASH_COLLECTION_DATA",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_EMI_CASH_COLLECTION_DATA",
						payload: {}
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error(
					"An error has occurred while getting emi cash collection data, Please try again"
				);
			});
	};

	const getCreditReports = async (filters = {}) => {
		setIsLoading("creditTable");
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}credit-reports/list`,
				filters
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_CREDIT_REPORTS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_CREDIT_REPORTS",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while getting credit reports, Please try again");
			});
	};

	const getLoanApplicationsCases = async data => {
		if (cancelTokenSource && cancelTokenSource[data.type]) {
			cancelTokenSource[data.type].cancel("Previous request canceled");
		}
		
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, [data.type]: newCancelTokenSource});

		if (moment(data.start_date).isAfter(data.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		data["start_date"] = data.start_date + " 00:00:00";
		data["end_date"] = data.end_date + " 23:59:59";
		setIsLoading(data.type);
		const params = {
			...data,
			form_type: data["formType"],
			provider_id: user.organization_enc_id,
			user_type: user.user_type
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-applications`,
				params, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_DISBURSED_CASES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_LOAN_DISBURSED_CASES",
						payload: []
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					toast.error(
						"An error has occurred while getting loan applications cases, Please try again"
					);
				}
				
			});
	};

	const getNachRequests = async data => {
		if (moment(data.start_date).isAfter(data.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		// data["start_date"] = data.start_date + " 00:00:00";
		// data["end_date"] = data.end_date + " 23:59:59";

		if (cancelTokenSource && cancelTokenSource["nach_requests"]) {
			cancelTokenSource["nach_requests"].cancel("Previous request canceled");
		}

		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["nach_requests"]: newCancelTokenSource});

		setIsLoading("nach_requests");

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/nach-list`,
				data, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: data.type === "registered"
							? "SET_NACH_REQUESTS"
							: "SET_" + data.type.toUpperCase() + "_NACH_REQUESTS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: data.type === "registered"
							? "SET_NACH_REQUESTS"
							: "SET_" + data.type.toUpperCase() + "_NACH_REQUESTS",
						payload: []
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					toast.error(
						"An error has occurred while getting eNACH Registrations report, Please try again"
					);
				}

			});
	};
	
	const getNewNachRequests = async data => {
		if (moment(data.start_date).isAfter(data.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		// data["start_date"] = data.start_date + " 00:00:00";
		// data["end_date"] = data.end_date + " 23:59:59";

		if (cancelTokenSource && cancelTokenSource["new_nach_requests"]) {
			cancelTokenSource["new_nach_requests"].cancel("Previous request canceled");
		}

		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["new_nach_requests"]: newCancelTokenSource});

		setIsLoading("new_nach_requests");

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/lead-nach-list`,
				data, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_NEW_NACH_REQUESTS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_NEW_NACH_REQUESTS",
						payload: []
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					toast.error(
						"An error has occurred while getting eNACH Registrations report, Please try again"
					);
				}

			});
	};

	const getDashboardLoanApplications = async data => {
		if (moment(data.start_date).isAfter(data.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		data["start_date"] = data.start_date + " 00:00:00";
		data["end_date"] = data.end_date + " 23:59:59";
		setIsLoading(data.type);
		const params = {
			...data,
			form_type: data["formType"],
			provider_id: user.organization_enc_id
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-applications`,
				params
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type:
							data.type === "all"
								? "SET_LOAN_APPLICATION"
								: "SET_" + data.type.toUpperCase() + "_LOAN_APPLICATION",
						payload: response.data.response
					});
					if (data.type === "all") {
						dispatch({
							type: "SET_APPLICATION_STATS",
							payload: response.data.response.data
						});
					}
				} else {
					dispatch({
						type:
							data.type === "all"
								? "SET_LOAN_APPLICATION"
								: "SET_" + data.type.toUpperCase() + "_LOAN_APPLICATION",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting dashboard loan applications, Please try again"
				);
			});
	};

	const getPartnerLoanApplications = async data => {
		if (moment(data.start_date).isAfter(data.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		data["start_date"] = data.start_date + " 00:00:00";
		data["end_date"] = data.end_date + " 23:59:59";
		setIsLoading(data.type);
		const params = {
			...data,
			form_type: data["formType"],
			provider_id: user.organization_enc_id
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/partner-loan-applications`,
				params
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type:
							data.type === "all"
								? "SET_PARTNER_LOAN_APPLICATION"
								: "SET_" + data.type.toUpperCase() + "_LOAN_APPLICATION",
						payload: response.data.response
					});
					// if (data.type === "all") {
					// 	dispatch({
					// 		type: "SET_APPLICATION_STATS",
					// 		payload: response.data.response.data
					// 	});
					// }
				} else {
					dispatch({
						type:
							data.type === "all"
								? "SET_PARTNER_LOAN_APPLICATION"
								: "SET_" + data.type.toUpperCase() + "_LOAN_APPLICATION",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting partner loan applications, Please try again"
				);
			});
	};

	const getLoanApplications = async (filter, formType) => {
		setIsLoading("table");
		const params = {
			filter,
			form_type: formType,
			limit: 2000,
			provider_id: user.organization_enc_id
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-applications`,
				params
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_APPLICATION",
						payload: response.data.response.loans
					});
				} else {
					dispatch({
						type: "SET_LOAN_APPLICATION",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting loan applications, Please try again"
				);
			});
	};

	const getBranches = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-branches`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_BRANCHES",
						payload: response.data.response.branches
					});
				} else {
					dispatch({
						type: "SET_BRANCHES",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting branches, Please try again"
				);
			});
	};

	const getStates = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-states`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_STATES",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_STATES",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting states, Please try again"
				);
			});
	};

	const getNegativeLocations = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/get-financer-loan-negative-location`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "NEGATIVE_LOCATIONS",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "NEGATIVE_LOCATIONS",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting negative locations, Please try again"
				);
			});
	};

	const getEmiCollection = async data => {
		if (cancelTokenSource && cancelTokenSource[data.pageType]) {
			cancelTokenSource[data.pageType].cancel("Previous request canceled");
		}
		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, [data.pageType]: newCancelTokenSource});
		data["discrepancy_list"] = data["custom_status"] === "discrepancy";
		data["custom_status"] =
			data["custom_status"] !== "all" && !data["discrepancy_list"]
				? data["custom_status"]
				: null;
		const params = {
			...data,
			organization_id: user.organization_enc_id,
			limit: data.limit ? data.limit : 50
		};
		setIsLoading("emiTable");
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/get-collected-emi-list`,
				params, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setIsLoading(false);
				// setMultiLoading(multiLoading.filter(val => val !== loaderKey));
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type:
							data.discrepancy_list ||
							(data.custom_status && (data.custom_status === "pipeline" || data.custom_status === "pending" || data.custom_status === "exception"))
								? "SET_EMI_" +
								  (data.discrepancy_list ? "DISCREPANCY" : data.custom_status.toUpperCase()) +
								  "_COLLECTION" : "SET_EMI_COLLECTION",
						payload: response.data.response
					});
				} else {
					dispatch({
						type:
							data.discrepancy_list ||
							(data.custom_status &&
								(data.custom_status === "pipeline" ||
									data.custom_status === "pending" || data.custom_status === "exception"))
								? "SET_EMI_" + (data.discrepancy_list
									? "DISCREPANCY"
									: data.custom_status.toUpperCase()) + "_COLLECTION"
								: "SET_EMI_COLLECTION",
						payload: []
					});
				}
			})
			.catch(error => {
				// setMultiLoading(multiLoading.filter(val => val !== loaderKey));
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error(
						"An error has occurred while getting Emi Collection Data, Please try again"
					);
				}
			});
	};

	const getEmiAccountData = async data => {
		setIsLoading(data.type ? data.type : "table");
		// Cancel the previous request if it exists
		if (cancelTokenSource && cancelTokenSource[data.type]) {
			cancelTokenSource[data.type].cancel("Previous request canceled");
		}

		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, [data.type]:newCancelTokenSource});
		
		const params = { ...data };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-emi-accounts`,
				params, { cancelToken: newCancelTokenSource ? newCancelTokenSource.token : null }
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: data.type && data.type != "all" ? "SET_EMI_"+data.type.toUpperCase() : "SET_EMI_LOAN_ACCOUNT",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: data.type && data.type != "all" ? "SET_EMI_"+data.type.toUpperCase() : "SET_EMI_LOAN_ACCOUNT",
						payload: []
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					console.log(error);
					toast.error(
						"An error has occurred while getting EMI Loan Account Data, Please try again"
					);
				}
			});
	};

	const getPtpCases = async data => {
		setMultiLoading([...multiLoading, "ptpTable"]);
		const params = { ...data };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-ptp-cases`,
				params
			)
			.then(response => {
				setMultiLoading(multiLoading.filter(val => val !== "ptpTable"));
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_PTP_CASES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_PTP_CASES",
						payload: {count:0, ptpcases: []}
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting PTP cases, Please try again"
				);
			});
	};
	
	const getPickCashCases = async data => {
		setMultiLoading([...multiLoading, "pick-cash"]);
		const params = { ...data };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/cash-pickup-detail`,
				params
			)
			.then(response => {
				setMultiLoading(multiLoading.filter(val => val !== "pick-cash"));
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_PICK_CASH_CASES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_PICK_CASH_CASES",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting Pick Cash cases, Please try again"
				);
			});
	};

	const getAssignedLoanTypes = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/assigned-loan-types`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_ASSIGNED_LOAN_TYPES",
						payload: response.data.response.assignedLoanTypes
					});
				} else {
					dispatch({
						type: "SET_ASSIGNED_LOAN_TYPES",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting Assigned Loan Types, Please try again"
				);
			});
	};

	const getFinancerAssignedLoanTypes = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/assigned-financer-loan-types`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_FIN_ASSIGNED_LOAN_TYPES",
						payload: response.data.response.assignedLoanTypes
					});
				} else {
					dispatch({
						type: "SET_FIN_ASSIGNED_LOAN_TYPES",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting Financer Assigned Loan Types, Please try again"
				);
			});
	};

	const getLoanProducts = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-loan-products`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_PRODUCTS",
						payload: response.data.response.loan_products
					});
				} else {
					dispatch({
						type: "SET_LOAN_PRODUCTS",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting Loan Products, Please try again"
				);
			});
	};

	const getOrganizationNotices = async (data) => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-notice`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_NOTICES",
						payload: response.data.response.notices
					});
				} else {
					dispatch({
						type: "SET_NOTICES",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(
					"An error has occurred while getting Organization Notices, Please try again"
				);
			});
	};

	const getLoanAccountStats = async filters => {
		if (moment(filters.start_date).isAfter(filters.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		filters["start_date"] = filters.start_date + " 00:00:00";
		filters["end_date"] = filters.end_date + " 23:59:59";
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/stats`,
				filters
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_ACCOUNT_STATS",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_LOAN_ACCOUNT_STATS",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(
					"An error has occurred while getting Loan Account Stats, Please try again"
				);
			});
	};

	const getLoanAccountTypes = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/loan-accounts-type`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_ACCOUNT_TYPES",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_LOAN_ACCOUNT_TYPES",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error(
					"An error has occurred while getting Loan Account Types, Please try again"
				);
			});
	};

	const getVehicleBrand = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-financer-vehicle-brand`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_BRANDS_LIST",
						payload: response.data.response.financer_list
					});
				} else {
					dispatch({
						type: "SET_BRANDS_LIST",
						payload: []
					});
				}
			})
			.catch(error => {
				console.error(error);
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
				toast.error(
					"An error has occurred while getting Vehicle Brands, Please try again"
				);
			});
	};

	const getVehicleType = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/get-financer-vehicle-type`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_VEHICLE_TYPE_LIST",
						payload: response.data.response.financer_list
					});
				} else {
					dispatch({
						type: "SET_VEHICLE_TYPE_LIST",
						payload: []
					});
				}
			})
			.catch(error => {
				console.error(error);
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
				toast.error(
					"An error has occurred while getting Vehicle Types, Please try again"
				);
			});
	};

	const getDesignationList = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/financer-designation-list`
			)
			.then(response => {
				if(response.data.response.status === 200){
					dispatch({
						type: "SET_DESIGNATION_LIST",
						payload: response.data.response.data
					});
				}else{
					dispatch({
						type: "SET_DESIGNATION_LIST",
						payload: []
					});
				}
			}).catch(error => {
				console.error(error);
				toast.error(
					"An error has occurred while getting department lists, Please try again"
				);
			});
	};

	const getOrganizationDepartments = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/organization-department-list`
			)
			.then(response => {
				if(response.data.response.status === 200){
					dispatch({
						type: "SET_ORGANIZATION_DEPARTMENTS",
						payload: response.data.response.data
					});
				}else{
					dispatch({
						type: "SET_ORGANIZATION_DEPARTMENTS",
						payload: []
					});
				}
			}).catch(error => {
				console.error(error);
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
				toast.error(
					"An error has occurred while getting department lists, Please try again"
				);
			});
	};

	const cleanEmiStorage = () => {
		dispatch({
			type: "REMOVE_EMI_COLLECTIONS_LIST",
			payload: []
		});
	};

	const getDealerCollectionList = async data => {
		setIsLoading("dealerList");
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/dealer-collection-list`, data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_DEALER_COLLECTION_LIST",
						payload: response.data.response
					});
				}else {
					dispatch({
						type: "SET_DEALER_COLLECTION_LIST",
						payload: []
					});
				}
			}).catch(error => {
				setIsLoading(false);
				console.error(error);
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
				toast.error(
					"An error has occurred while getting Dealer Collection List, Please try again"
				);
			});
	};

	return (
		<OrganizationDashboardContext.Provider
			value={{
				stats: state.stats,
				stats_amounts: state.stats_amounts,
				new_stats_amounts: state.new_stats_amounts,
				loan_applications: state.loan_applications,
				partner_loan_applications: state.partner_loan_applications,
				application_stats: state.application_stats,
				negativeLocations: state.negativeLocations,
				branches_list: state.branches_list,
				states_list: state.states_list,
				creditReports: state.creditReports,
				assigned_loan_types: state.assigned_loan_types,
				financer_assigned_loan_types: state.financer_assigned_loan_types,
				loan_products: state.loan_products,
				collection: state.collection,
				EmiCollectionStatsData: state.EmiCollectionStatsData,
				notices: state.notices,
				noticeImages: state.noticeImages,
				noticeTextsOnly: state.noticeTextsOnly,
				accountData: state.accountData,
				emiCashCollectionData: state.emiCashCollectionData,
				loanApplicationProcessed: state.loanApplicationProcessed,
				loanProductReport: state.loanProductReport,
				loanAccountStats: state.loanAccountStats,
				loanAccountTypes: state.loanAccountTypes,
				ptpcases: state.ptpcases,
				getPartnerLoanApplications,
				brandsList: state.brandsList,
				vehicleTypeList: state.vehicleTypeList,
				departmentList: state.departmentList,
				designationList: state.designationList,
				nach_requests: state.nach_requests,
				new_nach_requests: state.new_nach_requests,
				dealerCollectionList: state.dealerCollectionList,
				pickCash: state.pickCash,
				getDesignationList,
				getEmiCollection,
				getLoanProductsReport,
				getEmiAccountData,
				getCreditReports,
				getDashboardLoanApplications,
				getStatsData,
				getLoanApplications,
				getLoanApplicationsCases,
				getNachRequests,
				getNewNachRequests,
				isLoading,
				multiLoading,
				emiCombinedData,
				getEmiCollectionStats,
				getPickCashCases,
				handleLoading,
				getBranches,
				getStates,
				getDashboardStatsAmount,
				getNewDashboardStatsAmount,
				getNegativeLocations,
				getAssignedLoanTypes,
				getFinancerAssignedLoanTypes,
				getLoanProducts,
				getOrganizationNotices,
				getEmiCashCollectionData,
				getLoanAccountStats,
				getLoanAccountTypes,
				getPtpCases,
				getVehicleBrand,
				getVehicleType,
				getOrganizationDepartments,
				cleanEmiStorage,
				getDealerCollectionList,
			}}
		>
			{children}
		</OrganizationDashboardContext.Provider>
	);
};
