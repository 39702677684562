export const loanStatusData = {
	loanStatus: [
		{
			name: "New Lead",
			value: 0
		},
		{
			name: "Accepted",
			value: 1
		},
		{
			name: "Pre Verification",
			value: 2
		},
		{
			name: "Under Process",
			value: 3
		},
		{
			name: "Login",
			value: 4
		},
		{
			name: "Credit Check",
			value: 5
		},
		{
			name: "Online Login Not Received",
			value: 6
		},
		{
			name: "Invalid Login: Login Again",
			value: 7
		},
		{
			name: "CIBIL Reject",
			value: 8
		},
		{
			name: "CIBIL Approved",
			value: 9
		},
		{
			name: "Field Visit & Document Collection",
			value: 10
		},
		{
			name: "TL Approved",
			value: 11
		},
		{
			name: "PD Planned",
			value: 12
		},
		{
			name: "TVR Calling Done",
			value: 13
		},
		{
			name: "PD Done",
			value: 14
		},
		{
			name: "TVR / Soft Approval",
			value: 15
		},
		{
			name: "Conditional Sanction",
			value: 16
		},
		{
			name: "Pendencies",
			value: 17
		},
		{
			name: "Decision Pending",
			value: 18
		},
		{
			name: "L&T Fee Pending",
			value: 19
		},
		{
			name: "Legal/Technical initiated",
			value: 20
		},
		{
			name: "Valuation Received",
			value: 21
		},
		{
			name: "Legal Received",
			value: 22
		},
		{
			name: "Technical/Legal Approval Received",
			value: 23
		},
		{
			name: "Conditional / Soft Sanction",
			value: 24
		},
		{
			name: "Finalise ASAP",
			value: 25
		},
		{
			name: "Disbursement Approval",
			value: 26
		},
		{
			name: "Disbursement",
			value: 27
		},
		{
			name: "CNI",
			value: 28
		},
		{
			name: "On Hold",
			value: 29
		},
		{
			name: "Sanctioned",
			value: 30
		},
		{
			name: "Disbursed",
			value: 31
		},
		{
			name: "Rejected",
			value: 32
		},
		{
			name: "Completed",
			value: 33
		}
	]
};
