import { toast } from "react-toastify";
import axios from "axios";
import { Col, Modal } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import MapDraggableMarker from "../MapDraggableMarker";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { MainContext } from "../../context/MainState";
import { IoCloseCircle } from "react-icons/io5";
// import MapWithDragableMarker from "../MapWithDragableMarker";

const DropLocationModal = ({ show, onHide, locationData, loanDetails, type="", openSideBar}) => {
	const {handlePageLoaded } = useContext(MainContext);
	const { getNegativeLocations } = useContext(OrganizationDashboardContext);
	const { handleSubmit, register, setValue, reset, watch } = useForm();
	const [markerPosition, setMarkerPosition] = useState({});
	// const [mapValues, setMapValues] = useState({ latitude: 30.898364, longitude: 75.830917 });
	useEffect(() => {
		if (locationData.latitude) {
			setMarkerPosition({lat: locationData.latitude, lng: locationData.longitude});
		}
	}, [locationData]);
	// const getLocationDetail = values => {
	// 	setValue("street", values.street);
	// 	setValue("city", values.locality);
	// 	setValue("state", values.state);
	// 	setValue("postalCode", values.postal);
	// 	setValue("latitude", values.latlng.lat);
	// 	setValue("longitude", values.latlng.lng);
	// };
	// const onMarkerDragEnd = (e) => {
	// 	const newPosition = {
	// 		lat: e.latLng.lat(),
	// 		lng: e.latLng.lng(),
	// 	};
	// 	setMarkerPosition(newPosition);
	// 	// You can handle the marker position here, e.g., update state, send to server, etc.
	// };

	const onSubmit = response => {
		let address_arr = [response.street, response.city, response.state, response.postalCode];
		address_arr = address_arr.filter(Boolean);
		const data = {
			radius: response.radius,
			local_address: address_arr.length ? address_arr.toString() : "",
			latitude: markerPosition.lat,
			longitude: markerPosition.lng,
		};
		if(type === "sidebar") {
			const selectedValues = Array.isArray(response.assigned_borrower_enc_id)
				? response.assigned_borrower_enc_id
				: [response.assigned_borrower_enc_id];
			data.loan_id = loanDetails.loan_app_enc_id;
			data.assigned_borrower_enc_id = selectedValues;
			data.location_name = response.location_name === "others" ? response.location_address : response.location_name;
		}
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/${type === "sidebar" ? "add-verification-location" : "create-financer-loan-negative-location"}`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					if(type === "sidebar") {
						openSideBar(loanDetails.loan_app_enc_id);
					}else{
						getNegativeLocations();
					}
					toast.success("Location successfully dropped");
					onHide(true);
					reset();
				} else {
					toast.error("An error has occurred, Please try again later");
				}
			}).catch(error => {
				handlePageLoaded(false);
				toast.error("An Error Occurred: " + error.message);
			});
	};

	useEffect(() => {
		register("street");
		register("city");
		register("state");
		register("postalCode");
		register("latitude");
		register("longitude");
	}, []);
	useEffect(() => {
		if (locationData.latitude) {
			setValue("street", locationData.street);
			setValue("city", locationData.city);
			setValue("state", locationData.state);
			setValue("postalCode", locationData.postalCode);
			setValue("latitude", locationData.latitude);
			setValue("longitude", locationData.longitude);
			// setMapValues({ latitude: locationData.latitude, longitude: locationData.longitude });
		}
	}, [locationData]);
	
	const cityCode = watch("location_name");
	return (
		<>
			<Modal show={show} onHide={onHide} size={"lg"} centered>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						{type === "sidebar" ? (
							<>
								<h3 className="text-center">Location Details <span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span></h3>
								<div className="col-sm-12 col-12">
									<div className="mb-3">
										<div>
											<label className="mb-2">Location </label>
										</div>
										<div className="radio-wrapper hero-radio-wrapper">
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="option-1"
													value="Home"
													{...register("location_name", {
														required: "This is required."
													})}
												/>
												<label htmlFor="option-1" className="option option-1">
													<div className="dot"></div>
													<span>Home</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="option-2"
													value="Work"
													{...register("location_name", {
														required: "This is required."
													})}
												/>
												<label htmlFor="option-2" className="option option-2">
													<div className="dot"></div>
													<span>Work</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="select"
													className="radioInput"
													id="option-3"
													value="others"
													{...register("location_name", {
														required: "This is required."
													})}
												/>
												<label htmlFor="option-3" className="option option-3">
													<div className="dot"></div>
													<span>Other</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								{cityCode === "others" ? (
									<div className="col-sm-6 col-6">
										<div className="mb-3">
											<label className="mb-2">Location Name</label>
											<input
												type="text"
												name="location_address"
												className="form-control"
												{...register("location_address", {
													required: "This is required."
												})}
											/>
										</div>
									</div>
								): ""}
								<div className="col-sm-12 col-12">
									<div className="mb-3">
										<div>
											<label className="mb-2">Choose Applicants </label>
										</div>
										<div className="radio-wrapper hero-radio-wrapper">
											{loanDetails.loanCoApplicants && loanDetails.loanCoApplicants.length
												? loanDetails.loanCoApplicants.map((value, index) => {
													return (
														<>
															<div className="radio-item mb-3" key={`dropUser-${index}`}>
																<input
																	type="checkbox"
																	name="user"
																	className="radioInput"
																	id={value.loan_co_app_enc_id}
																	value={value.loan_co_app_enc_id}
																	{...register("assigned_borrower_enc_id", {
																		required: "This is required."
																	})}
																/>
																<label htmlFor={value.loan_co_app_enc_id} className="option option-1 position-relative justify-content-center">
																	<span>{value.name}</span>
																	<span className="b-type">{value.borrower_type}</span>
																</label>
															</div>
														</>
													);
												})
									  		: "-"}
										</div>
									</div>
								</div>
							</>
						) :(
							<>
								<div className="col-sm-12 col-12">
									<div className="mb-3">
										<label className="form-label">Enter Radius in KM</label>
										<input
											placeholder="Radius"
											name="radius"
											type="number"
											min={1}
											className="form-control"
											onWheel={e => e.target.blur()}
											{...register("radius", {
												required: "This is required."
											})}
										/>
									</div>
									{/* {markerPosition.lat ? (*/}
									{/*	 <MapWithDragableMarker  markerPosition={markerPosition} onMarkerDragEnd={onMarkerDragEnd} center={markerPosition}/>*/}
									{/* ) : ""}*/}
								</div>
								<div className="col-sm-12 col-12">
									{/* <MapDraggableMarker */}
									{/* 	data={mapValues} */}
									{/* 	setInputFieldData={getLocationDetail} */}
									{/* /> */}
								</div>
							</>
						)}
						<div className="col-sm-12 col-12">
							<Col size={12} className="text-center mt-2">
								<button type="submit" className="btn btn-primary">
									Save
								</button>
							</Col>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default DropLocationModal;
