import moment from "moment";
import { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import MinMaxFilter from "./MinMaxFilter";

const LoanColSearch = ({
	colName,
	setSearchArray,
	searchArray,
	branches,
	status,
	states_list,
	clearFilters = false,
	loan_types = [],
	loan_product_id 
}) => {
	const [selectedCount, setSelectedCount] = useState("0");
	const [dateRange, setDateRange] = useState(["~", "~"]);
	const [startDate, endDate] = dateRange;
	const [multiSelectValues, setMultiSelectValues] = useState("~");
	const [financerLoanProducts, setFinancerLoanProducts] = useState([]);

	const searchLoanApps = val => {
		let data = [];
		if(colName === "state"){
			data["state_enc_id"] = val;
		}else{
			data[colName] = val;
		}
		setSearchArray({ ...searchArray, ...data });
	};
 
	const [selectAllArr, setSelectAllArr] = useState({});
	const [previousAllArr, setpreviousAllArr] = useState({});

	const selectedCustomData = (selected, list = null) => {
		let selectAll = selected.filter(item => item.value === "all");
		selectAll = selectAll.length ? selectAll[0] : "";
		let prevSelectAll = previousAllArr && previousAllArr[colName] ? previousAllArr[colName].filter(item => item.value === "all") : ""; 
		let flag = false;

		if(list && selected.length === list.length - 1 && !selectAll && prevSelectAll.length){
			flag = false;
			selected = [];
		}else if((selected.length && selectAll && !selectAllArr[colName]) || (list && selected.length === list.length - 1 && !selectAll)){
			flag = true;
			selected = list;
		}else{
			flag = false;
			selected = selected.filter(item => item.value != "all");
		}
						
		setpreviousAllArr({...prevSelectAll, [colName]: selected});		
		setSelectAllArr({...selectAllArr, [colName]: flag});
		
		searchLoanApps(
			selected.map(option => option.value),
			colName
		);
		setSelectedCount(selected.length);
		setMultiSelectValues(selected);
	};

	const loan_status_list = status
		? [{
			label: "All", value: "all"
		}, ...status.map(data => ({
			label: data.loan_status,
			value: data.value
		  }))]
		: [];

	const branches_list = branches
		?  [{
			label: "All", value: "all"
		}, ...branches.map(data => ({
			label: data.value,
			value: data.id
		  }))]
		: [];
	
	const states= states_list
		?  [{
			label: "All", value: "all"
		}, ...states_list.map(data => ({
			label: data.name,
			value: data.state_enc_id
		  }))]
		: [];

	useEffect(() => {
		if (startDate !== "~" && endDate !== "~") {
			let data = [];
			data[colName + "_start_date"] = startDate
				? moment(startDate).format("YYYY-MM-DD") + " 00:00:00"
				: "";
			data[colName + "_end_date"] = endDate ? moment(endDate).format("YYYY-MM-DD") + " 23:59:59" : "";
			setSearchArray({ ...searchArray, ...data });
		}
	}, [dateRange]);

	let priorityList = [
		{
			label: "High",
			value: "2"
		},
		{
			label: "Medium",
			value: "3"
		},
		{
			label: "Low",
			value: "4"
		}
	];

	useEffect(() => {
		const matchingProduct = loan_types.find(product => product.loan_type_enc_id === loan_product_id);
		setFinancerLoanProducts(matchingProduct ? matchingProduct.financerLoanProducts : []);
	  }, [loan_types, loan_product_id]);

	  const loan_portfolio = financerLoanProducts
		?  [{
			label: "All", value: "all"
		}, ...financerLoanProducts.map(data => ({
			label: data.name,
			value: data.financer_loan_product_enc_id
		  }))]
		: [];

	useEffect(() => {
		if (clearFilters) {
			searchLoanApps("");
			setSelectedCount("0");
			setDateRange(["~", "~"]);
			setMultiSelectValues("~");
		}
	}, [clearFilters]);

	return colName === "branch" ? (
		<Multiselect
			className="custom-select-col"
			avoidHighlightFirstOption
			emptyRecordMsg="No Branches Found"
			placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
			displayValue="label"
			options={branches_list}
			selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
			showCheckbox
			hideSelectedList
			onRemove={e => selectedCustomData(e, branches_list)}
			onSelect={e => selectedCustomData(e, branches_list)}
		/>
	) : colName === "status" ? (
		<Multiselect
			className="custom-select-col"
			avoidHighlightFirstOption
			emptyRecordMsg="No Status Found"
			selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
			placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
			displayValue="label"
			options={loan_status_list}
			showCheckbox
			hideSelectedList
			onRemove={e => selectedCustomData(e, loan_status_list)}
			onSelect={e => selectedCustomData(e, loan_status_list)}
		/>
	) : colName === "product" ? (
		<Multiselect
			className="custom-select-col"
			avoidHighlightFirstOption
			emptyRecordMsg="No Loan Product Found"
			selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
			placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
			displayValue="label"
			options={loan_portfolio}
			showCheckbox
			hideSelectedList
			onRemove={e => selectedCustomData(e, loan_portfolio)}
			onSelect={e => selectedCustomData(e, loan_portfolio)}
		/>
	) : colName === "state" ? (
		<Multiselect
			className="custom-select-col"
			avoidHighlightFirstOption
			emptyRecordMsg="No States Found"
			selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
			placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
			displayValue="label"
			options={states}
			showCheckbox
			hideSelectedList
			onRemove={e => selectedCustomData(e, states)}
			onSelect={e => selectedCustomData(e, states)}
		/>
	) : colName === "priority" ? (
		<Multiselect
			className="custom-select-col"
			avoidHighlightFirstOption
			emptyRecordMsg="No priority Found"
			selectedValues={multiSelectValues !== "~" ? multiSelectValues : []}
			placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : ""}
			displayValue="label"
			options={priorityList}
			showCheckbox
			hideSelectedList
			onRemove={e => selectedCustomData(e)}
			onSelect={e => selectedCustomData(e)}
		/>
	) : colName === "apply_date" || colName === "last_updated_on" || colName === "login"  ? (
		<MinMaxFilter
			id={ colName === "apply_date" ? "application_date" : colName === "last_updated_on" ? "last_updated_on" : colName === "login" ? "login_date" : "" }
			placeholder="Date"
			startDate={startDate !== "~" ? startDate : null}
			endDate={endDate !== "~" ? endDate : null}
			searchArray={searchArray}
			setSearchArray={setSearchArray}
			inputType={"datepicker"}
			clearFilters={clearFilters}
			type="mis"
		/>
	) : colName === "tat" || colName === "bdo_approved_amount" || colName === "tl_approved_amount" || colName === "tl_approved_amount" ||  colName === "amount" ? (
		<MinMaxFilter
			title=""
			id={ colName === "tat" ? "tat" : colName === "bdo_approved_amount" ? "bdo_approved_amount" : colName === "tl_approved_amount" ? "tl_approved_amount" : colName === "amount" ? "amount" : ""}
			placeholder="Amount"
			customClass="mis-filter-input"
			searchArray={searchArray}
			setSearchArray={setSearchArray}
			clearFilters={clearFilters}
			type="mis"
		/>
	) :   
		(	
			<div className="col-search">
				<input
					type={"text"}
					className="loan-search-input"
					value={searchArray ? searchArray.colName : ""}
					onChange={e => {
						searchLoanApps(e.target.value);
					}}
				/>
			</div>
		);
};

export default LoanColSearch;
