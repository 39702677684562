import { Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getLogo } from "../helpers/customFunctions";

const ResetPasswordModal = ({ show, onHide, token = null }) => {
	const params = useParams();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm();
	const newpassword = watch("new_password");
	const history = useNavigate();

	const onSubmit = async data => {
		if (token) {
			data = { ...data, token: token };
		} else {
			data = { ...data, token: params.id };
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/reset-password`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Password changed successfully");
					history("/");
					onHide();
				}
			});
	};
	return (
		<Modal show={show} onHide={onHide} size={"lg"} centered>
			<div className="login-page-modal">
				<div className="login-box">
					<div className="login-form-box">
						<div className="logo">
							<img src={getLogo()} alt={process.env.REACT_APP_NAME} />
						</div>

						<div className="login-form">
							<h1 className="mb10">Reset Password</h1>
							{/* <p className="font-12">If you've forgotten your password, enter your registered e-mail address or username and we'll send you an e-mail with a reset password link.</p>*/}
							<form className="form" onSubmit={handleSubmit(onSubmit)}>
								<div className="upper-form">
									<div className="login-username mb-3">
										<input
											placeholder="Password"
											type="password"
											className="form-control"
											{...register("new_password", {
												required: "This is required.",
												minLength: {
													value: 8,
													message:
														"Password must have at least 8 characters"
												}
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="new_password"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="login-username mb-3">
										<input
											placeholder="Confirm Password"
											type="password"
											className="form-control"
											{...register("confirm_password", {
												required: "This is required.",
												validate: value =>
													value === newpassword ||
													"The Password does not match"
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="confirm_password"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="login-buttons submit-form">
										<button type="submit" className="submit-btn btn">
											Reset
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="login-right-img">
						<div>
							<h1>Back To Home</h1>
							<div className="signup-as">
								<div className="signup-as-btns mb-3">
									<button onClick={() => history("/")}>Home</button>
								</div>
								{/* <div className="signup-as-btns">*/}
								{/*    <button onClick={organizationShow}>Sign Up as Organization</button>*/}
								{/* </div>*/}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ResetPasswordModal;
