import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { toast } from "react-toastify";
import { GiDiscussion } from "react-icons/gi";
import { Button, Modal } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { IoCloseCircle } from "react-icons/io5";
import { readOnlyUsers } from "../../helpers/authServices";

const InitiateFi = ({ loanDetails, reload }) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const [show, setShow] = useState(false);
	const { register } = useForm();
	const [documentImage, setDocumentImage] = useState({ image: "", name: "" });
	const [fiStatus, setFiStatus] = useState({ id: "", status: "" });
	const [managerList, setManagerList] = useState([]);
	const [selectedManager, setSelectedManager] = useState([]);
	const fiStepOne = () => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let data  = {branch_id : loanDetails.branch_id};
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-collection-managers`, data)
			.then(
				response => {
					if(response.data.response.status === 200){
						setManagerList(response.data.response.data);
						setShow(true);
					}else{
						setManagerList([]);
					}
				}
			)
			.catch(error => {
				toast.error(error.message);
			}
			);
	};

	const initiateFI = status => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (status === 0 && !selectedManager) {
			toast.error("Please Select Collection Manager");
			return false;
			// if (!window.confirm("Are you sure you want to change the status?")) {
			// 	return false; // User cancelled the action
			// }
		}
		const formData = new FormData();
		if (status === 1 && !documentImage.image) {
			toast.error("Please Choose Document");
			return false;
		}
		handlePageLoaded(true);
		if (status === 1) {
			formData.append("document", documentImage.image);
		}
		if(status === 0){
			formData.append("collection_manager", selectedManager[0].user_enc_id);
		}
		formData.append("loan_app_enc_id", loanDetails.loan_app_enc_id);
		formData.append("type", 0);
		formData.append("status", status);
		if (fiStatus.id) {
			formData.append("loan_application_fi_enc_id", fiStatus.id);
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-fi`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success(`FI ${status === 0 ? "Initiated" : "Completed"} Successfully`);
					reload(loanDetails.loan_app_enc_id);
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "FI Initiated Error"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};
	useEffect(() => {
		if (
			loanDetails &&
			loanDetails.loanApplicationFis &&
			loanDetails.loanApplicationFis.length
		) {
			const tempStatus = loanDetails.loanApplicationFis;
			if (tempStatus.length) {
				setFiStatus({
					id: tempStatus[0].loan_application_fi_enc_id,
					status: tempStatus[0].status
				});
			}
		}
	}, [loanDetails]);
	return (
		<>
			{fiStatus.status === "1" ? (
				<button type="button" className="btn btn-success">
					<GiDiscussion size={17} /> FI Completed
				</button>
			) : (
				<>
					{fiStatus.status === "0" ? (
						<button
							type="button"
							className="btn btn-warning"
							onClick={() => setShow(true)}
						>
							<GiDiscussion size={17} /> FI Initiated
						</button>
					) : (
						<button
							type="button"
							className="btn btn-primary"
							// onClick={() => initiateFI(0)}
							onClick={() => fiStepOne()}
						>
							<GiDiscussion size={17} /> Initiate FI
						</button>
					)}
				</>
			)}

			<Modal show={show} onHide={setShow}>
				<Modal.Header>
					<Modal.Title>{!fiStatus.status ? "Select Collection Manager" : "Upload FI Document"} <span className="close-icon" onClick={() => setShow(false)}><IoCloseCircle /></span></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!fiStatus.status ? 
						<div>
							<Typeahead
								id="select-collection-manager"
								labelKey="name"
								onChange={setSelectedManager}
								options={managerList}
								placeholder="Select Collection Manager"
								selected={selectedManager}
								renderMenuItemChildren={(option) => (
									<div className="d-flex mb-2">
										<img src={option.image} className="EmployeeAvatar"/>
										<p className="m-0 text-black-50">{option.name} ({option.designation})</p>
									</div>
								)
								}
							/>
							<div className="d-flex justify-content-center mt-3">
								<Button variant="primary" onClick={() => initiateFI(!fiStatus.status ? 0 : 1)}>
						Save
								</Button>
							</div>
						</div>
						: 
						<div className="col-lg-12 col-12 mb-3">
							<div className="Fi-main">
								<img
									src="/images/icons/documents.png"
									alt="documents"
									className="fi-img"
								/>
								<div
									className="d-flex align-items-center"
									style={{ marginTop: "10px" }}
								>
									<label
										className="form-control w-auto text-center"
										style={{
											backgroundColor: "#00a0e3",
											color: "#fff",
											cursor: "pointer"
										}}
										htmlFor="FiDocument"
									>
										<FaUpload /> Upload Document
									</label>
									<input
										id="FiDocument"
										placeholder="Upload Document"
										className="form-control d-none"
										{...register("documentImage", {
											required: "This is required."
										})}
										accept="pdf/*"
										type="file"
										onChange={e => {
											if (e.target.files.length) {
												setDocumentImage({
													image: e.target.files[0],
													name: e.target.files[0].name
												});
											}
										}}
									/>
								</div>
								{documentImage.name ? (
									<p
										style={{
											marginBottom: "0px",
											marginTop: "10px",
											fontSize: "14px",
											color: "#000"
										}}
									>
										{documentImage.name}
									</p>
								) : (
									""
								)}
							</div>
						</div>
					}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InitiateFi;
