import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import React, { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import { RiDeleteBin6Line, RiDragMove2Line } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { appsForms } from "../../data/appsFormName";
import { AiFillEye } from "react-icons/ai";

const CreateApps = () => {
	const {
		register,
		formState: { errors }
	} = useForm();
	// const [selectedValue, setSelectedValue] = useState({ index: "", name: "", link: "" });
	const [show, setShow] = useState(false);

	const handleShow = () => setShow(true);

	const handleEdit = () => {
		// setSelectedValue({ index, name, link });
		handleShow();
	};

	const removeElement = () => {
		if (window.confirm("Are you sure you want to remove this element")) {
			// const tempElements = [...elementsData];
			// tempElements.splice(index, 1);
			// setElementsData(tempElements);
		}
	};
	const handleClose = () => {
		setShow(false);
	};

	const sidebarList = [
		{
			text: "Heading",
			elem: row => {
				return (
					<div className="mb-3">
						<label className="form-label mb-0" htmlFor="app_name">
							Heading
						</label>
						<input
							placeholder={`Heading ${row.index}`}
							className="form-control"
							{...register(`${"heading" + "-" + row.index}`, {
								required: "This is required."
							})}
						/>
						<ErrorMessage
							errors={errors}
							name={`heading-${row.index}`}
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				);
			},
			value: ""
		},
		{
			text: "Form",
			elem: row => {
				return (
					<div className="element-item" key={`form-${row.index}`}>
						<RiDragMove2Line size={25} style={{ marginRight: "10px" }} />
						<div className="element-label">
							{"Click edit to enter name and their link"}
						</div>
						<button
							className="link-button"
							style={{ marginRight: "10px" }}
							onClick={() => handleEdit(row.index)}
						>
							<BiEdit size={25} />
						</button>
						<button
							className="link-button"
							style={{ color: "red" }}
							onClick={() => removeElement(row.index)}
						>
							<RiDeleteBin6Line size={25} />
						</button>
					</div>
				);
			},
			name: "",
			link: ""
		},
		{
			text: "Button",
			elem: () => {
				return <input type="text" />;
			}
		}
	];
	const appElements = [];

	// const [list, setList] = useState(sidebarList);
	const [list2, setList2] = useState(appElements);

	const listEmpty = () => {};

	const [file, setFile] = useState();
	const handleLogo = e => {
		if (e.target.files) {
			setFile(URL.createObjectURL(e.target.files[0]));
		} else {
			setFile("");
		}
	};
	return (
		<div>
			<Helmet>
				<title>Dashboard</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="createApps">
				<div className="appsSidebar">
					<h4 className="dashboard-heading-2">App Items</h4>
					<ReactSortable
						className="sortable"
						group={{ name: "shared-badge-group", pull: "clone" }}
						list={sidebarList}
						setList={listEmpty}
					>
						{sidebarList.map(item => {
							return (
								<div className="draggable drag-list" key={item.text}>
									{item.text}
								</div>
							);
						})}
					</ReactSortable>
				</div>
				<div className="createAppsDiv">
					<form>
						<h4 className="dashboard-heading">Create New App</h4>
						<div className="mb-3 d-flex justify-content-center align-items-center">
							{/* <div>*/}
							<img
								style={{
									maxWidth: "150px",
									maxHeight: "150px",
									border: "5px solid #fff",
									boxShadow: "0px 1px 10px 1px #ddd",
									borderRadius: "6px"
								}}
								src={file ? file : "https://place-hold.it/150"}
							/>
							{/* </div>*/}
						</div>
						<div className="mb-3">
							<div>
								<label className="form-label mb-0" htmlFor="loan_amt">
									Choose Logo
								</label>
								<input
									type="file"
									min={1}
									placeholder="Choose File"
									className="form-control"
									{...register("logo", {
										required: "This is required.",
										validate: {
											lessThan10MB: files =>
												files[0]?.size < 5000000 || "Max 5MB",
											acceptedFormats: files =>
												[
													"image/jpeg",
													"image/png",
													"application/pdf"
												].includes(files[0]?.type) ||
												"Only PNG, JPEG and PDF"
										}
									})}
									onChange={handleLogo}
								/>
								<ErrorMessage
									errors={errors}
									name="logo"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="app_name">
								App Name
							</label>
							<input
								placeholder="Full Name"
								className="form-control"
								{...register("app_name", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="app_name"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<div className="mb-3">
							<label className="sr-only form-label mb-0" htmlFor="app_description">
								App Description{" "}
							</label>
							<textarea
								className="form-control"
								id="app_description"
								rows="4"
								{...register("app_description", {
									required: "This is required."
								})}
								placeholder="App Description"
							></textarea>
							<ErrorMessage
								errors={errors}
								name="app_description"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<ReactSortable
							className="sortable mb-3"
							group={{ name: "shared-badge-group" }}
							list={list2}
							setList={setList2}
						>
							{list2.length ? (
								list2.map((item, index) => {
									return (
										<div key={item.text + "-" + index}>{item.elem(index)}</div>
									);
								})
							) : (
								<div className="drag-drop-div">Drag Items Here</div>
							)}
						</ReactSortable>
					</form>
				</div>
			</div>
			<Modal show={show} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>Sign in process</Modal.Title>
				</Modal.Header>
				<form>
					<Modal.Body>
						{appsForms.allForms.map((value, index) => {
							return (
								<div className="element-item" key={`formList-${index}`}>
									<div className="element-label">{value.name}</div>
									<button className="link-button" style={{ marginRight: "10px" }}>
										<AiFillEye size={25} />
									</button>
								</div>
							);
						})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClose}>
							Save & Close
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
		</div>
	);
};
export default CreateApps;
