import React, { useContext, useState} from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import { MainContext } from "../../context/MainState";
import { FaCodeBranch, FaRupeeSign, FaCarAlt, FaUser } from "react-icons/fa";
import ProfileDetails from "../../components/ProfileDetails";
import AddBranches from "../../components/AddBranches";
import { Nav, Tab } from "react-bootstrap";
import FinancerLoanSettings from "../../components/dashboard/FinancerLoanSettings";
import { GiMoneyStack } from "react-icons/gi";
import FinancerLoanProducts from "../../components/dashboard/FinancerLoanProducts";
import DesignationsSettings from "../../components/DesignationsSettings";
import { RiUserVoiceLine } from "react-icons/ri";
import { CiBullhorn } from "react-icons/ci";
import UploadNotice from "../../components/UploadNotice";
import ChangePassword from "../../components/ChangePassword";
import { BsLockFill } from "react-icons/bs";
import UploadAnnouncement from "../../components/UploadAnnouncement";
import { BiNotepad } from "react-icons/bi";
import { OrganizationLoanProductsProvider } from "../../context/LoanProductsState";
import VehicleBrandsSettings from "../../components/VehicleBrandsSettings";
import FinancerVehicleTypes from "../../components/FinancerVehicleTypes";
import { MdGarage, MdOutlineMenuBook, MdOutlineSms } from "react-icons/md";
import SmsSettings from "../../components/SmsSettings";
import AssignTelecallersToBucket from "../../components/dashboard/AssignTelecallersToBucket";

const FinancerProfile = () => {    
	const { user } = useContext(MainContext);
	const [tabTrue, setBrandsList] = useState(false);

	return (
		<div>
			<Helmet>
				<title>Settings</title>
			</Helmet>
			<Navbar />
			<OrganizationLoanProductsProvider>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="vertical-navi bg-white shadow-ell p-5 my-5 rounded-3">
								<Tab.Container defaultActiveKey="personal_details">
									<div className="row">
										<div className="col-md-3 border-right-1">
											<Nav variant="pills" className="flex-column mb-5">
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="personal_details">
														<FaUser /> Personal Details
													</Nav.Link>
												</Nav.Item>
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="branches">
														<FaCodeBranch /> Branches
													</Nav.Link>
												</Nav.Item>
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="loan_settings">
														<FaRupeeSign /> Loan Products Portfolio
													</Nav.Link>
												</Nav.Item>
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="loan_products">
														<GiMoneyStack /> Product Settings
													</Nav.Link>
												</Nav.Item>
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="designations">
														<RiUserVoiceLine /> Designations
													</Nav.Link>
												</Nav.Item>
												{process.env.REACT_APP_TYPE === "DEV" ?
													<> 
														<Nav.Item className="pointer-cursor">
															<Nav.Link eventKey="sms_settings">
																<MdOutlineSms/> SMS Settings
															</Nav.Link>
														</Nav.Item>
														<Nav.Item className="pointer-cursor">
															<Nav.Link eventKey="assign_telecaller">
																<MdOutlineMenuBook /> Assign Telecaller
															</Nav.Link>
														</Nav.Item>
													</>
													: ""}
												<Nav.Item className="pointer-cursor" onClick={() => setBrandsList(true)}>
													<Nav.Link eventKey="brands">
														<FaCarAlt /> Vehicle Brands
													</Nav.Link>
												</Nav.Item>
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="vehicle_types">
														<MdGarage /> Vehicle Types
													</Nav.Link>
												</Nav.Item>
												{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
													<>
														<Nav.Item className="pointer-cursor">
															<Nav.Link eventKey="notices">
																<BiNotepad /> Notices
															</Nav.Link>
														</Nav.Item>
														<Nav.Item className="pointer-cursor">
															<Nav.Link eventKey="announcement">
																<CiBullhorn /> Announcements
															</Nav.Link>
														</Nav.Item>
													</>
												) : ""}
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="resetpass">
														<BsLockFill /> Account Settings
													</Nav.Link>
												</Nav.Item>
											</Nav>
										</div>
										<div className="col-md-9 padding-left-30">
											<Tab.Content>
												<Tab.Pane eventKey="personal_details">
													<ProfileDetails user={user} />
												</Tab.Pane>
												<Tab.Pane eventKey="branches">
													<AddBranches />
												</Tab.Pane>
												<Tab.Pane eventKey="loan_settings">
													<FinancerLoanSettings />
												</Tab.Pane>
												<Tab.Pane eventKey="loan_products">
													<FinancerLoanProducts />
												</Tab.Pane>
												<Tab.Pane eventKey="designations">
													<DesignationsSettings />
												</Tab.Pane>
												<Tab.Pane eventKey="sms_settings">
													<SmsSettings />
												</Tab.Pane>
												<Tab.Pane eventKey="assign_telecaller">
													<AssignTelecallersToBucket />
												</Tab.Pane>
												<Tab.Pane eventKey="brands">
													<VehicleBrandsSettings tabRender={tabTrue}/>
												</Tab.Pane>
												<Tab.Pane eventKey="vehicle_types">
													<FinancerVehicleTypes />
												</Tab.Pane>
												{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
													<>
														<Tab.Pane eventKey="notices">
															<UploadNotice defaultLoad={1}/>
														</Tab.Pane>
														<Tab.Pane eventKey="announcement">
															<UploadAnnouncement />
														</Tab.Pane>
													</>
												) : ""}
												<Tab.Pane eventKey="resetpass">
													<ChangePassword />
												</Tab.Pane>
											</Tab.Content>
										</div>
									</div>
								</Tab.Container>
							</div>
						</div>
					</div>
				</div>
			</OrganizationLoanProductsProvider>
		</div>
	);
};

export default FinancerProfile;
