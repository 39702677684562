import React, { useEffect, useRef, useState } from "react";
import { FaSort, FaSortNumericDown, FaSortNumericDownAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const MinMaxFilter = ({ sortArr, setSortArr, id, placeholder, title, setSearchArray, searchArray, clearFilters = false, inputType, customClass = "", futureDate = false, pastDate = false, type = null }) => {
	const [showMinMaxBox, setShowMinMaxBox] = useState(false);
	const [minMaxValue, setMinMaxValue] = useState({});
	const [errorMsg, setErrorMsg] = useState({});
	const [compareValue, setCompareValue] = useState({});
	const ref = useRef(null);

	const bolderLabelsIds = ["total_cases", "new_lead", "login", "under_process", "sanctioned", "disbursed", "rejected", "cibil", "equifax", "crif", "target_collection_amount","target_date", "sales_target_date", "telecaller_target_date", "collection_target_date", "registered_nach"];

	const setSortedArray = (id, val) => {
		setSortArr({ [id]: val });
	};

	const useOutsideAlerter = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			setShowMinMaxBox(false);
			removeEvent();
		}
	};

	const removeEvent = () => {
		document.removeEventListener("click", useOutsideAlerter, true);
	};

	const addEvent = () => {
		document.addEventListener("click", useOutsideAlerter, true);
	};

	const settingMinMaxArray = (type, id, value) => {
		let flag = true;
		let erMsg = "";

		if (type === "min_" && minMaxValue["max_" + id] && value) {
			if (inputType === "datepicker" ? new Date(value) > new Date(minMaxValue["max_" + id]) : parseFloat(value) > parseFloat(minMaxValue["max_" + id])) {
				flag = false;
				erMsg = inputType === "datepicker" ?  "Min Date is greater" : "Min Amount is greater";
			}
		}
		if (type === "max_" && minMaxValue["min_" + id] && value) {
			if (inputType === "datepicker" ? new Date(value) < new Date(minMaxValue["min_" + id]) : parseFloat(value) < parseFloat(minMaxValue["min_" + id])) {
				flag = false;
				erMsg = inputType === "datepicker" ? "Max Date is less" : "Max Amount is less";
			}
		}

		setErrorMsg({ ...errorMsg, [type + id]: erMsg });
		setCompareValue({ ...compareValue, [type + id]: value });

		if (flag) {
			setMinMaxValue({
				...minMaxValue,
				[type + id]: inputType === "datepicker"
				  ? value
						? `${moment(value).format("YYYY-MM-DD")} ${type === "min_" ? "00:00:00" : "23:59:59"}`
						: ""
				  : value
			  });
			  
			setCompareValue(false);
			setErrorMsg({ ...errorMsg, [type + id]: erMsg });
		}
	};

	useEffect(() => {
		if (minMaxValue && Object.keys(minMaxValue).length) {
			const timerId = setTimeout(() => {
				setSearchArray({ ...searchArray, ...minMaxValue });
			}, 1000);
			return () => clearTimeout(timerId);
		}
	}, [minMaxValue]);


	useEffect(() => {
		if (clearFilters) {
			setMinMaxValue({});
		}
	}, [clearFilters]);

	return (
		<div className={`w-100 height-set ${customClass}`}>
			{title ? (
				<div className={bolderLabelsIds.includes(id) ? "bold" : ""}>
					{title}
				</div>
			) : ""}
			<div className="d-flex align-items-center position-relative">
				<div className={sortArr ? "w90" : "w-100"}>
					<input
						type="text"
						readOnly={true}
						className={`form-control ${type == "mis" ? "name-input-field-MIS" : "name-input-field"} mb-1`}
						onClick={() => { setShowMinMaxBox(true); addEvent(); }}
						value={(minMaxValue["min_" + id] ? (inputType === "datepicker" ? moment(minMaxValue["min_" + id]).format("DD MMM") : "min:"+ " " + minMaxValue["min_" + id]) : "") + " " + (minMaxValue["max_" + id] ? (inputType === "datepicker" ? "to" + " " + moment(minMaxValue["max_" + id]).format("DD MMM") : "max:"+ " " + minMaxValue["max_" + id]) : "")}
					/>
				</div>
				{showMinMaxBox && (
					<div className="min-max-box min-max-alt" ref={ref}>
						<div className="position-relative mb-4">
							<label>{placeholder === "Date" ? "From" : "Minimum" + " " + placeholder}</label>
							{inputType === "datepicker" ? (
								<DatePicker
									selected={compareValue["min_" + id] ? new Date(compareValue["min_" + id]) : minMaxValue["min_" + id] ? new Date(minMaxValue["min_" + id]) : null}
									onChange={date => settingMinMaxArray("min_", id, date)}
									dateFormat="dd MMM yyy"
									maxDate={!futureDate ? new Date() : ""}
									minDate={pastDate ? new Date() : ""}
									isClearable={true}
									className="form-control name-input-field mb-0"
									popperPlacement="right" 
								/>
							) : (
								<input
									type="number"
									min={0}
									className="form-control name-input-field mb-0"
									value={compareValue["min_" + id] || minMaxValue["min_" + id] || ""}
									onChange={e => settingMinMaxArray("min_", id, e.target.value)}
								/>
							)}
							{errorMsg["min_" + id] && <span className="error min-max-error">{errorMsg["min_" + id]}</span>}
						</div>
						<div className="position-relative">
							<label>{placeholder === "Date"  ? "To" : "Maximum" + " " + placeholder}</label>
							{inputType === "datepicker" ? (
								<DatePicker
									selected={compareValue["max_" + id] ? new Date(compareValue["max_" + id]) : minMaxValue["max_" + id] ? new Date(minMaxValue["max_" + id]) : null}
									onChange={date => settingMinMaxArray("max_", id, date)}
									dateFormat="dd MMM yyy"
									maxDate={!futureDate ? new Date() : ""}
									minDate={pastDate ? new Date() : ""}
									isClearable={true}
									className="form-control name-input-field mb-0"
									popperPlacement="right"
								/>
							) : (
								<input
									type="number"
									min={0}
									className="form-control name-input-field mb-0"
									value={compareValue["max_" + id] || minMaxValue["max_" + id] || ""}
									onChange={e => settingMinMaxArray("max_", id, e.target.value)}
								/>
							)}
							{errorMsg["max_" + id] && <span className="error min-max-error">{errorMsg["max_" + id]}</span>}
						</div>
					</div>
				)}
				{sortArr && (
					<div className="sortIcon m-0">
						{sortArr[id] === "1" ? (
							<FaSortNumericDown size={16} onClick={() => setSortedArray(id, "2")} />
						) : sortArr[id] === "2" ? (
							<FaSortNumericDownAlt size={16} onClick={() => setSortedArray(id, "")} />
						) : (
							<FaSort size={16} onClick={() => setSortedArray(id, "1")} />
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default MinMaxFilter;
