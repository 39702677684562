import { Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../context/MainState";
import { useNavigate } from "react-router-dom";
import MultiStepFormContainer from "./forms/MultiStepApplyForm/MultiStepFormContainer";
import Step3 from "./forms/MultiStepApplyForm/Step3";
import axios from "axios";
import { toast } from "react-toastify";
import Step2Partial from "./forms/MultiStepApplyForm/Step2Partial";

const ApplyLoanPartial = ({ show, onHide }) => {
	const [formStep, setFormStep] = useState(0);
	const { user, handleApplyModal } = useContext(MainContext);
	const [allFormData, setAllFormData] = useState({});
	const [preventTab, setPreventTab] = useState(false);
	const nextFormStep = () => setFormStep(currentStep => currentStep + 1);
	const prevFormStep = () => setFormStep(currentStep => currentStep - 1);
	const history = useNavigate();
	const setFormData = data => {
		setAllFormData({ ...allFormData, ...data });
	};
	const onSubmit = async fdata => {
		const data = { ...fdata, ...allFormData };
		// const ref_id = searchParams.get("ref_id");
		// if (ref_id) {
		//     data['ref_id'] = ref_id;
		// }
		data["loan_id"] = localStorage.getItem("loan_app_enc_id");
		// setFormIsLoading(true);
		let formData;
		// if(data.loan_type === 'Medical Loan') {
		//     formData = new FormData(form.current);
		//     formData.append('loan_type', 'Medical Loan');
		//     formData.append('bill', bill);
		//     if (ref_id) {
		//         formData('ref_id', ref_id);
		//     }
		// } else {
		formData = data;
		// if (user && user.referral_code) {
		//     formData['ref_id'] = user.referral_code;
		// }
		// }

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/update`,
				formData
			)
			.then(response => {
				// setFormIsLoading(false);
				if (response.data.response.status === 201) {
					// localStorage.setItem('paymentRequested', 'true');
					handleApplyModal(false);
					// handleShow();
					// setPaymentLink(response.data.response.data.payment_url);
					toast.success("Application Submitted");
				} else if (response.data.response.status === 200) {
					localStorage.removeItem("loan_app_enc_id");
					localStorage.removeItem("app_applied_for");
					localStorage.removeItem("vehicle_option");
					localStorage.removeItem("vehicle_type");
					toast.success("Application Submitted");
					handleApplyModal(false);
					if (user.access_token) {
						history("/account/dashboard");
					}
					// reset();
				}
				// callback();
			})
			.catch(error => {
				console.log(error.message);
			});
	};
	useEffect(() => {
		if (
			localStorage.getItem("app_applied_for") === "Medical Loan" ||
			localStorage.getItem("app_applied_for") === "Teacher Loan" ||
			localStorage.getItem("app_applied_for") === "Gold Loan"
		) {
			setFormStep(1);
			setPreventTab(true);
		} else {
			setFormStep(0);
		}
	}, [handleApplyModal]);
	return (
		<Modal show={show} onHide={onHide} size={"xl"} centered>
			<div className="container position-relative">
				<div className="row">
					<div className="col-md-12">
						<div className="bg-white mb-10 rounded-3 p-5">
							<div>
								<div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
									<div className="mb-6 text-center">
										<h1 className="mb-0">Please Fill The Following Details</h1>
									</div>
								</div>
								<MultiStepFormContainer
									maxStep={2}
									currentStep={formStep}
									prevFormStep={prevFormStep}
									preventTabs={preventTab}
								>
									{formStep >= 0 && (
										<Step2Partial
											formStep={formStep}
											nextFormStep={nextFormStep}
											setFormData={setFormData}
										/>
									)}
									{formStep >= 1 && (
										<Step3
											currentStep={1}
											formStep={formStep}
											nextFormStep={nextFormStep}
											setFormData={onSubmit}
										/>
									)}
								</MultiStepFormContainer>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default ApplyLoanPartial;
