import React, { useContext, useEffect, useRef, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import { getFloatNumber } from "../../helpers/customFunctions";
import MultiDataFilters from "../MultiDataFilters";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { MainContext } from "../../context/MainState";
import NameComp from "../NameComp";
import MinMaxFilter from "../MinMaxFilter";

const BranchSalesTable = ({ type, loadDefault }) => {
	const { getBranchesData, branchesReport } = useContext(OrganizationUsersContext);
	const { branches_list, financer_assigned_loan_types} = useContext(OrganizationDashboardContext);
	const {loan_products} = useContext(MainContext);
	const rowsPerPage = 100;
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		loan_id: "",
		field: "",
		order_by: ""
	});
	const [searchArray, setSearchArray] = useState({});
	const [sortArr, setSortArr] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);


	const renderColumnValue = value => {
		return (
			<>
				{type === "by_amount" ? <BiRupee size={16} /> : ""}{" "}
				{parseFloat(value ? value : 0).toLocaleString("en-IN")}
			</>
		);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "180px",
			selector: row => (row.location_name ? row.location_name : "-"),
			sortable: false
		},
		{
			id: "login",
			name: (
				<MinMaxFilter
					title={"Login"}
					id={"login_amount"}
					placeholder="Cases"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.login_amount ? row.login_amount : "-"),
			sortable: false,
			cell: row => {
				return <>{renderColumnValue(row.login_amount)}</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Pending Approval At Branch"}
					id={"login_amount"}
					placeholder="Pending Approval At Branch"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "190px",
			selector: row => (row.login_amount ? row.login_amount : "--"),
			sortable: false,
			cell: () => {
				return <>--</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Branch Approval"}
					id={""}
					placeholder="Branch Approval"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			// selector: row => (row.reporting_person ? row.reporting_person : "--"),
			sortable: false,
			cell: () => {
				return <>--</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Credit Approval Pending"}
					id={""}
					placeholder="Credit Approval Pending"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "175px",
			// selector: row => row.email,
			sortable: false,
			cell: () => {
				return <>--</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Conditional / Soft Sanction"}
					id={"soft_sanctioned_amount"}
					placeholder="Conditional / Soft Sanction"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.soft_sanctioned_amount ? row.soft_sanctioned_amount : "--"),
			sortable: false,
			cell: row => {
				return <>{renderColumnValue(row.soft_sanctioned_amount)}</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursement Approval Pending"}
					id={""}
					placeholder="Disbursement Approval Pending"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "210px",
			sortable: false,
			cell: row => {
				return (
					<>
						{renderColumnValue(
							getFloatNumber(row.soft_sanctioned_amount) -
								getFloatNumber(row.disbursed_approval_amount)
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursement Approval"}
					id={"disbursed_approval_amount"}
					placeholder="Disbursement Approval"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "185px",
			selector: row => (row.disbursed_approval_amount ? row.disbursed_approval_amount : "-"),
			sortable: false,
			cell: row => {
				return <>{renderColumnValue(row.disbursed_approval_amount)}</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursement Pending"}
					id={""}
					placeholder="Disbursement Pending"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.disbursed_amount ? row.disbursed_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{renderColumnValue(
							getFloatNumber(row.disbursed_approval_amount) -
								getFloatNumber(row.disbursed_amount)
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursement"}
					id={"disbursed_amount"}
					placeholder="Disbursement"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "145px",
			selector: row => (row.disbursed_amount ? row.disbursed_amount : "-"),
			sortable: false,
			cell: row => {
				return <>{renderColumnValue(row.disbursed_amount)}</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Insurance & Other Incidental Charges"}
					id={"insurance_charges_amount"}
					placeholder="Insurance & Other Incidental Charges"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "260px",
			omit: type === "by_cases",
			selector: row => (row.insurance_charges_amount ? row.insurance_charges_amount : "-"),
			cell: row => {
				return <>{renderColumnValue(row.insurance_charges_amount)}</>;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Total Disbursement"}
					id={""}
					placeholder="Total Disbursement"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "140px",
			cell: () => {
				return <>--</>;
			}
		},
		{
			name: "Amount Out Of Book",
			sortable: false,
			minWidth: "148px",
			omit: type === "by_cases",
			cell: row => {
				return (
					<>
						{renderColumnValue(
							getFloatNumber(row.disbursed_amount) +
								getFloatNumber(row.insurance_charges_amount)
						)}
					</>
				);
			}
		},
		{
			name: "Net Disbursement",
			minWidth: "140px",
			sortable: false,
			cell: () => {
				return <>--</>;
			}
		},
		{
			name: "Total Rejection",
			minWidth: "140px",
			sortable: false,
			maxWidth: "115px",
			cell: row => {
				return <>{renderColumnValue(row.rejected_amount)}</>;
			}
		},
		{
			name: "Login: DA",
			sortable: false,
			maxWidth: "100px",
			cell: row => {
				return (
					<>
						{(row.disbursed_approval_amount && row.disbursed_approval_amount !== "0") ||
						(row.login_amount && row.login_amount !== "0") ? (
								<>
									{(
										getFloatNumber(row.disbursed_approval_amount) /
									getFloatNumber(row.login_amount)
									).toFixed(2)}
								</>
							) : (
								"--"
							)}
					</>
				);
			}
		},
		{
			name: "Login: Rejection",
			minWidth: "130px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.rejected_amount &&
						row.rejected_amount !== "0" &&
						row.login_amount &&
						row.login_amount !== "0" ? (
								<>
									{(
										getFloatNumber(row.rejected_amount) /
									getFloatNumber(row.login_amount)
									).toFixed(2)}
								</>
							) : (
								"--"
							)}
					</>
				);
			}
		},
		{
			name: "Under Process",
			minWidth: "120px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.rejected_amount &&
						row.rejected_amount !== "0" &&
						row.disbursed_approval_amount &&
						row.disbursed_approval_amount !== "0" ? (
								<>
									{100 -
									getFloatNumber(
										(
											getFloatNumber(row.disbursed_approval_amount) /
											getFloatNumber(row.login_amount)
										).toFixed(2)
									) -
									getFloatNumber(
										(
											getFloatNumber(row.rejected_amount) /
											getFloatNumber(row.login_amount)
										).toFixed(2)
									)}
								%
								</>
							) : (
								"--"
							)}
					</>
				);
			}
		}
	];

	// ** Table data to render
	const dataToRender = () => {

		if (
			branchesReport &&
			branchesReport[type] &&
			branchesReport[type].data &&
			branchesReport[type].data.length > 0
		) {
			return branchesReport[type].data;
		} else if (
			branchesReport &&
			branchesReport[type] &&
			branchesReport[type].data &&
			branchesReport[type].data.length === 0
		) {
			return [];
		} else {
			return branchesReport &&
				branchesReport[type] &&
				branchesReport[type].data &&
				branchesReport[type].data.length > 0
				? branchesReport[type].data.slice(0, rowsPerPage)
				: [];
		}
	};
	const handleSort = async (column, sortDirection) => {
		setFilters({
			...filters,
			field: column.id,
			order_by: sortDirection === "asc" ? 0 : 1
		});
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
		setSortArr({});
	};
	
	useEffect(() => {
		if(branchesReport){
			setPageLoading(false);
		}
	},[branchesReport]);

	const dataLoaded = useRef(false);

	useEffect(() => {
		if (loadDefault && dataLoaded.current) {
			// dataLoaded.current = true;
			if (searchArray && filters.start_date && filters.end_date) {	
				setPageLoading(true);
		
				const timerId = setTimeout(() => {
					getBranchesData({
						type,
						start_date: filters.start_date,
						end_date: filters.end_date,
						product_id: filters.loan_product,
						loan_type_enc_id: filters.loan_product_portfolio,
						branch: filters.branch_name,
						fields_search: searchArray,
						state_enc_id: filters.state_name,
					});
				}, 1000);

				return () => {
					clearTimeout(timerId);
				};
			}

			
		}
	}, [filters, searchArray]);
	useEffect(() => {
		if (loadDefault && !dataLoaded.current) {
			// dataLoaded.current = true;
			if (searchArray && filters.start_date && filters.end_date) {	
				setPageLoading(true);
		
				const timerId = setTimeout(() => {
					dataLoaded.current = true;
					getBranchesData({
						type,
						start_date: filters.start_date,
						end_date: filters.end_date,
						product_id: filters.loan_product,
						loan_type_enc_id: filters.loan_product_portfolio,
						branch: filters.branch_name,
						fields_search: searchArray,
						state_enc_id: filters.state_name,
					});
				}, 1000);

				return () => {
					clearTimeout(timerId);
				};
			}

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}
		}
	}, [filters, searchArray, loadDefault]);

	const getDataApi = (filters) => {
		setFilters(filters);
	};

	return (
		<div className="loan-application-table mb-5">
			<Row className="mt-1 mb-5">
				<Col sm="12">
					<div className="d-flex align-items-center py-2 flex-wrap">
						<MultiDataFilters
							branches_list={branches_list}
							loan_product_portfolio={financer_assigned_loan_types}
							loan_products={loan_products}
							getData={getDataApi}
							type={["LoanProductPortfolio", "LoanProduct", "branches","states"]}
						/>
					</div>
				</Col>
				<Col className="d-flex align-items-center justify-content-end" sm="2">
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button
								className="btn btn-primary btn-xs my-2"
								onClick={e => clearFilter(e)}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				columns={columns}
				className="branchSalesTable fix-overflow report-tables height-fix z-1"
				data={dataToRender()}
				sortServer={true}
				onSort={handleSort}
				progressPending={pageLoading}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
				persistTableHead = {true}
				fixedHeader={true}
			/>
		</div>
	);
};
export default BranchSalesTable;
