import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import { useContext } from "react";
import { MainContext } from "../../context/MainState";
import { Link } from "react-router-dom";
import { OrganizationESignProvider } from "../../context/OrganizationESignState";
import ESignTable from "../../components/dashboard/e-sign/ESignTable";

const OrganizationESign = () => {
	const { user } = useContext(MainContext);
	return (
		<div>
			<Helmet>
				<title>Dashboard</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<OrganizationESignProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className={"d-flex justify-content-between mb-3"}>
							<h1 className="head-title mb-0">Dashboard</h1>
							{user.organization_username &&
							user.organization_username === "phfleasing" ? (
									<Link
										to="/account/e-signing"
										className="btn btn-primary btn-sm d-flex align-items-center"
									>
									Document E-Sign
									</Link>
								) : (
									""
								)}
						</div>
						<div className="shadow-lg rounded-3 py-6 px-6">
							<ESignTable />
						</div>
					</div>
				</div>
			</OrganizationESignProvider>
		</div>
	);
};
export default OrganizationESign;
