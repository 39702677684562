import React, { useContext, useEffect, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import NameComp from "../NameComp";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import MinMaxFilter from "../MinMaxFilter";

const IncentivePointsReportTable = ( ) => {
	const { getIncentivePoints, incentivePoints, isLoading } =
		useContext(OrganizationUsersContext);
	const { branches_list, getOrganizationDepartments, departmentList, states_list,getBranches, getDesignationList, getStates,designationList} = useContext(OrganizationDashboardContext);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [sortArr, setSortArr] = useState({});
	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};
	const columns = [
		{
			name: (
				<NameComp
					title={"Code"}
					id={"employee_code"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.employee_code ? row.employee_code : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"employee_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.employee_name ? row.employee_name : "-"),
			style: { textTransform: "capitalize" },
			sortable: false,			
		},
		{
			name: (
				<MinMaxFilter
					title={"Active Points"}
					id={"active_points"}
					placeholder="active points"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.active_points ? row.active_points : "-"),
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"Pending Points"}
					id={"pending_points"}
					placeholder="pending points"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.pending_points ? row.pending_points : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Department"}
					id={"department"}
					departmentList={departmentList}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.department ? row.department : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					designations={designationList}
				/>
			),
			minWidth: "200px",
			selector: row => (row.designation ? row.designation : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Reporting Person"}
					id={"reporting_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.reporting_person ? row.reporting_person : "-"),
			style: { textTransform: "capitalize" }
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			style: { textTransform: "capitalize" },
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state_name ? row.state_name: "-"),
			sortable: false
		},
	];
		// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getIncentivePoints({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
		});
		setCurrentPage(page.selected + 1);
	};
	
	// ** Function to handle per page
	const handlePerPage = e => {
		getIncentivePoints({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			fields_sort: sortArr,
		});
		setRowsPerPage(parseInt(e.target.value));
	};
	useEffect(() => {
		getIncentivePoints({
			page: 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
		});
		setCurrentPage(1);
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray,sortArr]);	

	useEffect(() => {
		if (!branches_list) {
			getBranches();
		}
		if(!states_list){
			getStates();
		}
		if(!designationList){
			getDesignationList();
		}
	}, []);
	// ** Custom Pagination
	const CustomPagination = () => {
		let totalPageCount = parseInt(incentivePoints?.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};
	
	useEffect(() => {
		if (!departmentList || !departmentList.length) {
			getOrganizationDepartments();
		}
	}, []);
	
	const dataToRender = () => {
		if (incentivePoints?.data && incentivePoints?.data.length > 0) {
			return incentivePoints.data;
		} else {
			return incentivePoints?.data && incentivePoints?.data.length > 0
				? incentivePoints.data.slice(0, rowsPerPage)
				: [];
		}
	};
		
	
	return (
		<>
			<div className="loan-application-table">
				<div className="dashboard-dsa loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
					<div className="px-3 py-3">
						<h2 className="m-0 text-capitalize">Incentive Points Report</h2>
					</div>
					<Row className="mx-0 mt-1 mb-3">
						<Col sm="9">
							<div className="d-flex align-items-center justify-content-start mb-3">
								<label htmlFor="sort-select">Show</label>
								<select
									className="dataTable-select form-select loan_acc_select"
									style={{
										width: "65px",
										padding: "2px 8px",
										margin: "0px 10px"
									}}
									id="sort-select"
									value={rowsPerPage}
									onChange={e => handlePerPage(e)}
								>
									<option value={10}>10</option>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={75}>75</option>
									<option value={100}>100</option>
								</select>
								<label htmlFor="sort-select">Results</label>
							</div>
						</Col>
						<Col
							className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
							sm="3"
						>
							<div className="reset-filters">
								<div className="filters-container d-flex justify-content-end">
									{showClearBtn ? (
										<button
											className="btn btn-primary btn-xs my-2"
											onClick={e => clearFilter(e)}
										>
									Reset
										</button>
									) : (
										""
									)}
								</div>
							</div>
						</Col>
					</Row>
					<DataTable
						columns={columns}
						data={dataToRender()}
						pagination
						paginationServer
						paginationComponent={CustomPagination}
						highlightOnHover="true"
						persistTableHead={true}
						className="fix-overflow myUser-table"
						progressPending={isLoading}
						progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
						noDataComponent={
							<p className="w-100 m-5 text-start">There are no records to display</p>
						}
					/>
				</div>
			</div>
		</>
	);
};

	
export default IncentivePointsReportTable;
