import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { MainContext } from "../../context/MainState";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import EmiCashListSidebar from "./EmiCashListSidebar";
import { BiRupee } from "react-icons/bi";
import NameComp from "../NameComp";
import { useNavigate } from "react-router-dom";
import { FaMobileAlt } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import MultiDataFilters from "../MultiDataFilters";

const EmiColletionReportsTable = () => {
	const {
		getEmiCashCollectionData,
		emiCashCollectionData,
		branches_list,
		getBranches,
		getStates, 
		states_list,
		getDesignationList,
		designationList
	} = useContext(OrganizationDashboardContext);
	const { user, loanAccountTypes} = useContext(MainContext);
	const history = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [emiNameDetails, openSideBarDetails] = useState("");
	const [allStatsData, setAllStatsData] = useState("");
	const [clearFilters, setClearFilters] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [filters, setFilters] = useState({
		loan_type: "",
		fields_search: "",
		order_by: "",
		branch_id: ""
	});

	const [sortArr, setSortArr] = useState({});
	const [searchArray, setSearchArray] = useState({});

	const reload = () => {
		getEmiCashCollectionData({
			page: currentPage,
			limit: rowsPerPage,
			// keyword: searchValue,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			order_by: filters.order_by,
			branch_id: filters.branch_name
		});
	};
	
	const getCollectionReportStats = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/cash-report-stats`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setAllStatsData(response.data.response.data);
				} else {
					setAllStatsData([]);
				}
			})
			.catch(error => {
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
				toast.error("An error has occured while getting collection report stats. Please try again");
			});
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Employee Code"}
					id={"employee_code"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "120px",
			selector: row => (row.employee_code ? row.employee_code : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => openSideBarDetails(row)}
					>
						{row.employee_code ? row.employee_code : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
				/>
			),
			minWidth: "160px",
			selector: row => (row.name ? row.name : "-"),
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => openSideBarDetails(row)}
					>
						{row.name ? row.name : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Cash In Hand"}
					id={"collected_cash"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize">
						<BiRupee size={16} />{" "}
						{parseFloat(
							parseFloat(row.collected_cash) + parseFloat(row.received_cash)
						).toLocaleString("en-IN")}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Cash Transferred"}
					id={"transfered_cash"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize">
						<BiRupee size={16} />{" "}
						{row.received_pending_cash
							? parseFloat(row.received_pending_cash).toLocaleString("en-IN")
							: "0"}
					</h6>
				);
			}
		},

		{
			name: (
				<NameComp
					title={"Unverified Cash"}
					id={"unverified_cash"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						<BiRupee size={16} />{" "}
						{parseFloat(row.bank_unapproved_cash).toLocaleString("en-IN")}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					designations={designationList}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.designation ? row.designation : "-"),
		},

		{
			name: (
				<NameComp
					title={"Reporting Person"}
					id={"reporting_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.reporting_person ? row.reporting_person : "-")
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "240px",
			selector: row => (row.location_name ? row.location_name : "-"),
			sortable: false
		},{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Phone"}
					id={"phone"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			cell: row => {
				return (
					<a href={`tel: ${row.phone}`} className="mb-0 text-capitalize text-primary">
						<FaMobileAlt /> {row.phone}
					</a>
				);
			},
			selector: row => row.phone
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getEmiCashCollectionData({
			page: page.selected + 1,
			limit: rowsPerPage,
			// keyword: searchValue,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			fields_sort: sortArr,
			order_by: filters.order_by,
			branch_id: filters.branch_name
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getEmiCashCollectionData({
			page: 1,
			limit: parseInt(e.target.value),
			// keyword: searchValue,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			fields_sort: sortArr,
			order_by: filters.order_by,
			branch_id: filters.branch_name
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(emiCashCollectionData.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		if (
			emiCashCollectionData &&
			emiCashCollectionData.data &&
			emiCashCollectionData.data.length > 0
		) {
			return emiCashCollectionData.data;
		} else {
			return emiCashCollectionData &&
				emiCashCollectionData.data &&
				emiCashCollectionData.data.length > 0
				? emiCashCollectionData.data.slice(0, rowsPerPage)
				: [];
		}
	};

	const handleSort = async (column, sortDirection) => {
		setFilters({
			...filters,
			fields_search: column.id,
			order_by: sortDirection === "asc" ? 0 : 1
		});
	};

	useEffect(()=>{
		if(emiCashCollectionData){
			setPageLoading(false);
		}
	},[emiCashCollectionData]);

	useEffect(() => {
		if (
			!(
				user.user_type === "Financer" ||
				["phf110", "KKB", "phf604", "ghuman"].includes(user.username)
			)
		) {
			history("/account/dashboard");
		}
		if (!branches_list) {
			getBranches();
		}
		if (!states_list || !states_list.length) {
			getStates();
		}
		if(!designationList){
			getDesignationList();
		}
		getCollectionReportStats();
	}, []);

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
	};

	useEffect(() => {
		if (searchArray) {
			const timerId = setTimeout(() => {
				setClearFilters(false);
				setCurrentPage(1);
				setPageLoading(true);
				getEmiCashCollectionData({
					page: 1,
					limit: rowsPerPage,
					// keyword: searchValue,
					loan_type: filters.loan_type,
					fields_search: searchArray,
					fields_sort: sortArr,
					order_by: filters.order_by,
					branch_id: filters.branch_id
				});
			}, [1000]);
			
			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setShowClearBtn(true);
			}

			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArray, sortArr, filters]);

	const getDataApi = (filters) => {
		setFilters({...searchArray, ...filters});
	};

	return (
		<>
			<div className="row dsa-stats">
				<div className="col-lg-4 col-xl-2 col-sm-6 col-12">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/emi-stats-icons/Cash.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mt-1 mb-0 fs-4">
							₹{" "}
							{allStatsData.collected_cash
								? parseFloat(allStatsData.collected_cash).toLocaleString("en-IN")
								: "0"}
						</p>
						<p className="card-head mt-2 mb-0 fs-5">
							<span>Cash in Hand</span>
						</p>
					</div>
				</div>
				<div className="col-lg-4 col-xl-2 col-sm-6 col-12">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/emi-stats-icons/Collected.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mt-1 mb-0 fs-4">
							₹{" "}
							{allStatsData.received_pending_cash
								? parseFloat(allStatsData.received_pending_cash).toLocaleString(
									"en-IN"
								  )
								: "0"}
						</p>
						<p className="card-head mt-2 mb-0 fs-5">
							<span>Cash Transferred</span>
						</p>
					</div>
				</div>
				<div className="col-lg-4 col-xl-2 col-sm-6 col-12">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/emi-stats-icons/Cheque.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mt-1 mb-0 fs-4">
							₹{" "}
							{allStatsData.bank_unapproved_cash
								? parseFloat(allStatsData.bank_unapproved_cash).toLocaleString(
									"en-IN"
								  )
								: "0"}
						</p>
						<p className="card-head mt-2 mb-0 fs-5">
							<span>Unverified Cash</span>
						</p>
					</div>
				</div>
			</div>
			<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 bg-white">
				<div className="loan-application-table mb-5">
					<div className="salesRep px-3">
						<h4 className="mb-0 text-capitalize">Cash In Hand Report</h4>
					</div>
					<Row className="mx-0 mt-1 mb-5">
						<Col sm="6">
							<div className="d-flex align-items-center py-2 flex-wrap">
								<div className="form-group" style={{marginRight: "10px"}}>
									<label htmlFor="float-text" className="floating-label fw400 ft14 fs-5">
										Show Results
									</label>
									<select
										className="dataTable-select form-select"
										style={{ width: "95px", padding: "2px 8px", height: "48px" }}
										id="sort-select"
										value={rowsPerPage}
										onChange={e => handlePerPage(e)}
									>
										<option value={10}>10</option>
										<option value={25}>25</option>
										<option value={50}>50</option>
										<option value={75}>75</option>
										<option value={100}>100</option>
									</select>
								</div>
								<MultiDataFilters
									branches_list={branches_list}
									loan_types={loanAccountTypes}
									getData={getDataApi}
									type={["loanAccountsType"]}
									dateRange={false}
								/>
							</div>
						</Col>
						<Col sm="6">
							<div className="filters-container d-flex justify-content-end">
								{showClearBtn ? (
									<button className="btn btn-primary btn-xs my-2" onClick={(e) => clearFilter(e)}>Reset</button>
								): ""}
							</div>
						</Col>
					</Row>
					<DataTable
						noHeader
						pagination
						paginationServer
						columns={columns}
						data={dataToRender()}
						sortServer={true}
						onSort={handleSort}
						paginationComponent={CustomPagination}
						progressPending={pageLoading}
						highlightOnHover="true"
						persistTableHead={true}
						progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
						noDataComponent={
							<p className="w-100 m-5 text-start">There are no records to display</p>
						}
						className=" fix-overflow cash_table"
					/>

					<EmiCashListSidebar
						setShowsidebar={() => openSideBarDetails("")}
						emiNameDetails={emiNameDetails}
						reload={reload}
					/>
				</div>
			</div>
		</>
	);
};
export default EmiColletionReportsTable;
