import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { MainContext } from "../../context/MainState";
import { readOnlyUsers } from "../../helpers/authServices";

const CollectLoginFeeManually = ({
	loanDetails,
	feeStructures,
	reload,
	callback = () => {
		return true;
	}
}) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm();
	const [image, setImage] = useState(null);

	const options = [
		{ value: "0", label: "Online Payment" },
		{ value: "1", label: "NEFT" },
		{ value: "2", label: "RTGS" },
		{ value: "3", label: "IMPS" },
		{ value: "4", label: "Cheque" },
		{ value: "5", label: "UPI" },
		{ value: "6", label: "DD" },
		{ value: "7", label: "Cash" },
		{ value: "8", label: "Credit Card" },
		{ value: "9", label: "Debit Card" }
	];
	const handleFileChange = e => {
		setImage(e.target.files[0]);
	};

	const onSubmit = async data => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		data.amount = data.amount ? data.amount.filter(el => el) : [];
		if (!data.amount.length) {
			toast.error("Please select amount");
			return false;
		}
		const formData = new FormData();
		formData.append("payment_mode", parseInt(data.payment_mode.value));
		formData.append("loan_app_id", loanDetails.loan_app_enc_id);
		formData.append("image", image);
		formData.append("remarks", data.remarks);
		data.amount.map((value, index) => {
			formData.append(`amount[${index}]`, data.amount[index]);
		});
		["reference_number"].forEach(e => {
			formData.append(e, data[e]);
			delete data[e];
		});
		handlePageLoaded(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}payments/get-manual-payment`,
				formData
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Payment collected successfully");
					reset();
					callback(false);
					reload(loanDetails.loan_app_enc_id);
				} else {
					console.log("An error has occurred, Please try again");
				}
				handlePageLoaded(false);
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="form">
			<fieldset>
				<div className="row">
					<div className="col-md-6">
						<label className="form-label d-block titles">Payment Mode</label>
						<Controller
							control={control}
							name="payment_mode"
							rules={{ required: "This is required." }}
							render={({ field }) => (
								<Select
									{...field}
									options={options}
									className="customStyleSelect "
									isClearable
									placeholder="Select"
								/>
							)}
						/>
						<ErrorMessage
							errors={errors}
							name="payment_mode"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
					<div className="col-md-6">
						<label className="form-label d-block titles">Reference Number</label>
						<Controller
							control={control}
							name="reference_number"
							rules={{
								required: "This is required."
							}}
							render={({ field }) => (
								<input type="text" className="form-control" {...field} />
							)}
						/>
						<ErrorMessage
							errors={errors}
							name="reference_number"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-lg-12">
						<label className="form-label mb-0" htmlFor="">
							Fee Structure
						</label>
					</div>
					<div className="radio-wrapper">
						{feeStructures.length
							? feeStructures.map((fee, feeIndex) => (
								<div className="radio-item mb-2" key={`limit-${feeIndex}`}>
									<input
										type="checkbox"
										value={
											fee.financer_loan_product_login_fee_structure_enc_id
										}
										className="radioInput"
										name="payment_method"
										id={`payment-id-${feeIndex}`}
										{...register(`amount[${feeIndex}]`)}
									/>
									<label
										htmlFor={`payment-id-${feeIndex}`}
										className="option option-1"
									>
										<span>
											{fee.name} ₹{" "}
											{fee.amount
												? parseFloat(fee.amount).toLocaleString("en-IN")
												: ""}
										</span>
									</label>
								</div>
							  ))
							: "No fee Structure Available"}
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<label className="form-label d-block col-sm titles">Receipt</label>
						<input
							type="file"
							name="receipt"
							className="form-control"
							{...register("receipt", {
								required: "This is required",
								validate: {
									fileType: value => {
										if (value && value[0]) {
											const acceptedExtensions =
												/\.(doc|docx|png|jpg|jpeg|pdf|xls|xlsx)$/i;
											if (!acceptedExtensions.test(value[0].name)) {
												return "Invalid file type. Only .doc, .docx, .png, .jpg, .jpeg, .pdf, .xls, .xlsx files are allowed.";
											}
										}

										return true;
									}
								}
							})}
							onChange={e => handleFileChange(e)}
						/>
						<ErrorMessage
							errors={errors}
							name="receipt"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<label className="form-label d-block titles">Remarks</label>
						<textarea
							className="form-control"
							{...register("remarks", {
								required: false
							})}
						/>
					</div>
				</div>
				<div className="button_div text-center mt-3">
					<button type="submit" className="btn btn-primary btns">
						Collect Now
					</button>
				</div>
			</fieldset>
		</form>
	);
};

export default CollectLoginFeeManually;
