import "../../assets/css/formstyles.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsFillCameraFill } from "react-icons/bs";
import { FaUpload } from "react-icons/fa";
import React, { useState } from "react";
import Compressor from "compressorjs";

export default function EmiCollectionNewStep3({
	user,
	formStep,
	setFormData,
	fullFormData,
	isLoading
}) {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [commentShow, setCommentShow] = useState(false);
	const [prImg, setPrImg] = useState({ image: "", name: "", compressedImage: null });
	const [borrowerImage, setBorrowerImage] = useState({ image: "", name: "", compressedImage: "" });
	const [locImage, setLocImage] = useState({ image: "", name: "", compressedImage: "" });

	const compressImage = (e, setImageState) => {
		if (e.target.files.length) {
			const selectedImage = e.target.files[0];
			setImageState({
				image: selectedImage,
				name: selectedImage.name
			});

			new Compressor(selectedImage, {
				quality: 0.6,
				success: res => {
					setImageState(prevState => ({
						...prevState,
						compressedImage: res
					}));
				}
			});
		}
	};

	const onSubmit = values => {
		let formData = new FormData();
		delete values.borrowerImage;
		delete values.receipt_image;
		delete values.otherDocImage;
		Object.entries(values).map(([key, value]) => {
			formData.append(key, value);
		});
		formData.append("pr_receipt_image", prImg.compressedImage);
		formData.append("borrower_image", borrowerImage.compressedImage);
		formData.append("other_doc_image", locImage.compressedImage);
		formData.append("brand", user.organization_name);
		setFormData(formData);
		// setCommentShow(false);
		// setPrImg({ image: "", name: "" });
		// setBorrowerImage({ image: "", name: "" });
		// setLocImage({ image: "", name: "" });
	};
	return (
		<div className={formStep === 2 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">

					{fullFormData.customer_interaction !== "On Call" ? (
						<>
							<div className="col-md-6 col-12 mb-3">
								<label className="form-label">
									Photo With The Borrower{" "}
									{!(
										fullFormData.branch_enc_id ===
										"yVgawN7rxoLL9A10jpnYoYOM5kelbv" &&
										(user.designation === "Tele Sales Executive" ||
											user.designation === "Tele Caller Collection")
									) ? (
											<span style={{ color: "red" }}>*</span>
										) : ""
									}
								</label>
								<label
									className="form-control text-center"
									style={{
										backgroundColor: "#00a0e3",
										color: "#fff",
										cursor: "pointer"
									}}
									htmlFor="borrower_image"
								>
									<BsFillCameraFill /> Capture Image
								</label>
								<input
									id="borrower_image"
									accept="image/*"
									placeholder="Capture Image"
									className="form-control d-none"
									{...register("borrowerImage", {
										required: !(
											fullFormData.branch_enc_id ===
											"yVgawN7rxoLL9A10jpnYoYOM5kelbv" &&
											(user.designation === "Tele Sales Executive" ||
												user.designation === "Tele Caller Collection")
										)
											? "This is required."
											: false
									})}
									type="file"
									capture="environment"
									onChange={e => compressImage(e, setBorrowerImage)}
								/>
								{borrowerImage.name ? (
									<p
										style={{
											marginBottom: "0px",
											fontSize: "14px",
											color: "#000"
										}}
									>
										{borrowerImage.name}{" "}
									</p>
								) : (
									""
								)}
								<ErrorMessage
									errors={errors}
									name="borrowerImage"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</>
					) : (
						""
					)}
					<div className="col-md-6 col-12 mb-4">
						<label className="form-label">
							PR Receipt Photo
						</label>
						<label
							className="form-control text-center"
							style={{
								backgroundColor: "#00a0e3",
								color: "#fff",
								cursor: "pointer"
							}}
							htmlFor="receipt_image"
						>
							<BsFillCameraFill /> Capture Image
						</label>
						<input
							id="receipt_image"
							accept="image/*"
							placeholder="Capture Image"
							className="form-control d-none"
							{...register("receipt_image", {
								required: false
							})}
							type="file"
							capture="environment"
							onChange={e => compressImage(e, setPrImg)}
						/>
						{prImg.name ? (
							<p
								style={{
									marginBottom: "0px",
									fontSize: "14px",
									color: "#000"
								}}
							>
								{prImg.name}{" "}
							</p>
						) : (
							""
						)}
						<ErrorMessage
							errors={errors}
							name="receipt_image"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>

					<div className="col-md-6 col-12 mb-3">
						<label className="form-label">Other Document</label>
						<div className="d-flex align-items-center">
							<label
								className="form-control w-auto text-center"
								style={{
									backgroundColor: "#00a0e3",
									color: "#fff",
									cursor: "pointer"
								}}
								htmlFor="locationimage"
							>
								<BsFillCameraFill /> Capture Image
							</label>
							<input
								id="locationimage"
								accept="image/*"
								placeholder="Capture Image"
								className="form-control d-none"
								{...register("otherDocImage")}
								type="file"
								capture="environment"
								onChange={e => compressImage(e, setLocImage)}
							/>
							&nbsp;OR&nbsp;
							<label
								className="form-control w-auto text-center"
								style={{
									backgroundColor: "#00a0e3",
									color: "#fff",
									cursor: "pointer"
								}}
								htmlFor="locationimage2"
							>
								<FaUpload /> Upload Document
							</label>
							<input
								id="locationimage2"
								placeholder="Capture Image"
								className="form-control d-none"
								{...register("otherDocImage")}
								type="file"
								onChange={e => compressImage(e, setLocImage)}
							/>
						</div>
						{locImage.name ? (
							<p
								style={{
									marginBottom: "0px",
									marginTop: "10px",
									fontSize: "14px",
									color: "#000"
								}}
							>
								{locImage.name}{" "}
							</p>
						) : (
							""
						)}
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12 mb-5">
						<button
							type="button"
							className="btn btn-primary btn-sm mb-2"
							onClick={() => {
								setCommentShow(prevCommentShow => !prevCommentShow);
							}}
						>
							Add Comments{" "}
							{!commentShow ? (
								<AiOutlinePlus style={{ marginTop: "-3px" }} />
							) : (
								<AiOutlineMinus style={{ marginTop: "-3px" }} />
							)}
						</button>
						{commentShow ? (
							<textarea
								className="form-control"
								placeholder="Enter you comments"
								{...register("comments", {})}
							></textarea>
						) : (
							""
						)}
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12 text-center">
						{isLoading ? (
							<button type="button" className="btn btn-primary" disabled={true}>
								<img
									src="/images/pages/home/loader.svg"
									alt={process.env.REACT_APP_NAME}
								/>
							</button>
						) : (
							<button type="submit" className="btn btn-primary">
								Save
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
