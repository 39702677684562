import "../../../assets/css/formstyles.css";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { toast } from "react-toastify";

export default function F({ formStep, setFormData, nextFormStep }) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm();
	const [allVehicleList, setAllVehicleList] = useState([]);
	const [selectedVal, setSelectedVal] = useState("~");
	const [selectedDate, setSelectedDate] = useState("~");
	const [selectedBrands, setSelectedbrands] = useState([]);
	const [brandsList, setBrandsList] = useState([]);
	const [vehicleTypeValues, setVehicleTypeValues] = useState([]);


	const selectedDealerType = watch("dealer_type");

	const getVehicleBrand = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-financer-vehicle-brand`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setAllVehicleList(response.data.response.financer_list);
				} else {
					setAllVehicleList([]);
				}
			})
			.catch(error => {
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
				toast.error(
					"An error has occurred while getting Vehicle Brands, Please try again"
				);
			});
	};

	const brandOptions =
		brandsList && brandsList.length
			? brandsList.map(vehicle => ({
				value: vehicle.financer_vehicle_brand_enc_id,
				label: (
					<div>
						<img
							src={vehicle.logo}
							alt="logo"
							className="img-fluid brand-img-label"
						/>
						{vehicle.brand_name}
					</div>
				)
			  }))
			: "";

	const handleCheck = (e, val) => {
		const isChecked = e.target.checked;

		if(isChecked){
			setVehicleTypeValues(prevValues => [...prevValues, val]);
		}else{
			setVehicleTypeValues(prevValues => prevValues.filter(item => item !== val));
		}

		setBrandsList(prevBrandsList => {
			if (isChecked) {
				const tempList = allVehicleList.filter(
					value => value.financer_vehicle_type_enc_id === val
				);
				return [...prevBrandsList, ...tempList[0].assignedVehicleTypes];
			} else {
				return prevBrandsList.filter(
					brand =>
						!allVehicleList
							.find(value => value.financer_vehicle_type_enc_id === val)
							.assignedVehicleTypes.includes(brand)
				);
			}
		});
	};

	const selectedBrand = selectedOption => {
		let brandsData = [];
		if (selectedOption) {
			selectedOption.map(value => {
				brandsData.push(value.value);
			});
		}
		setSelectedbrands(brandsData);
	};

	const onSubmit = values => {
		// const vehicleArray = JSON.stringify(vehicleTypeValues);
		// const brandArray = JSON.stringify(selectedBrands);
		if(vehicleTypeValues){
			delete values["vehicle_type"];
			vehicleTypeValues.forEach(function (item, ind) {
				values[`vehicle_type[${ind}]`] = item;
			});
		}
		
		if (selectedBrands) {
			values.brands = selectedBrands;
		}
		if (selectedVal !== "~") {
			values["dealership_date"] = moment(selectedVal).format("MMM yyyy");
		}
		if (selectedDate !== "~") {
			values["since_with_financer"] = moment(selectedDate).format("MMM yyyy");
		}
		setFormData(values);
		nextFormStep();
	};

	const renderYearContent = year => {
		const tooltipText = `Tooltip for year: ${year}`;
		return <span title={tooltipText}>{year}</span>;
	};

	useEffect(() => {
		getVehicleBrand();
	}, []);
	return (
		<div className={formStep === 1 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="col-12">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-12 dealer-category mt-3">
							<h4>
								Dealer Type{" "}
								<span style={{ color: "red", fontSize: "13px" }}>*</span>
							</h4>
							<select
								className="form-select"
								style={{ width: "100%" }}
								{...register("dealer_type", {
									required: "This is required."
								})}
							>
								<option value="">Choose Dealer Type</option>
								<option value="vehicle">Vehicle</option>
								<option value="electronics">Electronics</option>
							</select>
							<ErrorMessage
								errors={errors}
								name="dealer_type"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<div
							className="col-12 dealer-products mt-3"
							style={{ display: selectedDealerType === "vehicle" ? "block" : "none" }}
						>
							<h4>Vehicle Types</h4>
							{allVehicleList && allVehicleList.length ? (
								<div className="radio-wrapper">
									{allVehicleList.map((value, index) => {
										return (
											<div
												className="radio-item"
												key={`vehicle-type-${index}`}
											>
												<input
													type="checkbox"
													name={`vehicle_type[${index}]`}
													className="radioInput"
													id={value.vehicle_type}
													value={value.financer_vehicle_type_enc_id}
													onChange={e => {
														handleCheck(
															e,
															value.financer_vehicle_type_enc_id
														);
													}}
												/>
												<label
													htmlFor={value.vehicle_type}
													className="option option-1 mb-2"
												>
													<img
														src={value.icon}
														alt=""
														className="brand-img-label"
													/>
													<span>{value.vehicle_type}</span>
												</label>
											</div>
										);
									})}
								</div>
							) : (
								"Vehicle types not added!"
							)}
						</div>

						{brandsList && brandsList.length ? (
							<div
								className="col-12 dealer-brands mt-3"
								style={{
									display: selectedDealerType === "vehicle" ? "block" : "none"
								}}
							>
								<h4>Brands</h4>
								{allVehicleList && allVehicleList.length ? (
									<div className="w-100">
										<Select
											isMulti={true}
											options={brandOptions}
											placeholder={"Select Brand"}
											// {...register("brands")}
											onChange={selectedBrand}
										/>
									</div>
								) : (
									"Brands not added!"
								)}
							</div>
						) : (
							""
						)}

						<div className="col-12 dealer-category mt-3">
							<h4>
								Category <span style={{ color: "red", fontSize: "13px" }}>*</span>
							</h4>
							<div className="radio-wrapper">
								<div className="radio-item">
									<input
										type="radio"
										name="category"
										className="radioInput"
										id="category_1"
										value="Distributor"
										{...register("category", {
											required: "this is required."
										})}
									/>
									<label htmlFor="category_1" className="option option-1 mb-2">
										<span>Distributor</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="category"
										className="radioInput"
										id="category_2"
										value="Main Dealer"
										{...register("category", {
											required: "this is required."
										})}
									/>
									<label htmlFor="category_2" className="option option-1 mb-2">
										<span>Main Dealer</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="category"
										className="radioInput"
										id="category_3"
										value="Sub Dealer"
										{...register("category", {
											required: "this is required."
										})}
									/>
									<label htmlFor="category_3" className="option option-1 mb-2">
										<span>Sub Dealer</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="category"
										className="radioInput"
										id="category_4"
										value="Channel Dealer"
										{...register("category", {
											required: "This is required."
										})}
									/>
									<label htmlFor="category_4" className="option option-1 mb-2">
										<span>Channel Dealer</span>
									</label>
								</div>
								<ErrorMessage
									errors={errors}
									name="category"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>

						<div className="col-12 dealer-trade mt-3">
							<h4>
								Trade Certificate{" "}
								<span style={{ color: "red", fontSize: "13px" }}>*</span>
							</h4>
							<div className="radio-wrapper">
								<div className="radio-item mb-2">
									<input
										type="radio"
										value="yes"
										className="radioInput"
										name="trade"
										id={"trade-y"}
										{...register("trade_certificate", {
											required: "This is required."
										})}
									/>
									<label htmlFor={"trade-y"} className="option option-1">
										<span>Yes</span>
									</label>
								</div>
								<div className="radio-item mb-2">
									<input
										type="radio"
										value="No"
										className="radioInput"
										name="trade"
										id={"trade-n"}
										{...register("trade_certificate", {
											required: "This is required."
										})}
									/>
									<label htmlFor={"trade-n"} className="option option-1">
										<span>No</span>
									</label>
								</div>
							</div>
							<ErrorMessage
								errors={errors}
								name="trade_certificate"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3"
							style={{ display: selectedTradeCertificate === "yes" ? "block" : "none" }}
						>
							<h4>Trade Certificate Number</h4>
							<input
								placeholder="Trade Certificate Number"
								className="form-control"
								{...register("tc_number", {
									required: false
								})}
							/>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3"
							style={{ display: selectedTradeCertificate === "yes" ? "block" : "none" }}
						>
							<h4>Upload Trade Certificate</h4>
							<div className="d-flex align-items-center">
								<label
									className="form-control w-auto text-center"
									style={{
										backgroundColor: "#00a0e3",
										color: "#fff",
										cursor: "pointer"
									}}
									htmlFor="locationimage"
								>
									<BsFillCameraFill /> Upload
								</label>
								<input
									id="locationimage"
									placeholder="upload Image"
									className="form-control d-none"
									{...register("trade_certificate_img", {
										required: false
									})}
									type="file"
									onChange={e => compressImage(e, setUploadTc)}
								/>
							</div>
							{uploadTc.name ? (
								<p
									style={{
										marginBottom: "0px",
										marginTop: "10px",
										fontSize: "14px",
										color: "#000"
									}}
								>
									{uploadTc.name}{" "}
								</p>
							) : (
								""
							)}
						</div> */}
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 dealer-trade mt-3">
							<h4>
								Financing Facility{" "}
								<span style={{ color: "red", fontSize: "13px" }}>*</span>
							</h4>
							<div className="radio-wrapper">
								<div className="radio-item mb-2">
									<input
										type="radio"
										value="1"
										className="radioInput"
										name="financing_facility"
										id={"financing-ta"}
										{...register("financing_facility", {
											required: "This is required."
										})}
									/>
									<label htmlFor={"financing-ta"} className="option option-1">
										<span>TA</span>
									</label>
								</div>
								<div className="radio-item mb-2">
									<input
										type="radio"
										value="0"
										className="radioInput"
										name="financing_facility"
										id={"financing-nonta"}
										{...register("financing_facility", {
											required: "This is required."
										})}
									/>
									<label htmlFor={"financing-nonta"} className="option option-1">
										<span>Non TA</span>
									</label>
								</div>
							</div>
							<ErrorMessage
								errors={errors}
								name="financing_facility"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 dealer-trade mt-3">
							<h4>
								Agreement Status{" "}
								<span style={{ color: "red", fontSize: "13px" }}>*</span>
							</h4>
							<div className="radio-wrapper">
								<div className="radio-item mb-2">
									<input
										type="radio"
										value="1"
										className="radioInput"
										name="agreement_status"
										id={"status_signed"}
										{...register("agreement_status", {
											required: "This is required."
										})}
									/>
									<label htmlFor={"status_signed"} className="option option-1">
										<span>Signed</span>
									</label>
								</div>
								<div className="radio-item mb-2">
									<input
										type="radio"
										value="0"
										className="radioInput"
										name="agreement_status"
										id={"status_unsigned"}
										{...register("agreement_status", {
											required: "This is required."
										})}
									/>
									<label htmlFor={"status_unsigned"} className="option option-1">
										<span>Not Signed</span>
									</label>
								</div>
							</div>
							<ErrorMessage
								errors={errors}
								name="agreement_status"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>

						<div className="col-lg-6 col-md-6 col-sm-6 col-12 dealer-category mt-3">
							<h4>Business Since With PHF</h4>
							<ReactDatePicker
								selected={selectedDate !== "~" ? selectedDate : ""}
								onChange={date => {
									setSelectedDate(date);
								}}
								renderYearContent={renderYearContent}
								dateFormat="MMM yyyy"
								showMonthYearPicker
								dropdownMode="select"
								isClearable={selectedDate !== "~"}
								className={"form-control"}
								maxDate={moment().toDate()}
							/>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-12 dealer-category mt-3">
							<h4>Opening Date of Dealership</h4>
							<ReactDatePicker
								selected={selectedVal !== "~" ? selectedVal : ""}
								onChange={date => setSelectedVal(date)}
								dateFormat="MMM yyyy"
								showMonthYearPicker
								dropdownMode="select"
								className={"form-control"}
								isClearable={selectedVal !== "~"}
								maxDate={moment().toDate()}
							/>
						</div>

						<div className="col-12 mt-3 text-center">
							<div className="mb-3">
								<button type="submit" className="btn btn-primary">
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
