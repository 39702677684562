import axios from "axios";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import AutoComplete from "../forms/AutoComplete";
import { ErrorMessage } from "@hookform/error-message";
import { useState, useEffect, useContext } from "react";
import Compressor from "compressorjs";
import { MainContext } from "../../context/MainState";

const ApproveCashAmountModal = ({ emiListData, setCashModal, reload, openSideBar, cashData }) => {
	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
		setValue
		// clearErrors
	} = useForm();
	const { handlePageLoaded } = useContext(MainContext);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [compressedFile, setCompressedFile] = useState("");
	const [sumSelectedAmounts, setSumSelectedAmounts] = useState(0);
	const depositTo = watch("type");
	let base_uri2 = "";

	const compressImage = input => {
		if (!input.target.files) {
			return false;
		}
		const file = input.target.files[0];
		const fileExtenstion = file.name.split(".").pop().toLowerCase();
		if (fileExtenstion === "png" || fileExtenstion === "jpg" || fileExtenstion === "jpeg") {
			getUri(file).then(() => {
				setCompressedFile(base_uri2);
			});
		} else {
			const fileReader = new FileReader();
			let base64;
			// Onload of file read the file content
			fileReader.onload = function (fileLoadedEvent) {
				base64 = fileLoadedEvent.target.result;
				// Print data in console
				console.log(base64);
				setCompressedFile(base64);
			};
			// Convert data to base64
			fileReader.readAsDataURL(file);
		}
	};

	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};

	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		// base = result_base64;
		base_uri2 = result_base64;
		// base_uri2 = base;
		return result_base64;
	};

	const onSubmit = async values => {
		if (depositTo === "1") {
			delete values.given_to;
		}
		if (cashData.type !== "cash-amount" && !selectedOptions.length) {
			toast.error("Please Choose EMI's");
			return false;
		}
		if (depositTo === "2" && !values.given_to) {
			toast.error("Please Enter Employee Name");
			return false;
		}
		if (depositTo === "1" && !compressedFile) {
			toast.error("Please Choose Receipt");
			return false;
		}
		handlePageLoaded(true);
		const formData = new FormData();
		Object.entries(values).map(([key, value]) => {
			formData.append(key, value);
		});
		formData.append("cash_ids", cashData.type === "cash-amount" ? cashData.cash_id : selectedOptions.map(option => option.value));
		formData.append("received_from", cashData.created_by);
		formData.append("amount", cashData.type === "cash-amount" ? cashData.amount : sumSelectedAmounts);
		formData.append("receipt", compressedFile);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/admin-collect-cash`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Saved Successfully");
					reload();
					handleResetForm();
					openSideBar();
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "An error has occurred, Please try again"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("An error has occurred", error);
			});
	};

	const handleResetForm = () => {
		reset();
		setCashModal(false);
		setSelectedOptions([]);
		setCompressedFile("");
	};

	const emiOptions = emiListData
		? emiListData.map(data => ({
			value: data.cash_report_enc_id,
			label: `${data.loan_account_number} - ₹${parseFloat(data.amount).toLocaleString(
				"en-IN"
			)}`
		  }))
		: [];

	const selectedEmis = selected => {
		setSelectedOptions(selected);
	};

	useEffect(() => {
		if (depositTo === "2") {
			setCompressedFile("");
		}
	}, [depositTo]);

	useEffect(() => {
		if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
			const sum = selectedOptions.reduce((total, selectedOption) => {
				const amount = parseFloat(selectedOption.label.split("₹")[1].replace(/,/g, ""));
				return total + amount;
			}, 0);
			setSumSelectedAmounts(sum);
		} else {
			setSumSelectedAmounts(0);
		}
	}, [selectedOptions]);

	return (
		<>
			<Modal
				show={cashData && cashData.type}
				onHide={() => handleResetForm()}
				size={"md"}
				centered
			>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<h3 className="text-center mb-3">Approve Cash</h3>
						<div className="row">
							{cashData.type !== "cash-amount" ? (
								<div className="col-12 mb-4 optionsList">
									<label
										htmlFor="float-text"
										className="floating-label fw400 ft14"
									>
										EMI&#39;s <span style={{ color: "red" }}>*</span>
									</label>
									<Select
										options={emiOptions}
										// formatOptionLabel={formatOptionLabel}
										isMulti={true}
										onChange={selectedEmis}
										placeholder={"Choose EMI's"}
									/>
									<p className="fs-6 m-0">
										Total Amount: ₹
										{sumSelectedAmounts
											? parseFloat(sumSelectedAmounts).toLocaleString("en-IN")
											: "0"}
									</p>
								</div>
							) : (
								""
							)}
							<div className="col-lg-12 col-sm-12">
								<label htmlFor="float-text" className="d-block fw400 ft14">
									Deposit To <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper hero-radio-wrapper mb-4">
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-1"
											value="1"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-1" className="option option-1">
											<div className="dot"></div>
											<span>Bank</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="select"
											className="radioInput"
											id="option-2"
											value="2"
											{...register("type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-2" className="option option-6">
											<div className="dot"></div>
											<span>Employee</span>
										</label>
									</div>
									<ErrorMessage
										errors={errors}
										name="type"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
							</div>
							{depositTo === "1" ? (
								<>
									<div className="col-lg-5 col-sm-12 mb-4">
										<label
											className="floating-label fw400 ft14"
											htmlFor="pr_receipt"
										>
											Reference Number <span style={{ color: "red" }}>*</span>
										</label>
										<input
											type="text"
											placeholder="Enter Reference Number"
											className="form-control"
											{...register("reference_number", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="reference_number"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="col-lg-7 col-sm-12 mb-4">
										<label
											className="floating-label fw400 ft14"
											htmlFor="pr_receipt"
										>
											Choose Receipt <span style={{ color: "red" }}>*</span>
										</label>
										<input
											id="pr_receipt"
											accept=".png, .jpg, .jpeg, .pdf"
											placeholder="Capture Image"
											className="form-control"
											type="file"
											capture="environment"
											onChange={e => compressImage(e)}
										/>
									</div>
								</>
							) : (
								""
							)}
							{depositTo === "2" ? (
								<div className="col-lg-12 col-sm-12">
									<div className="search-box-auto mb-4">
										<label
											htmlFor="float-text"
											className="floating-label fw400 ft14"
										>
											Search Employee <span style={{ color: "red" }}>*</span>
										</label>
										<AutoComplete
											setFieldValue={setValue}
											link={
												"company-dashboard/employee-search?type=employees&employee_search="
											}
											name={"given_to"}
											method={"GET"}
											placeholder={"Enter Username"}
											{...register("given_to", {})}
										/>
									</div>
								</div>
							) : (
								""
							)}
							<div className="col-12 mb-4">
								<label htmlFor="float-text" className="floating-label fw400 ft14">
									Remarks
								</label>
								<textarea
									className="form-control"
									rows="3"
									placeholder="Enter you comments"
									{...register("remarks", {
										required: false
									})}
								></textarea>
							</div>
							<div className="text-center">
								<button className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default ApproveCashAmountModal;
