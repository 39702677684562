import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import { MainContext } from "../context/MainState";
import SignUpPage from "../pages/SignUpPage";
import SignUpEmployee from "../pages/SignUpEmployee";
import SignUpDsa from "../pages/SignUpDsa";
import ResetPassword from "../pages/ResetPassword";
import Login from "../pages/LoginPage";
import { isCredifinUser } from "./customFunctions";

const AuthRoutes = () => {
	const { user } = useContext(MainContext);
	{
		if (user) {
			return (
				<>
					<Routes>
						<Route path="*" element={<Navigate to="/account/dashboard" />} />
					</Routes>
				</>
			);
		} else {
			return (
				<>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/signup/dsa/:id" element={<SignUpDsa />} />
						<Route path="/signup/connector/:id" element={<SignUpPage />} />
						<Route path="/signup/employee/:id" element={<SignUpEmployee />} />
						<Route path="/reset-password/:id" element={<ResetPassword />} />
						{isCredifinUser() ? (
							<>
								<Route path="*" element={<Login />} />
							</>
						) : (
							<Route path="*" element={<NotFound />} />
						)}
					</Routes>
				</>
			);
		}
	}
};
export default AuthRoutes;
