import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ReactSortable } from "react-sortablejs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { LoanProductsContext } from "../../context/LoanProductsState";

const StatusTab = ({ productId, showDocuments, handlePageLoaded }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [defaultStatus, setDefaultStatus] = useState([]);
	const [lists, setLists] = useState({
		lead: [],
		verification: [],
		physical_discussion: [],
		approval: [],
		legal: [],
		sanction: [],
		disbursement: [],
		reject: [],
		close: [],
		unassigned: []
	});
	const { handleSubmit } = useForm();
	const { getLoanProductsDetail, productStatusData } = useContext(LoanProductsContext);

	const getDefaultStatusList = async () => {
		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/loan-status`)
			.then((response) => {
				if (response.data.response.status === 200) {
					setDefaultStatus(response.data.response.loan_status);
				}
			});
	};

	const removeElement = (index, listType, id) => {
		let items = [...lists[listType]];
		if (id && !window.confirm("Do you really want to delete this status")) {
			return false;
		}
		if (id) {
			removeStatus(id);
		}
		items.splice(index, 1);
		setLists((prevLists) => ({
			...prevLists,
			[listType]: items
		}));
	};

	// Function to remove a status
	const removeStatus = async (id) => {
		let data = { financer_loan_product_status_enc_id: id };
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/remove-loan-product-status`,
				data
			);
			if (response.data.response.status === 200) {
				toast.success("Status removed successfully");
			} else {
				toast.error(
					"An error occurred " +
            (response.data?.response?.error ? response.data.response.error : response.data?.response?.message)
				);
			}
		} catch (error) {
			toast.error("An error occurred " + error.message);
		}
	};

	const onSubmit = async () => {
		setIsLoading(true);
		const filteredLists = Object.fromEntries(
			Object.entries(lists).filter(([key]) => key !== "unassigned")
		);
		let data = { financer_loan_product_enc_id: productId, ...filteredLists };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product-status`,
				data
			)
			.then((response) => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Status updated successfully");
					getLoanProductsDetail({ id: productId, type: "status" });
				} else {
					toast.error(
						"An error occurred " +
              (response.data?.response?.error ? response.data.response.error : response.data?.response?.message)
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};

	console.log(lists);

	const handleStatusDrag = (newList, listType) => {
		const updatedList = newList.map((status, index) => ({
			...status,
			type: listType,
			sequence_no: index.toString()
		}));

		const uniqueList = updatedList.filter(
			(status, index, self) => index === self.findIndex((t) => t.loan_status_enc_id === status.loan_status_enc_id)
		);

		setLists((prevLists) => ({
			...prevLists,
			[listType]: uniqueList
		}));
	};

	const filterDefaultStatus = () => {
		const existingStatuses = new Set();
		Object.values(lists).forEach(list => {
			list.forEach(status => {
				existingStatuses.add(status.loan_status_enc_id);
			});
		});

		return defaultStatus.filter(status => !existingStatuses.has(status.loan_status_enc_id));
	};

	useEffect(() => {
		getDefaultStatusList();
	}, []);

	useEffect(() => {
		if (showDocuments) {
			getLoanProductsDetail({ id: productId, type: "status" });
			handlePageLoaded(true);
		}
	}, [showDocuments]);

	useEffect(() => {
		if (productStatusData) {
			setLists({
				lead: productStatusData.lead || [],
				verification: productStatusData.verification || [],
				physical_discussion: productStatusData.physical_discussion || [],
				approval: productStatusData.approval || [],
				legal: productStatusData.legal || [],
				sanction: productStatusData.sanction || [],
				disbursement: productStatusData.disbursement || [],
				reject: productStatusData.reject || [],
				close: productStatusData.close || [],
				unassigned: productStatusData.unassigned || []
			});
			handlePageLoaded(false);
		}
	}, [productStatusData]);

	return (
		<>
			<div className="status-modal mt-6">
				<div className="status-modal-sidebar">
					<PerfectScrollbar>
						<ReactSortable
							className="sortable"
							group={{ name: "shared" }}
							list={filterDefaultStatus()}
							setList={() => {}}
						>
							{filterDefaultStatus().map((item, index) => {
								return (
									<div className="draggable drag-list cursor-point" key={index}>
										{item.name}
									</div>
								);
							})}
						</ReactSortable>
					</PerfectScrollbar>
				</div>
				<div className="status-modal-body">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="">
							<div className="sortableStatusList customize-status-list row">
								{Object.entries(lists).map(([listType, list]) => {
									// Check if the listType is 'unassigned' and its length is 0, then skip rendering it
									if (listType === "unassigned" && list.length === 0) {
										return null;
									}

									return (
										<div key={listType} className="col-lg-6 offset-lg-3">
											<h4 className="fs-5 fw-bold text-capitalize m-0">{listType.replace("_", " ")}</h4>
											<PerfectScrollbar>
												<ReactSortable
													className="sortable mb-3 customize-sortable"
													group={{ name: "shared" }}
													list={list}
													setList={(newList) => handleStatusDrag(newList, listType)}
												>
													{list.length ? (
														list.map((item, index) => (
															<div className="status-flex" key={index}>
																<div className="status-div disFlex justify-content-between flexw customize-drop fs-5">
																	{item.name}
																	<button
																		type="button"
																		className="link-button"
																		style={{ color: "red" }}
																		onClick={() =>
																			removeElement(index, listType, item.financer_loan_product_status_enc_id)
																		}
																	>
																		<RiDeleteBin6Line size={16} />
																	</button>
																</div>
															</div>
														))
													) : (
														<div className="status-drag-drop-div text-black-50 fs-5">Drag Status to Add</div>
													)}
												</ReactSortable>
											</PerfectScrollbar>
										</div>
									);
								})}
							</div>
						</div>
						<div className="mt-5 text-center">
							{isLoading ? (
								<button type="button" className="btn btn-primary" disabled={true}>
									<img src="/images/pages/home/loader.svg" alt="Loader" />
								</button>
							) : (
								<button type="submit" className="btn btn-primary">
                  Save List
								</button>
							)}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default StatusTab;

