import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainState";
import { toast } from "react-toastify";

const FinancerLoanSettings = () => {
	const [allLoanTypes, setAllLoanTypes] = useState([]);
	const { getAssignedLoanTypes } = useContext(MainContext);

	const changeLoanStatus = (loan_id, e) => {
		let data = {
			loan_type_enc_id: loan_id,
			status: e.target.checked == true ? "Active" : "Inactive"
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-type`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					getAllLoanTypes();
					getAssignedLoanTypes();
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};
	const getAllLoanTypes = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-loan-types`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setAllLoanTypes(response.data.response.allLoanTypes);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};
	useEffect(() => {
		getAllLoanTypes();
	}, []);
	return (
		<div className="row">
			{allLoanTypes.length
				? allLoanTypes.map((value, index) => {
					return (
						<div className="col-md-6 col-sm-6" key={index}>
							<div className="loan-row">
								{value.name} :{" "}
								<span>
									<div className="toggle-button_wrap">
										<input
											type="checkbox"
											id={value.loan_type_enc_id}
											defaultChecked={value.status == 1 ? true : false}
											className="toggler"
											onChange={e =>
												changeLoanStatus(value.loan_type_enc_id, e)
											}
										/>
										<label
											className="toggle-button"
											htmlFor={value.loan_type_enc_id}
										></label>
										<div className="toggle"></div>
									</div>
								</span>
							</div>
						</div>
					);
				  })
				: ""}
		</div>
	);
};

export default FinancerLoanSettings;
