import Geocode from "react-geocode";
import { useEffect } from "react";

Geocode.setApiKey("AIzaSyDYtKKbGvXpQ4xcx4AQcwNVN6w_zfzSg8c");

const GetMyLocation = ({ matchState, matchCity, setVals, reload = "", btnClasses = "" }) => {
	const location = () => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(success);
		} else {
			navigator.geolocation.getCurrentPosition(locerrors);
		}
	};
	const success = async pos => {
		var crd = pos.coords;

		Geocode.fromLatLng(crd.latitude, crd.longitude).then(
			response => {
				let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
				for (let i = 0; i < response.results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
						case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
							// case "country":
							// 	country = response.results[0].address_components[i].long_name;
							// 	break;
						case "postal_code":
							postalCode = response.results[0].address_components[i].long_name;
							break;
						case "premise":
							route = response.results[0].address_components[i].long_name;
							break;
						case "sublocality":
							sublocality = response.results[0].address_components[i].long_name;
							break;
						case "sublocality_level_2":
							sublocality_2 = response.results[0].address_components[i].long_name;
						}
					}
				}
				if (route && sublocality_2 && sublocality) {
					streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
				} else if (route && sublocality) {
					streetAdd = route + ", " + sublocality;
				} else {
					streetAdd = route;
				}
				matchState(state);
				matchCity(city);
				setVals(postalCode, streetAdd);
			},
			error => {
				console.error(error);
			}
		);
		// const res = await fetch(api)
		// const location = await res.json();
		// setUserLocation(location)
	};

	const locerrors = err => {
		alert(`ERROR(${err.code}): ${err.message}`);
	};
	useEffect(() => {
		if (reload === "yes") {
			fetchmylocation();
		}
	}, [reload]);
	const fetchmylocation = () => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				if (result.state === "granted") {
					console.log(result.state);
					location();
					// If granted then you can directly call your function here
				} else if (result.state === "prompt") {
					location();
					console.log(result.state);
				} else if (result.state === "denied") {
					console.log(result.state);
					// If denied then you have to show instructions to enable location
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			alert("Sorry Not available!");
		}
	};
	return (
		<>
			<button
				type="button"
				className={`btn btn-primary ${btnClasses}`}
				onClick={fetchmylocation}
			>
				Fetch My Location
			</button>
		</>
	);
};

export default GetMyLocation;
