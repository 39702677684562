import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import LoanApplicationByStatus from "../../components/LoanApplicationByStatus";
import { useParams } from "react-router-dom";
// import { useEffect } from "react";

const LoanApplications = () => {
	const params = useParams();

	return (
		<>
			<Helmet>
				<title>Loan Applications</title>
				{/* <meta name="description" content="Tutorial for React Helmet"/>*/}
			</Helmet>
			<Navbar />
			<LoanApplicationByStatus
				loan_product={params.type}
				product_portfolio={params["*"].split("/")[0] === "portfolio" ? "portfolio" : ""}
			/>
		</>
	);
};
export default LoanApplications;
