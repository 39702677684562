import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import { BiRupee } from "react-icons/bi";
import Datepicker from "react-datepicker";
import moment from "moment/moment";
import { MainContext } from "../../context/MainState";
import { readOnlyUsers } from "../../helpers/authServices";

const EditInput = ({
	editInputActive= false,
	type = "text",
	defaultValue,
	getData,
	id,
	api,
	placeholder = "",
	formType = "",
	loanDetailStatus = false,
	validateAsNumber = false,
	validateAsAplhaNumeric = false,
	required = false,
	asAmount = false,
	parentId = "",
	provider = "",
	inheritedCondition = true,
}) => {
	const {user} = useContext(MainContext);
	const [statusToggle, setStatusToggle] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const [currentVal, setCurrentVal] = useState("");

	const handleDateChange = (value, type) => {
		if (type === "date") {
			const selectedDate = value;
			const today = new Date().toISOString().split("T")[0];

			if (selectedDate > today) {
				toast.error("Please select a date in the past or today.");
				return false;
			}
		}
		setCurrentVal(value);
	};
	const handleSave = (value, loanId) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if(!value){
			toast.error("Please Enter Data");
			return false;
		}
		const numberValidateRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);
		const alphaNumericRegex = new RegExp(/^[A-Z0-9]+$/i);
		let numtest = numberValidateRegex.test(value);
		if (type === "customDate" || type === "validTill") {
			const formattedDate = moment(value, "DD MMM yyyy", true);
			if (!formattedDate.isValid()) {
				toast.error("Invalid date entered");
				return false;
			}
			value = formattedDate.format("YYYY-MM-DD");
		}
		if (type === "number" && (value.length !== 10 || value.length > 10)) {
			toast.error("Mobile number must be 10 digits");
			return;
		}
		let data = {
			id: loanId,
			value: value,
			type: formType,
			parent_id: parentId,
			provider_id: provider
		};
		if (required && value === "") {
			toast.error("Please Enter Value");
			return false;
		}
		if (value && !numtest && validateAsNumber) {
			toast.error("Please Enter Number Only");
			return false;
		}
		if (value && !alphaNumericRegex.test(value) && validateAsAplhaNumeric) {
			toast.error("Please Enter Alphanumeric Value");
			return false;
		}
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				data
			)
			.then(response => {
				setLoading(false);
				getData(id, value);
				if (response.data.response.status === 200) {
					setStatusToggle(false);
					toast.success("Successfully updated");
				} else if(loanId === "employee_code" && response.data.response.status === 400){
					toast.error(response.data.response.message);
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
				toast.error("An error has occurred, Please try again");
			});
	};

	useEffect(() => {
		if(defaultValue){
			setCurrentVal(defaultValue);
		}else{
			setCurrentVal("");
		}
	},[defaultValue]);
	
	return (
		<div className={`d-flex align-items-center fix-width ${(type === "users-table" || type === "number" || type === "onlyAmount") ? "w-90" : type === "amount" ? "inputwidth50" : ""}`}>
			{statusToggle && showCurrent === id || (editInputActive && inheritedCondition && (user.user_type === "Financer" || user.specialAccessRole)) ? (
				<>
					{!loading ? (
						<>
							{(type === "customDate" || type === "validTill") ? (
								<Datepicker
									onChange={date => {
										handleSave(date, id);
									}}
									maxDate={type === "customDate" ? new Date() : ""}
									placeholderText={placeholder}
									dropdownMode="select"
									className={`form-control custom-width m-0 mx-1 ${!editInputActive && "name-input-field"}`}
									isClearable={true}
									dateFormat="dd MMM yyyy"
									value={defaultValue ?  moment(defaultValue).format("DD MMM YYYY") : ""}
								/>
							) : (
								<>
									<input
										style={{
											maxWidth: "calc(100% - 50px)"
										}}
										type={(type === "amount" || type === "onlyAmount") ? "number" : type}
										placeholder={placeholder ? placeholder : "Enter Amount"}
										value={currentVal}
										onChange={event => handleDateChange(event.target.value, type)}
										onKeyDown={event => {
											if (event.key === "Enter") {
												handleSave(event.target.value, id);
											} else if (event.key === "Escape") {
												setStatusToggle(false);
												event.preventDefault();
												event.stopPropagation();
											}
										}}
										className={`w-auto mx-1 form-control ${!editInputActive && "input-padding-xs"}`}
									/>
								</>
							)}
							{defaultValue !== currentVal ? (
								<>
									<span
										style={{
											margin: "0px 5px",
											marginTop: "-2px",
											cursor: "pointer",
											color: "green"
										}}
										onClick={() => {
											handleSave(currentVal, id);
										} }
									>
										<GiCheckMark size={18} />
									</span>
							
									<span
										style={{
											margin: "0px 5px",
											marginTop: "-2px",
											cursor: "pointer",
											color: "red"
										}}
										onClick={() => {
											setStatusToggle(false);
											setCurrentVal(defaultValue);
										} }
									>
										<FaTimes size={18} />
									</span>
								</>
							) : ""}
						</>
					) : (
						<i>Please Wait Saving...</i>
					)}
				</>
			) : (
				<div
					style={{ display: "flex", alignItems: "center" }}
					onClick={
						loanDetailStatus !== 28 &&
						loanDetailStatus !== 32 &&
						inheritedCondition &&
						(!defaultValue || user.user_type === "Financer" || user.specialAccessRole || ((user.designation === "HR Executive" || user.designation === "HR Manager") && ["employee_code", "user_name", "user_email", "user_number"].includes(id)))
							? () => {
								setShowCurrent(id);
								setStatusToggle(true);
							  } : () => {
								setStatusToggle(false);
							  }
					}>
					{defaultValue ? (
						<>
							{asAmount ? (
								<>
									{" "}
									<BiRupee size={16} />{" "}
									{parseFloat(defaultValue).toLocaleString("en-IN")}
								</>
							) : (
								<>
									{type === "customDate" || type === "validTill" ? moment(defaultValue).format("DD MMM YYYY") : defaultValue}
								</>
							)}
						</>
					) : (
						<div style={{ cursor: "pointer", padding: "5px", textAlign: "center" }}>
							-
						</div>
					)}
					{/* <FaRegEdit style={{color: 'rgb(41, 121, 255)', cursor: 'pointer', margin: '0px 5px'}} size={16}*/}
					{/*           onClick={() => {*/}
					{/*               setShowCurrent(id);*/}
					{/*               setStatusToggle(true);*/}
					{/*           }}/>*/}
				</div>
			)}
		</div>
	);
};
export default EditInput;
