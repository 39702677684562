const ReEmpowerReducer = (state, action) => {
	switch (action.type) {
	case "SET_STATS":
		return {
			...state,
			stats: action.payload
		};
	case "SET_VEHICLES":
		return {
			...state,
			vehicles: action.payload
		};
	default:
		return state;
	}
};
export default ReEmpowerReducer;
