import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiRupee } from "react-icons/bi";
import { AiOutlinePercentage } from "react-icons/ai";
import { toast } from "react-toastify";
import TatReport from "../TatReport";
import { loanStatusData } from "../../data/loanStatusData";
import { vehicleProductsList } from "../../data/authData";
const CaseReport = ({ loanDetails, loadCaseReport, tatReportData }) => {
	const [caseReport, setCaseReport] = useState([]);
	const genderSelect ={
		1: "Male",
		2: "Female",
		3: "Others"
	};

	const caseReportData = async () => {
		let data = { loan_app_enc_id: loanDetails.loan_app_enc_id };
		

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-applications/case-report-details`,
				data
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					setCaseReport(response.data.response.data);
				} else {
					console.log("data not found");
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred, Please try a	gain");
			});
	};

	useEffect(() => {
		if (loadCaseReport && caseReport.length == 0) {
			caseReportData();
		}
	}, [loadCaseReport]);


	return (
		<>
			<div className="px-3">
				<div className="row mb-3">
				     <div className="mb-3 d-flex align-items-center">
						<h3 className="mb-0">Loan Details</h3>
					</div>
					<div className="col-md-6">
					 <p className="d-flex flex-wrap align-items-center emiAmt ht-40">
							<span className="me-1">Lead Creation Date:</span>{" "}
							{caseReport?.lead_start
								? moment(caseReport?.lead_start).format("DD MMM YYYY")
								: "-"}
						</p>
						
					</div>
					<div className="col-md-6">
						<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">Login Fee:</span>
							{loanDetails?.assignedLoanPayments && loanDetails.assignedLoanPayments.length
								? (() => {
									const filteredPayments = loanDetails.assignedLoanPayments.filter(value => value.payment_status === "captured");
									return filteredPayments.length > 0 ? (
										<>
											<BiRupee /> 
											<div key={`purposes-${loanDetails.loan_app_enc_id}0`}>
												{parseFloat(filteredPayments[0].payment_amount).toLocaleString("en-IN")}
											</div>
										</>
									) : "-";
								})()
								: "-"}
						</p>
					</div>

					<div className="col-md-6">
					    <p className="d-flex flex-wrap align-items-center emiAmt ht-40">
							<span className="me-1">Login Date:</span>{" "}
							{caseReport?.login_date
								? moment(caseReport.login_date).format("DD MMM YYYY")
								: "-"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="d-flex flex-wrap align-items-center em-Amt ht-40">
							<span className="me-1">Branch:</span>
							{loanDetails?.branch ? loanDetails.branch : "-"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="d-flex flex-wrap align-items-center em-Amt ht-40">
							<span className="me-1">Loan Number:</span>
							{loanDetails.application_number ? (
							  loanDetails.application_number
						      ) :"-"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="d-flex flex-wrap align-items-center em-Amt ht-40">
							<span className="me-1">Current Stage of Loan:</span>
							{loanStatusData.loanStatus[parseInt(loanDetails.loan_status)] ? loanStatusData.loanStatus[parseInt(loanDetails.loan_status)].name : "Unknown"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="d-flex flex-wrap align-items-center em-Amt ht-40">
							<span className="me-1 ">Originator:</span>
							<div className="text-capitalize">
							 {loanDetails?.created_by ? loanDetails.created_by : "-"}
							 </div>
						</p>
					</div>
					<div className="col-md-6 ">
						<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">Applied Loan Amount:</span>
							<BiRupee />{" "}
							{loanDetails?.amount
								? parseFloat(loanDetails.amount).toLocaleString("en-IN")
								: "0"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">Sanction Amount:</span>
							<BiRupee />{" "}
							{loanDetails?.amount
								? parseFloat(loanDetails.amount).toLocaleString("en-IN")
								: "-"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="emiAmt d-flex align-items-center ht-40">
							<span className="me-1">Purpose:</span>
							<div className="list-data d-inline-block">
								{loanDetails?.loanPurposes && loanDetails.loanPurposes.length
									? loanDetails.loanPurposes.map((value, index) => {
										return (
											<span
												key={`purposes-${loanDetails.loan_app_enc_id}${index}`}
											>
												{value.purpose}
											</span>
										);
									})
									: "-"}
							</div>
						</p>
					</div>
					<div className="col-md-6">
						<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">Valuation:</span>{" "}
							{caseReport?.valuation ? <><BiRupee /> { parseFloat(caseReport.valuation).toLocaleString("en-IN")}</> : "-"}
						</p>
					</div>

				
					{vehicleProductsList.includes(loanDetails.loan_product) ? (
						<>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Invoice Number:</span>
									{loanDetails?.invoice_number ? loanDetails.invoice_number : "-"}
								</p>
							</div>
							
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Invoice Date:</span>
									{loanDetails?.invoice_date
								   ? moment(loanDetails.invoice_date).format("DD MMM YYYY")
								  : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Vehicle Color:</span>
									{loanDetails?.vehicle_color ? loanDetails.vehicle_color : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Motor Number:</span>
									{loanDetails?.motor_number ? loanDetails.motor_number : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Battery Number:</span>
									 {loanDetails?.battery_number ? loanDetails.battery_number : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">RC Number:</span>
								 	{loanDetails?.rc_number ? loanDetails.rc_number : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Chassis Number:</span>
									{loanDetails?.chassis_number ? loanDetails.chassis_number : "-"}
								</p>	
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Year of Model: </span>
									 {loanDetails?.model_year ? loanDetails.model_year: "-"}
								</p>
							</div>
							
							{loanDetails?.loan_product === "Two Wheeler" ? (
								<div className="col-md-6">
									<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
										<span className="me-1">Engine Number: </span>
										 {loanDetails?.engine_number ? loanDetails.engine_number : "-"}
									</p>
								</div>
							) : ("")}
						
						
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Ex-Showroom Price: </span>
									{loanDetails?.ex_showroom_price ? (
										<>
											<BiRupee /> { parseFloat(loanDetails.ex_showroom_price  ).toLocaleString("en-IN")}
										</>
									) : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">On-Road Price: </span>
									{loanDetails?.on_road_price ? (
										<>
											<BiRupee /> {parseFloat(loanDetails.on_road_price) .toLocaleString("en-IN")}
										</>
									) : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">Margin Money: </span>
									 {loanDetails?.margin_money ? <><BiRupee /> {parseFloat(loanDetails.margin_money) .toLocaleString("en-IN")}</> : "-" }
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1">LTV: </span>
									 {loanDetails?.ltv ? loanDetails.ltv : "-"}
								</p>
							</div>
							<div className="col-md-6">
								<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
									<span className="me-1"> Dealer: </span>
									{loanDetails?.dealer_name ? (
										<div className="text-capitalize">
											{loanDetails.dealer_name}
										</div>
									) : ("-")}
					 		</p>
						   </div>
					  </>
				 ) : (
					 ""
				 )}
				     <div className="col-md-6">
						<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">Number of EMIs:</span>{" "}
							{loanDetails?.number_of_emis ? loanDetails.number_of_emis : "-"}
						</p>
					</div>
					<div className="col-md-6">
					  <p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">EMI Amount:</span>{" "}
							{loanDetails?.emi_amount ? <><BiRupee /> {parseFloat(loanDetails.emi_amount).toLocaleString("en-IN")}</> : "-"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">PF:</span>{" "}
							{loanDetails?.pf ? <><BiRupee /> {loanDetails.pf} </> : "-"}
						</p>
					</div>
					<div className="col-md-6">
						<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">ROI:</span>
							{loanDetails?.roi ? <>{loanDetails.roi} <AiOutlinePercentage /></>  : "-"} 
						</p>
					</div>
				</div>
				<hr />
				
				<div className="row mb-3">
					<div className="mb-3">
						<h3 className="mb-0">Borrowers</h3>
					</div>
					<div className="row mt-3">
						{loanDetails?.loanCoApplicants?.length
							? loanDetails.loanCoApplicants.map((val, ind) => {
								return (
									<>
										<div
											className="col-md-4"
											key={`borrower-Images-${ind}`}
										>
											<div className="main-box mb-3 rounded-3 shadow-el">
												<div className="description text-center">
													<span>{val.borrower_type}</span>
												</div>
												<div className="case_user_box">
													<div className="case_user_image">
														{val.image ? (
															<img
																src={val.image}
																alt={val.name}
															/>
														) : (
															<img
																src="/images/pages/re-empower/avatar.svg"
																alt={val.name}
															/>
														)}
													</div>
													<div className="case_borrower_info mt-4 px-5">
														<div className="row">
															<div className="col-md-5 col-4">
																<p>Name:</p>{" "}
															</div>
															<div className="col-md-7 col-8">
																<p className="case_info text-capitalize  ">
																	{val.name}{" "}
																</p>
															</div>
															<div className="col-md-5 col-4">
																<p>Gender:</p>
															</div>
															<div className="col-md-7 col-8">
																<p className="case_info">
																 {genderSelect[val.gender] ? genderSelect[val.gender] : "-"}
																</p>
															</div>
															<div className="col-md-5 col-4">
																<p>Cibil Score:</p>
															</div>
															<div className="col-md-7 col-8">
																<p className="case_info">
																	{val.cibil_score
																		? val.cibil_score
																		: "-"}
																</p>
															</div>
															
															{val.borrower_type !== "Borrower" ? (
																<>
																	<div className="col-md-5 col-4">
																		<p>Relation:</p>
																	</div>
																	<div className="col-md-7 col-8">
																		<p className="case_info">{val.relation ? val.relation : "-"}</p>
																	</div>
																</>
															) : " "}
															
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})
							: ""}
					</div>
				</div>
				<hr />
				{loanDetails?.sharedTo?.length?
					<><div className="row mb-3">
						<div className="mb-3 d-flex align-items-center">
							<h3 className="mb-0">Loan Processing Team</h3>
						</div>
						<div className="row mt-3">
							{loanDetails?.sharedTo?.length
								? loanDetails.sharedTo.map((val, ind) => {
									return (
										<div className="col-md-4" key={`loan-team-${ind}`}>
											<div className="main-box2 mb-3 rounded-3 shadow-el">
												<div className="description text-center">
													{val.designation}
												</div>
												<div className="case_user_box">
													<div className="case_user_image">
														<img src={val.image} alt="BD0"
												 className="loan_team_images"
												 />
													</div>

													<div className="case_borrower_info mt-4 px-4 p-sm-3">
														<div className="row">
															<div className="col-md-4 col-3">
																<p >Name:</p>
															</div>
															<div className="col-md-8 col-9">
																<p className="case_info text-capitalize">
																	{val.name}
																</p>
															</div>
															<div className="col-md-4 col-3">
																<p>Branch:</p>
															</div>
															<div className="col-md-8 col-9">
																<p className="case_info">
																	{val.branch_name
																		? val.branch_name
																		: "-"}{" "}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})
								: ""}
						</div>
					</div>
					<hr />
					</>:""}
				<div className="row mb-3">
					<div className="mb-3 d-flex align-items-center">
						<h3 className="mb-0">Turn Around Time Details</h3>
					</div>
					<div className="col-md-6 ht40">
						<p className="d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">Lead To Login TAT:</span>
							{caseReport?.lead_creation_tat ? caseReport.lead_creation_tat : "-"}
						</p>
					</div>
					<div className="col-md-6 ht40">
						<p className="d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">TVR TAT:</span> 	{caseReport?.tvr_tat ? caseReport.tvr_tat : "-"}
						</p>
					</div>
					<div className="col-md-6 ht40">
						<p className="d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">PD TAT:</span> 
							{caseReport?.pd_tat ? caseReport.pd_tat : "-"}
						</p>
					</div>
					<div className="col-md-6 ht40">
						<p className="d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">FI TAT:</span>{" "}
							{caseReport?.fi_tat ? caseReport.fi_tat : "-"}
						</p>
					</div>
					<div className="col-md-6 ht40">
						<p className="d-flex flex-wrap align-items-center ht-40">
							<span className="me-1">Total TAT:</span> {caseReport?.total_tat ? `${caseReport.total_tat} days` : "-"}
						</p>
					</div>
				</div>
				<hr />
				{tatReportData && tatReportData.length > 0 ? <TatReport tatReportData={tatReportData}/> : null}
			</div>
		</>
	);
};
export default CaseReport;
