import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { useContext } from "react";
import { MainContext } from "../context/MainState";
import { getLogo } from "../helpers/customFunctions";

const Footer = () => {
	const { orgSignUpHandler, dealerSignUpHandler, user } = useContext(MainContext);
	return (
		<>
			<div className="footer bg-dark pt-8 ">
				<div className="container">
					<div className="row mb-8">
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
							<img
								src={getLogo()}
								alt={process.env.REACT_APP_NAME}
								className="footer-logo"
							/>
							<div className="text-white-50 mb-3">
								<p>
									{process.env.REACT_APP_NAME} act as an intermediary between you and your
									financial problems. We have a pool of lenders and banks that
									provide the loans as per your requirements. We want to make
									financial products inexpensive and easily accessible to
									everyone.
								</p>
								<div className="row mt-3 social-items">
									<a
										target="_blank"
										href="https://www.facebook.com/empowerloans"
										className="text-inherit"
										rel="noreferrer"
									>
										<FaFacebookF size={14} className={"me-2"} />
										Facebook
									</a>
									<a
										target="_blank"
										href="https://www.instagram.com/empowerloans.in"
										className="text-inherit"
										rel="noreferrer"
									>
										<FaInstagram size={14} className={"me-2"} />
										Instagram
									</a>
									<a
										target="_blank"
										href="https://www.linkedin.com/company/empower-loans/"
										className="text-inherit"
										rel="noreferrer"
									>
										<FaLinkedin size={14} className={"me-2"} />
										LinkedIn
									</a>
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12">
							<div className="mb-3">
								<ul className="list-unstyled text-muted">
									<li className="d-flex">
										<Link to="/" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Home
										</Link>
									</li>
									<li className="d-flex">
										<Link to="/about-us" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											About Us
										</Link>
									</li>
									<li className="d-flex">
										<Link to="/contact-us" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Contact Us
										</Link>
									</li>
									{!user.access_token ? (
										<>
											<li className="d-flex">
												<button
													className="text-inherit fs-5 link-button"
													onClick={dealerSignUpHandler}
												>
													<BsChevronRight size={14} className={"me-2"} />
													Dealer Signup
												</button>
											</li>
											<li className="d-flex">
												<button
													className="text-inherit fs-5 link-button"
													onClick={orgSignUpHandler}
												>
													<BsChevronRight size={14} className={"me-2"} />
													Partner Signup
												</button>
											</li>
										</>
									) : (
										""
									)}
									<li className="d-flex">
										<Link to="/nbfcs" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											NBFC&#39;s
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="mb-3">
								<ul className="list-unstyled text-muted disFlex footer-flex">
									<li>
										<Link to="/car-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Car Loan
										</Link>
									</li>
									<li>
										<Link to="/personal-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Personal Loan
										</Link>
									</li>
									<li>
										<Link to="/education-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Education Loan
										</Link>
									</li>
									<li>
										<Link to="/business-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Business Loan
										</Link>
									</li>
									<li>
										<Link to="/home-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Home Loan
										</Link>
									</li>
									<li>
										<Link to="/two-wheeler-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Two Wheeler Loan
										</Link>
									</li>
									<li>
										<Link to="/e-vehicle-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											E-vehicle Loan
										</Link>
									</li>
									<li>
										<Link
											to="/loan-against-property"
											className="text-inherit fs-5"
										>
											<BsChevronRight size={14} className={"me-2"} />
											LAP
										</Link>
									</li>
									<li>
										<Link to="/medical-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Medical Loan
										</Link>
									</li>
									<li>
										<Link to="/micro-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Micro Loan
										</Link>
									</li>
									<li>
										<Link to="/solar-panel-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Solar Panel Loan
										</Link>
									</li>
									<li>
										<Link to="/marriage-loan" className="text-inherit fs-5">
											<BsChevronRight size={14} className={"me-2"} />
											Marriage Loan
										</Link>
									</li>
									<li>
										<Link
											to="/school-fee-finance"
											className="text-inherit fs-5"
										>
											<BsChevronRight size={14} className={"me-2"} />
											School Fee Finance
										</Link>
									</li>
									<li>
										<Link
											to="/consumer-durable-loan"
											className="text-inherit fs-5"
										>
											<BsChevronRight size={14} className={"me-2"} />
											Consumer Durable Loan
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							<p className="fs-6 text-muted">© Copyright 2024 | {process.env.REACT_APP_NAME}</p>
						</div>
						<div
							className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12
                  text-md-end"
						>
							<p className="fs-6 text-muted">
								<Link to={"/terms-condition"} className="text-inherit">
									Terms of Use
								</Link>{" "}
								|
								<Link to={"/privacy-policy"} className="text-inherit">
									{" "}
									Privacy Policy
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Footer;
