import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useContext, useEffect } from "react";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";

SwiperCore.use([Autoplay]);

const NoticeBoard = () => {
	const { getOrganizationNotices, noticeImages } = useContext(OrganizationDashboardContext);
	useEffect(() => {
		getOrganizationNotices({status : "active"});
	}, []);

	return (
		<>
			{noticeImages && noticeImages.length ? (
				<div className="noticeBoard bg-white">
					<div className="noticeSlider">
						<Swiper
							className="swiper-wrapper"
							spaceBetween={30}
							pagination={{ clickable: true }}
							modules={[Pagination]}
							slidesPerView={1}
							autoplay={true}
							loop={true}
							autoHeight={true}
							breakpoints={{
								320: {
									slidesPerView: 1,
									spaceBetween: 10
								},
								768: {
									slidesPerView: 1,
									spaceBetween: 10
								},
								1024: {
									slidesPerView: 1,
									spaceBetween: 10
								}
							}}
						>
							{noticeImages.map(value => {
								return (
									<SwiperSlide key={`noticesImages${value.notice_enc_id}`}>
										<div className="notice-img">
											<img
												src={value.image}
												alt="notice-img"
												className="img-fluid"
											/>
										</div>
									</SwiperSlide>
								);
							})}
						</Swiper>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};
export default NoticeBoard;
