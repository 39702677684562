import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";
import NameComp from "../NameComp";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { EmployeeReportsContext } from "../../context/EmployeeReports";
import MultiDataFilters from "../MultiDataFilters";
import CollectionReportHeadings from "./CollectionReportHeadings";
import { MainContext } from "../../context/MainState";

const DailyReportTableLoanType = ({ loadDefault = true }) => {
	const { getDailyCollectionReportLoanTypes, dailyReportLoanTypes, isLoading } =
		useContext(EmployeeReportsContext);
	const { branches_list} = useContext(OrganizationDashboardContext);
	const {loanAccountTypes } = useContext(MainContext);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		loan_type: "",
		fields_sort: ""
	});
	const [sortArr, setSortArr] = useState({});

	const DataTableDetails = ({totalCases,colletionCases,verifiedAmount,unVerifiedAmount,interactionCount,interactionAmount,registeredEnach}) => {
		const formatValue = (value, isCurrency = false) => {
			if (value === "0" || (isCurrency && value === "0.00")) {
				return "-";
			}
			return isCurrency ? `₹ ${parseFloat(value).toLocaleString("en-IN")}` : value;
		};

		return (
			<div className="d-flex justify-content-around fix-collection-w w-100">
				<div>{formatValue(totalCases)}</div>
				<div>{formatValue(colletionCases, true)}</div>
				<div>{formatValue(verifiedAmount, true)}</div>
				<div>{formatValue(unVerifiedAmount, true)}</div>
				<div>{formatValue(interactionCount)}</div>
				<div>{formatValue(interactionAmount, true)}</div>
				<div>{formatValue(registeredEnach)}</div>
			</div>
		);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "220px"
		},
		{
			name: <CollectionReportHeadings name={"Total"} type={"total"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "total",
			style: { textAlign: "center" },
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.total_cases_count}
						colletionCases={row?.total_collected_cases_sum}
						verifiedAmount={row?.total_collected_verified_amount}
						unVerifiedAmount={row?.total_collected_unverified_amount}
						interactionCount={row?.total_interaction_count}
						interactionAmount={row?.total_interaction_sum}
						registeredEnach={row?.total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"On Time"} type={"OnTime"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "on-time",
			style: { textAlign: "center" },
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.OnTime_total_cases_count}
						colletionCases={row?.OnTime_collected_cases_sum}
						verifiedAmount={row?.OnTime_collected_verified_amount}
						unVerifiedAmount={row?.OnTime_collected_unverified_amount}
						interactionCount={row?.OnTime_total_interaction_count}
						interactionAmount={row?.OnTime_total_interaction_sum}
						registeredEnach={row?.OnTime_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"0-30"} type={"SMA0"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "0-30",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA0_total_cases_count}
						colletionCases={row?.SMA0_collected_cases_sum}
						verifiedAmount={row?.SMA0_collected_verified_amount}
						unVerifiedAmount={row?.SMA0_collected_unverified_amount}
						interactionCount={row?.SMA0_total_interaction_count}
						interactionAmount={row?.SMA0_total_interaction_sum}
						registeredEnach={row?.SMA0_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"30-60"} type={"SMA1"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "30-60",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA1_total_cases_count}
						colletionCases={row?.SMA1_collected_cases_sum}
						verifiedAmount={row?.SMA1_collected_verified_amount}
						unVerifiedAmount={row?.SMA1_collected_unverified_amount}
						interactionCount={row?.SMA1_total_interaction_count}
						interactionAmount={row?.SMA1_total_interaction_sum}
						registeredEnach={row?.SMA1_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"60-90"} type={"SMA2"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "60-90",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA2_total_cases_count}
						colletionCases={row?.SMA2_collected_cases_sum}
						verifiedAmount={row?.SMA2_collected_verified_amount}
						unVerifiedAmount={row?.SMA2_collected_unverified_amount}
						interactionCount={row?.SMA2_total_interaction_count}
						interactionAmount={row?.SMA2_total_interaction_sum}
						registeredEnach={row?.SMA2_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"90+"} type={"NPA"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "90+",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.NPA_total_cases_count}
						colletionCases={row?.NPA_collected_cases_sum}
						verifiedAmount={row?.NPA_collected_verified_amount}
						unVerifiedAmount={row?.NPA_collected_unverified_amount}
						interactionCount={row?.NPA_total_interaction_count}
						interactionAmount={row?.NPA_total_interaction_sum}
						registeredEnach={row?.NPA_total_registered_enach}
					/>
				);
			},
			sortable: false
		}
	];

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (dailyReportLoanTypes && dailyReportLoanTypes.data.length > 0) {
			return dailyReportLoanTypes.data;
		} else if (
			dailyReportLoanTypes &&
			dailyReportLoanTypes.data.length === 0 &&
			isFiltered
		) {
			return [];
		} else {
			return dailyReportLoanTypes && dailyReportLoanTypes.data.length > 0
				? dailyReportLoanTypes.data.slice(0)
				: [];
		}
	};


	useEffect(() => {
		if(loadDefault){
			if (filters.start_date && filters.end_date) {
				const timerId = setTimeout(() => {
					getDailyCollectionReportLoanTypes({
						page: 1,
						start_date: filters.start_date,
						end_date: filters.end_date,
						loan_type: filters.loan_type,
						branch_enc_id: filters.branch_name,
						state_enc_id: filters.state_name,
						fields_search: searchArray,
						fields_sort: sortArr,
					});
				}, 800);

				const valLength = Object.values(searchArray);
				if (!valLength.length || !valLength.filter(val => val != "").length) {
					setShowClearBtn(false);
				} else {
					setClearFilters(false);
					setShowClearBtn(true);
				}

				return () => {
					clearTimeout(timerId);
				};
			}
		}
	}, [filters, searchArray, loadDefault, sortArr]);

	const getDataApi = (filters) => {
		setFilters({...searchArray, ...filters});
	};

	return (
		<div className="loan-application-table mb-5">
			<Row className="mx-0 mt-1 mb-5">
				<Col sm="9">
					<div className="d-flex align-items-center py-2 flex-wrap">
						<MultiDataFilters
							branches_list={branches_list}
							loan_types={loanAccountTypes}
							getData={getDataApi}
							type={["branches","states"]}
							defaultDate={{value: "3", label: "Today"}}
						/>
					</div>
				</Col>
				<Col
					className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
					sm="3"
				>
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button
								className="btn btn-primary btn-xs my-2"
								onClick={e => clearFilter(e)}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				columns={columns}
				data={dataToRender()}
				sortServer={true}
				progressPending={isLoading === "employeeStat"}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent={
					<p className="w-100 m-5 text-start">There are no records to display</p>
				}
				persistTableHead={true}
				className="loan-app-table-container fix-overflow report-tables z-0"
				fixedHeader={true}
				fixedHeaderScrollHeight="80vh"
			/>
		</div>
	);
};
export default DailyReportTableLoanType;
