export const hasSmartphone = {
	0: "No",
	1: "Yes"
};

export const requiredDetails = [
	"state_abbreviation",
	"address",
	"gender",
	"postal_code",
	"co_applicant_dob"
];

export const fiEligibleProducts = [
	"E-Rickshaw",
	"EV Two Wheeler",
	"Two Wheeler",
	"Capital LAP BC 10",
	"Affordable Housing Loan BC 15"
];

export const statusColorObj = {
	paid: "success",
	pending: "warning",
	rejected: "danger",
	failed: "danger",
	pipeline: "secondary",
	"not paid": "danger",
	collected: "secondary",
	Active: "success",
	Issued: "warning",
	Pending: "secondary",
	Inactive: "danger",
	Failed: "danger",
	Cancelled: "danger",
	"Not Registered": "primary"
};


export const eRishawPurposeList = [
	{
		value: "1",
		label: "Self Driven"
	},
	{
		value: "2",
		label: "Rental"
	}
];

export const eRishawTypeList = [
	{
		value: "1",
		label: "Loader"
	},
	{
		value: "2",
		label: "Passenger"
	}
];

export const eRishawBatteryType = [
	{
		value: "Lead",
		label: "Lead"
	},
	{
		value: "Lithium",
		label: "Lithium"
	}
];

export let priorityList = [
	{
		label: "High",
		value: "2"
	},
	{
		label: "Medium",
		value: "3"
	},
	{
		label: "Low",
		value: "4"
	}
];

export const genderSelect = {
	1: "Male",
	2: "Female",
	3: "Others"
};

