import React, { useState } from "react";
import Chart from "react-apexcharts";
const EmiCalculator = () => {
	const [amount, setAmount] = useState("");
	const [interest, setInterest] = useState("");
	const [tenure, setTenure] = useState("");
	const [emi, setEMI] = useState(null);

	
	const [chartData, setChartData] = useState({
		series: [],
		options: {
			labels: [],
			legend: {
				position: "right"
			}
		}
	});
	const updatePieChart = (principal, interest) => {
		setChartData({
			series: [principal, interest],
			options: {
				...chartData.options,
				labels: ["Principal", "Interest"]
			}
		});
	};
	const EMICalculation = () => {
		const p = parseFloat(amount);
		const r = parseFloat(interest) / (12 * 100);
		const n = parseFloat(tenure);
		const emiValue = (p * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
		setEMI(emiValue.toFixed(2)); 
      
		const principal = p;
		const totalInterest = emiValue * n - p;
		updatePieChart(principal, totalInterest);
	};
	return (
		<div className="container mt-5">
			<div className="emi-calc shadow p-6 bg-white">
				<div className="row">
					<div className="col-md-6">
						<h1 className="text-center mb-4">EMI CALCULATOR</h1>
						<div className="mb-3">
							<label htmlFor="amount" className="form-label">Principal amount:</label>
							<input
								id="amount"
								type="number"
								placeholder="Enter Amount"
								className="form-control"
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="interest" className="form-label">Interest Rate(%):</label>
							<input
								id="interest"
								type="number"
								className="form-control"
								placeholder="Enter Interest"
								value={interest}
								onChange={(e) => setInterest(e.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="tenure" className="form-label">Tenure in Months:</label>
							<input
								id="tenure"
								type="number"
								className="form-control"
								value={tenure}
								placeholder="In Years"
								onChange={(e) => setTenure(e.target.value)}
							/>
						</div>
						<div className="text-center">
							<button className="btn btn-primary mb-3" onClick={EMICalculation}>Calculate EMI</button>
							{emi && <p className="mb-0">EMI: ₹{emi}</p>}
						</div>
					</div>
					<div className="col-md-6  d-flex justify-content-center align-items-center">
						<Chart
							options={chartData.options}
							series={chartData.series}
							type="pie"
							width="380"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmiCalculator;
