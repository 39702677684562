import Navbar from "../../components/Navbar";
import "../../assets/css/accountStyles.css";
import EmiColletionReportsTable from "../../components/dashboard/EmiColletionReportsTable";
import { Helmet } from "react-helmet";

const EmiCollectionReport = () => {
	return (
		<>
			<Helmet>
				<title>Cash In Hand Report</title>
				<meta name="description" content="Cash In Hand Report" />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<EmiColletionReportsTable />
				</div>
			</div>
		</>
	);
};
export default EmiCollectionReport;
