import { Helmet } from "react-helmet";
import LoanAccountsTable from "../../components/dashboard/LoanAccountsTable";
import Navbar from "../../components/Navbar";
import LoanAccountPtpTable from "../../components/dashboard/LoanAccountPtpTable";
import LoanAccountsDataTable from "../../components/dashboard/LoanAccountsDataTable";
import { useEffect, useState } from "react";

const LoanAccounts = () => {
	const [locationPath, setLocationPath] = useState("");

	useEffect(() => {
		const url = window.location.pathname.split("/").slice(-1).toString();
		setLocationPath(url);
	}, [window.location.pathname]);

	return (
		<>
			<Helmet>
				<title>{locationPath === "foreclosure-requests" ? "Foreclosure Requests" : locationPath === "noc-requests" ? "NOC Requests" : locationPath === "initiate-requests" ? "Initiate Requests" : "Loan Accounts"}</title>
				<meta name="description" content="Loan Accounts" />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<LoanAccountsTable locationPath={locationPath}/>
					{!locationPath ? (
						<>
							<div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5">
								{/* <h2 className="mb-3">Upcoming EMI&#39;s</h2> */}
								<LoanAccountsDataTable type={"upcoming"} name={"Upcoming EMI's"} />
							</div>
							<div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5">
								{/* <h2 className="mb-3">Upcoming Nach</h2> */}
								<LoanAccountsDataTable type={"nach"} name= {"Upcoming Nach"} />
							</div>
							<LoanAccountPtpTable />
						</>
					): ""}
				</div>
			</div>
		</>
	);
};

export default LoanAccounts;
