import React, { createContext, useReducer, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import ReportsReducer from "../reducers/ReportsReducer";
// import { MainContext } from "./MainState";

// Create context
export const EmployeeReportsContext = createContext({});


// Provider component
export const EmployeeReportsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(ReportsReducer, {});
	const [isLoading, setIsLoading] = useState(false);
	const [cancelTokenSource, setCancelTokenSource] = useState(null);

	const getRcPendingApplications = async (data) => {
		setIsLoading(true);

		if (cancelTokenSource && cancelTokenSource["rc_pending_requests"]) {
			cancelTokenSource["rc_pending_requests"].cancel("Previous request canceled");
		}

		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["rc_pending_requests"]: newCancelTokenSource});

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/empty-rc`, data , { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_RC_PENDING_CASES",
						payload: response.data.response
					});
				}
				else {
					dispatch({
						type: "SET_RC_PENDING_CASES",
						payload: {}
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					console.log(error);
					toast.error("An error has occurred while getting RC Pending Cases, Please try again");
				}
			});
	};
	const getCollectionReports = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		if (cancelTokenSource && cancelTokenSource["collectionReport" + filter.report_type]) {
			cancelTokenSource["collectionReport" + filter.report_type].cancel("Previous request canceled");
		}
		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["collectionReport" + filter.report_type]: newCancelTokenSource});
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/employee-collected-emi-stats`,filter, { cancelToken: newCancelTokenSource.token })
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_COLLECTION_STATS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_COLLECTION_STATS",
						payload: {}
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error("An error has occurred while getting Collection reports, Please try again");
				}
			});
	};

	const getCollectionReportBranches = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		if (cancelTokenSource && cancelTokenSource["collectionReportBranches"]) {
			cancelTokenSource["collectionReportBranches"].cancel("Previous request canceled");
		}
		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["collectionReportBranches"]: newCancelTokenSource});
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/collection-report-branches`,filter, { cancelToken: newCancelTokenSource.token })
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_COLLECTION_STATS_BRANCHES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_COLLECTION_STATS_BRANCHES",
						payload: {}
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error("An error has occurred while getting Collection Branches reports, Please try again");
				}
			});
	};
	const getCollectionReportStates = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		if (cancelTokenSource && cancelTokenSource["collectionReportStates"]) {
			cancelTokenSource["collectionReportStates"].cancel("Previous request canceled");
		}
		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["collectionReportStates"]: newCancelTokenSource});
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/collection-report-states`,filter, { cancelToken: newCancelTokenSource.token })
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_COLLECTION_STATS_STATES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_COLLECTION_STATS_STATES",
						payload: {}
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error("An error has occurred while getting Collection States reports, Please try again");
				}
			});
	};

	const getDailyCollectionReports = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		if (cancelTokenSource && cancelTokenSource["dailyCollectionReport"]) {
			cancelTokenSource["dailyCollectionReport"].cancel("Previous request canceled");
		}
		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["dailyCollectionReport"]: newCancelTokenSource});
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/collection-daily-stats`,filter, { cancelToken: newCancelTokenSource.token })
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_DAILY_COLLECTION_STATS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_DAILY_COLLECTION_STATS",
						payload: {}
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error("An error has occurred while getting Daily Collection reports, Please try again");
				}
			});
	};

	const getDailyCollectionReportBranches = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		if (cancelTokenSource && cancelTokenSource["dailyCollectionBranchReport"]) {
			cancelTokenSource["dailyCollectionBranchReport"].cancel("Previous request canceled");
		}
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["dailyCollectionBranchReport"]: newCancelTokenSource});
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/branch-daily-stats`,filter, { cancelToken: newCancelTokenSource.token })
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_DAILY_COLLECTION_STATS_BRANCHES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_DAILY_COLLECTION_STATS_BRANCHES",
						payload: {}
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error("An error has occurred while getting Daily Collection Branches reports, Please try again");
				}
			});
	};

	const getDailyCollectionReportLoanTypes = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		if (cancelTokenSource && cancelTokenSource["dailyCollectionReportLoanTypes"]) {
			cancelTokenSource["dailyCollectionReportLoanTypes"].cancel("Previous request canceled");
		}
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, ["dailyCollectionReportLoanTypes"]: newCancelTokenSource});
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/collection-product-stats`,filter, { cancelToken: newCancelTokenSource.token })
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_DAILY_COLLECTION_LOAN_TYPES",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_DAILY_COLLECTION_LOAN_TYPES",
						payload: {}
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					setIsLoading(false);
					console.log(error);
					toast.error("An error has occurred while getting Daily Collection Branches reports, Please try again");
				}
			});
	};

	return (
        
		<EmployeeReportsContext.Provider
			value={{
				collectionReports: state.collectionReports,
				collectionReportBranches: state.collectionReportBranches,
				collectionReportStates: state.collectionReportStates,
				dailyCollectionReports: state.dailyCollectionReports,
				dailyReportBranches: state.dailyReportBranches,
				dailyReportLoanTypes: state.dailyReportLoanTypes,
				rcPendingApplications: state.rcPendingApplications,
				isLoading,
				setIsLoading,
				getCollectionReports,
				getCollectionReportBranches,
				getCollectionReportStates,
				getRcPendingApplications,
				getDailyCollectionReports,
				getDailyCollectionReportBranches,
				getDailyCollectionReportLoanTypes
			}}
		>
			{children}
		</EmployeeReportsContext.Provider>
	);
};