const CartTotal = () => {
	return (
		<>
			<div className="grand-total-main">
				<div className="grand-total">
					Cart Total:-
					<span className="grand-amount">₹2,016</span>
				</div>
				<div className="Buy-btn">
					<button type="submit">Proceed To Buy</button>
				</div>
			</div>
		</>
	);
};
export default CartTotal;
