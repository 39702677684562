
import { Helmet } from "react-helmet";
import { CandidateDashboardProvider } from "../context/CandidateDashboardState";
import LoanAccountDetails from "../components/dashboard/LoanAccountDetails";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";

const CandidateLoanAccount = () => {
	const params = useParams();
	return(
		<>
			<Helmet>
				<title>Loan Account</title>
			</Helmet>
			<Navbar />
			<CandidateDashboardProvider>
				<LoanAccountDetails loan_id={params.id} type={"individual"}/>
			</CandidateDashboardProvider>
		</>
	);
};

export default CandidateLoanAccount;