import moment from "moment";

export const cibilUrls = {
	equifax: "credit/equifax/cr-report",
	cibil: "credit/cibil/cr-report",
	crif: "credit/crif/cr-report"
};
export const cibilEvaluationData = (type, data) => {
	switch (type) {
	case "equifax":
		return getEquifaxData(data);
	case "cibil":
		return getCibilData(data);
	case "crif":
		return getCrifData(data);
	default:
		break;
	}
};

function getEquifaxData(rawData) {
	const data = {};
	data["tokenValue"] = rawData.tokenValue;
	data["loan_app_enc_id"] = rawData.loan_app_enc_id;
	data["loan_co_app_enc_id"] = rawData.loan_co_app_enc_id ? rawData.loan_co_app_enc_id : null;
	data["firstName"] = rawData.name;
	data["middleName"] = rawData.middleName ? rawData.middleName : "";
	data["lastName"] = rawData.lastName ? rawData.lastName : "";
	data["dob"] = rawData.co_applicant_dob;
	data["inquiryPurpose"] = rawData.loan_type_code ? rawData.loan_type_code : "00";
	data["gender"] = rawData.gender == 1 ? "M" : rawData.gender == 2 ? "F" : "T";
	data["transectionAmount"] = JSON.stringify(parseInt(rawData.amount));
	data["unique_identifier"] = rawData.pan_number;
	data["phones"] = [
		{
			seq: "1",
			Number: rawData.phone,
			PhoneType: ["M"]
		}
	];
	data["address"] = [
		{
			seq: "1",
			AddressLine1: rawData.address,
			City: rawData.city,
			State: rawData.state_abbreviation === "UK" ? "UL" : rawData.state_abbreviation,
			AddressType: ["H"],
			Postal: rawData.postal_code
		}
	];
	data["iddetails"] = [];
	if (rawData.pan_number) {
		data.iddetails.push({
			seq: data["iddetails"].length + 1,
			IDValue: rawData.pan_number,
			IDType: "T",
			Source: "Inquiry"
		});
	}
	if (rawData.voter_card_number) {
		data.iddetails.push({
			seq: data["iddetails"].length + 1,
			IDValue: rawData.voter_card_number,
			IDType: "V",
			Source: "Inquiry"
		});
	}
	if (rawData.aadhaar_number) {
		data.iddetails.push({
			seq: data["iddetails"].length + 1,
			IDValue: rawData.aadhaar_number,
			IDType: "M",
			Source: "Inquiry"
		});
	}
	if (rawData.driving_license_number) {
		data.iddetails.push({
			seq: data["iddetails"].length + 1,
			IDValue: rawData.driving_license_number,
			IDType: "D",
			Source: "Inquiry"
		});
	}
	return data;
}

function getCibilData(rawData) {
	const data = {};
	data["tokenValue"] = rawData.tokenValue;
	data["transectionAmount"] = JSON.stringify(parseInt(rawData.amount));
	data["loan_app_enc_id"] = rawData.loan_app_enc_id;
	data["loan_co_app_enc_id"] = rawData.loan_co_app_enc_id ? rawData.loan_co_app_enc_id : null;
	data["inquiryPurpose"] = rawData.loan_type_code ? rawData.loan_type_code : "05";
	data["Applicant"] = {
		ApplicantType: "Main",
		ApplicantFirstName: rawData.name,
		ApplicantMiddleName: rawData.middleName ? rawData.middleName : "",
		ApplicantLastName: rawData.lastName ? rawData.lastName : "",
		DateOfBirth: moment(rawData.co_applicant_dob).format("DDMMYYYY"),
		Gender: rawData.gender == 1 ? "M" : rawData.gender == 2 ? "F" : "T",
		Identifiers: {
			Identifier: []
		},
		Telephones: {
			Telephone: [
				{
					TelephoneExtension: "",
					TelephoneNumber: rawData.phone,
					TelephoneType: "01"
				}
			]
		},
		Addresses: {
			Address: {
				AddressType: "01",
				AddressLine1: rawData.address,
				AddressLine2: "",
				AddressLine3: "",
				AddressLine4: "",
				AddressLine5: "",
				City: rawData.city,
				PinCode: rawData.postal_code,
				ResidenceType: "02",
				StateCode: rawData.state_code
			}
		}
	};
	if (rawData.pan_number) {
		data.Applicant.Identifiers.Identifier.push({
			IdNumber: rawData.pan_number,
			IdType: "01"
		});
	}
	if (rawData.voter_card_number) {
		data.Applicant.Identifiers.Identifier.push({
			IdNumber: rawData.voter_card_number,
			IdType: "03"
		});
	}
	if (rawData.driving_license_number) {
		data.Applicant.Identifiers.Identifier.push({
			IdNumber: rawData.driving_license_number,
			IdType: "04"
		});
	}
	if (rawData.aadhaar_number) {
		data.Applicant.Identifiers.Identifier.push({
			IdNumber: rawData.aadhaar_number,
			IdType: "06"
		});
	}
	return data;
}
function getCrifData(rawData) {
	const data = {};
	data["tokenValue"] = rawData.tokenValue;
	data["transectionAmount"] = JSON.stringify(parseInt(rawData.amount));
	data["loan_app_enc_id"] = rawData.loan_app_enc_id;
	data["loan_co_app_enc_id"] = rawData.loan_co_app_enc_id ? rawData.loan_co_app_enc_id : null;
	const tempData = `<INQUIRY>
        <APPLICANT-SEGMENT>
          <APPLICANT-NAME>
            <NAME1>${rawData.name}</NAME1>
            <NAME2></NAME2>
            <NAME3></NAME3>
          </APPLICANT-NAME>
          <DOB>
            <DOB-DATE>${moment(rawData.co_applicant_dob).format("DD-MM-YYYY")}</DOB-DATE>
            <AGE></AGE>
            <AGE-AS-ON></AGE-AS-ON>
          </DOB>
          <IDS>
            ${
	rawData.driving_license_number
		? `<ID>
                  <TYPE>ID06</TYPE>
                  <VALUE>` +
					  rawData.driving_license_number +
					  `</VALUE>
                </ID>`
		: ""
}
					${
	rawData.pan_number
		? `<ID>
                  <TYPE>ID07</TYPE>
                  <VALUE>` +
					  rawData.pan_number +
					  `</VALUE>
                </ID>`
		: ""
}
            ${
	rawData.aadhaar_number
		? `<ID>
                  <TYPE>ID03</TYPE>
                  <VALUE>` +
					  rawData.aadhaar_number +
					  `</VALUE>
                </ID>`
		: ""
}
            ${
	rawData.voter_card_number
		? `<ID>
                    <TYPE>ID02</TYPE>
                    <VALUE>` +
					  rawData.voter_card_number +
					  `</VALUE>
                </ID>`
		: ""
}
          </IDS>
          <RELATIONS>
            <RELATION>
              <TYPE></TYPE>
              <NAME></NAME>
            </RELATION>
          </RELATIONS>
          <KEY-PERSON>
            <TYPE></TYPE>
            <NAME></NAME>
          </KEY-PERSON>
          <NOMINEE>
            <TYPE></TYPE>
            <NAME></NAME>
          </NOMINEE>
          <PHONES>
            <PHONE>
              <TELE-NO-TYPE>P03</TELE-NO-TYPE>
              <TELE-NO>${rawData.phone}</TELE-NO>
            </PHONE>
          </PHONES>
          <GENDER>${rawData.gender == 1 ? "G01" : rawData.gender == 2 ? "G02" : "G03"}</GENDER>
          <EMAILS>
            <EMAIL>${rawData.email}</EMAIL>
          </EMAILS>
        </APPLICANT-SEGMENT>
        <ADDRESS-SEGMENT>
          <ADDRESS>
            <TYPE>D01</TYPE>
            <ADDRESS-1>${rawData.address}</ADDRESS-1>
            <CITY>${rawData.city}</CITY>
            <STATE>${rawData.state_abbreviation}</STATE>
            <PIN>${rawData.postal_code}</PIN>
          </ADDRESS>
        </ADDRESS-SEGMENT>
        <APPLICATION-SEGMENT>
          <INQUIRY-UNIQUE-REF-NO>${
	Math.floor(Math.random() * 90000) + 10000
}</INQUIRY-UNIQUE-REF-NO>
          <CREDT-RPT-ID></CREDT-RPT-ID>
          <CREDT-REQ-TYP>INDV</CREDT-REQ-TYP>
          <CREDT-RPT-TRN-ID></CREDT-RPT-TRN-ID>
          <CREDT-INQ-PURPS-TYP>ACCT-ORIG</CREDT-INQ-PURPS-TYP>
          <CREDT-INQ-PURPS-TYP-DESC></CREDT-INQ-PURPS-TYP-DESC>
          <CREDIT-INQUIRY-STAGE>PRE-DISB</CREDIT-INQUIRY-STAGE>
          <CREDT-RPT-TRN-DT-TM></CREDT-RPT-TRN-DT-TM>
          <MBR-ID>NBF0003155</MBR-ID>
          <KENDRA-ID></KENDRA-ID>
          <BRANCH-ID></BRANCH-ID>
          <LOS-APP-ID></LOS-APP-ID>
          <LOAN-AMOUNT>${JSON.stringify(parseInt(rawData.amount))}</LOAN-AMOUNT>
        </APPLICATION-SEGMENT>
      </INQUIRY>`;
	data["data"] = tempData.replace(/\s+/g, " ").trim();
	return data;
}
