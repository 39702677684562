import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import NameComp from "../NameComp";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { EmployeeReportsContext } from "../../context/EmployeeReports";
import { MainContext } from "../../context/MainState";
import CollectionReportBranchesHeading from "./CollectionReportBranchesHeading";


const CollectionReportTableBranches = ({ loadDefault = true }) => {
	const { getCollectionReportStates, collectionReportStates, isLoading } =
		useContext(EmployeeReportsContext);
	const { states_list } = useContext(OrganizationDashboardContext);
	const { loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [sortArr, setSortArr] = useState({});
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		product_id: "",
		loan_type: "",
		field: "",
		order_by: ""
	});
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "1",
		label: "This Month"
	});
	const [selectedLoanType, setSelectedLoanType] = useState();
	const [loanTypesOptions, setLoanTypesOptions] = useState([]);

	
	const DataTableDetails = ({
		totalCases,
		colletionCases,
		targetAmount,
		verifiedAmount,
		unVerifiedAmount
	}) => {
		return (
			<div className="d-flex justify-content-around fix-collection-w w-100">
				<div>{totalCases ? parseFloat(totalCases).toLocaleString("en-In") : "0"}</div>
				<div>
					{colletionCases ? parseFloat(colletionCases).toLocaleString("en-In") : "0"}
				</div>
				<div>
					{targetAmount ? (
						<> ₹ {parseFloat(targetAmount).toLocaleString("en-IN")}</>
					) : (
						"0"
					)}
				</div>
				<div>
					{verifiedAmount ? (
						<> ₹ {parseFloat(verifiedAmount).toLocaleString("en-IN")}</>
					) : (
						"0"
					)}
				</div>
				<div>
					{unVerifiedAmount ? (
						<> ₹ {parseFloat(unVerifiedAmount).toLocaleString("en-IN")}</>
					) : (
						"0"
					)}
				</div>
			</div>
		);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state ? row.state : "-"),
			sortable: false
		},
		{
			name: (
				<CollectionReportBranchesHeading
					name={"Total"}
					search_id={"total"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
				/>
			),
			id: "on-time",
			style: { textAlign: "center" },
			minWidth: "800px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row.total_cases_count ? row.total_cases_count : ""}
						colletionCases={
							row.total_collected_cases_count ? row.total_collected_cases_count : ""
						}
						targetAmount={row.total_target_amount ? row.total_target_amount : ""}
						verifiedAmount={
							row.total_collected_verified_amount
								? row.total_collected_verified_amount
								: ""
						}
						unVerifiedAmount={
							row.total_collected_unverified_amount
								? row.total_collected_unverified_amount
								: ""
						}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportBranchesHeading 
					name={"On Time"} 
					search_id={"OnTime"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters} 
					sortArr={sortArr}
					setSortArr={setSortArr}
				/>
			),
			id: "on-time",
			style: { textAlign: "center" },
			minWidth: "800px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row.OnTime_cases_count ? row.OnTime_cases_count : ""}
						colletionCases={
							row.OnTime_collected_cases_count ? row.OnTime_collected_cases_count : ""
						}
						targetAmount={row.OnTime_target_amount ? row.OnTime_target_amount : ""}
						verifiedAmount={
							row.OnTime_collected_verified_amount
								? row.OnTime_collected_verified_amount
								: ""
						}
						unVerifiedAmount={
							row.OnTime_collected_unverified_amount
								? row.OnTime_collected_unverified_amount
								: ""
						}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportBranchesHeading 
					name={"0-30"} 
					search_id={"SMA0"} 
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters} 
					sortArr={sortArr}
					setSortArr={setSortArr}
				/>
			),
			id: "0-30",
			minWidth: "800px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row.SMA0_cases_count ? row.SMA0_cases_count : ""}
						colletionCases={
							row.SMA0_collected_cases_count ? row.SMA0_collected_cases_count : ""
						}
						targetAmount={row.SMA0_target_amount ? row.SMA0_target_amount : ""}
						verifiedAmount={
							row.SMA0_collected_verified_amount
								? row.SMA0_collected_verified_amount
								: ""
						}
						unVerifiedAmount={
							row.SMA0_collected_unverified_amount
								? row.SMA0_collected_unverified_amount
								: ""
						}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportBranchesHeading 
					name={"30-60"} 
					search_id={"SMA1"} 
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters} 
					sortArr={sortArr}
					setSortArr={setSortArr}
				/>
			),
			id: "30-60",
			minWidth: "800px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row.SMA1_cases_count ? row.SMA1_cases_count : ""}
						colletionCases={
							row.SMA1_collected_cases_count ? row.SMA1_collected_cases_count : ""
						}
						targetAmount={row.SMA1_target_amount ? row.SMA1_target_amount : ""}
						verifiedAmount={
							row.SMA1_collected_verified_amount
								? row.SMA1_collected_verified_amount
								: ""
						}
						unVerifiedAmount={
							row.SMA1_collected_unverified_amount
								? row.SMA1_collected_unverified_amount
								: ""
						}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportBranchesHeading 
					name={"60-90"} 
					search_id={"SMA2"} 
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters} 
					sortArr={sortArr}
					setSortArr={setSortArr}
				/>
			),
			id: "60-90",
			minWidth: "800px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row.SMA2_cases_count ? row.SMA2_cases_count : ""}
						colletionCases={
							row.SMA2_collected_cases_count ? row.SMA2_collected_cases_count : ""
						}
						targetAmount={row.SMA2_target_amount ? row.SMA2_target_amount : ""}
						verifiedAmount={
							row.SMA2_collected_verified_amount
								? row.SMA2_collected_verified_amount
								: ""
						}
						unVerifiedAmount={
							row.SMA2_collected_unverified_amount
								? row.SMA2_collected_unverified_amount
								: ""
						}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportBranchesHeading 
					name={"90+"} 
					search_id={"NPA"} 
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters} 
					sortArr={sortArr}
					setSortArr={setSortArr}
				/>
			),
			id: "90+",
			minWidth: "800px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row.NPA_cases_count ? row.NPA_cases_count : ""}
						colletionCases={
							row.NPA_collected_cases_count ? row.NPA_collected_cases_count : ""
						}
						targetAmount={row.NPA_target_amount ? row.NPA_target_amount : ""}
						verifiedAmount={
							row.NPA_collected_verified_amount
								? row.NPA_collected_verified_amount
								: ""
						}
						unVerifiedAmount={
							row.NPA_collected_unverified_amount
								? row.NPA_collected_unverified_amount
								: ""
						}
					/>
				);
			},
			sortable: false
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getCollectionReportStates({
			page: page.selected + 1,
			limit: rowsPerPage,
			start_date: filters.start_date,
			end_date: filters.end_date,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			fields_sort: sortArr,
			order_by: filters.order_by
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getCollectionReportStates({
			page: 1,
			limit: parseInt(e.target.value),
			start_date: filters.start_date,
			end_date: filters.end_date,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			fields_sort: sortArr,
			order_by: filters.order_by
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(collectionReportStates.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (collectionReportStates && collectionReportStates.data.length > 0) {
			return collectionReportStates.data;
		} else if (
			collectionReportStates &&
			collectionReportStates.data.length === 0 &&
			isFiltered
		) {
			return [];
		} else {
			return collectionReportStates && collectionReportStates.data.length > 0
				? collectionReportStates.data.slice(0, rowsPerPage)
				: [];
		}
	};
	const getCurrentMonthData = () => {
		const tempDate = new Date();
		const fromDate = moment(
			tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-1"
		).format("YYYY-MM-DD");
		const lastDay = new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0);
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const getTodayData = () => {
		const formattedToday = moment().format("YYYY-MM-DD");
		setFilters({
			...filters,
			start_date: formattedToday,
			end_date: formattedToday
		});
	};

	const getThisWeekData = () => {
		const today = new Date();
		const dayOfWeek = today.getDay();
		const startOfWeek = new Date(today);

		// Calculate the number of days to subtract to get to Monday
		const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
		startOfWeek.setDate(today.getDate() - daysToSubtract);

		const formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
		const formattedToday = today.toISOString().split("T")[0];

		setFilters({
			...filters,
			start_date: formattedStartOfWeek,
			end_date: formattedToday
		});
	};
	const getPrevMonthData = () => {
		const tempDate = new Date();
		const lastDay = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
		const fromDate = moment(
			(tempDate.getMonth() === 0 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) +
				"-" +
				(tempDate.getMonth() === 0 ? 12 : tempDate.getMonth()) +
				"-1"
		).format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const getThreeMonthData = () => {
		const tempDate = new Date();
		const startingMonth = (tempDate.getMonth() - 3 + 12) % 12;
		const lastDay = new Date(
			tempDate.getMonth() < 3 ? tempDate.getFullYear() - 1 : tempDate.getFullYear(),
			startingMonth + 3,
			0
		);
		const fromDate = moment(
			(tempDate.getMonth() <= 2 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) +
				"-" +
				(startingMonth + 1) +
				"-1"
		).format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};
	const handleSort = async (column, sortDirection) => {
		setFilters({
			...filters,
			field: column.id,
			order_by: sortDirection === "asc" ? 0 : 1
		});
	};

	const handleLoanTypeChange = selectedOptions => {
		// Extracting values from selected options
		const selectedTypeValues = selectedOptions.map(option => option.value);

		setSelectedLoanType(selectedOptions);

		// Modify the state accordingly
		setFilters({ ...filters, loan_type: selectedTypeValues });
	};

	useEffect(() => {
		if (loanAccountTypes && loanAccountTypes.length) {
			const temploanTypesOptions = loanAccountTypes.map(loanTypes => ({
				value: loanTypes.loan_type,
				label: loanTypes.loan_type
			}));
			setLoanTypesOptions(temploanTypesOptions);
		}
	}, [loanAccountTypes]);

	useEffect(() => {
		if (selectedStatusModel.value === "1") {
			getCurrentMonthData();
		} else if (selectedStatusModel.value === "0") {
			getPrevMonthData();
		} else if (selectedStatusModel.value === "2") {
			getThreeMonthData();
		} else if (selectedStatusModel.value === "3") {
			getTodayData();
		} else if (selectedStatusModel.value === "4") {
			getThisWeekData();
		}
	}, [selectedStatusModel]);

	useEffect(() => {
		if (loadDefault) {
			if (filters.start_date && filters.end_date) {
				const timerId = setTimeout(() => {
					getCollectionReportStates({
						page: 1,
						limit: rowsPerPage,
						// keyword: searchValue,
						start_date: filters.start_date,
						end_date: filters.end_date,
						loan_type: filters.loan_type,
						fields_search: searchArray,
						fields_sort: sortArr,
						order_by: filters.order_by
					});
					setCurrentPage(1);
				}, 1000);

				return () => {
					clearTimeout(timerId);
				};
			}
		}

		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [filters, searchArray, sortArr, loadDefault]);

	return (
		<div className="loan-application-table mb-5">
			<Row className="mx-0 mt-1 mb-5">
				<Col sm="9">
					<div className="d-flex align-items-center py-2">
						<div className="form-group">
							<label htmlFor="float-text" className="floating-label fw400 ft14">
								Show Results
							</label>
							<select
								className="dataTable-select form-select"
								style={{ width: "95px", padding: "2px 8px", height: "48px" }}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
						</div>
						<div
							style={{
								marginLeft: "15px",
								display: "flex",
								alignItems: "center"
							}}
						>
							<div className="form-group">
								<label htmlFor="float-text" className="floating-label fw400 ft14">
									Range
								</label>
								<Select
									options={[
										{
											value: "1",
											label: "This Month"
										},
										{ value: "3", label: "Today" },
										{ value: "4", label: "This Week" },
										{
											value: "0",
											label: "Last Month"
										},
										{
											value: "2",
											label: "Last Three Months"
										},
										{
											value: "Custom",
											label: "Custom Range"
										}
									]}
									className="customStyleSelect smSelect smOption z-1"
									value={selectedStatusModel}
									name="status"
									isSearchable={false}
									placeholder="Choose Status"
									onChange={option => {
										if (option.value !== selectedStatusModel.value) {
											setSelectedStatusModel(option);
										}
									}}
								/>
							</div>
						</div>
						{selectedStatusModel && selectedStatusModel.value === "Custom" ? (
							<>
								<div className="form-group">
									<label
										htmlFor="float-text"
										className="floating-label fw400 ft14"
									>
										From
									</label>
									<input
										type="date"
										className="form-control"
										style={{
											maxWidth: "150px",
											height: "48px",
											marginRight: "5px"
										}}
										value={filters.start_date}
										onChange={e => {
											setFilters({ ...filters, start_date: e.target.value });
										}}
									/>
								</div>
								<div className="form-group">
									<label
										htmlFor="float-text"
										className="floating-label fw400 ft14"
									>
										To
									</label>
									<input
										type="date"
										className="form-control"
										style={{ maxWidth: "150px", height: "48px" }}
										value={filters.end_date}
										onChange={e => {
											setFilters({ ...filters, end_date: e.target.value });
										}}
									/>
								</div>
							</>
						) : (
							""
						)}
						<div className="form-group mx-2">
							<label htmlFor="float-text" className="floating-label fw400 fs-5 z-2">
								Loan Type
							</label>
							<Select
								options={[...loanTypesOptions]}
								className="dashboard-stats-design smSelect m-0 fs-5 w-100"
								value={selectedLoanType}
								isSearchable={false}
								name="status"
								placeholder="Choose Loan Type"
								onChange={e => handleLoanTypeChange(e)}
								isMulti
							/>
						</div>
					</div>
				</Col>
				<Col
					className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
					sm="3"
				>
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button
								className="btn btn-primary btn-xs my-2"
								onClick={e => clearFilter(e)}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				pagination
				paginationServer
				columns={columns}
				data={dataToRender()}
				sortServer={true}
				onSort={handleSort}
				paginationComponent={CustomPagination}
				progressPending={isLoading === "employeeStat"}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent={
					<p className="w-100 m-5 text-start">There are no records to display</p>
				}
				persistTableHead={true}
				className="loan-app-table-container fix-overflow report-tables z-0"
				fixedHeader
				// fixedHeaderScrollHeight="600px"
			/>
		</div>
	);
};
export default CollectionReportTableBranches;
