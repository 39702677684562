import React, { useContext, useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useForm } from "react-hook-form";
import axios from "axios";
import moment from "moment";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import { MainContext } from "../context/MainState";
import { FaEdit } from "react-icons/fa";

const UploadAnnouncement = () => {
	const [isChecked, setIsChecked] = useState({ check: true, id: "" });
	const [allNotices, setAllNotices] = useState([]);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm();
	const { getOrganizationNotices, notices } = useContext(OrganizationDashboardContext);
	const { handlePageLoaded, user } = useContext(MainContext);
	const [noticeId, setNoticeId] = useState("");

	const changeStatus = async (notice_id, val, type = null) => {
		const myArray = [...notices];
		const objIndex = myArray.findIndex(obj => obj.notice_enc_id === notice_id);
		let data = {};
		if (type === "delete") {
			if (!window.confirm("Are you sure you want to delete")) {
				return false;
			}
			myArray.splice(objIndex, 1);
			setAllNotices(myArray);
			data = { delete: true, notice_enc_id: notice_id };
		} else {
			myArray[objIndex].status = val.status === "1" ? "0" : "1";
			setAllNotices(myArray);
			let status = val.status === "0" ? false : true;
			data = { status: status, notice_enc_id: notice_id };
		}

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-notice`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setIsChecked({ ...isChecked, id: "" });
					toast.success(` ${type === "delete" ? "Deleted" : "Updated"}  Successfully`);
					if (type === "delete") {
						getOrganizationNotices({type: "all"});
					}
				} else {
					toast.error(
						response.data.response.message
							? response.data.response.message
							: "An error occurred"
					);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(
					"An error has occurred while uploading Organization Announcements, Please try again"
				);
			});
	};

	const onSubmit = data => {
		handlePageLoaded(true);
		data["type"] = 1;
		if(noticeId){
			data["notice_enc_id"] = noticeId;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/add-notice`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					reset();
					getOrganizationNotices({type: "all"});
					toast.success(`${noticeId ? "Update" : "Saved"} Successfully`);
					setNoticeId("");
				} else {
					toast.error(
						response.data.response.message
							? response.data.response.message
							: "An error occurred"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	const editAnnouncements = value => {
		let data = notices.filter(notice => notice.notice_enc_id === value);
		setValue("notice", data[0]["notice"]);
		setNoticeId(value);
	};

	useEffect(() => {
		if(user.organization_username && user.organization_username === "phfleasing" && (user.username === "Isudhanshu4u" || user.username === "phf219")){
			getOrganizationNotices({type: "all"});
		}
		setAllNotices(notices);
	}, [notices]);
	return (
		<>
			<h2>Announcements</h2>
			<div className="my-4 offset-3">
				<h4>Add New Announcement</h4>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="col-md-7">
						<div className="mb-3">
							<textarea
								className="form-control"
								id="app_description"
								rows="4"
								{...register("notice", {
									required: "This is required."
								})}
								placeholder="Enter Announcement"
							></textarea>
							<ErrorMessage
								errors={errors}
								name="notice"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<div className="uploadBtn">
							<button type="submit" className="btn btn-primary">
								Save
							</button>
						</div>
					</div>
				</form>
			</div>
			<hr />
			<div className="mt-5">
				<h4>Announcement List</h4>
				<div className="row">
					<div className="imageList">
						<table className="table">
							<thead>
								<tr>
									<th>Announcement</th>
									<th>Date & Time</th>
									<th className="text-center">Status</th>
									<th className="text-center">Edit</th>
									<th className="text-center">Delete</th>
								</tr>
							</thead>
							<tbody className="imageListTd">
								{allNotices && allNotices.length ? (
									allNotices.map((value, index) => {
										return (
											<>
												{value.notice ? (
													<tr key={`announcement-${index}`}>
														<td>
															<div className="upldNotice">
																<HiOutlineSpeakerphone
																	style={{
																		color: "rgb(41, 121, 255)"
																	}}
																/>{" "}
																{value.notice}
															</div>
														</td>
														<td>
															<div className="uploadDateTime">
																<p className="text-muted mb-0">
																	{moment(
																		value.created_on
																	).format("DD MMM YYYY")}
																</p>
															</div>
														</td>
														<td className="text-center">
															<div className="toggle-button_wrap">
																<input
																	type="checkbox"
																	id={value.notice_enc_id}
																	className="toggler"
																	onChange={() =>
																		changeStatus(
																			value.notice_enc_id,
																			value
																		)
																	}
																	checked={value.status === "1"}
																/>
																<label
																	className="toggle-button"
																	htmlFor={value.notice_enc_id}
																></label>
																<div
																	className={`toggle ${
																		isChecked ? "on" : "off"
																	}`}
																></div>
															</div>
														</td>
														<td className="text-center">
															<button
																type="button"
																className="btn btn-primary btn-xs"
																onClick={() =>
																	editAnnouncements(value.notice_enc_id)
																}
															>
																<FaEdit fontSize={15} />
															</button>
														</td>
														<td className="text-center">
															<button
																type="button"
																onClick={() =>
																	changeStatus(
																		value.notice_enc_id,
																		value,
																		"delete"
																	)
																}
																className="btn btn-primary btn-xs"
															>
																<MdDelete fontSize={15} />
															</button>
														</td>
													</tr>
												) : (
													""
												)}
											</>
										);
									})
								) : (
									<tr>
										<td colSpan="4"> No notices to display</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default UploadAnnouncement;
