import axios from "axios";
import { useEffect, useState } from "react";
import AccessDenied from "./dashboard/AccessDenied";
import { BiRupee } from "react-icons/bi";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getLatLong, isLocationEnabled } from "../helpers/customFunctions";

const IndividualLoanAccountDetails = ({loan_id}) => {
	const [loading, setLoading] = useState(false); 
	const [emiAccountDetails, setEmiAccountDetails] = useState("");
	const [checkStatus, setCheckstatus] = useState(false);
	const [collectNow, setCollectNow] = useState(false);
	const { handleSubmit } = useForm();
	const [pageLoading, setPageLoading] = useState();

	const openSideBar = () => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/customer-loan-account-detail`,
				{ loan_account_enc_id: loan_id}
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					setEmiAccountDetails(response.data.response.data);
				} else if (response.data.response.status === 404){
					setCheckstatus(true);
				} else {
					setEmiAccountDetails([]);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error(error.message);
				setLoading(false);
		  });
	};

	const onSubmit = async data => {
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			return;
		}
		try {
			const { latitude, longitude } = await getLatLong();
			data.latitude = latitude;
			data.longitude = longitude;
			data.emi_amount = emiAccountDetails.emi_amount ? emiAccountDetails.emi_amount : "0";
			data.pay_by = "0";
			data.loan_account_enc_id = loan_id;
			setPageLoading(true);
			const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/create-qr`, data);
			setPageLoading(false);
			if (response?.data?.response && response?.data?.response?.status === 200) {
				toast.success("Redirecting to Payment Gateway");
				if (response.data.response.link) {
					window.location.replace(response.data.response.link);
					setCollectNow(false);
				}
			} else {
				toast.error(
					response?.data?.response?.message
						? response.data.response.message.toString()
						: "An Error occurred"
				);
			}
		} catch (error) {
			setPageLoading(false);
			console.log(error);
			toast.error(error.message);
		}
	};

	const renderEmiDate = (date) => {
		const currMonth = new Date();
		let month  = moment().format("MMMM");
		if (parseInt(date) < currMonth.getDate()) {
			month = moment().add(1, "months").format("MMMM");
		}
		return date + " " + month;
	};

	useEffect(() => {
		if(loan_id){
			openSideBar();
		}
	}, [loan_id]);
	return(
		<>
			{checkStatus === true ? (
				<AccessDenied title="Loan Account Not found" statusType="checkStatus"/>
			) : 
        	loading ? (
					<div className="cus-loader">
						<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
					</div>
				) : ( 
					<>
						<div className="expend-data individual-expand-data"
							style={{ maxWidth: "1440px", margin: "0 auto",position: "relative" }}
						>
							<div className="emis-details-tab p-3 pt-0">
								<div className="row mb-1 mb-3 p-2">
									<div className="col-md-12">
										<h2 className="mb-3">Loan Account Details</h2>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex flex-wrap mb-1 ht35">
                Loan Account Number:{" "}
											<span className="fw-medium text-gray-700 mx-2 text-capitalize">
												{emiAccountDetails?.loan_account_number}
											</span>
										</p>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex mb-1 ht35">
                Name:{" "}
											<span className="fw-medium text-gray-700 mx-2 text-capitalize">
												{emiAccountDetails?.name}
											</span>
										</p>
									</div>
									<div className="col-md-6 ht35 res-bucket">
										<p className="d-flex flex-wrap mb-2 ht35 ">
												Overdue EMI:{" "}
											<span className="fw-medium text-gray-700 mx-2 text-capitalize">
												{emiAccountDetails?.overdue_amount ? (
													<>
														<BiRupee size={16} className="me-n1" />{" "}
														{parseFloat(
															emiAccountDetails?.overdue_amount
														).toLocaleString("en-IN")}
													</>
												) : (
													"--"
												)}
											</span>
										</p>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex flex-wrap mb-2 ht35">
												Loan Type:{" "}
											<span className="fw-medium text-gray-700 mx-2 text-capitalize">
												{emiAccountDetails?.loan_type}
											</span>
										</p>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex flex-wrap mb-3 ht35">
												EMI Amount:{" "}
											{emiAccountDetails?.emi_amount ?
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiAccountDetails?.emi_amount}
												</span>
											 : "--"}
												
										</p>
									</div>
									<div className="col-md-6 ht35">
										<p className="d-flex flex-wrap mb-3 ht35">
												EMI Date:{" "}
											{emiAccountDetails?.emi_date ?
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{renderEmiDate(emiAccountDetails.emi_date)}
												</span>
											 : "--"}
										</p>
									</div>
									<div className="col-md-12">
										<p className="d-flex flex-wrap align-items-center justify-content-center res-3buttn">
											<span className="">
												<button type="button" className="btn btn-primary btn-sm mb-0" onClick={() => setCollectNow(true)}>
                                        		Pay EMI
												</button>
											</span>
										</p>
									</div>
								</div>
							</div>
					
						</div>
						<Modal show={collectNow} onHide={() => setCollectNow(false)} size={"md"} centered>
							<Modal.Body>
								<form onSubmit={handleSubmit(onSubmit)} className="form">
									<fieldset>
										<div className="container">
											<div className="row">
												<div className="col-12 text-center mb-3">
													<h2>Pay EMI</h2>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<p className="d-flex align-items-center flex-wrap">
												EMI Amount :
														<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													₹{" "}
															{emiAccountDetails?.emi_amount
																? parseFloat(
																	emiAccountDetails?.emi_amount
														  ).toLocaleString("en-IN")
																: "0"}
														</span>
													</p>
												</div>
											</div>
											<div className="text-center mt-3">
												{pageLoading ? (
													<button type="button" className="btn btn-primary" disabled={true}>
														<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
													</button>
												) : (
													<button type="submit" className="btn btn-primary">
														Generate Payment Link
													</button>
												)}
											</div>
										</div>
									</fieldset>
								</form>
							</Modal.Body>
						</Modal>
					</>
				)
			}              
		</>
	);
};

export default IndividualLoanAccountDetails;