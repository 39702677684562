import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { toast } from "react-toastify";
import { CgFileAdd } from "react-icons/cg";

const NachRegisterModal = ({reload, loanDetails, applicantName, type=null}) => {
	
	const [showNachModal, setShowNachModal] = useState(false);
	const { register, watch, handleSubmit, setValue, reset, formState: {errors} } = useForm();
	const { handlePageLoaded } = useContext(MainContext);
	const [showLink, setShowLink] = useState("");
	const authType = watch("auth_type");
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const accountNumber = /^[0-9]{8,16}$/;
	const onSubmit = data => {
		if (!loanDetails?.loan_account_enc_id && !loanDetails?.loan_app_enc_id) {
			toast.error("Loan account not found");
			return;
		  }
		if (!data["loan_amount"]) {
			data["loan_amount"] = loanDetails?.financed_amount || loanDetails?.disbursement_approved || loanDetails?.amount;
		}
		data["method"] = data.auth_type === "upi" ? "upi" : "emandate";
		data["mandate_expire_time"] = data.auth_type === "upi" ? "20" : "40";
		data["loan_account_enc_id"] = loanDetails?.loan_account_enc_id;
		data["loan_app_enc_id"] = loanDetails?.loan_app_enc_id;
		data["description"] = "Registration for " + (loanDetails?.loan_account_number ?  loanDetails?.loan_account_number :  loanDetails?.application_number);
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}payments/register-enach`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("eNach Initiated Successfully");
					setShowLink(response.data.response.data.url);
					hideModal();
					reset();
				} else {
					toast.error(response.data?.response?.message ? response.data.response.message : "An error occurred #201");
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
				handlePageLoaded(false);
			});
	};

	const hideModal = () => {
		setShowNachModal(false);
		reset();    
	};

	const hideLinkModal = () => {
		setShowLink(false);
		if(type === "loan_applications"){
			reload(loanDetails.loan_app_enc_id);
		}else{
			reload(loanDetails.loan_account_enc_id);
		}
	};

	const openNachModal = () => {
		setShowNachModal(true);
	};

	const inputHandler = event => {
		setShowLink(event.target.value);
	};

	const copy = async () => {
		await navigator.clipboard.writeText(showLink);
		toast.success("Link copied");
	};

	useEffect(() => {
		if (authType === "upi") {
			setValue("loan_amount", 100000);
	  	} else if(loanDetails?.financed_amount || loanDetails?.disbursement_approved || loanDetails?.amount){
			setValue("loan_amount", loanDetails?.financed_amount || loanDetails?.disbursement_approved || loanDetails?.amount);
		}
	},[authType, loanDetails]);

	return (
		<>
			<button
				type="button"
				className="btn btn-secondary btn-xs mb-0"
				// className={type === "loan_account" ? "btn btn-secondary btn-xs mb-0"  : type === "emi-modal"
				// 	? "btn btn-primary btn-xs": "btn btn-primary"}
				onClick={() => openNachModal()}
			>
			  <CgFileAdd size={16}/> Register eNACH
			</button>
			<Modal
				show={showNachModal}
				onHide={hideModal}
				size={"lg"}
			>
				<Modal.Header>
					<Modal.Title>Nach Registration</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label fs-5 mb-0" htmlFor="name">
                                        Customer Name <span style={{ color: "red", fontSize: "13px" }}>*</span>
									</label>
									<input
										placeholder="Customer Name"
										className="form-control"
										{...register("name", {
											required: "This is required."
										})}
										value={applicantName ? applicantName : ""}
									/>
									<ErrorMessage
										errors={errors}
										name="name"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label fs-5 mb-0" htmlFor="email">
							            Email <span style={{ color: "red", fontSize: "13px" }}>*</span>
									</label>
									<input
										placeholder="Email"
										className="form-control"
										{...register("email", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="email"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label fs-5 mb-0" htmlFor="phone">
                                        Phone Number <span style={{ color: "red", fontSize: "13px" }}>*</span>
									</label>
									<div>
										<div className="d-flex">
											<input
												style={{
													width: "50px",
													padding: "8px",
													height: "49px",
													marginRight: "-4px"
												}}
												type="text"
												value="+91"
												className="form-control"
												disabled={true}
											/>
											<input
												style={{
													borderLeftColor: "transparent",
													borderTopLeftRadius: 0,
													borderBottomLeftRadius: 0
												}}
												type="tel"
												placeholder="Phone"
												className="form-control"
												{...register("mobile", {
													required: "This is required.",
													minLength: {
														value: 10,
														message:
                                                        "Mobile number Should be at least 10 numbers"
													},
													maxLength: {
														value: 10,
														message:
                                                        "Mobile number maximum be at least 10 numbers"
													},
													pattern: {
														value: phoneRegExp,
														message: "Invalid Phone number"
													}
												})}
											/>
										</div>
										<ErrorMessage
											errors={errors}
											name="mobile"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
											)}
										/>  
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label fs-5 mb-0" htmlFor="token_payment">
							            Token Amount <span style={{ color: "red", fontSize: "13px" }}>*</span>
									</label>
									<input
									    type="number"
										max={15}
										min={10}
										defaultValue={10}
										placeholder="Nach Amount"
										className="form-control"
										readOnly={true}
										{...register("token_payment", {
											required: "This is required."
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="token_payment"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="mb-3">
									<label className="form-label fs-5 mb-0" htmlFor="loan_amount">
							            Maximum Amount <span style={{ color: "red", fontSize: "13px" }}>*</span>
									</label>
									<input
										value={authType === "upi" ? 100000 : (loanDetails.financed_amount || loanDetails.disbursement_approved || loanDetails.amount)}
										disabled={true}
										placeholder="Max Deductable Amount"
										className="form-control"
										{...register("loan_amount", {
											required: false
										})}
									/>
									{authType === "upi" ? (
										<p className="fs-6 text-danger fw-medium"> <sup>*</sup>For UPI, the maximum one-time deductible amount is 1,00,000 as per RBI rules.</p>
									): ""}
								</div>
							</div>
							<div className="col-12">
								<div>
									<label className="form-label mb-0" htmlFor="nach_payment_method">
										Payment Method <span style={{ color: "red", fontSize: "13px" }}>*</span>
									</label>
								</div>
								<div className="radio-wrapper mb-3">
									<div className="radio-item">
										<input
											type="radio"
											name="nach_payment_method"
											className="radioInput"
											id="nach_payment_method_2"
											value="debitcard"
											{...register("auth_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="nach_payment_method_2" className="option option-1 mb-1">
											<span>Debit Card</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="nach_payment_method"
											className="radioInput"
											id="nach_payment_method_3"
											value=""
											{...register("auth_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="nach_payment_method_3" className="option option-1 mb-1">
											<span>Emandate</span>
										</label>
									</div>
									<ErrorMessage
										errors={errors}
										name="nach_payment_method"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
									<div className="radio-item">
										<input
											type="radio"
											name="nach_payment_method"
											className="radioInput"
											id="nach_payment_method_4"
											value="upi"
											{...register("auth_type", {
												required: "This is required."
											})}
										/>
										<label htmlFor="nach_payment_method_4" className="option option-1 mb-1">
											<span>UPI</span>
										</label>
									</div>
								</div>
								{authType !== "upi" ? (
									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
											<div className="mb-3">
												<label className="form-label fs-5 mb-0" htmlFor="bank_account_number">
												Account Number <span style={{ color: "red", fontSize: "13px" }}>*</span>
												</label>
												<input
											    type="number"
													placeholder="Bank Account Number"
													className="form-control"
													{...register("bank_account_number", {
														required: "This is required.",
														minLength: {
															value: 8,
															message:
														"Bank Account Number must contain at least 8 digits."
														},
														maxLength: {
															value: 16,
															message:
														"Bank Account Number should have a maximum of 16 digits."
														},
														pattern: {
															value: accountNumber,
															message: "Invalid Account number"
														}
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="bank_account_number"
													render={({ message }) => (
														<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
													)}
												/>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
											<div className="mb-3">
												<label className="form-label fs-5 mb-0" htmlFor="bank_account_holder_name">
												Account Holder Name <span style={{ color: "red", fontSize: "13px" }}>*</span>
												</label>
												<input
													placeholder="Enter Name"
													className="form-control"
													{...register("bank_account_holder_name", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="bank_account_holder_name"
													render={({ message }) => (
														<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
													)}
												/>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
											<div className="mb-3">
												<label className="form-label fs-5 mb-0" htmlFor="bank_account_type">
												Account Type <span style={{ color: "red", fontSize: "13px" }}>*</span>
												</label>
												<select
													className="form-control"
													{...register("bank_account_type", {
														required: "This is required."
													})}>
													<option value="savings">Savings</option>
													<option value="current">Current</option>
												</select>
												<ErrorMessage
													errors={errors}
													name="bank_account_type"
													render={({ message }) => (
														<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
													)}
												/>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
											<div className="mb-3">
												<label className="form-label fs-5 mb-0" htmlFor="bank_account_ifsc_code">
												IFSC Code <span style={{ color: "red", fontSize: "13px" }}>*</span>
												</label>
												<input
													placeholder="Bank IFSC Code"
													className="form-control"
													{...register("bank_account_ifsc_code", {
														required: "This is required."
													})}
												/>
												<ErrorMessage
													errors={errors}
													name="bank_account_ifsc_code"
													render={({ message }) => (
														<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
													)}
												/>
											</div>
										</div>
									</div>
								):""}
							</div>
							<div className="text-center">
								<button type="submit" className="btn btn-primary">
                                Save{" "}
								</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>

			<Modal show={showLink} onHide={() => hideLinkModal()} size={"md"} centered>
				<Modal.Body>
					<fieldset>
						<div className="container">
							<div className="row align-items-center">
								<div className="col-12">
									<label className="form-label fs-5 d-block titles">
										Emendate Registration Link
									</label>
									<input
										type="text"
										className="form-control"
										value={showLink}
										disabled={true}
										onChange={inputHandler}
									/>
									<button
										type="button"
										className="btn btn-primary m-auto d-block mt-3"
										onClick={copy}
									>
										Copy
									</button>
								</div>
							</div>
						</div>
					</fieldset>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default NachRegisterModal;