import MinMaxFilter from "../MinMaxFilter";
import NameComp from "../NameComp";

const CollectionReportHeadings = ({name, searchArray, setSearchArray, sortArr, setSortArr, clearFilters, type = null, isRemove}) => {

	return(
		<>  
        	<div className="w-100">
				<h3 className="text-center">{name}</h3>
				<div className="d-flex justify-content-around fix-collection-w">
					<div>
						<MinMaxFilter
							title={"EMI's Collected"}
							id={type !== "total" ? `${type}_total_cases_count` : "total_cases_count"}
							placeholder="EMI's Collected"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<NameComp
							title={"Collected Amount"}
							id={`${type}_collected_cases_sum`}
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<NameComp
							title={"Verified Amount"}
							id={`${type}_collected_verified_amount`}
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<NameComp
							title={"Unverified Amount"}
							id={`${type}_collected_unverified_amount`}
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>

					<div>
						<MinMaxFilter
							title={"Interaction Count"}
							id= {type !== "total" ? `${type}_total_interaction_count` : "total_interaction_count"}
							placeholder="Interaction Count"
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>
					
					<div>
						<NameComp
							title={"Interaction Amount"}
							id={type !== "total" ? `${type}_total_interaction_sum` : "total_interaction_sum"}
							searchArray={searchArray}
							setSearchArray={setSearchArray}
							sortArr={sortArr}
							setSortArr={setSortArr}
							sortType="numeric"
							clearFilters={clearFilters}
						/>
					</div>
					{!isRemove ? (
						<div>
							<MinMaxFilter
								title={"Registered eNACH"}
								id= {type !== "total" ? `${type}_total_registered_enach` : "total_registered_enach"}
								placeholder="Registered eNACH"
								searchArray={searchArray}
								setSearchArray={setSearchArray}
								sortArr={sortArr}
								setSortArr={setSortArr}
								sortType="numeric"
								clearFilters={clearFilters}
							/>
						</div>
						
					) : (" ")}
					
				</div>
			</div>
		</>
	);
};

export default CollectionReportHeadings;