import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
	FaCopy,
	FaEnvelope,
	FaFacebookF,
	FaInfoCircle,
	FaTwitter,
	FaWhatsapp
} from "react-icons/fa";
import { MainContext } from "../../context/MainState";
import { toast } from "react-toastify";

const InviteLinks = () => {
	const { user } = useContext(MainContext);
	const openWindow = link => {
		window.open(link, "_blank", "width=800,height=400,left=200,top=100");
	};
	const copyToClipboard = link => {
		navigator.clipboard.writeText(link);
		toast.success("Copied to clipboard");
	};

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const handleKeyPress = event => {
		if (event.key === "Enter") {
			if (event.target.value.length === 10) {
				if (phoneRegExp.test(event.target.value)) {
					openWindow(
						`https://api.whatsapp.com/send?phone=+91${event.target.value}&text=${window.location.origin}/apply?ref_id=${user.referral_code}`
					);
				} else {
					toast.warning("Invalid Mobile Number");
				}
			} else {
				toast.warning("Mobile Number can only be of 10 digits");
			}
		}
	};
	return (
		<>
			{user.referral_code ? (
				<div className="p-4 pb-2">
					<div className="row justify-content-center">
						<div
							className={`${
								user.user_type === "Dealer" ? "col-12" : "col-md-6 col-sm-6"
							}`}
						>
							<div className="mb-3 effect thurio tr-height">
								<h3 className="text-white size-set">
									Invite Customer
									<OverlayTrigger
										placement="left"
										delay={{ show: 250, hide: 400 }}
										overlay={
											<Tooltip id="invite-customer">
												This is your personalized invite link which is used
												to add referral leads direct into your account
											</Tooltip>
										}
									>
										<div style={{ display: "inline-block", marginLeft: "8px" }}>
											<FaInfoCircle size={22} />
										</div>
									</OverlayTrigger>
								</h3>
								<div className="buttons">
									<button
										className="facebook-f link-button"
										onClick={() => {
											openWindow(
												`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/apply?ref_id=${user.referral_code}`
											);
										}}
									>
										<FaFacebookF />
									</button>
									<button
										className="twitter-t link-button"
										onClick={() => {
											openWindow(
												`https://twitter.com/intent/tweet?text=&url=${window.location.origin}/apply?ref_id=${user.referral_code}`
											);
										}}
									>
										<FaTwitter />
									</button>
									{/* <a href="javascript:;" className="linked-l"*/}
									{/*   onClick={() => {*/}
									{/*       openWindow(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.origin}/apply?ref_id=${user.referral_code}&source=${window.location.origin}/apply?ref_id=${user.referral_code}`)*/}
									{/*   }}>*/}
									{/*    <FaLinkedinIn />*/}
									{/* </a>*/}
									<button
										className="whatsapp-w link-button"
										onClick={() => {
											openWindow(
												`https://api.whatsapp.com/send?text=${window.location.origin}/apply?ref_id=${user.referral_code}`
											);
										}}
									>
										<FaWhatsapp />
									</button>
									<button
										className="enve-e link-button"
										onClick={() => {
											openWindow(
												`mailto:?&body=${window.location.origin}/apply?ref_id=${user.referral_code}`
											);
										}}
									>
										<FaEnvelope />
									</button>
									<button
										className="enve-e link-button"
										onClick={() => {
											copyToClipboard(
												`${window.location.origin}/apply?ref_id=${user.referral_code}`
											);
										}}
									>
										<FaCopy />
									</button>
								</div>
								<div className="wts-ap">
									<div className="col-md-12 p0 form-whats d-flex">
										<input
											type="text"
											value="+91"
											style={{ maxWidth: "45px", padding: "6px" }}
										/>
										<input
											type="tel"
											id="whatsappshareform-phone"
											className="wts-txt"
											name="whatsAppShareForm[phone]"
											placeholder="98 XXXX XXXX"
											onKeyDown={handleKeyPress}
										/>
										{/* <div className="send"><FaArrowRight/></div>*/}
									</div>
								</div>
							</div>
						</div>
						{user.user_type === "Financer" ? (
							<div className="col-md-6 col-sm-6">
								<div className="mb-3 dsa-box tr-height">
									<h3 className="text-white size-set">
										Invite Employee
										<OverlayTrigger
											placement="left"
											delay={{ show: 250, hide: 400 }}
											overlay={
												<Tooltip id="invite-emp">
													This is your personalized invite link which is
													used to add Employee into your account
												</Tooltip>
											}
										>
											<div
												style={{
													display: "inline-block",
													marginLeft: "8px"
												}}
											>
												<FaInfoCircle size={22} />
											</div>
										</OverlayTrigger>
									</h3>
									<input
										type="text"
										className="link line-clamp"
										id="dsaLink"
										defaultValue={`${window.location.origin}/signup/employee/${user.referral_code}`}
									/>
									<button
										className="create-btn link-button"
										onClick={() => {
											copyToClipboard(
												`${window.location.origin}/signup/employee/${user.referral_code}`
											);
										}}
									>
										Copy Link
									</button>
								</div>
							</div>
						) : (
							""
						)}
						{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
							<>
								{user.user_type === "Financer" ? (
									<div className="col-md-6 col-sm-6">
										<div className="mb-3 dsa-box tr-height">
											<h3 className="text-white size-set">
												Invite DSA
												<OverlayTrigger
													placement="left"
													delay={{ show: 250, hide: 400 }}
													overlay={
														<Tooltip id="invite-dsa">
															This is your personalized invite link which is
															used to add DSA into your account
														</Tooltip>
													}
												>
													<div
														style={{
															display: "inline-block",
															marginLeft: "8px"
														}}
													>
														<FaInfoCircle size={22} />
													</div>
												</OverlayTrigger>
											</h3>
											<input
												type="text"
												className="link line-clamp"
												id="dsaLink"
												defaultValue={`${window.location.origin}/signup/dsa/${user.referral_code}`}
											/>
											<button
												className="create-btn link-button"
												onClick={() => {
													copyToClipboard(
														`${window.location.origin}/signup/dsa/${user.referral_code}`
													);
												}}
											>
												Copy Link
											</button>
										</div>
									</div>
								) : (
									""
								)}
								{user.user_type === "Employee" ||
								user.user_type === "DSA" ||
								user.user_type === "Financer" ? (
										<div className="col-md-6 col-sm-6">
											<div className="mb-3 dsa-box tr-height">
												<h3 className="text-white size-set">
												Invite Connector
													<OverlayTrigger
														placement="left"
														delay={{ show: 250, hide: 400 }}
														overlay={
															<Tooltip id="invite-connector">
															This is your personalized invite link which is
															used to add Connector direct into your account
															</Tooltip>
														}
													>
														<div
															style={{
																display: "inline-block",
																marginLeft: "8px"
															}}
														>
															<FaInfoCircle size={22} />
														</div>
													</OverlayTrigger>
												</h3>
												<input
													type="text"
													className="link line-clamp"
													id="dsaLink"
													defaultValue={`${window.location.origin}/signup/connector/${user.referral_code}`}
												/>
												<button
													className="create-btn link-button"
													onClick={() => {
														copyToClipboard(
															`${window.location.origin}/signup/connector/${user.referral_code}`
														);
													}}
												>
												Copy Link
												</button>
											</div>
										</div>
									) : (
										""
									)}
							</>
						) : ""}
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};
export default InviteLinks;
