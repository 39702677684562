import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Main from "./Main";
import { ToastContainer } from "react-toastify";
import { MainProvider } from "./context/MainState";
import { useEffect } from "react";
import { askForPermissionToReceiveNotifications } from "./helpers/firebaseInit";

function App() {

	const showInternetError = () => {
		document.getElementById("root").insertAdjacentHTML("beforeBegin", "<div id='network-status'>It seems you are offline, Please check your internet connection</div>");
		setTimeout(() => {
			document.getElementById("network-status").classList.add("failed");
		}, 500);
	};
	const hideInternetError = () => {
		document.getElementById("network-status").classList.remove("failed");
		document.getElementById("network-status").innerHTML = "Back online";
		document.getElementById("network-status").classList.add("connected");
		setTimeout(() => {
			document.getElementById("network-status").remove();
		}, 3000);
	};

	useEffect(() => {
		askForPermissionToReceiveNotifications();

		window.addEventListener("offline", showInternetError);
		window.addEventListener("online", hideInternetError);

		return () => {
			window.removeEventListener("offline", showInternetError);
			window.removeEventListener("online", hideInternetError);
		};
	}, []);
	return (
		<>
			<MainProvider>
				<BrowserRouter>
					<ToastContainer />
					<Main />
				</BrowserRouter>
			</MainProvider>
		</>
	);
}

export default App;
