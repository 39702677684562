import React, { useContext, useEffect, useState } from "react";
import { Badge, Col, Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { jsPDF } from "jspdf";
import { FaClock, FaPlus, FaUserCheck } from "react-icons/fa";
import { MainContext } from "../../context/MainState";
import { GiCheckMark, GiSandsOfTime } from "react-icons/gi";
import moment from "moment/moment";
import CaptureImageForm from "./CaptureImageForm";
import PdfToImg from "./PdfToImg";
import { OrganizationSidebarContext } from "../../context/OrganizationSidebarState";
import PendenciesModal from "../dashboard/PendenciesModal";
import RegistryCollected from "../dashboard/RegistryCollected";
import { IoCloseCircle } from "react-icons/io5";
import { getLatLong, isLocationEnabled } from "../../helpers/customFunctions";

let Pagewidth2;
let doc2;
let Pageheight2;
let pdf_uri2;
let base_uri2 = "";

const FinancerDocumentsTab = ({
	rawData,
	showDocuments,
	allCertificates,
	getDocumentsData,
	isLoading,
	handleChangeData
}) => {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors }
	} = useForm();
	const document_for = watch("assigned_to");
	const [addDocuments, setAddDocuments] = useState(false);
	const [addImage, setAddImage] = useState(false);
	const [selectedOptionsModel, setSelectedOptionsModel] = useState({});
	const [shortDescription, setShortDescription] = useState("");
	const [uploadedFile, setUploadedFile] = useState(false);
	const [uploadedDocumentName, setUploadedDocumentName] = useState({
		name: "",
		id: "",
		type: ""
	});
	const { documents_types, getAssignedLoanDocuments, handlePageLoaded, user } = useContext(MainContext);
	const [loanDocuments, setLoanDocuments] = useState([]);
	const [sortedDocuments, setSortedDocuments] = useState([]);
	const { getLoanApplicationPendencies, assignedPendencies, getPendencyImages, pendencyImages } =
		useContext(OrganizationSidebarContext);
	const [allPendencies, setAllPendencies] = useState([]);
	const [allPendencyImages, setAllPendencyImages] = useState([]);
	const handleShow = () => {
		setAddDocuments(true);
	};
	const hideModal = () => {
		setAddDocuments(false);
	};
	const handleThumbnail = link => {
		if (link.includes(".doc") || link.includes(".docx")) {
			return "/images/icons/word.svg";
		}
		if (link.includes(".xls") || link.includes(".xlsx")) {
			return "/images/icons/excel.svg";
		}
		return "/images/icons/pdf.svg";
	};
	const optionList = [
		{
			value: "PAN Card",
			label: "PAN Card"
		},
		{
			value: "Passport",
			label: "Passport"
		},
		{
			value: "Aadhar Card",
			label: "Aadhar Card"
		},
		{
			value: "10th dmc",
			label: "10th dmc"
		},
		{
			value: "12th dmc",
			label: "12th dmc"
		},
		{
			value: "Graduation Degree",
			label: "Graduation Degree"
		},
		{
			value: "Semester DMC",
			label: "Semester DMC"
		},
		{
			value: "Post Graduation Degree",
			label: "Post Graduation Degree"
		},
		{
			value: "IELTS Score Card",
			label: "IELTS Score Card"
		},
		{
			value: "Gap Proof",
			label: "Gap Proof"
		},
		{
			value: "Offer Letter",
			label: "Offer Letter"
		},
		{
			value: "Other",
			label: "Other"
		}
	];
	const readFileAsDataURL = async file => {
		let result_base64 = await new Promise(resolve => {
			let fileReader = new FileReader();
			fileReader.onload = () => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});

		// base = result_base64;
		base_uri2 = result_base64;
		// base_uri2 = base;
		return result_base64;
	};
	const getUri = async file => {
		let result_base64 = await new Promise(resolve => {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					readFileAsDataURL(result).then(data => {
						resolve(data);
					});
				}
			});
		});
		return result_base64;
	};
	const callback = async (width, height, src, i) => {
		let result_base64 = await new Promise(resolve => {
			const widthRatio = Pagewidth2 / width;
			const heightRatio = Pageheight2 / height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
			const canvasWidth = width * ratio;
			const canvasHeight = height * ratio;
			const marginX = (Pagewidth2 - canvasWidth) / 2;
			const marginY = (Pageheight2 - canvasHeight) / 2;
			doc2.addImage(src, "JPEG", marginX, marginY, canvasWidth, canvasHeight, i);

			pdf_uri2 = doc2.output("blob");
			resolve(true);
		});
		return result_base64;
	};
	const createPDF = async imgData => {
		if (imgData.length == "undefined" || imgData.length === 0) {
			alert("select image");
			return false;
		}
		let result_base64 = await new Promise(resolve => {
			doc2 = new jsPDF("p", "pt", "a4", true);
			Pagewidth2 = doc2.internal.pageSize.width;
			Pageheight2 = doc2.internal.pageSize.height;

			const img = new Image();
			img.src = imgData;
			img.length = imgData.length;
			img.onload = function () {
				img.imgWidth = img.naturalWidth;
				img.imgHeight = img.naturalHeight;
				callback(img.imgWidth, img.imgHeight, img.src, img.i, img.length).then(() => {
					resolve(true);
				});
			};
		});
		return result_base64;
	};
	const handleFileInputChange = (input, certificate_name = "", id = "", pendency = "") => {
		if (!input.target.files) {
			return false;
		}
		if (certificate_name && id) {
			setUploadedDocumentName({ name: certificate_name, id, type: pendency });
		}
		const file = input.target.files[0];
		const fileExtenstion = file.name.split(".").pop().toLowerCase();
		if (fileExtenstion === "png" || fileExtenstion === "jpg" || fileExtenstion === "jpeg") {
			getUri(file).then(() => {
				createPDF(base_uri2).then(() => {
					setUploadedFile(pdf_uri2);
				});
			});
		} else {
			setUploadedFile(file);
		}
	};
	const onSubmit = async data => {
		handlePageLoaded(true);
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			handlePageLoaded(false);
			return;
		}
		try {
			const { latitude, longitude } = await getLatLong();
			const formData = new FormData();
			formData.append("loan_id", rawData.loan_app_enc_id);
			formData.append("file", uploadedFile);
			formData.append("type", "3");
			if (data.assigned_to) {
				formData.append("assigned_to", data.assigned_to);
			}
			formData.append("latitude", latitude);
			formData.append("longitude", longitude);
			if (data.assigned_to === "1") {
				formData.append("proof_of", data.proof_of);
				formData.append("document_type", selectedOptionsModel.value);
				formData.append("short_description", data.short_description);
			} else if (data.assigned_to) {
				formData.append("document_type", data.financer_document_type);
				formData.append("short_description", "");
			
			} else {
				formData.append(
					"financer_loan_product_document_enc_id",
					data.financer_loan_product_document_enc_id
				);
				formData.append("document_type", data.financer_document_type);
			}
			
			if (data.loan_pendency_enc_id) {
				formData.append("loan_pendency_enc_id", data.loan_pendency_enc_id);
			}
			if (data.name) {
				formData.append("name", data.name);
			}
			const response = await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${
						uploadedDocumentName.type
							? "loans/upload-pendency-image"
							: "loans/upload-document"
					}`,
					formData
				);
			handlePageLoaded(false);
			setUploadedDocumentName("");
			if (response.data.response.status === 200) {
				toast.success("Submit Successfully");
				reset();
				hideModal();
				if (uploadedDocumentName.type) {
					getLoanApplicationPendencies(rawData.loan_app_enc_id, rawData.loan_products_enc_id, true);
					getPendencyImages(rawData.loan_app_enc_id);
				}else{
					getDocumentsData(rawData.loan_app_enc_id, 1);
					getAssignedLoanDocuments();
				}
				// openSideBar(rawData.loan_app_enc_id);
			} else {
				toast.error(
					response.data?.response?.error
						? response.data?.response?.error
						: "An error has occurred, Please try again"
				);
			}
		} catch (error) {
			handlePageLoaded(false);
			console.log(error);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		if (selectedOptionsModel && selectedOptionsModel.value === "Other") {
			setShortDescription(true);
		} else {
			setShortDescription(false);
			setValue("short_description", "");
		}
	}, [selectedOptionsModel]);

	useEffect(() => {
		setAllPendencies({});
		setAllPendencyImages([]);
		if (!documents_types) {
			getAssignedLoanDocuments();
		}
	}, [rawData]);

	useEffect(() => {
		if (rawData && documents_types) {
			const documentsData =
				documents_types && documents_types.length
					? documents_types.filter(item => item.loan === rawData.loan_product)
					: [];
			setLoanDocuments(documentsData.length ? documentsData[0].financerLoanProductDocuments : []);
		}
	}, [rawData, documents_types]);

	useEffect(() => {
		setAllPendencies(assignedPendencies);
	}, [assignedPendencies]);

	useEffect(() => {
		setAllPendencyImages(pendencyImages);
	}, [pendencyImages]);

	useEffect(() => {
		if (loanDocuments && loanDocuments.length) {
			const sortedDocs = loanDocuments
				.map((value, index) => {
					const isUploaded =
						allCertificates && allCertificates.length
							? allCertificates.some(o => o.name === value.certificate_name)
							: false;
					return { value, isUploaded, index };
				})
				.sort(a => (a.isUploaded ? 1 : -1));

			setSortedDocuments(sortedDocs);
		}
	}, [loanDocuments, allCertificates]);

	useEffect(() => {
		if (showDocuments && !allCertificates) {
			getDocumentsData(rawData.loan_app_enc_id, 1);
			getLoanApplicationPendencies(rawData.loan_app_enc_id, rawData.loan_products_enc_id, true);
			getPendencyImages(rawData.loan_app_enc_id);
		}
	}, [showDocuments]);

	useEffect(() => {
		if (uploadedDocumentName.id && uploadedDocumentName.name) {
			if (uploadedDocumentName.type) {
				onSubmit({
					loan_pendency_enc_id: uploadedDocumentName.id,
					name: uploadedDocumentName.name,
				});
			} else {
				onSubmit({
					financer_document_type: uploadedDocumentName.name,
					financer_loan_product_document_enc_id: uploadedDocumentName.id,
					short_description: ""
				});
			}
		}
	}, [uploadedFile]);

	return (
		<>
			{user.user_type !== "Lender Auditor" ? (
				<>
					<div className="row justify-content-end mb-2 ">
						<div className="col-md-12 d-flex justify-content-end flex-wrap ">
							{user.user_type === "Financer" || user.department === "Credit" ?(
								<PendenciesModal loanDetails={rawData} />
							): ""}
							<RegistryCollected loanDetails={rawData} reload={(_, val) => handleChangeData(val, "registry_status")}/>
							<button
								type="button"
								className="btn btn-primary btn-sm mx-1 btn-sm-sidebar"
								onClick={() => setAddImage(true)}
							>
								Capture Image
							</button>
							<button
								type="button"
								className="btn btn-primary btn-sm btn-sm-sidebar"
								onClick={() => handleShow()}
							>
								Upload Documents
							</button>
						</div>
					</div>
					{allPendencies && Object.keys(allPendencies).length ? (
						<div className="row">
							<div className="col-md-12">
								<h3>Pendencies</h3>
							</div>
							{Object.entries(allPendencies).map(([key, value], index) => {
								return (
									<>
										<div key={`pendency-${index}`}></div>
										<h4 className="pendency-heading">{key}</h4>
										{value
											? value.map((item, index) => {
												return (
													<>
														{key === "Individual" ? (
															<>
																<h5 className="mb-2 font-bold">
																	{item.name}
																</h5>
																{item.loanApplicationPendencies.map(
																	(val, index) => {
																		return (
																			<div
																				className="col-md-4"
																				key={`pendency_type_input${index}`}
																			>
																				<div
																					className="shadow-lg user-document1 p-3 mb-4 overflow-hidden"
																					style={{
																						position:
																								"relative"
																					}}
																				>
																					
																					<div
																						style={{
																							position: "absolute",
																							top: "-5px",
																							right: "-5px"
																						}}
																					>
																						<Badge
																							className="mx-1"
																							bg={
																								val.is_uploaded ===
																									"1"
																									? "success"
																									: "warning"
																							}
																						>
																							{val.is_uploaded ===
																								"1" ? (
																									<>
																										<GiCheckMark />{" "}
																										Uploaded
																									</>
																								) : (
																									<>
																										<GiSandsOfTime />{" "}
																										Pending
																									</>
																								)}
																						</Badge>
																					</div>
																					<div className="upload-document d-flex justify-content-between align-items-center">
																						<h3 className="my-2">{val.label}</h3>
																						<label
																							className="text-center form-control w-auto bg-primary btn-sm g-mr-15 my-3"
																							style={{ cursor: "pointer" }}
																							htmlFor={`pendency_input_${val.loan_pendency_enc_id}${index}`}
																						>
																							<FaPlus color="#fff" fontSize={"16"} />
																						</label>
																						<input
																							id={`pendency_input_${val.loan_pendency_enc_id}${index}`}
																							type="file"
																							min={1}
																							accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
																							placeholder="Choose File"
																							className="form-control d-none"
																							onChange={e =>
																								handleFileInputChange(
																									e,
																									item.name,
																									val.loan_pendency_enc_id,
																									"pendency"
																								)
																							}
																						/>
																					</div>
																				</div>
																			</div>
																		);
																	}
																)}
															</>
														) : (
															<div
																className="col-md-4"
																key={`pendency_type_input${index}`}
															>
																<div
																	className="shadow-lg user-document1 p-3 mb-4 overflow-hidden"
																	style={{ position: "relative" }}
																>
																	<div
																		style={{
																			position: "absolute",
																			top: "-5px",
																			right: "-5px"
																		}}
																	>
																		<Badge
																			className="mx-1"
																			bg={
																				item.is_uploaded === "1"
																					? "success"
																					: "warning"
																			}
																		>
																			{item.is_uploaded ===
																				"1" ? (
																					<>
																						<GiCheckMark />{" "}
																						Uploaded
																					</>
																				) : (
																					<>
																						<GiSandsOfTime />{" "}
																						Pending
																					</>
																				)}
																		</Badge>
																	</div>
																	<div className="upload-document  d-flex justify-content-between align-items-center">
																		<h3 className="my-2">
																			{item.name}
																		</h3>
																		<label
																			className="text-center form-control w-auto bg-primary btn-sm g-mr-15 my-3"
																			style={{ cursor: "pointer" }}
																			htmlFor={`pendency_${item.name}`}
																		>
																			<FaPlus color="#fff" fontSize={"16"} />
																		</label>
																		<input
																			id={`pendency_${item.name}`}
																			type="file"
																			min={1}
																			accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
																			placeholder="Choose File"
																			className="form-control d-none"
																			onChange={e =>
																				handleFileInputChange(
																					e,
																					item.name,
																					item.assigned_id,
																					"pendency"
																				)
																			}
																		/>
																	</div>
																</div>
															</div>
														)}
													</>
												);
											  })
											: ""}
									</>
								);
							})}
						</div>
					) : (
						""
					)}
					<div className="row mb-4">
						<h3>Documents</h3>
						{sortedDocuments.map(({ value, isUploaded, index }) => (
							<div className="col-md-4" key={`document_type_input${index}`}>
								<div
									className="shadow-lg user-document1 p-3 mb-4"
									style={{ position: "relative", overflow: "hidden" }}
								>
									<div style={{
										position: "absolute",
										top: "-5px",
										right: "-5px"
									}}>
										<Badge className="mx-1 fs-7" bg={isUploaded ? "success" : "warning"}>
											{isUploaded ? (
												<>
													<GiCheckMark /> Uploaded
												</>
											) : (
												<>
													<GiSandsOfTime /> Pending
												</>
											)}
										</Badge>
									</div>
									<div className="upload-document d-flex justify-content-between align-items-center">
										<h3 className="my-2">{value.certificate_name}</h3>
										<label
											className="text-center form-control w-auto bg-primary btn-sm g-mr-15 my-3"
											style={{ cursor: "pointer" }}
											htmlFor={`file_input_${index}`}
										>
											<FaPlus color="#fff" fontSize={"16"} />
										</label>
										<input
											id={`file_input_${index}`}
											type="file"
											min={1}
											accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
											placeholder="Choose File"
											className="form-control d-none"
											onChange={e =>
												handleFileInputChange(
													e,
													value.certificate_name,
													value.financer_loan_product_document_enc_id
												)
											}
										/>
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			) : ""}
			<div className="row mb-2">
				{rawData?.creditLoanApplicationReports &&
				rawData.creditLoanApplicationReports.length ? (
						<div className="col-md-12">
							<h3>Credit Reports</h3>
						</div>
					) : (
						""
					)}
				{rawData?.creditLoanApplicationReports &&
				rawData.creditLoanApplicationReports.length
					? rawData.creditLoanApplicationReports.map((value, index) => {
						return (
							<>
								{value.file_url && (
									<div className="col-md-3" key={`borrower_credit${index}`}>
										<a
											href={value.file_url}
											target="_blank"
											rel="noreferrer"
											className="documentView"
										>
											<PdfToImg url={value.file_url} />
											<p>
												{rawData.applicant_name +
														" " +
														value.request_source +
														" Report"}
											</p>
											<hr className="w-100 my-2" />
											<div className="uploaded-by">
												<p className={"m-0"}>
													<FaClock fontSize={14} color={"#00a0e3"} />{" "}
													{moment(value.created_on).format(
														"DD MMM YYYY h:mm a"
													)}
												</p>
											</div>
										</a>
									</div>
								)}
							</>
						);
					  })
					: ""}
				{rawData?.loanCoApplicants && rawData.loanCoApplicants.length
					? rawData.loanCoApplicants.map((val, i) => {
						return (
							val.creditLoanApplicationReports &&
								val.creditLoanApplicationReports.map((value, index) => {
									return (
										<>
											{value.file_url && (
												<div
													className="col-md-3"
													key={`coborrower_credit${i}${index}`}
												>
													<a
														href={value.file_url}
														target="_blank"
														rel="noreferrer"
														className="documentView"
													>
														<PdfToImg url={value.file_url} />
														<p>
															{val.name +
																" " +
																value.request_source +
																" Report"}
														</p>
														<hr className="w-100 my-2" />
														<div className="uploaded-by">
															<p className={"m-0"}>
																<FaClock
																	fontSize={14}
																	color={"#00a0e3"}
																/>{" "}
																{moment(value.created_on).format(
																	"DD MMM YYYY h:mm a"
																)}
															</p>
														</div>
													</a>
												</div>
											)}
										</>
									);
								})
						);
					  })
					: ""}
			</div>

			{isLoading || (allCertificates && allCertificates.length) ? (
				<div className="row">
					<div className="col-md-12">
						<h3>Uploaded Documents</h3>
					</div>
				</div>
			) : (
				""
			)}

			{isLoading ? (
				<p>Loading...</p>
			) : (
				<>
					{allCertificates && allCertificates.length ? (
						<div className="row">
							{allCertificates.map((value, index) => {
								return (
									<>
										{value.image ? (
											<div
												className="col-md-3"
												key={`allCertificates-${index}`}
											>
												<a
													href={value.image ? value.image : ""}
													target="_blank"
													rel="noreferrer"
													className="documentView"
												>
													{value.image.includes(".pdf") ? (
														<PdfToImg url={value.image} />
													) : (
														<img src={handleThumbnail(value.image)} />
													)}
													<p>
														{value.name
															? value.name !== "Other"
																? value.name
																: value.short_description
																	? value.short_description
																	: value.name
															: ""}
													</p>
													<hr className="w-100 my-2" />
													<div className="uploaded-by">
														<p className={"mb-2 m-0"}>
															<FaUserCheck
																fontSize={18}
																color={"#00a0e3"}
															/>{" "}
															{value.created_by}
														</p>
														<p className={"m-0"}>
															<FaClock
																fontSize={14}
																color={"#00a0e3"}
															/>{" "}
															{moment(value.created_on).format(
																"DD MMM YYYY h:mm a"
															)}
														</p>
													</div>
												</a>
											</div>
										) : (
											""
										)}
									</>
								);
							})}
						</div>
					) : (
						""
					)}
				</>
			)}

			{allPendencyImages && allPendencyImages.length ? (
				<>
					<div className="row">
						<div className="col-md-12">
							<h3>Uploaded Pendencies</h3>
						</div>
						{allPendencyImages.map((value, index) => {
							return (
								<div className="col-md-3" key={`pendencyImage-${index}`}>
									<a
										href={value.image ? value.image : ""}
										target="_blank"
										rel="noreferrer"
										className="documentView"
									>
										{value.image.includes(".pdf") ? (
											<PdfToImg url={value.image} />
										) : (
											<img src={handleThumbnail(value.image)} />
										)}
										<p>
											{value.name}{" "}
											{value.applicant_name ? value.applicant_name : ""}
										</p>
										<hr className="w-100 my-2" />
										<div className="uploaded-by">
											<p className={"mb-2 m-0"}>
												<FaUserCheck fontSize={18} color={"#00a0e3"} />{" "}
												{value.created_by}
											</p>
											<p className={"m-0"}>
												<FaClock fontSize={14} color={"#00a0e3"} />{" "}
												{moment(value.created_on).format(
													"DD MMM YYYY h:mm a"
												)}
											</p>
										</div>
									</a>
								</div>
							);
						})}
					</div>
				</>
			) : (
				""
			)}

			<Modal show={addDocuments} onHide={hideModal} size={"lg"} centered>
				<Modal.Header>
					<Modal.Title>Upload Documents</Modal.Title><span className="close-icon" onClick={()=>hideModal()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<div className="col-12">
						<form className="form" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-6 col-12">
									<label className="form-label" htmlFor="loan_amt">
										Document For
									</label>
									<div className="radio-wrapper simple" style={{ width: "100%" }}>
										<div className="radio-item">
											<input
												type="radio"
												name="assigned_to"
												className="radioInput"
												id="internal_purpose"
												value="2"
												defaultChecked={true}
												{...register("assigned_to", {
													required: "This is required."
												})}
											/>
											<label htmlFor="internal_purpose" className="option">
												<span>Internal Purpose</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="radio"
												name="assigned_to"
												className="radioInput"
												id="for_customer"
												value="1"
												{...register("assigned_to", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="for_customer"
												className="option dents-2"
											>
												<span>Customer</span>
											</label>
										</div>
									</div>
								</div>
								{document_for === "1" ? (
									<div className="col-md-6 col-12 mb-3">
										<label className=" form-label">Documents Type</label>
										<Select
											options={optionList}
											className="customStyleSelect"
											value={
												selectedOptionsModel.value
													? selectedOptionsModel
													: {
														value: "",
														label: "Choose Document Type"
													  }
											}
											name="document_type"
											onChange={option => {
												setSelectedOptionsModel(option);
											}}
										/>
									</div>
								) : (
									<div className="col-md-6 col-12 mb-3">
										<label className=" form-label">Enter Document Type</label>
										<input
											placeholder="Enter Type"
											className="form-control"
											{...register("financer_document_type", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="financer_document_type"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								)}

								{shortDescription && document_for === "1" ? (
									<div className="col-md-6 col-12 mb-3">
										<div className="mb-6">
											<label className=" form-label">Enter Type</label>
											<input
												placeholder="Enter  Type"
												className="form-control"
												{...register("short_description", {
													required: "This is required."
												})}
											/>
											<ErrorMessage
												errors={errors}
												name="short_description"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
									</div>
								) : (
									""
								)}
								<div className="col-md-6 col-12 mb-3">
									<label className="form-label" htmlFor="loan_amt">
										Files
									</label>
									<input
										type="file"
										min={1}
										accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
										placeholder="Choose File"
										className="form-control"
										{...register("file", {
											required: "This is required.",
											validate: {
												// lessThan10MB: (files) => files[0]?.size < 5000000 || "Max 5MB",
												// acceptedFormats: (files) =>
												//     ["image/jpeg", "image/webp", "image/png", "application/pdf"].includes(files[0]?.type) ||
												//     "Only PNG, JPEG and PDF",
											}
										})}
										onChange={handleFileInputChange}
									/>
									<ErrorMessage
										errors={errors}
										name="file"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
								{document_for === "1" ? (
									<div className="col-12">
										<div className="mb-6">
											<label className="form-label">Document</label>
											<div
												className="radio-wrapper simple"
												style={{ width: "100%" }}
											>
												<div className="radio-item">
													<input
														type="radio"
														name="loan_for"
														className="radioInput"
														id="self"
														value="Self"
														defaultChecked={true}
														{...register("proof_of", {
															required: "This is required."
														})}
													/>
													<label htmlFor="self" className="option">
														<span>Self</span>
													</label>
												</div>
												<div className="radio-item">
													<input
														type="radio"
														name="loan_for"
														className="radioInput"
														id="mother"
														value="Mother"
														{...register("proof_of", {
															required: "This is required."
														})}
													/>
													<label htmlFor="mother" className="option">
														<span>Mother</span>
													</label>
												</div>
												<div className="radio-item">
													<input
														type="radio"
														name="loan_for"
														className="radioInput"
														id="father"
														value="Father"
														{...register("proof_of", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="father"
														className="option dents-2"
													>
														<span>Father</span>
													</label>
												</div>
												<div className="radio-item">
													<input
														type="radio"
														name="loan_for"
														className="radioInput"
														id="Guarantor"
														value="Guarantor"
														{...register("proof_of", {
															required: "This is required."
														})}
													/>
													<label
														htmlFor="Guarantor"
														className="option dents-2"
													>
														<span>Guarantor</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								) : (
									""
								)}
								<div className="col-sm-12 col-12">
									<Col size={12} className="text-center mt-2">
										<button type="submit" className="btn btn-primary">
											Apply
										</button>
									</Col>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={addImage} onHide={() => setAddImage(false)} size={"lg"} centered>
				<Modal.Header>
					<Modal.Title>Capture Image</Modal.Title><span className="close-icon" onClick={()=>setAddImage(false)}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<CaptureImageForm rawData={rawData} setHideModal={() => setAddImage(false)} reload={() => getDocumentsData(rawData.loan_app_enc_id, 1)} />
				</Modal.Body>
			</Modal>
		</>
	);
};
export default FinancerDocumentsTab;
