import moment from "moment/moment";
import React from "react";

const CreditReport = ({ reportDetails, reportKey }) => {
	return (
		<>
			<div className="borrower-main p-3 mb-3 rounded-3 shadow-el position-relative mx-2" key={`reportKey-${reportKey}`}>
				<div className="co-borrower-name">{reportDetails.borrower_type}</div>

				<div className="report-user-details mb-2 mt-3">
					<div className="report-user-name p-2 d-flex align-items-center justify-content-between">
						<h3>{reportDetails.name}</h3>
						{/* <p className="reportDate">{moment(reportDetails.report_date).format(" DD MMM YYYY ")}</p> */}
					</div>
					<div className="d-flex flex-wrap align-items-center justify-content-between p-2 pb-0 pt-0 mb-2 applicant-scores">
						<p>CIBIL: <span>{reportDetails.cibil_score ? reportDetails.cibil_score : "--"}</span>
							{reportDetails.cibil_score && reportDetails.cibil_date ? (
								<span className="ms-1">as on {moment(reportDetails.cibil_date).format(" DD MMM YYYY ")}</span> 
							): ("")}</p>
						<p>EQUIFAX: <span>{reportDetails.equifax_score ? reportDetails.equifax_score : "--"}</span>
							{reportDetails.equifax_score && reportDetails.equifax_date ? (
								<span className="ms-1">as on {moment(reportDetails.equifax_date).format(" DD MMM YYYY ")}</span> 
							): ("")}
						</p>
						<p>CRIF: <span>{reportDetails.crif_score ? reportDetails.crif_score : "--"}</span>
							{reportDetails.crif_score && reportDetails.crif_date ? (
								<span className="ms-1">as on {moment(reportDetails.crif_date).format(" DD MMM YYYY ")}</span> 
							): ("")}
						</p>
					</div>
				</div>

				<div className="p-2 pb-0">
					{reportDetails.email && reportDetails.email.length > 0 ? (
						<div className="user-contact-details">
							<p>Email:-</p>
							<ul>
								{reportDetails.email.map((val, ind) => {
									return <li key={`userEmail-${ind}`} className="pe-2">{val}</li>;
								})}
							</ul>
						</div>
					):("")
					}

					{reportDetails.phones && reportDetails.phones.length > 0 ? (
						<div className="user-contact-details">
							<p>Phone:-</p>
							<ul>
								{reportDetails.phones.map((val, ind) => {
									return <li key={`userEmail-${ind}`} className="pe-2">{val}</li>;
								})}
							</ul>
						</div>
					):("")
					}
				</div>

				{reportDetails.address && reportDetails.address.length > 0 ? (
					<div className="userAdd p-2 pt-0">
						<p>Address:-</p>
						<ul>
							{reportDetails.address.map((val, ind) => {
								return (
									<li
										key={`userAddress-${ind}`}
										className="borrowAdd"
									>
										{val}
									</li>
								);
							})}
						</ul>
					</div>
				): ("")}
			</div>
		</>
	);
};
export default CreditReport;
