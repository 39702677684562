import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import SignUp from "../components/SignUp";

const SignUpPage = () => {
	const handleClose = () => {
		return false;
	};
	return (
		<>
			<Helmet>
				<title>Sign Up | {process.env.REACT_APP_NAME}</title>
				{/* <meta name="description" content="Avail loans against property at the best rate of interest. GetLAP to cover personal expenses like education, vehicle purchase, health care, etc. Contact us at : +91-8727985888." />*/}
			</Helmet>

			<Navbar />
			<div style={{ height: "50vh" }}></div>
			<SignUp
				show={true}
				onHide={handleClose}
				navigate={true}
				refId={"ref_id"}
				userType="Connector"
			/>
			<Footer />
		</>
	);
};
export default SignUpPage;
