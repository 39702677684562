import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../context/MainState";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const NotificationDisplay = ({group, loadDefault}) => {
	const { getNotificationsList, notifications } = useContext(MainContext);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [loadMore, setLoadMore] = useState(true);
	
	useEffect(() => {
		if (loadDefault) {
			setLoading(true);
			getNotificationsList({
				page,
				group
			}).finally(() => setLoading(false));
		}
	}, [loadDefault]);
	
	const fetchMoreNotification = () => {
		if (notifications[group].length < notifications[group].count) {
			let newPage = page + 1;
			setPage(newPage);
			getNotificationsList(newPage);
		} else {
			setLoadMore({ hasMore: false });
		}
	};

	const setOpenTrue = id => {
		axios.post(
			`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}notifications/set-notification-open`,
			{ notification_enc_id: id }
		);
	};

	return(
		<>
			{loading ? (
				<div className="cus-loader">
					<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
				</div>
			) : (
				<div>
					{notifications && notifications[group] &&  Object.keys(notifications[group]).length ? (
						<InfiniteScroll
							next={fetchMoreNotification}
							hasMore={loadMore}
							dataLength={Object.keys(notifications[group]).length}
							endMessage={
								<p style={{ textAlign: "center" }}>
									<b>You are all catched up</b>
								</p>
							}
						>
							{Object.entries(notifications[group]).map(([key, value], index) => {
								return ( 	
									<div className="mb-3 bg-white" key={`notification-${index}`}>
										<h4 className="text-black loan-account-heading p-3 fs-3">{key}</h4>
										{value.map((value, index) => {
											return (
												<Link
													to={`${value.link}`}
													target="_blank"
													key={index}
													onClick={() =>
														setOpenTrue(value.notification_enc_id)
													}
												>
													<div className="notification-card">
														<div className="notification-img">
															<img src={value.image} alt=""></img>
															<FaBell className="bell-icon" />
														</div>
														<div className="notification-msg">
															<h4>{value.title}</h4>
															<p>
																{value.description
																	? value.description
																	: ""}
															</p>
														</div>
														<div className="notification-date">
															{moment(value.created_on).format(
																"DD MMM YYYY h:mm a"
															)}
														</div>
													</div>
												</Link>
											);
										})}
									</div>
								);
							})}
						</InfiniteScroll>
					) : (
						<>
							<div className="no-notification-main">
								<div className="noti-img">
									<img
										src="/images/icons/bell_notification.png"
										alt={process.env.REACT_APP_NAME}
									/>
								</div>
								<div className="noti-content">
									<p>No New Notifications</p>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</>	
	);
};

export default NotificationDisplay;