import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import LoanApplicationsTable from "../../components/dashboard/LoanApplicationsTable";
import { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainState";
import LoanAccountPtpTable from "../../components/dashboard/LoanAccountPtpTable";
import LoanAccountsDataTable from "../../components/dashboard/LoanAccountsDataTable";
import PickCashTable from "../../components/dashboard/PickCashTable";
import OrganizationNewStats from "../../components/dashboard/OrganizationNewStats";

const OrganizationDashboard = () => {
	const { user, getFinancerLoanStatusList,} = useContext(MainContext);
	useEffect(() => {
		getFinancerLoanStatusList();
	}, []);
	return (
		<div>
			<Helmet>
				<title>Dashboard</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5 position-relative">
				<div className="container-fluid">
					<OrganizationNewStats />
					{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
						<>
						  <div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top emiDash">
								{/* <h4 className="mb-3 ptp-heading">Target Collection Cases</h4> */}
								<LoanAccountsDataTable type={"new_cases"} name={"First Six EMI Account"} />
							</div>
							{process.env.REACT_APP_TYPE === "DEV" && (
								<div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top cash">
									<PickCashTable type={"dashboard"}/>
								</div>
							)}
							<div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top all" >
								{/* <h4 className="mb-3 ptp-heading">Target Collection Cases</h4> */}
								<LoanAccountsDataTable type={"upcoming_priority"} name={"Target Collection Cases"} />
							</div>
							<div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top npa">
								{/* <h4 className="mb-3 ptp-heading">NPA Collection Cases</h4> */}
								<LoanAccountsDataTable type={"npa"}  name={"NPA Collection Cases"}/>
							</div>
							<div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top high-priority">
								{/* <h4 className="mb-3 ptp-heading"></h4> */}
								<LoanAccountsDataTable type={"high_priority"} name={"High Priority Collection Cases "} />
							</div>
							<div className="shadow-lg rounded-3 py-6 px-4 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top other-cases">
								{/* <h4 className="mb-3 ptp-heading">Other Collection Cases</h4> */}
								<LoanAccountsDataTable type={"other_cases"}  name={"Other Collection Cases"}/>
							</div>
							<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top all">
								<h4 className="mb-3 ptp-heading">PTP Cases</h4>
								<LoanAccountPtpTable type={"dashboard"} />
							</div>
						</>
					) : ""}
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top new_leads">
						<LoanApplicationsTable type={"new_lead"} />
					</div>
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top all">
						<LoanApplicationsTable />
					</div>
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top rejected">
						<LoanApplicationsTable type={"rejected"} />
					</div>
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white table-border-top disbursed">
						<LoanApplicationsTable type={"disbursed"} />
					</div>
				</div>
			</div>
		</div>
	);
};
export default OrganizationDashboard;
