import axios from "axios";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { BsArrowRight } from "react-icons/bs";
import moment from "moment";

const UsersSideBar = ({ showSideBar, handleClose, userId }) => {
	const [auditTrails, setAuditTrails] = useState([]);
	const [loading, setLoading] = useState(false);

	const openSideBar = () => {
		setLoading(true);

		let data = { user_id: userId, limit: 100, page: 1 };

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/user-audit`,
				data
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					setAuditTrails(response.data.response.data);
				} else{
					setAuditTrails([]);
				}
				console.log(auditTrails);
			})
			.catch((error) => {
				console.log(error);
				toast.error("An error has occurred, Please try again");
				setLoading(false);
			});
	};
	
	const statusObj = {
		SET: "text-success",
		CHANGE: "text-danger"
	};

	useEffect(() => {
		if (showSideBar === true) {
			openSideBar();
		}
	}, [showSideBar]);


	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail ${showSideBar === false ? "expend-detail-close" : ""}`}>
				<div className="closeIcon" onClick={handleClose}>
					<AiFillCloseCircle />
				</div>
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					{loading ? (
						<div className="cus-loader">
							<img
								src="/images/pages/home/loader-black.svg"
								alt={process.env.REACT_APP_NAME}
							/>
						</div>
					) : (
						<>
							<h3 className="mt-4 mx-2">Audit Trail</h3>
							{auditTrails && auditTrails.length ? (
								auditTrails.map((auditKey, index) => {
									return (
										<div className="audit-trail-main" key={`emp-audit-${index}`}>
											<div className="audit-trail-list">
												<div className="audit-msg">
													<p style={{ width: "150px" }}>
														<img
															src={auditKey.image}
															alt={auditKey.created_by}
															className="employer-img"
														/>
														<span className="audit-text-color">
															{auditKey.created_by}
														</span>
													</p>
				
													<p className="d-flex align-items-center flex-wrap text-break" style={{ width: "500px" }}>
														<span className={`chngd ${statusObj[auditKey.action]}`}>
															{auditKey.action} {auditKey.field}
														</span>
														<span className="trail-status audit-text-color">
															{auditKey.old_value}
														</span>
														{auditKey.old_value ? <BsArrowRight /> : ""}
														<span className="trail-status audit-text-color">
															{auditKey.new_value}
														</span>
													</p>
												</div>
												<div className="audit-msg-date">
													<p>{moment(auditKey.stamp).format("DD MMM YYYY h:mm a")}</p>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<p className="my-2 mx-2">No Data Found</p>
							)}
						</>
					)}


				</PerfectScrollbar>
			</div>
		</>
	);
};
export default UsersSideBar;
