const PageInnerTitle = ({
	title,
	heading,
	subheading,
	backgroundURL = "/page-header.jpg",
	disableTitle = false
}) => {
	return (
		<div>
			<div className="position-relative">
				<div className="layer-shadow"></div>
				<div
					className="py-14"
					style={{
						backgroundImage: `url('/images/header-images${backgroundURL}')`,
						backgroundSize: "cover",
						backgroundPosition: "top left",
						backgroundRepeat: "no-repeat",
						minHeight: "250px",
						display: "flex",
						alignItems: "center"
					}}
				>
					{!disableTitle ? (
						<div className="container">
							<div className="row">
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
									<div className="position-relative">
										<h1 className="display-4 text-white fw-bold mb-3">
											{heading}
										</h1>
										<p className="text-white d-none d-xl-block d-lg-block d-sm-block">
											{subheading}
										</p>
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
			{title && ""}
		</div>
	);
};
export default PageInnerTitle;
