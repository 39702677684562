import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import AutoComplete from "../../components/forms/AutoComplete";
import { MainContext } from "../../context/MainState";
import { useNavigate, useParams } from "react-router-dom";
import Compressor from "compressorjs";
import { Typeahead } from "react-bootstrap-typeahead";

const ReEmpowerForm = () => {
	const history = useNavigate();
	const { getBrandsList, brands } = useContext(MainContext);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors }
	} = useForm();
	const any_dent = watch("any_dent");
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "Active",
		label: "Active"
	});
	const [selectedImages, setSelectedImages] = useState([]);
	const [selectedImagesDent, setSelectedImagesDent] = useState([]);
	const [imagesVal, setImagesVal] = useState([]);
	const [dentImagesVal, setDentImagesVal] = useState([]);
	const [brandModels, setBrandModels] = useState("");
	const [modelSelection, setModelSelection] = useState("");
	const [modelId, setModelId] = useState("");
	const [updatedProduct, setUpdatedProduct] = useState("");
	const numberValidateRegex = /^[0-9]\d*$/;
	const onSubmit = values => {
		setFormIsLoading(true);
		const formData = new FormData();
		imagesVal.map((value, i) => {
			formData.append(`images[${i}]`, value);
		});
		dentImagesVal.map((value, i) => {
			formData.append(`dent_images[${i}]`, value);
		});
		if (updatedProduct) {
			formData.append("product_enc_id", updatedProduct.product_enc_id);
		}
		formData.append("model_id", modelId);
		formData.append("status", selectedStatusModel.value);
		formData.append("assigned_category", "Two Wheeler");
		if (updatedProduct) {
			formData.append("product_name", updatedProduct.name);
		} else {
			formData.append(
				"product_name",
				selectedOptions.label + " " + modelSelection[0]["label"]
			);
		}
		[
			"price",
			"city_id",
			"variant",
			"vehicle_type",
			"ownership_type",
			"km_driven",
			"making_year"
		].forEach(e => {
			formData.append(e, values[e]);
			delete values[e];
		});
		delete values["images"];
		delete values["dent_images"];
		formData.append("product_other_detail", JSON.stringify(values));
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${
					process.env.REACT_APP_API_URL_VERSION
				}products/${updatedProduct ? "update" : "add"}`,
				formData
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Vehicle Added Successfully");
					reset();
					setDentImagesVal([]);
					setImagesVal([]);
					setSelectedImagesDent([]);
					setBrandModels([]);
					setModelSelection("");
					setSelectedOptions([]);
					setSelectedImages([]);
					history("/account/re-empower/vehicles");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setFormIsLoading(false);
			});
	};

	const addModelName = e => {
		if (modelSelection.length && modelSelection[0]["model_enc_id"]) {
			setModelId(modelSelection[0]["model_enc_id"]);
		} else {
			let data = { model: e, brand_id: selectedOptions["brand_enc_id"] };
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/add-model`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						setModelId(response.data.response.model_id);
						setBrandModels([
							...brandModels,
							{
								model_enc_id: response.data.response.model_id,
								value: response.data.response.model_id,
								label: response.data.response.model_name
							}
						]);
						setModelSelection([
							{
								model_enc_id: response.data.response.model_id,
								value: response.data.response.model_id,
								label: response.data.response.model_name
							}
						]);
					}
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	};

	const handleUpload = (e, type) => {
		let AllFiles = [];
		let testFiles = [];
		[...e.target.files].map(file => {
			AllFiles.push(URL.createObjectURL(file));
			new Compressor(file, {
				quality: 0.6,
				// Files whose file type is included in this list, and whose file size exceeds the convertSize value will be converted to JPEGs.
				// convertTypes:["image/png"],
				// Files whose file type is included in the convertTypes list, and whose file size exceeds this value will be converted to JPEGs. To disable this, just set the value to Infinity.
				convertSize: 1, // Default: 5000000 (5 MB)
				success(result) {
					testFiles.push(result);
				},
				error(err) {
					console.log(err.message);
				}
			});
		});
		if (type === "scratches") {
			setDentImagesVal(testFiles);
			setSelectedImagesDent(AllFiles);
		} else {
			setImagesVal(testFiles);
			setSelectedImages(AllFiles);
		}
	};

	let params = useParams();
	const getProductDetails = () => {
		let data = { slug: params.slug };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/get-product-details`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					let tempData = response.data.response.products;
					if (tempData.productOtherDetails.length) {
						const other_detail = JSON.parse(
							tempData.productOtherDetails[0].other_detail
						);
						tempData["ownership_type"] = tempData.productOtherDetails[0].ownership_type;
						tempData["variant"] = tempData.productOtherDetails[0].variant;
						tempData["other_detail"] = {
							...other_detail,
							...tempData.productOtherDetails[0]
						};
					}
					let brand_models = brands.filter(item => item.label === tempData.brand);
					setBrandModels(brand_models[0].brandModels);
					let modelIndex = brand_models[0].brandModels.findIndex(
						models => models.label == tempData.model
					);
					tempData["modelIndex"] = modelIndex;
					setUpdatedProduct(tempData);
					setModelId(tempData.model_id);
					setSelectedOptions({ value: tempData.brand, label: tempData.brand });
					setSelectedStatusModel({ value: tempData.status, label: tempData.status });
					let mainImages = tempData.productImages.filter(item => item.type === "default");
					setSelectedImages(mainImages);
					let dentImages = tempData.productImages.filter(item => item.type === "defect");
					setSelectedImagesDent(dentImages);
					setValue("making_year", tempData.other_detail.making_year);
					setValue("insured", tempData.other_detail.insured);
					setValue("any_dent", tempData.other_detail.any_dent);
					setValue("ownership_type", tempData.other_detail.ownership_type);
					setValue("color", tempData.other_detail.color);
					setValue("variant", tempData.variant);
					setValue("vehicle_type", tempData.vehicle_type);
					setValue("price", parseFloat(tempData.price));
					setValue("km_driven", tempData.other_detail.km_driven);
					setValue("registration_number", tempData.other_detail.registration_number);
					setValue("rc_valid_upto", tempData.other_detail.rc_valid_upto);
					setValue("city_id", tempData.city_enc_id);
				}
			});
	};
	useEffect(() => {
		getBrandsList();
	}, []);
	useEffect(() => {
		if (params.slug && brands) {
			getProductDetails();
		}
	}, [params.slug, brands]);
	return (
		<>
			<Helmet>
				<title>Re Empower Form</title>
				{/* <meta name="description" content="Tutorial for React Helmet"/>*/}
			</Helmet>
			<Navbar />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-10 bg-white py-5 px-10 mb-10 mt-5">
						<div className="row">
							<h1 className="text-center mb-5">Vehicle Details</h1>
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 col-12">
									<div className="col-md-4 col-12 mb-4">
										<label className="form-label" htmlFor="loan_amt">
											Vehicle Images <span style={{ color: "red" }}>*</span>
										</label>
										<input
											type="file"
											multiple={true}
											min={1}
											accept=".png, .jpg, .jpeg"
											placeholder="Choose File"
											className="form-control"
											{...register("images", {
												required: updatedProduct
													? false
													: "This is required.",
												validate: {
													// lessThan10MB: (files) => files[0]?.size < 5000000 || "Max 5MB",
													// acceptedFormats: (files) =>
													//     ["image/jpeg", "image/webp", "image/png"].includes(files[0]?.type) ||
													//     "Only PNG, JPG and JPEG",
												}
											})}
											onChange={e => handleUpload(e, "default")}
										/>

										<ErrorMessage
											errors={errors}
											name="images"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
										<p className="fs-6 mt-2">
											Note: Multiple images can be selected only once. Images
											must be in the JPG, JPEG or PNG format.
										</p>
									</div>
									<div className="uploaded-images mb-6">
										{selectedImages.map((data, index) => {
											return data.image_link ? (
												<img
													src={data.image_link}
													key={`defaultImages${index}`}
													alt=""
												/>
											) : (
												<img
													src={data}
													key={`defaultImages${index}`}
													alt=""
												/>
											);
										})}
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">
											Brand <span style={{ color: "red" }}>*</span>
										</label>
										<Select
											className="customStyleSelect"
											options={brands && brands.length ? brands : []}
											value={selectedOptions}
											isDisabled={updatedProduct ? true : ""}
											name="brand_name"
											placeholder="Choose Brand"
											onChange={option => {
												setBrandModels(option["brandModels"]);
												setSelectedOptions(option);
												// setModelSelection('')
											}}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">
											Model Name <span style={{ color: "red" }}>*</span>
										</label>
										{brandModels ? (
											<>
												{updatedProduct.model_id ? (
													<Typeahead
														allowNew
														id="model_name"
														name="model_name"
														options={brandModels}
														defaultSelected={brandModels.slice(
															updatedProduct.modelIndex,
															updatedProduct.modelIndex + 1
														)}
														disabled={updatedProduct ? true : ""}
														newSelectionPrefix=""
														onChange={setModelSelection}
														onBlur={e => addModelName(e.target.value)}
														placeholder="Select Your Device"
													/>
												) : !params.slug ? (
													<Typeahead
														allowNew
														id="model_name"
														name="model_name"
														options={brandModels}
														newSelectionPrefix=""
														onChange={setModelSelection}
														onBlur={e => addModelName(e.target.value)}
														placeholder="Select Your Device"
													/>
												) : (
													<input
														placeholder="Select Your Device"
														className="form-control"
														{...register("model_name", {
															required: "This is required."
														})}
														onBlur={e => addModelName(e.target.value)}
													/>
												)}
												<ErrorMessage
													errors={errors}
													name="model_name"
													render={({ message }) => (
														<p
															style={{
																color: "red",
																fontSize: "13px"
															}}
														>
															{message}
														</p>
													)}
												/>
											</>
										) : (
											<input
												placeholder="Select Your Device"
												className="form-control"
												{...register("model_name", {
													required: "This is required."
												})}
												onBlur={e => addModelName(e.target.value)}
											/>
										)}
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">Variant</label>
										<input
											placeholder="Enter Variant"
											className="form-control"
											{...register("variant", {
												required: false
											})}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">
											Color <span style={{ color: "red" }}>*</span>
										</label>
										<input
											placeholder="Enter Color"
											className="form-control"
											{...register("color", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="color"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">
											Making Year <span style={{ color: "red" }}>*</span>
										</label>
										<select
											name="making_year"
											className="form-control"
											{...register("making_year", {
												required: "This is required."
											})}
										>
											{[
												2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016,
												2015, 2014, 2013, 2012, 2011, 2010
											].map((value, index) => {
												return (
													<option
														value={value}
														key={`making_year${index}`}
													>
														{value}
													</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">Vehicle Type</label>
										<select
											name="vehicle_type"
											className="form-control"
											{...register("vehicle_type", {
												required: false
											})}
										>
											<option value="">Select Option</option>
											<option value="Sports Bike">Sports Bike</option>
											<option value="Best Mileage">Best Mileage</option>
											<option value="Offroad Bikes">Offroad Bike</option>
											<option value="Cruiser Bikes">Cruiser Bike</option>
										</select>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">
											Selling Price (Digits Only){" "}
											<span style={{ color: "red" }}>*</span>
										</label>
										<input
											placeholder="Enter Offer Price"
											className="form-control"
											{...register("price", {
												required: "This is required.",
												minLength: {
													value: 4,
													message: "Selling Price is to Less"
												},
												maxLength: {
													value: 8,
													message: "Selling Price is to High"
												},
												pattern: {
													value: numberValidateRegex,
													message: "Selling Price is invalid"
												}
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="price"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">Registration Number</label>
										<input
											type="text"
											placeholder="Enter Reg. Number"
											className="form-control"
											{...register("registration_number", {
												required: false
											})}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">
											KM Driven (Digits Only){" "}
											<span style={{ color: "red" }}>*</span>
										</label>
										<input
											placeholder="Enter Odometer Reading"
											className="form-control"
											{...register("km_driven", {
												required: "This is required.",
												pattern: {
													value: numberValidateRegex,
													message: "KM Driven is invalid"
												},
												max: {
													value: 99999,
													message:
														"KM Driven must be less than or equal to 99,999"
												}
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="km_driven"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">
											Ownership Type <span style={{ color: "red" }}>*</span>
										</label>
										<select
											name="ownership_type"
											className="form-control"
											{...register("ownership_type", {
												required: "This is required."
											})}
										>
											<option value="1">I bought it new</option>
											<option value="2">I&#39;m the Second Owner</option>
											<option value="3">I&#39;m the Third Owner</option>
											<option value="4">I&#39;m the Fourth Owner</option>
										</select>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className=" form-label">RC Valid Upto</label>
										<input
											type="month"
											min="2023-03"
											className="form-control"
											{...register("rc_valid_upto", {
												required: false
											})}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className="form-label" htmlFor="cities">
											City <span style={{ color: "red" }}>*</span>
										</label>
										<AutoComplete
											setFieldValue={setValue}
											link={"utilities/search-cities?keyword="}
											name={"city_id"}
											method={"GET"}
											placeholder={"Enter City"}
											val={updatedProduct ? updatedProduct.city : ""}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className="form-label">Status</label>
										<Select
											options={[
												{ value: "Active", label: "Active" },
												{
													value: "Inactive",
													label: "Inactive"
												}
											]}
											className="customStyleSelect"
											value={selectedStatusModel}
											name="status"
											placeholder="Choose Status"
											onChange={option => {
												setSelectedStatusModel(option);
											}}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className="form-label">
											Insured <span style={{ color: "red" }}>*</span>
										</label>
										<div
											className="radio-wrapper simple"
											style={{ width: "100%" }}
										>
											<div className="radio-item">
												<input
													type="radio"
													name="insured"
													className="radioInput"
													id="lead_type-1"
													value="Yes"
													{...register("insured", {
														required: "This is required."
													})}
													defaultChecked={true}
												/>
												<label
													htmlFor="lead_type-1"
													className="option option-1"
												>
													<span>Yes</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="insured"
													className="radioInput"
													id="lead_type-2"
													value="No"
													{...register("insured", {
														required: "This is required."
													})}
												/>
												<label
													htmlFor="lead_type-2"
													className="option option-2"
												>
													<span>No</span>
												</label>
											</div>
										</div>
										<ErrorMessage
											errors={errors}
											name="insured"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="mb-6">
										<label className="form-label">Any Dent</label>
										<div
											className="radio-wrapper simple"
											style={{ width: "100%" }}
										>
											<div className="radio-item">
												<input
													type="radio"
													name="any_dent"
													className="radioInput"
													id="bike_condition2"
													value="No"
													{...register("any_dent", {
														required: false
													})}
													defaultChecked={true}
												/>
												<label
													htmlFor="bike_condition2"
													className="option dents-2"
												>
													<span>No</span>
												</label>
											</div>
											<div className="radio-item">
												<input
													type="radio"
													name="any_dent"
													className="radioInput"
													id="bike_condition1"
													value="Yes"
													{...register("any_dent", {
														required: false
													})}
												/>
												<label
													htmlFor="bike_condition1"
													className="option dents-1"
												>
													<span>Yes</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								{any_dent === "Yes" ? (
									<div className="col-md-12 col-12">
										<div className="col-md-4 col-12 mb-4">
											<label className="form-label" htmlFor="loan_amt">
												Scrathes / Dent Images
											</label>
											<input
												type="file"
												multiple={true}
												min={1}
												accept=".png, .jpg, .jpeg"
												placeholder="Choose File"
												className="form-control"
												{...register("dent_files", {
													required: updatedProduct
														? false
														: "This is required.",
													validate: {
														// lessThan10MB: (files) => files[0]?.size < 5000000 || "Max 5MB",
														// acceptedFormats: (files) =>
														//     ["image/jpeg", "image/png"].includes(files[0]?.type) ||
														//     "Only PNG, JPG and JPEG",
													}
												})}
												onChange={e => handleUpload(e, "scratches")}
											/>
											<ErrorMessage
												errors={errors}
												name="dent_files"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
											<p className="fs-6 mt-2">
												Note: Multiple images can be selected only once.
												Images must be in the JPG, JPEG or PNG format.
											</p>
										</div>
										<div className="uploaded-images mb-6">
											{selectedImagesDent.map((data, index) => {
												return data.image_link ? (
													<img
														key={`selectedDentImages${index}`}
														src={data.image_link}
														alt=""
													/>
												) : (
													<img
														key={`selectedDentImages${index}`}
														src={data}
														alt=""
													/>
												);
											})}
										</div>
									</div>
								) : (
									""
								)}
								<div className="text-center mt-2">
									{formIsLoading ? (
										<button
											type="button"
											className="btn btn-primary"
											disabled={true}
										>
											<img
												src="/images/pages/home/loader.svg"
												alt={process.env.REACT_APP_NAME}
											/>
										</button>
									) : (
										<button type="submit" className="btn btn-primary">
											Submit
										</button>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default ReEmpowerForm;
