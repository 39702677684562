import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IoCloseCircle } from "react-icons/io5";
import { toast } from "react-toastify";
import AutoComplete from "./forms/AutoComplete";
import Select from "react-select";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import Datepicker from "react-datepicker";
import moment from "moment";
const CreateEmployeeModal = ({show , onModalHide, designationList, gradesList}) => {
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm();
	const [formIsLoading, setFormIsLoading] = useState(false);
	const uppercaseRegExp = /^[A-Z0-9]+$/;
	const [options, setOptions] = useState([]);
	const [selectDesignation, setSelectDesignation] = useState("");
	const [selectGrade, setSelectGrade] = useState("");
	const [selectedBranch, setSelectedBranch] = useState("");
	const [gradeValue, setGradeValue] = useState("");
	const [branchValue, setBranchValue] = useState("");
	const [selectDate, setSelectedDate] = useState("~");
	const { branches_list } = useContext(OrganizationDashboardContext);
	const [allData, setAllData] = useState(false);
	const [showLink, setShowLink] = useState("");

	useEffect(() => {
		if (designationList && typeof designationList === "object") {
			const groupedOptions = Object.entries(designationList).map(([group, optionsList]) => ({
				label: group,
			  	options: optionsList.map(option => ({ label: option.value, value: option.id, cat: group}))
			}));
			setOptions(groupedOptions);
		  }
	  }, [designationList]);
	 
	  useEffect(() => {
		setSelectGrade(gradesList ? gradesList.map(data => ({label: data.id,value: data.value})) : []);
		setSelectedBranch(branches_list ? branches_list.map(data => ({label: data.value, value: data.id})) : []);
	  }, [gradesList, branches_list]);

	  const copy = async () => {
		await navigator.clipboard.writeText("Username: " + showLink.username + "\n Password: " + showLink.password);
		toast.success("Link copied");
	};
	const onSubmit = async data => {
		if(!branchValue){
			toast.error("Branch Cannot be null");
			return false;
		}
		setFormIsLoading(true);
		const allData = {
			...data,
			designation_id: selectDesignation,
			grade: gradeValue,
			branch_id: branchValue,
			joining_date: selectDate !== "~" ? moment(selectDate).format("YYYY-MM-DD") : ""
		};
	

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/create-new-login`, allData)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 201) {
					setAllData(response.data.response);
					setShowLink(response.data.response.data);
					toast.success("Data Saved Successfully");
					resetAllData();
				} else if (response.data.response.status === 500) {
					const errors = response.data.response.error;
					const messages = [];
					for (var propName in errors) {
						if (Object.prototype.hasOwnProperty.call(errors, propName)) {
							messages.push(errors[propName]);
						}
					}
					toast.error(messages.toString());
				} else if (response.data.response.status === 400){
					toast.error(response.data.response.message);
				}
			})
			.catch(error => {
				setFormIsLoading(false);
				toast.error("An error occurred: " + error);
			});
	};

	const resetAllData = () => {
		setSelectedDate("~");
		onModalHide();
		reset();
		setBranchValue("");
		setSelectDesignation("");
	};
	return (
		<div>
			<Modal show={show} onHide={resetAllData} size={"xl"} centered>
				<div className="sign-page">
					<div className="">
						<div className="signup-form">
							<h1 className="mb-3 text-center mb-4">Create Employee <span className="close-icon" onClick={resetAllData}><IoCloseCircle /></span></h1>
							<form className="row" onSubmit={handleSubmit(onSubmit)}>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="first_name">
										First Name
										</label>
										<input
											placeholder="First Name"
											className="form-control"
											{...register("first_name", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="first_name"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="last_name">
										Last Name
										</label>
										<input
											placeholder="Last Name"
											className="form-control"
											{...register("last_name", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="last_name"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="email">
										Email
										</label>
										<input
											placeholder="Email"
											className="form-control"
											{...register("email", {
												required: "This is required.",
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													message: "Email is invalid"
												}
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="email"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="last_name">
										Phone Number
										</label>
										<input
											type="number"
											placeholder="Phone"
											className="form-control"
											min="0"
											{...register("phone", {
												required: "This is required.",
												minLength: {
													value: 10,
													message:
													"Mobile number Should be at least 10 numbers"
												},
												maxLength: {
													value: 10,
													message:
													"Mobile number maximum be at least 10 numbers"
												},
												// pattern: {
												// 	value: phoneRegExp,
												// 	message: "Invalid Phone number"
												// }
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="phone"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
							
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="last_name">
											Employee Code
										</label>
										<input
											type="text"
											placeholder="Enter Employee Code"
											className="form-control" 
											{...register("employee_code", {
												required: "This is required.",
												pattern : {
													value: uppercaseRegExp,
													message: "Please use Uppercase letters"
												}
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="employee_code"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label htmlFor="basic-url" className="form-label mb-0">
										Username
										</label>
										<div className="input-group mb-3">
											{/* <span
												className="input-group-text"
												id="inputGroup-sizing-default"
											>
												{window.location.origin}/
											</span> */}
											<input
												aria-label="Sizing example input"
												aria-describedby="inputGroup-sizing-default"
												placeholder="Username"
												className="form-control"
												{...register("username", {
													required: "This is required.",
													minLength: {
														value: 3,
														message:
														"Username must have at least 3 characters"
													}
												})}
											/>
										</div>
										<ErrorMessage
											errors={errors}
											name="username"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="first_name">
										Designation
										</label>
										<Select
											onChange={(e) => setSelectDesignation(e.value)}
											options={options}
											placeholder="Choose Designation"
										/>
										
										<ErrorMessage
											errors={errors}
											name="designation"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="first_name">
										Reporting Person
										</label>
										<div className="w-100">
											<AutoComplete
												setFieldValue={setValue}
												link={"company-dashboard/employee-search?type=employees&employee_search="}
												name={"reporting_person"}
												method={"GET"}
												placeholder={"Assign Reporting Person"}
											/>
										</div>
										<ErrorMessage
											errors={errors}
											name="reporting_person"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="first_name">
										Grade
										</label>
										<Select
											options={selectGrade}
											placeholder="Choose Grade"
											onChange={(e) => setGradeValue(e.value)}
										/>
										<ErrorMessage
											errors={errors}
											name="Grade"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="first_name">
										Branch
										</label>
										<Select
											onChange={(e) => setBranchValue(e.value)}
											options={selectedBranch}
											placeholder="Choose Branch"
										
										/>
										<ErrorMessage
											errors={errors}
											name="Branch"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="first_name">
										Date Of Joining
										</label>
										<Datepicker
											onChange={date => setSelectedDate(date)}
											maxDate={new Date()}
											placeholderText="Choose Joining Date"
											dropdownMode="select"
											className={"form-control "}
											isClearable={true}
											dateFormat="dd MMM yyyy"
											value={selectDate !== "~" ?  moment(selectDate).format("DD MMM YYYY") : ""}
										/>
										<ErrorMessage
											errors={errors}
											name="date of joining"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 text-center">
									<div className="mb-3">
										{formIsLoading ? (
											<button
												type="button"
												id="Submit"
												name="Submit"
												className="btn btn-primary"
												disabled={true}
											>
												<img
													src="/images/pages/home/loader.svg"
													alt={process.env.REACT_APP_NAME}
												/>
											</button>
										) : (
											<button type="submit" className="btn btn-primary">
											Save
											</button>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Modal>

			<Modal show={allData} onClose={() => setAllData(false)}>
				<Modal.Header><h3 className="m-0 w-100"> Credentials <span className="close-icon" onClick={() => setAllData(false)}><IoCloseCircle /></span></h3></Modal.Header>
				<Modal.Body>
					<div className="w-60">
						<div className="text-start mb-3">
             				<label className="me-2">Username:</label>
							 <span className="fw-bold">{showLink.username}</span>
						</div>
						<div className="text-start mb-5">
           				  	<label className="me-2">Password:</label>
							<span className="fw-bold">{showLink.password}</span>
						</div>
						<button
							type="button"
							className="btn btn-primary m-auto d-block mt-3"
							onClick={copy}
						>
							Copy Credentials
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default CreateEmployeeModal;