import Navbar from "../../components/Navbar";
import "../../assets/css/accountStyles.css";
import "../../assets/css/loanDashboard.css";
import { CandidateDashboardProvider } from "../../context/CandidateDashboardState";
import CandidateSidebar from "../../components/dashboard/CandidateSidebar";
import { Helmet } from "react-helmet";
import { Tab } from "react-bootstrap";
import React from "react";
import CandidateDashboardLoanDetails from "../../components/dashboard/CandidateDashboardLoanDetails";



const CandidateDashboard = () => {

	return (
		<div>
			<Helmet>
				<title>Customer Dashboard</title>
			</Helmet>
			<Navbar />
			<CandidateDashboardProvider>
				<section>
					<div className="container-fluid mt-4">
						<Tab.Container id="left-tabs-example" defaultActiveKey="first">
							<div className="row">
								<div className="col-lg-3 col-md-4 col-sm-12 col-xs-12" id="side-bar-main">
									<CandidateSidebar />
								</div>
								<div className="col-lg-9 col-md-8 col-sm-12 col-xs-12" id="integration-main">
									{/* <CandidateDashboardData /> */}
									<CandidateDashboardLoanDetails />
								</div>
							</div>
						</Tab.Container>
					</div>
				</section>
			</CandidateDashboardProvider>
		</div>
	);
};
export default CandidateDashboard;
