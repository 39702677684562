import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { BiRupee, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { MainContext } from "../../context/MainState";
import NameComp from "../NameComp";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
import EmiCollectionStats from "./EmiCollectionStats";
import Select from "react-select";
import { useForm } from "react-hook-form";
import MinMaxFilter from "../MinMaxFilter";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import { readOnlyUsers } from "../../helpers/authServices";

const EmiCollecttionTable = ({pageType= "pending"}) => {
	const { getEmiCollection, collection, isLoading, branches_list, cleanEmiStorage} = useContext(
		OrganizationDashboardContext
	);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
	});
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "3",
		label: "Today"
	});
	const [show, setShow] = useState(false);
	const [reloadSidebar, setReloadSidebar] = useState(false);

	const emiObj = {
		pending: null,
		qr: "1",
		link: "2",
		POS: "3",
		cash: "4",
		cheque: "5",
		nach: "6",
		eNach: "7",
		"net-banking": "83",
		"RTGS-NEFT": "84",
		"scanned-qr": "9",
		"digital-transfer": "10",
		"paid-to-dealer": "11",
	};
	const { handleSubmit } = useForm();

	const url = window.location.pathname.split("/").slice(-1).toString();
	const [emiId, showLoanSideBar] = useState("");
	const { user, loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({
		// emi_payment_status: !emiObj[url] ? "pending" : null,
		// emi_payment_method: emiObj[url],
	});
	const [isImagesToggled, setIsImagesToggled] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);

	const getCurrentMonthData = () => {
		const tempDate = new Date();
		const fromDate = moment(
			tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-1"
		).format("YYYY-MM-DD");
		const toDate = moment(tempDate).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};

	const getTodayData = () => {
		const formattedToday = moment().format("YYYY-MM-DD");
		setFilters({
			...filters,
			start_date: formattedToday,
			end_date: formattedToday
		});
	};

	const getThisWeekData = () => {
		const today = new Date();
		const dayOfWeek = today.getDay();
		const startOfWeek = new Date(today);

		// Calculate the number of days to subtract to get to Monday
		const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
		startOfWeek.setDate(today.getDate() - daysToSubtract);

		const formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
		const formattedToday = today.toISOString().split("T")[0];

		setFilters({
			...filters,
			start_date: formattedStartOfWeek,
			end_date: formattedToday
		});
	};

	const getPrevMonthData = () => {
		const tempDate = new Date();
		const lastDay = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
		const fromDate = moment((tempDate.getMonth() === 0 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) + "-" + (tempDate.getMonth() === 0 ? 12 : tempDate.getMonth()) + "-1").format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};

	const getThreeMonthData = () => {
		const tempDate = new Date();
		const startingMonth = (tempDate.getMonth() - 3 + 12) % 12;
		const lastDay = new Date((tempDate.getMonth() < 3 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()), startingMonth + 3, 0);
		const fromDate = moment((tempDate.getMonth() <= 2 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) + "-" + (startingMonth + 1) + "-1").format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};

	const handleEmiDelete = (id) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/delete-emi`,
				{ emi_collection_enc_id: id }
			)
			.then((response) => {
				if (response.data.response.status === 200) {
					toast.success("Application Successfully Deleted");
					// setSearchArray({ emi_payment_method: emiObj["cash"] });
					getEmiCollection({
						page: currentPage,
						limit: rowsPerPage,
						fields_search: searchArray,
						custom_method: emiObj[url],
						pageType,
						custom_status: !emiObj[url] ? "pending" : null,
					});
				} else {
					toast.error("An error has occurred, Please try again later");
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const handleEmiUpdate = (id, action) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm("Are you sure you want to update Status")) {
			return false;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/emi-collection`,
				{ emi_collection_enc_id: id, status: action }
			)
			.then((response) => {
				if (response.data.response.status === 200) {
					toast.success("Application successfully marked as " + action);
					getEmiCollection({
						page: currentPage,
						limit: rowsPerPage,
						fields_search: searchArray,
						pageType,
						custom_method: emiObj[url],
						custom_status: !emiObj[url] ? "pending" : null,
					});
				} else {
					toast.error("An error has occurred while updating emi, Please try again later");
				}
			})
			.catch(error => {
				toast.error(error.message);
			});;
	};

	const convertToCsv = (data) => {
		const header = Object.keys(data[0]);
		const csvHeader = header.join(",");
		const csvData = data.map((row) => header.map((fieldName) => row[fieldName]).join(","));
		return [csvHeader, ...csvData].join("\n");
	  };

	  const onSubmit = () => {
		let data = {
		  start_date: filters.start_date,
		  end_date: filters.end_date,
		};
	  
		const urlParts = window.location.pathname.split("/");
		const nameFromURL = urlParts[urlParts.length - 1];
	  
		axios
		  .post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/get-emi-phone`,
				data
		  )
		  .then((response) => {
				if (response.data.response.status === 200) {
			  	const csv = convertToCsv(response.data.response.data);
	  
			  	// Use the extracted name for the filename
			  	const filename = `${nameFromURL}.csv`;
	  
			  	const blob = new Blob([csv], { type: "text/csv" });
	  
			  	const url = window.URL.createObjectURL(blob);
	  
			  	const a = document.createElement("a");
			  	a.href = url;
			  	a.download = filename;
			  	a.click();
			  	window.URL.revokeObjectURL(url);
			  	toast.success("Data Exported Successfully");
			  	setShow(false);
				} else {
			  		toast.error(response.data.response
						? response.data.response.message
						: "An error has occurred, Please try again");
				}
		  })
		  .catch((error) => {
				console.error("Error in loading data:", error);
				toast.error("Error exporting data. Data Not Found!");
		  });
	  };

	const statusColorObj = {
		paid: "success",
		pending: "warning",
		rejected: "danger",
		failed:"danger",
		pipeline:"secondary",
		"not paid": "danger",
		collected:"secondary",
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: (row) =>
				row.loan_account_number ? row.loan_account_number : "-",
			sortable: false,
			cell: (row) => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => (showLoanSideBar({loan_account_enc_id: row.loan_account_enc_id , loan_account_number: row.loan_account_number }))}
					>
						{row.loan_account_number ? row.loan_account_number : "-"}
					</h6>
				);
			},
		},
		{
			name: (
				<NameComp
					title={"Customer Name"}
					id={"customer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: (row) => (row.customer_name ? row.customer_name : "-"),
			sortable: false,
			cell: (row) => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => (showLoanSideBar({loan_account_enc_id: row.loan_account_enc_id , loan_account_number: row.loan_account_number }))}
					>
						{row.customer_name}
					</h6>
				);
			},
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "240px",
			selector: (row) => (row.branch_name ? row.branch_name : "-"),
			sortable: false,
		},
		{
			name: (
				<MinMaxFilter
					title={"Collection Date"}
					id={"collection_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: (row) => row.collection_date ? moment(row.collection_date).valueOf() : 0,
			sortable: false,
			cell: (row) => row.collection_date ? moment(row.collection_date).format("DD MMM YYYY") : "-",
		},
		{
			name: (
				<MinMaxFilter
					title={"Amount Received"}
					id={"amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			selector: (row) => (row.amount ? row.amount : "-"),
			sortable: false,
			minWidth: "160px",
			cell: (row) => {
				return (
					<h6 className="mb-0 emiAmt">
						<BiRupee size={16} />{" "}
						{parseFloat(row.amount).toLocaleString("en-IN")}
					</h6>
				);
			},
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					clearFilters={clearFilters}
				/>
			),
			selector: (row) => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "170px",
		},
		{
			name: (
				<NameComp
					title={"Mode of Payment"}
					id={"emi_payment_mode"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			cell: (row) => {
				return <div>{row.emi_payment_mode}</div>;
			},
		},
		{
			name: (
				<NameComp
					title={"Payment Method"}
					id={"emi_payment_method_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			selector: (row) =>
				row.emi_payment_method ? row.emi_payment_method : "-",
			sortable: false,
			minWidth: "195px",
		},
		{
			name: (
				<NameComp
					title={"Payment status"}
					id={"emi_payment_status_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "200px",
			cell: (row) => {
				const paymentStatus =
          row.emi_payment_status && row.emi_payment_status.length
          	? row.emi_payment_status
          	: "-";

				return paymentStatus === "-" ? (
					paymentStatus
				) : (
					<div
						className={`text-capitalize mx-2 badge rounded-pill bg-${statusColorObj[paymentStatus]}`}
					>
						{paymentStatus}
					</div>
				);
			},
		},
		{
			name: (
				<NameComp
					title={"Address"}
					id={"address"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "200px",
			cell: (row) => {
				return <div>{row.address ? row.address : "-"}</div>;
			},
		},
		{
			name: (
				<NameComp
					title={"Reason For Delay"}
					id={"ddelay_reason_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			selector: (row) => (row.delay_reason ? row.delay_reason : "-"),
			sortable: false,
			minWidth: "200px",
			cell: (row) => {
				return (
					<div>
						{row.delay_reason && row.delay_reason != "false"
							? row.delay_reason
							: "-"}
					</div>
				);
			},
		},
		{
			name: "Borrower Image",
			minWidth: "150px",
			cell: (row) => {
				return (
					<>
						{row.borrower_image ? (
							<Link
								to={row.borrower_image}
								target="_blank"
								className="link-button"
							>
								{isImagesToggled ? (
									<img
										src={row.borrower_image}
										alt="borrower image"
										className="borrowImg"
									/>
								) : (
									"Preview"
								)}
							</Link>
						) : (
							"-"
						)}
					</>
				);
			},
		},
		{
			name: "PR Receipt",
			minWidth: "150px",
			cell: (row) => {
				return (
					<>
						{row.pr_receipt_image ? (
							<Link
								to={row.pr_receipt_image}
								target="_blank"
								className="link-button"
							>
								{isImagesToggled ? (
									<img
										src={row.pr_receipt_image}
										alt="Pr Receipt"
										className="borrowImg"
									/>
								) : (
									"Preview"
								)}
							</Link>
						) : (
							"-"
						)}
					</>
				);
			},
		},
		{
			name: "Other Document",
			minWidth: "150px",
			cell: (row) => {
				return (
					<>
						{row.other_doc_image ? (
							<Link
								to={row.other_doc_image}
								target="_blank"
								className="link-button"
							>
								{isImagesToggled ? (
									<img
										src={row.other_doc_image}
										alt="Other Documents"
										className="borrowImg"
									/>
								) : (
									"Preview"
								)}
							</Link>
						) : (
							"-"
						)}
					</>
				);
			},
		},
		{
			name: (
				<NameComp
					title={"Reference Number"}
					id={"reference_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: (row) => {
				return <>{row.reference_number ? row.reference_number : "-"}</>;
			},
		},
		{
			name: (
				<NameComp
					title={"Dealer Name"}
					id={"dealer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: (row) => {
				return (
					<>
						{row.emi_payment_method === "Paid To Dealer"
							? row.dealer_name ?? row.collected_by
							: "-"}
					</>
				);
			},
		},
		{
			name: (
				<NameComp
					title={"Collected By"}
					id={"collected_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "150px",
			selector: (row) => (row.collected_by ? row.collected_by : "-"),
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designation"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "150px",
			selector: (row) => (row.designation ? row.designation : "-"),
		},
		{
			name: "Actions",
			minWidth: "120px",
			style: { textAlign: "center" },
			cell: (row) => {
				return (
					<>
						{(row.emi_payment_status === "pipeline" || row.emi_payment_status === "pending") && !["Link", "QR"].includes(row.emi_payment_method) && !(row.emi_payment_mode === "Manual Collection" && row.emi_payment_method === "Cash" && moment(row.created_on).isAfter("2023-11-07")) && (user.user_type === "Financer" || (["KKB", "phf604", "phf403"].includes(user.username))) ? (
							<div className="d-flex justify-content-center align-items-center w-100">
								<button
									type="button"
									className="btn btn-success btn-xs mx-2"
									title="Mark as Paid"
									onClick={() =>
										handleEmiUpdate(row.emi_collection_enc_id, "paid")
									}
								>
									<FaCheck color={"white"} />
								</button>
								<button
									type="button"
									className="btn btn-danger btn-xs"
									title="Unpaid"
									onClick={() =>
										handleEmiUpdate(row.emi_collection_enc_id, "rejected")
									}
								>
									<FaTimes color={"white"} />
								</button>
							</div>
						) : (
							""
						)}
					</>
				);
			},
		},
		{
			name: "Delete",
			minWidth: "70px",
			style: { textAlign: "center" },
			omit: user.user_type !== "Financer",
			cell: (row) => {
				return (
					<div className="d-flex justify-content-center w-100">
						<button
							type="button"
							className="link-button"
							onClick={() => handleEmiDelete(row.emi_collection_enc_id)}
						>
							<BiTrash size={18} color="red" />
						</button>
					</div>
				);
			},
		},
	];

	const reloadDetails = () => {
		setReloadSidebar(true);
		getEmiCollection({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
			pageType,
			custom_method: emiObj[url],
			custom_status: !emiObj[url] ? "pending" : null,
		});
	};

	useEffect(() => {
		getEmiCollection({
			page: 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			pageType,
			custom_method: emiObj[url],
			custom_status: !emiObj[url] ? "pending" : null,
		});
		setCurrentPage(1);
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray, window.location.pathname]);
	// ** Function to handle filter

	// ** Function to handle Pagination and get data
	const handlePagination = (page) => {
		getEmiCollection({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			pageType,
			custom_method: emiObj[url],
			custom_status: !emiObj[url] ? "pending" : null,
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = (e) => {
		getEmiCollection({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			pageType,
			custom_method: emiObj[url],
			custom_status: !emiObj[url] ? "pending" : null,
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({name: ""});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(collection[!emiObj[url] ? "pending" : "all"].count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={(page) => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const filters = {
			q: "",
		};

		const isFiltered = Object.keys(filters).some(function(k) {
			return filters[k].length > 0;
		});

		if (collection && collection[!emiObj[url] ? "pending" : "all"] && collection[!emiObj[url] ? "pending" : "all"].data) {
			if (collection[!emiObj[url] ? "pending" : "all"].data.length > 0) {
				return collection[!emiObj[url] ? "pending" : "all"].data;
			} else if (collection && collection[!emiObj[url] ? "pending" : "all"].data.length === 0 && isFiltered) {
				return [];
			} else {
				return collection && collection[!emiObj[url] ? "pending" : "all"].data.length > 0
					? collection[!emiObj[url] ? "pending" : "all"].data.slice(0, rowsPerPage)
					: [];
			}
		}
	};

	useEffect(() => {
		return () => {
			if (collection && collection[!emiObj[url] ? "pending" : "all"] && collection[!emiObj[url] ? "pending" : "all"].data) {
				cleanEmiStorage();
			}
		};
	}, []);

	useEffect(() => {
		if (selectedStatusModel.value === "1") {
			getCurrentMonthData();
		} else if (selectedStatusModel.value === "0") {
			getPrevMonthData();
		} else if (selectedStatusModel.value === "2") {
			getThreeMonthData();
		} else if (selectedStatusModel.value === "3") {
			getTodayData();
		} else if (selectedStatusModel.value === "4") {
			getThisWeekData();
		}
	}, [selectedStatusModel]);
	return (
		<>
			<div>
				{user.user_type === "Financer" || user.specialAccessRole ? (
					<div className="container-fluid">
						<EmiCollectionStats statsType={emiObj[url]} loan_types={loanAccountTypes}/>
					</div>
				) : (
					""
				)}
			</div>
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5">
						<div className="loan-application-table">
							<div className="disFlex align-items-center justify-content-between">
								<div>
									<h2 className="m-0">EMI Collections</h2>
								</div>
							</div>
							<Row className="mx-0 mt-1 mb-50 d-flex align-items-center">
								<Col sm="6">
									<div className="d-flex align-items-center">
										<label htmlFor="sort-select">Show</label>
										<select
											className="dataTable-select form-select"
											style={{
												width: "65px",
												padding: "2px 8px",
												margin: "0px 10px",
											}}
											id="sort-select"
											value={rowsPerPage}
											onChange={(e) => handlePerPage(e)}
										>
											{/* <option value={10}>10</option>*/}
											<option value={25}>25</option>
											<option value={50}>50</option>
											<option value={75}>75</option>
											<option value={100}>100</option>
										</select>
										<label htmlFor="sort-select">Results</label>
									</div>
								</Col>
								<Col sm="6">
									<div className="d-flex align-items-end justify-content-end">
										{/* <button className="btn btn-primary btn-sm mb-0 me-4 fs-6" onClick={() => setShow(true)}>Export Data</button> */}
										<div className="images-toggle-btn">
											<p className="mb-1 fs-6 fw-medium">
								Show Images{" "}
												<OverlayTrigger
													placement="left"
													delay={{ show: 250, hide: 400 }}
													overlay={
														<Tooltip id="show-images">
															Switch to a preview mode for images within the table.
														</Tooltip>
													}
												>
													<div style={{ display: "inline-block", marginLeft: "2px" }}>
														<FaInfoCircle size={14} />
													</div>
												</OverlayTrigger>
											</p>
											<div className="toggle-button_wrap overflow-hidden">
												<input
													type="checkbox"
													id="images"
													defaultChecked={isImagesToggled ? true : false}
													className="toggler"
													onChange={() => setIsImagesToggled(!isImagesToggled)}
												/>
												<label className="toggle-button" htmlFor="images"></label>
												<div className="toggle"></div>
											</div>
										</div>
									</div>
								</Col>
							</Row>

							<div className="filters-container d-flex justify-content-end mt-2">
								{showClearBtn ? (
									<button className="btn btn-primary btn-xs my-2" onClick={(e) => clearFilter(e)}>Reset</button>
								): ""}
							</div>
							<hr />
							<div className="row">
								<div className="col-lg-12">
									<DataTable
										noHeader
										pagination
										paginationServer
										columns={columns}
										data={dataToRender()}
										paginationComponent={CustomPagination}
										progressPending={isLoading === "emiTable"}
										highlightOnHover="true"
										persistTableHead={true}
										progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
										noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
										className="loan-app-table-container fix-overflow"
									/>
								</div>
							</div>
							<EmiLoanAccountSidebar
								handleCloseSideBar={() => showLoanSideBar("")}
								loanAccountId={emiId}
								reloadDetails={reloadDetails}
								reloadSidebar={reloadSidebar}
							/>
						</div>
					</div>
				</div>
			</div>

			<Modal show={show} onHide={setShow}>
				<Modal.Header>
					<Modal.Title>Select the Date Range to Export Data</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div>
							<div className="form-group">
								<label
									htmlFor="float-text"
									className="floating-label fw400 fs-5"
								>
									Sort By
								</label>
								<Select
									options={[
										{ value: "3", label: "Today" },
										// { value: "4", label: "This Week" },
										{ value: "1", label: "This Month" },
										{
											value: "0",
											label: "Last Month"
										},
										{
											value: "2",
											label: "Last Three Months"
										},
										{
											value: "Custom",
											label: "Custom Range"
										}
									]}
									className="dashboard-stats-design customStyleSelect smSelect m-0 fs-5 w-100"
									value={selectedStatusModel}
									name="status"
									isSearchable={false}
									placeholder="Choose Status"
									onChange={option => {
										if (option.value !== selectedStatusModel.value) {
											setSelectedStatusModel(option);
										}
									}}
								/>
							</div>
							{selectedStatusModel && selectedStatusModel.value === "Custom" ? (
								<div className="d-flex align-items-center justify-content-between mt-4">
									<div className="form-group mx-2">
										<label
											htmlFor="float-text"
											className="floating-label fw400 ft14"
										>
											From
										</label>
										<input
											type="date"
											className="form-control"
											style={{ maxWidth: "150px", height: "48px" }}
											value={filters.start_date}
											onChange={e => {
												setFilters({
													...filters,
													start_date: e.target.value
												});
											}}
										/>
									</div>
									<div className="form-group">
										<label
											htmlFor="float-text"
											className="floating-label fw400 ft14"
										>
											To
										</label>
										<input
											type="date"
											className="form-control"
											style={{ maxWidth: "150px", height: "48px" }}
											value={filters.end_date}
											onChange={e => {
												setFilters({ ...filters, end_date: e.target.value });
											}}
										/>
									</div>
								</div>
							) : (
								""
							)}
							<hr/>
							<div className="text-end">
								<button type="submit" className="btn btn-primary">Export</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default EmiCollecttionTable;
