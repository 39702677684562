import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangeFilter = ({callBack, defaultDate= null}) => {
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
	});
	const [selectedStatusModel, setSelectedStatusModel] = useState(defaultDate ? defaultDate : {
		value: "1",
		label: "This Month"
	  });

	const getCurrentMonthData = () => {
		const tempDate = new Date();
		const fromDate = moment(tempDate.getFullYear() + (tempDate.getMonth() < 9 ? "-0" : "-") + (tempDate.getMonth() + 1) + "-01").format("YYYY-MM-DD");
		const toDate = moment(tempDate).format("YYYY-MM-DD");
		setFilters({start_date: fromDate, end_date: toDate });
	};

	const getTodayData = () => {
		const formattedToday = moment().format("YYYY-MM-DD");
		setFilters({
			start_date: formattedToday,
			end_date: formattedToday
		});
	};

	const getYesterdayData = () => {
		const formattedYesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
		setFilters({
			start_date: formattedYesterday,
			end_date: formattedYesterday
		});
	};

	const getThisWeekData = () => {
		const today = new Date();
		const dayOfWeek = today.getDay();
		const startOfWeek = new Date(today);

		// Calculate the number of days to subtract to get to Monday
		const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
		startOfWeek.setDate(today.getDate() - daysToSubtract);

		const formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
		const formattedToday = today.toISOString().split("T")[0];

		setFilters({
			start_date: formattedStartOfWeek,
			end_date: formattedToday
		});
	};

	const getLastWeekData = () => {
		const lastWeekStart = moment().subtract(1, "week").startOf("isoWeek").format("YYYY-MM-DD");
		const lastWeekEnd = moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD");
	
		setFilters({
			start_date: lastWeekStart,
			end_date: lastWeekEnd
		});
	};

	const getPrevMonthData = () => {
		const tempDate = new Date();
		const lastDay = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
		const fromDate = moment(
			(tempDate.getMonth() === 0 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) +
				(tempDate.getMonth() < 9 ? "-0" : "-") +
				(tempDate.getMonth() === 0 ? 12 : tempDate.getMonth()) +
				"-01"
		).format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({start_date: fromDate, end_date: toDate });
	};

	const getThreeMonthData = () => {
		const tempDate = new Date();
		const startingMonth = (tempDate.getMonth() - 3 + 12) % 12;
		const lastDay = new Date(
			tempDate.getMonth() < 3 ? tempDate.getFullYear() - 1 : tempDate.getFullYear(),
			startingMonth + 3,
			0
		);
		const fromDate = moment(
			(tempDate.getMonth() <= 2 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) +
				(tempDate.getMonth() < 9 ? "-0" : "-") +
				(startingMonth + 1) +
				"-01"
		).format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({start_date: fromDate, end_date: toDate });
	};


	useEffect(() => {
		if (selectedStatusModel.value === "1") {
			getCurrentMonthData();
		} else if (selectedStatusModel.value === "0") {
			getPrevMonthData();
		} else if (selectedStatusModel.value === "2") {
			getThreeMonthData();
		} else if (selectedStatusModel.value === "3") {
			getTodayData();
		} else if (selectedStatusModel.value === "4") {
			getThisWeekData();
		} else if (selectedStatusModel.value === "5") {
			getYesterdayData();
		} else if (selectedStatusModel.value === "6") {
			getLastWeekData();
		}
	}, [selectedStatusModel]);

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			callBack(filters);
		}
	}, [filters]);

	return (
		<>
			<div className="form-group stats-width">
				<label htmlFor="float-text" className="floating-label fw400 fs-5">
					Sort By
				</label>
				<Select
					options={[
						{ value: "3", label: "Today" },
						{ value: "5", label: "Yesterday" },
						{ value: "4", label: "This Week" },
						{ value: "6", label: "Last Week" },
						{ value: "1", label: "This Month" },
						{ value: "0", label: "Last Month" },
						{ value: "2", label: "Last Three Months" },
						{ value: "Custom", label: "Custom Range" }
					]}
					className="dashboard-stats-design customStyleSelect smSelect m-0 fs-5 w-100 z-3"
					value={selectedStatusModel}
					name="status"
					isSearchable={false}
					placeholder="Choose Status"
					onChange={option => {
						if (option.value !== selectedStatusModel.value) {
							setSelectedStatusModel(option);
						}
					}}
				/>
			</div>
			{selectedStatusModel && selectedStatusModel.value === "Custom" ? (
				<>
					<div className="form-group mx-2" style={{marginRight: 10, maxWidth: 140}}>
						<label htmlFor="float-text" className="floating-label fw400 ft14">
							From
						</label>
						<Datepicker
							selected={filters.start_date ? new Date(filters.start_date) : null}
							onChange={e => {
								setFilters({
									...filters,
									 start_date: e ? moment(e).format("YYYY-MM-DD") : ""
								});
							}}
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							className={"form-control"}
							maxDate={new Date()}
							isClearable={true}
							dateFormat="dd MMM yyyy"
						/>
						{/* <input
							type="date"
							className="form-control"
							style={{ maxWidth: "150px", height: "48px" }}
							value={filters.start_date}
							onChange={e => {
								setFilters({
									...filters,
									start_date: e.target.value
								});
							}}
						/> */}
					</div>
					<div className="form-group" style={{marginRight: 10, maxWidth: 140}}>
						<label htmlFor="float-text" className="floating-label fw400 ft14">
							To
						</label>
						<Datepicker
							selected={filters.end_date ? new Date(filters.end_date) : null}
							onChange={e => {
								setFilters({
									...filters,
									 end_date: e ? moment(e).format("YYYY-MM-DD") : ""
								});
							}}
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							className={"form-control"}
							maxDate={new Date()}
							isClearable={true}
							dateFormat="dd MMM yyyy"
						/>
						{/* <input
							type="date"
							className="form-control"
							style={{ maxWidth: "150px", height: "48px", marginRight: "10px" }}
							value={filters.end_date}
							onChange={e => {
								setFilters({
									...filters,
									end_date: e.target.value
								});
							}}
						/> */}
					</div>
				</>
			) : (
				""
			)}
		</>
	);
};
export default DateRangeFilter;
