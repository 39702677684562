import React, { useContext, useEffect, useRef, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import UserStatusField from "./UserStatusField";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MainContext } from "../../context/MainState";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import NameComp from "../NameComp";

const DsaUsersTable = ({tabActive}) => {
	const { user } = useContext(MainContext);
	const { getUsers, dsa, updateStatus, isLoading } = useContext(OrganizationUsersContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchArray, setSearchArray] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [clearFilters, setClearFilters] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);
	// const [overflowToggle, setOverflowToggle] = useState(false);
	//  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	// const [rowOpenStates, setRowOpenStates] = useState(false);
	const dsaUser = useRef(false);

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};
	const columns = [
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.first_name ? row.first_name : "-"),
			cell: row => {
				return (
					<>
					
						<img src={row.image} alt={row.first_name} className="emp-img" />
						<h6 className="mb-0 text-capitalize text-link">
							{row.first_name} {row.last_name}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Phone"}
					id={"phone"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return <h6 className="mb-0 text-capitalize text-link">{row.phone}</h6>;
			},
			selector: row => row.phone,
		},
		{
			name: (
				<NameComp
					title={"Email"}
					id={"email"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return <h6 className="mb-0 emiAmt">{row.email}</h6>;
			},
			selector: row => row.email,
		},
		{
			name: (
				<NameComp
					title={"UserName"}
					id={"username"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.username ? row.username : "-"),
		},
		{
			name: (
				<NameComp
					title={"Status"}
					id={"status"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanStatus={[
						{ value: "Active", loan_status: "Active" },
						{ value: "Inactive", loan_status: "Inactive" },
						{ value: "Pending", loan_status: "Pending" }
					]}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.status ? row.status : "-"),
			cell: row => <UserStatusField row={row} updateStatus={updateStatus} type="dsa"/>
		},
		{
			name:"Delete",
			maxWidth: "80px",
			style: { textAlign: "center" },
			omit: user.user_type !== "Financer" || user.specialAccessRole,
			cell: row => {
				return (
				// <div className="user-action">
				//     <button type="button" className="button" onClick={() => toggleDropdown(row.user_enc_id)}>
				//         <GoKebabHorizontal/>
				//     </button>
				//     {rowOpenStates[row.user_enc_id] && (
				//         <div className="user-dropdown">
				//             <ul>
				//                 <li onClick={() => {
				//                     if (window.confirm('Are you sure you want to delete?')) {
				//                         updateStatus(row.user_enc_id, 'delete')
				//                     }}}>Delete</li>
				//                 <li>Convert to Employee</li>
				//             </ul>
				//         </div>
				//     )}
				//
				// </div>

					<div>
						<RiDeleteBin6Fill
							className="cursor-point"
							size={16}
							color={"red"}
							onClick={() => {
								if (window.confirm("Are you sure you want to delete?")) {
									updateStatus(row.user_enc_id, "delete","dsa");
								}
							}}
						/>
					</div>
				);
			}
		}
	];

	const handlePagination = page => {
		getUsers({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			type: "dsa"
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getUsers({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			type: "dsa"
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	useEffect(() => {
		if (searchArray && Object.keys(searchArray).length) {
			getUsers({
				fields_search: searchArray,
				type: "dsa",
				page: 1,
				limit: rowsPerPage,
			});
			setCurrentPage(1);
		}
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray]);

	useEffect(() => {
		if (tabActive === "2" && !dsaUser.current) {
			getUsers({
				type: "dsa",
				page: 1,
				limit: rowsPerPage,
			});
			dsaUser.current = true;
		}
	}, [tabActive]);
	
	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(dsa?.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
		
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	const dataToRender = () => {
		if (dsa?.dsa && dsa?.dsa.length > 0) {
			return dsa.dsa;
		} else {
			return dsa?.dsa && dsa?.dsa.length > 0
				? dsa.dsa.slice(0, rowsPerPage)
				: [];
		}
	};
	

	return (
		<>
			<div
				//  className={`loan-application-table height-fix mb-5 ${
				// 	overflowToggle ? "fixOverflow" : ""
				// }`}
			>
				<Row className="mx-0 mt-1 mb-3">
					<Col sm="9">
						<div className="d-flex align-items-center justify-content-start mb-3">
							<label htmlFor="sort-select">Show</label>
							<select
								className="dataTable-select form-select loan_acc_select"
								style={{
									width: "65px",
									padding: "2px 8px",
									margin: "0px 10px"
								}}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
							<label htmlFor="sort-select">Results</label>
						</div>
					</Col>
					<Col
						className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
						sm="3"
					>
						<div className="reset-filters">
							<div className="filters-container d-flex justify-content-end">
								{showClearBtn ? (
									<button
										className="btn btn-primary btn-xs my-2"
										onClick={e => clearFilter(e)}
									>
								Reset
									</button>
								) : (
									""
								)}
							</div>
						</div>
					</Col>
				</Row>
				<div className="loan-application-table mb-5">
					<DataTable
						columns={columns}
						data={dataToRender()}
						pagination
						paginationServer
						paginationComponent={CustomPagination}
						highlightOnHover="true"
						persistTableHead={true}
						className="fix-overflow myUser-table"
						progressPending={isLoading === "dsa"}
						progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
						noDataComponent={
							<p className="w-100 m-5 text-start">There are no records to display</p>
						}
					/>
				</div>
	 </div>
		</>
	);
};
export default DsaUsersTable;
