import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import AutoComplete from "../forms/AutoComplete";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainState";

const AddLocation = ({ show, onHide, dealerId, reload}) => {
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	const { handlePageLoaded } = useContext(MainContext);

	const onSubmit = values => {
		let data = { ...values, organization_enc_id: dealerId, location_enc_id: show.location_enc_id };
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/add-location`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Location added successfully");
					onHide();
					reset();
					reload(dealerId);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
				handlePageLoaded(false);
			});
	};

	const hide = () => {
		onHide();
	};

	useEffect(() => {

		if(show){
			setValue("location_name", show.location_name);
			setValue("address", show.address);
			setValue("city_enc_id", show.city_enc_id);
		}
	}, [show]);

	return (
		<>
			<Modal
				size={"lg"}
				show={show}
				onHide={hide}
			>
				<Modal.Header>
					<Modal.Title>
						Location Details
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="location_name">
									Location Name
								</label>
								<input
									placeholder="Enter Location Name"
									className="form-control"
									{...register("location_name", {
										required: "This is Required"
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="location_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="address">
									Address
								</label>
								<input
									placeholder="Enter Address"
									className="form-control"
									{...register("address", {
										required: "This is Required"
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="location_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="cities">
									City
								</label>
								<AutoComplete
									setFieldValue={setValue}
									link={"utilities/search-cities?keyword="}
									name={"city_enc_id"}
									method={"GET"}
									placeholder={"Enter City"}
									val={show ? show.city_name : ""}
								/>
							</div>
							<hr/>
							<div className="text-center">
								<button type="submit" className="btn btn-primary">
									Save{" "}
								</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default AddLocation;
