import { Col, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import AutoComplete from "../forms/AutoComplete";
import { useContext } from "react";
import { MainContext } from "../../context/MainState";
import { IoCloseCircle } from "react-icons/io5";
import { readOnlyUsers } from "../../helpers/authServices";

const DealerSearch = ({
	selectedApp,
	api,
	onHide,
	setTableLoading,
	getDashboardLoanApplications,
	params,
	openSideBar = null
}) => {
	const { handleSubmit, setValue, reset } = useForm();
	const { user } = useContext(MainContext);
	const onSubmit = async data => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		data["id"] = selectedApp.loan_app_enc_id;
		data["type"] = "assigned_dealer";
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reset();
					setTableLoading(params.type);
					getDashboardLoanApplications(params);
					if (openSideBar) {
						openSideBar(selectedApp.loan_app_enc_id);
					}
					// onHide();
					toast.success("Submit Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	return (
		<>
			<Modal
				show={selectedApp && selectedApp.loan_app_enc_id}
				onHide={onHide}
				size={"md"}
				centered
			>
				<Modal.Header>
					<Modal.Title>Assign Dealer</Modal.Title><span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="col-sm-12 col-12">
							<div className="mb-3 d-flex">
								{/* <label className="form-label">Enter Username</label> */}
								<div className="w-100">
									<AutoComplete
										setFieldValue={setValue}
										link={`dealers?type=dealer&assigned_financer_enc_id=${user.organization_enc_id}&employee_search=`}
										name={"value"}
										method={"GET"}
										placeholder={"Enter Dealer Name"}
									/>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-12">
							<Col size={12} className="text-center mt-2">
								<button type="submit" className="btn btn-primary">
                                    Submit
								</button>
							</Col>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default DealerSearch;
