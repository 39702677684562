import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import { IoCloseCircle } from "react-icons/io5";

const PriorityModal = ({show, hide, selectedRows, reload}) => {
	const {
		register,
		reset,
		handleSubmit
	} = useForm();

	const [targetDates, setTargetDates] = useState({sales_target_date: null, telecaller_target_date: null, collection_target_date: null});
	const [isLoading, setIsLoading] = useState(false);
	const onSubmit = async data => {
		setIsLoading(true);	
		data["loan_account_enc_id"] = selectedRows.map(vals => vals.loan_account_enc_id);
		data["sales_target_date"] =  targetDates.sales_target_date ? moment(targetDates.sales_target_date).format("YYYY-MM-DD") : "";
		data["telecaller_target_date"] = targetDates.telecaller_target_date ? moment(targetDates.telecaller_target_date).format("YYYY-MM-DD") : "";
		data["collection_target_date"] = targetDates.collection_target_date ? moment(targetDates.collection_target_date).format("YYYY-MM-DD") : "";

		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-priority`, data).then(
			response => {
				setIsLoading(false);
				if(response.data.response.status === 200){
					toast.success("Priority Updated Successfully");
					hide();
					reset();
					reload();
				}else{
					hide();
					toast.error("Some Error Occured");
				}
			}
		)
			.catch(error => {
				console.log(error);
				toast.error(error.message);
				setIsLoading(false);
			});
	};
	return(
		<>
			<Modal show={show} onHide={hide} size={"lg"} centered>
				<Modal.Header>
					<Modal.Title>Assign Priority</Modal.Title><span className="close-icon" onClick={hide}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-md-4 mb-3">
								<label>Set Sales Priority</label>
								<select
									className="form-select"
									name="sales"
									{...register("sales_priority", {})}
								>
									<option value="">Choose Priority</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>	
							</div>
							<div className="col-md-4 mb-3">
								<label>Set Telecaller Priority</label>
								<select
									className="form-select"
									name="telecaller"
									{...register("telecaller_priority", {})}
								>
									<option value="">Choose Priority</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>	
							</div>
							<div className="col-md-4 mb-3">
								<label>Set Collection Priority</label>
								<select
									className="form-select"
									name="access"
									{...register("collection_priority", {})}
								>
									<option value="">Choose Priority</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>	
							</div>
							<div className="col-md-4 mb-3 modal-target-date">
								<label>Set Sales Target Date</label>
								<DatePicker
									showIcon
									selected={targetDates.sales_target_date}
									onChange={date => {
										setTargetDates({...targetDates, sales_target_date: date});}
									}
									showMonthDropdown
									showYearDropdown
									className={"form-control h-48"}
									minDate={new Date()}
									dropdownMode="select"
									isClearable={true}
									dateFormat="dd MMM yyyy"
								/>	
							</div>
							<div className="col-md-4 mb-3 modal-target-date">
								<label>Set Telecaller Target Date</label>
								<DatePicker
									showIcon
									selected={targetDates.telecaller_target_date}
									onChange={date => {
										setTargetDates({...targetDates, telecaller_target_date: date});}
									}
									showMonthDropdown
									showYearDropdown
									className={"form-control h-48"}
									minDate={new Date()}
									dropdownMode="select"
									isClearable={true}
									dateFormat="dd MMM yyyy"
								/>	
							</div>
							<div className="col-md-4 mb-3 modal-target-date">
								<label>Set Collection Target Date</label>
								<DatePicker
									showIcon
									selected={targetDates.collection_target_date}
									onChange={date => {
										setTargetDates({...targetDates, collection_target_date: date});}
									}
									showMonthDropdown
									showYearDropdown
									className={"form-control h-48"}
									minDate={new Date()}
									dropdownMode="select"
									isClearable={true}
									dateFormat="dd MMM yyyy"
								/>	
							</div>
							<div className="col-md-12 text-center">
								{isLoading ? (
									<button type="button" className="btn btn-primary" disabled={true}>
										<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
									</button>
								) : (
									<button className="btn btn-primary">Save</button>
								)}
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PriorityModal;