import Navbar from "../../components/Navbar";
import { CandidateDashboardProvider } from "../../context/CandidateDashboardState";
import { Helmet } from "react-helmet";
import React from "react";
import CandidateDashboardLoanDetails from "../../components/dashboard/CandidateDashboardLoanDetails";
import { useParams } from "react-router-dom";

const CandidateLoanDetails = () => {
	const params = useParams();
	return (
		<div>
			<Helmet>
				<title>Loan Details</title>
			</Helmet>
			<Navbar />
			<CandidateDashboardProvider>
				<section>
					<div className="container-fluid mt-4">
						<div className="row">
							<div className="col-12" id="integration-main">
								<CandidateDashboardLoanDetails
									type={"details"}
									loan_id={params.id}
								/>
							</div>
						</div>
					</div>
				</section>
			</CandidateDashboardProvider>
		</div>
	);
};
export default CandidateLoanDetails;
