import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import React, { useState, useRef } from "react";
import NotificationDisplay from "./NotificationDisplay";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "react-bootstrap";

const Notifications = () => {
	const [active, setActive] = useState("1");
	const dataLoaded = useRef({
		general: true,
		uploads: false,
		chats: false,
	});

	const toggle = (tab, group) => {
		if (active !== tab) {
			setActive(tab);
			if (!dataLoaded.current[group]) {
				dataLoaded.current[group] = true;
			}
		}
	};

	return (
		<div>
			<Helmet>
				<title>Notifications</title>
				<meta name="description" content="Page Not Found" />
			</Helmet>
			<Navbar />
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="notification-div">
							<div className="d-flex justify-content-between">
								<h1>Notifications</h1>
								<Nav className="mb-4 users-nav">
									<NavItem>
										<NavLink
											active={active === "1"}
											onClick={() => toggle("1", "general")}
										>
											General
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											active={active === "2"}
											onClick={() => toggle("2", "uploads")}
										>
											Uploads
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											active={active === "3"}
											onClick={() => toggle("3", "chats")}
										>
											Chats
										</NavLink>
									</NavItem>
								</Nav>
							</div>
							<TabContent className="py-50" activeTab={active}>
								<TabPane tabId="1" className={active === "1" ? "active" : ""}>
									{dataLoaded.current.general && (
										<NotificationDisplay group="general" loadDefault="general" />
									)}
								</TabPane>
								<TabPane tabId="2" className={active === "2" ? "active" : ""}>
									{dataLoaded.current.uploads && (
										<NotificationDisplay group="uploads" loadDefault="uploads" />
									)}
								</TabPane>
								<TabPane tabId="3" className={active === "3" ? "active" : ""}>
									{dataLoaded.current.chats && (
										<NotificationDisplay group="chats" loadDefault="chats" />
									)}
								</TabPane>
							</TabContent>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Notifications;