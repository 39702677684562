const CandidateDashboardReducer = (state, action) => {
	switch (action.type) {
	case "SET_ALL_LOAN_APPLICATION":
		return {
			...state,
			all_applications: action.payload
		};
	case "SET_EMI_DETAILS":
		return {
			...state,
			emiDetails: action.payload
		};
	case "SET_LENDER_DETAILS":
		return {
			...state,
			lender_details: action.payload
		};
	case "SET_SCRATCH_CARDS":
		return {
			...state,
			scratch_cards: action.payload
		};
	case "SET_MY_REWARDS":
		return {
			...state,
			my_rewards: action.payload
		};
	case "REMOVE_LENDER_DETAILS":
		return {
			...state,
			lender_details: ""
		};
	case "CANDIDATE_DASHBOARD_NOTICE":
		return {
			...state,
			candidate_notice: action.payload
		};
	default:
		return state;
	}
};
export default CandidateDashboardReducer;
