const DynamicManifest = ({ path = null }) => {
	let startUrl = ".";
	if (path) {
		startUrl = window.location.href;
	}
	var myDynamicManifest = {
		name: path.app_name,
		short_name: process.env.REACT_APP_NAME,
		start_url: startUrl,
		background_color: "#ffffff",
		theme_color: "#000000",
		icons: [
			{
				src: path.logo,
				sizes: "512x5126",
				type: "image/png"
			}
		],
		display: "standalone"
	};
	const stringManifest = JSON.stringify(myDynamicManifest);
	const blob = new Blob([stringManifest], { type: "application/json" });
	const manifestURL = URL.createObjectURL(blob);
	var link = document.createElement("Link");
	link.rel = "manifest";
	if (path) {
		document.querySelector("#my-manifest-placeholder").setAttribute("href", manifestURL);
		link.setAttribute("href", "data:application/json;charset=8" + stringManifest);
	} else {
		document.querySelector("#my-manifest-placeholder").setAttribute("href", "/manifest.json");
		link.setAttribute("href", "/manifest.json");
	}
	return <></>;
};

export default DynamicManifest;
