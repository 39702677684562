import { Modal } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";

const UpdateEmiFields = ({ onHide, emiData, reload, api = null }) => {
	const { handlePageLoaded } = useContext(MainContext);
	const { handleSubmit, register, reset, setValue } = useForm();

	const resetComponent = () => {
		reset();
		reload();
	};

	useEffect(() => {
		setValue("case_no", emiData.case_no ? emiData.case_no : "");
		setValue("company_id", emiData.company_id ? emiData.company_id : "");
	}, [emiData]);

	const onSubmit = data => {
		if (emiData.loan_account_enc_id) {
			data["loan_account_enc_id"] = emiData.loan_account_enc_id;
		} else {
			data["emi_collection_enc_id"] = emiData.emi_collection_enc_id;
		}
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${
					api ? api : "emi-collections/update-company-case"
				}`,
				data
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success(response.data.response.message ?? "Updated Successfully");
					resetComponent();
				} else {
					toast.error(
						response.data?.response?.message
							? response.data.response.message.toString()
							: "An error occurred"
					);
				}
				handlePageLoaded(false);
			})
			.catch(error => {
				handlePageLoaded(false);
				console.log(error);
				toast.error("An error has occurred, Please try again", error.message);
			});
	};

	return (
		<Modal
			show={
				emiData.emi_collection_enc_id
					? emiData.emi_collection_enc_id
					: emiData.loan_account_enc_id
			}
			onHide={onHide}
			size={"md"}
			centered
		>
			<Modal.Body>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					<h3 className="text-center mb-3">Update Case Number and Company ID</h3>
					<div className="col-12 mb-3">
						<label className="form-label mb-0" htmlFor="date_of_transfer">
							Enter Case Number
						</label>
						<div>
							<input
								type="text"
								placeholder="Case Number"
								className="form-control"
								{...register("case_no", { required: false })}
							/>
						</div>
					</div>
					<div className="col-12 mb-3">
						<label className="form-label mb-0" htmlFor="date_of_transfer">
							Enter Company ID
						</label>
						<div>
							<input
								type="number"
								min={0}
								placeholder="Company ID"
								className="form-control"
								{...register("company_id", { required: false })}
							/>
						</div>
					</div>
					<button type="submit" className="btn btn-primary m-auto d-block mt-3">
						Save
					</button>
				</form>
			</Modal.Body>
		</Modal>
	);
};
export default UpdateEmiFields;
