import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Login from "./Login";
import SignUp from "./SignUp";
import { MainContext } from "../context/MainState";
import { authServices } from "../helpers/authServices";
import OrganizationSignUp from "./OrganizationSignUp";
import { toast } from "react-toastify";
import { FaAngleDown,FaRupeeSign, FaUser} from "react-icons/fa";
import { Modal } from "react-bootstrap";
import OneStepLoanApplyForm from "./forms/OneStepLoanApplyForm";
import InviteLinks from "./dashboard/InviteLinks";
import Geocode from "react-geocode";
import { TiDocumentAdd } from "react-icons/ti";
import { BsCurrencyRupee } from "react-icons/bs";
import EmiCollectionForm from "./dashboard/EmiCollectionForm";
import { IoNotificationsOutline } from "react-icons/io5";
import { getLogo } from "../helpers/customFunctions";
import { FaNotesMedical,FaQrcode } from "react-icons/fa6";
import QrCodeModal from "./QrCodeModal";

const Navbar = () => {
	const [orgShow, orgSetShow] = useState(false);
	const [headerOpen, setHeaderOpen] = useState("");
	const [routeAsAccount, setRouteAsAccount] = useState(false);
	const {
		user,
		removeUser,
		modalLogin,
		handleModalLogin,
		signUpHandler,
		signUp,
		getAssignedLoanTypes,
		loan_types,
		loan_products,
		dealerSignUpHandler,
		getFinancerAssignedLoanTypes,
		getAssignedLoanProducts,
		financer_assigned_loan_types,
		getLoanAccountTypes,
		getAssignedLoanStatus
	} = useContext(MainContext);
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [showApplyLoan, setShowApplyLoan] = useState(false);
	const [showInvite, setShowInvite] = useState(false);
	const accessToDealers = ["pushapduggal", "Satparkash", "Phf24", "phf986", "PHF310", "Kanchan", "muskan164", "Harleenkaur", "phf113", "gursewak122", "simran517", "phf672", "SHAHRUKH", "phf110", "ghuman"];
	const handleShowInvite = () => setShowInvite(true);
	const handleCloseInvite = () => setShowInvite(false);
	const handleCloseApplyLoan = () => setShowApplyLoan(false);
	// const handleShowApplyLoan = () => setShowApplyLoan(true);
	const handleClose = () => handleModalLogin(false);
	const handleShow = () => handleModalLogin(true);
	const orgShowed = () => orgSetShow(false);
	const signClose = () => signUpHandler(false);
	const[show,setShow]=useState(false);
	const [vals, setVals] = useState({ latitude: "" });
	const openWindow = link => {
		window.open(link, "_blank", "width=800,height=400,left=200,top=100");
	};
	const handleCloseEmi = () => {
		setShowEmiModal(false);
	};
	const copyToClipboard = link => {
		navigator.clipboard.writeText(link);
		toast.success("Copied to clipboard");
	};
	const signShow = () => {
		handleModalLogin(false);
		signUpHandler(true);
	};
	const organizationShow = () => {
		handleModalLogin(false);
		orgSetShow(true);
	};
	const location = type => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(success);
			if (type === "emi") {
				setShowEmiModal(true);
			}
			if (type != "emi") {
				setShowApplyLoan(true);
			}
		} else {
			navigator.geolocation.getCurrentPosition(locerrors);
		}
	};

	const success = async pos => {
		var crd = pos.coords;
		Geocode.fromLatLng(crd.latitude, crd.longitude).then(
			response => {
				let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
				for (let i = 0; i < response.results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
						case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
							// case "country":
							// 	country = response.results[0].address_components[i].long_name;
							// 	break;
						case "postal_code":
							postalCode = response.results[0].address_components[i].long_name;
							break;
						case "premise":
							route = response.results[0].address_components[i].long_name;
							break;
						case "sublocality":
							sublocality = response.results[0].address_components[i].long_name;
							break;
						case "sublocality_level_2":
							sublocality_2 = response.results[0].address_components[i].long_name;
						}
					}
				}
				if (route && sublocality_2 && sublocality) {
					streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
				} else if (route && sublocality) {
					streetAdd = route + ", " + sublocality;
				} else {
					streetAdd = route;
				}
				setVals({
					latitude: crd.latitude,
					longitude: crd.longitude,
					postal_code: postalCode,
					streetAdd,
					city,
					state
				});
			},
			error => {
				console.error(error);
			}
		);
	};

	const locerrors = err => {
		toast.error("Please enable location to continue");
		alert(`ERROR(${err.code}): ${err.message}`);
	};
	const fetchmylocation = type => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				if (result.state === "granted") {
					location(type);
					// If granted then you can directly call your function here
				} else if (result.state === "prompt") {
					location(type);
				} else if (result.state === "denied") {
					toast.error("Please enable location to continue");
					// If denied then you have to show instructions to enable location
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			// alert("Sorry Not available!");
			toast.error("Please enable location to continue");
		}
	};
	useEffect(() => {
		if (user && user.access_token && window.location.pathname.includes("/account/")) {
			setRouteAsAccount(true);
		} else {
			setRouteAsAccount(false);
		}
		if (document.body.style.overflow === "hidden") {
			document.body.style.overflow = "unset";
		}

		if(document.querySelector(".backdrop-blur")){
			document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
		}
	}, [window.location.pathname]);
	
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		if (user && user.access_token && !loan_types) {
			getAssignedLoanTypes();
		}
		if (user?.user_type === "Employee" || user.user_type === "Financer") {
			getFinancerAssignedLoanTypes();
			getAssignedLoanProducts();
			getLoanAccountTypes();
			getAssignedLoanStatus();
		}	
	}, []);
	
	const handleShowApplyLoan = type => {
		fetchmylocation(type);
	};

	return (
		<>
			<nav className="navbar navbar-expand-lg py-3 navbar-default">
				<div className="container-fluid px-0">
					<div className="navFLex">
						<Link
							className="navbar-brand"
							to={
								user &&
								user.user_type !== "Individual"
									? "/account/dashboard"
									: "/"
							}
						>
							<img src={getLogo()} alt={process.env.REACT_APP_NAME} />
						</Link>
						{user.user_type === "Employee" ||
						user.user_type === "Financer" ||
						(user.user_type === "Dealer" &&
							user.organization_username &&
							user.organization_username === "phfleasing") ? (
								<>
									<div className="createButton">
										<button
											className="createLead-btn link-button"
											onClick={handleShowApplyLoan}
										>
											<TiDocumentAdd className="crLead" />
										Create Lead
										</button>
									</div>
									{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
										<div className="createButton">
											<button
												className="createLead-btn link-button"
												onClick={() => handleShowApplyLoan("emi")}
											>
												<BsCurrencyRupee className="crLead" />
											Collect EMI
											</button>
										</div>
									) : ""}
								</>
							) : (
								""
							)}
					</div>
					<button
						className="navbar-toggler collapsed"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbar-default"
						aria-controls="navbar-default"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="icon-bar top-bar mt-0"></span>
						<span className="icon-bar middle-bar"></span>
						<span className="icon-bar bottom-bar"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbar-default">
						<ul className="navbar-nav ms-auto align-items-center">
							{user.user_type !== "Lender Auditor" ? (
								<>
									{routeAsAccount && user.user_type !== "Individual" ? (
										<>
											{user && user.user_type === "DSA" ? (
												<>
													<li className="nav-item dropdown">
														<button
															className="nav-link dropdown-toggle link-button"
															id="navbarFeatures"
															role="button"
															data-bs-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															Invite
														</button>
														<ul
															className="dropdown-menu"
															aria-labelledby="navbarFeatures"
														>
															<li className="dropdown-submenu dropstart-lg">
																<button className="dropdown-item dropdown-list-group-item dropdown-toggle link-button">
																	Customers
																</button>
																<ul className="dropdown-menu">
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				copyToClipboard(
																					`${window.location.origin}/apply?ref_id=${user.referral_code}`
																				);
																			}}
																		>
																			Copy Link
																		</button>
																	</li>
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				openWindow(
																					`https://api.whatsapp.com/send?text=${window.location.origin}/apply?ref_id=${user.referral_code}`
																				);
																			}}
																		>
																			Share on WhatsApp
																		</button>
																	</li>
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				openWindow(
																					`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/apply?ref_id=${user.referral_code}`
																				);
																			}}
																		>
																			Share on Facebook
																		</button>
																	</li>
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				openWindow(
																					`https://twitter.com/intent/tweet?text=&url=${window.location.origin}/apply?ref_id=${user.referral_code}`
																				);
																			}}
																		>
																			Share on Twitter
																		</button>
																	</li>
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				openWindow(
																					`mailto:?&body=${window.location.origin}/apply?ref_id=${user.referral_code}`
																				);
																			}}
																		>
																			Share on Gmail
																		</button>
																	</li>
																</ul>
															</li>
															<li className="dropdown-submenu dropstart-lg">
																<button className="dropdown-item dropdown-list-group-item dropdown-toggle link-button">
																	Connector
																</button>
																<ul className="dropdown-menu">
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				copyToClipboard(
																					`${window.location.origin}/signup/dsa/${user.organization_enc_id}`
																				);
																			}}
																		>
																			Copy Link
																		</button>
																	</li>
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				openWindow(
																					`https://api.whatsapp.com/send?text=${window.location.origin}/signup/dsa/${user.organization_enc_id}`
																				);
																			}}
																		>
																			Invite from WhatsApp
																		</button>
																	</li>
																	<li>
																		<button
																			className="dropdown-item link-button"
																			onClick={() => {
																				openWindow(
																					`mailto:?&body=${window.location.origin}/signup/dsa/${user.organization_enc_id}`
																				);
																			}}
																		>
																			Invite from Gmail
																		</button>
																	</li>
																</ul>
															</li>
														</ul>
													</li>
													<li className="nav-item">
														<Link to="/account/users" className="nav-link">
															Connectors
														</Link>
													</li>
												</>
											) : (
												""
											)}


											{(user.user_type === "Financer" ||
												user.user_type === "Employee") ? (
													<li className="nav-item dropdown">
														<button
															className={"nav-link dropdown-toggle link-button"}
															id="navbarProducts"
															data-bs-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
															onClick={() => setHeaderOpen(previous =>previous === "default" ? "" : "default")}
														>
														MIS
														</button>
														<ul className="dropdown-menu dropdown-menu-arrow position-absolute" aria-labelledby="navbarProducts">
															<li className="nav-item dropdown dropdown-submenu customized-nav dropstart-lg">
																<div className="d-flex">
																	<a className="dropdown-item dropdown-list-group-item dropdown-toggle"
																	   href="javascript:;"
																	   id="portfolio-list"
																	   data-bs-toggle="dropdown"
																	   aria-haspopup="true"
																	   aria-expanded="false"
																	>
																		Portfolio
																	</a>
																</div>
																<ul className="dropdown-menu  dropdown-menu-scroll first-product"
																	aria-labelledby="portfolio-list">
																	{financer_assigned_loan_types?.map((value, index) => {
																		return (
																			<li
																				className="nav-item dropdown dropdown-submenu customized-nav dropend"
																				key={"loan-" + index}
																			>
																				<Link
																					className="dropdown-item"
																					to={`/account/portfolio/${value.loan_type_enc_id}`}
																				>
																					{value.name}
																				</Link>
																			</li>
																		);
																	})}
																</ul>
															</li>
															<li className="nav-item dropdown dropdown-submenu customized-nav dropstart-lg">
																<div className="d-flex">
																	<a className="dropdown-item dropdown-list-group-item dropdown-toggle"
																	   href="javascript:;"
																	   id="navbarProductsDropdown"
																	   data-bs-toggle="dropdown"
																	   aria-haspopup="true"
																	   aria-expanded="false"
																	>
																		Products
																	</a>
																</div>
																<ul className="dropdown-menu dropdown-menu-scroll second-product"
																	aria-labelledby="navbarProductsDropdown">
																	{loan_products?.map((value, index) => {
																		return (
																			<li
																				className="nav-item dropdown dropdown-submenu customized-nav dropend"
																				key={"loan-" + index}
																			>
																				<Link
																					className="dropdown-item"
																					to={`/account/product/${value.financer_loan_product_enc_id}`}
																				>
																					{value.name}
																				</Link>
																			</li>
																		);
																	})}
																</ul>
															</li>
														</ul>
													</li>
												) : (
													""
												)}
											{user.user_type === "Dealer" ? (
												<li className="nav-item dropdown">
													<button
														className="nav-link dropdown-toggle link-button"
														id="navbarProducts"
														data-bs-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
														onClick={() =>
															setHeaderOpen(previous =>
																previous === "default" ? "" : "default"
															)
														}
													>
														ReEmpower
													</button>
													<ul
														className={"dropdown-menu dropdown-menu-arrow"}
														aria-labelledby="navbarProducts"
													>
														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<div className="d-flex">
																<Link
																	className="dropdown-item dropdown-list-group-item dropdown-toggle"
																	to="/account/re-empower/vehicles"
																>
																	Refurbished Vehicles
																</Link>
																<div className="customize-header-toggle">
																	<FaAngleDown
																		style={{ margin: "5px 14px" }}
																		size={18}
																	/>
																</div>
															</div>
															<ul className={"dropdown-menu px-3"}>
																<li>
																	<Link
																		className="dropdown-item dropdown-list-group-item"
																		to="/account/re-empower/add-vehicle"
																	>
																		Add Vehicle
																	</Link>
																	<Link
																		className="dropdown-item dropdown-list-group-item"
																		to="/account/re-empower/vehicles"
																	>
																		Vehicles List
																	</Link>
																</li>
															</ul>
														</li>
														<li className="nav-item dropdown dropdown-submenu customized-nav dropstart-lg">
															<div className="d-flex">
																<Link
																	className="dropdown-item dropdown-list-group-item dropdown-toggle"
																	to="/account/re-empower/mobiles"
																>
																	Refurbished Mobiles
																</Link>
																<div className="customize-header-toggle">
																	<FaAngleDown
																		style={{ margin: "5px 14px" }}
																		size={18}
																	/>
																</div>
															</div>
															<ul className={"dropdown-menu px-3"}>
																<li>
																	<Link
																		className="dropdown-item dropdown-list-group-item"
																		to="/account/re-empower/add-mobile"
																	>
																		Add Mobile
																	</Link>
																	<Link
																		className="dropdown-item dropdown-list-group-item"
																		to="/account/re-empower/mobiles"
																	>
																		Mobiles List
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : (
												""
											)}
											{user.user_type !== "Connector" ? (
												<>
													<li className="nav-item">
														<button
															className="nav-link link-button lead-btn"
															onClick={handleShowApplyLoan}
														>
														Create Lead
														</button>
													</li>
													<Modal
														show={showApplyLoan}
														onHide={handleCloseApplyLoan}
														size={"xl"}
														centered
													>
														{/* <MultiStepApplyLoan callback={handleCloseApplyLoan}/>*/}
														<OneStepLoanApplyForm
															geoLoc={vals}
															callback={handleCloseApplyLoan}
														/>
													</Modal>
													{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
														<li className="nav-item dropdown">
															<button
																className="nav-link dropdown-toggle link-button"
																id="navbarReports"
																data-bs-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
															Reports
															</button>
															<ul
																className="dropdown-menu dropdown-menu-arrow position-absolute px-2 dropdown-menu-scroll"
																aria-labelledby="navbarReports"
															>
																{process.env.REACT_APP_TYPE === "DEV" || ["phfleasing", "phf110", "phf1148", "DHIRAJSINGH", "phf1111", "ghuman", "divyajain", "Rachyita"].includes(user.username) ? (
																	<>
																		<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																			<Link
																				to="/account/reports/sales-dashboard"
																				className="nav-link"
																			>
																				Sales Dashboard
																			</Link>
																		</li>
																		<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																			<Link
																				to="/account/reports/collection-dashboard"
																				className="nav-link"
																			>
																			Collection Dashboard
																			</Link>
																		</li>
																	</>
																) : ""}
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/reports/sales"
																		className="nav-link"
																	>
																	Sales Report
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/reports/collection"
																		className="nav-link"
																	>
																	Collection Report
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/reports/daily-collection"
																		className="nav-link"
																	>
																	Daily Collection Report
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/reports/enach-registrations"
																		className="nav-link"
																	>
																	eNACH Registrations Report
																	</Link>
																</li>
																{user.user_type === "Financer" || user.username === "Phf24" || user.username === "phf110" || user.username === "ghuman" ?
																	<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																		<Link
																			to="/account/reports/dealers"
																			className="nav-link"
																		>
																		Dealer Sales Report
																		</Link>
																	</li>
																	: ""}
																{user.user_type === "Financer" || (["phf110", "KKB", "phf604", "ghuman"].includes(user.username)) ? (
																	<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																		<Link
																			to="/account/cash-in-hand"
																			className="nav-link"
																		>
																		Cash In Hand Report
																		</Link>
																	</li>
																) : ""}
																{user.user_type === "Financer" ?
																	<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																		<Link
																			to="/account/reports/exception"
																			className="nav-link"
																		>
																		Exception Report
																		</Link>
																	</li>
																	: ""}
																{user.user_type === "Financer" ?
																	<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																		<Link
																			to="/account/reports/incentive-points"
																			className="nav-link"
																		>
																		Incentive Points Report
																		</Link>
																	</li>
																	: ""}
															</ul>
														</li>
													) : ""}
												</>
											) : (
												""
											)}
											{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username === "phfleasing" && (user.user_type === "Financer" || user.user_type === "Employee")) ? (
												<li className="nav-item dropdown">
													<button
														className="nav-link dropdown-toggle link-button "
														id="navbarOperations"
														data-bs-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
													Operations
													</button>
													<ul
														className="dropdown-menu dropdown-menu-arrow position-absolute px-2 dropdown-menu-scroll"
														aria-labelledby="navbarOperations"
													>
														{(user.user_type === "Financer" || user.user_type === "Employee") && (user.organization_username === "phfleasing" || process.env.REACT_APP_TYPE === "DEV") ? (
															<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																<Link
																	to="/account/emi-collection/discrepancy"
																	className="nav-link"
																>
																	Discrepancy List
																</Link>
															</li>
														) : ""}
														{user.user_type === "Financer" || ["pardeepkaur", "vipin199", "Sharan07", "kavi123", "phf1104"].includes(user.username) ? (
															<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																<Link
																	to="/account/partner-applications"
																	className="nav-link"
																>
																	BC/CO-Lending Cases
																</Link>
															</li>
														) : ""}
														{user.user_type === "Financer" || (["phf110", "KKB", "phf604", "ghuman"].includes(user.username)) ? (
															<>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/emi-payment-links"
																		className="nav-link"
																	>
																		EMI Payment Links
																	</Link>
																</li>
															</>
														) : ""}
														{user.user_type === "Financer" || user.specialAccessRole ? (
															<>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/tvr-applications"
																		className="nav-link"
																	>
																	TVR Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/fi-applications"
																		className="nav-link"
																	>
																	FI Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/pd-applications"
																		className="nav-link"
																	>
																	PD Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/technical-applications"
																		className="nav-link"
																	>
																	Technical Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/legal-applications"
																		className="nav-link"
																	>
																	Legal Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/release-payment-applications"
																		className="nav-link"
																	>
																	Release Payment Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/loan-applications/disbursed"
																		className="nav-link"
																	>
																	Disbursed Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/tv-scheme-loan-applications"
																		className="nav-link"
																	>
															TV Scheme Loan Applications
																	</Link>
																</li>
															</>
														) : ""}
														{process.env.REACT_APP_TYPE === "DEV" && (
															<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																<Link to="/account/pick-cash" className="nav-link">
																	Pick Cash Requests
																</Link>
															</li>
														)}
														{user.user_type === "Financer" || user.specialAccessRole || user.username === "ritika927" ? (
															<>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/loan-applications/removed"
																		className="nav-link"
																	>
																	Removed Applications
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/loan-applications/rejected"
																		className="nav-link"
																	>
																Rejected Applications
																	</Link>
																</li>
															</>
														) : ""}
														{user && ["ritika927", "ABHA", "phf1157", "Naveenkirpal", "phf207", "phf1183", "phf986"].includes(user.username) ? (
															<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																<Link to="/account/loan-applications/disbursed" className="nav-link">
																	Disbursed Applications
																</Link>
															</li>
														) : ""}
														{user && ["phf1135"].includes(user.username) ? (
															<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																<Link
																	to="/account/loan-applications/rejected"
																	className="nav-link"
																>
																	Rejected Applications
																</Link>
															</li>
														) : ""}
														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/loan-accounts"
																className="nav-link"
															>
																Loan Accounts
															</Link>
														</li>
														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/ptp-applications"
																className="nav-link"
															>
																PTP Applications
															</Link>
														</li>
														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/rc-pending-applications"
																className="nav-link"
															>
																RC Pending Applications
															</Link>
														</li>
														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/dealer-collection"
																className="nav-link"
															>
																Dealer Collections
															</Link>
														</li>
														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/repo-requests"
																className="nav-link"
															>
																Repossession Requests
															</Link>
														</li>

														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/legal-requests"
																className="nav-link"
															>
																Legal Requests
															</Link>
														</li>
														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/accident-requests"
																className="nav-link"
															>
																Accident Requests
															</Link>
														</li>

														<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
															<Link
																to="/account/health-requests"
																className="nav-link"
															>
																Health Requests
															</Link>
														</li>
														{user.user_type === "Financer" || (["KKB"].includes(user.username)) ? (
															<>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/foreclosure-requests"
																		className="nav-link"
																	>
																		Foreclosure Requests
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/noc-requests"
																		className="nav-link"
																	>
																		NOC Requests
																	</Link>
																</li>
																<li className="nav-item dropdown dropdown-submenu customized-nav dropend">
																	<Link
																		to="/account/initiate-requests"
																		className="nav-link"
																	>
																		Initiate Requests
																	</Link>
																</li>
															</>
														) : ""}
													</ul>
												</li>
											) : ""}

											{user.organization_username === "amritmalwa" ? (
												<>
													<li className="nav-item">
														<Link
															to="/account/partner-applications"
															className="nav-link"
														>
															BC/CO-Lending Cases
														</Link>
													</li>
												</>
											) : (
												""
											)}

											{process.env.REACT_APP_TYPE === "DEV" ||
											(user.organization_username &&
												user.organization_username === "phfleasing") ? (
													<>
														<li className="nav-item">
															<Link
																to="/account/emi-collection"
																className="nav-link"
															>
															EMI Collection
															</Link>
														</li>
													</>
												) : (
													""
												)}
										</>
									) : (
										<>
											<li className="nav-item dropdown">
												<button
													className="nav-link dropdown-toggle link-button"
													id="navbarProducts"
													data-bs-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													onClick={() =>
														setHeaderOpen(previous =>
															previous === "default" ? "" : "default"
														)
													}
												>
													Loans
													{/* <FaAngleDown size={14}/>*/}
												</button>
												<ul
													className={`dropdown-menu dropdown-menu-arrow position-absolute ${
														headerOpen ? "show1" : ""
													}`}
													aria-labelledby="navbarProducts"
												>
													<li className="nav-item dropdown dropdown-submenu customized-nav dropstart-lg">
														<div className="d-flex">
															<Link
																className="dropdown-item dropdown-list-group-item dropdown-toggle"
																to="/education-loan"
															>
																Education Loan
															</Link>
															<div
																className="customize-header-toggle"
																onClick={() =>
																	setHeaderOpen(previous =>
																		previous === "education_loan"
																			? "default"
																			: "education_loan"
																	)
																}
															>
																<FaAngleDown
																	style={{ margin: "5px 14px" }}
																	size={18}
																/>
															</div>
														</div>
														<ul
															className={`dropdown-menu px-3 ${
																headerOpen === "education_loan"
																	? "show1"
																	: ""
															}`}
														>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/annual-fee-finance"
																>
																	Annual Fee Finance
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/education-loan/apply"
																>
																	Education Loan With Moratorium
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/school-fee-finance"
																>
																	School Fee Finance
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/interest-free-loan"
																>
																	Interest Free Loan
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/study-abroad-loan"
																>
																	Study Abroad Loan
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/refinance-education-loan"
																>
																	Refinance Education Loan
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/teacher-loan"
																>
																	Teacher Loan
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/education-loan/apply"
																>
																	EdTech Loans
																</Link>
															</li>
														</ul>
													</li>
													<li className="nav-item dropdown dropdown-submenu customized-nav dropstart-lg">
														<div className="d-flex">
															<Link
																className="dropdown-item dropdown-list-group-item dropdown-toggle"
																to="/car-loan"
															>
																Car Loan
															</Link>
															<div
																className="customize-header-toggle"
																onClick={() =>
																	setHeaderOpen(previous =>
																		previous === "car_loan"
																			? "default"
																			: "car_loan"
																	)
																}
															>
																<FaAngleDown
																	style={{ margin: "5px 14px" }}
																	size={18}
																/>
															</div>
														</div>
														<ul
															className={`dropdown-menu px-3 ${
																headerOpen === "car_loan" ? "show1" : ""
															}`}
														>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/new-car-loan"
																>
																	New Car Loan
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/used-car-loan"
																>
																	Used Car Loan
																</Link>
															</li>
															<li>
																<Link
																	className="dropdown-item dropdown-list-group-item"
																	to="/loan-against-car"
																>
																	Loan Against Car
																</Link>
															</li>
														</ul>
													</li>
													<li>
														<Link className="dropdown-item" to="/personal-loan">
															Personal Loan
														</Link>
													</li>
													<li>
														<Link className="dropdown-item" to="/home-loan">
															Home Loan
														</Link>
													</li>
													<li>
														<Link
															className="dropdown-item"
															to="/two-wheeler-loan"
														>
															Two Wheeler Loan
														</Link>
													</li>
													<li>
														<Link
															className="dropdown-item"
															to="/e-vehicle-loan"
														>
															E-Vehicle Loan
														</Link>
													</li>
													<li>
														<Link className="dropdown-item" to="/business-loan">
															Business Loan
														</Link>
													</li>
													<li>
														<Link
															className="dropdown-item"
															to="/loan-against-property"
														>
															Loan Against Property
														</Link>
													</li>
													<li>
														<Link className="dropdown-item" to="/medical-loan">
															Medical Loan
														</Link>
													</li>
													<li>
														<Link className="dropdown-item" to="/micro-loan">
															Micro Loan
														</Link>
													</li>
													<li>
														<Link
															className="dropdown-item"
															to="/solar-panel-loan"
														>
															Solar Panel Loan
														</Link>
													</li>
													<li>
														<Link className="dropdown-item" to="/marriage-loan">
															Marriage Loan
														</Link>
													</li>
													<li>
														<Link
															className="dropdown-item"
															to="/consumer-durable-loan"
														>
															Consumer Durable Loan
														</Link>
													</li>
												</ul>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="/contact-us">
													Contact Us
												</Link>
											</li>
										</>
									)}
								</>
							) : ""}
							{!user.access_token ? (
								<>
									<li className="nav-item">
										<div className="nav-link cursor-point" onClick={handleShow}>
											Login
										</div>
									</li>
								</>
							) : (
								<>
									{user && user.user_type === "Individual" ? (
										<li className="nav-item">
											<Link className="nav-link" to="/account/dashboard">
											Dashboard
											</Link>
										</li>
									) : ""}
									{user && user.access_token && user.user_type !== "Lender Auditor" ? (
										<li>
											<Link
												to="/account/notifications"
												className="nav-link nav-svg"
											>
												<IoNotificationsOutline style={{ fontSize: "1.5rem" }} />
											</Link>
										</li>
									) : (
										""
									)}
									<li className="nav-item dropdown">
										<button
											className="nav-link dropdown-toggle link-button text-capitalize text-primary bold"
											id="navbarProducts"
											data-bs-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<img
												src={
													user.user_type === "Financer"
														? user.logo
														: user.image
												}
												className="user-avatar"
												alt={user.name}
											/>
											{user.first_name
												? user.first_name + " " + user.last_name
												: user.name}
										</button>
										<ul
											className={
												"dropdown-menu dropdown-menu-arrow position-absolute p-3 r-0"
											}
											aria-labelledby="navbarProducts"
										>
											{user.user_type !== "Financer" && user.user_type !== "Lender Auditor" ? (
												<>
													<li
														className="nav-item my-2"
														style={{ cursor: "pointer" }}
													>
														<Link
															to={"/account/my-profile"}
															className="nav-link"
														>
															My Profile
														</Link>
													</li>
												</>
											) : (
												""
											)}
											{(user.user_type === "Financer" ||
											(user.designation && (user.designation == "HR Manager" || user.designation == "HR Executive")) ||
												user.username === "Naveenkirpal") && !["dineshgupta", "Vksphf", "wishey"].includes(user.username) ? (
													<>
														<li className="nav-item my-2">
															<Link
																to="/account/users"
																className="nav-link"
															>
															My Users
															</Link>
														</li>
													</>
												) : (
													""
												)}
											{user &&
											(user.username === "pushapduggal" ||
												user.username === "Satparkash" ||
												user.username === "Phf24" ||
												user.username === "phf986" ||
												user.username === "phf110" || user.username === "ghuman") ? (
													<li className="nav-item my-2">
														<Link
															to="/account/dealers"
															className="nav-link"
														>
															Dealers
														</Link>
													</li>
												) : ""}
											{user.user_type === "Financer" && !["dineshgupta", "Vksphf", "wishey"].includes(user.username) ? (
												<>
													<li
														className="nav-item my-2"
														style={{ cursor: "pointer" }}
													>
														<Link
															to={"/account/settings"}
															className="nav-link"
														>
															Settings
														</Link>
													</li>
												</>
											) : (
												""
											)}
											{user &&
											(user.user_type === "Financer" || accessToDealers.includes(user.username)) ? (
													<>
														<li className="nav-item my-2">
															<button
																type="button"
																className="nav-link link-button"
																onClick={() => dealerSignUpHandler()}
															>
																Create Dealer
															</button>
														</li>
													</>
												) : (
													""
												)}
											{user && user.user_type !== "connector" && user.user_type !== "Lender Auditor" ? (
												<li className="nav-item">
													<button
														className="nav-link link-button"
														onClick={handleShowInvite}
													>
												Invite
													</button>
												</li>
											) : ""}
											<Modal
												show={showInvite}
												onHide={handleCloseInvite}
												size={`${
													user.user_type === "Employee" ||
												user.user_type === "Dealer"
														? "lg"
														: "lg"
												} invite-modal`}
												centered
											>
												<InviteLinks />
											</Modal>
											{/* {user && (user.user_type === "Financer" || user.user_type === "Employee") && (process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing")) ? (*/}
											{/*	<>*/}
											{/*		<li className="nav-item my-2">*/}
											{/*			<Link to="/account/negative-locations"className="nav-link">*/}
											{/*				Negative Locations*/}
											{/*			</Link>*/}
											{/*		</li>*/}
											{/*	</>*/}
											{/* ) : ""}*/}
											{(process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing")) && user.user_type !== "Lender Auditor" && !["dineshgupta", "Vksphf", "wishey"].includes(user.username) ? (
												<>
													<li className="nav-item my-2">
														<Link
															to="/account/e-sign/list"
															className="nav-link"
														>
															E-Sign List
														</Link>
													</li>
													<li className="nav-item my-2">
														<Link
															to="/account/apps/list"
															className="nav-link"
														>
															My Apps
														</Link>
													</li>
												</>
											) : (
												""
											)}
											<li
												className="nav-item my-2"
												style={{ cursor: "pointer" }}
											>
												<div
													className="nav-link"
													onClick={() => authServices.logout(removeUser)}
												>
													Logout
												</div>
											</li>
										</ul>
									</li>
								</>
							)}
						</ul>
						{!user || user.user_type === "Individual" ? (
							<>
								<div className="ms-lg-3 mt-3 d-grid mt-lg-0">
									<Link to="/pro" className="btn btn-secondary btn-sm">
										Pro Membership
									</Link>
								</div>
								<div className="ms-lg-3 mt-3 d-grid mt-lg-0">
									<Link to="/apply" className="btn btn-primary btn-sm">
										Apply Now
									</Link>
								</div>
							</>
						) : (
							""
						)}
					</div>
				</div>
			</nav>
			{user.user_type === "Employee" || user.user_type === "Individual" ? (
				<div className="div">
					<div className="d-md-none z-4 mobile-options-list">
						<div className="w-100 shadow" style={{backgroundColor:"white",color:"black",fontSize:"14px"}}>
							<div className="buttons d-flex justify-content-evenly align-items-center">
								{user.user_type === "Individual" ? (
									<li className="p-2 text-center">
										<Link to={"/account/my-loans"}>
											<img src="/images/icons/my-loan.svg" className="img-fluid loan-svg"/>
											<div className="dark-text">My Loans</div>
										</Link>
									</li>
								): (
									<li className="p-2 text-center"  onClick={() => setShow(true)}>
										<FaQrcode
									 size={20} color="#00a0e3"/>
										<div>Show QR</div>
									</li>
								)}
								{user.user_type === "Individual" ? (
									<li className="p-2 text-center">
										<Link to="/apply">
											<FaNotesMedical size={20} color="#00a0e3" />
											<div className="dark-text">Apply Loan</div>
										</Link>
									</li>
								) : (
									<li className="p-2 text-center"  onClick={handleShowApplyLoan}>
										<FaNotesMedical size={20} color="#00a0e3" />
										<div>Create Lead</div>
									</li>
								)}
								{user.user_type === "Individual" ? (
									<li className="p-2 text-center">
										<Link to={"/account/individual-profile"}>
											<FaUser  size={20} color="#00a0e3"/>
											<div className="dark-text">My Profile</div>
										</Link>
									</li>
								) : (
									<li className="p-2 text-center" onClick={() => handleShowApplyLoan("emi")} >
										<FaRupeeSign  size={20} color="#00a0e3"/>
										<div>Collect EMI</div>
									</li>
								)}	
							</div>
						</div>
					</div>
					<QrCodeModal
						show={show}
						onHide={() => setShow(false)}
					/> 
				</div>
			): ""}
			
			{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
				<EmiCollectionForm show={showEmiModal} hide={handleCloseEmi} geoLoc={vals} />
			) : ""}

			<Login
				withModal={true}
				show={modalLogin}
				onHide={handleClose}
				signShow={signShow}
				organizationShow={organizationShow}
				handleShow={handleShow}
			/>
			<OrganizationSignUp show={orgShow} onHide={orgShowed} />
			<SignUp show={signUp} onHide={signClose} />
		</>
	);
};
export default Navbar;
