import { FaEdit, FaTimes } from "react-icons/fa";

const BrandsListDashboard = ({row, brandUpdates, removeBrand}) => {
	
	return (
		<div className="mt-3 d-flex flex-wrap">
			{row.data.assignedVehicleTypes && row.data.assignedVehicleTypes.length
				? row.data.assignedVehicleTypes.map((value, index) => {
					return (
						<>
							<div
								className="col-2 shadow-ell mx-2 p-3 mb-3 brands-inned-design"
								key={`innerBrandList${index}`}
							>
								<div className="remove-brand-btn">
									<span className="mx-1">
										<FaEdit size={12} onClick={() => {
											brandUpdates({brand_id: value.financer_vehicle_brand_enc_id, type_id: value.financer_vehicle_type_enc_id, name: value.brand_name, logo: value.logo});
										}}/>
									</span>
									<span>
										<FaTimes size={12} color={"red"} onClick={() => {
											removeBrand(value.assigned_vehicle_types_enc_id);
										}}/>
									</span>
								</div>
								{value.logo ? (
									<img
										src={value.logo}
										style={{
											width: "100%",
											height: "50px",
											objectFit: "contain",
											margin: "auto"
										}}
										className="img-fluid"
										alt={value.brand_name}
									/>
								) : (
									""
								)}
								{value.brand_name}
							</div>
						</>
					);
				  })
				: null}
		</div>
	);
};
export default BrandsListDashboard;
