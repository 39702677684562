import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import AutoComplete from "../forms/AutoComplete";
import { useContext, useState } from "react";
import { MainContext } from "../../context/MainState";
import AutoSuggestLoanNumber from "./AutoSuggestLoanNumber";
import Datepicker from "react-datepicker";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import { getLatLong, isLocationEnabled } from "../../helpers/customFunctions";

const DealerCollectionForm = ({setReloadState}) => {
	const { user } = useContext(MainContext);
	const [caseExist, setCaseExist] = useState({ status: false, caseNum: "" });
	const [selectedCase, setSelectedCase] = useState({});
	const [selectedDealer, setSelectedDealer] = useState({dealer_enc_id: "", dealer_name: ""});
	const [counter, setCounter] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [addedEmis, setAddedEmis] = useState([
		{ id:0, dealer_enc_id: "", loan_account_number: "", collected_amount: "", collection_date: "",  phone:"", dealer_name: "" }
	]);
	const currentDate = new Date();
	const previousMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
	const {
		// register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm();

	const addMoreEmi = () => {
		let count = counter + 1;
		setAddedEmis([...addedEmis, { id: count, dealer_enc_id: selectedDealer.dealer_enc_id ? selectedDealer.dealer_enc_id : "", 
			loan_account_number: "", collected_amount: "", collection_date: "", phone:"",
			dealer_name: selectedDealer.dealer_name  ? selectedDealer.dealer_name : ""  }]);

		setCounter(count);
	};

	const removeEmiRow = (index) => {
		let items = [...addedEmis];
		items = items.filter(item => item.id != index);
		setAddedEmis(items);
	};

	const setEmiValue = (index, type, value, id, event) => {
		let items = [...addedEmis];
		let item = items[index];
		item[type] = value;
		if(event){
			item["dealer_name"]= event;
		}
		items[index] = item;
		setAddedEmis(items);
		clearErrors(type+"_"+id);

		if(type === "dealer_enc_id"){
			setSelectedDealer({"dealer_enc_id" : value, "dealer_name": event});
		}
	};

	// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const phoneRegExp = /^(\+91|\+91-|0)?[789]\d{9}$/;
	const numberValidateRegex = /^[0-9]\d*$/;
	const handleDateChange = (index, type, date, id) => {
		date = moment(date).format("YYYY-MM-DD");
		setEmiValue(index, type, date, id);
	};
	
	const onSubmit = async () => {
		if (!await isLocationEnabled()) {
			toast.error("Please allow location to continue");
			return;
		}
		const { latitude, longitude } = await getLatLong();

		let flag = false;
		setIsLoading(true);
		addedEmis.forEach(val => {
			let id = val.id;
			Object.entries(val).forEach(([key, value]) => {
				let message = "";
				if (key === "phone" && !value.match(phoneRegExp)) {
					message = "Invalid phone number format";	
				}
				if (key === "collected_amount" && !value.match(numberValidateRegex)) { 
					message = "Invalid collected amount format";
				}
				if(value === "" && key != "dealer_name" && key != "id"){
					message = "This Field Is Required";
				}
				if(message){
					setError(`${key}_${id}`, {
						type: "custom",
						message: message
					});
					flag = true;
				}
			});
		});

		if(flag){
			setIsLoading(false);
			return false;
		}
		let data = {latitude: latitude, longitude: longitude, emis: addedEmis};
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/dealer-collection`, data).then(
			response => {
				setIsLoading(false);
				if(response.data.response.status === 200){
					setSelectedDealer({dealer_enc_id: "", dealer_name: ""});
					setCaseExist({ status: false, caseNum: "" });
					setSelectedCase({});
					setAddedEmis([{ id:0, dealer_enc_id: "", loan_account_number: "", collected_amount: "", collection_date: "",  phone:"", dealer_name: "" }]);
					setReloadState(true);
				};
			}
		);
	};
	return (
		<>
			<div className="shadow-lg rounded-3 py-4 px-6">
				<h2 className="ptp-heading mb-3">Add Collected EMI&#39;s</h2>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					{addedEmis && addedEmis.length
						? addedEmis.map((val, index) => {
							return (
								<div className="row" key={`added-emis-${index}`}>
									<div className="mb-4 col-md-2" >
										<label
											className="form-label"
											htmlFor={`dealer_enc_id_${val.id}`}
										>
													Search Dealer <span style={{ color: "red" }}>*</span>
										</label>
										<AutoComplete
											setFieldValue={(event, value) => setEmiValue(index, "dealer_enc_id", value, val.id, event)}
											link={`dealers?type=dealer&assigned_financer_enc_id=${user.organization_enc_id}&employee_search=`}
											name={val.dealer_name}
											method={"GET"}
											placeholder={"Enter Dealer Name"}
											type="dealer_id"
											val={val.dealer_name ? val.dealer_name : ""}
										/>
										<ErrorMessage
											errors={errors}
											name={`dealer_enc_id_${val.id}`}
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px", marginBottom:"0px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="mb-4 col-md-2">
										<label
											className="form-label" htmlFor={`loan_account_number_${val.id}`}
										>
												Loan Account Number{" "}
											<span style={{ color: "red" }}>*</span>
										</label>
										<AutoSuggestLoanNumber
											setSelectedcase={setSelectedCase}
											setVal={(event, value) => setEmiValue(index, "loan_account_number", value, val.id)}
											setCaseExist={setCaseExist}
											caseExist={caseExist}
											fetchedCase={selectedCase}
											fieldname={`loan_account_number_${val.id}`}
											filledValue={val?.loan_account_number ? val.loan_account_number : ""}
											placeholder={"Enter Loan Account Number"}
												
										/>
										<ErrorMessage
											errors={errors}
											name={`loan_account_number_${val.id}`}
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px", marginBottom:"0px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="mb-4 col-md-2">
										<label
											className="form-label"
											htmlFor={`collected_amount_${val.id}`}
										>
											Amount Collected <span style={{ color: "red" }}>*</span>
										</label>
										<input
											placeholder="Enter Collected Amount"
											className="form-control"
											value={val.collected_amount ? val.collected_amount : ""}
											// {...register(`collected_amount_${val.id}`, {	
											// 	pattern: {
											// 		value: numberValidateRegex,
											// 		message: "Collection Amount is invalid"
											// 	}
												
											// })}
											onChange={(e) => setEmiValue(index, "collected_amount", e.target.value, val.id)}
										/>
										<ErrorMessage
											errors={errors}
											name={`collected_amount_${val.id}`}
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px", marginBottom:"0px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="mb-4 col-md-2">
										<label className="form-label" htmlFor={`collection_date_${val.id}`}>
											Collection Date <span style={{ color: "red" }}>*</span>
										</label>

										<Datepicker
											onChange={date => handleDateChange(index, "collection_date", date, val.id)}
											maxDate={currentDate}
											minDate={previousMonthDate}
											placeholderText="Choose Collection Date"
											dropdownMode="select"
											className={"form-control "}
											isClearable={true}
											dateFormat="dd MMM yyyy"
											name=""
											value={val.collection_date ?  moment(val.collection_date).format("DD MMM YYYY") : ""}
										/>
										<ErrorMessage
											errors={errors}
											name={`collection_date_${val.id}`}
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px", marginBottom:"0px" }}>
													{message}
												</p>
											)}
										/>
											
									</div>
									<div className="mb-4 col-md-2">
										<label className="form-label" htmlFor={`phone_${val.id}`}>
												Phone Number <span style={{ color: "red" }}>*</span>
										</label>
										<input
											placeholder="Enter Phone Number"
											className="form-control"
											value={val.phone ? val.phone : ""}
											// {...register(`phone_${val.id}`, {
											// 	minLength: {
											// 		value: 10,
											// 		message:
											// 		"Mobile number Should be at least 10 numbers"
											// 	},
											// 	maxLength: {
											// 		value: 10,
											// 		message:
											// 		"Mobile number maximum be at least 10 numbers"
											// 	},
											// 	pattern: {
											// 		value: phoneRegExp,
											// 		message: "Enter Phone Number"
											// 	}
											// })}
											onChange={(e) => setEmiValue(index, "phone", e.target.value, val.id)}
										/>
										<ErrorMessage
											errors={errors}
											name={`phone_${val.id}`}
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px", marginBottom:"0px" }}>
													{message}
												</p>
											)}
										/>
									</div>
									<div className="col-md-2">
										{index > 0 ?  
											<button type="button" className="mt-5 btn btn-primary me-2" onClick={() => removeEmiRow(val.id)}>
												<MdDelete fontSize={20} />
											</button>
											 : ""}
										{addedEmis.length - 1 === index ?
											<button type="button" className="mt-5 btn btn-primary" onClick={addMoreEmi}>
												<AiOutlinePlus fontSize={20} />
											</button>
											: "" }
									</div>
								</div>
							);
						})
						: ""}
					<div className="row">
						<div className="col-md-12 text-center">
							{isLoading ? (
								<button type="button" className="mt-2 btn btn-primary" disabled={true}>
									<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
								</button>
							) : 
								(
									<button type="submit" className="mt-2 btn btn-primary">
										Save
									</button>
								)
							}
						</div>
						
					</div>
				</form>
			</div>
		</>
	);
};

export default DealerCollectionForm;
