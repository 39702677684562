import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import { useContext } from "react";
import { MainContext } from "../../context/MainState";
import { Link } from "react-router-dom";
import { OrganizationUsersProvider } from "../../context/OrganizationUsersState";
import ConnectorUsersTable from "../../components/dashboard/ConnectorUsersTable";

const DsaUsers = () => {
	const { user } = useContext(MainContext);
	return (
		<div>
			<Helmet>
				<title>Connectors | {process.env.REACT_APP_NAME}</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<OrganizationUsersProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className={"d-flex justify-content-between mb-3"}>
							<h1 className="head-title mb-0">Connectors</h1>
							<div className="dashboard-sub-action d-flex">
								{user.organization_username &&
								user.organization_username === "phfleasing" ? (
										<>
											<Link
												to="/account/e-signing"
												className="btn btn-primary btn-sm d-flex align-items-center mx-1"
											>
											Document E-Sign
											</Link>
											<Link
												to="/account/e-sign/list"
												className="btn btn-primary btn-sm d-flex align-items-center mx-1"
											>
											E-Sign List
											</Link>
										</>
									) : (
										""
									)}
							</div>
						</div>

						{/* <OrganizationStats/>*/}
						<div className="shadow-lg rounded-3 py-6 px-6">
							<ConnectorUsersTable />
						</div>
					</div>
				</div>
			</OrganizationUsersProvider>
		</div>
	);
};
export default DsaUsers;
