import { Helmet } from "react-helmet";
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { BsSearch } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
const Pharmacy = () => {
	return (
		<>
			<Helmet>
				<title>Buy Medicines Online</title>
			</Helmet>
			<Navbar />
			<div className="order-med-main">
				<div className="container">
					<div className="row">
						<div className="col-md-7 col-sm-12">
							<div className="order-med-online">
								<div className="Order-med-content">
									<h1>Get Your Medications Delivered to Your Doorsteps</h1>
								</div>
								<div className="order-med-searchbox">
									<div className="order-med-searchText">
										Search for Medicines / Healthcare Products
									</div>
									<div className="order-med-searchbar">
										<div className="select">
											<p>Select Pincode</p>
											<IoIosArrowDown size="18px" />
										</div>
										<input type="text" placeholder="Search Medicines" />
										<button className="searchBtn">
											<BsSearch size="20px" color="#6c757d" />
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-5 col-sm-12">
							<div className="order-med-img">
								<img
									src="/images/pages/pharmacy/medicine-at-ur-doorsteps.png"
									alt="Medicine at your doorsteps"
									className="img-fluid"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="shop-category-main pt-10 bg-white">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12">
							<div className="shop-category-headtext">
								<h1 className="mb-5 text-center">Shop By Category</h1>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-2">
							<div className="category-card">
								<div className="category-img"></div>
								<div className="category-name"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Pharmacy;
