import { FaRegEdit, FaTimes } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import AutoComplete from "./forms/AutoComplete";
import { GiCheckMark } from "react-icons/gi";
import { toast } from "react-toastify";
import axios from "axios";
import { readOnlyUsers } from "../helpers/authServices";
import { MainContext } from "../context/MainState";

const 	EditTypehead = ({
	api,
	defaultValue,
	colName,
	parentId,
	getData,
	placeHolder = "Designation",
	hideEditButton = false,
	searchUrl = "utilities/designations?keyword=",
	type = null,
	callback = () => {
		return true;
	}
}) => {
	const {user} = useContext(MainContext);
	const [statusToggle, setStatusToggle] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const [currentVal, setCurrentVal] = useState(defaultValue);

	const handleSave = (value, colName) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let data = { id: colName, value: value, parent_id: parentId };
		if (value === parentId && colName === "reporting_person") {
			toast.error("Employee and reporting person cannot be same");
			return false;
		}
		if (value === "") {
			toast.error("Please Enter Value");
			return false;
		}
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				data
			)
			.then(response => {
				setLoading(false);
				getData(colName);
				if (response.data.response.status === 200) {
					setStatusToggle(false);
					setCurrentVal("");
					toast.success("Successfully updated");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
				toast.error("An error has occurred, Please try again");
			});
	};
	
	useEffect(() => {
		callback(statusToggle);
	}, [statusToggle]);
	return (
		<>
			{statusToggle && showCurrent === colName ? (
				<>
					{!loading ? (
						<>
							<AutoComplete
								link={searchUrl}
								setFieldValue={(event, value) => setCurrentVal(value)}
								name={"designation"}
								method={"GET"}
								placeholder={placeHolder}
								val={defaultValue ? defaultValue : ""}
								type={type}
							/>
							<span
								style={{
									margin: "0px 5px",
									marginTop: "-2px",
									cursor: "pointer",
									color: "green"
								}}
								onClick={() => {
									handleSave(currentVal, colName);
								}}
							>
								<GiCheckMark size={15} />
							</span>
							<span
								style={{
									margin: "0px 5px",
									marginTop: "-2px",
									cursor: "pointer",
									color: "red"
								}}
								onClick={() => {
									setStatusToggle(false);
								}}
							>
								<FaTimes size={15} />
							</span>
						</>
					) : (
						<i>Please Wait Saving...</i>
					)}
				</>
			) : (
				<>
					<div
						style={{ margin: "0px 5px", display: "flex", alignItems: "center" }}
						onDoubleClick={() => {
							setShowCurrent(colName);
							setStatusToggle(true);
						}}
						onClick={() => {
							if(hideEditButton) {
								setShowCurrent(colName);
								setStatusToggle(true);
							}
						}}
					>
						{defaultValue ? <>{defaultValue}</> : "N/A"}
						{!hideEditButton ? (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer",
									margin: "0px 5px"
								}}
								size={16}
								onClick={() => {
									setShowCurrent(colName);
									setStatusToggle(true);
								}}
							/>
						) : ""}
					</div>
				</>
			)}
		</>
	);
};

export default EditTypehead;
