import Navbar from "../../components/Navbar";
import React, { useContext, useState } from "react";
import "../../assets/css/accountStyles.css";
import { Helmet } from "react-helmet";
import CollectionReportTable from "../../components/dashboard/CollectionReportTable";
import { EmployeeReportsProvider } from "../../context/EmployeeReports";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "react-bootstrap";
import CollectionReportTableBranches from "../../components/dashboard/CollectionReportTableBranches";
import { MainContext } from "../../context/MainState";
import CollectionReportTableStates from "../../components/dashboard/CollectionReportTableStates";

const CollectionReport = () => {
	const {user} = useContext(MainContext);
	const [active, setActive] = useState("1");
	const [loadSecond, setLoadSecond] = useState(false);
	const [loadThird, setLoadThird] = useState(false);

	const toggle = tab => {
		if (active !== tab) {
			setActive(tab);
		}
	};
	return (
		<>
			<Helmet>
				<title>Collection Report | {process.env.REACT_APP_NAME}</title>
			</Helmet>
			<Navbar />
			<EmployeeReportsProvider>
				<div className="dashboard-dsa py-5 branch-report-main">
					<div className="container-fluid">
						<div className="loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
							<div className={"d-flex justify-content-between mb-3"}>
								<h4 className="mb-0">Collection Reports</h4>
							</div>
							{user.user_type === "Financer" ? (
								<Nav className="mb-4 users-nav">
									<NavItem>
										<NavLink
											active={active === "1"}
											onClick={() => {
												toggle("1");
											}}
										>
										By Employees
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											active={active === "2"}
											onClick={() => {
												toggle("2");
												setLoadSecond(true);
											}}
										>
										By Branches
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											active={active === "3"}
											onClick={() => {
												toggle("3");
												setLoadThird(true);
											}}
										>
										By States
										</NavLink>
									</NavItem>
								</Nav>
							) : ""}
							<TabContent className="py-50" activetab={active}>
								<TabPane tabid="1" className={active === "1" ? "active" : ""}>
									<CollectionReportTable />
								</TabPane>
								<TabPane tabid="2" className={active === "2" ? "active" : ""}>
									<CollectionReportTableBranches
										loadDefault={loadSecond}
									/>
								</TabPane>
								<TabPane tabid="3" className={active === "3" ? "active" : ""}>
									<CollectionReportTableStates
										loadDefault={loadThird}
									/>
								</TabPane>
							</TabContent>
						</div>
					</div>
				</div>
			</EmployeeReportsProvider>
		</>
	);
};

export default CollectionReport;
