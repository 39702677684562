import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { RiCloseFill } from "react-icons/ri";
const AssignTelecallersToBucket = () => {
	const [telecallerList, setTelecallerList] = useState([]);
	const [selectedCallers, setSelectedCallers] = useState([]);
	const [bucket, setBucket] = useState("");

	const { handleSubmit, reset } = useForm();

	const getTelecallerList = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-telecaller-list`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setTelecallerList(response.data.response.data);
				} else {
					setTelecallerList([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(
					"An error has occurred while getting Telecaller Data, Please try again"
				);
			});
	};

	const onSubmit = async () => {
		if (!bucket) {
			toast.error("Please Choose Bucket");
			return;
		}
		if (!selectedCallers?.length) {
			toast.error("please Choose Telecaller");
			return;
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/cdd`,
				{caller_ids: selectedCallers, bucket: bucket}
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Loan Accounts Assigned Successfully");
					reset();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			});
	};

	const HandleSelect = (selected, id) => {
		if (id === "bucket") {
			setBucket(selected.value);
			// setBucket(selected.map(option => option.value)); //if multiple Bucket options
		} else {
			setSelectedCallers(selected.map(option => option.value));
		}
	};

	const telecallerOptions = telecallerList
		? telecallerList.map(data => ({
			label: data.name,
			value: data.user_enc_id
		  }))
		: [];

	useEffect(() => {
		if(process.env.REACT_APP_TYPE === "DEV"){
			getTelecallerList();
		}
	}, []);

	let bucketListData = [
		{
			label: "X",
			value: "X"
		},
		{
			label: "1",
			value: "1"
		},
		{
			label: "2",
			value: "2"
		},
		{
			label: "3",
			value: "3"
		},
		{
			label: "4",
			value: "4"
		},
		{
			label: "5",
			value: "5"
		},
		{
			label: "6",
			value: "6"
		},
		{
			label: "7",
			value: "7"
		},
		{
			label: "8",
			value: "8"
		}
	];

	return (
		<>
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="row justify-content-center">
					<div className="col-6">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="first_name">
								Choose Bucket
							</label>
							<Select
								onChange={e => HandleSelect(e, "bucket")}
								options={bucketListData}
								// isMulti
								placeholder="Choose Bucket"
							/>
						</div>
						<div className="mb-3">
							<label className="form-label">Assign Telecaller</label>
							<Select
								onChange={e => HandleSelect(e, "telecaller")}
								options={telecallerOptions}
								isMulti
								placeholder="Choose Telecaller"
							/>
						</div>
						<div className="mb-3 text-center">
							<button type="submit" className="btn btn-primary">
								Save
							</button>
						</div>
					</div>
				</div>
			</form>
			<hr/>
			<div className="mt-5">
				<div style={{ border: "3px solid #eee", borderRadius: "10px", padding: "15px", marginBottom:"20px" }}>
					<h4>2</h4>
					<div className="chip-list">
						<RiCloseFill
							style={{ marginRight: "6px", cursor: "pointer", color: "#bb2124"}}
							size={19}
							onClick={() => console.log("id")}
						/>
                        Kulwinder Singh
					</div>
					<div className="chip-list">
						<RiCloseFill
							style={{ marginRight: "6px", cursor: "pointer", color: "#bb2124"}}
							size={19}
							onClick={() => console.log("id")}
						/>
                        Ravinder Singh
					</div>
				</div>
			</div>
		</>
	);
};
export default AssignTelecallersToBucket;
