import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import RcPendingCases from "../../components/dashboard/RcPendingCases";
import { EmployeeReportsProvider } from "../../context/EmployeeReports";

const RcPendingApplications = () => {
	return (
		<>
			<Helmet>
				<title>RC Pending Applications</title>
			</Helmet>
			<Navbar />
			<EmployeeReportsProvider>
				<div className="container-fluid py-5">
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white">
						<RcPendingCases />
					</div>
				</div>
			</EmployeeReportsProvider>
		</>
	);
};

export default RcPendingApplications;
