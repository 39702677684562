import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
// import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { OrganizationSidebarContext } from "../../context/OrganizationSidebarState";
import { toast } from "react-toastify";
import { MdPendingActions } from "react-icons/md";
const PendenciesModal = ({ loanDetails }) => {
	const { getLoanApplicationPendencies, defaultPendencies, isLoading, setIsLoading } = useContext(
		OrganizationSidebarContext
	);
	const [pendencies, setPendencies] = useState([]);
	const [showPendencyModal, setShowPendencyModal] = useState(false);
	const [borrowerList, setBorrerList] = useState([]);
	const [showBorrower, setShowBorrower] = useState([]);
	const [selectedPendencies, setSelectedPendencies] = useState([]);
	const selectedCustomData = (selected, id) => {
		selectBorrower(selected, id);
		const tempBorrowers = [...showBorrower];
		let pendencyIndex = tempBorrowers.findIndex(items => items.id === id);
		if (pendencyIndex >= 0){
			let pendency = tempBorrowers[pendencyIndex];
			pendency.assignedApplicants = selected.map(data => ({label:data.label, value:data.value}));
		} else {
			tempBorrowers.push({
				id,
				status:true,
				assignedApplicants: selected.map(data => ({label:data.label, value:data.value}))
			});
		}
		setShowBorrower(tempBorrowers);

	};
	const hidePendecyModal = () => {
		setShowPendencyModal(false);
	};
	const checkedPendency = (e, item) => {
		if (e.target.checked) {
			if (item.type === "1") {
				setShowBorrower([
					...showBorrower,
					{ status: true, id: item.pendencies_enc_id, error: "" }
				]);
			}
			setSelectedPendencies([
				...selectedPendencies,
				{
					pendency_name: item.name,
					pendency: item.pendencies_enc_id,
					co_applicants: [],
					type: item.type,
					loan_id: loanDetails.loan_app_enc_id
				}
			]);
		} else {
			if (item.type === "1") {
				let showBorrowerList = [...showBorrower];
				let itemIndex = showBorrowerList.findIndex(
					items => items.id === item.pendencies_enc_id
				);
				showBorrowerList.splice(itemIndex, 1);
				setShowBorrower(showBorrowerList);
			}
			let allSelectedPendency = [...selectedPendencies];
			let pendencyIndex = allSelectedPendency.findIndex(
				items => items.pendency === item.pendencies_enc_id
			);
			allSelectedPendency.splice(pendencyIndex, 1);
			setSelectedPendencies(allSelectedPendency);
		}
	};

	const borrowerOptions = borrowerList.map(value => ({
		value: value.loan_co_app_enc_id,
		label: value.name
	}));
	

	const selectBorrower = (selected, id) => {
		let allSelectedPendency = [...selectedPendencies];
		let pendencyIndex = allSelectedPendency.findIndex(items => items.pendency === id);
		let pendency = allSelectedPendency[pendencyIndex];
		pendency.co_applicants = [];
		if (selected.length) {
			selected.map(val => {
				pendency.co_applicants.push(val.value);
			});
		}
		allSelectedPendency[pendencyIndex] = pendency;
		setSelectedPendencies(allSelectedPendency);
	};

	const submitPendencies = () => {
		setIsLoading(true);
		let all_pendencies = [...selectedPendencies];
		let individual_pendency = all_pendencies.filter(items => items.type === "1");
		let flag = true;
		individual_pendency.forEach(element => {
			let all_show_borrowers = [...showBorrower];
			let borrowerIndex = all_show_borrowers.findIndex(
				elems => elems.id === element.pendency
			);
			let selectedBorrower = all_show_borrowers[borrowerIndex];
			if (element.co_applicants.length === 0) {
				setIsLoading(false);
				selectedBorrower.error = "This field is required";
				flag = false;
			} else {
				selectedBorrower.error = "";
			}
			all_show_borrowers[borrowerIndex] = selectedBorrower;
			setShowBorrower(all_show_borrowers);
		});
		
		if (flag) {
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/assign-pendency`,
					{ data: selectedPendencies, loan_id: loanDetails.loan_app_enc_id }
				)
				.then(response => {
					setIsLoading(false);
					if (response.data.response.status === 200) {
						toast.success("Pendencies updated successfully");
						setShowPendencyModal(false);
						getLoanApplicationPendencies(loanDetails.loan_app_enc_id,loanDetails.loan_products_enc_id,true);
						getLoanApplicationPendencies(loanDetails.loan_app_enc_id,loanDetails.loan_products_enc_id);
					} else {
						toast.error(
							response.data.response && response.data.response.error
								? response.data.response.error
								: "An error occurred, Please try again later"
						);
					}
				})
				.catch(error => {
					setIsLoading(false);
					toast.error(error.message);
					console.error("Error Occurred: ", error);
				});
		}
	};
	
	useEffect(() => {
	
		if (defaultPendencies) {
			let selectedPendArr = [];
			let selectedBorrowerArr = [];
			Object.values(defaultPendencies).map(value => {
				value.map(value => {
					if (value.checked === "1") {
						if (value.type === "1") {
							selectedBorrowerArr.push({
								status: true,
								id: value.pendencies_enc_id,
								assignedApplicants: value.loanApplicationPendencies,
								
								error: ""
							});
						}
						selectedPendArr.push({
							pendency_name: value.name,
							pendency: value.pendencies_enc_id,
							co_applicants: value.loanApplicationPendencies
								? value.loanApplicationPendencies
								: [],
							type: value.type,
							loan_id: loanDetails.loan_app_enc_id
						});
					}
				});
			});
			setShowBorrower(selectedBorrowerArr);
			setSelectedPendencies(selectedPendArr);
			setPendencies(defaultPendencies);
		}
	}, [defaultPendencies]);

	useEffect(() => {
		setPendencies([]);
		setShowBorrower([]);
		if (loanDetails && loanDetails.loan_products_enc_id) {
			setBorrerList(loanDetails.loanCoApplicants);
			getLoanApplicationPendencies(
				loanDetails.loan_app_enc_id,
				loanDetails.loan_products_enc_id
			);
		}
	}, [loanDetails]);

	return (
		<>
			{pendencies && Object.entries(pendencies).length ? (
				<div className="mx-1 btn-sm-width">
					<button 
						className="btn btn-primary btn-sm-sidebar"
						onClick={() => setShowPendencyModal(true)}
					>
						<MdPendingActions /> Raise Pendencies
					</button>
					{showPendencyModal ? (
						<Modal
							show={showPendencyModal}
							onHide={hidePendecyModal}
							size="lg"
							className=""
						>
							<ModalHeader>
								<Modal.Title>Raise Pendencies</Modal.Title>
								<Modal.Title>{loanDetails.application_number}</Modal.Title>
							</ModalHeader>
							<ModalBody>
								<div className="row">
									<div className="col-lg-12">
										<div className="pendency-Parent" style={{minHeight:200, maxHeight:400, overflowY: "scroll" }}>
											{pendencies
												? Object.entries(pendencies).map(
													([key, value], index) => {
														return (
															<>
																<div
																	key={`pendency-${index}`}
																>
																	<h3>{key}</h3>
																	<ul className="list-unstyled">
																		{value && value.length
																			? value.map(
																				(item, index) => {
																					return (
																						<li
																							key={`pen-item-${index}`}
																							className="list-unstyled d-flex justify-content-between align-items-center mb-2"
																							style={{
																								maxWidth:"500px"}}

																						>
																							<label
																								style={{
																									cursor: "pointer"
																								}}
																							>
																								<input
																									type="checkbox"
																									value={
																										item.pendencies_enc_id
																									}
																									defaultChecked={
																										item.checked ===
																										"1"
																											? true
																											: false
																									}
																									disabled={item.checked === "1"}
																									onChange={e =>
																										checkedPendency(
																											e,
																											item
																										)
																									}
																								/>
																								<span
																									style={{
																										marginLeft:
																											"5px",
																										marginBottom:
																											"55px"
																									}}
																								>
																									{
																										item.name
																									}
																								</span>
																							</label>
																							{showBorrower.map((sb,index) => {
																								const assignedApplicants = sb.assignedApplicants || [];

																								// Filter out options that are already assigned
																								const filteredOptions = borrowerOptions?.filter(
																								  option => !assignedApplicants.some(applicant => applicant.value === option.value)
																								);
																								return sb.status && sb.id === item.pendencies_enc_id ? (
																									<div
																										className="width50 borrow_field"
																										key={`sb-${index}`}
																									>
																										<div className="w90">
																											<Multiselect
																												avoidHighlightFirstOption
																												emptyRecordMsg="No Borrowers Found"
																												placeholder={
																													sb.assignedApplicants && sb.assignedApplicants.length >
																														0
																														? sb.assignedApplicants.length +
																																" " +
																																"Selected"
																														: ""

																												}
																												displayValue="label"
																												selectedValues={sb.assignedApplicants}
																												options={filteredOptions}
																												showCheckbox
																												hideSelectedList
																												defaultValue={sb.assignedApplicants ? sb.assignedApplicants : []}
																												onRemove={e =>
																													selectedCustomData(
																														e, item.pendencies_enc_id
																													)
																												}
																												onSelect={e =>
																													selectedCustomData(
																														e, item.pendencies_enc_id
																													)
																												}
																											/>
																										</div>
																										{sb.error ? (
																											<p className="error">
																												{
																													sb.error
																												}
																											</p>
																										) : (
																											""
																										)}
																									</div>
																								) : (
																									""
																								);
																							}
																							)}
																						</li>
																					);
																				}
																			)
																			: ""}
																	</ul>
																</div>
															</>
														);
													}
												)
												: ""}
										</div>
										<hr />
										<div className="text-end">
											{isLoading ? (
												<button
													type="button"
													className="btn btn-primary btn-sm"
													disabled={true}
												>
													<img
														src="/images/pages/home/loader.svg"
														alt={process.env.REACT_APP_NAME}
													/>
												</button>
											) : (
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={() => submitPendencies()}
												>
													Save
												</button>
											)}
										</div>
									</div>
								</div>
							</ModalBody>
						</Modal>
					) : (
						""
					)}
				</div>
			) : (
				""
			)}
		</>
	);
};

export default PendenciesModal;
