import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ResetPasswordModal from "../components/ResetPasswordModal";

const ResetPassword = () => {
	const handleClose = () => {
		return false;
	};
	return (
		<>
			<Helmet>
				<title>Reset Password</title>
			</Helmet>

			<Navbar />
			<div style={{ height: "50vh" }}></div>
			<ResetPasswordModal show={true} onHide={handleClose} />
			<Footer />
		</>
	);
};

export default ResetPassword;
