import { ReactSortable } from "react-sortablejs";
import { RiDeleteBin6Line, RiDragMove2Line } from "react-icons/ri";
import { Typeahead } from "react-bootstrap-typeahead";
import { BsPlusCircleFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LoanProductsContext } from "../../context/LoanProductsState";

const DocumentsTab = ({ productId, showDocuments, handlePageLoaded }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [documentList, setDocumentList] = useState([]);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [currentSelection, setCurrentSelection] = useState("");
	const { register, handleSubmit } = useForm();
	const { getLoanProductsDetail, productDocumentData } = useContext(LoanProductsContext);
	const getDocumentList = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-documents-list`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setDocumentList(response.data.response.documents_list);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const addNewCertificate = () => {
		setSelectedDocuments([
			...selectedDocuments,
			{ id: selectedDocuments.length + 1, name: "" }
		]);
	};

	const updateDocumentList = (selection, index) => {
		let items = [...selectedDocuments]; // array of all selected documents updates every time user add a document
		let itemExists = items.at(index);
		if (itemExists && itemExists.name && selection != itemExists.name) {
			// Runs when we update the exiting documents list
			itemExists.id = index;
			// currentSelection[0]['label'] = gets object from typehead, selection: gets name in result
			itemExists.name = currentSelection.length ? currentSelection[0]["label"] : selection;
			if (itemExists.name) {
				itemExists.itemIndex = documentList.findIndex(val => val.label === itemExists.name);
				removeDocument(itemExists.financer_loan_product_document_enc_id);
				itemExists.financer_loan_product_document_enc_id = "";
				itemExists.financer_loan_product_enc_id = "";
				itemExists.certificate_type_enc_id = "";
				items[index] = itemExists;
			}
		} else {
			// run when new item is added
			let item = { ...items[index] };
			if (currentSelection.length && currentSelection[0]["label"] === selection) {
				item["name"] = currentSelection[0]["label"];
				setCurrentSelection("");
			} else {
				item["name"] = selection;
			}
			item["certificate_type_enc_id"] = "";
			item["assigned_financer_loan_type_id"] = "";
			if (!item.financer_loan_product_enc_id) {
				let duplicate = items.filter(i => i.name === item["name"]); // matches duplicate document
				if (duplicate.length) {
					item["name"] = "";
				}
			}
			item["name"] === "" ? items.splice(index, 1) : (items[index] = item); // removes item from list if item name is empty
		}

		setSelectedDocuments(items);
	};

	const removeElement = (index, id = "") => {
		if (id) {
			if (!window.confirm("Do you really want to delete this document")) {
				return false;
			}
			removeDocument(id);
		}
		let items = [...selectedDocuments];
		items.splice(index, 1);
		setSelectedDocuments(items);
	};

	const onsubmit = () => {
		setIsLoading(true);
		let data = {
			financer_loan_product_enc_id: productId,
			certificate_types: selectedDocuments
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product-documents`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Documents updated successfully");
					getLoanProductsDetail({ id: productId, type: "document" });
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
				setIsLoading(false);
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};

	const handleEdit = selectedList => {
		let newArr = [];
		selectedList.forEach(function (item) {
			let itemIndex = documentList.findIndex(val => val.label === item.name);
			newArr.push({ ...item, itemIndex: itemIndex, defaultVal: true });
		});
		setSelectedDocuments(newArr);
	};

	const removeDocument = id => {
		let data = { financer_loan_product_document_enc_id: id };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/remove-loan-product-document`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Document removed successfully");
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	useEffect(() => {
		if (showDocuments) {
			getLoanProductsDetail({ id: productId, type: "document" });
			handlePageLoaded(true);
		}
	}, [showDocuments]);

	useEffect(() => {
		getDocumentList();
	}, []);

	useEffect(() => {
		if (productDocumentData) {
			handlePageLoaded(false);
			setSelectedDocuments(productDocumentData);
			handleEdit(productDocumentData);
		} else {
			handlePageLoaded(false);
		}
	}, [productDocumentData, documentList]);
	return (
		<>
			<form onSubmit={handleSubmit(onsubmit)} className="mt-6">
				<div className="proof-selection mb20">
					<div className="mb-5">
						<label>
							Click the button to add document and drag document to adjust sequence
						</label>
					</div>
					{selectedDocuments && selectedDocuments.length ? (
						<>
							<ReactSortable list={selectedDocuments} setList={setSelectedDocuments}>
								{selectedDocuments.map((value, index) => {
									return (
										<div
											key={`div-${
												value.id ? value.id : value.certificate_type_enc_id
											}`}
											className="documentTypehead"
										>
											<RiDragMove2Line
												size={25}
												style={{ marginRight: "10px" }}
											/>
											{value.defaultVal && value.itemIndex >= 0 ? (
												<Typeahead
													className="typeheadStyle"
													allowNew
													options={documentList}
													id={
														value.id
															? value.id
															: value.certificate_type_enc_id
													}
													defaultSelected={documentList.slice(
														value.itemIndex,
														value.itemIndex + 1
													)}
													onChange={setCurrentSelection}
													onBlur={e =>
														updateDocumentList(e.target.value, index)
													}
													placeholder="Select Document"
												/>
											) : !value.defaultVal ? (
												<Typeahead
													className="typeheadStyle"
													allowNew
													autoFocus={true}
													options={documentList}
													id={
														value.id
															? value.id
															: value.certificate_type_enc_id
													}
													onChange={setCurrentSelection}
													onBlur={e =>
														updateDocumentList(e.target.value, index)
													}
													placeholder="Select Document"
												/>
											) : (
												<input
													placeholder="Select Document"
													className="form-control"
													{...register("documnet", {
														required: "This is required."
													})}
												/>
											)}
											<button
												className="link-button"
												style={{ color: "red", marginLeft: "10px" }}
												onClick={() =>
													removeElement(
														index,
														value.financer_loan_product_document_enc_id
													)
												}
											>
												<RiDeleteBin6Line size={25} />
											</button>
										</div>
									);
								})}
							</ReactSortable>
						</>
					) : (
						""
					)}
					<button
						className="link-button"
						type="button"
						style={{ color: "#6c757d", marginLeft: "10px" }}
						onClick={() => addNewCertificate()}
					>
						<BsPlusCircleFill size={30} />
					</button>
				</div>
				<div className="mt-5 text-center">
					{isLoading ? (
						<button type="button" className="btn btn-primary" disabled={true}>
							<img src="/images/pages/home/loader.svg" />
						</button>
					) : (
						<button type="submit" className="btn btn-primary">
							{" "}
							Save{" "}
						</button>
					)}
				</div>
			</form>
		</>
	);
};

export default DocumentsTab;
