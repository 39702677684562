import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { IoCloseCircle  } from "react-icons/io5";
import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainState";

const ActionRemarks = ({
	selectedId,
	title,
	api,
	confirmMessage,
	callback,
	onHide
}) => {
	const { handlePageLoaded } = useContext(MainContext);
	const [data, setModal] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	useEffect(() => {
		setModal(selectedId);
	}, [selectedId]);

	const handleClose = () => {
		reset();
		onHide();
	};
	const onSubmit = async values => {
		if (!window.confirm(confirmMessage)) {
			return false;
		}
		handlePageLoaded(true);
		values = {...values, ...data};
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`, values)
			.then(
				response => {
					handlePageLoaded(false);
					if(response?.data?.response?.status === 200){
						toast.success(response?.data?.response?.message);
						callback();
						handleClose();
					} else {
						toast.error(response?.data?.response?.message ? response?.data?.response?.message : "An error has occurred. Please try again later");
					}
				}
			).catch(error => {
				handlePageLoaded(false);
				toast.error(error.message);
			});
	};
	return (
		<>
			<Modal
				show={data}
				onHide={handleClose}
				size={"md"}
				centered
			>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title><span className="close-icon" onClick={handleClose}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="col-12 mb-3">
							<label className="form-label mb-0" htmlFor="date_of_transfer">
								Remarks<span style={{ color: "red" }}>*</span>
							</label>
							<textarea
								className="form-control"
								id="action_remarks"
								rows="4"
								{...register("remarks", {
									required: "This is required."
								})}
								placeholder="Enter Remarks"
							></textarea>
							<ErrorMessage
								errors={errors}
								name="remarks"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>
										{message}
									</p>
								)}
							/>
						</div>
						<button type="submit" className="btn btn-primary m-auto d-block">
							Save
						</button>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ActionRemarks;
