import "../../../assets/css/formstyles.css";
import { IoChevronBack } from "react-icons/io5";
import NachRegisterModal from "../../dashboard/NachRegisterModal";
export default function MultiStepFormContainer({
	children,
	currentStep,
	prevFormStep,
	maxStep = 3,
	preventTabs = false,
	data
}) {
	return (
		<div>
			{currentStep < maxStep && !preventTabs && (
				<>
					{currentStep > 0 && (
						<button className="back" onClick={prevFormStep} type="button">
							<IoChevronBack />
						</button>
					)}
					<div className="d-flex justify-content-between">
						<span className={"steps"}>
						Step {currentStep + 1} of {maxStep}
						</span>
						{data?.nach_approved && !["Active", "Issued"].includes(data.nach_approved) ? (
							<span>	
								<NachRegisterModal loanDetails={data} applicantName={data.name} type="emi-modal" />
							</span>
						 ):""}
					 </div>
				</>
			)}
			{children}		
		</div>
	);
}
