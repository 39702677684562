import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import { Tab } from "react-bootstrap";
import CandidateSidebar from "../../components/dashboard/CandidateSidebar";
import { CandidateDashboardProvider } from "../../context/CandidateDashboardState";
import React from "react";
import MedicalLoanForm from "../../components/forms/MedicalLoanForm";

const MedicalLoan = () => {
	return (
		<div>
			<Helmet>
				<title>Candidate Dashboard</title>
				{/* <meta name="description" content="Tutorial for React Helmet"/>*/}
			</Helmet>
			<Navbar />
			<CandidateDashboardProvider>
				<section>
					<div className="container-fluid mt-4">
						<Tab.Container id="left-tabs-example" defaultActiveKey="first">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12" id="side-bar-main">
									<CandidateSidebar />
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12" id="integration-main">
									<div className="row">
										<div className="col-md-12">
											<div className="portlet light nd-shadow">
												<div className="portlet-title tabbable-line">
													<div className="caption">
														<i className="icon-microphone font-dark hide"></i>
														<span className="caption-subject bold font-dark uppercase">
															Apply Medical Loan
														</span>
													</div>
												</div>
												<div className="portlet-body">
													<MedicalLoanForm />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tab.Container>
					</div>
				</section>
			</CandidateDashboardProvider>
		</div>
	);
};

export default MedicalLoan;
