import React, { useContext, useEffect } from "react";
import { CandidateDashboardContext } from "../../context/CandidateDashboardState";
import { FaInfoCircle } from "react-icons/fa";

const NotificationsList = () => {
	const { getLoanApplications, all_applications } = useContext(CandidateDashboardContext);

	useEffect(() => {
		getLoanApplications();
	}, []);
	return (
		<div className="row">
			{all_applications && all_applications.length ? (
				all_applications.map((data, index) => {
					return (
						<div className="row" key={`loan_applications_list-${index}`}>
							{data.loanApplicationNotifications.length ? (
								<div className="col-md-12">
									<ul className="notifications-list nd-shadow bg-white">
										<h3>
											{data.applicant_name} ({data.loan_type}) -{" "}
											<span>₹ {data.loan_amount} </span>
										</h3>
										{data.loanApplicationNotifications.map(
											(notification, index) => {
												return (
													<li key={`notificationList-${index}`}>
														<div className="container-fluid ">
															<div className="row">
																<div className="col-xs-12">
																	<div className="new-message-box">
																		<div className="new-message-box-info">
																			<div
																				className="info-tab tip-icon-info"
																				title="error"
																			>
																				<FaInfoCircle />
																				<i></i>
																			</div>
																			<div className="tip-box-info">
																				<p
																					style={{
																						marginBottom:
																							"20px"
																					}}
																				>
																					<strong className="formattedDate">
																						{
																							notification.created_on
																						}
																					</strong>
																					{
																						notification.message
																					}
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</li>
												);
											}
										)}
									</ul>
								</div>
							) : (
								""
							)}
						</div>
					);
				})
			) : (
				<div className="col-md-12">
					<div className="main-error-page nd-shadow rounded-3 py-5">
						<img
							src="/images/background/notification.png"
							alt="Error"
							className="img-fluid"
						/>
						<h1 className="error-title">No Notifications found</h1>
					</div>
				</div>
			)}
		</div>
	);
};
export default NotificationsList;
