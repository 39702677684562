import React, { useContext, useState, useEffect } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { MainContext } from "../../context/MainState";
import { Link } from "react-router-dom";
import MultiDataFilters from "../MultiDataFilters";
import SkeletonCard from "./SkeletonCard";

const EmiCollectionStats = ({ statsType = "", type = "", loan_types, isPieFilters = null, piefilters = null, }) => {
	const {user } = useContext(MainContext);
	const { EmiCollectionStatsData, multiLoading, emiCombinedData } = useContext(OrganizationDashboardContext);
	const [loading, setloading]= useState(true);
	const getDataApi = filters => {
		setloading(true);
		const timerId = setTimeout(() => {
			emiCombinedData({
				start_date: filters.start_date,
				end_date: filters.end_date,
				loan_type: filters.loan_type,
				branch_enc_id: filters.branch_name,
				state_enc_id: filters.state_name,
				department: filters.departmnets,
				method: statsType === null ? "pending" : statsType,
				type: true
			}).then(() => setloading(false));
		},1000);
		return () => {
			clearTimeout(timerId);
		};
	};

	const emiObj = {
		Total: "",
		Pending: "pending",
		QR: "qr",
		Link: "link",
		POS: "POS",
		Cash: "cash",
		Cheque: "cheque",
		Nach: "nach",
		eNach: "eNach",
		"Net Banking": "net-banking",
		"RTGS/NEFT": "RTGS-NEFT",
		"Scanned QR": "scanned-qr",
		"Digital Transfer": "digital-transfer",
		"Paid To Dealer": "paid-to-dealer",
		"Not Collected": "",
		Collected: "",
		Pipeline: "",
		Rejected: "",
		Failed: ""
	};

	const getEmiStatsLink = id => {
		const url = window.location.href;
		if (url.split("/").slice(-1).toString() === "emi-collection") {
			return window.location.href + (id ? "/" + id : "");
		}
		const urlFinal = url.slice(0, url.lastIndexOf("/"));
		if (id === "Total") {
			return urlFinal;
		}
		return urlFinal + (id ? "/" + id : "");
	};

	useEffect(() => {
		if(piefilters){
			getDataApi(piefilters);
		}
	}, [piefilters]);
	return (
		<div className={`emi-stats row ${type !== "dashboard-stats" ? "my-6" : ""}`}>
			<div className="col-md-12">
				{!isPieFilters ? (
					<div className="d-flex align-items-center mb-4 justify-content-end flex-wrap">
						<MultiDataFilters
							loan_types={loan_types}
							getData={getDataApi}
							type={["loanAccountsType", "branches", "states", "departments"]}
							defaultDate={{value: "3", label: "Today"}}
						/>
					</div>
				) : ""}
				<div className="row emi-stat gx-3 gy-3">
					{loading ? (
						<div className="row" style={{minHeight:"155px"}}>
							{[...Array(12)].map((index) => (
								<SkeletonCard key={index} type={"md"} widthMatch={type}/>
							))}
						</div>
					) : EmiCollectionStatsData && EmiCollectionStatsData.length ? (
						EmiCollectionStatsData.map((value, index) => {
							return (
								<>
									<div
										className={`${type === "dashboard-stats" ? "col-xl-3 col-lg-4" : "col-xl-2 col-md-4"} col-sm-6 col-6`}
										key={`emiCollectionStatCard-${index}`}
									>
										<Link
											to={(type !== "dashboard-stats" && !isPieFilters && user.user_type === "Financer") || user.specialAccessRole || ["phf110", "KKB", "phf604", "ghuman", "phf403"].includes(user.username) ? getEmiStatsLink(emiObj[value.payment_method]) : ""}
											className="card-body d-block emi-card-stats mb-0"
										>
											{value.payment_method ? (
												<img
													src={`/images/pages/dashboard/emi-stats-icons/${value.payment_method.replace(/[\s/]/g, "-")}.png`}
													className="card-right-img"
													alt={value.payment_method}
												/>
											) : (
												""
											)}
											<p className="card-head mb-3">
												<span className="d-block">{(value.count ? value.count : 0) + (value.pending ? value.pending.count : 0)}</span>
												<span className="fs-5">{value.payment_method} </span>
												<span className="bold d-block">
												₹
													{(
														(value.sum ? parseFloat(value.sum) : 0) +
													(value.pending
														? parseFloat(value.pending.sum)
														: 0)
													).toLocaleString("en-IN")}
												</span>
											</p>
											{value.pending && !statsType ? (
												<div className="d-flex align-items-center justify-content-between">
													<>
														<p className="pending-amounts">
															<span className="fs-5 text-start w-100">{value.count ? value.count : "0"} Paid</span>
															<span className="fs-5 text-start w-100">₹{value.sum ? parseFloat(value.sum).toLocaleString("en-IN") : "0"}</span>
														</p>
														<p className="pending-amounts">
															<span className="fs-5">
																{value.pending
																	? value.pending.count
																	: "0"}{" "}
																{!["QR","Link"].includes(value.payment_method)? "Collected":"Pending"}
															</span>
															<span className="fs-5">₹{value.pending ? parseFloat(value.pending.sum).toLocaleString("en-IN") : "0"}</span>
														</p>
													</>
												</div>
											) : (
												""
											)}
											{value.payment_method === "Pending" && !statsType ? (
												<div className="d-flex align-items-center justify-content-between">
													<>
														{value.custom ? value.custom.map((value2, index2) => {
															return (
																<p className="pending-amounts" key={`pending${index2}`}>
																	<span className="fs-5 text-start w-100">{value2.count ? value2.count : "0"} {value2.payment_method}</span>
																	<span className="fs-5 text-start w-100">₹{value2.sum ? parseFloat(value2.sum).toLocaleString("en-IN") : "0"}</span>
																</p>
															);
														}) : ""}
													</>
												</div>
											) : (
												""
											)}
										</Link>
									</div>
								</>
							);
						})
					) : (
						<div className="no-data text-center">{multiLoading.includes("emiStats") ? (<h3>Loading...</h3>) : ("No data found")}</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default EmiCollectionStats;
