import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import { CandidateDashboardProvider } from "../context/CandidateDashboardState";
import LoanList from "./LoanList";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const MyLoans = () => {
	return(
		<>
			<Helmet>
				<title>My Loans</title>
			</Helmet>
			<Navbar />
			<CandidateDashboardProvider>
				<div className="d-md-none bg-white p-6 mb-6">
					<div className="mb-4 d-flex align-items-center justify-content-between">
						<h2 className="mb-0">My Loans</h2>
						<Link
							className="navbar-brand me-0 dark-text fw-normal fs-4"
							to={"/account/dashboard"}
						><IoIosArrowBack  color={"#000"}/>Back</Link>
					</div>
					<LoanList />
				</div>
			</CandidateDashboardProvider>
		</>
	);
};
export default MyLoans;