import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import React from "react";
import Footer from "../components/Footer";
import AddToCart from "../components/AddToCart";
import CartTotal from "../components/CartTotal";

const Cart = () => {
	return (
		<>
			<Helmet>
				<title>Add to Cart</title>
			</Helmet>
			<Navbar />
			<section className="ProductCart-main mb-10" style={{ marginTop: "4.5rem" }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md- 8 col-sm-12">
							<AddToCart />
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<CartTotal />
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};
export default Cart;
