import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import LoanApplicationCases from "../../components/dashboard/LoanApplicationCases";
import { useState, useEffect } from "react";

const LoanApplicationCase = ({type = ""}) => {
	const [onlydisbursed, setOnlyDisbursed] = useState("");

	useEffect(() => {
		const url = window.location.pathname.split("/").slice(-1).toString();
		if(url === "disbursed" || type === "disbursed"){
			setOnlyDisbursed("only_disbursed");
		} else if(url === "rejected" || type === "rejected"){
			setOnlyDisbursed("only_rejected");
		} else {
			setOnlyDisbursed("only_removed");
		}
	}, [window.location.pathname]);
	
	return(
		<>
			<Helmet>
				<title>Loan Application</title>
			</Helmet>
			<Navbar />
			<div className="container-fluid py-5">
				<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white">
					{onlydisbursed && <LoanApplicationCases type={onlydisbursed} />}
				</div>
			</div>
		</>
	);
};
export default LoanApplicationCase;