import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { useContext, useState} from "react";
import { IoCloseCircle } from "react-icons/io5";
import Datepicker from "react-datepicker";
import moment from "moment";

const PickCashModal = ({loanId, onHide, reload=false}) => {

	const { register, handleSubmit, reset, formState: { errors } } = useForm();
	const { handlePageLoaded } = useContext(MainContext);
	const [selectDate, setSelectedDate] = useState("~");
	const numberValidateRegex = /^[0-9]\d*$/;

	const onSubmit = async values => {
		if (selectDate === "~") {
			toast.error("Please select a date");
			return;
		}
		handlePageLoaded(true);
		try {
			const formData = new FormData();
			formData.append("pickup_date", moment(selectDate).format("YYYY-MM-DD"));
			formData.append("initiated_amount", values.amount);
			formData.append("loan_account_enc_id", loanId.loan_account_enc_id);
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/cash-pickup`,
				formData
			);
			handlePageLoaded(false);
			if (response.data.response.status === 200) {
				reset();
				toast.success("Submit successfully");
				onHide();
				reload(loanId);
			}else {
				toast.error(response.data.response?.error ? response.data.response?.error : "An error has occurred");
			}
		} catch (error) {
			toast.error(error.message);
			handlePageLoaded(false);
		}
	};
	
	
	
	const clearFormData = () =>{
		reset();
		onHide();
	};

	return(
		<>
			<Modal size={"md"} show={loanId && loanId.loan_account_enc_id} onHide={clearFormData}>
				<Modal.Header>
					<Modal.Title>
						Cash Pickup Request
					</Modal.Title><span className="close-icon" onClick={clearFormData}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="first_name">
									Enter Amount <span style={{ color: "red", fontSize: "13px" }}>*</span>
							</label>
							<input type="number"
								min={0}
								className="form-control"
								id="amount"
								{...register("amount", {
									required: "This is required.",
									pattern: {
										value: numberValidateRegex,
										message: "Amount is invalid"
									}
								})}
								placeholder="Enter Amount"
							></input>
							<ErrorMessage
								errors={errors}
								name="amount"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="first_name">
										Collection Date <span style={{ color: "red", fontSize: "13px" }}>*</span>
							</label>
							<Datepicker
								onChange={date => setSelectedDate(date)}
								// maxDate={new Date()}
								placeholderText="Choose Date"
								dropdownMode="select"
								className={"form-control "}
								isClearable={true}
								dateFormat="dd MMM yyyy"
								value={selectDate !== "~" ?  moment(selectDate).format("DD MMM YYYY") : ""}
							/>
						</div>
						<hr/>
						<div className="text-center">
							<button type="submit" className="btn btn-primary">
									Save{" "}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PickCashModal;