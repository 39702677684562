import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import axios from "axios";
import { MainContext } from "../../context/MainState";
import { loanStatusData } from "../../data/loanStatusData";
import { IoCloseCircle  } from "react-icons/io5";
import { readOnlyUsers } from "../../helpers/authServices";
import Select from "react-select";

const ProductChangeModal = ({ productChange, setProductChange, loan_products, loanDetails, openSideBar }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	const { loan_status, handlePageLoaded, user } = useContext(MainContext);
	const [defaultPurposes, setDefaultPurposes] = useState([]);
	const [statusData, setStatusData] = useState([]);
	const [statusValue, setStatusValue] = useState("");
	const [upcomingStatus, setUpcomingStatus] = useState([]);

	const handleLoanProductChange = val => {
		const tempProductPurposes = loan_products
			.filter(value => value.financer_loan_product_enc_id === val)
			.map(product => product.financerLoanProductPurposes);
      
		const assignedStatus = loan_status.find(item => item.financer_loan_product_enc_id === val);

		if (assignedStatus) {
			const { financerLoanProductStatuses } = assignedStatus;
			if (financerLoanProductStatuses) {
				setUpcomingStatus(financerLoanProductStatuses);
			}
		} else {
			const otherStatus = loanStatusData.loanStatus.map(status => ({
				label: status.name,
				value: status.value
			}));
			setStatusData(otherStatus);
		}

		setDefaultPurposes(tempProductPurposes.length ? tempProductPurposes[0] : []);
	};

	useEffect(() => {
		if(upcomingStatus){
			const groupOrder = ["lead", "verification", "physical_discussion", "approval", "legal", "sanction", "disbursement", "reject", "close", "unassigned"];
			const sortedGroups = groupOrder.filter(group => upcomingStatus[group]);

			const groupedOptions = sortedGroups.map(group => ({
				label: group.replace("_", " "),
				options: upcomingStatus[group].map(status => ({
					label: status.name,
					value: status.value
				}))
			}));
			setStatusData(groupedOptions);
		}
    
	}, [upcomingStatus]);

      

	const onSubmit = values => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if(statusValue === ""){
			toast.error("Please Choose Status");
			return false;
		}
		let data = { ...values, loan_id: loanDetails.loan_app_enc_id, purpose: values.purpose ? values.purpose.filter(value => value) : [], status : statusValue};
		if (defaultPurposes.length && !data.purpose.length){
			toast.error("Please select purposes");
			return false;
		}
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-applications/update-loan-application`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Loan Product Changed Successfully");
					setProductChange(false);
					openSideBar(loanDetails.loan_app_enc_id);
					reset();
				}else {
					toast.error("An error occurred while updating");
				}
			}).catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	const CustomStyle = {
		option: (data, state) => ({
			...data,
			backgroundColor: state.isFocused ? "#00a0e3" : state.isSelected ? "#fff" : "#fff",
			color: state.isFocused ? "#fff" : state.isSelected ? "#00a0e3" : "#000",
			fontWeight: state.isSelected ? "bold" : "",
			cursor: "pointer",
		})
	};


	const handleResetForm = () => {
		setDefaultPurposes([]);
		reset();
	  };

	return (
		<>
			<Modal show={productChange} onHide={() => {handleResetForm(); setProductChange(false);}} size={"lg"} centered>
				<Modal.Header>
					<Modal.Title>Update New Loan Product <span className="close-icon" onClick={()=>setProductChange(false)}><IoCloseCircle /></span></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div></div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="product-modal">
							<div className="row">
								<div className="col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
									<label className="form-label mb-0" htmlFor="loanType">
										Loan Product
									</label>
									<select
										id={"loanProduct"}
										className="form-select"
										{...register("product_enc_id", {
											required: "This is required."
										})}
										onChange={e => {
											handleLoanProductChange(e.target.value);
										}}
									>
										<option value="">Choose Loan Product</option>
										{loan_products
											? loan_products.filter(product => product.status === "Active").map((value, index) => {
												return (
													<option
														value={
															value.financer_loan_product_enc_id
														}
														key={`loan-products-${value.financer_loan_product_enc_id}${index}`}
													>
														{value.name}
													</option>
												);
											  })
											: ""}
									</select>
									<ErrorMessage
										errors={errors}
										name={"product_enc_id"}
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>
												{message}
											</p>
										)}
									/>
								</div>
								<div className="col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
									<label className="form-label mb-0" htmlFor="loanType">
										Loan Status
									</label>
									<Select
										options={statusData}
										name="loan-status"
										isSearchable={false}
										placeholder="Select Status"
										onChange={e => setStatusValue(e.value)}
										noOptionsMessage={() => "No Status Available"}
										styles={CustomStyle}
										GroupHeadingProps={{ style: { color: "#00a0e3", fontWeight: "bold" } }}
									/>
								</div>
								{defaultPurposes && defaultPurposes.length ? (
									<div className="col-12">
										<div className="mb-3">
											<label
												className="form-label mb-0 d-block"
												htmlFor="loan_purpose12"
											>
												Purpose of Loan
											</label>
											<div className="radio-wrapper">
												{defaultPurposes.map((value, index) => {
													return (
														<div
															className="radio-item mb-2"
															key={`purpose${index}`}
														>
															<input
																type="checkbox"
																name="purpose"
																value={
																	value.financer_loan_product_purpose_enc_id
																}
																className="radioInput"
																id={`optionVehicleType-${index}-${value.financer_loan_product_purpose_enc_id}`}
																{...register(`purpose[${index}]`)}
															/>
															<label
																htmlFor={`optionVehicleType-${index}-${value.financer_loan_product_purpose_enc_id}`}
																className="option option-1"
															>
																<span>{value.purpose}</span>
															</label>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								) : (
									""
								)}
								<div className="mt-2 text-center">
									<button className="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default ProductChangeModal;
