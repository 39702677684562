import { Modal,Col } from "react-bootstrap";
import { IoCloseCircle } from "react-icons/io5";
import { useContext, useState } from "react";
import AutoComplete from "./forms/AutoComplete";
import { MainContext } from "../context/MainState";



const SearchDealerModal = ({assignDealer,hideSoldToModal,soldToModal,requestEncId}) => {
	const{user}= useContext(MainContext);
	const[dealerName,setDealerName] =useState("");
    
	return (
		<div>
			<Modal  show={soldToModal} onHide={hideSoldToModal} size={"md"} requestEncId={requestEncId} centered>
				<Modal.Header>
					<Modal.Title>
						Search Dealer
					</Modal.Title>
					<span className="close-icon" onClick={() => hideSoldToModal()}>
						<IoCloseCircle />
					</span>
				</Modal.Header>
				<Modal.Body>
					<div className="col-sm-12 col-12">
						<div className="mb-3 d-flex">
							<div className="w-100">
							 <label
									className="form-label"
								>
									Search Dealer <span style={{ color: "red" }}>*</span>
								</label>
							 <AutoComplete
									setFieldValue={(_,value) => setDealerName(value)}
									link={`dealers?type=dealer&assigned_financer_enc_id=${user.organization_enc_id}&employee_search=`}
									method={"GET"}
									placeholder={"Enter Dealer Name"}
									type="repo_dealer"
								/>
								<div className="col-sm-12 col-12">
											 <Col size={12} className="text-center mt-2">
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => {assignDealer(requestEncId,dealerName),hideSoldToModal();}}

										>
													Save
										</button>
									</Col>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default SearchDealerModal;
