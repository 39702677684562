import axios from "axios";
import { useEffect, useState } from "react";

const NachRequesStats = ({finalDate, type= "",searchField={}}) => {
	const [enachRegistrationStats, setEnachRegistrationStats] = useState([]);
	const [totalCounts, setTotalCounts] = useState({ count: 0, amount: 0 });

	const getEnachRegistrationStats = filters => {
		filters["type"] = type;
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/nach-requests-stats`,
				filters
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEnachRegistrationStats(response.data.response.data);
				} else {
					setEnachRegistrationStats([]);
				}
			})
			.catch(error => {
				console.error("An Error Occurred", error);
			});
	};

	useEffect(() => {
		if (finalDate.start_date && type) {
			getEnachRegistrationStats({
				start_date: finalDate.start_date + " 00:00:00",
				end_date: finalDate.end_date + " 23:59:59",
			    fields_search: searchField,
			});
		}
	}, [finalDate, type, searchField]);

	useEffect(() => {
		if (enachRegistrationStats && Object.keys(enachRegistrationStats).length) {
			let totalCount = 0;
			let totalAmounts = 0;
			Object.keys(enachRegistrationStats)
				.filter(val => val.match(/_count/))
				.forEach(val => {
					totalCount += enachRegistrationStats[val] ? parseInt(enachRegistrationStats[val]) : 0;
				});
			Object.keys(enachRegistrationStats)
				.filter(val => val.match(/_sum/))
				.forEach(val => {
					totalAmounts += enachRegistrationStats[val] ? parseFloat(enachRegistrationStats[val]) : 0;
				});
			setTotalCounts({ count: totalCount, amount: totalAmounts });
		}
	}, [enachRegistrationStats]);

	return (
		<div className="dsa-stats">
			<div className="row">
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/total-applications.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{totalCounts.count}
						</p>
						<p className="card-head mb-1">
							<span>Total Registrations</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{totalCounts.amount.toLocaleString("en-IN")}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/new-leads.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{enachRegistrationStats.active_count
								? enachRegistrationStats.active_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Active</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{enachRegistrationStats.active_sum
									? parseFloat(enachRegistrationStats.active_sum).toLocaleString(
										"en-IN"
									  )
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body" style={{height:"129.5px"}}>
						<img
							src="/images/pages/dashboard/cni.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{enachRegistrationStats.inactive_count
								? enachRegistrationStats.inactive_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Inactive</span>
						</p>
						{/* <div className="loan-amounts"> */}
							 {/* <p className="mb-0 text-white text-end bold">
								 ₹{" "} 
								 {enachRegistrationStats.inactive_sum
									? parseFloat(
										enachRegistrationStats.inactive_sum
									  ).toLocaleString("en-IN")
									: " "} 
							</p>
						</div> */}
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/disbursed.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{enachRegistrationStats.issued_count
								? enachRegistrationStats.issued_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Issued</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{enachRegistrationStats.issued_sum
									? parseFloat(enachRegistrationStats.issued_sum).toLocaleString(
										"en-IN"
									  )
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/loan-account-stats/total-pending-amount.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{enachRegistrationStats.pending_count
								? enachRegistrationStats.pending_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Pending</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{enachRegistrationStats.pending_sum
									? parseFloat(enachRegistrationStats.pending_sum).toLocaleString(
										"en-IN"
									  )
									: "0"}
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-2 col-sm-6 col-6">
					<div className="card-body">
						<img
							src="/images/pages/dashboard/emi-stats-icons/Rejected.png"
							className="card-right-img"
							alt="ds"
						/>
						<p className="card-stat mb-0">
							{enachRegistrationStats.failed_count
								? enachRegistrationStats.failed_count
								: "0"}
						</p>
						<p className="card-head mb-1">
							<span>Failed</span>
						</p>
						<div className="loan-amounts">
							<p className="mb-0 text-white text-end bold">
								₹{" "}
								{enachRegistrationStats.failed_sum
									? parseFloat(enachRegistrationStats.failed_sum).toLocaleString(
										"en-IN"
									  )
									: "0"}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default NachRequesStats;
