import { useEffect, useState } from "react";
import SmsFrequencyModal from "./dashboard/SmsFrequencyModal";
import axios from "axios";


const SmsSettings = () => {
	const [modalShow, setModalShow] = useState({show: false, type: ""});
	const [smsTemplates, setSmsTemplates] = useState("");
	const getSmsTemplates = () => {
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}sms/sms-template`).then(
			response => {
				if(response.data.response.status === 200){
					setSmsTemplates(response.data.response.posts);
				}
			}
		);
	};
	useEffect(() => {
		if(!smsTemplates){
			getSmsTemplates();
		}
	}, []);
	
	const hideModal = () => {
		setModalShow({show: false, type: ""});
	};

	return (
		<>
			<h2 className="mb-5">SMS Settings</h2>
			<h4>Message Templates</h4>
			{smsTemplates ? (
				<div className="row">
					{smsTemplates.map((value, index) => {
						return (
							<div className="col-md-6" key={`sms-${index}`}>
								<div className="sms-box">
									<p>{value.content}</p>
								</div>
							</div>
						);
					})}
				</div>
			) : ""}
			<div className="row mb-3"></div>
			<h3>Message Frequencies</h3>
			<div className="d-flex justify-content-between align-item-center">
				<h4>By Bucket</h4>
				<button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow({show: true, type: "bucket"})}>Add Bucket Frequency</button>
			</div>
			<div className="mb-5"></div>

			<div className="d-flex justify-content-between align-item-center">
				<h4>By EMI Date</h4>
				<button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow({show: true, type: "emiDate"})}>Add EMI Date Frequency</button>
			</div>
			<div className="mb-5"></div>

			<div className="d-flex justify-content-between align-item-center">
				<h4>By Overdue</h4>
				<button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow({show: true, type: "overdue"})}>Add Overdue Frequency</button>
			</div>


			<SmsFrequencyModal 
				onHide={hideModal} 
				show={modalShow.show} 
				type={modalShow.type} 
				templates={smsTemplates}
			/>
		</>
	);
};

export default SmsSettings;
