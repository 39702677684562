import { useContext } from "react";
import ProfileDetails from "./ProfileDetails";
import { MainContext } from "../context/MainState";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import ChangePassword from "./ChangePassword";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const IndividualProfile = () => {
	const { user } = useContext(MainContext);
	return(
		<>
			<Helmet>
				<title>My Profile</title>
			</Helmet>
			<Navbar />
			<div className="d-md-none bg-white p-6 mb-6">
				<div className="mb-6 d-flex align-items-center justify-content-between">
					<h2 className="mb-0"> Personal Details</h2>
					<Link
						className="navbar-brand me-0 dark-text fw-normal fs-4"
						to={"/account/dashboard"}
					><IoIosArrowBack  color={"#000"}/>Back</Link>
				</div>
				<ProfileDetails user={user} />
				<ChangePassword />
			</div>
		</>
	);
};

export default IndividualProfile;