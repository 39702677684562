import Navbar from "../../components/Navbar";
import React, { useContext, useState } from "react";
import EmployeeSalesTable from "../../components/dashboard/EmployeeSalesTable";
import { OrganizationUsersProvider } from "../../context/OrganizationUsersState";
import "../../assets/css/accountStyles.css";
import { Helmet } from "react-helmet";
import { Nav, NavItem, TabContent, TabPane, NavLink, Row, Tab } from "react-bootstrap";
import BranchSalesTable from "../../components/dashboard/BranchSalesTable";
import StateSalesTable from "../../components/dashboard/StateSalesTable";
import { MainContext } from "../../context/MainState";
import EmployeeSalesTableByAmount from "../../components/dashboard/EmployeeSalesTableByAmount";
import SalesReportStats from "../../components/SalesReportStats";

const ReportSales = () => {
	const {user} = useContext(MainContext);
	const [active, setActive] = useState("1");
	const [activeBranch, setActiveBranch] = useState("1");
	const [loadSecond, setLoadSecond] = useState("");
	const [loadThird, setLoadThird] = useState("");
	const [filters, setFilters] = useState("");

	const toggle = tab => {
		if (active !== tab) {
			setActive(tab);
		}
	};
	
	const toggleBranch = tab => {
		if (activeBranch !== tab) {
			setActiveBranch(tab);
		}
	};
	
	const filtersData = filters => {
		setFilters(filters);
	};


	return (
		<>
			<Helmet>
				<title>Sales Report | {process.env.REACT_APP_NAME}</title>
			</Helmet>
			<Navbar />
			<OrganizationUsersProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className="loan-application-table shadow-lg rounded-3 py-4 px-6 bg-white">
							<div className={"d-flex justify-content-between mb-3"}>
								<h4 className="mb-0">Sales Reports</h4>
							</div>
							{user.user_type === "Financer" ? (
								<Nav className="mb-4 users-nav overflow-hidden">
									<NavItem>
										<NavLink
											active={active === "1"}
											onClick={() => {
												toggle("1");
												toggleBranch("1");
											}}
										>
										By Employees
										</NavLink>
									</NavItem>
								
									<NavItem>
										<NavLink
											active={active === "2"}
											onClick={() => {
												toggle("2");
												toggleBranch("3");
												setLoadSecond("amount");
											}}
										>
										By Branches
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											active={active === "3"}
											onClick={() => {
												toggle("3");
												toggleBranch("5");
												setLoadThird("amount");
											}}
										>
	                                        By States
										</NavLink>
									</NavItem>
								</Nav>
							) : ""}
							<SalesReportStats filters={filters}/>
							<TabContent className="py-50" activetab={active}>
								<TabPane tabid="1" className={active === "1" ? "active" : ""}>
								  <Row className="mx-0 mt-1 mb-5">
										<Tab.Container id="top-tabs-example" defaultActiveKey="reportType0">
											<Nav variant="pills" className="flex-row">
												<Nav.Item className="accountBucket">
													<Nav.Link eventKey="reportType0" className="py-1 px-3" onClick={() => {toggleBranch("1");}}>Amount</Nav.Link>
												</Nav.Item>
												<Nav.Item className="accountBucket">
													<Nav.Link eventKey="reportType1"  className="py-1 px-3" onClick={() => {toggleBranch("2");}}>Number of Cases</Nav.Link>
												</Nav.Item>
											</Nav>
										</Tab.Container>
									</Row>

									<TabContent className="py-50" activetab={activeBranch}>
										{(active === "1" && activeBranch === "1") &&
										<TabPane tabid="1" className={activeBranch === "1" ? "active" : ""}>
											<EmployeeSalesTableByAmount callback={filtersData} type={"sum"} pageType={"employees"}/>
										</TabPane>
										}
										{(active === "1" && activeBranch === "2" ) &&
											<TabPane tabid="2" className={activeBranch === "2" ? "active" : "" } >
												<EmployeeSalesTable callback={filtersData} type={"count"} pageType={"employee_sales"}/>
											</TabPane>
										}
										
									</TabContent>
								</TabPane>
								<TabPane tabid="2" className={active === "2" ? "active" : ""}>
									<Row className="mx-0 mt-1 mb-5">
										<Tab.Container id="top-tabs-example" defaultActiveKey="reportType0">
											<Nav variant="pills" className="flex-row">
												<Nav.Item className="accountBucket">
													<Nav.Link eventKey="reportType0" className="py-1 px-3" onClick={() => {toggleBranch("3");setLoadSecond("amount");}}>Amount</Nav.Link>
												</Nav.Item>
												<Nav.Item className="accountBucket">
													<Nav.Link eventKey="reportType1"  className="py-1 px-3" onClick={() => {toggleBranch("4");setLoadSecond("cases");}}>Number of Cases</Nav.Link>
												</Nav.Item>
											</Nav>
										</Tab.Container>
									</Row>
									<TabContent className="py-50" activetab={activeBranch}>
										<TabPane tabid="1" className={activeBranch === "3" ? "active" : ""}>
											<BranchSalesTable type="by_amount" loadDefault={loadSecond === "amount" ? loadSecond :""}/>
										</TabPane>
										<TabPane tabid="2" className={activeBranch === "4" ? "active" : ""}>
											<BranchSalesTable type="by_cases" loadDefault={loadSecond === "cases" ? loadSecond :""}/>
										</TabPane>
									</TabContent>
								</TabPane>
								<TabPane tabid="3" className={active === "3" ? "active" : ""}>
									<Row className="mx-0 mt-1 mb-5">
										<Tab.Container id="top-tabs-example" defaultActiveKey="reportType0">
											<Nav variant="pills" className="flex-row">
												<Nav.Item className="accountBucket">
													<Nav.Link eventKey="reportType0" className="py-1 px-3" onClick={() => {toggleBranch("5");setLoadThird("amount");}}>Amount</Nav.Link>
												</Nav.Item>
												<Nav.Item className="accountBucket">
													<Nav.Link eventKey="reportType1"  className="py-1 px-3" onClick={() => {toggleBranch("6");setLoadThird("cases");}}>Number of Cases</Nav.Link>
												</Nav.Item>
											</Nav>
										</Tab.Container>
									</Row>
									<TabContent className="py-50" activetab={activeBranch}>
									 {(active === "3"  && activeBranch === "5") &&
										<TabPane tabid="1" className={activeBranch === "5" ? "active" : ""}>
											<StateSalesTable loadDefault={loadThird === "amount" ? loadThird :""} type={"sum"} dataHandle={"state_sum"} pageType={"states"} />
										</TabPane>
										}
										{(active === "3" && activeBranch === "6") &&
											<TabPane tabid="2" className={activeBranch === "6" ? "active" : ""}>
												<StateSalesTable loadDefault={loadThird === "cases" ? loadThird :""}  type={"count"} dataHandle={"state_count"} pageType={"states"}/>
											</TabPane>	
										}
									</TabContent>
								</TabPane>
							</TabContent>
						</div>
					</div>
				</div>
			</OrganizationUsersProvider>
		</>
	);
};
export default ReportSales;
