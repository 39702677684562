import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import EmiLoanAccountSidebar from "../components/dashboard/EmiLoanAccountSidebar";
const LoanAccountDetailPage = () => {
	const params = useParams();
	return(
		<>
			<Helmet>
				<title>Loan Account Details</title>
				<meta name="description" content="Loan Account Details" />
			</Helmet>  
			<Navbar/>
			<EmiLoanAccountSidebar
			    type = {"fullwidth"}
				handleCloseSideBar={() => {return false;}}
				loanAccountId={{loan_account_enc_id: params.id}}
				reloadDetails={() => {return false;}}
			/>
		</>
	);
};

export default LoanAccountDetailPage;