import { BiChevronLeft } from "react-icons/bi";

const AppsFormDisplay = ({ show, hide, data }) => {
	return (
		<div className={`app-form-details ${show ? "app-form-details-show" : ""}`}>
			<button className="backForm" onClick={hide}>
				<BiChevronLeft size={25} /> BACK
			</button>

			<iframe
				id="JotFormIFrame-222552968019462"
				title="New Customer Registration Form"
				onLoad="window.parent.scrollTo(0,0)"
				allowFullScreen={true}
				allow="geolocation; microphone; camera"
				src={data.link}
				// src="https://form.jotform.com/222552968019462"
				frameBorder="0"
				style={{ minWidth: "100%", height: "93vh", border: "none" }}
				// scrolling="no"
			></iframe>
		</div>
	);
};
export default AppsFormDisplay;
