
import { Modal, ModalBody } from "react-bootstrap";
import QRCode from "react-qr-code";
import React, { useContext } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { MainContext } from "../context/MainState";
const QrCodeModal = ({ show, onHide }) => {

	const {user} = useContext(MainContext);
	const copyLink = window.location.origin + "/employee/" + user.username;
	return (
		<div>
			<Modal show={show} onHide={onHide} centered  size="sm" >
				<ModalBody>
					<h1 className="mb-2 text-center bold">QR Code</h1><span className="close-icon" onClick={onHide}><IoCloseCircle size={25}/></span>
					<div className="text-center">
						<div className="mb-1">
							<QRCode
								size={200}
								value={copyLink}
								viewBox={"0 0 0 0"}
								className="mb-1"
							/>
						</div>
					</div>
				</ModalBody>
				
			</Modal>
		</div>
	);
};

export default QrCodeModal;
