import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import EmiPendingCollectionTable from "../../components/dashboard/EmiPendingCollectionTable";

const EmiPendingCollection = () => {
	return (
		<>
			<Helmet>
				<title>EMI Collection</title>
			</Helmet>
			<Navbar />
			<EmiPendingCollectionTable />
		</>
	);
};

export default EmiPendingCollection;
