import React, { useContext, useEffect, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MainContext } from "../../context/MainState";
import DealerSideBar from "./DealerSideBar";
import { toast } from "react-toastify";
import axios from "axios";
import NameComp from "../NameComp";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

const DealersTable = ({tabActive}) => {
	const { user } = useContext(MainContext);
	const { getDealers, dealer, isLoading } = useContext(OrganizationUsersContext);
	// const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	const [dealerId, setDealerId] = useState("");
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [searchArray, setSearchArray] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const deleteDealer = async (elem) => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		
		let data = {
			assigned_dealer_enc_id: elem,
		};

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/remove-dealer`,
				data
			)
			
			.then(response => {	
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Dealer Deleted Successfully");
					getDealers();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const handleClose = () => {
		setShowSidebar(false);

		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};

	const showDealerSideBar = row => {
		setShowSidebar(true);
		setDealerId(row);
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};
	const columns = [
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			// name: "Name",
			selector: row => (row.name ? row.name : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<img src={row.logo} alt={row.name} className="emp-img" />
						<h6
							className="mb-0 text-capitalize text-link"
							onClick={() => showDealerSideBar(`${row.dealer_enc_id}`)}
						>
							{row.name}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Category"}
					id={"category"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			cell: row => {
				return <h6 className="mb-0 text-capitalize">{row.category}</h6>;
			},
			selector: row => row.category
		},
		{
			name: (
				<NameComp
					title={"Dealer Type"}
					id={"dealer_type"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return <h6 className="mb-0 text-capitalize">{row.dealer_type}</h6>;
			},
			selector: row => row.dealer_type
		},
		{
			name: (
				<NameComp
					title={"Contact Person"}
					id={"contact_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			cell: row => {
				return <h6 className="mb-0 text-capitalize">{row.contact_person ? row.contact_person : "-"}</h6>;
			},
			selector: row => row.contact_person
		},
		{
			name: (
				<NameComp
					title={"Phone"}
					id={"phone"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			cell: row => {
				return <h6 className="mb-0 text-capitalize">{row.phone ? row.phone : "-"}</h6>;
			},
			selector: row => row.phone
		},
		{
			name: (
				<NameComp
					title={"Email"}
					id={"email"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			cell: row => {
				return <h6 className="mb-0 emiAmt">{row.email ? row.email : "-"}</h6>;
			},
			selector: row => row.email
		},
		{
			name: (
				<NameComp
					title={"Username"}
					id={"username"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.username ? row.username : "-")
		},
		{
			name: "Delete",
			minWidth: "70px",
			style: { textAlign: "center" },
			omit: user.user_type !== "Financer" || user.specialAccessRole,
			cell: row => {
				return (
					<div>
						<RiDeleteBin6Fill
							className="cursor-point"
							size={16}
							color={"red"}
							onClick={() => deleteDealer(row.assigned_dealer_enc_id)}
						/>
					</div>
				);
			}
		}
	];

		  // ** Function to handle Pagination and get data
	const handlePagination = page => {
		getDealers({
			page: page.selected + 1,
			limit: rowsPerPage,
			field_search: searchArray,
		});
		setCurrentPage(page.selected + 1);
	};
	
	// ** Function to handle per page
	const handlePerPage = e => {
		getDealers({
			page: 1,
			limit: parseInt(e.target.value),
			field_search: searchArray,
		});
		setRowsPerPage(parseInt(e.target.value));
	};
	
	
	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(dealer?.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		if (dealer?.list && dealer?.list.length > 0) {
			return dealer.list;
		} else {
			return dealer?.list && dealer?.list.length > 0
				? dealer.list.slice(0, rowsPerPage)
				: [];
		}
	};

	useEffect(() => {
		if (tabActive === "4") {
			getDealers({
				page: 1,
				limit: rowsPerPage,
			});
		}
	}, [tabActive]);

	useEffect(() => {
		if (searchArray && Object.keys(searchArray).length) {
			getDealers({
				field_search: searchArray,
				page: 1,
				limit: rowsPerPage,
			});
			setCurrentPage(1);
		}
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray]);

	return (
		<>
			<div className="loan-application-table">
				<div className="mb-1 text-end" >
					
				</div>
				<Row className="mx-0 mt-1 mb-3">
					<Col sm="9">
						<div className="d-flex align-items-center justify-content-start mb-3">
							<label htmlFor="sort-select">Show</label>
							<select
								className="dataTable-select form-select loan_acc_select"
								style={{
									width: "65px",
									padding: "2px 8px",
									margin: "0px 10px"
								}}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
							<label htmlFor="sort-select">Results</label>
						</div>
					</Col>
					<Col
						className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
						sm="3"
					>
						<div className="reset-filters">
							<div className="filters-container d-flex justify-content-end">
								{showClearBtn ? (
									<button
										className="btn btn-primary btn-xs my-2"
										onClick={e => clearFilter(e)}
									>
								Reset
									</button>
								) : (
									""
								)}
							</div>
						</div>
					</Col>
				</Row>
				
				<DataTable
					columns={columns}
					data={dataToRender()}
					pagination
					paginationServer
					paginationComponent={CustomPagination}
					highlightOnHover="true"
					persistTableHead={true}
					className="fix-overflow myUser-table"
					progressPending={isLoading}
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={
						<p className="w-100 m-5 text-start">There are no records to display</p>
					}
				/>
			</div>

			<DealerSideBar
				showSideBar={showSidebar}
				handleClose={handleClose}
				dealerId={dealerId}
			/>
		</>
	);
};
export default DealersTable;
