import { Button, Modal } from "react-bootstrap";
import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { authServices } from "../helpers/authServices";
import { MainContext } from "../context/MainState";
import { useNavigate } from "react-router-dom";
import { FaMobileAlt } from "react-icons/fa";
import ForgotPassword from "./ForgotPassword";
import SignInWithPhone from "./SignInWithPhone";
import AuthCode from "react-auth-code-input";
import { toast } from "react-toastify";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { getLogo, isCredifinUser } from "../helpers/customFunctions";

const Login = ({ show, onHide, handleShow, withModal, redirect = true }) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const AuthInputRef = useRef(null);
	const { updateUser, signUpHandler, handlePageLoaded } = useContext(MainContext);
	const [loginType, setLoginType] = useState("Login");
	const [otpModal, setOtpModal] = useState(false);
	const [showSubmitOtpBtn, setShowSubmitOtpBtn] = useState(false);
	const [otp, setOtp] = useState("");
	const [formData, setFormData] = useState(null);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const history = useNavigate();
	const [forgotModal, setForgotModal] = useState(false);
	const showForgotModal = () => setForgotModal(true);
	const showForgotPassModal = () => {
		setForgotModal(true);
		if (withModal) {
			onHide();
		}
	};
	const handleClose = () => {
		setForgotModal(false);
	};
	const [showPassword, setShowPassword] = useState(false);
	const handleLogin = async data => {
		handlePageLoaded(true);
		await authServices.login(data, updateUser).then(data => {
			handlePageLoaded(false);
			if (data?.status === 200) {
				if (withModal) {
					onHide();
				}
				if(redirect){
					history("/account/dashboard");
				}
			} else if(data?.status === 308 && data.access_token && data.auto_login_token) {
				history(`/redirect/${data.access_token}/${data.auto_login_token}`);
			}
		});
		
	};
	const handleLoginType = value => {
		setLoginType(value);
	};
	// Validate OTP
	const ValidateOtp = (otpnumb = otp) => {
		if (otpnumb === null || !otpModal) return;
		authServices.validateOtp(otpModal, otpnumb)
			.then(response => {
				setFormIsLoading(false);
				if (response?.status === 200) {
					setOtpModal(false);
					updateUser(response.data);
					history("/account/dashboard");
					if (withModal) {
						onHide();
					}
					if(redirect){
						history("/account/dashboard");
					}
				} else if(response?.status === 308 && response.access_token && response.auto_login_token) {
					setOtpModal(false);
					history(`/redirect/${response.access_token}/${response.auto_login_token}`);
				}
			})
			.catch(err => {
				console.log(err);
				setShowSubmitOtpBtn(false);
				setFormIsLoading(false);
				toast.error("Invalid OTP");
			});
	};
	const handleOtpChange = res => {
		setOtp(res);
		if (res.length === 6) {
			ValidateOtp(res);
			setTimeout(() => {
				setShowSubmitOtpBtn(true);
			}, 1200);
		}
	};
	const signShow = () => {
		signUpHandler(true);
		if (withModal) {
			onHide();
		}
	};
	const handleSignIn = async phone => {
		setFormIsLoading(true);
		setFormData(phone);
		await authServices.sendOtp(phone)
			.then(result => {
				setFormIsLoading(false);
				if (result.status === 200) {
					setOtpModal(result.data.id);
				}
			})
			.catch(err => {
				toast.error(err);
				setFormIsLoading(false);
			});
	};

	const LoginForm = () => {
		return (
			<div className="login-page-modal">
				<div className="login-box">
					<div className="login-form-box">
						<div className="logo">
							<img src={getLogo()} alt={process.env.REACT_APP_NAME} />
						</div>
						{loginType === "Login" ? (
							<div className="login-form">
								<h1>Login</h1>
								<form onSubmit={handleSubmit(handleLogin)} className="form">
									<div className="upper-form">
										<div className="login-username mb-3">
											<input
												type="text"
												placeholder="Enter Your Username"
												className="form-control"
												{...register("username", {
													required: "This is required.",
													minLength: {
														value: 3,
														message:
															"Username must have at least 3 characters"
													}
												})}
											/>
											<ErrorMessage
												errors={errors}
												name="username"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
										<div className="login-password position-relative mb-3">
											<input
												type={showPassword ? "text" : "password"}
												placeholder="Enter Your Password"
												className="form-control"
												{...register("password", {
													required: "This is required.",
													minLength: {
														value: 8,
														message:
															"Password must have at least 8 characters"
													}
												})}
											/>
											{showPassword === false ? (
												<BsFillEyeSlashFill
													className="password-eye"
													onClick={() => setShowPassword(!showPassword)}
												/>
											) : (
												<BsFillEyeFill
													className="password-eye"
													onClick={() => setShowPassword(!showPassword)}
												/>
											)}
											<ErrorMessage
												errors={errors}
												name="password"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>
														{message}
													</p>
												)}
											/>
										</div>
										<div className="form-option">
											<div className="form-check">
												{/* <input className="form-check-input" type="checkbox" value=""*/}
												{/*       id="flexCheckChecked" defaultChecked={true}/>*/}
												{/* <label className="form-check-label" htmlFor="flexCheckChecked">Remember*/}
												{/*    me?</label>*/}
											</div>
											<span className="forgot">
												<button
													type="button"
													className="link-button"
													onClick={showForgotPassModal}
												>
													Forgot Password?
												</button>
											</span>
										</div>
									</div>
									<div className="login-buttons submit-form">
										<button type="submit" className="submit-btn btn">
											Login
										</button>
									</div>
									<span className="or">OR</span>
									<div className="login-buttons social-continue">
										<button
											className="social-submit btn-google btn"
											onClick={() => handleLoginType("SignInWithPhone")}
										>
											<FaMobileAlt /> Sign in with Mobile
										</button>
										{/* <button className="social-submit btn-google btn">*/}
										{/*    <img*/}
										{/*        src="https://user-images.githubusercontent.com/72601463/160390922-6add6204-c526-49e7-b2f8-701e560b5e08.png"/>Sign*/}
										{/*    in with Google*/}
										{/* </button>*/}
										{/* <button className="social-submit btn-facebook btn">*/}
										{/*    <img*/}
										{/*        src="https://user-images.githubusercontent.com/72601463/160391873-14e9c23b-065f-4343-af8c-9634c9dffa54.png"/>Sign*/}
										{/*    in with Facebook*/}
										{/* </button>*/}
									</div>
								</form>
							</div>
						) : (
							<SignInWithPhone
								handleLoginType={handleLoginType}
								handleSignIn={handleSignIn}
								formIsLoading={formIsLoading}
							/>
						)}
					</div>
					<div className="login-right-img">
						<div>
							<h1>{!isCredifinUser() ? "New Here ?" :"Welcome back!"}</h1>
							<p>{!isCredifinUser() ? "Sign up and discover new opportunities." :"Login for easier and faster access to your account."}</p>
							{!isCredifinUser() ? 
								<div className="signup-as">
									<div className="signup-as-btns mb-3">
										<button onClick={signShow}>Sign Up</button>
									</div>
								</div>
								: ""}
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{withModal ? (
				<Modal show={show} onHide={onHide} size={"lg"} centered>
					<LoginForm />
					<div id="recaptcha-container-login"></div>
				</Modal>
			) : (
				<div>
					<LoginForm />
					<div id="recaptcha-container-login"></div>
				</div>
			)}
			<Modal show={otpModal}>
				<Modal.Header>
					<Modal.Title>Verify Mobile Number</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Please enter the OTP received on number +91{formData}{" "}
					<span
						className="text-primary"
						style={{
							cursor: "pointer",
							fontSize: "13px"
						}}
						onClick={() => setOtpModal(false)}
					>
						Change Number
					</span>
					<AuthCode
						autoFocus={true}
						onChange={handleOtpChange}
						ref={AuthInputRef}
						placeholder="-"
						containerClassName="otp-container"
						inputClassName="otp-input-field"
					/>
				</Modal.Body>
				{showSubmitOtpBtn ? (
					<Modal.Footer>
						{formIsLoading ? (
							<button type="button" className="btn btn-primary" disabled={true}>
								<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
							</button>
						) : (
							<Button variant="primary" onClick={ValidateOtp}>
								Submit
							</Button>
						)}
					</Modal.Footer>
				) : (
					<div style={{ minHeight: "100px" }}></div>
				)}
			</Modal>
			<ForgotPassword
				show={forgotModal}
				onHide={handleClose}
				showLogin={handleShow}
				showForgot={showForgotModal}
			></ForgotPassword>
		</>
	);
};
export default Login;
