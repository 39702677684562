
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { BsTelephoneOutbound } from "react-icons/bs";
import { FaClock, FaEye, FaPlus, FaUserCheck } from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import PhoneListModal from "../../components/dashboard/PhoneListModal";

const RequestSidebar = ({ showSidebar, handleClose, sidebarDetails, emiAccountTableDetails, loading, sidebarFor, requestId, openSideBar, reloadDetails}) => {
	const [commentVal, setCommentVal] = useState("");
	const [allComments, setAllComments] = useState([]); 
	const [key, setKey] = useState("basic_details");
	const [phoneModal, setPhoneModal] = useState({status: false, type: ""});

	const saveComment = (e, type) => {
		if (e.keyCode === 13) {
			submitComment(e.target.value, requestId, type);
		}
	};
	const submitComment = (value, type) => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/save-repo-comments`,
				{"request_enc_id": requestId, "comment": value, "type": type }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setCommentVal("");
					getComments(requestId, sidebarFor);
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};
	const getComments = (loan_id, type) => {
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-repo-comments`, 
			{"request_enc_id": requestId, "type": type})
			.then(response => {
				if(response.data.response.status === 200){
					setAllComments(response.data.response.data);
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};
	const getSelectedTab = k => {
		if (k === "chat") {
			getComments(requestId, sidebarFor);
		}
	};

	const clearSidebarData = () => {
		setAllComments([]);
	};

	const hidePhoneModal = () => {
		setPhoneModal({status:false, type:""});
	};

	const reloadData = () => {
		openSideBar({loan_account_enc_id: sidebarDetails.loan_account_enc_id});
		reloadDetails();
	};

	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail ${showSidebar === false ? "expend-detail-close" : ""}`}>
				<div
					className="closeIcon"
					onClick={() => {
						handleClose();
						clearSidebarData();
					}}
				>
					<AiFillCloseCircle />
				</div>
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					{loading ? (
						<div className="cus-loader">
							<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
						</div>
					) : (
						<div className="expend-data expand-data-details">
							<Tabs onSelect={k => (setKey(k), getSelectedTab(k))} defaultActiveKey="basic_details">
								<Tab 	
									eventKey="basic_details"
									title="Basic Details"
									className="pt-4"
								>
									<div className="emis-details-tab p-5">
										<div className="row mb-1 mb-3 p-2">
											<div className="col-md-6 ht	35">
												<p className="d-flex flex-wrap mb-2 ht35">
													Loan Account Number:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.loan_account_number}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex mb-2 ht35">
													Name:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.name}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													Mobile Number:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														<a
															href={`tel:${sidebarDetails.phone && sidebarDetails.phone.length ? sidebarDetails.phone[0] : ""}`}
															className="text-center w-100"
														>
															<BsTelephoneOutbound size={17} />{" "}
															{sidebarDetails.phone && sidebarDetails.phone.length ? sidebarDetails.phone[0] : "-"}
														
														</a>
														{sidebarDetails.phone && sidebarDetails.phone.length > 0 ? (
															<button type="button" className="btn btn-primary btn-sm mb-0 mx-2 py-1 px-2"  style={{fontSize:"3px"}} 
																onClick={() => {setPhoneModal({status:true , type: ""});}}><FaEye style={{fontSize:"13px"}}  /></button>
														) : ""}
														<button type="button" className="btn btn-primary btn-sm mb-0 mx-2 py-1 px-2"  style={{fontSize:"3px"}} 
															onClick={() => {setPhoneModal({status:true , type: "add_new"});}}><FaPlus style={{fontSize:"13px"}}  /></button>
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													EMI Amount:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.emi_amount ? 
															<><BiRupee size={16} /> {parseFloat(sidebarDetails.emi_amount).toLocaleString("en-IN")}</>
															: "--"}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													Overdue EMI:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.overdue_amount ? (
															<>
																<BiRupee size={16} className="me-n1" />{" "}
																{parseFloat(
																	sidebarDetails.overdue_amount
																).toLocaleString("en-IN")}
															</>
														) : (
															"--"
														)}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													Ledger Amount:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.ledger_amount ? (
															<>
																<BiRupee size={16} className="me-n1" />{" "}
																{parseFloat(
																	sidebarDetails.ledger_amount
																).toLocaleString("en-IN")}
															</>
														) : (
															"--"
														)}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													Loan Type:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.loan_type ? sidebarDetails.loan_type : "--"}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													EMI Date:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{moment(sidebarDetails.emi_date).format("DD MMM YYYY")}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													Last Collection Received:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.last_emi_received_amount ? (
															<>
																<BiRupee size={16} className="me-n1" />{" "}
																{parseFloat(
																	sidebarDetails.last_emi_received_amount
																).toLocaleString("en-IN")}
															</>
														) : (
															"--"
														)}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap mb-2 ht35">
													Last Collection Date:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{moment(sidebarDetails.last_emi_received_date).format(
															"DD MMM YYYY"
														)}
													</span>
												</p>
											</div>
											<div className="col-md-6 ht35">
												<p className="d-flex flex-wrap ht35">
													Total EMI&#39;s Collected:{" "}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{sidebarDetails.total_emis ? sidebarDetails.total_emis : "--"}
													</span>
												</p>
											</div>
										</div>

										<hr />

										<div className="row mb-5 w-100 pb-3" style={{ overflowX: "scroll" }}>
											<table className="table">
												<thead>
													<tr>
														<th>Created On</th>
														<th>Collected By</th>
														<th>Amount</th>
														<th>Reference Number</th>
														<th>Payment Method</th>
														<th>Payment Status</th>
														<th>Delay Reason</th>
														<th>Ptp Amount</th>
														<th>Ptp Date</th>
													</tr>
												</thead>
												<tbody>
													{emiAccountTableDetails && emiAccountTableDetails.length ? (
														emiAccountTableDetails.map((value, index) => {
															return (
																<tr key={`emi-table-data${index}`}>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{moment(value.created_on).format(
																				"DD MMM YYYY h:mm a"
																			)}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value.collected_by}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			<BiRupee size={16} />
																			{parseFloat(
																				value.amount
																			).toLocaleString("en-IN")}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value.reference_number}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value.payment_method
																				? value.payment_method
																				: value.other_payment_method}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value.emi_payment_status}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value.delay_reason
																				? value.delay_reason
																				: "-"}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value.ptp_amount ? (
																				<>
																					<BiRupee size={16} />
																					{parseFloat(
																						value.ptp_amount
																					).toLocaleString("en-IN")}
																				</>
																			) : (
																				"-"
																			)}
																		</p>
																	</td>
																	<td>
																		<p className="mb-0 text-gray-900 fw-medium">
																			{value.ptp_date
																				? moment(value.ptp_date).format("DD MMM YYYY")
																				: "-"}
																		</p>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td colSpan="3">
																<div className="not-branch text-center">
																	No Data Found
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>

										{emiAccountTableDetails && emiAccountTableDetails.length ? (
											<>
												<hr />
												<div className="row mb-3">	
													<h3 className="mb-2">Attachments</h3>
													<div className="row">
														{emiAccountTableDetails.map((value, index) => {
															return (
																<>
																	{value.borrower_image ? (
																		<div
																			className="col-md-3"
																			key={`all-Images-${index}`}
																		>
																			<a
																				href={
																					value.borrower_image
																						? value.borrower_image
																						: ""
																				}
																				target="_blank"
																				rel="noreferrer"
																				className="documentView imagesView"
																			>
																				<img
																					src={value.borrower_image}
																					alt={value.collected_by}
																				/>
																				<hr className="w-100 my-2" />
																				<div className="uploaded-by">
																					<p className={"mb-2 m-0"}>
																						<FaUserCheck
																							fontSize={18}
																							color={"#00a0e3"}
																						/>{" "}
																						{value.collected_by}
																					</p>
																					<p className={"m-0"}>
																						<FaClock
																							fontSize={14}
																							color={"#00a0e3"}
																						/>{" "}
																						{moment(
																							value.created_on
																						).format(
																							"DD MMM YYYY h:mm a"
																						)}
																					</p>
																				</div>
																			</a>
																		</div>
																	) : (
																		""
																	)}
																	{value.pr_receipt_image ? (
																		<div
																			className="col-md-3"
																			key={`all-Images-${index}`}
																		>
																			<a
																				href={
																					value.pr_receipt_image
																						? value.pr_receipt_image
																						: ""
																				}
																				target="_blank"
																				rel="noreferrer"
																				className="documentView imagesView"
																			>
																				<img
																					src={value.pr_receipt_image}
																					alt={value.collected_by}
																				/>
																				<hr className="w-100 my-2" />
																				<div className="uploaded-by">
																					<p className={"mb-2 m-0"}>
																						<FaUserCheck
																							fontSize={18}
																							color={"#00a0e3"}
																						/>{" "}
																						{value.collected_by}
																					</p>
																					<p className={"m-0"}>
																						<FaClock
																							fontSize={14}
																							color={"#00a0e3"}
																						/>{" "}
																						{moment(
																							value.created_on
																						).format(
																							"DD MMM YYYY h:mm a"
																						)}
																					</p>
																				</div>
																			</a>
																		</div>
																	) : (
																		""
																	)}
																	{value.other_doc_image ? (
																		<div
																			className="col-md-3"
																			key={`allImages-${index}`}
																		>
																			<a
																				href={
																					value.other_doc_image
																						? value.other_doc_image
																						: ""
																				}
																				target="_blank"
																				rel="noreferrer"
																				className="documentView imagesView"
																			>
																				<img
																					src={value.other_doc_image}
																					alt={value.collected_by}
																				/>
																				<hr className="w-100 my-2" />
																				<div className="uploaded-by">
																					<p className={"mb-2 m-0"}>
																						<FaUserCheck
																							fontSize={18}
																							color={"#00a0e3"}
																						/>{" "}
																						{value.collected_by}
																					</p>
																					<p className={"m-0"}>
																						<FaClock
																							fontSize={14}
																							color={"#00a0e3"}
																						/>{" "}
																						{moment(
																							value.created_on
																						).format(
																							"DD MMM YYYY h:mm a"
																						)}
																					</p>
																				</div>
																			</a>
																		</div>
																	) : (
																		""
																	)}
																</>
															);
														})
														}
													</div>
												</div>
											</>
										) : (
											" "
										)}
									</div>

									<PhoneListModal 
										show={phoneModal.status} 
										hide={hidePhoneModal} 
										phoneData={sidebarDetails.phone}
										type={phoneModal.type}
										loanAccNumber={sidebarDetails.loan_account_enc_id}
										reload={reloadData}	
									/>
								</Tab>
								<Tab 
									eventKey="chat"
									title="Chat"
									className="pt-4"
								>
									{key === "chat" ? (
										<div className="row mb-5">
											<div className="panel-body mb20">
												<textarea className="form-control"	rows="2" value={commentVal}
													onKeyDown={e =>
														saveComment(e, sidebarFor)
													}
													onChange={e => setCommentVal(e.target.value)} placeholder="Add New Comment"
												></textarea>
												<button
													className="btn btn-primary btn-sm mt-2"
													onClick={() =>
														submitComment(
															commentVal,
															sidebarFor
														)
													}
												>
											Comment
												</button>
											</div>
											{allComments &&
											allComments.length
												? allComments.map(
													(value, index) => {
														return (
															<div
																className="media g-mb-30 media-comment mb20"
																key={`comment-${index}`}
															>
																<div className="media-body u-shadow-v18 g-bg-secondary g-pa-30">
																	<div className="g-mb-15 comment-main">
																		<h5 className="h5 g-color-gray-dark-v1 mb-0">
																			<span>
																				<img
																					src={
																						value.logo ||
																							value.user_image
																							? value.logo ||
																								value.user_image
																							: value.image
																					}
																					alt={
																						value.created_by
																					}
																					className="creator-img"
																				/>
																			</span>
																			{value.created_by}
																		</h5>
																		<span className="comment-date">
																			{moment(
																				value.created_on
																			).format(
																				"DD MMM YYYY h:mm A"
																			)}
																		</span>
																	</div>

																	<p>{value.comment}</p>
																</div>
															</div>
														);
													}
												)
												: ""}
										</div>
									) : ""}
								</Tab>
							</Tabs>
							
						</div>
					)}
				</PerfectScrollbar>
			</div>
		</>
	);
};

export default RequestSidebar;
