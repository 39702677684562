import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";

const CompanyDetails = ({ register, errors, isUser }) => {
	const [passwordValue, setPasswordValue] = useState("");
	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	return (
		<>
			{isUser ? (
				<>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="mb-3">
							<label htmlFor="basic-url" className="form-label mb-0">
								Username <span style={{ color: "red", fontSize: "13px" }}>*</span>
							</label>
							<div className="input-group mb-3">
								<span className="input-group-text" id="inputGroup-sizing-default">
									{window.location.origin}/
								</span>
								<input
									aria-label="Sizing example input"
									aria-describedby="inputGroup-sizing-default"
									placeholder="Username"
									className="form-control"
									{...register("username", {
										required: "This is required.",
										minLength: {
											value: 3,
											message: "Username must have at least 3 characters"
										}
									})}
								/>
							</div>
							<ErrorMessage
								errors={errors}
								name="username"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="password">
								Password <span style={{ color: "red", fontSize: "13px" }}>*</span>
							</label>
							<input
								id="password"
								name="password"
								type="password"
								placeholder="Password"
								className="form-control"
								{...register("password", {
									required: "This is required.",
									minLength: {
										value: 8,
										message: "Password must have at least 8 characters"
									}
								})}
								onChange={e => setPasswordValue(e.target.value)}
							/>
							<ErrorMessage
								errors={errors}
								name="password"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="cpassword">
								Confirm Password{" "}
								<span style={{ color: "red", fontSize: "13px" }}>*</span>
							</label>
							<input
								id="cpassword"
								name="cpassword"
								type="password"
								placeholder="Confirm Password"
								className="form-control"
								{...register("cpassword", {
									required: "This is required.",
									validate: value =>
										value === passwordValue || "The passwords do not match"
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="cpassword"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
				</>
			) : (
				""
			)}
			<div className="col-12">
				<div className="mb-3">
					<label className="form-label mb-0" htmlFor="organization_name">
						Organization Name {!isUser ? <span style={{ color: "red", fontSize: "13px" }}>*</span> : ""}
					</label>
					{isUser ? (
						<input
							placeholder="Organization Name"
							className="form-control"
							{...register("organization_name", {
								required: false
							})}
						/>
					) : (
						<input
							placeholder="Organization Name"
							className="form-control"
							{...register("organization_name", {
								required: "This is Required"
							})}
						/>
					)}
					<ErrorMessage
						errors={errors}
						name="organization_name"
						render={({ message }) => (
							<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
						)}
					/>
				</div>
			</div>
			{isUser ? (
				<>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="organization_email">
								Organization Email
							</label>
							<input
								placeholder="Organization Email"
								className="form-control"
								{...register("organization_email", {
									required: false,
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: "Email is invalid"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="organization_email"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="organization_website">
								Website
							</label>
							<input
								placeholder="Website"
								className="form-control"
								{...register("organization_website", {
									required: false
									// pattern: {
									//     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									//     message: "Email is invalid"
									// }
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="organization_website"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="last_name">
								Phone Number
							</label>
							<input
								type="tel"
								placeholder="Phone Number"
								className="form-control"
								{...register("organization_phone", {
									required: false,
									minLength: {
										value: 10,
										message: "Mobile number Should be at least 10 numbers"
									},
									maxLength: {
										value: 10,
										message: "Mobile number maximum be at least 10 numbers"
									},
									pattern: {
										value: phoneRegExp,
										message: "Invalid Phone number"
									}
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="organization_phone"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					</div>
				</>
			) : (
				""
			)}
		</>
	);
};
export default CompanyDetails;
