import React, { useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { IoDocumentLockOutline } from "react-icons/io5";

const RegistryCollected = ({ loanDetails, reload }) => {
	const { handlePageLoaded } = useContext(MainContext);
	const initiateTVR = () => {
		if (!window.confirm("Are you sure you want to change the status?")) {
			return false;
		}
		handlePageLoaded(true);
		let data = {
			loan_app_enc_id: loanDetails.loan_app_enc_id,
			type: "registry"
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/update-loan`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Successfully");
					reload(loanDetails.loan_app_enc_id, "1");
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "Release Payment Initiated Error"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	return (
		<>
			{loanDetails.registry_status === "1" ? (
				<button type="button" className="btn btn-success">
					<IoDocumentLockOutline size={17} /> Registry Collected
				</button>
			) : (
				<button type="button" className="btn btn-primary btn-sm-sidebar" onClick={() => initiateTVR(0)}>
					<IoDocumentLockOutline size={17} /> Collect Registry
				</button>
			)}
		</>
	);
};

export default RegistryCollected;
