const ProComponentDashboard = () => {
	return (
		<div className="col-12">
			<div className="Become-pro-dashboard nd-shadow p-4 bg-white mb-5">
				<div className="text-box-pro">
					<h1>Benefits of Pro Member</h1>
					<h1>Benefits of Pro Member</h1>
				</div>
				<div className="row justify-content-center">
					<div className="col-12 col-sm-6 col-md-6 col-lg-4">
						<div className="box">
							<div className="pro-dash-img">
								<img src="/images/pages/pro-membership/education.jpg" alt="" />
							</div>
							<div className="pro-dashboard-data">
								<h3>Education</h3>
								<p>Upto 66% off on Classes and other Language Courses</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-md-6 col-lg-4">
						<div className="box">
							<div className="pro-dash-img">
								<img src="/images/pages/pro-membership/hotels.jpg" alt="" />
							</div>
							<div className="pro-dashboard-data">
								<h3>Hotels</h3>
								<p>Upto 10% off on food & Hotel Rooms</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-md-6 col-lg-4">
						<div className="box">
							<div className="pro-dash-img">
								<img src="/images/pages/pro-membership/food.jpg" alt="" />
							</div>
							<div className="pro-dashboard-data">
								<h3>Food</h3>
								<p>Upto 5 % to 15% discounts on food or more</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-md-6 col-lg-4">
						<div className="box">
							<div className="pro-dash-img">
								<img
									src="/images/pages/pro-membership/backgrounds/super99-bg.jpg"
									alt=""
								/>
							</div>
							<div className="pro-dashboard-data">
								<h3>Stores</h3>
								<p>Upto 30% off & Freebies on Shopping</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-md-6 col-lg-4">
						<div className="box">
							<div className="pro-dash-img">
								<img
									src="/images/pages/pro-membership/backgrounds/himanshi.jpg"
									alt=""
								/>
							</div>
							<div className="pro-dashboard-data">
								<h3>Clothing</h3>
								<p>Upto 30% off & Freebies on Shopping</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ProComponentDashboard;
