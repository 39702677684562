const AppReducer = (state, action) => {
	switch (action.type) {
	case "SET_USER_DATA":
		return {
			...state,
			user: action.payload
		};
	case "REMOVE_USER_DATA":
		return {
			...state,
			user: ""
		};
	case "SET_BRANDS_DATA":
		return {
			...state,
			brands: action.payload
		};
	case "SET_LOAN_TYPES":
		return {
			...state,
			loan_types: action.payload
		};
	case "SET_LOAN_PRODUCT":
		return {
			...state,
			loan_products: action.payload
		};
	case "SET_DOCUMENT_TYPES":
		return {
			...state,
			documents_types: action.payload
		};
	case "SET_IMAGE_TYPES":
		return {
			...state,
			images_types: action.payload
		};
	case "SET_PURPOSES_LIST":
		return {
			...state,
			purposes: action.payload
		};
	case "SET_STATUS_LIST":
		return {
			...state,
			loan_status: action.payload
		};
	case "SET_ALL_FINANCERS":
		return {
			...state,
			all_financers: action.payload
		};
	case "SET_FINANCER_STATUS_LIST":
		return {
			...state,
			financer_loan_status: action.payload
		};
	case "SET_NOTIFICATION_LIST": {
		const notifications = state.notifications ? state.notifications : [];
		return {
			...state,
			notifications: [...notifications, ...action.payload.notification],
			notificationCount: action.payload.count
		};
	}
	case "SET_LOAN_ACCOUNT_TYPES":
		return {
			...state,
			loanAccountTypes: action.payload
		};
	case "SET_GENERAL_NOTIFICATION":
		return {
			...state,
			notifications: { ...state.notifications, general: action.payload.notification, count: action.payload.count},
		};
	case "SET_UPLOADS_NOTIFICATION":
		return {
			...state,
			notifications: { ...state.notifications, uploads: action.payload.notification, count: action.payload.count},
		};
	case "SET_CHATS_NOTIFICATION":
		return {
			...state,
			notifications: { ...state.notifications, chats: action.payload.notification, count: action.payload.count},
		};
	case "SET_FIN_ASSIGNED_LOAN_TYPES":
		return {
			...state,
			financer_assigned_loan_types: action.payload
		};
	default:
		return state;
	}
};
export default AppReducer;
