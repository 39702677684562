import EmiCollectionForm from "./EmiCollectionForm";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import {Col, Modal, Row } from "react-bootstrap";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import { BiRupee, BiTrash } from "react-icons/bi";
import axios from "axios";
import moment from "moment";
import { MainContext } from "../../context/MainState";
import NameComp from "../NameComp";
import { FaRegEdit } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Datepicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import EditTypehead from "../EditTypehead";
import UpdateEmiPaymentMethod from "../forms/UpdateEmiPaymentMethod";
import CollectionManagerModal from "./CollectionManagerModal";
import UpdateEmiFields from "../forms/UpdateEmiFields";
import EmiCollectionStats from "./EmiCollectionStats";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import MinMaxFilter from "../MinMaxFilter";
import { readOnlyUsers } from "../../helpers/authServices";

const EmiCollectionTable = ({
	headingName = "",
	cases = "all",
	paymentMethod = "all",
	stats = false
}) => {
	const {
		collection,
		branches_list,
		states_list,
		getEmiCollection,
		designationList,
		departmentList,
		cleanEmiStorage
	} = useContext(OrganizationDashboardContext);
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors }
	} = useForm();
	const { user, handlePageLoaded, loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [documentId, setDocumentId] = useState(false);
	const [vals, setVals] = useState({ latitude: "" });
	const [editPaymentMethod, setEditPaymentMethod] = useState({ emi_collection_enc_id: "" });
	const [editEmiFields, setEditEmiFields] = useState({ emi_collection_enc_id: "" });
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [searchArray, setSearchArray] = useState({});
	const [sortArr, setSortArr] = useState({});
	const [amountModal, setAmountModal] = useState(false);
	const [documentImage, setDocumentImage] = useState("");
	const [tableLoading, setTableLoading] = useState(false);
	const [loanAccountId, showLoanSideBar] = useState({loan_account_enc_id: "", loan_account_number: ""});
	const [reloadSidebar, setReloadSidebar] = useState(false);
	const handleClose = () => {
		setShowEmiModal(false);
	};
	const [selectedVal, setSelectedVal] = useState("~");
	const [sharedModal, setSharedModal] = useState(false);

	const numberValidateRegex = /^[0-9]\d*$/;
	const location = () => {
		if ("geolocation" in navigator) {
			setShowEmiModal(true);
			navigator.geolocation.getCurrentPosition(success);
		} else {
			navigator.geolocation.getCurrentPosition(locerrors);
		}
	};
	

	const success = async pos => {
		var crd = pos.coords;
		Geocode.fromLatLng(crd.latitude, crd.longitude).then(
			response => {
				let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
				for (let i = 0; i < response.results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
						case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
						case "postal_code":
							postalCode = response.results[0].address_components[i].long_name;
							break;
						case "premise":
							route = response.results[0].address_components[i].long_name;
							break;
						case "sublocality":
							sublocality = response.results[0].address_components[i].long_name;
							break;
						case "sublocality_level_2":
							sublocality_2 = response.results[0].address_components[i].long_name;
						}
					}
				}
				if (route && sublocality_2 && sublocality) {
					streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
				} else if (route && sublocality) {
					streetAdd = route + ", " + sublocality;
				} else {
					streetAdd = route;
				}
				setVals({
					latitude: crd.latitude,
					longitude: crd.longitude,
					postalCode,
					streetAdd,
					city,
					state
				});
			},
			error => {
				console.error(error);
			}
		);
	};

	const locerrors = err => {
		toast.error("Please enable location to continue");
		alert(`ERROR(${err.code}): ${err.message}`);
	};
	const fetchmylocation = () => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				if (result.state === "granted") {
					location();
					// If granted then you can directly call your function here
				} else if (result.state === "prompt") {
					location();
				} else if (result.state === "denied") {
					toast.error("Please enable location to continue");
					// If denied then you have to show instructions to enable location
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			// alert("Sorry Not available!");
			toast.error("Please enable location to continue");
		}
	};

	const statusColorObj = {
		paid: "success",
		pending: "warning",
		rejected: "danger",
		failed: "danger",
		pipeline: "secondary",
		"not paid": "danger",
		collected: "secondary"
	};

	const reloadDetails = () => {
		setReloadSidebar(true);
		setTableLoading(cases);
		if (cases !== "all") {
			getEmiCollection({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
				custom_status: cases,
				pageType: cases,
				custom_method: paymentMethod !== "all" ? paymentMethod : ""
			});
		} else {
			getEmiCollection({
				page: currentPage,
				limit: rowsPerPage,
				pageType: cases,
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
	};

	const handleEmiDelete = id => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/delete-emi`,
				{ emi_collection_enc_id: id }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Application Successfully Deleted");
					reloadDetails();
				} else {
					toast.error("An error has occurred, Please try again later");
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const handleEditEmi = (row, type) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (
			(user.user_type === "Financer" ||
				(["KKB", "phf604", "phf403"].includes(user.username))) &&
			row.emi_payment_mode !== "Pay Now" &&
			row.emi_payment_status !== "paid"
		) {
			setAmountModal({ id: row.emi_collection_enc_id, type, status: row.emi_payment_status });
		}
	};

	const uploadDocument = () => {
		const formData = new FormData();
		if (!documentImage) {
			toast.error("Please Choose Document");
			return false;
		}
		handlePageLoaded(true);
		formData.append("pr_receipt_image", documentImage);
		formData.append("emi_collection_enc_id", documentId);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/upload-receipt`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Receipt Uploaded Successfully");
					setDocumentId("");
					setDocumentImage("");
					setTableLoading(cases);
					getEmiCollection({
						page: currentPage,
						limit: rowsPerPage,
						pageType: cases,
						fields_search: searchArray,
						fields_sort: sortArr,
					});
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "An error occurred while uploading"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	const conditionalRowStyles = [
		{
			when: row => !row.loan_account_enc_id,
			style: { backgroundColor: "rgb(255 176 176 / 42%)" },
		},
	];

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{(user.user_type === "Financer" ||
							(["KKB", "phf604", "phf403"].includes(user.username))) &&
							row.emi_payment_status !== "paid" ? (
								<EditTypehead
									getData={reloadDetails}
									api={"emi-collections/update-emi-number"}
									defaultValue={
										row.loan_account_number ? row.loan_account_number : ""
									}
									parentId={row.loan_account_enc_id}
									colName={row.emi_collection_enc_id}
									placeHolder="Search Loan Number"
									hideEditButton={true}
									searchUrl="organizations/search-emi-accounts?loan_number="
									type="loan_account_number"
								/>
							) : (
								<h6
									className="mb-0 text-capitalize text-link"
									onClick={() => (showLoanSideBar({loan_account_enc_id: row.loan_account_enc_id , loan_account_number: row.loan_account_number }))}
								>
									{row.loan_account_number ? row.loan_account_number : "-"}
								</h6>
							)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Customer Name"}
					id={"customer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.customer_name ? row.customer_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => (showLoanSideBar({loan_account_enc_id: row.loan_account_enc_id , loan_account_number: row.loan_account_number }))}
					>
						{row.customer_name}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "240px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Bucket"}
					id={"sub_bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			maxWidth: "155px",
			selector: row => (row.sub_bucket ? <b>{row.sub_bucket === "0" ? "X" : row.sub_bucket}</b> : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Current Bucket"}
					id={"current_sub_bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			maxWidth: "155px",
			selector: row => (row.current_sub_bucket ? <b>{row.current_sub_bucket}</b> : "-"),
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"Collection Date"}
					id={"collection_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => (row.collection_date ? moment(row.collection_date).valueOf() : 0),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 emiAmt cursor-point"
						onClick={() => handleEditEmi(row, "date")}
					>
						{row.collection_date
							? moment(row.collection_date).format("DD MMM YYYY")
							: "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Transaction Initiated"}
					id={"amount"}
					placeholder={"Amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.amount ? row.amount : "-"),
			sortable: false,
			minWidth: "180px",
			conditionalCellStyles: [
				{
					when: row => row.loan_account_enc_id,
					style: { background: "#ff5d5d", fontWeight: "700"},
				},
				{
					when: row => row.emi_amount && parseFloat(row.amount) >= parseFloat(row.emi_amount),
					style: { background: "#fdfd75", fontWeight: "700"},
				},
				{
					when: row => row.target_collection_amount && parseFloat(row.amount) >= parseFloat(row.target_collection_amount),
					style: {background: "#64de6b", fontWeight: "700"},
				},
				{
					when: row => ["pending", "rejected", "failed"].includes(row.emi_payment_status),
					style: {background: "#d4d4d4", fontWeight: "700"},
				}
			],
			cell: row => {
				return (
					<h6
						className="mb-0 emiAmt cursor-point"
						onClick={() => handleEditEmi(row, "amount")}
					>
						<BiRupee size={16} /> {parseFloat(row.amount).toLocaleString("en-IN")}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Target Collection Amount"}
					id={"target_collection_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					placeholder={"Amount"}
				/>
			),
			selector: row => (row.target_collection_amount ? row.target_collection_amount : "-"),
			sortable: false,
			minWidth: "180px",
			cell: row => {
				return (
					<h6
						className="mb-0 emiAmt cursor-point">
						<BiRupee size={16} /> {row.target_collection_amount ? parseFloat(row.target_collection_amount).toLocaleString("en-IN") : "0"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Net Outstanding Amount"}
					id={"total_pending_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					placeholder={"Amount"}
				/>
			),
			selector: row => (row.total_pending_amount ? row.total_pending_amount : "-"),
			sortable: false,
			minWidth: "180px",
			cell: row => {
				return (
					<h6
						className="mb-0 emiAmt cursor-point">
						<BiRupee size={16} /> {row.total_pending_amount ? parseFloat(row.total_pending_amount).toLocaleString("en-IN"): "0"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Incentive Points"}
					id={"incentive_points"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "100px",
			selector: row => (row.incentive_points ? row.incentive_points : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"EMI Date"}
					id={"emi_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => row.emi_date ? moment(row.emi_date).valueOf() : "",
			sortable: false,
			cell: row => row.emi_date ? moment(row.emi_date).format("DD") : "-"
		},
		{
			name: (
				<MinMaxFilter
					title={"EMI Amount"}
					id={"emi_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "180px",
			selector: row => (row.emi_amount ? row.emi_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.emi_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.emi_amount).toLocaleString("en-IN")}
							</h6>
						) : ""}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "220px"
		},
		{
			name: (
				<NameComp
					title={"Collected By"}
					id={"collected_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "150px",
			selector: row => (row.collected_by ? row.collected_by : "-")
		},
		{
			name: (
				<NameComp
					title={"Mode of Payment"}
					id={"emi_payment_mode"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			minWidth: "150px",
			cell: row => {
				return <div>
					{row.emi_payment_mode}&nbsp;
					{(user.user_type === "Financer" ||
						user.username === "KKB" ||
						user.username === "phf604") && (row.emi_payment_mode === "Pay Now" || row.emi_payment_mode === "Online Off System Transaction") && row.emi_payment_status !== "paid" ? (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer"
								}}
								size={14}
								onClick={() => {
									setEditPaymentMethod(row);
								}}
							/>
						) : ""}
				</div>;
			}
		},
		{
			name:
				paymentMethod === "all" ? (
					<NameComp
						title={"Payment Method"}
						id={"emi_payment_method_list"}
						searchArray={searchArray}
						setSearchArray={setSearchArray}
						clearFilters={clearFilters}
					/>
				) : (
					"Payment Method"
				),
			selector: row => (row.emi_payment_method ? row.emi_payment_method : "-"),
			sortable: false,
			minWidth: "195px",
			cell: row => {
				return <div>{row.emi_payment_method}&nbsp;
					{(user.user_type === "Financer" ||
						user.username === "KKB" ||
						user.username === "phf604") && (row.emi_payment_mode === "Pay Now" || row.emi_payment_mode === "Online Off System Transaction") && row.emi_payment_status !== "paid" ? (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer"
								}}
								size={14}
								onClick={() => {
									setEditPaymentMethod(row);
								}}
							/>
						) : ""}
				</div>;
			}
		},
		{
			name:
				cases !== "pipeline" && cases !== "pending" ? (
					<NameComp
						title={"Payment Status"}
						id={"emi_payment_status_list"}
						searchArray={searchArray}
						setSearchArray={setSearchArray}
						clearFilters={clearFilters}
					/>
				) : (
					"Payment Status"
				),
			sortable: false,
			minWidth: "200px",
			cell: row => {
				const paymentStatus =
					row.emi_payment_status && row.emi_payment_status.length
						? row.emi_payment_status
						: "-";

				return paymentStatus === "-" ? (
					paymentStatus
				) : (
					<div
						className={`text-capitalize mx-2 badge rounded-pill bg-${statusColorObj[paymentStatus]}`}
						onClick={() => handleEditEmi(row, "status")}
					>
						{paymentStatus}
					</div>
				);
			}
		},
		{
			name: "PR Receipt",
			sortable: false,
			minWidth: "150px",
			cell: row => {
				return <div>
					{row.emi_payment_status !== "pending" && row.receipt_number ? (
						<a href={`/pr-receipt/${row.emi_collection_enc_id}`} target="_blank" rel="noreferrer">
							{row.receipt_number}</a>
					) : "-"}
				</div>;
			}
		},
		{
			name: (
				<NameComp
					title={"Case Number"}
					id={"case_no"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
				/>
			),
			sortable: false,
			omit: user.username !== "KKB",
			minWidth: "150px",
			cell: row => {
				return <div>
					{row.case_no}&nbsp;
					{user.username === "KKB" ? (
						<FaRegEdit
							style={{
								color: "rgb(41, 121, 255)",
								cursor: "pointer"
							}}
							size={14}
							onClick={() => {
								setEditEmiFields(row);
							}}
						/>
					) : ""}
				</div>;
			}
		},
		{
			name: (
				<NameComp
					title={"Company ID"}
					id={"company_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
				/>
			),
			sortable: false,
			omit: user.username !== "KKB",
			minWidth: "150px",
			cell: row => {
				return <div>
					{row.company_id}&nbsp;
					{user.username === "KKB" ? (
						<FaRegEdit
							style={{
								color: "rgb(41, 121, 255)",
								cursor: "pointer"
							}}
							size={14}
							onClick={() => {
								setEditEmiFields(row);
							}}
						/>
					) : ""}
				</div>;
			}
		},
		{
			name: (
				<NameComp
					title={"Reference Number"}
					id={"reference_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "240px",
			selector: row => (row.reference_number ? row.reference_number : "-"),
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"PTP Amount"}
					id={"ptp_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					placeholder={"Amount"}
				/>
			),
			selector: row => (row.ptp_amount ? row.ptp_amount : "-"),
			minWidth: "160px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.ptp_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.ptp_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"PTP Date"}
					id={"proposed_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					inputType={"datepicker"}
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "170px",
			selector: row => row.ptp_date ? moment(row.ptp_date).valueOf() : "-",
			sortable: false,
			cell: row => row.ptp_date ? moment(row.ptp_date).format("DD MMM YYYY") : "-"
		},
		{
			name: (
				<NameComp
					title={"Dealer Name"}
					id={"dealer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<>
						{row.emi_payment_method === "Paid To Dealer"
							? row.dealer_name ?? row.collected_by
							: "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Updated On"}
					id={"updated_on"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => (row.updated_on ? moment(row.updated_on).format("DD MMM YYYY") : "-"),
			omit: user.username !== "KKB",
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Updated By"}
					id={"updated_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			omit: user.username !== "KKB",
			minWidth: "150px",
			selector: row => (row.updated_by ? row.updated_by : "-")
		},
		{
			name: (
				<NameComp
					title={"Department"}
					id={"department"}
					departmentList={departmentList}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.department ? row.department : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					designations={designationList}
				/>
			),
			minWidth: "200px",
			selector: row => (row.designation ? row.designation : "-"),
			sortable: false
		},
		{
			name: "Delete",
			minWidth: "70px",
			style: { textAlign: "center" },
			omit: user.user_type !== "Financer",
			cell: row => {
				return (
					<div className="d-flex justify-content-center w-100">
						<button
							type="button"
							className="link-button"
							onClick={() => handleEmiDelete(row.emi_collection_enc_id)}
						>
							<BiTrash size={18} color="red" />
						</button>
					</div>
				);
			}
		}
	];

	useEffect(() => {
		if ((searchArray && Object.keys(searchArray).length) ) {
			const timerId = setTimeout(() => {
				setCurrentPage(1);
				setTableLoading(cases);
				getEmiCollection({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					fields_sort: sortArr,
					custom_status: cases,
					pageType: cases,
					custom_method: paymentMethod !== "all" ? paymentMethod : ""
				});
			}, [1000]);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}
			
			return () => {
				clearTimeout(timerId);
			};
		}
		
	}, [searchArray]);
	
	useEffect(() => {
		if (Object.keys(sortArr).length) {
			setCurrentPage(1);
			setTableLoading(cases);
			getEmiCollection({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
				custom_status: cases,
				pageType: cases,
				custom_method: paymentMethod !== "all" ? paymentMethod : ""
			});
		}
	}, [sortArr]);

	useEffect(() => {
		if (collection && collection[cases]) {
			setTableLoading(false);
		}
	}, [collection]);

	useEffect(() => {
		if (cases !== "all" && cases !== "pipeline" && cases !== "pending") {
			setTableLoading(cases);
			getEmiCollection({
				page: currentPage,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
				custom_status: cases,
				pageType: cases,
				custom_method: paymentMethod !== "all" ? paymentMethod : ""
			});
		} else if(stats) {
			setTableLoading(cases);
			getEmiCollection({
				page: currentPage,
				limit: rowsPerPage,
				pageType: cases,
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
		document.getElementsByTagName("body")[0].classList.add("bg-white");
		return () => {
			cleanEmiStorage();
			document.getElementsByTagName("body")[0].classList.remove("bg-white");
		};
	}, []);

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		setTableLoading(cases);
		if (cases !== "all") {
			getEmiCollection({
				page: page.selected + 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
				custom_status: cases,
				pageType: cases,
				custom_method: paymentMethod !== "all" ? paymentMethod : ""
			});
		} else {
			getEmiCollection({
				page: page.selected + 1,
				limit: rowsPerPage,
				pageType: cases,
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setTableLoading(cases);
		if (cases !== "all") {
			getEmiCollection({
				page: 1,
				limit: parseInt(e.target.value),
				fields_search: searchArray,
				fields_sort: sortArr,
				custom_status: cases,
				pageType: cases,
				custom_method: paymentMethod !== "all" ? paymentMethod : ""
			});
		} else {
			getEmiCollection({
				page: 1,
				limit: parseInt(e.target.value),
				pageType: cases,
				fields_search: searchArray,
				fields_sort: sortArr,
			});
		}
		setCurrentPage(1);
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(collection[cases].count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const filters = {
			q: ""
		};

		const isFiltered = Object.keys(filters).some(function (k) {
			return filters[k].length > 0;
		});

		if (collection && collection[cases] && collection[cases].data) {
			if (collection && collection[cases].data.length > 0) {
				return collection[cases].data;
			} else if (collection && collection[cases].data.length === 0 && isFiltered) {
				return [];
			} else {
				return collection && collection[cases].data.length > 0
					? collection[cases].data.slice(0, rowsPerPage)
					: [];
			}
		}
	};

	const updateAmount = async values => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let formData = new FormData();
		if (!values.amount && !values.status && amountModal.type !== "date") {
			toast.error("Please fill all required fields");
			return false;
		}
		if (amountModal.type === "date" && (selectedVal === "~" || selectedVal === null)) {
			toast.error("Please select Date");
			return false;
		}
		if (amountModal.type === "date") {
			delete values.amount;
			formData.append("collection_date", moment(selectedVal).format("YYYY-MM-DD"));
		}
		Object.entries(values).map(([key, value]) => {
			formData.append(key, value);
		});
		handlePageLoaded(true);
		formData.append("emi_id", amountModal.id);
		let api = "update";
		if (amountModal.type === "status") {
			api = "emi-collection";
			formData.append("emi_collection_enc_id", amountModal.id);
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/${api}`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Update Successfully");
					resetFormData();
					reloadDetails();
				} else {
					toast.error(
						response.data?.response?.error
							? response.data.response.error.toString()
							: "An error occurred"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occurred: " + error?.message);
			});
	};

	const resetFormData = () => {
		reset({
			amount: "",
			remarks: ""
		});
		setSelectedVal("~");
		setAmountModal(false);
	};

	const handleClick = () => {
		fetchmylocation();
	};
	return (
		<>
			{stats ? (
				<div className="container-fluid">
					<EmiCollectionStats loan_types={loanAccountTypes}/>
				</div>
			) : ""}
			<div className="dashboard-dsa py-5">
				<div className="container-fluid">
					<div className="shadow-lg rounded-3 py-6 px-6 px-xs-1-5 py-xs-1-5">
						<div className="loan-application-table">
							<Row className="mx-0 mt-1 mb-50 align-items-center">
								<Col sm="6">
								 <div className="d-flex align-items-center p-0" >
										<h2 className="m-0">{headingName ? headingName : "EMI Collection"}</h2>
									</div>
								</Col>
							
								
								{ tableLoading || (collection && collection[cases]) ? 
									<>
										<Col sm="6">
											<div className="d-flex align-items-center justify-content-end mb-3">
												<label htmlFor="sort-select">Show</label>
												<select
													className="dataTable-select form-select"
													style={{
														width: "65px",
														padding: "2px 8px",
														margin: "0px 10px"
													}}
													id="sort-select"
													value={rowsPerPage}
													onChange={e => handlePerPage(e)}
												>
													<option value={10}>10</option>
													<option value={25}>25</option>
													<option value={50}>50</option>
													<option value={75}>75</option>
													<option value={100}>100</option>
												</select>
												<label htmlFor="sort-select">Results</label>
											</div>
										</Col>
									</> :
									(<Col sm="6">
										<div className="d-flex align-items-center justify-content-end mb-3">
											<button
												type="button"
												className="btn btn-primary btn-sm txt-btn-sm-w mb-0"
												onClick={() => reloadDetails()}
											>
									
										Click here to load
											</button> 
										</div>
									</Col>)
									
								}
								<Col sm={"12"}>
									<div className="Drop-location-btn text-end d-flex align-items-center justify-content-end">
										<button
											type="button"
											className="btn btn-primary m-0 mx-2 d-lg-none"
											onClick={() => handleClick()}
										>
							          Collect EMI
										</button>
									</div>
									{showClearBtn ? (
										<div className="d-flex align-items-center justify-content-end">
											<button
												className="btn btn-primary btn-xs txt-btn-sm-w"
												onClick={e => clearFilter(e)}
											>
												Reset
											</button>
										</div>
									) : (
										""
									)}
								</Col>
							</Row>
							
							
							<div className="row">
								<div className="col-lg-12">
									<DataTable
										noHeader
										pagination
										paginationServer
										columns={columns}
										data={collection && collection[cases] ? dataToRender() : []} 
										paginationComponent={CustomPagination}
										progressPending={tableLoading === cases}
										highlightOnHover="true"
										persistTableHead={collection && collection[cases] ? true : false}
										progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
										noDataComponent={collection && collection[cases] ?
											<p className="w-100 m-5 text-start">
												There are no records to display
											</p> : 
											 ""
										}
										conditionalRowStyles={conditionalRowStyles}
										className={`fix-overflow ${collection && collection[cases] ? "mt-4 after-load-data" : ""}`}
									/>
								</div>
							</div>
							<EmiCollectionForm
								show={showEmiModal}
								hide={handleClose}
								geoLoc={vals}
								reloadData={reloadDetails}
							/>

							<EmiLoanAccountSidebar
								handleCloseSideBar={() => showLoanSideBar("")}
								loanAccountId={loanAccountId}
								reloadDetails={reloadDetails}
								reloadSidebar={reloadSidebar}
							/>

							<CollectionManagerModal
								sharedModal={sharedModal}
								setSharedModal={setSharedModal}
								reloadDetails={reloadDetails}
							/>
							
							<Modal show={amountModal.id} onHide={() => resetFormData()} size={"md"} centered>
								<Modal.Body>
									<form className="form" onSubmit={handleSubmit(updateAmount)}>
										<div className="row">
											<h3 className="text-center mb-3 text-capitalize">
									Update {amountModal.type ? amountModal.type : ""}
											</h3>
											<div className="col-12 mb-3">
												{amountModal.type === "amount" ? (
													<>
														<label
															className="form-label mb-0"
															htmlFor="date_of_transfer"
														>
												Enter Amount<span style={{ color: "red" }}>*</span>
														</label>
														<div>
															<input
																type="number"
																min={0}
																placeholder="Collection Amount"
																className="form-control"
																{...register("amount", {
																	required: false,
																	maxLength: {
																		value: 8,
																		message: "Collection Amount is to High"
																	},
																	pattern: {
																		value: numberValidateRegex,
																		message: "Collection Amount is invalid"
																	}
																})}
															/>
														</div>
														<ErrorMessage
															errors={errors}
															name="amount"
															render={({ message }) => (
																<p style={{ color: "red", fontSize: "13px" }}>
																	{message}
																</p>
															)}
														/>
													</>
												) : (
													""
												)}
												{amountModal.type === "date" ? (
													<>
														<label
															className="form-label mb-0"
															htmlFor="date_of_transfer"
														>
												Choose Date<span style={{ color: "red" }}>*</span>
														</label>
														<div>
															<Datepicker
																selected={
																	selectedVal !== "~" ? selectedVal : null
																}
																onChange={date => setSelectedVal(date)}
																showMonthDropdown
																showYearDropdown
																dropdownMode="select"
																className={"form-control w-100"}
																maxDate={new Date()}
																isClearable={true}
																dateFormat="dd MMM yyyy"
																placeholderText="Choose Collection Date"
															/>
														</div>
													</>
												) : (
													""
												)}
												{amountModal.type === "status" ? (
													<>
														<label
															className="form-label mb-0"
															htmlFor="date_of_transfer"
														>
												Choose Status<span style={{ color: "red" }}>*</span>
														</label>
														<div>
															<select className="form-control" {...register("status")}>
																<option value="">Select Status</option>
																{/* <option value="pending">Pending</option> */}
																<option value="collected">Collected</option>
																<option value="pipeline">Pipeline</option>
																{/* <option value="failed">Failed</option> */}
																<option value="rejected">Rejected</option>
																{/* <option value="paid">Paid</option> */}
															</select>
														</div>
													</>
												) : (
													""
												)}
											</div>
											<div className="col-12 mb-3">
												<label className="form-label mb-0" htmlFor="date_of_transfer">
										Remarks<span style={{ color: "red" }}>*</span>
												</label>
												<textarea
													className="form-control"
													id="app_description"
													rows="4"
													{...register("remarks", {
														required: "This is required."
													})}
													placeholder="Enter Remarks"
												></textarea>
												<ErrorMessage
													errors={errors}
													name="remarks"
													render={({ message }) => (
														<p style={{ color: "red", fontSize: "13px" }}>
															{message}
														</p>
													)}
												/>
											</div>
										</div>
										<button type="submit" className="btn btn-primary m-auto d-block">
								Save
										</button>
									</form>
								</Modal.Body>
							</Modal>

							<Modal show={documentId} onHide={() => setDocumentId("")}>
								<Modal.Header>
									<Modal.Title>Upload PR Receipt</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="col-lg-12 col-12 text-center">
										<div className="Fi-main mb-3">
											<img
												src="/images/icons/documents.png"
												alt="documents"
												className="fi-img"
											/>
											<div
												className="d-flex align-items-center"
												style={{ marginTop: "10px" }}
											>
												<input
													id="prReceipt"
													placeholder="Upload Document"
													className="form-control"
													accept=".png, .jpg, .jpeg, .pdf"
													type="file"
													onChange={e => {
														if (e.target.files.length) {
															setDocumentImage(e.target.files[0]);
														}
													}}
												/>
											</div>
										</div>
										<button
											className="btn btn-primary btn-md"
											onClick={() => uploadDocument()}
										>
								Save
										</button>
									</div>
								</Modal.Body>
							</Modal>

							<UpdateEmiPaymentMethod
								emiData={editPaymentMethod}
								onHide={() => setEditPaymentMethod({ emi_collection_enc_id: "" })}
								reload={() => {
									setEditPaymentMethod({ emi_collection_enc_id: "" });
									reloadDetails();
								}}
							/>

							<UpdateEmiFields
								emiData={editEmiFields}
								onHide={() => setEditEmiFields({ emi_collection_enc_id: "" })}
								reload={() => {
									setEditEmiFields({ emi_collection_enc_id: "" });
									reloadDetails();
								}}
							/>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EmiCollectionTable;
