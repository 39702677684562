import Navbar from "../../components/Navbar";
import React from "react";
import { OrganizationUsersProvider } from "../../context/OrganizationUsersState";
import "../../assets/css/accountStyles.css";
import { Helmet } from "react-helmet";
import DealersSalesReportTable from "../../components/dashboard/DealersSalesReportTable";

const DealersSalesReport = () => {
	return (
		<>
			<Helmet>
				<title>Dealers Sales Report | {process.env.REACT_APP_NAME}</title>
			</Helmet>
			<Navbar />
			<OrganizationUsersProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<DealersSalesReportTable />
					</div>
				</div>
			</OrganizationUsersProvider>
		</>
	);
};
export default DealersSalesReport;
