import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import PieChart from "../../components/charts/PieChart";
import { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import MultiDataFilters from "../../components/MultiDataFilters";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SkeletonCard from "../../components/dashboard/SkeletonCard";

const ApplicationReportsDashboard = () => {
	const history = useNavigate();
	const { getStates, states_list, branches_list } = useContext(OrganizationDashboardContext);
	const [loanAccData, setLoanAccData] = useState({});
	// const [isFiltered, setIsFiltered] = useState(false);
	const [branchArray, setBranchArray] = useState({}); 
	// const [statusData, setStatusData] = useState({});       
	const [statesData, setStatesData] = useState({});
	const [propertyData, setPropertyData] = useState({});
	const [branchesData, setBranchesData] = useState({});
	const [tatData, setTatData] = useState({});
	// const [brachesLoanAccounts, setBrachesLoanAccounts] = useState({});
	const [loading, setLoading] = useState(false);
	const [branchesOptions, setBranchesOptions] = useState([]);
	const [statesArray, setStatesArray] = useState([]);
	const [enachData, setEnachData] = useState({});
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		state_name: [],
		branch_name: []
	});

	const { loanAccountTypes, user } = useContext(MainContext);
    
	const PropertyStats=[
		{api: "stats/property-loan-stats"},
	];

	const tatStats = [
		{api: "stats/tat-stats"}
	];

	const enachStats = [
		{api: "stats/sales-nach-stats"}
	];

	const loanAccountProductStats =
    [
    	{ api: "stats/product-wise-loan-stats", keyword:["MSME", "LCV", "Used Commercial Vehical Loan", "Three Wheeler",] },
    	{ api: "stats/product-wise-loan-stats", keyword:["Personal Loan Top Up", "Used E-Rickshaw", "Used Car Loan"] },
    	{ api: "stats/product-wise-loan-stats", keyword:["Used Bike Loan", "Capital HL BC 2010", "Capital HL BC 2025", "EV Two Wheeler"] },
    	{ api: "stats/product-wise-loan-stats", keyword:["Amrit LAP", "BHN LAP", "School Fee Loan"] },
    	{ api: "stats/product-wise-loan-stats", keyword:["Consumer Durable Loan", "Two Wheeler", "E-Rickshaw"] },
    ];
	
	// const StatusStats =
	// 	[
	// 		{ api: "stats/status-wise-product-stats", keyword:["disbursed"] },
	// 		{ api: "stats/status-wise-product-stats", keyword:["rejected"] },
	// 		{ api: "stats/status-wise-product-stats", keyword:["running"] },
	// 		{ api: "stats/status-wise-product-stats", keyword:["new lead"] },
	// 	];

	const getData = async (filters, api, keyword = null) => {
		try {
			let filter = { ...filters, keyword: keyword };

			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				filter
			);

			if (response.data.data) {
				return response.data.data; // Return the fetched data
			} else {
				return {};
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const fetchData = async (arrayList, setState) => {
		try{
			const promises = arrayList.map(value => {
				return getData(filters, value.api, value.keyword);
			});
			const results = await Promise.all(promises);

			const combinedData = results.reduce((acc, data) => {
				return { ...acc, ...data };
			}, {});
			setState(combinedData);

		}
		catch (error) {
			console.error("Error in fetching data:", error);
		}
	};

	const getDataApi = filters => {
		setFilters({ ...filters, ...filters });
		if (filters?.branch_name?.length || filters?.state_name?.length) {
			// setIsFiltered(true); // hide state section 
			if (filters.state_name?.length) {
				const stateNames = {}; 
				// getting selected states name
				filters.state_name.map(stateName => stateNames[stateName] = states_list.filter(state => state.state_enc_id === stateName)[0].name);
				const filteredBranches = branches_list.filter(branch => filters.state_name.includes(branch.state_enc_id)).map(value => {
					return {...value,
						state_name: stateNames[value.state_enc_id] // Assigning state name to branch 
					};
				});
				setBranchesOptions(filteredBranches);
			}
		} else {
			// setIsFiltered(false);
		}
	};

	useEffect(() => {
		if (process.env.REACT_APP_TYPE !== "DEV" && !["phfleasing", "phf110", "phf1148", "DHIRAJSINGH", "phf1111", "ghuman", "divyajain", "Rachyita"].includes(user.username)) {
			history("/account/dashboard");
		}
		if (!states_list) {
			getStates();
		}
	}, []);

	const divideArrayList = (arrayList, api, type) => {
		const chunkSize = 3;
		let finalArr = [];
		if(arrayList){
			for (let i = 0; i < arrayList.length; i += chunkSize) {
				const chunk = arrayList.slice(i, i + chunkSize);
				let tempArr = [];
				chunk.map((value) => {tempArr.push(type === "states" ? value.name : value.location_enc_id);});
				let apiObj = {api: api, keyword: tempArr};
				finalArr = [...finalArr, apiObj];
			}
		}
		return finalArr;
	};
	
	useEffect(() => {
		let arrayResult = divideArrayList(states_list, "stats/state-wise-loan-stats", "states");
		setStatesArray(arrayResult);
	}, [states_list]);

	useEffect(() => {
		if(statesArray.length){ 
			fetchData(statesArray, setStatesData);
		}
	}, [statesArray,filters]);

	useEffect(() => {
		let arrayResult = divideArrayList(branches_list, "stats/branch-wise-loan-stats", "branch");
		setBranchArray(arrayResult);
	}, [branches_list]);

	useEffect(() => {
		if(branchArray.length){
			fetchData(branchArray, setBranchesData);
		}
	}, [branchArray, filters]);

	useEffect(() => {
		let arrayResult2 = divideArrayList(branchesOptions, "stats/branch-wise-loan-stats", "branches");
		
		if(arrayResult2?.length){
			fetchData(arrayResult2);
		}
	}, [branchesOptions]);

	useEffect(() => {
		const fetchAllData = async () => {
			if (filters.start_date && filters.end_date) {
		       setLoading(true); 
				try {
					await Promise.all([
						fetchData(loanAccountProductStats, setLoanAccData),
						// fetchData(StatusStats, setStatusData),
						fetchData(PropertyStats, setPropertyData),
						fetchData(tatStats, setTatData),
						fetchData(enachStats, setEnachData)
					]);
				} catch (error) {
					console.error("Error in fetching data:", error);
				}
		     setLoading(false); 
			}
		};

		fetchAllData();		
	}, [filters]);



	return (
		<div>
			<Helmet>
				<title>Sales Dashboard</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa py-5 position-relative">
				<div className="container-fluid">
					<div className="row py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all">
						<div className="d-flex flex-wrap justify-content-between align-items-center mb-5">
							<h4 className="mb-3 ptp-heading">Sales Stats</h4>
							<div className="d-flex flex-wrap">
								<MultiDataFilters
									branches_list={branches_list}
									loan_types={loanAccountTypes}
									getData={getDataApi}
									type={["branches", "states"]}
									defaultDate={{ value: "3", label: "Today" }}
								/>
							</div>
						</div>
						{filters.start_date && filters.end_date ? (
							<>
								<div className="row">
								 	<h4 className="text-center mb-3 mt-4 ptp-heading"> By EOB</h4>
									 {loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(propertyData).length ? 
											Object.entries(propertyData).map(([key, value], index) => {
												return(
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
														{value.length ? <PieChart title={key} allData={value} /> : ""}
													</div>
												);
											}): ""
									)}		
									<h4 className="text-center mb-3 mt-4 ptp-heading">By TAT</h4>
									{loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(tatData).length ?
											Object.entries(tatData).map(([key, value], index) => {
												return(
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`tat-stats-${index}`}>
														{value.length ? <PieChart title={key} allData={value} /> : ""}
													</div>
												);
											}) : ""
									)}
									
									<h4 className="text-center ptp-heading mt-3 mb-2">eNACH Stats</h4>
									{loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(enachData).length
											?  Object.entries(enachData).map(([key, value], index) => {
												return (
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`encahData-stats-${index}`}>
														{value.length ? <PieChart title={key} allData={value}  /> : ""}
													</div>
												);
											})
											: "")} 

									<h4 className="text-center mb-3 mt-4 ptp-heading">By Branches</h4>
									{loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(branchesData).length 
											? Object.entries(branchesData).map(([key,  value], index) => {
												return (
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`branch-stats-${index}`}>
														{value.length ?  <PieChart title={key} allData={value} /> : ""}
													</div>
												);
											})
											: "")}
									<h4 className="text-center ptp-heading mt-3 mb-2">By Products</h4>
									{loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(loanAccData).length 
											? Object.entries(loanAccData).map(([key, value], index) => {
												return (
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
														{value.length ?  <PieChart title={key} allData={value} /> : ""}
													</div>
												);
											}) : "")}	

									<h4 className="text-center mb-3 mt-4 ptp-heading"> By States</h4>
									{loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(statesData).length ? 
											Object.entries(statesData).map(([key, value], index) => {
												return(
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
														{value.length ? <PieChart title={key} allData={value} /> : ""}
													</div>
												);
											}): "")}
								</div>
							</>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default ApplicationReportsDashboard;
