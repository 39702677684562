import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import NameComp from "../NameComp";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { EmployeeReportsContext } from "../../context/EmployeeReports";
import CollectionReportHeadings from "./CollectionReportHeadings";
import MultiDataFilters from "../MultiDataFilters";
import { MainContext } from "../../context/MainState";
import SkeletonCard from "./SkeletonCard";
import axios from "axios";
// import DailyCollectionReportSidebar from "./DailyCollectionReportSidebar";
import CollectionReportSidebar from "./CollectionReportSidebar";

const DailyCollectionReportTable = () => {
	const { getDailyCollectionReports, dailyCollectionReports, isLoading, setIsLoading } =
		useContext(EmployeeReportsContext);
	const {
		branches_list,
		getBranches,
		getStates,
		states_list,
		getDesignationList,
		designationList
	} = useContext(OrganizationDashboardContext);
	const { loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [sortArr, setSortArr] = useState({});
	const [userId, setUserId] = useState("");
	const [pageLoading, setPageLoading] = useState(true);
	const [collectionData, setCollectionData] = useState("");
	const [showSidebar, setShowSidebar] = useState(false);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		loan_type: ""
	});

	const DataTableDetails = ({totalCases,colletionCases,verifiedAmount,unVerifiedAmount,interactionCount,interactionAmount,registeredEnach}) => {
		const formatValue = (value, isCurrency = false) => {
			if (value === "0" || (isCurrency && value === "0.00")) {
				return "-";
			}
			return isCurrency ? `₹ ${parseFloat(value).toLocaleString("en-IN")}` : value;
		};

		return (
			<div className="d-flex justify-content-around fix-collection-w w-100">
				<div>{formatValue(totalCases)}</div>
				<div>{formatValue(colletionCases, true)}</div>
				<div>{formatValue(verifiedAmount, true)}</div>
				<div>{formatValue(unVerifiedAmount, true)}</div>
				<div>{formatValue(interactionCount)}</div>
				<div>{formatValue(interactionAmount, true)}</div>
				<div>{formatValue(registeredEnach)}</div>
			</div>
		);
	};
	const getDailyCollectionStats = filters => {
		setPageLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/daily-collection-stats`,
				filters
			)
			.then(response => {
				setPageLoading(false);
				if (response.data.response.status === 200) {
					setCollectionData(response.data.response.data);
				} else {
					setCollectionData([]);
				}
			})
			.catch(error => {
				console.error("An Error Occurred", error);
			});
	};
	const columns = [
		{
			name: (
				<NameComp
					title={"Name"}
					id={"employee_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.employee_name ? row.employee_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize d-flex align-items-center text-link"
						onClick={() => showCollectionReportSidebar(`${row.user_enc_id}`)}
					>
						<img
							src={row.employee_image}
							alt={row.employee_name}
							className="img-fluid rounded-50 mr-1"
							style={{ width: 25, height: 25, objectFit: "cover" }}
						/>
						{row.employee_name}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Employee Code"}
					id={"employee_code"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "120px",
			selector: row => (row.employee_code ? row.employee_code : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					designations={designationList}
				/>
			),
			minWidth: "200px",
			selector: row => (row.designation ? row.designation : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Reporting Person"}
					id={"reporting_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.reporting_person ? row.reporting_person : "--"),
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize d-flex align-items-center">
						{row.reporting_person ? (
							<>
								<img
									src={row.reporting_image}
									alt={row.reporting_person}
									className="img-fluid rounded-50 mr-1"
									style={{ width: 25, height: 25, objectFit: "cover" }}
								/>
								{row.reporting_person}
							</>
						) : (
							"-"
						)}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					states={states_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<CollectionReportHeadings
					name={"Total"}
					type={"total"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			id: "total",
			style: { textAlign: "center" },
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.total_cases_count}
						colletionCases={row?.total_collected_cases_sum}
						verifiedAmount={row?.total_collected_verified_amount}
						unVerifiedAmount={row?.total_collected_unverified_amount}
						interactionCount={row?.total_interaction_count}
						interactionAmount={row?.total_interaction_sum}
						registeredEnach={row?.total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportHeadings
					name={"On Time"}
					type={"OnTime"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			id: "on-time",
			style: { textAlign: "center" },
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.OnTime_total_cases_count}
						colletionCases={row?.OnTime_collected_cases_sum}
						verifiedAmount={row?.OnTime_collected_verified_amount}
						unVerifiedAmount={row?.OnTime_collected_unverified_amount}
						interactionCount={row?.OnTime_total_interaction_count}
						interactionAmount={row?.OnTime_total_interaction_sum}
						registeredEnach={row?.OnTime_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportHeadings
					name={"0-30"}
					type={"SMA0"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			id: "0-30",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA0_total_cases_count}
						colletionCases={row?.SMA0_collected_cases_sum}
						verifiedAmount={row?.SMA0_collected_verified_amount}
						unVerifiedAmount={row?.SMA0_collected_unverified_amount}
						interactionCount={row?.SMA0_total_interaction_count}
						interactionAmount={row?.SMA0_total_interaction_sum}
						registeredEnach={row?.SMA0_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportHeadings
					name={"30-60"}
					type={"SMA1"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			id: "30-60",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA1_total_cases_count}
						colletionCases={row?.SMA1_collected_cases_sum}
						verifiedAmount={row?.SMA1_collected_verified_amount}
						unVerifiedAmount={row?.SMA1_collected_unverified_amount}
						interactionCount={row?.SMA1_total_interaction_count}
						interactionAmount={row?.SMA1_total_interaction_sum}
						registeredEnach={row?.SMA1_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportHeadings
					name={"60-90"}
					type={"SMA2"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			id: "60-90",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA2_total_cases_count}
						colletionCases={row?.SMA2_collected_cases_sum}
						verifiedAmount={row?.SMA2_collected_verified_amount}
						unVerifiedAmount={row?.SMA2_collected_unverified_amount}
						interactionCount={row?.SMA2_total_interaction_count}
						interactionAmount={row?.SMA2_total_interaction_sum}
						registeredEnach={row?.SMA2_total_registered_enach}
					/>
				);
			},
			sortable: false
		},
		{
			name: (
				<CollectionReportHeadings
					name={"90+"}
					type={"NPA"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			id: "90+",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.NPA_total_cases_count}
						colletionCases={row?.NPA_collected_cases_sum}
						verifiedAmount={row?.NPA_collected_verified_amount}
						unVerifiedAmount={row?.NPA_collected_unverified_amount}
						interactionCount={row?.NPA_total_interaction_count}
						interactionAmount={row?.NPA_total_interaction_sum}
						registeredEnach={row?.NPA_total_registered_enach}
					/>
				);
			},
			sortable: false
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getDailyCollectionReports({
			page: page.selected + 1,
			limit: rowsPerPage,
			start_date: filters.start_date,
			end_date: filters.end_date,
			fields_search: searchArray,
			fields_sort: sortArr
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getDailyCollectionReports({
			page: 1,
			limit: parseInt(e.target.value),
			start_date: filters.start_date,
			end_date: filters.end_date,
			fields_search: searchArray,
			fields_sort: sortArr
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const handleClose = () => {
		setShowSidebar(false);
		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};

	// const showDailyCollectionReportSidebar =(row) => {
	// 	setShowSidebar(true);
	// 	setUserId(row);
	// };

	const showCollectionReportSidebar =(row) => {
		setShowSidebar(true);
		setUserId(row);
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray("");
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(dailyCollectionReports?.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (dailyCollectionReports && dailyCollectionReports?.data?.length > 0) {
			return dailyCollectionReports.data;
		} else if (
			dailyCollectionReports &&
			dailyCollectionReports?.data?.length === 0 &&
			isFiltered
		) {
			return [];
		} else {
			return dailyCollectionReports && dailyCollectionReports?.data?.length > 0
				? dailyCollectionReports.data.slice(0, rowsPerPage)
				: [];
		}
	};

	useEffect(() => {
		if (!designationList) {
			getDesignationList();
		}
		if (!branches_list) {
			getBranches();
		}
		if (!states_list || !states_list.length) {
			getStates();
		}
	}, []);

	useEffect(() => {
		setIsLoading(true);
		if (filters.start_date && filters.end_date) {
			const timerId = setTimeout(() => {
				getDailyCollectionReports({
					page: 1,
					limit: rowsPerPage,
					// keyword: searchValue,
					start_date: filters.start_date,
					end_date: filters.end_date,
					fields_search: filters.loan_type ? {...searchArray, loan_type: filters.loan_type} : searchArray,
					fields_sort: sortArr
				});
				setCurrentPage(1);
			}, 800);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}

			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArray, sortArr, filters]);

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			getDailyCollectionStats({
				start_date: filters.start_date + " 00:00:00",
				end_date: filters.end_date + " 23:59:59",
				product_id: filters.loan_product,
				loan_type_enc_id: filters.loan_product_portfolio,
				loan_type: filters.loan_type,
			});
		}
	}, [filters]);

	const getDataApi = filters => {
		setFilters({ ...searchArray, ...filters });
	};

	return (
		<>
			<div className="loan-application-table mb-5">
				{pageLoading ? 
					<div className="row">
						{[...Array(6)].map((index) => (
							<>
								<SkeletonCard key={index} type={"sm"}/>
							</>
						))}
					</div> :
					<div className="dsa-stats">
						<div className="row">
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionData.total_cases_count?collectionData.total_cases_count:"0"}
									</p>
									<p className="mb-0 fs-3 text-white text-start bold card-h">
										Total
									</p>
									<div className="loan-amounts">
										<p className="mb-0 card-h text-white text-end bold">Collected Amount</p>
										<p className="mb-0 card-h text-white text-end bold">
											₹  
											{collectionData.total_collected_cases_sum ? parseFloat(collectionData.total_collected_cases_sum).toLocaleString("en-IN") : "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionData.OnTime_total_cases_count ? collectionData.OnTime_total_cases_count : "0"}
									</p>
									<p className="mb-0 fs-3 text-white text-start bold card-h">
										On Time 
									</p>
									<div className="loan-amounts">
										<p className="mb-0 card-h text-white text-end bold">Collected Amount</p>
										<p className="mb-0 card-h text-white text-end bold">
										    ₹  
											{collectionData.OnTime_collected_cases_sum ? parseFloat(collectionData.OnTime_collected_cases_sum).toLocaleString("en-IN") : "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionData.SMA0_total_cases_count ? collectionData.SMA0_total_cases_count : "0"}
									</p>
									<p className="mb-0 fs-3 text-white text-start bold card-h">
										0-30 
									</p>
									<div className="loan-amounts">
										<p className="mb-0 card-h text-white text-end bold">Collected Amount</p>
										<p className="mb-0 card-h text-white text-end bold">
										    ₹  
											{collectionData.SMA0_collected_cases_sum ? parseFloat(collectionData.SMA0_collected_cases_sum).toLocaleString("en-IN") : "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
									 	 {collectionData.SMA1_total_cases_count ? collectionData.SMA1_total_cases_count : "0"}
									</p>
									<p className="mb-0 fs-3 text-white text-start bold card-h">
										30-60 
									</p>
									<div className="loan-amounts">
										<p className="mb-0 card-h text-white text-end bold">Collected Amount</p>
										<p className="mb-0 card-h text-white text-end bold">
									        ₹  
											{collectionData.SMA1_collected_cases_sum ? parseFloat(collectionData.SMA1_collected_cases_sum).toLocaleString("en-IN") : "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
									  {collectionData.SMA2_total_cases_count ? collectionData.SMA2_total_cases_count : "0"}
									</p>
									<p className="mb-0 fs-3 text-white text-start bold card-h">
										60-90
									</p>
									<div className="loan-amounts">
										<p className="mb-0 card-h text-white text-end bold">Collected Amount</p>
										<p className="mb-0 card-h text-white text-end bold">
											{collectionData.SMA2_collected_cases_sum ? parseFloat(collectionData.SMA2_collected_cases_sum).toLocaleString("en-IN") : "0"}
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-6 col-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
									  {collectionData.NPA_total_cases_count ? collectionData.NPA_total_cases_count : "0"}
									</p>
									<p className="mb-0 fs-3 text-white text-start bold card-h">
										90+
									</p>
									<div className="loan-amounts">
										<p className="mb-0 card-h text-white text-end bold">Collected Amount</p>
										<p className="mb-0 card-h text-white text-end bold">
											₹  
											{collectionData.NPA_collected_cases_sum ? parseFloat(collectionData.NPA_collected_cases_sum).toLocaleString("en-IN") : "0"}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
				<Row className="mx-0 mt-1 mb-5">
					<Col sm="9">
						<div className="d-flex align-items-center py-2 flex-wrap">
							<div className="form-group me-2">
								<label htmlFor="float-text" className="floating-label fs-5">
								Show Results
								</label>
								<select
									className="dataTable-select form-select"
									style={{ width: "95px", padding: "2px 8px", height: "48px" }}
									id="sort-select"
									value={rowsPerPage}
									onChange={e => handlePerPage(e)}
								>
									<option value={10}>10</option>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={75}>75</option>
									<option value={100}>100</option>
								</select>
							</div>
							<MultiDataFilters
								branches_list={branches_list}
								loan_types={loanAccountTypes}
								getData={getDataApi}
								type={["loanAccountsType"]}
								defaultDate={{ value: "3", label: "Today" }}
							/>
						</div>
					</Col>
					<Col
						className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
						sm="3"
					>
						<div className="filters-container d-flex justify-content-end">
							{showClearBtn ? (
								<button
									className="btn btn-primary btn-xs my-2"
									onClick={e => clearFilter(e)}
								>
								Reset
								</button>
							) : (
								""
							)}
						</div>
					</Col>
				</Row>
				<DataTable
					noHeader
					pagination
					paginationServer
					columns={columns}
					data={dataToRender()}
					sortServer={true}
					paginationComponent={CustomPagination}
					progressPending={isLoading}
					highlightOnHover="true"
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={
						<p className="w-100 m-5 text-start">There are no records to display</p>
					}
					persistTableHead={true}
					className="loan-app-table-container fix-overflow report-tables z-0"
					fixedHeader={true}
					fixedHeaderScrollHeight="100vh"
				/>
			</div>

			<CollectionReportSidebar
				showSideBar={showSidebar}
				handleClose={handleClose}
				filters={filters}
				userId={userId}
			/>
		</>
	);
};
export default DailyCollectionReportTable;
