import React, { createContext, useContext, useReducer, useState } from "react";
import OrganizationUsersReducer from "../reducers/OrganizationUsersReducer";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { MainContext } from "./MainState";

// Create context
export const OrganizationUsersContext = createContext({});

// Provider component
export const OrganizationUsersProvider = ({ children }) => {
	const [state, dispatch] = useReducer(OrganizationUsersReducer, {});
	const [isLoading, setIsLoading] = useState(false);
	const [cancelTokenSource, setCancelTokenSource] = useState(null);
	const { user } = useContext(MainContext);

	// Actions
	const getConnector = async (filters = {}) => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/dsa-connectors`,
				filters
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_CONNECTOR",
						payload: response.data.response.connector
					});
				} else {
					dispatch({
						type: "SET_CONNECTOR",
						payload: []
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while getting Dealers, Please try again");
			});	
	};
	// Actions
	const getUsers = async (filters = {}) => {
		const data =  {...filters};
		setIsLoading(filters.type);
		data["assigned_financer_enc_id"] = user.organization_enc_id;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/employees`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: filters.type === "employees"
							? "SET_EMPLOYEE_LIST_DATA"
							: "SET_" + filters.type.toUpperCase() + "_DATA",
						payload: response.data.response
					});
				}else{
					dispatch({
						type: filters.type === "employees"
							? "SET_EMPLOYEE_LIST_DATA"
							: "SET_" + filters.type.toUpperCase() + "_DATA",
						payload: []
					});
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while getting employee list, Please try again");
			});
	};

	const getDealers = async (filters = {}) => {
		setIsLoading(true);
		const data =  {...filters};
		data["assigned_financer_enc_id"] = user.organization_enc_id;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_DEALER",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_DEALER",
						payload: []
					});
				}
			}).catch(error => {
				setIsLoading(false);
				console.log(error);
				toast.error("An error has occurred while getting Dealers, Please try again");
			});
	};

	const getInactiveEmployees = async (filters = {}) => {
		setIsLoading(true);
		const data =  {...filters};
		data["assigned_financer_enc_id"] = user.organization_enc_id;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/inactive-employee-list`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_INACTIVE_EMPLOYEE",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_INACTIVE_EMPLOYEE",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while getting inactive employee list, Please try again");
			});
	};

	const getIncentivePoints = async (filters = {}) => {
		setIsLoading(true);
		const data =  {...filters};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee-incentive-points/employee-points`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_INCENTIVE_POINTS",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_INCENTIVE_POINTS",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while getting incentive points report, Please try again");
			});
	};

	const updateStatus = async (user_id, status, id) => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/change-status`,
				{
					user_id,
					status
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Data Updated");
					getUsers({type: id});
					if(status !== "delete"){
						getInactiveEmployees();
					}
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while updating status, Please try again");
			});
	};

	const getEmployeeData = async filter => {
		if (cancelTokenSource && cancelTokenSource[filter.pageType]) {
			cancelTokenSource[filter.pageType].cancel("Previous request canceled");
		}
		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, [filter.pageType]: newCancelTokenSource});

		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/employee-stats`,
				filter, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: filter.page_type === "employees" && filter.data_type === "sum" 
							? "SET_EMPLOYEE_SUM_DATA"
							: "SET_" + filter.page_type.toUpperCase() + "_"+filter.data_type.toUpperCase() + "_DATA",
						payload: response.data.response
					});
				}else{
					dispatch({
						type: filter.page_type === "employees" && filter.data_type === "sum" 
							? "SET_EMPLOYEE_SUM_DATA"
							: "SET_" + filter.page_type.toUpperCase() + "_"+filter.data_type.toUpperCase() + "_DATA",
						payload: []
					});
				}
			})
			.catch(error => {
				if (axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					console.log(error);
					setIsLoading(false);
					toast.error("An error has occurred while getting sales reports, Please try again");
				}
			});
	};

	const getDealerData = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		filter["start_date"] = filter.start_date + "  00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";
		filter["organization_enc_id"] = user.organization_enc_id;

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/dealer-stats`,
				filter
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_DEALER_DATA",
						payload: response.data.response
					});
				} else {
					dispatch({
						type: "SET_DEALER_DATA",
						payload: {}
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while getting sales reports, Please try again");
			});
	};

	const getDealerstotalStats = async filter => {
		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("dealerStats");
		filter["start_date"] = filter.start_date + "  00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";
		filter["organization_enc_id"] = user.organization_enc_id;

		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/dealer-total-stats`,
		 	filter
		).then(response => {
			setIsLoading(false);
			if(response.data.response.status === 200){
				dispatch({
					type: "SET_DEALER_TOTAL_STATS",
					payload: response.data.response.data
				});
			}else {
				dispatch({
					type: "SET_DEALER_TOTAL_STATS",
					payload: {}
				});
			}
		}
		).catch(error => {
			console.log(error);
			setIsLoading(false);
			toast.error("An error has occurred while getting sales reports, Please try again");

		});
	};

	// const getDealerData = async filter => {
	// 	console.log("Filter:", filter);
	
	// 	if (moment(filter.start_date).isAfter(filter.end_date)) {
	// 		toast.error("To date should be after from date");
	// 		return false;
	// 	}
	
	// 	setIsLoading("employeeStat");
	
	// 	filter["start_date"] = filter.start_date + "  00:00:00";
	// 	filter["end_date"] = filter.end_date + " 23:59:59";
	// 	filter["organization_enc_id"] = user.organization_enc_id;
	
	// 	const apiUrl = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}dealers/dealer-stats`;
	// 	console.log("API URL:", apiUrl);
	
	// 	await axios.post(apiUrl, filter)
	// 		.then(response => {
	// 			console.log("Axios Response:", response);
	// 			setIsLoading(false);
	// 			if (response.data.response.status === 200) {
	// 				dispatch({
	// 					type: "SET_DEALER_DATA",
	// 					payload: response.data.response
	// 				});
	// 			} else {
	// 				dispatch({
	// 					type: "SET_DEALER_DATA",
	// 					payload: {}
	// 				});
	// 			}
	// 		})
	// 		.catch(error => {
	// 			console.error("Axios Error:", error);
	// 			setIsLoading(false);
	// 			toast.error("An error has occurred while getting sales reports, Please try again");
	// 		});
	// };	

	const getBranchesData = async filter => {
		if (cancelTokenSource && cancelTokenSource[filter.type]) {
			cancelTokenSource[filter.type].cancel("Previous request canceled");
		}
		// Create a new cancel token for this request
		const newCancelTokenSource = axios.CancelToken.source();
		setCancelTokenSource({...cancelTokenSource, [filter.type]: newCancelTokenSource});

		if (moment(filter.start_date).isAfter(filter.end_date)) {
			toast.error("To date should be after from date");
			return false;
		}
		setIsLoading("employeeStat");
		filter["start_date"] = filter.start_date + " 00:00:00";
		filter["end_date"] = filter.end_date + " 23:59:59";

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/branch-list`,
				filter, { cancelToken: newCancelTokenSource.token }
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					dispatch({
						type:
							filter.type === "by_amount"
								? "SET_BRANCHES_REPORT_AMOUNTS"
								: "SET_BRANCHES_REPORT_COUNT",
						payload: response.data.response
					});
				} else {
					dispatch({
						type:
							filter.type === "by_amount"
								? "SET_BRANCHES_REPORT_AMOUNTS"
								: "SET_BRANCHES_REPORT_COUNT",
						payload: {}
					});
				}
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
				toast.error("An error has occurred while getting sales reports, Please try again");
			});
	};

	return (
		<OrganizationUsersContext.Provider
			value={{
				users: state.users,
				dsa: state.dsa,
				connector: state.connector,
				dealer: state.dealer,
				deleted: state.deleted,
				employeeData: state.employeeData,
				// employeeDataByAmount:state.employeeDataByAmount,
				dealerData: state.dealerData,
				branchesReport: state.branchesReport,
				inactiveEmployee: state.inactiveEmployee,
				incentivePoints: state.incentivePoints,
				dealerAllStats: state.dealerAllStats,
				getConnector,
				getInactiveEmployees,
				getIncentivePoints,
				getUsers,
				getDealers,
				getBranchesData,
				isLoading,
				updateStatus,
				getEmployeeData,
				// getEmployeeDataByAmount,
				getDealerData,
				getDealerstotalStats
			}}
		>
			{children}
		</OrganizationUsersContext.Provider>
	);
};
