import { Col, Modal } from "react-bootstrap";
import AutoComplete from "../forms/AutoComplete";
import { MdDelete } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";

const AssignBdoModal = ({ bdoModal, onHide, reloadData }) => {
	const { user, handlePageLoaded } = useContext(MainContext);
	const { handleSubmit, reset, setValue, watch, getValues } = useForm();
	const [finalUsers, setFinalUsers] = useState([]);
	let toBeSharedName = watch("name");
	const toBeShared = watch("shared_to");

	const onSubmit = async data => {
		if (bdoModal.type === "bdo") {
			const alreadyExist = finalUsers.filter(user => user.shared_to === data.shared_to);
			if (alreadyExist.length) {
				reset();
				toast.error("Already Selected");
				return false;
			}
			setFinalUsers([...finalUsers, data]);
			reset();
		} else {
			assignBdo(bdoModal.type, data);
		}
	};

	const handleBdoRemoved = id => {
		const tempAll = finalUsers.filter(list => list.shared_to !== id);
		setFinalUsers(tempAll);
	};

	const assignBdo = (type, userData = null) => {
		const data = {};
		const bdos = [];
		if (type === "bdo") {
			finalUsers.forEach(vals => bdos.push({ id: vals.shared_to, access: vals.access }));
		} else {
			bdos.push({ id: userData.shared_to });
		}
		data["users"] = bdos;
		data["type"] = type;
		data["loan_accounts"] = [bdoModal.id];
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/share-loan-accounts`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					onHide();
					reloadData(new Date());
					toast.success("Submit Successfully");
					reset();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				console.log(error);
				toast.error(error.message);
			});
	};

	const clearData = () => {
		onHide();
		setFinalUsers([]);
	};

	useEffect(() => {
		if (toBeShared) {
			onSubmit(getValues());
		}
	}, [toBeShared]);

	return (
		<Modal show={bdoModal.id} onHide={onHide} size={"md"} centered>
			<Modal.Header>
				<Modal.Title>
					Assign {bdoModal.type === "bdo" ? "BDO's" : "Collection Manager"}
				</Modal.Title>
				<span className="close-icon" onClick={clearData}>
					<IoCloseCircle />
				</span>
			</Modal.Header>
			<Modal.Body>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					{bdoModal.type === "bdo" ? (
						<>
							<div className="col-sm-12 col-12">
								<div className="mb-3 d-flex">
									<div className="w-100">
										<AutoComplete
											setFieldValue={setValue}
											link={`company-dashboard/employee-search?type=${bdoModal.type}&employee_search=`}
											name={"shared_to"}
											val={toBeSharedName ? toBeSharedName : "empty"}
											method={"GET"}
											placeholder={"Enter Username"}
											type="loan_accounts"
										/>
									</div>
								</div>
							</div>
							{finalUsers && finalUsers.length ? (
								<div className="row">
									<h4 className="m-0 mt-2">Selected Users</h4>
									<div className="imageList">
										<table className="table">
											<thead>
												<tr>
													<th>User</th>
													{user.user_type === "Financer" || ["ghuman", "phf110", "phf1164"].includes(user.username) ? (
														<th className="text-center">Delete</th>
													) : ""}
												</tr>
											</thead>
											<tbody className="imageListTd">
												{finalUsers.map((value, index) => {
													return (
														<>
															{value.shared_to ? (
																<tr
																	key={`users.${index}-${value.shared_to}`}
																>
																	<td>
																		<div>
																			<img
																				src={value.image}
																				alt={value.name}
																				className="emp-img"
																			/>{" "}
																			{value.name}
																		</div>
																	</td>
																	{user.user_type === "Financer" || ["ghuman", "phf110", "phf1164"].includes(user.username) ? (
																		<td className="text-center">
																			<button
																				type="button"
																				onClick={() =>
																					handleBdoRemoved(
																						value.shared_to
																					)
																				}
																				className="btn btn-primary btn-xs"
																			>
																				<MdDelete
																					fontSize={15}
																				/>
																			</button>
																		</td>
																	) : ""}
																</tr>
															) : (
																""
															)}
														</>
													);
												})}
											</tbody>
										</table>
									</div>
									<div className="col-sm-12 col-12">
										<Col size={12} className="text-center mt-2">
											<button
												type="button"
												className="btn btn-primary"
												onClick={() => assignBdo(bdoModal.type)}
											>
												Save
											</button>
										</Col>
									</div>
								</div>
							) : (
								""
							)}
						</>
					) : (
						<>
							<div className="col-sm-12 col-12">
								<div className="mb-3 d-flex">
									<div className="w-100">
										<AutoComplete
											setFieldValue={setValue}
											link={`company-dashboard/employee-search?type=${bdoModal.type}&employee_search=`}
											name={"shared_to"}
											val={toBeSharedName ? toBeSharedName : "empty"}
											method={"GET"}
											placeholder={"Enter Username"}
											// type="loan_accounts"
										/>
										<div className="col-sm-12 col-12">
											<Col size={12} className="text-center mt-2">
												<button type="submit" className="btn btn-primary">
													Save
												</button>
											</Col>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</form>
			</Modal.Body>
		</Modal>
	);
};
export default AssignBdoModal;
