import React, { useContext, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { CandidateDashboardContext } from "../../context/CandidateDashboardState";
import { FaPhoneAlt, FaRupeeSign, FaWhatsapp } from "react-icons/fa";
import { MainContext } from "../../context/MainState";
import { MdOutlineClose } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

SwiperCore.use([Autoplay]);
const CandidateSidebar = () => {
	const { getLoanApplications, selectedApplication, all_applications, setChoosedApplication, getCandidateNoticeBoard, candidate_notice  } = useContext(CandidateDashboardContext);
	const { user } = useContext(MainContext);
	const [sidebar, setSidebar] = useState(false);

	useEffect(() => {
		getLoanApplications();
	}, []);

	useEffect(() => {
		getCandidateNoticeBoard();
	}, []);

	const showSidebar = () => {
		setSidebar(!sidebar);
	};
	return (
		<>
			<div className="navigate-loan mb-5">
				<div className="navigate-user nd-shadow p-3 mb-3 text-capitalize rounded-2 bg-white">
					<h3 className="mb-0 d-flex align-items-center">
						<img src={user.image} alt={user.first_name} className="img-fluid mr-1" style={{maxWidth: 80}}/>
						<ul className="l list-unstyled m-0">
							<li className="text-primary fs-4">{user.first_name ? user.first_name + " " + user.last_name : user.name}</li>
							<li className="fs-5 text-black-50 fw-medium"><FaPhoneAlt size={12}/> {user.phone}</li>
						</ul>
					</h3>
				</div>
				<div className={`nd-shadow bg-white p-3 mb-3 mobile-stick ${sidebar ? "mobile-stick-width d-none" : ""}`}>
					<span className="showMore" onClick={showSidebar}> {!sidebar ? "" : <MdOutlineClose size={20} />} </span>
					<Nav variant="pills" className="flex-column set-width drop-margin">
						<div className="loan-list">
							<h4>My Loans</h4>
							<Nav.Item className="mb-2 pb-2">
								{all_applications && all_applications.length ? (
									all_applications.map((data, index) => {
										return (
											<Nav.Link
												key={`loan_applications_list_${data.loan_app_enc_id}${index}`}
												className={
													selectedApplication.loan_app_enc_id === data.loan_app_enc_id
														? selectedApplication.loan_status === "Disbursed" 
															? "active active-color bg-success"
															: "active active-color"
														: ""
												}
												onClick={() => {
													setChoosedApplication(data);
												}}
											>
												<span className="loan-type-sidebar">
													{data.loan_type}
												</span>
												<span className="fs-5">
													<FaRupeeSign size={12} />
													{parseFloat(data.loan_amount).toLocaleString(
														"en-IN"
													)}
												</span>
											</Nav.Link>
										);
									})
								) : (
									<div className="no-data-found">No Records</div>
								)}
							</Nav.Item>
						</div>

						{/* <Nav.Item>
							<Link to="/account/notifications" className="fs-4">
								<FaBell /> Notifications
							</Link>
						</Nav.Item>
						<Nav.Item className="my-2">
							<Link to="/account/my-rewards" className="fs-4">
								<FiGift /> My Rewards
							</Link>
						</Nav.Item> */}
					</Nav>
				</div>
				{candidate_notice && candidate_notice.length !== "0" ? 
					<div className="nd-shadow bg-white p-3 mb-3 notices-mb">
						<h3 className="mb-2">Promotions</h3>
						{candidate_notice && candidate_notice.length ? (
							<div className="noticeBoard bg-white">
								<div className="noticeSlider">
									<Swiper
										className="swiper-wrapper"
										spaceBetween={30}
										pagination={{ clickable: true }}
										modules={[Pagination]}
										slidesPerView={1}
										autoplay={true}
										loop={true}
										autoHeight={true}
										breakpoints={{
											320: {
												slidesPerView: 1,
												spaceBetween: 10
											},
											768: {
												slidesPerView: 1,
												spaceBetween: 10
											},
											1024: {
												slidesPerView: 1,
												spaceBetween: 10
											}
										}}
									>
										{candidate_notice && candidate_notice.map(value => {
											return (
												<>
													{value.image ?
														<SwiperSlide key={`noticesImages${value.notice_enc_id}`}>
															<div className="notice-img">
																<img
																	src={value.image}
																	alt="notice-img"
																	className="img-fluid"
																/>
															</div> 
														</SwiperSlide>
														: ""}
												</>
											);
										})}
									</Swiper>
								</div>
							</div>
						) : (
							""
						)}
					</div>
					: ""} 
				
				
				<div className="nd-shadow bg-white p-3 mb-3 call-links">
					<h3>Call / Whatsapp</h3>
					<a
						href="tel:+918727985888"
						target="_blank"
						rel="noreferrer"
						className="for-calling"
					>
						<FaPhoneAlt />
					</a>
					<a
						href="https://wa.me/+918727985888"
						target="_blank"
						rel="noreferrer"
						className="for-whatsapp"
					>
						<FaWhatsapp />{" "}
					</a>
				</div>
		
			</div>
		</>
	);
};
export default CandidateSidebar;
