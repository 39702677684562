import { Modal } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { BsFillCameraFill } from "react-icons/bs";
import Compressor from "compressorjs";
import ReactDatePicker from "react-datepicker";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import { readOnlyUsers } from "../../helpers/authServices";

const UpdateEmiPaymentMethod = ({ onHide, emiData, reload }) => {
	const { handlePageLoaded, user } = useContext(MainContext);
	const {
		handleSubmit,
		register,
		watch,
		setError,
		clearErrors,
		unregister,
		reset,
		formState: { errors }
	} = useForm();
	const [inputRefNo, setInputRefNo] = useState("");
	const otherPayment = watch("payment_mode");
	const paymentMethod = watch("payment_method");
	const onlineOffsystem = watch("online_offsystem");
	const [prImg, setPrImg] = useState({ image: "", name: "", compressedImage: "" });
	const [selectedVal, setSelectedVal] = useState("~");
	const [numberExist, setNumberExist] = useState({
		ref: false
	});
	const handleChange = (e) => {
		setInputRefNo(e.target.value);
	};
	const compressImage = (e, setImageState) => {
		if (e.target.files.length) {
			const selectedImage = e.target.files[0];
			setImageState({
				image: selectedImage,
				name: selectedImage.name
			});

			new Compressor(selectedImage, {
				quality: 0.6,
				success: res => {
					setImageState(prevState => ({
						...prevState,
						compressedImage: res
					}));
				}
			});
		}
	};
	const checkRefNumber = async (value, key) => {
		if (value) {
			let data = { [key]: inputRefNo };
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/emi-ref-check`,
					data
				)
				.then(response => {
					if (response.data.response.status === 201) {
						setError(key, {
							type: "custom",
							message: "Already Exists"
						});
						setNumberExist({ ...numberExist, [key]: true });
					} else {
						clearErrors(key);
						setNumberExist({ ...numberExist, [key]: false });
					}
				})
				.catch(error => {
					toast.error(error.message);
				});	
		}
	};
	useEffect(() => {
		const timer = setTimeout(() => {
			checkRefNumber(inputRefNo, "ref");
		}, 500);

		return () => {
			clearTimeout(timer);
		};
	}, [inputRefNo]);
	useEffect(() => {
		unregister("receipt_image");
		unregister("reference_number");
	}, [emiData]);
	const resetComponent = () => {
		reset();
		setPrImg({ image: "", name: "", compressedImage: "" });
		setSelectedVal("~");
		setInputRefNo("");
		reload();
	};
	const onSubmit = data => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let formData = new FormData();
		if (!data.payment_method || !data.payment_mode || (!emiData.collection_date && (!selectedVal || selectedVal === "~"))){
			toast.error("Please fill all required fields");
			return false;
		}
		delete data.receipt_image;
		Object.entries(data).map(([key, value]) => {
			formData.append(key, value);
		});
		formData.append("emi_id", emiData.emi_collection_enc_id);
		if (prImg.name) {
			formData.append("pr_receipt_image", prImg.compressedImage);
		}
		if (selectedVal !== "~") {
			formData.append("collection_date", moment(selectedVal).format("YYYY-MM-DD"));
		}
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/update-payment-method`,
				formData
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success(response.data.response.message ?? "Saved Updated");
					resetComponent();
				} else {
					toast.error(
						response.data?.response?.message ? response.data.response.message.toString() : "An error occurred"
					);
				}
				handlePageLoaded(false);
			})
			.catch(error => {
				handlePageLoaded(false);
				console.log(error);
				toast.error("An error has occurred, Please try again");
			});
	};

	return (
		<Modal show={emiData.emi_collection_enc_id} onHide={onHide} size={"lg"} centered>
			<Modal.Body>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					{/* <div className="row"> */}
					<h3 className="text-center mb-3">Update Payment Method</h3>
					{/* </div> */}
					<div className="row mb-3">
						<div className="col-lg-12">
							<label className="form-label mb-0" htmlFor="">
								Mode Of Payment <span style={{ color: "red" }}>*</span>
							</label>
						</div>
						<div className="radio-wrapper">
							{/* <div className="radio-item"> */}
							{/* 	<input */}
							{/* 		type="radio" */}
							{/* 		name="payment_method" */}
							{/* 		className="radioInput" */}
							{/* 		id="pay_now" */}
							{/* 		value="1" */}
							{/* 		{...register("payment_mode", { */}
							{/* 			required: "This is required." */}
							{/* 		})} */}
							{/* 	/> */}
							{/* 	<label */}
							{/* 		htmlFor="pay_now" */}
							{/* 		className="option option-1" */}
							{/* 		style={{ */}
							{/* 			justifyContent: "flex-start", */}
							{/* 			marginBottom: "10px" */}
							{/* 		}} */}
							{/* 	> */}
							{/* 		<div className="dot"></div> */}
							{/* 		<span>Pay Now</span> */}
							{/* 	</label> */}
							{/* </div> */}
							{/* <div className="radio-item"> */}
							{/* 	<input */}
							{/* 		type="radio" */}
							{/* 		name="payment_method" */}
							{/* 		className="radioInput" */}
							{/* 		id="payment_manual_collection" */}
							{/* 		value="2" */}
							{/* 		{...register("payment_mode", { */}
							{/* 			required: "This is required." */}
							{/* 		})} */}
							{/* 	/> */}
							{/* 	<label */}
							{/* 		htmlFor="payment_manual_collection" */}
							{/* 		className="option option-1" */}
							{/* 		style={{ */}
							{/* 			justifyContent: "flex-start", */}
							{/* 			marginBottom: "10px" */}
							{/* 		}} */}
							{/* 	> */}
							{/* 		<div className="dot"></div> */}
							{/* 		<span>Manual Collection</span> */}
							{/* 	</label> */}
							{/* </div> */}
							{/* <div className="radio-item"> */}
							{/* 	<input */}
							{/* 		type="radio" */}
							{/* 		name="payment_method" */}
							{/* 		className="radioInput" */}
							{/* 		id="pay_by_eod" */}
							{/* 		value="3" */}
							{/* 		{...register("payment_mode", { */}
							{/* 			required: "This is required." */}
							{/* 		})} */}
							{/* 	/> */}
							{/* 	<label */}
							{/* 		htmlFor="pay_by_eod" */}
							{/* 		className="option option-1" */}
							{/* 		style={{ */}
							{/* 			justifyContent: "flex-start", */}
							{/* 			marginBottom: "10px" */}
							{/* 		}} */}
							{/* 	> */}
							{/* 		<div className="dot"></div> */}
							{/* 		<span>Pay By EOD</span> */}
							{/* 	</label> */}
							{/* </div> */}
							<div className="radio-item">
								<input
									type="radio"
									name="payment_method"
									className="radioInput"
									id="online_off_system"
									value="4"
									checked={true}
									{...register("payment_mode", {
										required: "This is required."
									})}
								/>
								<label
									htmlFor="online_off_system"
									className="option option-1"
									style={{
										justifyContent: "flex-start",
										marginBottom: "10px"
									}}
								>
									<div className="dot"></div>
									<span>Online Off System Transaction</span>
								</label>
							</div>
						</div>

						{otherPayment === "1" ? (
							<>
								<label className="form-label mb-0">
									Choose Pay Now Options <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="qr-code"
											className="radioInput"
											id="QrCode"
											value="1"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="QrCode"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Generate QR</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="qr-link"
											className="radioInput"
											id="link"
											value="2"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="link"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Generate Link</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											disabled={true}
											type="radio"
											name="POS_Service"
											className="radioInput"
											id="pos-service"
											value=""
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="pos-service"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>POS Machine</span>
										</label>
									</div>
								</div>
							</>
						) : (
							""
						)}

						{otherPayment === "2" ? (
							<>
								<label className="form-label mb-0">
									Manual Collection By <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="manual_collection"
											className="radioInput"
											id="manual_cash"
											value="4"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="manual_cash"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Cash</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="manual_collection"
											className="radioInput"
											id="manual_cheque"
											value="5"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="manual_cheque"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Cheque</span>
										</label>
									</div>
									{/* <div className="radio-item">
                  <input
                    type="radio"
                    name="manual_collection"
                    className="radioInput"
                    id="paid_to_dealer"
                    value="4"
                    {...register("payment_method", {
                      required: "This is required.",
                    })}
                  />
                  <label
                    htmlFor="paid_to_dealer"
                    className="option option-1"
                    style={{
                      justifyContent: "flex-start",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="dot"></div>
                    <span>Paid To Dealer</span>
                  </label>
                </div> */}
								</div>
							</>
						) : (
							""
						)}
						{otherPayment === "3" ? (
							<>
								<label className="form-label mb-0">
									EOD By <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="pay_by_eod"
											className="radioInput"
											id="pay_by_eod_nach"
											value="6"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="pay_by_eod_nach"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Nach</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="pay_by_eod"
											className="radioInput"
											id="pay_by_eod_enach"
											value="7"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="pay_by_eod_enach"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Enach</span>
										</label>
									</div>
								</div>
							</>
						) : (
							""
						)}
						{/* {otherPayment === "4" ? ( */}
						<>
							<label className="form-label mb-0">
								Online Off System By <span style={{ color: "red" }}>*</span>
							</label>
							<div className="radio-wrapper">
								<div className="radio-item">
									<input
										type="radio"
										name="online_offsystem"
										className="radioInput"
										id="directBank"
										value="Direct Deposit In Bank Account"
										{...register("online_offsystem", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="directBank"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Direct Deposit In Bank Account</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="online_offsystem"
										className="radioInput"
										id="digital_platform"
										value="Through Digital Platform"
										{...register("online_offsystem", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="digital_platform"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Through Digital Platform</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="manual_collection"
										className="radioInput"
										id="paid_to_dealer"
										value="11"
										{...register("online_offsystem", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="paid_to_dealer"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Paid To Dealer</span>
									</label>
								</div>
							</div>
						</>
						{/* ) : ( */}
						{/* 	"" */}
						{/* )} */}
					</div>
					{otherPayment === "4" &&
					onlineOffsystem === "Direct Deposit In Bank Account" ? (
							<>
								<div className="radio-wrapper">
									<label className="form-label mb-0">
										Direct Deposit In Bank Account By{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<div className="radio-wrapper">
										<div className="radio-item">
											<input
												type="radio"
												name="direct_deposit"
												className="radioInput"
												id="directBankCash"
												value="81"
												{...register("payment_method", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="directBankCash"
												className="option option-1"
												style={{
													justifyContent: "flex-start",
													marginBottom: "10px"
												}}
											>
												<div className="dot"></div>
												<span>Cash</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="radio"
												name="direct_deposit"
												className="radioInput"
												id="directBankCheque"
												value="82"
												{...register("payment_method", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="directBankCheque"
												className="option option-1"
												style={{
													justifyContent: "flex-start",
													marginBottom: "10px"
												}}
											>
												<div className="dot"></div>
												<span>Cheque</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="radio"
												name="direct_deposit"
												className="radioInput"
												id="directBankNetBanking"
												value="83"
												{...register("payment_method", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="directBankNetBanking"
												className="option option-1"
												style={{
													justifyContent: "flex-start",
													marginBottom: "10px"
												}}
											>
												<div className="dot"></div>
												<span>Net Banking</span>
											</label>
										</div>
										<div className="radio-item">
											<input
												type="radio"
												name="direct_deposit"
												className="radioInput"
												id="directBankNeft"
												value="84"
												{...register("payment_method", {
													required: "This is required."
												})}
											/>
											<label
												htmlFor="directBankNeft"
												className="option option-1"
												style={{
													justifyContent: "flex-start",
													marginBottom: "10px"
												}}
											>
												<div className="dot"></div>
												<span>RTGS/NEFT</span>
											</label>
										</div>
									</div>
								</div>
							</>
						) : (
							""
						)}
					{otherPayment === "4" && onlineOffsystem === "Through Digital Platform" ? (
						<>
							<div className="radio-wrapper">
								<label className="form-label mb-0">
									Through Digital Platform By{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper w-100">
									<div className="radio-item">
										<input
											type="radio"
											name="through_digital_platform"
											className="radioInput"
											id="digitalUpi"
											value="9"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="digitalUpi"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Scanned QR Code/UPI</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="through_digital_platform"
											className="radioInput"
											id="digitalTransfer"
											value="10"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="digitalTransfer"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Digital Transfer</span>
										</label>
									</div>
								</div>
							</div>
						</>
					) : (
						""
					)}
					<div className="row">
						{!emiData?.reference_number ? (
							<div className="col-md-6 col-12 mb-2">
								<label className="form-label mb-0">
									Reference Number
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Refrence Number"
									{...register("reference_number", {
										required: "This is required."
									})}
									onChange={handleChange}
									// onChange={e => {
									// 	checkRefNumber(e.target.value, "ref");
									// }}
								/>
								<ErrorMessage
									errors={errors}
									name="reference_number"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						) : ""}
						{!emiData?.pr_receipt_image ? (
							<div className="col-md-6 col-12 mb-2">
								<label className="form-label mb-0">
									PR Receipt Photo{" "}
									{(otherPayment !== "Not Paid" &&
											otherPayment !== "1" &&
											paymentMethod !== "6" &&
											paymentMethod !== "7") ? (
											<span style={{ color: "red" }}>*</span>
										) : (
											""
										)}
								</label>
								<label
									className="form-control text-center"
									style={{
										backgroundColor: "#00a0e3",
										color: "#fff",
										cursor: "pointer"
									}}
									htmlFor="receipt_image"
								>
									<BsFillCameraFill /> Capture Image
								</label>
								<input
									id="receipt_image"
									accept="image/*"
									placeholder="Capture Image"
									className="form-control d-none"
									{...register("receipt_image", {
										required:
											(otherPayment !== "Not Paid" &&
												otherPayment !== "1" &&
												paymentMethod !== "6" &&
												paymentMethod !== "7")
												? "This is required."
												: false
									})}
									type="file"
									capture="environment"
									onChange={e => compressImage(e, setPrImg)}
								/>
								{prImg.name ? (
									<p
										style={{
											marginBottom: "0px",
											fontSize: "14px",
											color: "#000"
										}}
									>
										{prImg.name}{" "}
									</p>
								) : (
									""
								)}
								<ErrorMessage
									errors={errors}
									name="receipt_image"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						) : ""}
						{!emiData?.collection_date ? (
							<div className="col-md-6 col-12 d-flex flex-column">
								<label className="form-label mb-0" htmlFor="collection_date">
									Collection Date
									<span style={{ color: "red" }}>*</span>
								</label>
								<ReactDatePicker
									selected={selectedVal !== "~" ? selectedVal : ""}
									onChange={date => setSelectedVal(date)}
									placeholderText="Enter Collection Date"
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className={"form-control"}
									isClearable={selectedVal !== "~"}
									maxDate={new Date()}
									dateFormat="dd MMM yyyy"
								/>
							</div>
						) : ("")}
					</div>
					<button type="submit" className="btn btn-primary m-auto d-block mt-3">
						Save
					</button>
				</form>
			</Modal.Body>
		</Modal>
	);
};
export default UpdateEmiPaymentMethod;