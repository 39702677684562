import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import NameComp from "../NameComp";
import { OrganizationSidebarProvider } from "../../context/OrganizationSidebarState";
import LoanApplicationDetails from "./LoanApplicationDetails";
import { MainContext } from "../../context/MainState";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { BiRupee } from "react-icons/bi";
import { loanStatusData } from "../../data/loanStatusData";
import EditInput from "../forms/EditInput";
import moment from "moment";
import { EmployeeReportsContext } from "../../context/EmployeeReports";
import MinMaxFilter from "../MinMaxFilter";
import UpdateLoanStatus from "./UpdateLoanStatus";
import { FaTimes } from "react-icons/fa";

const RcPendingCases = ({formType = ""}) => {
	const { user, loan_products, financer_loan_status, getFinancerLoanStatusList } = useContext(MainContext);
	const { branches_list, getBranches } = useContext(OrganizationDashboardContext);
	const { getRcPendingApplications, rcPendingApplications } = useContext(EmployeeReportsContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [userDsa, setUserDsa] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [loanDetails, setLoanDetails] = useState("");
	const [statusToggle, setStatusToggle] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const [pageLoading, setPageLoading] = useState(true);
	const ref = useRef(null);

	const getStatus = key => {
		const selectedStatus = loanStatusData.loanStatus.filter(
			value => value.value === parseInt(key)
		);
		return selectedStatus && selectedStatus.length > 0 ? selectedStatus[0].name : "--";
	};

	useEffect(() => {
		setUserDsa(user.user_type === "DSA" ? false : true);
	}, [user]);

	const reloadData = () => {
		setPageLoading(true);
		getRcPendingApplications({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
		});
	};
	const handleStatusField = (reload = false) => {
		setShowCurrent("1");
		if (reload) {
			setPageLoading(true);
			getRcPendingApplications({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
			});
		}
	};
	const columns = [
		{
			name: (
				<NameComp
					title={"Application Number"}
					id={"application_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.application_number ? row.application_number : "--"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => setLoanDetails(row.loan_app_enc_id)}
					>
						{row.application_number ? row.application_number : "--"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.branch ? row.branch : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Employee Name"}
					id={"employee_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
				/>
			),
			minWidth: "160px",
			selector: row => (row.employee_name ? row.employee_name : "-"),
			sortable: false,
			omit: userDsa
		},
		{
			name: (
				<NameComp
					title={"Applicant Name"}
					id={"applicant_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => setLoanDetails(row.loan_app_enc_id)}
					>
						{row.applicant_name}
					</h6>
				);
			},
			selector: row => row.applicant_name,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Loan Product"}
					id={"loan_product_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanProducts={loan_products}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.loan_product ? row.loan_product : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Loan Purpose"}
					id={"purpose"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanProducts={loan_products}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "280px",
			selector: row => (row.loanPurposes ? row.loanPurposes : "-"),
			sortable: false,
			cell: row => {
				return (
					<div className="list-data d-inline-block">
						{row.loanPurposes && row.loanPurposes.length
							? row.loanPurposes.map((value, index) => {
								return (
									<span
										key={`purposes-${row.loan_app_enc_id}${index}`}
									>
										{value.purpose}
									</span>
								);
							})
							: "-"}
					</div>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Loan Applied"}
					id={"amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			cell: row => {
				return (
					<h6 className="mb-0 emiAmt">
						<BiRupee size={16} /> {parseFloat(row.amount).toLocaleString("en-IN")}
					</h6>
				);
			},
			selector: row => row.amount,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Dealer Name"}
					id={"dealer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize"> {row.dealer_name ? row.dealer_name : "-"} </h6>
				);
			},
			selector: row => row.dealer_name,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={`${user.user_type === "DSA" ? "Lenders and" : ""} Status`}
					id={"status_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					loanStatus={financer_loan_status}
				/>
			),
			minWidth: "200px",
			cell: row => {
				return (
					<div>
						{row.assignedLoanProviders && row.assignedLoanProviders.length
							? row.assignedLoanProviders.map((value, index) => {
								return (
									<>
										{statusToggle &&
											showCurrent === value.assigned_loan_provider_enc_id ? (
												<div className="d-flex align-items-center">
													<UpdateLoanStatus
														key={`ap-${index}`}
														allStatus={loanStatusData.loanStatus}
														loanType={
															row.loan_product ? row.loan_product : ""
														}
														defaultStatus={value.status}
														loanId={row.loan_app_enc_id}
														label={false}
														callback={handleStatusField}
														completeLoanDetails={row}
													/>
													<FaTimes
														style={{
															color: "red",
															cursor: "pointer",
															margin: "0 5px"
														}}
														size={14}
														onClick={() => {
															setShowCurrent("");
															setStatusToggle(false);
														}}
													/>
												</div>
											) : (
												<h6
													key={`ap-${index}`}
													onDoubleClick={() => {
														setShowCurrent(
															value.assigned_loan_provider_enc_id
														);
														setStatusToggle(true);
													}}
												>
													{user.user_type === "DSA"
														? value.providerEnc.name
														: ""}
													<span
														className={`status-color status-color-${value.status}`}
													>
														{user.user_type === "DSA"
															? `(${getStatus(value.status)})`
															: `${getStatus(value.status)}`}
													</span>
												</h6>
											)}
									</>
								);
							})
							: "N/A"}
					</div>
				);
			},
			selector: row => {
				return row.assignedLoanProviders && row.assignedLoanProviders.length
					? row.assignedLoanProviders.map(value => {
						return `${getStatus(value.status)}`;
					})
					: "";
			},
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursement Date"}
					id={"disbursement_date_range"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => row.disbursement_date ? moment(row.disbursement_date).valueOf() : 0,
			sortable: false,
			cell: row => row.disbursement_date ? moment(row.disbursement_date).format("DD MMM YYYY") : "--",
		},
		{
			name: (
				<MinMaxFilter
					title={"BDO Approved Amount"}
					id={"bdo_approved_amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.bdo_approved_amount,
			sortable: false,
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.bdo_approved_amount ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.bdo_approved_amount).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.bdo_approved_amount ? row.bdo_approved_amount : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"bdo_approved_amount"}
							asAmount={true}
							validateAsNumber={true}
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"TL Approved Amount"}
					id={"tl_approved_amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.tl_approved_amount,
			sortable: false,
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.tl_approved_amount ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.tl_approved_amount).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.tl_approved_amount ? row.tl_approved_amount : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"tl_approved_amount"}
							asAmount={true}
							validateAsNumber={true}
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"TVR / Soft Approval"}
					id={"soft_approval"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.soft_approval,
			sortable: false,
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.soft_approval ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.soft_approval).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.soft_approval ? row.soft_approval : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"soft_approval"}
							asAmount={true}
							validateAsNumber={true}
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Conditional / Soft Sanction"}
					id={"soft_sanction"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => row.soft_sanction,
			sortable: false,
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.soft_sanction ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.soft_sanction).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.soft_sanction ? row.soft_sanction : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"soft_sanction"}
							asAmount={true}
							validateAsNumber={true}
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Valuation"}
					id={"valuation"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => row.valuation,
			sortable: false,
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.valuation ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.valuation).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.valuation ? row.valuation : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"valuation"}
							asAmount={true}
							validateAsNumber={true}
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursement Approval"}
					id={"disbursement_approved"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.disbursement_approved,
			sortable: false,
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.disbursement_approved ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.disbursement_approved).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.disbursement_approved ? row.disbursement_approved : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"disbursement_approved"}
							asAmount={true}
							validateAsNumber={true}
						/>
					);
			}
		}, // {
		//     name: 'Trench Amount',
		//     minWidth: '160px',
		//     selector: row => row.amount,
		//     sortable: true,
		// },
		{
			name: (
				<MinMaxFilter
					title={"Application Date"}
					id={"application_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => moment(row.apply_date).valueOf(),
			sortable: false,
			cell: row => moment(row.apply_date).format("DD MMM YYYY h:mm a")
		},
		{
			name: (
				<NameComp
					title={"Prize Won"}
					id={"claimedDeals"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			sortable: true,
			omit: formType !== "diwali-dhamaka",
			cell: row => {
				const labelObj = {
					OVEN: "Oven",
					POWERBANK: "Power Bank",
					BAG: "Bag",
					WASHINGMACHINE: "Washing Machine",
					LED: "LED",
					HEADPHONES: "Headphones"
				};
				return (
					<>
						{row.claimedDeals && row.claimedDeals.length > 0 ? (
							<h6 className="mb-0 emiAmt">
								{labelObj[row.claimedDeals[0].claimed_coupon_code]}
							</h6>
						) : (
							""
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Created By"}
					id={"created_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => row.creator_name,
			style: { padding: "0px" },
			cell: row => {
				return (
					<div
						style={{
							backgroundColor: row.is_self == "1" ? "#ff7d0db0" : "",
							fontWeight: 500,
							width: "100%",
							display: "flex",
							alignItems: "center",
							height: "100%"
						}}
					>
						{row.creator_name}
					</div>
				);
			}
		},
	];

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
		setPageLoading(true);
	};

	const handlePerPage = e => {
		setPageLoading(true);
		getRcPendingApplications({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const handlePagination = page => {
		setPageLoading(true);
		getRcPendingApplications({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(rcPendingApplications.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		if (
			rcPendingApplications &&
			rcPendingApplications?.loans?.length > 0
		) {
			return rcPendingApplications.loans;
		} else {
			return rcPendingApplications &&
                rcPendingApplications?.loans?.length > 0
				? rcPendingApplications.loans.slice(0, rowsPerPage)
				: [];
		}
	};

	useEffect(() => {
		if (searchArray) {
			const timerId = setTimeout(() => {
				setClearFilters(false);
				setCurrentPage(1);
				setPageLoading(true);
				getRcPendingApplications({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
				});
			}, [1000]);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setShowClearBtn(true);
			}
			
			return () => {
				clearTimeout(timerId);
			};
		}
		
	}, [searchArray]);

	useEffect(() =>{
		if(rcPendingApplications){
			setPageLoading(false);
		}
	},[rcPendingApplications]);


	useEffect(() => {
		if (!branches_list) {
			getBranches();
		}
		if(!financer_loan_status){
			getFinancerLoanStatusList();
		}
	}, []);
	return (
		<>
			<div className="loan-application-table">
				<div className="d-flex justify-content-between align-items-center table-heading-line mb-2">
					<h2 className="m-0 text-capitalize mx-3">RC Pending Applications</h2>
					<Row className="mx-0 mt-1 mb-50">
						<Col sm="6">
							<div className="d-flex align-items-center">
								<label htmlFor="sort-select">Show</label>
								<select
									className="dataTable-select form-select"
									style={{
										width: "60px",
										padding: "2px 8px",
										margin: "0px 10px"
									}}
									id="sort-select"
									value={rowsPerPage}
									onChange={e => handlePerPage(e)}
								>
									<option value={10}>10</option>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={75}>75</option>
									<option value={100}>100</option>
								</select>
								<label htmlFor="sort-select">Results</label>
							</div>
						</Col>
					</Row>
				</div>
				<div className="filters-container d-flex justify-content-end">
					{showClearBtn ? (
						<button
							className="btn btn-primary btn-xs my-2"
							onClick={e => clearFilter(e)}
						>
							Reset
						</button>
					) : (
						""
					)}
				</div>
				<hr />
				<DataTable
					noHeader
					pagination
					paginationServer
					columns={columns}
					data={dataToRender()}
					paginationComponent={CustomPagination}
					persistTableHead={true}
					// actions={actionsMemo}
					progressPending={pageLoading}
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={<p className="w-100 m-5 text-start">There are no records to display</p>}
					highlightOnHover="true"
					className="loan-app-table-container fix-overflow"
				/>
			</div>
			<div ref={ref}>
				<OrganizationSidebarProvider>
					<LoanApplicationDetails
						handleCloseSideBar={() => setLoanDetails("")}
						loanId={loanDetails}
						params={{
							page: currentPage ? currentPage : "",
							limit: rowsPerPage ? rowsPerPage : "",
							fields_search: searchArray ? searchArray : {}
						}}
						setTableLoading={pageLoading}
					/>
				</OrganizationSidebarProvider>
			</div>
		</>
	);
};

export default RcPendingCases;
