import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import { useContext, useEffect} from "react";
import { MainContext } from "../../context/MainState";
import { useNavigate } from "react-router-dom";
import { OrganizationUsersProvider } from "../../context/OrganizationUsersState";
import DealersTable from "../../components/dashboard/DealersTable";

const DealersList = () => {
	const { user } = useContext(MainContext);
	const history = useNavigate();
	const accessToDealers = ["pushapduggal", "Satparkash", "Phf24", "PHF310", "Kanchan", "muskan164", "Harleenkaur", "phf113", "gursewak122", "simran517", "phf672", "SHAHRUKH", "phf110", "ghuman"];

	useEffect(() => {
		if (user && user.user_type !== "Financer" && !accessToDealers.includes(user.username)) {
			history("/");
		}
	}, [user]);
	return (
		<div>
			<Helmet>
				<title>Dealers | {process.env.REACT_APP_NAME}</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<OrganizationUsersProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						<div className="shadow-lg rounded-3 py-4 px-6">
							<div className={"d-flex justify-content-between mb-3"}>
								<h2 className="mb-0">Dealers List</h2>
							</div>
							<DealersTable />
						</div>
					</div>
				</div>
			</OrganizationUsersProvider>
		</div>
	);
};
export default DealersList;
