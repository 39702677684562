import { ErrorMessage } from "@hookform/error-message";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Datepicker from "react-datepicker";
import { useEffect, useState } from "react";
import Compressor from "compressorjs";
import { MdRemoveCircle } from "react-icons/md";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { IoCloseCircle  } from "react-icons/io5";

const RepossessionModal = ({ show, onHide, loan_account_id, loanType }) => {
	const {
		register,	
		control,
		handleSubmit,
		watch,
		reset,
		setError,
		setValue,
		formState: { errors }
	} = useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedImage, setSelectedImage] = useState({front:[], back: [], left: [], right:[]});
	const [rcImage, setRcImage] = useState();
	const [allVehicleList, setAllVehicleList] = useState([]);
	const [currentSelection, setCurrentSelection] = useState("");
	const numberValidateRegex = /^[0-9]\d*$/;


	const rc = watch("rc");
	const imageChange = async (e, side) => {
		if (e.target.files && e.target.files.length > 0) {
			let data = [];
			Object.values(e.target.files).forEach(async (element) => {
				let tempImg = await compressImg(element);
				data.push(tempImg);
				if(side === "rc_image"){
					setRcImage(data);
					setError("rc_image", "");
				}else{
					setSelectedImage({...selectedImage, [side]:[...data]});
				}
			});
		}
	};
	const compressImg = async (imageSelected) => {
		try {
			const compressedImage = await new Promise((resolve, reject) => {
				new Compressor(imageSelected, {
					quality: 0.6,
					maxWidth: 1000,
					maxHeight: 1000,
					success: res => {
						resolve(res);
					},
					error: err => {
						reject(err);
					}
				});
			});
			return compressedImage;
		} catch (error) {
			console.error("Compression error:", error);
			// Handle the error as needed
		}
	};

	const removeSelectedImage = (index, side) => {
		let allImgs = {...selectedImage};
		allImgs[side].splice(index, 1);
		if(allImgs[side].length == 0){
			setValue(side, "");
		}
		setSelectedImage(allImgs);
	};
	const removeRcImage = () => {
		setRcImage("");
		setValue("rc_image", "");
	};
	const hide = () => {
		reset();
		setSelectedImage("");
		setRcImage([]);
		onHide();
	};

	const getVehicleBrand = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-financer-vehicle-brand`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setAllVehicleList(response.data.response.financer_list);
				} else {
					setAllVehicleList([]);
				}
			})
			.catch(error => {
				// handlePageLoaded(false);
				console.error("Error in axios call:", error);
				toast.error(
					"An error has occurred while getting Vehicle Brands, Please try again"
				);
			});
	};
	const onSubmit = async (values) => {
		setIsLoading(true);
		if(rc === "yes" && !rcImage){
			setError("rc_image", "This field is required");
			setIsLoading(false);
			return false;
		}
		let formdata = new FormData();
		Object.entries(values).map(([key, value]) => {
			if(key === "repossession_date"){
				formdata.append(key, moment(value).format("YYYY-MM-DD"));
			}else if(key === "vehicle_model"){
				formdata.append(key, moment(value).format("YYYY"));
			}else{
				formdata.append(key, value);
			};
		});
		if(rcImage){
			formdata.delete("rc_image");
			formdata.append("rc_image", rcImage[0]);
		}
		formdata.append("financer_vehicle_brand_enc_id", currentSelection[0].financer_vehicle_brand_enc_id);
		Object.entries(selectedImage).map(([key,value]) => {
			formdata.delete(key);
			value.map((val, i) => {
				formdata.append(key + "[" + i + "]", val);
			});
		});
		formdata.append("loan_account_enc_id", loan_account_id);

		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/vehicle-repossession`, formdata)
			.then(response => {
				setIsLoading(false);
				if(response.data.response.status === 200){
					toast.success("Request Submited Successfully");
					reset();
					setSelectedImage("");
					setRcImage("");
					onHide();
				}else{
					toast.error("An error occurred while submitting the Data");
				}
			}).catch(err => {
				toast.error("Error Occured", err);
			});
	};

	useEffect(() => {
		if(allVehicleList.length <= 0 && show){
			getVehicleBrand();
		}
	}, [show]);
	return (
		<>
			<Modal show={show} onHide={hide} size="lg">
				<Modal.Header>
					<Modal.Title>Repossession</Modal.Title><span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="vehicle_model">
									Vehicle Model
								</label>
								<Controller
									name="vehicle_model"
									control={control}
									render={({field: {onBlur, value, onChange, ref}}) => (
										<div className="vehicle-datepicker">
											<Datepicker
												selected={value}
												showYearPicker
												dateFormat={"yyyy"}
												className={"form-control w-100"}
												isClearable={true}
												onBlur={onBlur}
												maxDate={new Date()}
												inputRef={ref}
												placeholderText="Select Model"
												onChange={onChange}
											/>
										</div>
									)}
									rules={{ required: true }}
								/>
								{errors.vehicle_model ? (
									<p style={{ color: "red", fontSize: "13px" }}>This Field is required</p>
								) : ""}
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="vehicle_model">
									Vehicle Brand
								</label>
								<Typeahead
									className="typeheadStyle"
									options={allVehicleList}
									labelKey="brand_name"
									id="brand_name"
									onChange={setCurrentSelection}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="model">
									KM Driven
								</label>
								<input
									placeholder="Enter KM Driven"
									className="form-control"
									{...register("km_driven", {
										required: "This is Required",
										pattern: {
											value: numberValidateRegex,
											message: "Please enter numeric only"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="km_driven"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="model">
									Insurance
								</label>
								<div className="radio-wrapper hero-radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="insurance"
											className="radioInput"
											id="option-1"
											value="yes"
											{...register("insurance", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-1" className="option option-1">
											<div className="dot"></div>
											<span>Yes</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="insurance"
											className="radioInput"
											id="option-6"
											value="no"
											{...register("insurance", {
												required: "This is required."
											})}
										/>
										<label htmlFor="option-6" className="option option-6">
											<div className="dot"></div>
											<span>No</span>
										</label>
									</div>
								</div>
								<ErrorMessage
									errors={errors}
									name="insurance"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="model">
									Current Market Value
								</label>
								<input
									placeholder="Enter Current Value"
									className="form-control"
									{...register("current_value", {
										required: "This is Required",
										pattern: {
											value: numberValidateRegex,
											message: "Please enter numeric only"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="current_value"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label">Repossession Date</label>
								<Controller
									name="repossession_date"
									control={control}
									render={({field: {onBlur, value, onChange, ref}}) => (
										<div className="repo-datepicker">
											<Datepicker
												selected={value}
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												className={"form-control w-100"}
												isClearable={true}
												onBlur={onBlur}
												maxDate={new Date()}
												inputRef={ref}
												placeholderText="Select Date"
												onChange={onChange}
											/>
										</div>
									)}
									rules={{ required: true }}
								/>
								{errors.repossession_date ? (
									<p style={{ color: "red", fontSize: "13px" }}>This Field is required</p>
								) : ""}
							</div>
							<div className="mb-4 col-md-6">
								<label className="form-label" htmlFor="model">
									RC
								</label>
								<div className="radio-wrapper hero-radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="rc"
											className="radioInput"
											id="rc-1"
											value="yes"
											{...register("rc", {
												required: "This is required."
											})}
										/>
										<label htmlFor="rc-1" className="option option-1">
											<div className="dot"></div>
											<span>Yes</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="rc"
											className="radioInput"
											id="rc-6"
											value="no"
											{...register("rc", {
												required: "This is required."
											})}
										/>
										<label htmlFor="rc-6" className="option option-6">
											<div className="dot"></div>
											<span>No</span>
										</label>
									</div>
								</div>
								<ErrorMessage
									errors={errors}
									name="rc"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							
							{rc === "yes" ? (
								<>
									<div className="col-md-6">
										<div
											className="shadow-lg user-document1 p-3 "
											style={{ position: "relative" }}
										>
											<h3 className="mb-2">RC Image</h3>
											<div className="upload-document">
												<input
													type="file"
													min={1}
													accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
													placeholder="Choose File"
													className="form-control"
													name="rc"
													{...register("rc_image", {
														required: "This is required."
													})}
													capture="environment"
													onChange={(e) => imageChange(e,"rc_image")}
												/>
											</div>
											<ErrorMessage
												errors={errors}
												name="rc_image"
												render={({ message }) => (
													<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
												)}
											/>
										</div>
										<div className="d-flex flex-wrap mb-2">
											{rcImage && rcImage.length ? rcImage.map((value, index) => {
												return(
													<div className="imageUploaded-main uploaded-vehicle-img repo-img me-4 mb-4" key={`img-${index}`}>
														<label
															className="form-label"
															htmlFor="change_image"
														>
															<MdRemoveCircle
																className="delete-image"
																onClick={() => removeRcImage(index)}
															/>
														</label>
														<img
															src={URL.createObjectURL(value)}
															alt="Thumb"
														/>
													</div>
												);
											}
														
											) : ""}
										</div>
									</div>
								</>
							) : ""}
							
							<div className="mb-4 col-md-12">
								<label className="form-label" htmlFor="model">
									Upload Images
								</label>
								<div className="row">
									<div className="col-md-9 mb-2">
										<div className="d-flex">
											<div className="shadow-lg user-document1 p-3" style={{ position: "relative" }}>
												<h3 className="mb-2">Front Side</h3>
												<div className="upload-document">
													<input
														type="file"
														multiple={true}
														min={1}
														accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
														placeholder="Choose File"
														className="form-control"
														name="front"
														{...register("front", {
															required: "This is required."
														})}
														capture="environment"
														onChange={(e) => imageChange(e,"front")}
													/>
												</div>	
											</div>
											<div className="shadow-lg user-document1 p-3 ms-2 smp-img">
												{loanType === "TWO WHEELER" ? (
													<img
														src="/images/pages/dashboard/icon-scooty-front.png"
														alt="Thumb"
													/>
												) : (
													<img
														src="/images/pages/dashboard/evehicle-front.png"
														alt="Thumb"
													/>
												)}	
											</div>
										</div>	
										<div className="d-flex flex-wrap mb-2">
											{selectedImage.front && selectedImage.front.length ? selectedImage.front.map((value, index) => {
												return(
													<div className="imageUploaded-main uploaded-vehicle-img repo-img me-4 mb-4" key={`img-${index}`}>
														<label
															className="form-label"
															htmlFor="change_image"
														>
															<MdRemoveCircle
																className="delete-image"
																onClick={() => removeSelectedImage(index, "front")}
															/>
														</label>
														<img
															src={URL.createObjectURL(value)}
															alt="Thumb"
														/>
													</div>
												);
											}
												
											) : ""}
										</div>	
									</div>

									<div className="col-md-9 mb-2">
										<div className="d-flex">
											<div className="shadow-lg user-document1 p-3" style={{ position: "relative" }}>
												<h3 className="mb-2">Back Side</h3>
												<div className="upload-document">
													<input
														type="file"
														multiple={true}
														min={1}
														accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
														placeholder="Choose File"
														className="form-control"
														name="back"
														{...register("back", {
															required: "This is required."
														})}
														capture="environment"
														onChange={(e) => imageChange(e,"back")}
													/>
												</div>
											</div>
											<div className="shadow-lg user-document1 p-3 ms-2 smp-img">
												{loanType === "TWO WHEELER" ? (
													<img
														src="/images/pages/dashboard/icon-scooty-back.png"
														alt="Thumb"
													/>
												) : (
													<img
														src="/images/pages/dashboard/e-back.png"
														alt="Thumb"
													/>
												)}
											</div>
										</div>
										<div className="d-flex flex-wrap mb-2">
											{selectedImage.back && selectedImage.back.length ? selectedImage.back.map((value, index) => {
												return(
													<div className="imageUploaded-main uploaded-vehicle-img repo-img me-4 mb-4" key={`img-${index}`}>
														<label
															className="form-label"
															htmlFor="change_image"
														>
															<MdRemoveCircle
																className="delete-image"
																onClick={() => removeSelectedImage(index, "back")}
															/>
														</label>
														<img
															src={URL.createObjectURL(value)}
															alt="Thumb"
														/>
													</div>
												);
											}
												
											) : ""}
										</div>
									</div>

									<div className="col-md-9 mb-2">
										<div className="d-flex">
											<div className="shadow-lg user-document1 p-3" style={{ position: "relative" }}>
												<h3 className="mb-2">Left Side</h3>
												<div className="upload-document">
													<input
														type="file"
														multiple={true}
														min={1}
														accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
														placeholder="Choose File"
														className="form-control"
														name="left"
														{...register("left", {
															required: "This is required."
														})}
														capture="environment"
														onChange={(e) => imageChange(e,"left")}
													/>
												</div>
											</div>
											<div className="shadow-lg user-document1 p-3 ms-2 smp-img">
												{loanType === "TWO WHEELER" ? (
													<img
														src="/images/pages/dashboard/icon-scooty-left.png"
														alt="Thumb"
													/>
												) : (
													<img
														src="/images/pages/dashboard/e-left.png"
														alt="Thumb"
													/>
												)}
											</div>
										</div>
										<div className="d-flex flex-wrap mb-2">
											{selectedImage.left && selectedImage.left.length ? selectedImage.left.map((value, index) => {
												return(
													<div className="imageUploaded-main uploaded-vehicle-img repo-img me-4 mb-4" key={`img-${index}`}>
														<label
															className="form-label"
															htmlFor="change_image"
														>
															<MdRemoveCircle
																className="delete-image"
																onClick={() => removeSelectedImage(index, "left")}
															/>
														</label>
														<img
															src={URL.createObjectURL(value)}
															alt="Thumb"
														/>
													</div>
												);
											}
												
											) : ""}
										</div>
									</div>
									<div className="col-md-9 mb-2">
										<div className="d-flex">
											<div className="shadow-lg user-document1 p-3" style={{ position: "relative" }}>
												<h3 className="mb-2">Right Side</h3>
												<div className="upload-document">
													<input
														type="file"
														multiple={true}
														min={1}
														accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
														placeholder="Choose File"
														className="form-control"
														{...register("right", {
															required: "This is required."
														})}
														capture="environment"
														onChange={(e) => imageChange(e,"right")}
														
													/>
												</div>
											</div>
											<div className="shadow-lg user-document1 p-3 ms-2 smp-img">
												{loanType === "TWO WHEELER" ? (
													<img
														src="/images/pages/dashboard/icon-scooty-right.png"
														alt="Thumb"
													/>
												) : (
													<img
														src="/images/pages/dashboard/e-right.png"
														alt="Thumb"
													/>
												)}
											</div>
										</div>
										<div className="d-flex flex-wrap mb-2">
											{selectedImage.right && selectedImage.right.length ? selectedImage.right.map((value, index) => {
												return(
													<div className="imageUploaded-main uploaded-vehicle-img repo-img me-4 mb-4" key={`img-${index}`}>
														<label
															className="form-label"
															htmlFor="change_image"
														>
															<MdRemoveCircle
																className="delete-image"
																onClick={() => removeSelectedImage(index, "right")}
															/>
														</label>
														<img
															src={URL.createObjectURL(value)}
															alt="Thumb"
														/>
													</div>
												);
											}
												
											) : ""}
										</div>
									</div>
									<div className="col-md-12 text-center">
										{isLoading  ? (
											<button
												type="button"
												id="Submit"
												name="Submit"
												className="btn btn-primary"
												disabled={true}
											>
												<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
											</button>
										) : (
											<button type="submit" className="btn btn-primary">Save</button>
										)
										}
									</div>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default RepossessionModal;
