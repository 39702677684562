import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";



const EnachLogs = ({enachLogsData}) => {
	const statusColorObj = {
		Active: "success",
		Failed: "danger",
		Issued: "warning",
		Inactive: "danger",
		Pending: "secondary",
		Cancelled: "danger",
	};

	return (
		<div>
			<div className="audit-trail-main ">
				{enachLogsData && enachLogsData.length ?enachLogsData.map((value, index) => {
					return (
						<>
							<div className="audit-trail-list" key={`loan-payments-${index}`}>
								<div className="audit-msg " >
									<p style={{width: "30%"}}>
										<img
											src={value.image}
											alt={value.created_by}
											className="employer-img"
										/>
										<span className="audit-text-color">	
											{value.created_by}
										</span>
									</p>

									<p className="d-flex align-items-center flex-wrap text-break " style={{width: "100%"}}>
										<span className="chngd" style={{color: "#dc3545"}}>
											Update eNACH Status
										</span>
										<span className="trail-status">
											<Badge
												className="text-capitalize mx-2 py-1 text-white"
												bg={statusColorObj[value.enach_status]}
												pill
											>
												{value.enach_status}
											</Badge>
										</span>
										{value?.url ? <BsArrowRight /> : ""}
										<span className="trail-status audit-text-color">
											<a href={value.url} target="_blank" rel="noopener noreferrer">
												{value.enach_status=== "Active"? "":value?.url}
											</a>
										</span>
									</p>
								</div>
								<div className="audit-msg-date ">
									<p>
										{value?.created_on
											? moment(value.created_on).format("DD MMM YYYY h:mm a")
											: " "}
								  </p>
								</div>
							</div>
							{value?.error_description ? (
								<div className="d-flex px-3">
									<p className="mb-0 fs-5">Remarks:</p>
									<span className="trail-status audit-text-color fs-5">
										{value?.error_description ? value.error_description:" "}
									</span>
								</div>
							) : ("")}
						</>
					);
				}) : (
					""
				)}
				
			</div>
		</div>
	);
};

export default EnachLogs;

