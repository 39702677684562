// import firebase from 'firebase';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";

const fireBaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(fireBaseConfig);
const auth = firebase.auth();
let messaging = null;
if (firebase.messaging.isSupported()) {
	messaging = getMessaging();
}
export { auth, firebase, messaging };

export const askForPermissionToReceiveNotifications = async () => {
	try {
		if (firebase.messaging.isSupported()) {
			await Notification.requestPermission().then(permission => {
				if (permission === "granted") {
					getToken(messaging, {
						vapidKey: `${process.env.REACT_APP_FIREBASE_VAP_ID_KEY}`
					})
						.then(currentToken => {
							if (currentToken) {
								const notification_token =
									localStorage.getItem("push_notification");
								const loged_in_user = localStorage.getItem("user");
								if (notification_token !== currentToken && loged_in_user) {
									axios
										.post(
											`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}notifications/save-token`,
											{ token: currentToken }
										)
										.then(() => {
											localStorage.setItem("push_notification", currentToken);
										});
								}
							}
						})
						.catch(err => {
							console.log("Error Occurred", err);
						});
				}
			});
		}
	} catch (error) {
		console.error(error);
	}
};

// export function fireBaseConfig(){
//     return {
//         apiKey: 'AIzaSyDRQXJJP1rOOh8omclQ7146ME-oL9tNDAg',
//         authDomain: 'empoweryouth-49c18.firebaseapp.com',
//         databaseURL: 'https://empoweryouth-49c18.firebaseio.com',
//         projectId: 'empoweryouth-49c18',
//         storageBucket: 'empoweryouth-49c18.appspot.com',
//         messagingSenderId: '173074095977',
//     };
// }
