import React, { useContext, useEffect } from "react";
import { CandidateDashboardContext } from "../../context/CandidateDashboardState";
import { toast } from "react-toastify";
import moment from "moment";

const MyRewardsList = () => {
	const { getMyRewards, my_rewards } = useContext(CandidateDashboardContext);

	const copyToClipboard = link => {
		navigator.clipboard.writeText(link);
		toast.success("Copied to clipboard");
	};

	useEffect(() => {
		getMyRewards();
	}, []);
	return (
		<div className="p-4 nd-shadow bg-white">
			<div className="row">
				{my_rewards && my_rewards.length ? (
					my_rewards.map((data, index) => {
						return (
							<div className="col-md-6" key={`my_rewards-${index}`}>
								<div className={`coupon-card ${data.deal_type}`}>
									<div className="rectangle">
										<p>{data.deal_type}</p>
									</div>
									<img src={data.logo} className="logo" alt={data.title} />
									<h3>{data.title}</h3>

									<div className="coupon-row">
										<span className="cpncode">{data.claimed_coupon_code}</span>
										<span
											className="cpnbtn"
											onClick={() =>
												copyToClipboard(data.claimed_coupon_code)
											}
										>
											COPY CODE
										</span>
									</div>

									<p>
										Valid till: {moment(data.expiry_date).format("DD MMM YYYY")}
									</p>

									<div className="circle1"></div>
									<div className="circle2"></div>
								</div>
							</div>
						);
					})
				) : (
					<div className="col-md-12">
						<div className="main-error-page nd-shadow rounded-3 py-5">
							<img
								src="/images/background/notification.png"
								alt="Error"
								className="img-fluid"
							/>
							<h1 className="error-title">No Claimed offers found</h1>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default MyRewardsList;
