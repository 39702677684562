import { useForm } from "react-hook-form";
import AutoComplete from "../forms/AutoComplete";
import { Col, Modal } from "react-bootstrap";
import { IoCloseCircle } from "react-icons/io5";
// import { useContext } from "react";
// import { MainContext } from "../../context/MainState";

const BdoSearch = ({ show, onHide}) => {
	const { setValue } = useForm();
	// const { user } = useContext(MainContext);

	const hide = () => {
		onHide();
	};

	return(
		<Modal
			size={"md"}
			show={show}
			onHide={hide}
		>
			<Modal.Header>
				<Modal.Title>
                    Assign BDO
				</Modal.Title><span className="close-icon" onClick={()=>onHide()}><IoCloseCircle /></span>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className="col-sm-12 col-12">
						<div className="mb-3">
							<div className="w-100">
								<AutoComplete
									setFieldValue={setValue}
									link={"company-dashboard/employee-search?type=bdo&employee_search="}
									name={"value"}
									method={"GET"}
									placeholder={"Enter Username"}
								/>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-12">
						<Col size={12} className="text-center mt-2">
							<button type="submit" className="btn btn-primary">
                                Submit
							</button>
						</Col>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};
export default BdoSearch;