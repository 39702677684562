import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";

const CollectionManagerModal = ({sharedModal, setSharedModal, reloadDetails}) => {
	const {user, handlePageLoaded } = useContext(MainContext);
	const [sharedUsers, setSharedUsers] = useState([]);
	const handleAccessChange = async (val, id) => {

		if (val === "delete" && !window.confirm("Are you sure you want to remove the access?")) {
			return;
		}

		let data = {};
		data["assigned_enc_id"] = id;
		if (val === "delete") {
			data["delete"] = true;
		} else {
			data["access"] = val;
		}

		handlePageLoaded(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/update-loan-acc-access`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					setSharedModal(false);
					reloadDetails();
					toast.success("Saved Successfully");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
				console.log(error);
			});
	};

	useEffect(() => {
		setSharedUsers(sharedModal?.assignedLoanViews ? sharedModal?.assignedLoanViews : sharedModal?.assignedLoanAccounts ? sharedModal?.assignedLoanAccounts: []);
	}, [sharedModal]);

	return (
		<>
			<Modal show={sharedModal} onHide={() => setSharedModal(false)} size={"md"} centered>
				<div className="container position-relative">
					<div className="row">
						<div className="col-md-12">
							<div className="bg-white rounded-3 py-5 px-3">
								{sharedModal && sharedModal.hide !== "BDO" &&
								sharedUsers.filter(
									name => name.user_type === "bdo"
								).length > 0 ? (
										<div className="imageList">
											<h4>BDO List</h4>
											<ul className="usersAccessList p-0">
												{sharedModal &&
													sharedUsers.filter(name => name.user_type === "bdo").map((value, index) => {
														return (
															<>
																<li
																	key={`usersList-${index}`}
																	className="d-flex align-items-center mb-3"
																>
																	<div className="userAvatar">
																		<img src={value.image} />
																	</div>
																	<div className="userDetails">
																		<h4 className="m-0">
																			{value.name}
																		</h4>
																	</div>
																	<div>
																		{user.user_type === "Financer" || ["ghuman", "phf110", "phf1164"].includes(user.username) ? (
																			<button
																				type="button"
																				onClick={() =>
																					handleAccessChange(
																						"delete",
																						value.assigned_enc_id
																					)
																				}
																				className="btn btn-primary btn-xs"
																			>
																				<MdDelete
																					fontSize={15}
																				/>
																			</button>
																		) : ""}
																	</div>
																</li>
															</>
														);
													})}
											</ul>
										</div>
									) : (
										""
									)}


								{sharedModal && sharedModal.hide !== "CM" &&
								sharedUsers.filter(
									name => name.user_type === "collection_manager"
								).length > 0 ? (
										<>
											<div className="imageList">
												<h4>Collection Manager List</h4>
												<ul className="usersAccessList p-0">
													{sharedModal &&
														sharedUsers.filter(
															name =>
																name.user_type ===
																"collection_manager"
														).map((value, index) => {
															return (
																<>
																	<li
																		key={`usersList-${index}`}
																		className="d-flex align-items-center mb-3"
																	>
																		<div className="userAvatar">
																			<img
																				src={value.image}
																			/>
																		</div>
																		<div className="userDetails">
																			<h4 className="m-0">
																				{value.name}
																			</h4>
																		</div>
																		<div>
																			{user.user_type === "Financer" || ["ghuman", "phf110", "phf1164"].includes(user.username) ? (
																				<button
																					type="button"
																					onClick={() =>
																						handleAccessChange(
																							"delete",
																							value.assigned_enc_id
																						)
																					}
																					className="btn btn-primary btn-xs"
																				>
																					<MdDelete
																						fontSize={15}
																					/>
																				</button>
																			) : ""}
																		</div>
																	</li>
																</>
															);
														})}
												</ul>
											</div>
										</>
									) : (
										""
									)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default CollectionManagerModal;
