import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import Datepicker from "react-datepicker";
import moment from "moment";
import { BsFillCameraFill } from "react-icons/bs";
import { FaUpload } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const PaymentRequestForm = ({ show, onHide, loanDetails, reload }) => {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors }
	} = useForm();

	const [locImage, setLocImage] = useState({ image: "", name: "" });
	const [isLoading, setIsLoading] = useState(false);
	const banks = [
		"ICIC",
		"Axis",
		"HDFC",
		"IDFC",
		"Capital",
		"PNB",
		"SBI",
		"AU Small Finance Bank",
		"Punjab & Sind",
		"Indusind",
		"Indian Bank",
		"Indian Overseas Bank",
		"Bank Of Baroda",
		"Others"
	];

	let transaction_type = watch("type_of_transaction");
	let payment_to = watch("payment_to");
	let otherBank = watch("bank_name");

	const [selectedVal, setSelectedVal] = useState("~");

	const onsubmit = async values => {
		setIsLoading(true);
		let formData = new FormData();
		if (values.payment_to === "Others") {
			values.payment_to = values.payment_to_input;
		}
		if (values.bank_name === "Others") {
			values.bank_name = values.others_bank;
		}
		if (values.type_of_transaction === "Others") {
			values.type_of_transaction = values.type_of_transaction_input;
		}

		if (values.name || values.bank_name || values.ifsc_code || values.bank_account_number) {
			if (
				!values.name ||
				!values.bank_name ||
				!values.ifsc_code ||
				!values.bank_account_number
			) {
				toast.error("Please Enter All Bank Details");
				setIsLoading(false);
				return false;
			}
		}

		Object.entries(values).map(([key, value]) => {
			formData.append(key, value);
		});
		formData.append("date_of_transfer", moment(selectedVal).format("YYYY-MM-DD"));
		formData.append("loan_app_enc_id", loanDetails.loan_app_enc_id);
		formData.append("cheque_image", locImage.image);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/update-release-payment`,
				formData
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					reload(loanDetails.loan_app_enc_id);
					onHide();
				}
			});
	};
	const numberValidateRegex = /^[0-9]\d*$/;
	useEffect(() => {
		if (loanDetails) {
			setValue("applicant_name", loanDetails.applicant_name);
			setValue(
				"application_number",
				loanDetails.application_number ? loanDetails.application_number : ""
			);
		}
	}, [loanDetails]);
	return (
		<>
		
			<Modal show={show} onHide={onHide} size={"lg"} centered>
				<Modal.Header>
					<Modal.Title>Collection Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onsubmit)}>
						<div className="row">
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
									Borrowers Name <span style={{ color: "red" }}>*</span>
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("applicant_name", {
										required: "This is required."
									})}
									disabled={loanDetails.applicant_name ? "true" : ""}
								/>
								<ErrorMessage
									errors={errors}
									name="applicant_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
									Loan Account Number <span style={{ color: "red" }}>*</span>
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("application_number", {
										required: "This is required."
									})}
									disabled={loanDetails.application_number ? "true" : ""}
								/>
								<ErrorMessage
									errors={errors}
									name="application_number"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-6 mb-3 dateWidth">
								<label className="form-label mb-0" htmlFor="date_of_transfer">
									Date of Transfer<span style={{ color: "red" }}>*</span>
								</label>
								<div>
									<Datepicker
										selected={selectedVal !== "~" ? selectedVal : ""}
										onChange={date => setSelectedVal(date)}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										className={"form-control"}
										isClearable={true}
										minDate={moment().toDate()}
										dateFormat="dd MMM yyyy"
									/>
								</div>
								<ErrorMessage
									errors={errors}
									name="customer_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>

							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
									Total Amount to be Disbursed
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("total_amount_disbursed", {
										required: "This is required.",
										pattern: {
											value: numberValidateRegex,
											message: "Amount is invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="customer_name"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>

							<div className="col-lg-6 mb-3">
								<label
									className="form-label mb-0"
									htmlFor="previously_amount_disbursed"
								>
									Amount Previously Disbursed
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("previously_amount_disbursed", {
										pattern: {
											value: numberValidateRegex,
											message: "Amount is invalid"
										}
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="previously_disbursed_amount"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-lg-6 mb-3">
								<label className="form-label mb10">
									Type Of Transaction <span style={{ color: "red" }}>*</span>
								</label>
								<label className="radio-c">
									Initial Disbursement
									<input
										type="radio"
										value="Initial Disbursement"
										name="type_of_transaction"
										{...register("type_of_transaction", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<label className="radio-c">
									Tranche Release
									<input
										type="radio"
										value="Tranche Release"
										name="type_of_transaction"
										{...register("type_of_transaction", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<label className="radio-c">
									Others
									<input
										type="radio"
										value="Others"
										name="type_of_transaction"
										{...register("type_of_transaction", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<ErrorMessage
									errors={errors}
									name="type_of_transaction"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
								{transaction_type && transaction_type === "Others" ? (
									<input
										placeholder=""
										className="form-control"
										{...register("type_of_transaction_input")}
									/>
								) : (
									""
								)}
							</div>

							<div className="col-lg-6">
								<label className="form-label mb10">
									Mode of Payment <span style={{ color: "red" }}>*</span>
								</label>
								<label className="radio-c">
									Issue of Cheque
									<input
										type="radio"
										value="Issue of Cheque"
										name="payment_mode"
										{...register("mode_of_payment", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<label className="radio-c">
									Online Transfer
									<input
										type="radio"
										value="Online Transfer"
										name="payment_mode"
										{...register("mode_of_payment", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
							</div>

							<div className="col-lg-9">
								<label className="form-label mb10">
									Payment To Be Made to <span style={{ color: "red" }}>*</span>
								</label>
								<label className="radio-c">
									Customers Bank A/C
									<input
										type="radio"
										value="Customers Bank A/C"
										name="payment_to"
										{...register("payment_to", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<label className="radio-c">
									BT Payment
									<input
										type="radio"
										value="BT Payment"
										name="payment_to"
										{...register("payment_to", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<label className="radio-c">
									Payment To The Seller
									<input
										type="radio"
										value="Payment To The Seller"
										name="payment_to"
										{...register("payment_to", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<label className="radio-c">
									Payment to the third Party for repayment of Debt
									<input
										type="radio"
										value="Payment to the third Party for repayment of Debt"
										name="payment_to"
										{...register("payment_to", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>
								<label className="radio-c">
									Others
									<input
										type="radio"
										value="Others"
										name="payment_to"
										{...register("payment_to", {
											required: "This is required."
										})}
									/>
									<span className="radio-c-checkmark"></span>
								</label>

								{payment_to && payment_to === "Others" ? (
									<input
										placeholder=""
										className="form-control"
										{...register("payment_to_input")}
									/>
								) : (
									""
								)}
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-lg-12">
								<h3>Bank Details</h3>
							</div>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="name">
									Name Of Account Holder
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("name")}
								/>
								<ErrorMessage
									errors={errors}
									name="account_holder"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label className="form-label mb10">Bank Name</label>
								<div className="row">
									{banks && banks.length
										? banks.map((value, index) => {
											return (
												<div
													className="col-lg-4"
													key={`paymentReqFormField${index}`}
												>
													<label
														className="radio-c"
														key={`banks-${index}`}
													>
														{value}
														<input
															type="radio"
															value={value}
															name="bank"
															{...register("bank_name", {})}
														/>
														<span className="radio-c-checkmark"></span>
													</label>
												</div>
											);
										  })
										: ""}
									{otherBank === "Others" ? (
										<div className="col-lg-6">
											<input
												placeholder=""
												className="form-control"
												{...register("others_bank")}
											/>
										</div>
									) : (
										""
									)}
								</div>
							</div>

							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
									Bank Account Number
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("bank_account_number")}
								/>
								<ErrorMessage
									errors={errors}
									name="account_holder"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>

							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
									IFSC Code
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("ifsc_code")}
								/>
								<ErrorMessage
									errors={errors}
									name="account_holder"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>

							<div className="col-lg-6 col-12 mb-3">
								<label className="form-label">
									Upload Image of the Cheque or any other document with payment
									details
								</label>
								<div className="d-flex align-items-center">
									<label
										className="form-control w-auto text-center"
										style={{
											backgroundColor: "#00a0e3",
											color: "#fff",
											cursor: "pointer"
										}}
										htmlFor="locationimage"
									>
										<BsFillCameraFill /> Capture Image
									</label>
									<input
										id="locationimage"
										accept="image/*"
										placeholder="Capture Image"
										className="form-control d-none"
										{...register("cheque_image_2")}
										type="file"
										capture="environment"
										onChange={e => {
											if (e.target.files.length) {
												setLocImage({
													image: e.target.files[0],
													name: e.target.files[0].name
												});
											}
										}}
									/>
									&nbsp;OR&nbsp;
									<label
										className="form-control w-auto text-center"
										style={{
											backgroundColor: "#00a0e3",
											color: "#fff",
											cursor: "pointer"
										}}
										htmlFor="locationimage2"
									>
										<FaUpload /> Upload Document
									</label>
									<input
										id="locationimage2"
										placeholder="Capture Image"
										className="form-control d-none"
										{...register("cheque_image_2")}
										type="file"
										onChange={e => {
											if (e.target.files.length) {
												setLocImage({
													image: e.target.files[0],
													name: e.target.files[0].name
												});
											}
										}}
									/>
								</div>
								{locImage.name ? (
									<p
										style={{
											marginBottom: "0px",
											marginTop: "10px",
											fontSize: "14px",
											color: "#000"
										}}
									>
										{locImage.name}{" "}
									</p>
								) : (
									""
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12 text-center">
								{isLoading ? (
									<button
										type="button"
										className="btn btn-primary"
										disabled={true}
									>
										<img
											src="/images/pages/home/loader.svg"
											alt={process.env.REACT_APP_NAME}
										/>
									</button>
								) : (
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								)}
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PaymentRequestForm;
