import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { RiDeleteBin6Fill, RiHistoryLine } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ReEmpowerContext } from "../../context/ReEmpowerState";
import axios from "axios";
import { toast } from "react-toastify";
import UserStatusField from "./UserStatusField";
import { statusDropdownData } from "../../data/StatusDropdownData";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const VehicleList = ({ category }) => {
	const { getVehicles, vehicles, isLoading } = useContext(ReEmpowerContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchValue, setSearchValue] = useState("");
	const handleAppDelete = app_id => {
		if (window.confirm("Are you sure you want to remove this App")) {
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/remove-product`,
					{ product_id: app_id }
				)
				.then(response => {
					if (response.data.response.status === 200) {
						toast.success("Product Deleted Successfully");
						getVehicles({ category });
					}
				});
		}
	};

	const updateStatus = async (user_id, value, nagetiveId, product_id) => {
		let data = { status: value, product_enc_id: product_id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/update-product-status`,
				data
			)
			.then(() => {
				getVehicles({ category });
			});
	};

	const columns = [
		{
			name: "Vehicles",
			selector: row => (row.name ? row.name : "-"),
			sortable: true,
			minWidth: "60%",
			marginBottom: "20px",
			cell: row => {
				return (
					<Link
						to={`/${category === "Two Wheeler" ? "vehicle" : "mobile"}/${row.slug}`}
						target="_blank"
						rel="noreferrer"
						className="d-flex my-3"
					>
						{row.productImages && row.productImages.length ? (
							<div className="app-icon mx-3">
								<img
									style={{
										width: "80px",
										height: "80px",
										border: "4px solid #fff",
										boxShadow: "0px 1px 10px 1px #ddd",
										borderRadius: "6px"
									}}
									src={row.productImages[0].image_link}
									alt={row.name}
								/>
							</div>
						) : (
							""
						)}
						<div className="app-description">
							<h5 className="my-2 text-capitalize text-link">{row.name}</h5>
							<p className="mb-1">₹ {row.price}</p>
							<ul className="app-sub-action mb-0">
								{/* <li><RiFileListLine size={18}/>{row.elements_count} Forms</li>*/}
								{/* <li><BiCheckCircle size={18}/>0 Submissions</li>*/}
								<li>
									<RiHistoryLine />
									Posted On: {moment(row.created_on).format("DD MMM YYYY h:mm a")}
								</li>
							</ul>
						</div>
					</Link>
				);
			}
		},
		{
			name: "Status",
			selector: row => (row.status ? row.status : "-"),
			sortable: true,
			cell: row => (
				// editStatus && editStatus == row.product_enc_id ? (
				<UserStatusField
					row={row}
					dataFields={statusDropdownData.statusVehicle}
					updateStatus={updateStatus}
				/>
				// ) : (
				//     <>
				//         <Badge className="mx-1" bg={row.status === 'Active' ? 'success' : 'warning'}>
				//             {row.status}
				//         </Badge>
				//         <FaRegEdit size={14} style={{cursor:"pointer"}} onClick={() => setEditStatus(row.product_enc_id)}/>
				//     </>
				// )
			)
		},
		{
			name: "Actions",
			selector: row => moment(row.created_on).format("DD MMM YYYY h:mm a"),
			sortable: true,
			cell: row => (
				<>
					{/* <Link to={`/account/app/${row.app_enc_id}/edit`} className="btn btn-primary mx-1"*/}
					{/*      style={{padding: '10px 15px', color: '#fff'}}>*/}
					{/*    <FaRegEdit size={18}/>*/}
					{/* </Link>*/}
					<Link
						to={`/account/re-empower/${
							category === "Two Wheeler" ? "vehicle" : "mobile"
						}/${row.slug}`}
						className="btn btn-primary mx-1"
						style={{ padding: "10px 15px", color: "#fff" }}
					>
						<FaRegEdit size={18} />
					</Link>
					<button
						onClick={() => handleAppDelete(row.product_enc_id)}
						className="btn btn-danger mx-1"
						style={{ padding: "10px 15px", color: "#fff" }}
					>
						<RiDeleteBin6Fill size={18} />
					</button>
				</>
			)
		}
	];

	// ** Function to handle filter
	const handleFilter = e => {
		setSearchValue(e.target.value);
		getVehicles({
			page: currentPage,
			limit: rowsPerPage,
			search_keyword: e.target.value
		});
	};

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getVehicles({
			page: page.selected + 1,
			limit: rowsPerPage,
			search_keyword: searchValue
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getVehicles({
			page: 1,
			limit: parseInt(e.target.value),
			search_keyword: searchValue
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(vehicles.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const filters = {
			q: searchValue
		};

		const isFiltered = Object.keys(filters).some(function (k) {
			return filters[k].length > 0;
		});

		if (vehicles && vehicles.products.length > 0) {
			return vehicles.products;
		} else if (vehicles && vehicles.products.length === 0 && isFiltered) {
			return [];
		} else {
			return vehicles && vehicles.products.length > 0
				? vehicles.products.slice(0, rowsPerPage)
				: [];
		}
	};

	useEffect(() => {
		getVehicles({
			category,
			page: currentPage,
			limit: rowsPerPage,
			keyword: searchValue
		});
	}, []);

	return (
		<div className="loan-application-table mb-5">
			<Row className="mx-0 mt-1 mb-50">
				<Col sm="6">
					<div className="d-flex align-items-center">
						<label htmlFor="sort-select">Show</label>
						<select
							className="dataTable-select form-select"
							style={{ width: "65px", padding: "2px 8px", margin: "0px 10px" }}
							id="sort-select"
							value={rowsPerPage}
							onChange={e => handlePerPage(e)}
						>
							<option value={10}>10</option>
							<option value={25}>25</option>
							<option value={50}>50</option>
							<option value={75}>75</option>
							<option value={100}>100</option>
						</select>
						<label htmlFor="sort-select">Results</label>
					</div>
				</Col>
				<Col
					className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
					sm="6"
				>
					<label className="mr-1" htmlFor="search-input">
						Search
					</label>
					<input
						className="dataTable-filter form-control"
						type="text"
						style={{ maxWidth: "150px", margin: "0px 5px", height: "35px" }}
						id="search-input"
						value={searchValue}
						onChange={handleFilter}
					/>
				</Col>
			</Row>
			<DataTable
				noHeader
				pagination
				paginationServer
				columns={columns}
				data={dataToRender()}
				paginationComponent={CustomPagination}
				// actions={actionsMemo}
				progressPending={isLoading === "table"}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
			/>
		</div>
	);
};
export default VehicleList;
