import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BiRupee } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { MdOutlineLocalPrintshop } from "react-icons/md";

const PrReceipt = () => {
	const params = useParams();
	const [receiptData, setReceiptData] = useState("");
	const getReceiptDetails = async () => {
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/pr-receipt`, 
			{"emi_collection_enc_id": params.id}
		).then(
			response => {
				if(response.data.response.status === 200){
					setReceiptData(response.data.response.data);
				}
			}
		).catch();
	};

	const printReceipt = () => {
		window.print();
	};

	useEffect(() => {
		if(!receiptData){
			getReceiptDetails();
		}
	}, [params]);
	

	return (
		<>
			<Helmet>
				<title>PR Receipt</title>
			</Helmet>
			<div className="receipt-container shadow bg-white mb-5">
				<div className="top-border-img">
					<img src="/images/pages/pr-receipt/top-border1.png" alt="top-border-img" />
				</div>
				<div className="main1 bg-white pb-0">
					<div className="container-top pt-5 d-flex justify-content-between">
						<img src="/images/pages/dashboard/phfleasing-logo.png" className="pr_logo1 text-top" alt="logo_phf"/>
						<img src="/images/brand/logo/credifin_logo.png" className="pr_logo text-top"  alt="logo_credifin"/>
					</div>

					<div className="content-pr-receipt mt-3 text-end">
						<p className="fw-bold">
                        Receipt Number: <span className="mx-1">{receiptData?.reference_number}</span>
						</p>
						<p className="fw-bold">
                        Date:<span className="mx-1"> {receiptData.collection_date ? moment(receiptData.collection_date).format("DD MMMM[, ]YYYY") : "-"}</span>
						</p>
					</div>
					<div className="heading text-center">
						<p className="mt-4 fw-bold" style={{ fontSize: "28px", color: "rgb(15 93 125" }}>
							PROVISIONAL RECEIPT
						</p>
					</div>
					<div className="content p-3 pb-0">
						<h3 className="fw-bold mb-1" style={{ color: "#00a0e3" }}>Customer Information</h3>
						<table className="table width-fix m-0">
							<tbody>
								<tr>
									<td className="fw-bold w-40" style={{color:"black"}}>Name:</td>
									<td>{receiptData?.name}</td>
								</tr>
								<tr>
									<td className="fw-bold w-40" style={{color:"black"}}>Phone No:</td>
									<td>{receiptData?.phone}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="content p-3 pb-0">
						<h3 className="fw-bold mb-1" style={{ color: "#00a0e3" }}>Payment Information</h3>
						<table className="table width-fix">
							<tbody>
								<tr>
									<td className="fw-bold w-40" style={{color:"black"}}>Loan A/C No:</td>
									<td>{receiptData?.loan_account_number}</td>
								</tr>
								<tr>
									<td className="fw-bold w-40" style={{color:"black"}}>Loan Type:</td>
									<td>{receiptData?.loan_type}</td>
								</tr>
								<tr>
									<td className="fw-bold w-40" style={{color:"black"}}>Payment Method:</td>
									<td>{receiptData?.emi_payment_method}</td>
								</tr>
								<tr>
									<td className="fw-bold w-40" style={{color:"black"}}>Amount:</td>
									<td className="d-flex align-items-center border-none"><BiRupee /> {receiptData.amount ? parseFloat(receiptData.amount).toLocaleString("en-IN"): "0"}</td>
								</tr>
								<tr>
									<td className="fw-bold w-40" style={{color:"black"}}>Branch:</td>
									<td className="text-capitalize">{receiptData?.branch}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="pr-receipt-card">
					<h4 className="fw-bold">Collected By</h4>
					<div className="d-flex align-items-start"> 
						<img src={receiptData?.image} alt="collection-manager-image" className="collection-manager-image me-2"/>
						<p className="text-capitalize p-1 m-0">
							<span className="model-name-clamp"> {receiptData?.collected_by}</span>
							<span className="d-block fs-6 fw-medium text-black-50">
								{receiptData?.employee_code}
							</span>
							<span className="d-block fs-6 fw-medium text-black-50">
								{receiptData?.designation}
							</span>
						</p>
					</div>
				</div>
				<div className="bottom-border-img">
					<img src="/images/pages/pr-receipt/line.png" alt="bottom-border-img" />
				</div>
			</div>
			<div id="pr-actions" className="pr-actions text-center mb-5">
				<button type="button" className="btn btn-primary" onClick={() => printReceipt()}>
					<MdOutlineLocalPrintshop /> Print Receipt
				</button>
			</div>
		</>
	);
};

export default PrReceipt;
