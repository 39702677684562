import React, { createContext, useReducer } from "react";
import OrganizationAppsReducer from "../reducers/OrganizationAppsReducer";
import axios from "axios";
import { toast } from "react-toastify";
// import authData from "../data/authData";

// Create context
export const OrganizationAppsContext = createContext({});

// Provider component
export const OrganizationAppsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(OrganizationAppsReducer, {});

	// Actions
	const getApps = async (filters = {}) => {
		filters["limit"] = 30;
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organization-apps/list`,
				filters
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_APPS",
						payload: response.data.response.list
					});
				} else {
					dispatch({
						type: "SET_APPS",
						payload: []
					});
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting organization apps list, Please try again");
			});
	};

	const updateStatus = async (user_id, status) => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/change-status`,
				{
					user_id,
					status
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Data Updated");
					getApps();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while updating status, Please try again");
			});
	};

	const deleteApp = async app_id => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organization-apps/remove-app`,
				{
					app_id
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("App Removed Successfully");
					getApps();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	return (
		<OrganizationAppsContext.Provider
			value={{
				apps: state.apps,
				getApps,
				deleteApp,
				updateStatus
			}}
		>
			{children}
		</OrganizationAppsContext.Provider>
	);
};
