import DataTable from "react-data-table-component";
import AggrementTypeEdit from "../forms/AggrementTypeEdit";
import NameComp from "../NameComp";
import { BsPersonFillAdd } from "react-icons/bs";
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos, MdDelete } from "react-icons/md";
import { MainContext } from "../../context/MainState";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import moment from "moment";
import { BiRupee } from "react-icons/bi";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import EmiCollectionForm from "./EmiCollectionForm";
import { FaRegEdit } from "react-icons/fa";
import EditSelectInput from "../forms/EditSelectInput";
import UpdateEmiFields from "../forms/UpdateEmiFields";
import CollectionManagerModal from "./CollectionManagerModal";
import AutoComplete from "../forms/AutoComplete";
import { useForm } from "react-hook-form";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";
import MinMaxFilter from "../MinMaxFilter";
import SkeletonCard from "./SkeletonCard";
import { GoDotFill } from "react-icons/go";

const LoanAccountsDataTable = ({ type = "upcoming", name = null }) => {
	const { getEmiAccountData, accountData, branches_list, states_list} = useContext(
		OrganizationDashboardContext
	);
	const { handleSubmit, watch, setValue, getValues, reset } = useForm();
	const toBeShared = watch("shared_to");
	let toBeSharedName = watch("name");
	const { user, handlePageLoaded, all_financers, loanAccountTypes } = useContext(MainContext);
	const [searchArray, setSearchArray] = useState({});
	const [sortArr, setSortArr] = useState({});
	const [clearFilters, setClearFilters] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [bucketStats, setBucketStats] = useState({});
	const [loading, setLoading]= useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [loanAccountId, showLoanSideBar] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	const [loanAccountNumber, setLoanAccountNmber] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	const [userSpecial, setUserSpecial] = useState(false);
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [vals, setVals] = useState({ latitude: "" });
	const [tableLoading, setTableLoading] = useState(false);
	const [loanAccountSidebarId, setLoanAccountId] = useState("");
	const [reloadSidebar, setReloadSidebar] = useState(false);
	const [bdoModal, setBdoModal] = useState(false);
	const [finalUsers, setFinalUsers] = useState([]);
	const [isClicked, setIsClicked] = useState(false);
	const [sharedModal, setSharedModal] = useState(false);
	const [editEmiFields, setEditEmiFields] = useState({ loan_account_enc_id: "" });

	useEffect(() => {
		if (toBeShared) {
			onSubmit(getValues());
		}
	}, [toBeShared]);

	const handleBdoRemoved = id => {
		const tempAll = finalUsers.filter(list => list.shared_to !== id);
		setFinalUsers(tempAll);
	};

	const hideBdoModal = () => {
		reset();
		setLoanAccountId("");
		setBdoModal(false);
		setFinalUsers([]);
	};

	const resetBdoAssign = () => {
		setLoanAccountId("");
		// setToggleClearRows(!toggledClearRows);
		setFinalUsers([]);
		setBdoModal(false);
		reloadDetails();
	};

	const onSubmit = async data => {
		if (bdoModal === "bdo") {
			const alreadyExist = finalUsers.filter(user => user.shared_to === data.shared_to);
			if (alreadyExist.length) {
				reset();
				toast.error("Already Selected");
				return false;
			}
			setFinalUsers([...finalUsers, data]);
			reset();
		} else {
			assignBdo(bdoModal, data);
		}
	};

	const assignBdo = (type, userData = null) => {
		const data = {};
		const bdos = [];
		if (type === "bdo") {
			finalUsers.forEach(vals => bdos.push({ id: vals.shared_to, access: vals.access }));
		} else {
			bdos.push({ id: userData.shared_to });
		}
		data["loan_accounts"] = [loanAccountSidebarId];
		data["users"] = bdos;
		data["type"] = type;
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/share-loan-accounts`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					if (loanAccountSidebarId) {
						setReloadSidebar(new Date());
					}
					setLoanAccountId("");
					toast.success("Submit Successfully");
					resetBdoAssign();
					reset();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				console.log(error);
				toast.error(error.message);
			});
	};
	const conditionalRowStyles = [
		{
			when: row => row.priority === "1",
			style: { borderLeft: "4px solid red" }
		},
		{
			when: row => row.priority === "2",
			style: { borderLeft: "4px solid orange" }
		},
		{
			when: row => row.priority === "3",
			style: { borderLeft: "4px solid yellow" }
		},
		{
			when: row => row.priority === "4",
			style: { borderLeft: "4px solid #00a0e3" }
		},
		{
			when: row => row.priority === "5",
			style: { borderLeft: "4px solid #bebebe" }
		}
	];

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	// const handleSelectedRows = ({ selectedRows }) => {
	// 	setSelectedRows(selectedRows);
	// };

	// ** Function to handle per page
	const handlePerPage = e => {
		setTableLoading(type);
		getEmiAccountData({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			fields_sort: sortArr,
			sub_bucket: "",
			type: type
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const handlePagination = page => {
		setTableLoading(type);
		getEmiAccountData({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
			sub_bucket: "",
			type: type
		});
		setCurrentPage(page.selected + 1);
	};

	const location = type => {
		if ("geolocation" in navigator) {
			if (type === "emi") {
				setShowEmiModal(true);
			}
			navigator.geolocation.getCurrentPosition(success);
		} else {
			navigator.geolocation.getCurrentPosition(locerrors);
		}
	};

	const handleCloseEmi = () => {
		setLoanAccountNmber("");
		setShowEmiModal(false);
	};

	const success = async pos => {
		var crd = pos.coords;
		Geocode.fromLatLng(crd.latitude, crd.longitude).then(
			response => {
				let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
				for (let i = 0; i < response.results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
						case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
							// case "country":
							// 	country = response.results[0].address_components[i].long_name;
							// 	break;
						case "postal_code":
							postalCode = response.results[0].address_components[i].long_name;
							break;
						case "premise":
							route = response.results[0].address_components[i].long_name;
							break;
						case "sublocality":
							sublocality = response.results[0].address_components[i].long_name;
							break;
						case "sublocality_level_2":
							sublocality_2 = response.results[0].address_components[i].long_name;
						}
					}
				}
				if (route && sublocality_2 && sublocality) {
					streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
				} else if (route && sublocality) {
					streetAdd = route + ", " + sublocality;
				} else {
					streetAdd = route;
				}
				setVals({
					latitude: crd.latitude,
					longitude: crd.longitude,
					postal_code: postalCode,
					streetAdd,
					city,
					state
				});
			},
			error => {
				console.error(error);
			}
		);
	};

	const locerrors = err => {
		toast.error("Please enable location to continue");
		alert(`ERROR(${err.code}): ${err.message}`);
	};

	const fetchmylocation = type => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				if (result.state === "granted") {
					location(type);
					// If granted then you can directly call your function here
				} else if (result.state === "prompt") {
					location(type);
				} else if (result.state === "denied") {
					toast.error("Please enable location to continue");
					// If denied then you have to show instructions to enable location
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			// alert("Sorry Not available!");
			toast.error("Please enable location to continue");
		}
	};

	const handleShowApplyLoan = type => {
		fetchmylocation(type);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "260px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			cell: row => {
				return (
					<div className="justify-content-between d-flex align-content-center w-100">
						<span
							className="me-2 text-link"
							onClick={() =>
								showLoanSideBar({
									loan_account_enc_id: row.loan_account_enc_id,
									loan_account_number: row.loan_account_number,
									assignedLoanAccounts: row.assignedLoanViews
								})
							}
						>
							{row.loan_account_number ? row.loan_account_number : "-"}
						</span>
						
						<div className="d-flex align-items-center">
							{row?.nach_approved ? (
								<OverlayTrigger
									placement="top"
									delay={{ show: 250, hide: 400 }}
									overlay={<Tooltip id="invite-nach">Nach {row.nach_approved}</Tooltip>}
								>
									<div style={{ display: "inline-block", marginRight: "15px" }}>
										<GoDotFill size={16} color={row.nach_approved === "Active" ? "green" 
											: row.nach_approved === "Issued" ? "yellow" : "red"}/>
									</div>
								</OverlayTrigger>
							) : ""}

							<button
								type="button"
								className="btn-table-emi"
								onClick={() => {
									handleShowApplyLoan("emi");
									setLoanAccountNmber({
										loan_account_enc_id: row.loan_account_enc_id,
										loan_account_number: row.loan_account_number
									});
								}}
							>
								Collect EMI
							</button>
						</div>
					</div>
				);
			},
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() =>
							showLoanSideBar({
								loan_account_enc_id: row.loan_account_enc_id,
								loan_account_number: row.loan_account_number,
								assignedLoanAccounts: row.assignedLoanViews
							})
						}
					>
						{row.name ? row.name : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Bucket"}
					id={"sub_bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			maxWidth: "155px",
			selector: row => (row.sub_bucket ? <b>{row.sub_bucket === "0" ? "X" : row.sub_bucket}</b> : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "180px"
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					branches={branches_list ? branches_list : []}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false,
			cell: row => {
				return user.user_type === "Financer" ? (
					<EditSelectInput
						optionList={branches_list}
						getData={reloadDetails}
						defaultValue={row.branch_enc_id ? row.branch_enc_id : ""}
						defaultLabel={row.branch_name ? row.branch_name : ""}
						api={"loan-accounts/update-branch"}
						id={row.loan_account_enc_id}
						selectPlaceholder="Choose Branch"
						parentId={user.organization_enc_id}
						required={true}
						loanDetailStatus={row.loan_status ? parseInt(row.loan_status) : ""}
					/>
				) : row.branch_name ? (
					row.branch_name
				) : (
					"-"
				);
			}
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					states={states_list? states_list :[]}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Case Number"}
					id={"case_no"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			omit: user.username !== "KKB",
			minWidth: "150px",
			cell: row => {
				return (
					<div>
						{row.case_no}&nbsp;
						{user.username === "KKB" ? (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer"
								}}
								size={14}
								onClick={() => {
									setEditEmiFields(row);
								}}
							/>
						) : (
							""
						)}
					</div>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Company ID"}
					id={"company_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			omit: user.username !== "KKB",
			minWidth: "150px",
			cell: row => {
				return (
					<div>
						{row.company_id}&nbsp;
						{user.username === "KKB" ? (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer"
								}}
								size={14}
								onClick={() => {
									setEditEmiFields(row);
								}}
							/>
						) : (
							""
						)}
					</div>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Target Collection Amount"}
					id={"target_collection_amount"}
					searchArray={searchArray}
					placeholder={"Amount"}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
				/>
			),
			minWidth: "180px",
			selector: row => (row.target_collection_amount ? row.target_collection_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt bold">
							<BiRupee size={16} />{" "}
							{row.target_collection_amount
								? parseFloat(row.target_collection_amount).toLocaleString("en-IN")
								: "0"}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Net Outstanding Amount"}
					id={"total_pending_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "180px",
			selector: row => (row.total_pending_amount ? row.target_pending_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{row.total_pending_amount
								? parseFloat(row.total_pending_amount).toLocaleString("en-IN")
								: "0"}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"PTP Amount"}
					id={"proposed_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row =>
				(row.emiCollectionsCustom ? row.emiCollectionsCustom.proposed_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.emiCollectionsCustom ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(
									row.emiCollectionsCustom.proposed_amount
								).toLocaleString("en-IN")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"PTP Date"}
					id={"proposed_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					inputType={"datepicker"}
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "170px",
			selector: row => row?.emiCollectionsCustom?.proposed_date ? moment(row.emiCollectionsCustom.proposed_date).valueOf() : 0,
			sortable: false,
			cell: row => {
				return (
					<>
						{row.emiCollectionsCustom
							? moment(row.emiCollectionsCustom?.proposed_date).format("DD MMM YYYY")
							: "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"EMI Date"}
					id={"emi_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					searchFrom="loan_account"
				/>
			),
			minWidth: "170px",
			selector: row => row.emi_date ? moment(row.emi_date).valueOf() : "",
			sortable: false,
			cell: row => moment(row.emi_date).format("DD")
		},
		{
			name: (
				<MinMaxFilter
					title={"EMI Amount"}
					id={"emi_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.emi_amount ? row.emi_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{row.emi_amount
								? parseFloat(row.emi_amount).toLocaleString("en-IN")
								: ""}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Overdue EMI"}
					id={"overdue_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.overdue_amount ? row.overdue_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.overdue_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.overdue_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"POS"}
					id={"pos"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.pos ? row.pos : "-"),
			omit: !(user.user_type === "Financer" || ["monika200", "phf1136"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.pos ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} /> {parseFloat(row.pos).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Nach Status"}
					id={"nach_approved"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.nach_approved ? row.nach_approved : "-"),
			sortable: false,
			minWidth: "180px",
			omit: type === "nach" ? true : false
		},
		
		{
			name: (
				<MinMaxFilter
					title={"Last Collection Amount"}
					id={"last_emi_received_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "190px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.last_emi_received_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.last_emi_received_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Last Collection Date"}
					id={"last_emi_received_date"}
					placeholder="Date"
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => (row.last_emi_received_date ? moment(row.last_emi_received_date).valueOf() : 0),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.last_emi_received_date
							? moment(row.last_emi_received_date).format("DD MMM")
							: "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Sales Priority"}
					id={"sales_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					priorityType="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.sales_priority ? row.sales_priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">
							{row.sales_priority ? row.sales_priority : "-"}
						</h6>
					</>
				);
			},
			omit: !userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.sales_priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},

		{
			name: (
				<NameComp
					title={"Telecaller Priority"}
					id={"telecaller_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					priorityType="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.telecaller_priority ? row.telecaller_priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">
							{row.telecaller_priority ? row.telecaller_priority : "-"}
						</h6>
					</>
				);
			},
			omit: !userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.telecaller_priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},
		{
			name: (
				<NameComp
					title={"Collection Priority"}
					id={"collection_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					searchFrom="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.collection_priority ? row.collection_priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">
							{row.collection_priority ? row.collection_priority : "-"}
						</h6>
					</>
				);
			},
			omit: !userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.collection_priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},
		{
			name: (
				<NameComp
					title={"Priority"}
					id={"priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					priorityType="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.priority ? row.priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">{row.priority ? row.priority : "-"}</h6>
					</>
				);
			},
			omit: userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},
		{
			name: (
				<MinMaxFilter
					title={"Sales Target Date"}
					id={"sales_target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "170px",
			selector: row => (row.sales_target_date ? row.sales_target_date : "-"),
			sortable: false,
			omit: !userSpecial,
			cell: row => {
				return (
					<>
						{row.sales_target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.sales_target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Telecaller Target Date"}
					id={"telecaller_target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "170px",
			selector: row => (row.telecaller_target_date ? row.telecaller_target_date : "-"),
			sortable: false,
			omit: !userSpecial,
			cell: row => {
				return (
					<>
						{row.telecaller_target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.telecaller_target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Collection Target Date"}
					id={"collection_target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					futureDate={true}
				/>
				
			),
			minWidth: "170px",
			selector: row => (row.collection_target_date ? row.collection_target_date : "-"),
			sortable: false,
			omit: !userSpecial,
			cell: row => {
				return (
					<>
						{row.collection_target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.collection_target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Target Date"}
					id={"target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "180px",
			selector: row => (row.target_date ? row.target_date : "-"),
			sortable: false,
			omit: userSpecial,
			cell: row => {
				return (
					<>
						{row.target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Assigned Telecaller"}
					id={"assigned_caller"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => row.assigned_caller,
			sortable: false,
			cell: row => (row.assigned_caller ? row.assigned_caller : "-")
		},

		{
			name: (
				<NameComp
					title={"Assigned BDO"}
					id={"assigned_bdo"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => row.loan_account_number, // omit: user.user_type !== 'Financer',
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								<div className="register-count">
									<div
										className="reg-img"
										onClick={() => setSharedModal({ ...row, hide: "CM" })}
									>
										{row.assignedLoanViews && row.assignedLoanViews
											.filter(name => name.user_type === "bdo")
											.map((value, index) => {
												if (index <= 1) {
													return (
														<span
															className={`reg${index + 1} reg`}
															key={`accessList-${index}`}
														>
															<img src={value.image} />
														</span>
													);
												}
											})}
									</div>
									{row.assignedLoanViews && row.assignedLoanViews.filter(
										name => name.user_type === "bdo"
									).length > 2 ? (
											<span style={{ color: "#00a0e3", fontSize: "13px" }}>
												{" "}
											+
												{row.assignedLoanViews.filter(
													name => name.user_type === "bdo"
												).length - 2}{" "}
											</span>
										) : (
											""
										)}

									<span
										className="cont"
										onClick={() => {
											setLoanAccountId(row.loan_account_enc_id),
											setBdoModal("bdo");
										}}
									>
										<BsPersonFillAdd color="#00a0e3" size={22} />
									</span>
								</div>
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Collection Manager"}
					id={"collection_manager"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => row.loan_account_number, // omit: user.user_type !== 'Financer',
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								<div className="register-count">
									<div
										className="reg-img"
										onClick={() => setSharedModal({ ...row, hide: "BDO" })}
									>
										{row.assignedLoanViews && row.assignedLoanViews
											.filter(name => name.user_type === "collection_manager")
											.map((value, index) => {
												if (index <= 1) {
													return (
														<span
															className={`reg${index + 1} reg`}
															key={`accessList-${index}`}
														>
															<img src={value.image} />
														</span>
													);
												}
											})}
									</div>
									{row.assignedLoanViews && row.assignedLoanViews.filter(
										name => name.user_type === "collection_manager"
									).length > 2 ? (
											<span style={{ color: "#00a0e3", fontSize: "13px" }}>
												{" "}
											+
												{row.assignedLoanViews.filter(
													name => name.user_type === "collection_manager"
												).length - 2}{" "}
											</span>
										) : (
											""
										)}
									<span
										className="cont"
										onClick={() => {
											setLoanAccountId(row.loan_account_enc_id),
											setBdoModal("collection_manager");
										}}
									>
										<BsPersonFillAdd color="#00a0e3" size={22} />
									</span>
								</div>
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Assigned Financer"}
					id={"financer"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" ? (
							<>
								<AggrementTypeEdit
									optionList={all_financers}
									getData={() => reloadDetails()}
									defaultValue={row}
									type="loan_account"
									api={"loan-accounts/financer-assigned"}
									id={row.loan_account_enc_id}
									selectPlaceholder="Choose Financer"
									parentId={user.organization_enc_id}
									required={true}
									loanDetailStatus={0}
									editInputActive={true}
								/>
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		}
	];

	const handleSidebarModal = val => {
		setBdoModal(val.type);
		setLoanAccountId(val.id);
	};

	useEffect(() => {
		if (accountData && accountData[type]) {
			setTableLoading(false);
		}
	}, [accountData]);

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = accountData ? parseInt(accountData[type].count) / rowsPerPage : 1;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	const reloadDetails = () => {
		setTableLoading(type);
		getEmiAccountData({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
			sub_bucket: "",
			type: type
		});
		getEmiAccountsStats({
			type: type
		});
	};
	const getEmiAccountsStats = type => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-emi-accounts-stats`,
				type
			)
			.then(response => {
				setLoading(false);
				if (response?.data?.response?.status === 200) {
					setBucketStats(response.data.response.data);
				} else {
					toast.error("An error has occured while getting EMI Account Stats, Please try again");
				}
			})
			.catch((error) => {
				toast.error(error.message);
				setLoading(false);
			});
	};
	useEffect(() => {
		if (Object.keys(searchArray).length) {
			setTableLoading(type);
			setCurrentPage(1);
			getEmiAccountData({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
				sub_bucket: "",
				type: type
			});
			if (
				user.user_type === "Financer" &&
				(user.organization_username === "phfleasing" ||
					process.env.REACT_APP_TYPE === "DEV")
			) {
				// getLoanAccountStats({bucketVal, fields_search: searchArray});
			}
		}
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray]);

	useEffect(() => {
		if (Object.keys(sortArr).length) {
			setTableLoading(type);
			setCurrentPage(1);
			getEmiAccountData({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
				sub_bucket: "",
				type: type
			});
		}
	}, [sortArr]);

	useEffect(() => {
		if (user.user_type === "Financer" || user.specialAccessRole) {
			setUserSpecial(true);
		}
	}, []);

	return (
		<>
			<Row className="mx-0 mb-50 align-items-center">
			    <Col sm="6">
				 <div className="d-flex align-items-center p-0">
					 <h2 className= "ptp-heading">{name}</h2>
				 </div>
				</Col>
				{ isClicked  ? 
					<>
						<Col sm="6">
							<div className="d-flex align-items-center justify-content-end mb-3">
								<label htmlFor="sort-select">Show</label>
								<select
									className="dataTable-select form-select loan_acc_select"
									style={{
										width: "65px",
										padding: "2px 8px",
										margin: "0px 10px"
									}}
									id="sort-select"
									value={rowsPerPage}
									onChange={e => handlePerPage(e)}
								>
									<option value={10}>10</option>
									<option value={25}>25</option>
									<option value={50}>50</option>
									<option value={75}>75</option>
									<option value={100}>100</option>
								</select>
								<label htmlFor="sort-select">Results</label>
							</div>
						</Col>
					</> : ""}

				<Col sm={isClicked ? "12" : "6"}>
					<div className="d-flex align-items-center justify-content-end">
						{showClearBtn ? (
							<button className="btn btn-primary btn-xs txt-btn-sm-w" onClick={e => clearFilter(e)}>
								Reset
							</button>
						) : (
							""
						)}
						
						{!accountData?.[type] && !isClicked ? (
							<button
								type="button"
								className="btn btn-primary btn-sm txt-btn-sm-w mb-0 p-2"
								onClick={() => {
									if (reloadDetails && !isClicked) {
										reloadDetails();
										setIsClicked(true);
									}
								}}
							>
							Click here to load
							</button>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			{ isClicked && type!=="new_cases" ? 
				<>
					{loading ? (
						<div className="row" style={{minHeight:"3px"}}>
							{[...Array(6)].map((index) => (
								<SkeletonCard key={index} type={"loan_accounts"}/>
							))}
						</div>
						
					):(
						
						Object.keys(bucketStats).length ? (
							<div className="d-flex mb-2 mx-3 flex-wrap">
								{bucketStats.map((value, index) => {
									return (
										<div key={`bucket-stats-${index}`} className="ptp-stats-div mb-1">
											{value.sub_bucket} Bucket:-
											<span className="ml-2 bold">  {value.count} Cases</span>
										</div>
									);
								})}
							</div>
						) : ""
					)}
					
				</> : ""}
			<DataTable
				columns={columns}
				conditionalRowStyles={conditionalRowStyles}
				data={accountData && accountData[type] ? accountData[type].data : []}
				pagination
				paginationServer
				paginationComponent={CustomPagination}
				highlightOnHover="true"
				persistTableHead={accountData && accountData[type] ? true : false}
				progressPending={tableLoading === type}
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent={accountData && accountData[type] ?
					<p className="w-100 m-5 text-start">
						There are no records to display
					</p> : " "
				}
				reloadData={reloadDetails}
				className={`fix-overflow ${accountData && accountData[type] ? "after-load-data" : ""}`}
				// selectableRows={user.user_type === "Financer" || user.specialAccessRole || user.username === "ghuman"}
				// onSelectedRowsChange={handleSelectedRows}
				// clearSelectedRows={toggledClearRows}
			/>

			<Modal show={bdoModal} onHide={hideBdoModal} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>
						Assign Loan Accounts to{" "}
						{bdoModal === "bdo" ? "BDO's" : "Collection Manager"}
					</Modal.Title><span className="close-icon" onClick={()=>hideBdoModal()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						{bdoModal === "bdo" ? (
							<>
								<div className="col-sm-12 col-12">
									<div className="mb-3 d-flex">
										<div className="w-100">
											<AutoComplete
												setFieldValue={setValue}
												link={`company-dashboard/employee-search?type=${bdoModal}&employee_search=`}
												name={"shared_to"}
												val={toBeSharedName ? toBeSharedName : "empty"}
												method={"GET"}
												placeholder={"Enter Username"}
												type="loan_accounts"
											/>
										</div>
									</div>
								</div>
								{finalUsers && finalUsers.length ? (
									<div className="row">
										<h4 className="m-0 mt-2">Selected Users</h4>
										<div className="imageList">
											<table className="table">
												<thead>
													<tr>
														<th>User</th>
														<th className="text-center">Delete</th>
													</tr>
												</thead>
												<tbody className="imageListTd">
													{finalUsers.map((value, index) => {
														return (
															<>
																{value.shared_to ? (
																	<tr
																		key={`users.${index}-${value.shared_to}`}
																	>
																		<td>
																			<div>
																				<img
																					src={
																						value.image
																					}
																					alt={value.name}
																					className="emp-img"
																				/>{" "}
																				{value.name}
																			</div>
																		</td>
																		<td className="text-center">
																			<button
																				type="button"
																				onClick={() =>
																					handleBdoRemoved(
																						value.shared_to
																					)
																				}
																				className="btn btn-primary btn-xs"
																			>
																				<MdDelete
																					fontSize={15}
																				/>
																			</button>
																		</td>
																	</tr>
																) : (
																	""
																)}
															</>
														);
													})}
												</tbody>
											</table>
										</div>
										<div className="col-sm-12 col-12">
											<Col size={12} className="text-center mt-2">
												<button
													type="button"
													className="btn btn-primary"
													onClick={() => assignBdo(bdoModal)}
												>
													Save
												</button>
											</Col>
										</div>
									</div>
								) : (
									""
								)}
							</>
						) : (
							<>
								<div className="col-sm-12 col-12">
									<div className="mb-3 d-flex">
										<div className="w-100">
											<AutoComplete
												setFieldValue={setValue}
												link={`company-dashboard/employee-search?type=${bdoModal}&employee_search=`}
												name={"shared_to"}
												val={toBeSharedName ? toBeSharedName : "empty"}
												method={"GET"}
												placeholder={"Enter Username"}
												// type="loan_accounts"
											/>
											<div className="col-sm-12 col-12">
												<Col size={12} className="text-center mt-2">
													<button
														type="submit"
														className="btn btn-primary"
													>
														Save
													</button>
												</Col>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</form>
				</Modal.Body>
			</Modal>

			<EmiCollectionForm
				show={showEmiModal}
				hide={handleCloseEmi}
				geoLoc={vals}
				loan_number={loanAccountNumber}
			/>

			<EmiLoanAccountSidebar
				handleCloseSideBar={() => showLoanSideBar("")}
				loanAccountId={loanAccountId}
				reloadDetails={reloadDetails}
				handleSidebarModal={handleSidebarModal}
				reloadSidebar={reloadSidebar}
			/>

			<CollectionManagerModal
				sharedModal={sharedModal}
				setSharedModal={setSharedModal}
				reloadDetails={reloadDetails}
			/>

			<UpdateEmiFields
				emiData={editEmiFields}
				onHide={() => setEditEmiFields({ loan_account_enc_id: "" })}
				reload={() => {
					setEditEmiFields({ loan_account_enc_id: "" });
					reloadDetails();
				}}
				api={"loan-accounts/update-loan-account"}
			/>
		</>
	);
};

export default LoanAccountsDataTable;
