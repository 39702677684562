import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import { OrganizationESignContext } from "../../../context/OrganizationESignState";
import { toast } from "react-toastify";

const ESignTable = () => {
	const { getLoanApplications, loan_applications } = useContext(OrganizationESignContext);
	const [filterText, setFilterText] = useState({ text: "", date: "" });
	const [allLoans, setAllLoans] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText.date || filterText.text) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText({ text: "", date: "" });
				setAllLoans(loan_applications);
			}
		};

		const handleChange = (val, type) => {
			setFilterText({ [type]: val });
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/get-esign-applications`,
					{ search_keyword: val }
				)
				.then(response => {
					if (response.data.response.status === 200) {
						setAllLoans(response.data.response.data);
						setResetPaginationToggle(!resetPaginationToggle);
					} else {
						setAllLoans([]);
						setResetPaginationToggle(!resetPaginationToggle);
					}
				});
		};
		return (
			<FilterComponent
				onFilter={handleChange}
				onClear={handleClear}
				filterText={filterText}
			/>
		);
	}, [filterText, resetPaginationToggle, loan_applications]);

	const handleRedirect = link => {
		window.open(link, "_blank").focus();
	};

	const handleGetDocument = (vehicle_loan_id, type) => {
		toast.warning("Please wait..");
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/get-document-url`,
				{ vehicle_loan_id, type }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					window.open(response.data.response.url, "_blank", "noopener,noreferrer");
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};
	const ExpandedComponent = ({ data }) => (
		<div className="mx-5 my-3">
			<div className="row mt-2">
				<div className="col-md-4">
					<div className="detail-title">Aadhaar Number:-</div> {data.aadhaar_number}
				</div>
				<div className="col-md-4">
					<div className="detail-title">Down/Margin Payment:-</div>{" "}
					{data.down_margin_payment}
				</div>
				<div className="col-md-4">
					<div className="detail-title">Hospicare:-</div> {data.hospicare}
				</div>
				<div className="col-md-4">
					<div className="detail-title">Medical Loan:-</div> {data.medical_loan}
				</div>
				<div className="col-md-4">
					<div className="detail-title">Processing Fee:-</div> {data.processing_fee}
				</div>
			</div>
			{data.esignVehicleLoanDetails.length ? (
				<>
					<h3 className="mb-0 mt-2">Vehicle Details</h3>
					<div className="row">
						<div className="col-md-4">
							<div className="detail-title">Invoice Amount:-</div>{" "}
							{data.esignVehicleLoanDetails[0].invoice_amount}
						</div>
						<div className="col-md-4">
							<div className="detail-title">Insurance:-</div>{" "}
							{data.esignVehicleLoanDetails[0].Insurance}
						</div>
						<div className="col-md-4">
							<div className="detail-title">Accessories:-</div>{" "}
							{data.esignVehicleLoanDetails[0].accessories}
						</div>
						<div className="col-md-4">
							<div className="detail-title">Chassis Number:-</div>{" "}
							{data.esignVehicleLoanDetails[0].chassis_number}
						</div>
						{data.esignVehicleLoanDetails[0].chassis_image_url ? (
							<div className="col-md-4">
								<div className="detail-title">Chassis Number Image:-</div>{" "}
								<button
									type="button"
									className="btn btn-primary"
									onClick={() =>
										handleGetDocument(
											data.esignVehicleLoanDetails[0].vehicle_loan_id,
											"chassi"
										)
									}
								>
									Click here to download
								</button>
							</div>
						) : (
							""
						)}
						<div className="col-md-4">
							<div className="detail-title">Vehicle Conditon:-</div>{" "}
							{data.esignVehicleLoanDetails[0].vehicle_conditon}
						</div>
						<div className="col-md-4">
							<div className="detail-title">Driving License:-</div>{" "}
							{data.esignVehicleLoanDetails[0].dl_number}
						</div>
						{data.esignVehicleLoanDetails[0].driving_image_url ? (
							<div className="col-md-4">
								<div className="detail-title">Driving License Image:-</div>{" "}
								<button
									type="button"
									className="btn btn-primary"
									onClick={() =>
										handleGetDocument(
											data.esignVehicleLoanDetails[0].vehicle_loan_id,
											"license"
										)
									}
								>
									Click here to download
								</button>
							</div>
						) : (
							""
						)}
						<div className="col-md-4">
							<div className="detail-title">Hypothecation:-</div>{" "}
							{data.esignVehicleLoanDetails[0].hypothecation}
						</div>
						<div className="col-md-4">
							<div className="detail-title">Make:-</div>{" "}
							{data.esignVehicleLoanDetails[0].make}
						</div>
						<div className="col-md-4">
							<div className="detail-title">Model Name:-</div>{" "}
							{data.esignVehicleLoanDetails[0].model_name}
						</div>
						<div className="col-md-4">
							<div className="detail-title">RC:-</div>{" "}
							{data.esignVehicleLoanDetails[0].rc}
						</div>
						<div className="col-md-4">
							<div className="detail-title">Others:-</div>{" "}
							{data.esignVehicleLoanDetails[0].others}
						</div>
					</div>
				</>
			) : (
				""
			)}
			{data.doc_files.length ? (
				<>
					<h3 className="mb-0 mt-2">E-sign Documents</h3>
					<div className="row">
						{data.doc_files.map((value, index) => {
							return (
								<div
									className="col-md-4"
									key={`eSignDocuments${value.doc_id}${index}`}
								>
									<button
										type="button"
										className="btn btn-primary eSignActionButton"
										onClick={() => handleRedirect(value.file_url)}
									>
										Download {value.name} Pdf
									</button>
								</div>
							);
						})}
					</div>
				</>
			) : (
				""
			)}
			{data.agreements.length ? (
				<>
					<div className="loan-application-table mt-3">
						<h3 className="mb-0 mt-2">E-Sign Process</h3>
						<div className="dsa-table mt-0">
							<table className="table">
								<thead>
									<tr>
										<th>Name</th>
										<th>Phone</th>
										<th>Auth Type</th>
										<th>Auth Method</th>
										<th>Is Signed</th>
										{/* <th>Link Status</th>*/}
										<th>Expires On</th>
										<th>Action</th>
									</tr>
								</thead>
								{data.agreements[0].esignRequestedAgreementsDetails &&
								data.agreements[0].esignRequestedAgreementsDetails.length ? (
										<tbody>
											{data.agreements[0].esignRequestedAgreementsDetails.map(
												(value, index) => {
													return (
														<tr key={`eSignApp${value.signer_id}${index}`}>
															<td>
																<h6 className="mb-0">{value.name}</h6>
															</td>
															<td>
																<h6 className="mb-0">{value.phone}</h6>
															</td>
															<td>
																<h6 className="mb-0">
																	{value.auth_type}
																</h6>
															</td>
															<td>
																<h6 className="mb-0">
																	{value.auth_method}
																</h6>
															</td>
															<td>
																<h6 className="mb-0">
																	{value.is_signed}
																</h6>
															</td>
															{/* <td>*/}
															{/*    <h6 className="mb-0">{value.active === 'TRUE' ? 'Active' : 'Expired'}</h6>*/}
															{/* </td>*/}
															<td>
																<h6 className="mb-0">
																	{moment(value.expiryDate).format(
																		"DD MMM YYYY h:mm a"
																	)}
																</h6>
															</td>
															<td>
																{value.is_signed === "NO" ? (
																	<button
																		type="button"
																		className="btn btn-primary eSignActionButton"
																		onClick={() =>
																			handleRedirect(
																				value.private_url
																			)
																		}
																	>
																	Sign Now
																	</button>
																) : (
																	""
																)}
															</td>
														</tr>
													);
												}
											)}
										</tbody>
									) : (
										""
									)}
							</table>
						</div>
					</div>
				</>
			) : (
				""
			)}
			{/* <pre>{JSON.stringify(data, null, 2)}</pre>*/}
		</div>
	);

	const loanTypes = {
		1: "Vehicle loan",
		2: "Personal Loan",
		3: "Business Loan",
		4: "Education Loan",
		5: "Loan Against Property"
	};
	const agreementTypes = { 1: "Self", 2: "BC", 3: "Co Lending" };
	const columns = [
		{
			name: "Case Number",
			selector: row => row.case_no,
			sortable: false
		},
		{
			name: "Loan Type",
			selector: row => row.loan_type,
			cell: row => {
				return loanTypes[row.loan_type];
			}
		},
		{
			name: "Customer Name",
			selector: row => (row.customer_name ? row.customer_name : "-"),
			sortable: true
		},
		{
			name: "Customer Phone",
			selector: row => row.phone,
			sortable: true
		},
		{
			name: "ROI",
			cell: row => {
				return (
					<h6 className="mb-0 emiAmt">
						{row.roi_type} {row.roi}%
					</h6>
				);
			},
			selector: row => row.roi,
			sortable: true
		},
		{
			name: "Agreement Type",
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: true,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link">
						{agreementTypes[row.agreement_type]}
					</h6>
				);
			}
		},
		{
			name: "Tenure",
			selector: row => (row.tenure_period ? row.tenure_period : "-"),
			sortable: true,
			cell: row => {
				return (
					<h6 className="mb-0 emiAmt">
						{row.tenure_period} ({row.tenure})
					</h6>
				);
			}
		},
		{
			name: "E-sign Date",
			selector: row => moment(row.date_created).format("DD MMM YYYY h:mm a"),
			sortable: true
		}
	];

	useEffect(() => {
		getLoanApplications();
	}, []);

	useEffect(() => {
		setAllLoans(loan_applications);
	}, [loan_applications]);

	return (
		<div className="loan-application-table">
			<h4>E-Sign Applications</h4>

			<DataTable
				columns={columns}
				data={allLoans}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				paginationPerPage={50}
				paginationRowsPerPageOptions={[50, 100, 150, 200]}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				expandableRows
				expandableRowsComponent={ExpandedComponent}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
			/>
		</div>
	);
};
export default ESignTable;
