import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import { MainContext } from "../../context/MainState";
import ProfileDetails from "../../components/ProfileDetails";
import { Nav, Tab } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { BsLockFill } from "react-icons/bs";
import ChangePassword from "../../components/ChangePassword";
import IdCard from "../../components/dashboard/IdCard";
import { AiFillIdcard } from "react-icons/ai";
import { BiNotepad } from "react-icons/bi";
import UploadNotice from "../../components/UploadNotice";
import { CiBullhorn } from "react-icons/ci";
import UploadAnnouncement from "../../components/UploadAnnouncement";

const MyProfile = () => {
	const { user } = useContext(MainContext);
	return (
		<div>
			<Helmet>
				<title>My Profile</title>
			</Helmet>
			<Navbar />
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="vertical-navi bg-white shadow-ell p-5 my-5 rounded-3 overflow-hidden">
							<Tab.Container defaultActiveKey="personal_details">
								<div className="row">
									<div className="col-md-3 border-right-1">
										<Nav variant="pills" className="flex-column mb-5">
											<Nav.Item className="pointer-cursor">
												<Nav.Link eventKey="personal_details">
													<FaUser /> Personal Details
												</Nav.Link>
											</Nav.Item>
											{user.user_type !== "Individual" ? (
												<Nav.Item className="pointer-cursor">
													<Nav.Link eventKey="id_card">
														<AiFillIdcard /> ID Card
													</Nav.Link>
												</Nav.Item>
											): ""}
											{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing" && (user.username === "Isudhanshu4u" || user.username === "phf219")) ? (
												<>
													<Nav.Item className="pointer-cursor">
														<Nav.Link eventKey="notices">
															<BiNotepad /> Notices
														</Nav.Link>
													</Nav.Item>
													<Nav.Item className="pointer-cursor">
														<Nav.Link eventKey="announcement">
															<CiBullhorn /> Announcements
														</Nav.Link>
													</Nav.Item>
												</>
											) : ""}
											<Nav.Item className="pointer-cursor">
												<Nav.Link eventKey="resetpass">
													<BsLockFill /> Account Settings
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</div>
									<div className="col-md-9 padding-left-30">
										<Tab.Content>
											<Tab.Pane eventKey="personal_details">
												<ProfileDetails user={user} />
											</Tab.Pane>
											<Tab.Pane eventKey="id_card">
												<IdCard user={user} />
											</Tab.Pane>
											{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing" && (user.username === "Isudhanshu4u" || user.username === "phf219")) ? (
												<>
													<Tab.Pane eventKey="notices">
														<UploadNotice defaultLoad={1}/>
													</Tab.Pane>
													<Tab.Pane eventKey="announcement">
														<UploadAnnouncement />
													</Tab.Pane>
												</>
											) : ""}
											<Tab.Pane eventKey="resetpass">
												<ChangePassword />
											</Tab.Pane>
										</Tab.Content>
									</div>
								</div>
							</Tab.Container>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyProfile;
