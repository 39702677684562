import { ReactSortable } from "react-sortablejs";
import { RiDeleteBin6Line, RiDragMove2Line } from "react-icons/ri";
import { BsPlusCircleFill } from "react-icons/bs";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LoanProductsContext } from "../../context/LoanProductsState";

const ImagesTab = ({ productId, showDocuments, handlePageLoaded }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [imagesList, setImagesList] = useState([]);
	const { getLoanProductsDetail, productImagesData } = useContext(LoanProductsContext);
	const addNewImage = () => {
		setImagesList([...imagesList, { id: imagesList.length + 1, name: "" }]);
	};

	const removeElement = (index, id = "") => {
		if (id) {
			if (!window.confirm("Do you really want to delete this Image")) {
				return false;
			}
			removeImage(id);
		}
		let items = [...imagesList];
		items.splice(index, 1);
		setImagesList(items);
	};

	const removeImage = async id => {
		let data = { product_image_enc_id: id };
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/remove-loan-product-image`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Image Name removed successfully");
				} else {
					toast.error(
						"An error occurred " +
							(response.data?.response?.error
								? response.data.response.error
								: response.data?.response?.message)
					);
				}
			})
			.catch(error => {
				toast.error("An error occurred " + error.message);
			});
	};

	const handleChange = (index, value) => {
		let items = [...imagesList];
		let item = { ...items[index] };
		item.name = value;
		if (item.name === "" && item.product_image_enc_id) {
			if (window.confirm("Do you really want to delete this image name")) {
				removeImage(item.product_image_enc_id);
			}
		}
		if (!item.product_image_enc_id) {
			item.product_image_enc_id = "";
		}

		item.name === "" ? items.splice(index, 1) : (items[index] = item);
		setImagesList(items);
	};

	const onsubmit = async () => {
		if (!imagesList.length) {
			toast.error("Please add atleast one image name");
			return false;
		}
		for (let i = 0; i < imagesList.length; i++) {
			if (!imagesList[i].name) {
				toast.error("Please enter image name");
				return false;
			}
		}
		setIsLoading(true);
		let data = {
			financer_loan_product_enc_id: productId,
			images: imagesList
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/update-loan-product-images`,
				data
			)
			.then(response => {
				setIsLoading(false);
				if (response.data.response.status === 200) {
					toast.success("Images Name Updated successfully");
					getLoanProductsDetail({ id: productId, type: "images" });
				} else {
					toast.error(
						"An error occurred " +
							(response.data.response.error
								? response.data.response.error
								: response.data.response.message)
					);
				}
			})
			.catch(error => {
				setIsLoading(false);
				toast.error("An error occurred " + error.message);
			});
	};
	const submitWithDelay = () => {
		setTimeout(() => {
			onsubmit();
		}, 700);
	};

	useEffect(() => {
		if (showDocuments) {
			getLoanProductsDetail({ id: productId, type: "images" });
			handlePageLoaded(true);
		}
	}, [showDocuments]);

	useEffect(() => {
		if (productImagesData) {
			handlePageLoaded(false);
			setImagesList(productImagesData);
		} else {
			setImagesList([]);
			handlePageLoaded(false);
		}
	}, [productImagesData]);
	return (
		<>
			{/* <form onSubmit={handleSubmit(onsubmit)} className="mt-6"> */}
			<div className="proof-selection mb20 mt-6">
				<div className="mb-5">
					<label>Click the button to add Image Names</label>
				</div>
				{imagesList && imagesList.length ? (
					<>
						<ReactSortable list={imagesList} setList={setImagesList}>
							{imagesList.map((value, index) => {
								return (
									<div
										className="documentTypehead"
										key={`div-${value.id}${value.name ? `-${value.name}` : ""}`}
									>
										<RiDragMove2Line
											size={25}
											style={{ marginRight: "10px" }}
										/>
										<input
											type="text"
											placeholder="Enter Image Name"
											className="form-control typeheadStyle"
											defaultValue={value.name ? value.name : ""}
											onBlur={e => handleChange(index, e.target.value)}
										/>
										<button
											type="button"
											className="link-button"
											style={{ color: "red", marginLeft: "10px" }}
											onClick={() =>
												removeElement(index, value.product_image_enc_id)
											}
										>
											<RiDeleteBin6Line size={25} />
										</button>
									</div>
								);
							})}
						</ReactSortable>
					</>
				) : (
					""
				)}
				<button
					className="link-button"
					type="button"
					style={{ color: "#6c757d", marginLeft: "10px" }}
					onClick={() => addNewImage()}
				>
					<BsPlusCircleFill size={30} />
				</button>
			</div>
			<div className="mt-5 text-center">
				{isLoading ? (
					<button type="button" className="btn btn-primary" disabled={true}>
						<img src="/images/pages/home/loader.svg" alt="" />
					</button>
				) : (
					<button
						type="submit"
						className="btn btn-primary"
						onClick={() => submitWithDelay()}
					>
						{" "}
						Save{" "}
					</button>
				)}
			</div>
			{/* </form> */}
		</>
	);
};

export default ImagesTab;
