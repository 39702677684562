const OrganizationSidebarReducer = (state, action) => {
	switch (action.type) {
	case "SET_CERTIFICATES":
		return {
			...state,
			certificates: action.payload
		};
	case "SET_IMAGES":
		return {
			...state,
			loanImages: action.payload
		};
	case "SET_SELECTED_PENDENCIES":
		return {
			...state,
			assignedPendencies: action.payload
		};
	case "SET_DEFAULT_PENDENCIES":
		return {
			...state,
			defaultPendencies: action.payload
		};
	case "SET_PENDENCY_IMAGES":
		return{
			...state,
			pendencyImages: action.payload
		};
	case "SET_TAT_REPORT":
		return{
			...state,
			tatReportData: action.payload
		};
	case "SET_ENACH_LOGS":
		return{
			...state,
			enachLogsData: action.payload
		};
	default:
		return state;
	}
};
export default OrganizationSidebarReducer;
